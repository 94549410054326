import * as css from './ClearingFees.css';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import useAccountSettings from '../../hooks/useAccountSettings';
import {useTranslation} from 'react-i18next';
import NumberInput from '../../../../component/input/NumberInput';
import {TICKCHAK_SUPPLIERS} from '../../../../config/config';
import {formatDecimal} from '../../../../library/numberFunction';
import {useEffect} from 'react';
import {getAccountSuppliers} from '../../../../redux/account/accountActions';
import {XHR_SUCCESS_STATE} from '../../../../assets/constants/constants';

function ClearingFees() {
    const {account, update, getBillClassName, isMasterState} = useAccountSettings();
    const {t} = useTranslation('package');
    const initialValues = {
        defaultCommission: formatDecimal(account.billPackage?.defaultCommission),
        diner: formatDecimal(account.billPackage?.defaultCommissionExtraAmexDiners),
        defaultFreeTicketPrice: formatDecimal(account.billPackage?.defaultFreeTicketPrice),
        payments: formatDecimal(account.billPackage?.defaultCommissionPayments),
        foreign: formatDecimal(account.billPackage?.defaultCommissionExtraForeign),
        external: formatDecimal(account.billPackage?.defaultCommissionExternalClearance),
        billing: formatDecimal(account.billPackage?.defaultCommissionBillings)
    };

    const validationSchema = Yup.object().shape({
        defaultCommission: Yup.number().required().min(0).max(100),
        diner: Yup.number().required().min(0),
        defaultFreeTicketPrice: Yup.number().required().min(0),
        payments: Yup.number().required().min(0),
        foreign: Yup.number().required().min(0),
        external: Yup.number().required().min(0),
        billing: Yup.number().required().min(0)
    });
    const getNumberInputProps = (name, className='')=> ({
        name,
        icon: 'nisBell',
        className: `${getBillClassName(name)} ${className}`,
        text: t(name),
        events: {onBlur: (name, value) => update(name, value, true)},
        disabled: !isMasterState,
        forwardRef: undefined
    });
    useEffect(() => {
        if(account.getAccountSuppliersXHR!==XHR_SUCCESS_STATE)
            getAccountSuppliers().then();
    }, []);

    return (
        <Formik 
            initialValues={initialValues} 
            validationSchema={validationSchema} 
            enableReinitialize
            onSubmit={() => { }}>
            <Form>
                <css.top>
                    <css.defaultCommission>{t('tickchakClearingPercentages')}</css.defaultCommission>
                    <NumberInput name={'defaultCommission'} icon={false} className={getBillClassName('defaultCommission')} text={''} events={{onBlur: (name, value) => update(name, value, true)}} disabled={!isMasterState} forwardRef={undefined} />
                </css.top>
                <css.bottomInputs>
                    <css.side>
                        {/* diner */}
                        <NumberInput {...getNumberInputProps('diner', 'percent')} />
                        {/* defaultFreeTicketPrice */}
                        <NumberInput {...getNumberInputProps('defaultFreeTicketPrice', 'ILS')} />
                        {/* payments */}
                        <NumberInput {...getNumberInputProps('payments', 'percent')} />
                    </css.side>
                    <css.side>
                        {/* foreign */}
                        <NumberInput {...getNumberInputProps('foreign', 'percent')}/> 
                        {/* external */}
                        {!!account?.packageSuppliers.some(supplier => !TICKCHAK_SUPPLIERS.includes(supplier.srid)) && <NumberInput {...getNumberInputProps('external', 'percent')} />}
                        {/* billing */}
                        <NumberInput {...getNumberInputProps('billing', 'percent')} />
                    </css.side>
                </css.bottomInputs>
                <css.text>{t('clearingFeesText')}</css.text>
            </Form>
        </Formik>
    );
}

export default ClearingFees;
