import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './Search.css';

function Search({onSearch = () => { }, onSearchEnd = () => { }, placeHolder, searchState = {}}) {
    const {isSearchState, setIsSearchState} = searchState;
    const [isExpended, setIsExpended] = useState(false);
    const [value, setValue] = useState('');

    const {t} = useTranslation('global');

    const ref = useRef(0);

    const onChange = (e) => {
        if(!isSearchState)
            setIsSearchState(true);
        if(!e.target.value && isSearchState)
            setIsSearchState(false);
        setValue(e.target.value);
        ref.current++;
    };
    const timer = useRef(null);

    useEffect(() => {
        if(ref.current === 0)
            return;
        let count = ref.current;
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            if(count === ref.current && value)
                onSearch(value);
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [value]);

    const onClick = () => {
        if(isExpended) {
            onSearchEnd();
            setIsSearchState(false);
            setValue('');
        }
        setIsExpended(!isExpended);
    };

    return (
        <>
            <css.container className={'searchWrap ' + (isExpended && 'expended')} >
                <css.icon className={'searchIcon ' + (isExpended && 'expended')} onClick={onClick} />
                {isExpended && <css.input value={value} autoFocus autocomplete="off" name='search' placeholder={placeHolder || t('searchPlaceHolder')} onChange={onChange} />}
            </css.container>
        </>
    );
}

export default Search;
