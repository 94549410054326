import {isMaster} from '../../functions/permission';
import request from '../../library/request';
import store from '../../store';
import setGlobalState from '../setGlobalState';

export const getSecurityLevel = (permission, isActivating=false) => {
    const _permission = store.getState().account.permissions.find(item => item.permission === permission);
    let level= isActivating ? _permission?.securityLevel : _permission?.eventSecurityLevel;
    if(permission!=='course')
        return isMaster()?(level===3?2:0):level;
    const hasBilling=store.getState().account.permissions?.find(p=>p.permission==='billing')?.status;
    if(hasBilling!==1)
        return isMaster()?2:3;
    return level;
};

export const getToken = async (permission, isVoiceCode = false,isActivating) => {
    const level = getSecurityLevel(permission,isActivating);
    const res = await request('security.getToken', 'post', `/package/${store.getState().account.pid}/permissionSecurity`,
        {level: level, type: permission, isVoiceCode});
    if(res.packageOwner)
        setGlobalState('account.owner', res.packageOwner);
    setGlobalState('security.token', res?.securityToken);
};
