import SettingItem from '../settingItem/SettingItem';
import {useSelector} from 'react-redux';
import CrowdSettings from '../crowdSettings/CrowdSettings';
import EventActivation from '../../../component/eventActivation/EventActivation';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import * as css from '../settingItem/SettingItem.css';
import {useTranslation} from 'react-i18next';
import {subSettingInputs} from '../paymentForm/PaymentForm';
import {Form, Formik} from 'formik';
import EditImage from '../editImage/EditImage';

function GeneralForm({langDesign}) {
    const settings = useSelector(state => state.event.settings);
    const active = settings?.event?.active;
    const {t} = useTranslation('event');
    const eventSettings = useSelector(state => state.event.settings?.event);
    const settingsXHR = useSelector(state => state.forms.eventSettings);
    const {getInput} = subSettingInputs(eventSettings, 'event', settingsXHR, t);

    return (
        <>
            <Formik
                initialValues={{inactiveMessage: eventSettings?.inactiveMessage,}}
                enableReinitialize={true}
            >
                <Form>
                    <SettingItem  name={'active'} settingCategory={'event'} rolePermission='manage event'
                        settingChildren={<css.toggle>
                            <EventActivation Comp={ModeBtn} compProps={{status: active}}/>
                        </css.toggle>}>
                        {getInput('inactiveMessage')}
                    </SettingItem>
                </Form>
            </Formik>
            <SettingItem name={'newsletter'} settingCategory={'event'} rolePermission='manage event'/>
            <EditImage langDesign={langDesign}/>
            <SettingItem name={'block'} settingCategory={'access'}>
                <CrowdSettings />
            </SettingItem>
            <SettingItem name={'showGifts'} settingCategory={'event'} />
        </>
    );
}

export default GeneralForm;
