import React from 'react';
import * as css from './GrayWrapper.css';
import {useField} from 'formik';

function GrayWrapper({name,title,children}) {
    const [, meta]=useField(name);

    return (
        <css.wrapper>
            <css.title>{title}</css.title>
            <css.content>
                <css.children>
                    {children}
                </css.children>
                {meta.touched && meta.error && <css.error>{meta.error}</css.error>}
            </css.content>
       
        </css.wrapper>
    );
}

export default GrayWrapper;
