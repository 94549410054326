import * as css from './CountryFlag.css';

function CountryFlag({countryCode, imageProps={}}) {
    if(!countryCode)
        return <></>;
    countryCode=countryCode.toLowerCase().replace('ps','il');
    return (
        <css.countryImage src={`https://flagcdn.com/w20/${countryCode}.png`} {...imageProps} />
    );

}

export default CountryFlag;
