import styled from 'styled-components/macro';
import zIndex from '../../globalVariables/zIndex';

export const popUpFrame=styled.div`
    backdrop-filter: blur(8px) brightness(0.6);
    -webkit-backdrop-filter: blur(8px) brightness(0.6);
    position:fixed;
    width:100%;    
    top:0;
    left:0;
    z-index:${zIndex.basicModal};    
    height:100%;
    opacity:0;
    &.show{
        opacity:1;
    }
    transition: all 500ms;
`;
