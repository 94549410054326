import styled from 'styled-components/macro';
import {clusterDetails} from '../../../assets/symbols/symbols/Symbols.css';
import {iconBtn} from '../subMenu/SubMenu.css';

export const eventDetails = styled(iconBtn)`
    ::before{
        content: "";
        position: absolute;
        top: -9px;
        width: 60px;
        background-image: url("${clusterDetails}");
        background-repeat: no-repeat;
        height: 50px;
        background-position:center center;    
        background-size: 15px; 
    }
`;