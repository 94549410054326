import {useTranslation} from 'react-i18next';
import * as css from './SubMenuMobile.css';
import useShow from '../../../hooks/useShow';
import MobilePopupModal from '../../../component/newPopup/mobilePopup/MobilePopupModal';
import {POPUP_DURATION} from '../../../globalVariables/GlobalVariables';
import {PageWrapperContext} from '../pageWrapper/PageWrapper';
import {useContext} from 'react';

function SubMenuMobile({Comp, subMenuProps}) {
    const {open, close, isOpen, showClass} = useShow(POPUP_DURATION);
    const {t} = useTranslation('layout');
    const {title} = useContext(PageWrapperContext);

    return (
        <>
            <css.eventDetails onClick={open}>
                {title||t`soon`}
            </css.eventDetails>
            {isOpen &&
                <MobilePopupModal title={title} close={close} showClass={showClass}  >
                    <Comp {...subMenuProps}/>
                </MobilePopupModal>}
        </>
    );
}

export default SubMenuMobile;
