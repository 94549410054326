import styled from 'styled-components';
import {dFlexCenter} from '../../globalVariables/GlobalVariables';

export const wrapDay=styled.div`
    ${dFlexCenter}
    width: 22px;
    height: 22px;
    cursor: pointer;
    :hover{
        border-radius: 50%;
        background-color:#76D6E8 ;
        /* border: 1px solid #76D6E8; */
    }
    &.selected{
        border: 1px solid #76D6E8;
        border-radius: 50%;
    }
`;
export const day=styled.div`

`;
export const wrapDays=styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #00000052;

`;
export const text=styled.div`
    font-weight:600 ;
`;

