import React from 'react';
import * as css from './SmsScreen.css';
import {updateDesignEmail} from '../../../redux/branding/brandingActions';
import SmsEditor from '../smsEditor/SmsEditor';
import {useEffect} from 'react';
import {useContext} from 'react';
import {BrandingContext} from '../BrandingPage';
import AccountPermission from '../../components/accountPermission/AccountPermission';
import {useSelector} from 'react-redux';

function SmsScreen() {
    const {textEditor: {setCurrentEditorName}} = useContext(BrandingContext);
    const eventType=useSelector(s=>s.event.type);
    useEffect(() => {
        setCurrentEditorName('smsContent');
    }, []);

    const viewSmsEnter=eventType!=='course';

    return (   
        <css.wrapper data-cy='smsScreen'>
            <SmsEditor name={'smsContent'} onSave={(content)=>updateDesignEmail({smsContent:content})}/>
            {viewSmsEnter&&<AccountPermission permission={'sms enter'}>
                <SmsEditor name={'smsEnterContent'} onSave={(content)=>updateDesignEmail({smsEnterContent:content})}/>
            </AccountPermission>    }  
        </css.wrapper>
    );
}

export default SmsScreen;
