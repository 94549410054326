import styled from 'styled-components/macro';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
import * as symbols from '../../assets/symbols/symbols/categoriesIcons';
import {Close} from '../close/Close.css';

export const clear = styled.div`
   width:26px;
`;

export const wrap = styled.div`
    width: max-content;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 42px;
    height:35px;
    padding: 5px;
    padding-inline-end:10px;
    display: flex;
    align-items: center;
    transition: all 300ms;
    :hover{
        background-color: #CBF0FE;
        transition: all 300ms;
    }
    &.toggle{
        background-color: #76D6E8;
        ${Close}{
            display:block ;
        }
    }
    ${Close}{
        display:none;
        margin-top: 1px;
        background-size: 8px;
        width:30px;
    }
`;
export const icon = styled.div`
    width: 16px ;
    height: 16px;
    ${p=>BGImageCenter(symbols[p.icon],'contain')}
    margin-inline-end: 10px;
    margin-inline-start: 5px;
`;

export const text = styled.div`
    font-size: 13px;
`;