import * as css from './DateInput.css';
import {useField} from 'formik';
import MonthPicker from '../../FormikDatePicker/MonthPicker';
import {useState} from 'react';

function MonthInput({
    className,
    name,
    text,
    descText,
    minDate,
    onChange,
    icon,
}) {
    const [, meta] = useField(name);
    const [isOpen, setIsOpen] = useState(false);

    const classes = `${className}  ${
    !!meta.touched && (meta.error ? ' invalid' : ' valid')
  }`;
    const hasIcon=icon!==false;

    return (
        <>
            <css.container className={classes}>
                {hasIcon&&<css.icon className={classes} icon={icon}
                    data-cy={`${name ? name.substring(name.indexOf('.') + 1) : 'default'}Validation`}/>
                }
                <css.wrap className={hasIcon&&'hasIcon'}>
                    <css.inputs onClick={() => setIsOpen(true)}>
                        <css.wrapDate data-cy={`${name ? name.substring(name.indexOf('.')+1): 'default'}Input`}>
                            <css.textDate>{text}</css.textDate>
                            <css.wrapPicker>
                                <MonthPicker
                                    name={name}
                                    minDate={minDate}
                                    onChange={onChange}
                                    props={{open: isOpen, onClickOutside: () => setIsOpen(false)}}
                                />
                            </css.wrapPicker>
                        </css.wrapDate>
                    </css.inputs>
                    <css.text>
                        <css.descText> {descText} </css.descText>
                    </css.text>
                </css.wrap>
            </css.container>
        </>
    );
}

export default MonthInput;
