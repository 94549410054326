import styled from 'styled-components/macro';
import {greenCertificate, unVerified} from '../../../../assets/symbols/symbols/Symbols.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {white} from '../../../../layout/topMenu/accountVerificationMessage/verificationItem/VerificationItem.css';

export const verification=styled.div`
    height: 78px;
    width: 95%;
    background-color: #DD2162;
    margin: 0 auto;
    margin-top: 64px;
    border-radius: 3px;
    display: flex ;
    align-items: center;
    cursor: default;
    ${mobileMedia}{ 
        height: 135px;
        position: relative ;
    }

`;

export const close=styled.div`
    height: 40px;
    width: 40px;
    background-color: #f0e8e854;
    border-radius: 50px;
    margin: 13px;
    margin-right: 8px;
    ${mobileMedia}{ 
        height: 30px;
        width: 30px;
        position: absolute;
        top: -6px;
        ${props => props.theme.rtl ? 'right' :'left'}:-2px
    }
    .close{
        height: 40px;
        width: 40px;
        ${mobileMedia}{ 
            height: 30px;
            width: 30px;
        }
    }
`;

export const symbol=styled.div`
    background-repeat:no-repeat;  
    background-image: url("${unVerified}");
    background-position: bottom;
    background-size: contain;
    width: 131px;
    height: 122px;
    margin-top: -26px;
    ${mobileMedia}{ 
        position: absolute;
        top: -58px;
        left: 26%;
        background-size: 93px;
    }

`;

export const text=styled.div`
    width: ${props => props.theme.rtl ? 57 :55}%;
    margin: 8px;
    ${mobileMedia}{ 
        width: 92%;
        margin: 4%;
        margin-top: 13px;
    }
`;
export const title=styled(white)`
    font-weight: 800;
    ${mobileMedia}{ 
        font-size: 14px;
    }
`;
export const content=styled(white)`
    font-size: ${props => props.theme.rtl ? 12 :11}px;
`;
export const btn = styled.div`
 ${mobileMedia}{ 
    position: absolute;
    bottom: 8px;
    ${props => props.theme.rtl ? 'left' :'right'}: 8px;
}
`;
export const sendAgain = styled.span`
     color: #8fb921;
    border-bottom: 1px solid;
    width: fit-content;
    font-weight: 600;
    margin-${props => props.theme.rtl ? 'right' :'left'}: 5px;
    cursor: pointer;
`;
export const verifySymbol = styled.div`
    background-repeat:no-repeat;  
    background-image: url("${greenCertificate}");
    background-size: contain;
    width: 60px;
    height: 60px;
    margin: 7px 10px;
`;
export const verifiedTitle = styled.div`
    width: 200px;
    position: relative;
    top: 41px;
    font-weight: 800;
    font-size: 10px;
`;

