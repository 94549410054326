import React from 'react';
import {getDate, getHourMinutes} from '../../../../library/dates';
import t from '../../TranslateHe';
import StatusHover from './StatusHover/StatusHover';
import SettingsHover from './SettingsHover/SettingsHover';
import ScheduledChange from './ScheduledChange/ScheduledChange';
import {DEFAULT_TIME_ZONE} from '../../../../globalVariables/locals';

export default {
    format: format,
    formatSmall: formatSmall,
    formatSmallMessages: formatSmallMessages,
};

function format(data, onlyDate, handle) {
    return data.map(function (item) {
        item.insertTime = item.insert_time;
        item.messageSource = item.message_source;
        item.messageType = item.message_type;
        item.sendingStatus = item.sending_status;
        item.sendingTime = item.sending_time;
        item.recipientCount = item.recipient_count;
        return formatOneRow(item._id, item, 'regular', onlyDate, handle);
    });
}

function formatSmall(message) {
    message.insertTime = message.insert_time;
    message.messageSource = message.message_source;
    message.messageType = message.message_type;
    message.sendingStatus = message.sending_status;
    message.sendingTime = message.sending_time;

    let mailingData = {
        _id: message._id,
        Id: message._id,
        msg: {
            title: t('Details and information'),
            rowStatus: message.sendingStatus,
            messagesTitle: t('Messages'),
            messageType: message.messageType,
            subject: message.subject,
            sendingTime: formtDate(message.sendingTimeO, message.scheduled, true),
            rows: [{title:t('From'), 'value':message.sender},],
        },
        messages: [],
    };
    if(message.messageType === 'email'){
        mailingData.msg.rows.push({title:t('Sender email'), value:message.senderemail});
    }
    mailingData.msg.rows.push({title:t('Sending'), 'value': formtDate(message.sendingTimeO, false, true)});
    if(message.scheduled){
        mailingData.msg.rows.push({title:t('Timing'), 'value': formtDate(message.scheduled, false, true)});
    }

    mailingData.msg.rows.push({title:t('Recipients'), 'value': formatRecipient(message)});
    mailingData.msg.rows.push({title:t('Sent'), 'value':message.sent ? message.sent : 0});
    if(message.messageType === 'email'){
        mailingData.msg.rows.push({title:t('Open'), 'value':message.open ? message.open : 0});
    }
    mailingData.msg.rows.push({title:t('Error'), 'value':message.error ? message.error : 0});
    if(message.messageType === 'email'){
        mailingData.msg.rows.push({title:t('Subject'), value:message.subjectO ? message.subjectO : message.subject, allRow:true, bold:true});
    }
    mailingData.msg.rows.push({title:t('Message content'), 'value':message.bodyO ? message.bodyO : message.body, allRow:true});

    return mailingData;
}

function formatSmallMessages(message, data, onlyDate) {
    let messages = data.map(function (item, i) {
        item.insertTime = item.insert_time;
        item.messageSource = item.message_source;
        item.messageType = item.message_type;
        item.sendingStatus = item.sending_status;
        item.sendingTime = item.sending_time;
        let id = message.by === 'query' ? item._id + '_' + i : item._id;
        return formatOneRow(id, item, 'small', onlyDate, '');
    });

    return messages;
}

function formatOneRow(id, rowMaster, mailingType, onlyDate, handle) {
    let row = JSON.parse(JSON.stringify(rowMaster));
    const text = {'PENDING': 5, 'SENT': 1, 'ERROR': 2,};

    let originalStatus = row.sendingStatus;
    let item = row;
    item.original = JSON.parse(JSON.stringify(rowMaster));

    item.id = id;
    item.selected = false;
    item.process = false;
    item.sendingTimeO = row.sendingTime;
    item.sendingTime = formtDate(row.sendingTime, row.scheduled, onlyDate);
    item.status = text[originalStatus];
    item.sendingStatus = formatSendingStatus(row);
    item.sendingAmount = formatSendingAmount(row);
    item.mailingStatusHover = <StatusHover type={mailingType} item={item} status={originalStatus}/>;

    if(mailingType === 'regular'){
        item.body = row.body?.replace(/<[^>]*>?/gm, '');
        item.recipientCount = formatRecipient(row);
    }
    if(row.scheduled && (new Date(row.scheduled)).getTime() > (new Date()).getTime()){
        item.scheduled = new Date(row.scheduled);
        if(mailingType === 'regular'){
            item.scheduledChange = <ScheduledChange scheduled={row.scheduled} handle={handle} id={item.id}/>;
            item.mailingSettingHover = <SettingsHover id={id} handle={handle}/>;
        }
    } else {
        delete item.scheduled;
    }

    if(row._id.indexOf('orderComplete_') !== -1){
        delete item.sendingTime;
        item.bodyO = item.body;
        item.subjectO = item.subject;

        if(row.messageType === 'sms'){
            item.body = t('Order completed');
        } else {
            delete item.body;
            item.subject = t('Order completed');
        }
    }

    return item;
}

function formtDate(date, scheduled, onlyDate) {
    let text, mailingDate;
    if(scheduled && new Date(scheduled) > new Date(date)){
        if(new Date(scheduled) > new Date()){
            text = t('Will send in') + ' ';
        } else {
            text = t('Sent in') + ' ';
        }
        mailingDate = Math.floor(new Date(scheduled).getTime() / 1000);
    } else {
        text = t('Sent on') + ' ';
        mailingDate = Math.floor(new Date(date).getTime() / 1000);
    }
    if(onlyDate){
        text = getDate(mailingDate, DEFAULT_TIME_ZONE) + ' ' + getHourMinutes(mailingDate, DEFAULT_TIME_ZONE);
    } else {
        text += getDate(mailingDate, DEFAULT_TIME_ZONE) + ' ' + getHourMinutes(mailingDate, DEFAULT_TIME_ZONE);
    }
    return text;
}

function formatSendingStatus(item) {
    const sent = item.sent ? item.sent : 0;
    const all = formatRecipient(item);
    const open = item.open ? item.open : 0;
    let text = 1;
    if(all <= open){
        text = 3;
    } else if(all <= sent){
        text = 2;
    }
    return text;
}

function formatSendingAmount(item) {
    const sent = item.sent ? item.sent : 0;
    const all = formatRecipient(item);
    const text = all + ' / ' + sent;
    return text;
}

function formatRecipient(item) {
    let recipient = 0;
    if(item.recipientCount){
        recipient = item.recipientCount;
    } else {
        recipient = typeof item.recipient == 'string' ? 1 : item.recipient.length;
    }
    if(!recipient){
        recipient = '-';
    }
    return recipient;
}
