import {useTranslation} from 'react-i18next';
import Input from '../../../../../component/input/Input';
import * as css from './ExtensionsPop.css';
import {useFormikContext} from 'formik';
import PopOnPop from '../../../../../component/newPopup/popOnPop/PopOnPop';
import {addBtn, closeBtn} from '../../../../../functions/globalBtns';
import Button from '../../../../../component/button/button/Button';
import * as Yup from 'yup';
import InputToggle from '../../../../../component/input/inputToggle/InputToggle';
import FormContext from '../../../../components/formContext/FormContext';
import NumberInput from '../../../../../component/input/NumberInput';
import {yupStringMin} from '../../../../../functions/validationsYup';

function Bottom({values, close, onSubmit, onDelete}) {
    const form = useFormikContext();
    const {t} = useTranslation('ticket');

    return (
        <css.bottomPop>
            <Button BtnProps={closeBtn(t, close)} dataCyName = "close">  </Button>
            <Button BtnProps={addBtn(t,  () => {
                const touchedFields = Object.keys(form.values).reduce((val, key) => {
                    val[key] = true;
                    return val;
                }, {});
            
                form.setTouched(touchedFields);
                form.validateForm().then(errors => {
                    if(Object.keys(errors).length) {
                        return;
                    }
                    onSubmit({
                        ...(values.xid ? {xid: values.xid} : {}),
                        title: values.title,
                        price: values.price,
                        amount: values.amount,
                        limit: values.limit,
                        active: 1,
                        limitByTickets: values.limitByTickets
                    });
                    close();
                });
            }
            )} dataCyName = "add"/>
            <css.deleteBtn onClick={() => {
                onDelete();
                close();
            }
            }data-cy = "deleteBtn" />
        </css.bottomPop>);
}

function ExtensionsPop({show, close, onSubmit, item, onDelete}) {

    const {t} = useTranslation('ticket');

    const initialPopOnPop = item ? item : {
        title: '',
        price: '',
        amount: '',
        limit: 5,
        active: '',
        limitByTickets: 1,
    };

    const validationSchema = Yup.object().shape({
        title: yupStringMin().max(50).required(),
        price: Yup.number().required(),
        amount: Yup.number().required(),
        limit: Yup.number().required()
    });

    return (
        <>
            <PopOnPop dataCy='extensions' image={'groupColor'} title={t('extras')} showPopOnPop={{show: show, setShow: close}}>
                <>
                    <FormContext
                        initialValues={initialPopOnPop}
                        validationSchema={validationSchema}
                        // enableReinitialize={!dataWasLoaded}
                        // onSubmit={values => { onSubmitForm(values) }}
                        onSubmit={() => { }}
                        render={({values}) => (
                            <>
                                <css.popOnPopContent>
                                    <Input dataCy={'priceTitle'} name={'title'} text={t('extensionName')} description={t('extensionNameDesc')} />
                                    <NumberInput name={'price'} text={t('price')} description={t('priceDesc')} />
                                    <NumberInput name={'amount'} text={t('amountField')} description={t('amountDesc')} />
                                    <InputToggle name='limit'
                                        toggleProps={{toggleText: t('limitAsTicket'), toggleName: 'limitByTickets'}}
                                        inputProps={{text: t('limitField'), description: t('maxItems')}} />
                                </css.popOnPopContent>
                                <Bottom values={values} close={close} onSubmit={onSubmit} onDelete={onDelete} />
                            </>
                        )} />
                </>
            </PopOnPop>
        </>
    );

}

export default ExtensionsPop;
