import {ts2date, ts2time} from './dateFunctions_old_to_be_deprecated';
function paramsParse(content, event, user, parameters) {
    const params = {
        eventTitle: event?event.title:'שם האירוע',
        nameFirst: user.nameFirst,
        nameLast: user.nameLast,
        timeOpenstartDate: event ? ts2date(event.timeStart):'תאריך התחלה',
        timeOpenstartTime: event ? ts2time(event.timeStart):'שעת התחלה',
        timeOpenstartDateHeb: 'ט"ו אב תשפ"א',
        location: parameters['event.location'],
        ticketLink: 'tic.li/ticket',
        ticketLinkPrint: 'tic.li/ticket?print',
        ticketName: parameters['ticket.ticketName'],
        invoiceLink: 'מצורף קישור לקבלה. tic.li/invoice',
        eventLink: 'https://givechak.co.il/18737/he',
        donation : 50,
        currencySymbol: parameters['event.currencySymbol'],
        eventCurrency: parameters['event.eventCurrency'],
        producerName: user.nameFirst+' '+user.nameLast,
        producerPhone: user.phone,
        waze: parameters['event.waze'],
        ticketText: parameters['ticket.ticketText'],
        groupName: parameters['groups.groupName'],
        //crowd
        'crowd.title':parameters['crowd.title'],
        address:'שטנר 3 ',
        city:'ירושלים',
        birthDate:'21/11/1993',
        gender:'נקבה',
        tz:303035224,
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
    };
    
    for(var key in params) {
        content=content.replace(new RegExp('\\['+key+'\\]', 'g'),params[key]);
    } 

    return content;
}
export default paramsParse;