import './App.css';
import ThemeProvider from '../src/theme/ThemeProvider';
import Routs from './Routes';

import useEvent401Exception from './hooks/useEvent401Exception';

import useGlassixWidget from './hooks/useGlassixWidget';
function App() {
    useEvent401Exception();
    useGlassixWidget();
    return (
        <ThemeProvider>
            <Routs/>
        </ThemeProvider>
    );
}

export default App;
