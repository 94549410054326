import * as css from './NoPixelsScreen.css';
import {useTranslation} from 'react-i18next';
import NoPixelSelection from './NoPixelSelection';
import {Fragment} from 'react';
import {useState,useEffect,useRef} from 'react';

export const PIXEL_EXIT_DURATIOM=50;

function NoPixelsScreen({pixelTypes}) {
    const {t}=useTranslation('publish');
    
    const [currentIndex,setCurrentIndex]=useState(0);
    const [isWait,setIsWait]=useState(false);

    const interval=useRef();

    useEffect(()=>{
        interval.current=setInterval(() => {
            setCurrentIndex(prev=>prev+1);
        }, 2);
        return ()=>clearInterval(interval.current);
    },[]);

    useEffect(()=>{
        if(currentIndex>=pixelTypes.length)
            clearInterval(interval.current);
    },[currentIndex]);

    useEffect(()=>{
        if(isWait){
            setCurrentIndex(pixelTypes.length);
            interval.current =setInterval(() => {
                setCurrentIndex(prev=>prev-1);
            }, PIXEL_EXIT_DURATIOM);            
        }
    },[isWait]);

    return (
        <css.container data-cy='NoPixelsScreen'>
            <css.text>
                <css.title>
                    {t('noPixelsTitle')}
                </css.title>
                {t('noPixelsText')}
            </css.text>
            <css.line/>
            <css.pixels>
                {pixelTypes.map((pixel,index)=>
                    <Fragment key={pixel}>
                        <NoPixelSelection pixelKey={pixel} className={index<=currentIndex?'show':''} setIsWait={setIsWait} pixelTypes={pixelTypes}/>
                    </Fragment>
                )}
            </css.pixels>
        </css.container>
    );
}

export default NoPixelsScreen;
