import * as Yup from 'yup';
import validator from 'validator';
import i18next from 'i18next';

export default function yupMethods(){
    Yup.addMethod(Yup.mixed, 'decimal', function(message) {
        return this.test('decimal', message, function(value) {
            return !value || (value && validator.isDecimal(value));
        });
    });
}

export const yupStringMin = (min = 2, message = i18next.t('global:errorCharacters')) => {
    return Yup.string().min(min, message).matches(/(\S.*\S)/,  i18next.t('global:InvalidTemplate'));
};
