import styled from 'styled-components/macro';
import {remove} from '../../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter} from '../../../../../globalVariables/GlobalVariables';

export const bottomPop = styled.div`
   height: 70px;
   ${dFlexCenter}
`;

export const popOnPopContent=styled.div`
    background:#fbfbfb ;
    padding: 40px 0;
    padding-inline-end:30px;
    height: calc(100% - 179px);
    border-bottom: 1px solid #EEE;
`;

export const deleteBtn = styled.div` 
  ${ BGImageCenter(remove, '12px') }
    width: 20px;
    height: 20px;
    position: absolute;
    inset-inline-start: 20px;
    cursor: pointer;
`;
