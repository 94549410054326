import React from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './emailScreen/EmailScreen.css';
import {clock} from '../../assets/symbols/symbols/Symbols.css';
import {calendar, location} from '../../assets/symbols/symbols/brandingIcons';
import {useSelector} from 'react-redux';
import EyeHideSection from '../components/eyeHideSection/EyeHideSection';
import {getDaysDifference} from '../../library/dates';
import {useEffect, useState} from 'react';

function PartEmailDetail({value,sectionName,icon}) {
    if(!value)
        return <></>;
    return (
        <EyeHideSection sectionName={sectionName}>
            <css.partDetail data-cy="partEmailDetail">
                <css.icon icon={icon}/>
                <css.partText>{value}</css.partText>
            </css.partDetail>
        </EyeHideSection>
    );
}

function EmailDetails() {
    const {t} = useTranslation('newEvent');   
    const {location: locationName, currentLang} = useSelector(s => s.branding ?? {});
    const date = useSelector(s => s.branding.data[currentLang]?.date ?? {});
    const details = [];
    let startLongDate = null;
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        fetch(`/locale/${currentLang}/branding.json`)
            .then((response) => response.json())
            .then((data) => {
                setTranslations(data);
            })
            .catch((error) => {
                console.error('Error fetching translation files', error);
            });
    }, [currentLang]);
  
    if(Object.keys(date||{}).length){
        const isLongDate=getDaysDifference(date.timeStart,date.timeEnd);
        if(isLongDate){
            const getDateSection=key=><>
                <css.boldText>{t(translations[key.toLowerCase()])}: {date[`${key.toLowerCase()}Day`]}</css.boldText>
                <css.text>{date[`hebrew${key}Date`]}</css.text>
                <css.text>{date[`foreign${key}Date`]}<b> {date[`${key.toLowerCase()}Time`]}</b></css.text>
                {key==='Start'&&date.openTime&&<css.boldText>{translations.openingGates} {date.openTime}</css.boldText>}
            </>;
            const startValue=getDateSection('Start');
            startLongDate={value:startValue,icon:calendar, sectionName:'date'};
            const endValue=getDateSection('End');
            details.push({value:endValue,icon:calendar, sectionName:'date'});
        }
        else {
            const dateValue=<>
                <css.boldText>{date.day}</css.boldText>
                <css.text>{date.hebrewStartDate || date.hebrewDate}</css.text>
                <css.text>{date.foreignStartDate || date.foreignDate}</css.text>
            </>;
            details.push({value:dateValue,icon:calendar, sectionName:'date'});

            const timeValue=<>
                {date.openTime&&<css.text>{translations.openingGates} {date.openTime}</css.text>}
                <css.text>{date.endTime} {date.endTime&&'-'} {date.startTime}</css.text>
            </>;
            details.push({value:timeValue,icon:clock, sectionName:'time'});
        }
    }

    if(locationName)
        details.push({value:locationName,icon:location, sectionName:'location'});
    if(!details.length)
        return <></>;
    return (
        <css.details data-cy="emailDetails" className={startLongDate?'smallText':''}>
            {startLongDate&&<css.hideEye><PartEmailDetail  {...startLongDate}/></css.hideEye>}
            {details.map(detail=> (
                <PartEmailDetail key={detail.sectionName} {...detail}/>
            ))}
        </css.details>
    );
}

export default EmailDetails;
