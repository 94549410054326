import {useTranslation} from 'react-i18next';
import MobilePopup from '../../../component/newPopup/mobilePopup/MobilePopup';
import * as css from './ClearingFeeMessagePop.css';
import Btn from '../../../component/button/btn/Btn';

function ClearingFeeMessagePop({show,onClick}) {

    const {t} = useTranslation('layout');

    return (
        <css.wrapper>
            <MobilePopup showClass={show.showClass} close={show.close}>
                <css.container>
                    {t('clearingFeeMessage')}
                    <Btn onClick={onClick}>
                        {t('thankYou')}
                    </Btn>
                </css.container>
            </MobilePopup>
        </css.wrapper>
    );
}

export default ClearingFeeMessagePop;