import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter, ellipsis, mobileMedia} from '../../../../globalVariables/GlobalVariables';
// import { placeForDrag } from "../../newRow/Row.css";
import * as ticket from '../ticketRow/TicketRow.css';
import {toggle} from '../../../../component/modeBtn/ModeBtn.css';

export const container = styled.div`
    max-width:calc(100vw - 50px) ;
    width:450px ;
    height: 115px;
    display:flex ;
    border-radius: 5px;
     position: relative;
    &.improved{
        width:314px ;
        box-shadow: 0 3px 6px #00000029;
        //margin: 10px;
        ${mobileMedia}{
          width: 340px;
          max-width:90%;
        }
    }
`;
export const mobileRow = styled.div`
    width:100%;
    height: 100%;
    display:flex ;  
    transition:all 300ms ;
    &.isDrag{
        width:calc(100% - 30px) ;
    }

`;

export const placeDrag = styled.div`
    &.isDrag{
        width:30px ;
        background-color:transparent ;
    }
`;
export const iconWrap = styled.div`
    overflow:visible;
    width:70px;
    background-color: ${p => p.color};
    position:relative ;
    display:flex ;
    align-items:center ;
    border-radius:${p => p.theme.rtl ? '0 5px 5px 0' : '5px 0 0 5px'};
    .circleWrap{
        width:50px ;
        height:50px ;
        inset-inline-end:-25px;
    }
    .ticketProgress_circle{
        width:35px ;
        height:35px ;
    }


`;
export const icon = styled.div`
    width:60px;
    background-color: ${p => p.color};
    ${p => BGImageCenter(p.icon, '25px')}
    background-position: bottom right;
    padding-bottom: 15px;
    padding-right: 10px;
    height:100% ;
    background-origin: content-box;
    margin-inline:5px
`;

export const image = styled.div`
    border-radius:${p => p.theme.rtl ? '0 5px 5px 0' : '5px 0 0 5px'};
    width:70px;
    height:70px ;
    ${p => BGImageCenter(p.image, '25px')}
    position: absolute;
    top:0;
    background-size:cover;
    position:absolute ;
    inset-inline-start:0;
`;

export const left = styled.div`
    width:calc(100% - 70px);

`;
export const part = styled.div`
    height:50% ;
    display:flex ;
    width:100% ;
`;

export const topRight = styled.div`
    width:calc(100% - 85px) ;
    padding-top:15px;
    padding-inline-start:30px;
    overflow:hidden;
    >div{
        ${ellipsis}
    }
`;
export const topLeft = styled.div`
    width:85px ;
    ${dFlexCenter}
    ${toggle}{
      :not(.proposal)::after{
        ${mobileMedia}{
          box-shadow:unset !important;
        }
      }
    }

`;

export const groups = styled.div`
    width: 100px;
    padding-top:20px ;
    padding-inline-start:20px;
`;

export const details = styled.div`
    width:calc(100% - 100px);
    display:flex;
    flex-direction:column ;
    align-items:flex-end ;
    justify-content:center ;
    padding-inline-end:15px;
    padding-bottom:10px ;
`;

export const price = styled.div`
    font-size:30px ;
    font-weight:500 ;
    >span{
        font-size:16px ;
        font-weight:400 ;
    }
`;

export const amount = styled.div`
    display:flex;
`;
export const boldText = ticket.boldText;
export const text = ticket.text;
