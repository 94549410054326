export function calculateGrid(grid, mini) {
    let gridCalc = grid.filter(function(item) {
        return !(mini && !item.mini);
    }).map(function(item) { return item; });

    let fixedWidth = 0;
    let elementFixedWidth = 0;
    let itemHeaderWidth = 0;
    const headerWidth = gridCalc.map(function (item) {
        if(item.type === 'status' && mini){
            itemHeaderWidth = 40;
        } else {
            itemHeaderWidth = (item.width ? parseInt(item.width) : 0);
        }
        fixedWidth += itemHeaderWidth;
        elementFixedWidth += itemHeaderWidth ? 1 : 0;
        return itemHeaderWidth;
    });

    const percentage = 100 / (gridCalc.length - elementFixedWidth);
    const lessPercentage = fixedWidth / (gridCalc.length - elementFixedWidth);

    let gridTemplate = '';
    headerWidth.forEach(function (item) {
        gridTemplate += item ? item+'px ' : 'calc('+percentage+'% - '+lessPercentage+'px) ';
    });

    return gridTemplate;
}

export function foramTableContent(content, mini) {
    let contentFormat = content.filter(function(item) {
        return !(mini && !item.mini);
    }).map(function(item) { return item; });

    return contentFormat;
}