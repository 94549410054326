import TooltipAbsolute from '../tooltipAbsolute/TooltipAbsolute';

export function TooltipAbsoluteSum({text,amount}) {
    const showTooltip = amount && (/k|M/.test(amount));
    if(text === '' || !showTooltip)
        return <></>;
    return (
        <TooltipAbsolute text={text}/>
    );
}
export default TooltipAbsoluteSum;
