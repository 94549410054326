function replaceParameters(content, subject, area, mailOrSms, spansArray, crowdField) {
    
    let newContent = content.innerHTML;
    let newSubject = subject.innerHTML;
    let breakLine=mailOrSms=='sms'?'\n':'</br>';
    
    for(let i = 0; i < spansArray.length; i++) {
        if(newContent)
            newContent = newContent.replaceAll(spansArray[i].elem, spansArray[i].parse).replaceAll('&nbsp;', ' ').replaceAll('<div>', breakLine).replaceAll('</div>', '');
        if(newSubject)
            newSubject = newSubject.replaceAll(spansArray[i].elem, spansArray[i].parse).replaceAll('&nbsp;', ' ').replaceAll('<div>', breakLine).replaceAll('</div>', '');
    }
    if(area=='crowd' && crowdField ){
        for(let i = 0; i < crowdField.length; i++) {
            if(newContent)
                newContent = newContent.replaceAll('['+crowdField[i].title+']', '[crowdF'+crowdField[i].cfid+']');
            if(newSubject)
                newSubject = newSubject.replaceAll('['+crowdField[i].title+']', '[crowdF'+crowdField[i].cfid+']');
        }
        
    }
    return ({newContent:newContent, newSubject:newSubject});
}
export default replaceParameters;