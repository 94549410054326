import React, {useState} from 'react';
import './TableDetails.css';
import OutsideAlerter from '../../OutsideAlerter/OutsideAlerter';
import TableStatus from '../TableStatus/TableStatus';

const RtlCss = React.lazy(() => import('./TableDetails-rtl'));

function TableDetails(props) {
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [detailsScroll, setDetailsScroll] = useState(false);
    const details = props.data.msg;

    const handleSearch = (event) => {
        if(event === 'close'){
            if(search){
                props.handleEvent('showHideDetails', {id:props.data.Id});
            }
            setSearch('');
        }
        if(event.which === 13 && search){
            props.handleEvent('searchMessage', {id:props.data.Id, search:search});
        }
    };
    const handleSearchOpenClose = (type) => {
        if(type === 'outside'){
            setSearchOpen(false);
            if(searchOpen){
                props.handleEvent('showHideDetails', {id:props.data.Id});
            }
        } else if(type === 'inside'){
            setSearchOpen(true);
        }
    };

    const handleDetailsScroll = (event) => {
        if(event.target.clientHeight + 100 < event.target.scrollHeight){
            if(event.target.scrollTop > 2){
                setDetailsScroll(true);
            } else {
                setDetailsScroll(false);
            }
        }
    };

    return (
        <>
            {(props.lang === 'he') && <RtlCss />}
            <div className="table-row-all">
                <div className="table-row-all-title">
                    <div className="table-row-all-title-close" image="close" imageColor="474747" onClick={()=>{props.handleEvent('showHideDetails', {id:-1});}} />
                    <div className="table-row-all-title-title">{details.messagesTitle}</div>
                    <div className="table-row-all-title-action">
                        <OutsideAlerter handle={handleSearchOpenClose}>
                            <div className={'table-row-action' + (searchOpen ? ' opened' : '')} type="search" onClick={() => handleSearchOpenClose('inside')}>
                                <div image={(search ? 'close' : 'search')} imageColor="a7a6a6" onClick={() => handleSearch('close')} />
                                <input className="table-row-action" type="searchInput" value={search} onChange={(e) => {setSearch(e.target.value);}} onKeyUp={handleSearch}/>
                            </div>
                        </OutsideAlerter>
                        <div className="table-row-action" type="export" image="export" imageColor="a7a6a6" />
                        <div className="table-row-action" type="settings" image="settings" imageColor="a7a6a6" />
                    </div>
                </div>

                <div className="table-row-details">
                    <div className={'table-row-details-details' + (detailsScroll ? ' isScroll' : '')}>
                        <div className="table-row-title">
                            <div className="table-row-title-main">
                                <div className="table-row-title-image" image={props.data.msg.messageType} imageColor="474747" />
                                <div className="table-row-title-text">{props.data.msg.subject}</div>
                            </div>
                            <div className="table-row-title-bottom">
                                <span>{props.data.msg.sendingTime}</span>
                            </div>
                        </div>

                        <div className="col-details-title"><span>{details.title}</span><TableStatus status={details.rowStatus} mini={false}/></div>
                        <div className="table-details-rows Scroll" onScroll={handleDetailsScroll}>
                            {details.rows.map(function (item, i) {
                                if(item.allRow){
                                    return <div key={i} className={'col-details-row-value' + (item.bold ? ' bold' : '')}>{item.value.replace(/ +(?= )/g,'').replace(/\n\s*\n/g, '\n')}</div>;
                                } else {
                                    return <div key={i} className="col-details-row">
                                        <span className="col-details-row-title">{item.title}</span>
                                        <span className="col-details-row-value">{item.value}</span>
                                    </div>;
                                }
                            })}
                        </div>
                    </div>
                    <div className="table-row-details-all">
                        <div className="t-r-d-a-all Scroll">
                            {props.data.mailingAll}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TableDetails;