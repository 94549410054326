import styled from 'styled-components/macro';
import {ItemText} from '../item/Item.css';
import {ListTitle,List} from '../list/List.css';
import {dataItemwrapcss} from '../dataItem/DataItem.css';
import {ellipsis} from '../../globalVariables/GlobalVariables';

const DEFAULT_COLOR='#76D6E8';

export const wrap = styled.div`
  ${dataItemwrapcss}
  margin-inline-start:5px;
  height:25px;
  cursor: pointer;

`;
export const icon = styled.div`
    cursor: pointer;
    width: 17px ;
    height: 17px;
    border-radius: 50%;
    background-color: ${DEFAULT_COLOR};
    margin-inline-end:10px;

`;
export const wrapList = styled.div`
position: relative;
${ItemText}{
color:black;
}
${ListTitle}{
    color: ${DEFAULT_COLOR};
}
${List}{
   inset-inline-end:0;
}
`;

export const text = styled.div`
    font-size: 12px;
  max-width: 80px;
    ${ellipsis}
`;
export const textAutomaticData = styled.div`
    font-size: 12px;
    margin-inline-start: 4px;
`;