import * as css from './BasicItem.css';
import React from 'react';
import {useField} from 'formik';
import ErrorIcon from '../errorIcon/ErrorIcon';
import {Else, If} from 'react-if';

function BasicItem({color, children, icon, onClick = () => { }, number,name}) {
    const [, meta] = useField(name);

    return (
        <css.wrap color={color} onClick={onClick}>
            <css.wrapIcon>
                <If condition={name && meta.touched &&meta.error}>
                    <ErrorIcon message={meta.error} dataCyName={`${name ? name.substring(name.indexOf(".")+1): "default"}ErrorIcon`}/>
                    <Else>
                        <css.icon className='icon' icon={icon} number={number} data-cy={`${name ? name.substring(name.indexOf(".")+1): "default"}Icon`} />
                    </Else>
                </If>
            </css.wrapIcon>
            {children}
        </css.wrap >
    );
}
export default BasicItem;
