import * as css from './PopUpBubble.css';
import Close from '../close/Close';

function PopUpBubble({color, open,close=()=>{},showClass,children,isText=false}) {
    
    return (
        <css.container onMouseOver={open} onMouseLeave={close} className={showClass} color={color} isText={isText}>
            <Close onClick={close}/>
            {children}
        </css.container> 
    );
}
export default PopUpBubble;
