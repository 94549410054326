import * as css from './CourseGuides.css';
import UserImage from '../../../../../layout/topMenu/layoutUserProfile/userImage/UserImage';
import Tooltip from '../../../../../component/tooltip/Tooltip';
import List from '../../../../../component/list/List';
import {useTranslation} from 'react-i18next';
import useShow from '../../../../../hooks/useShow';
import {useEffect} from 'react';

function CourseGuides({guides,openList,guidesFilter}) {
    const {t} = useTranslation('attendance');
    const {open, close, isOpen, showClass} = useShow();
    let images = [...guides||[]];
    images =images.length>3 ? images.splice(images.length - 3):images;
    const setGuideFilterFunction = (uid) => {
        if(guidesFilter)
            uid!==guidesFilter?.guidesFilter? guidesFilter.setGuidesFilter(uid): guidesFilter?.setGuidesFilter(0);
    };
    useEffect(()=>{
        if(guidesFilter?.guidesFilter) {
            guides?.find((elem, index) => {elem.uid === guidesFilter?.guidesFilter && guides.push(guides.splice(index, 1)[0]);});
        }
    },[guidesFilter?.guidesFilter]);

    const list = guides?[{key: 0, text: t('filterGuidesTitle'), onClick: ()=>guidesFilter?.setGuidesFilter(0),},...guides.map(guide => ({
        key: guide.uid,
        text: guide.nameFirst,
        onClick: ()=>setGuideFilterFunction(guide.uid),
        rightChildren:<UserImage  path={guide.picture} />
    }))]:{};
    return (

        <css.images>
            {guides?.length > 3 && openList&& <css.openList    onClick={()=>open()}>
                {isOpen&&<List  close={close}  showClass={showClass}  list={list} />}
            </css.openList>}

            {images.map((i) => <Tooltip key={i.uid} text={`${i.nameFirst} ${i.nameLast}`} >
                <css.image className={i.uid===guidesFilter?.guidesFilter&&'selected'} onClick={()=>openList&&setGuideFilterFunction(i.uid)}><UserImage path={i.picture} /></css.image>
            </Tooltip>)}
        </css.images>

    );
}
export default CourseGuides;
