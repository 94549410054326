import * as css from './Accordion.css';
import {useState} from 'react';

function Accordion({title, isClose = false, isAlwaysOpen = false,children ,dataCyTitle,forwardRef}) {
    const [isOpen, setIsOpen] = useState(!isClose);
    const [classes, setClasses] = useState(isClose ? '' : ' show overflow');

    const arrowClick = () => {
        if(isOpen) {
            setClasses('');
            setTimeout(() => {
                setIsOpen(false);
            }, 400);

        }
        else {
            setIsOpen(true);
            setTimeout(() => {
                setClasses(' show');
            }, 0);
            setTimeout(() => {
                setClasses(' show overflow');
            }, 400);

        }
    };

    return (<>
        <css.Accordion data-cy ={dataCyTitle} {...(forwardRef ? {ref :forwardRef} : {})}>
            <css.Header className='accordionHeader'>
                <css.title onClick={arrowClick} className={classes + ' accordionArrow'}> {title}</css.title>
            </css.Header>
            {isAlwaysOpen ? <css.Inner className={classes + ' accordion'}>{children}</css.Inner> :
                isOpen && <css.Inner className={classes + ' accordion'}>{children}</css.Inner>}
        </css.Accordion>

    </>
    );
}

export default Accordion;
