import styled from 'styled-components/macro';
import {header} from '../../../../component/rightHeader/RightHeader.css';
import * as scroll from '../../../../component/scrollbar/Scrollbar.css';

export const pfTable=styled(scroll.Scrollbar)`
    height:calc(100% - 140px);
    padding: 0 15px;
    .stateTable{
        @media screen and (max-width: 1000px){ 
            padding:0;
            font-size:0;
            width:15px;
            height:15px
        }
        ::after{
            @media screen and (max-width: 1000px){ 
                top:1.5px;
                right:2px;
            }
        }
        &.active{
            @media screen and (max-width: 1000px){ 
                right:2px;
                 padding:0;
            }
             ::after{
                  @media screen and (max-width: 1000px){ 
                    right:2px;
                  }
                
             }
        }
    }
    &.dragStatus .row div{
       @media screen and (max-width: 1000px){
            /* pointer-events:none; */
       } 
    }
    ${header}{
       display:none;
    }
    .tableContainer{
        overflow:unset ;
    }
`;
export const group=styled.div`
`;