import React from 'react';
import * as css from './ColorAndImageTitle.css';

function ColorAndImageTitle({color, image, text}) {

    return (
        <css.popUpImage color={color}>
            <css.title>
                {text}
            </css.title>
            <css.image image={image}/>
        </css.popUpImage>
    );
}

export default ColorAndImageTitle;
