import {useTranslation} from 'react-i18next';
import {useEffect, useRef} from 'react';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {updatePackageVerification} from '../../../../redux/account/accountActions';
import {verifyBankDetails} from '../../../../library/verifyBank';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import FormContext from '../../../components/formContext/FormContext';
import BankVerificationFormDetails from './BankVerificationFormDetails';
import setGlobalState from '../../../../redux/setGlobalState';
import BankAdditionalVerification
    from '../bankAdditionalVerification/BankAdditionalVerification';
import useShow from '../../../../hooks/useShow';
import {POPUP_DURATION} from '../../../../globalVariables/GlobalVariables';

function BankVerificationForm() {
    const {t} = useTranslation('package');
    const show=useShow(POPUP_DURATION);
    const bank=useSelector(s => s.account?.bank||[]);

    const SignUpSchema = Yup.object().shape(
        {
            billingId: Yup.number().min(2).required(),
            billingName: Yup.string().matches(/^[^.\s]/).min(2).max(100).required().test(
                'no-double-quotes',
                t('accountOwnerValidationMsg'),
                value => !value?.includes('"')
            ),
            bankTransferOnBehalf: Yup.string().matches(/^[^.\s]/).min(2).max(100).required(),
            bankBranch: Yup.number().min(0).required(),
            bankAccount: Yup.number().min(2).required(),
        }
    );
    const timer=useRef();
    const onSubmit = async (_changedValues,values) => {
        if(['bankNumber','bankBranch','bankAccount'].some(key=>values.hasOwnProperty(key)))
            if(!verifyBankDetails(values.bankNumber, values.bankBranch, values.bankAccount)){
                bounceMessage(t('incorrectBankDetails'),'warning');
                return;
            }

        await updatePackageVerification(values).then(()=>{
            timer.current = setTimeout(()=>{
                setGlobalState('account.updatePackageVerificationXHR',null);
            },1000);
            show.open();

        });
    };
    useEffect(() => () => clearTimeout(timer.current), []);

    const formRef = useRef();

    const onSubmitForm = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <>
            <FormContext
                innerRef={formRef}
                initialValues={
                    {
                        billingId: bank?.billingId?bank.billingId:'',
                        billingName: bank?.billingName?bank.billingName:'',
                        bankNumber: bank?.bankNumber,
                        bankTransferOnBehalf: bank?.bankTransferOnBehalf?bank.bankTransferOnBehalf:'',
                        bankBranch: bank?.bankBranch?bank.bankBranch:'',
                        bankAccount: bank?.bankAccount?bank.bankAccount:'',
                        bankVerificationFile: bank && bank.bankVerificationFile?JSON.parse(bank.bankVerificationFile):{}
                    }
                }
                validationSchema={SignUpSchema}
                enableReinitialize

                onSubmit={onSubmit}
            >

                <BankVerificationFormDetails setBankKey={()=>{}} onSubmitForm={onSubmitForm} />

            </FormContext>
            {!!show.isOpen&&<BankAdditionalVerification   openPop={show}/>}
        </>
    );
}

export default BankVerificationForm;