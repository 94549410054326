import * as css from './EmailScreen.css';
import {useSelector} from 'react-redux';
import EmailImage from '../EmailImage';
import EmailDetails from '../EmailDetails';
import EmailButtons from '../emailButtons/EmailButtons';
import EmailArtist from '../emailArtist/EmailArtist';
import EditorWrapper from '../../../component/textEditor/editorWrapper/EditorWrapper';
import EyeHideSection from '../../components/eyeHideSection/EyeHideSection';
import {updateDesignEmail, updateEmailStatus} from '../../../redux/branding/brandingActions';
import EmailSubjectTextEditor from '../emailSubjectTextEditor/EmailSubjectTextEditor';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import ImageHeader from '../imageHeader/ImageHeader';
import {useTranslation} from 'react-i18next';
import CourseSection from '../courseBranding/courseSection/CourseSection';
import ParticipantSection from '../courseBranding/participantSection/ParticipantSection';

function EmailScreen() {
    const {currentLang,data,getBrandingData} = useSelector(s => s.branding??{});
    const {title,type}=useSelector(s=>s.event);
    const {emailContent, emailTicket, emailDisplayQr, emailDisplayProducer}=data?.[currentLang]??{};
    const {t} = useTranslation('branding');
    const dependencies=[currentLang,getBrandingData[`${currentLang}XHR`]];

    const updateStatus = async () => {
        await  updateEmailStatus('emailTicket', emailTicket===1?0:1);
    };
    const updateDisplayQr = async () => {
        await updateDesignEmail({'emailDisplayQr': emailDisplayQr===1?0:1});
    };
    const updateDisplayProducer = async () => {
        await updateDesignEmail({'emailDisplayProducer': emailDisplayProducer ===1?0:1});
    };
    const producers=<>
        <css.wrapModeBtn>
            <ModeBtn className={'small'} status={emailDisplayProducer} click={updateDisplayProducer} dataCyName={'displayProducer'} text={t('displayProducer')}/>
            <ModeBtn className={'small'} status={emailDisplayQr} click={updateDisplayQr} dataCyName={'displayQr'} text={t('addPDF')}/>
        </css.wrapModeBtn></>;

    const compsType={
        course:{last:<><CourseSection/><ParticipantSection/></>},
        ticket:{last:producers,buttons:<EmailButtons/>,afterTitle:<EmailDetails />}
    };

    const comps=compsType[type]||compsType.ticket;
    return (   
        <css.wrapper data-cy="emailScreen">
            <css.modeBtn>
                <ModeBtn className={'small'} status={emailTicket} click={updateStatus} dataCyName={'emailScreen'}/>
            </css.modeBtn>
            <EmailSubjectTextEditor name={'emailSubject'}  lang={currentLang} dependencies={dependencies}/>
            <css.globalStyle/>
            <ImageHeader/>
            <css.container>
                <EmailImage name='imageMain'/>
                <EyeHideSection sectionName={'title'}>
                    <css.title>{title}</css.title>
                </EyeHideSection>
                {comps.afterTitle||''}
                <css.editor>
                    <EditorWrapper defaultText={emailContent} events={{onSave:(content)=>updateDesignEmail({emailContent:content})}} showToolbar   lang={currentLang} dependencies={dependencies}/>
                </css.editor>
                {comps.buttons||''}
            </css.container>
            <EmailArtist />
            {comps.last||''}
            {/* <SendMeTicket/> */}
        </css.wrapper>
    );
}

export default EmailScreen;
