import {useState,useEffect} from 'react';

export default function useOpen(duration=300){
    const [isOpen,setIsOpen]=useState(true);

    useEffect(()=>{
        setTimeout(() => {
            setIsOpen(false);
        }, duration);
    },[]);
    return (isOpen);

}
