import {flagIsrael,flagUSA} from '../../../../assets/symbols/symbols/languagesIcons';
import useShow from '../../../../hooks/useShow';
import * as css from './LanguageSelect.css';
import {useTranslation} from 'react-i18next';
import setGlobalState from '../../../../redux/setGlobalState';
import List from '../../../../component/list/List';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';

export const LanguageSelect =() => {

    const {t} = useTranslation('layout');
    const show = useShow();
    const langloc = useSelector(s=>s.user.langloc);

    const list = [
        {key:'he',langloc:'he_il', text:t('hebrew'),type: 'icon', icon: flagIsrael},
        {key:'en',langloc:'en_us', text:t('english'),type: 'icon', icon: flagUSA}
    ];

    const currentLanguage = list.find(item=>item.langloc===langloc)||list[0];

    const onClickList=(item)=>{
        setGlobalState('user.lang',item.key); 
        setGlobalState('user.langloc',item.langloc);
        localStorage.setItem('user.langloc', JSON.stringify(item.langloc));
        show.close();
    };

    useEffect(()=>{
        const currentLang = JSON.parse(localStorage.getItem('user.langloc'))||'he_il'
        setGlobalState('user.langloc',currentLang);
        setGlobalState('user.lang',list.find(lang=>lang.langloc == currentLang)?.key || 'he')
    },[]);
    
    return (
        <css.wrap onClick={show.open} data-cy='languageSelectBtn'>
            <css.arrow />
            {currentLanguage.text}
            <css.icon icon={currentLanguage.icon} />
            {!!show.isOpen && <List close={close}
                onClick={onClickList} 
                width={'130px'}
                top={28}
                list={list}
                left={0} 
                showClass={show.showClass}/>}
        </css.wrap>  
    );
};

export default LanguageSelect;
