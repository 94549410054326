import {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import BottomPopup from '../../../bottomPopup/BottomPopup';
import {newBudget} from '../../../../../../assets/symbols/images/Images.css';
import * as css from '../BudgetItem.css';
import Input from '../../../../../../component/input/Input';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {addBudgetItem, updateBudgetItem} from '../../../../../../redux/account/accountActions';
import WrapChekBox from '../../../../../../component/wrapChekBox/WrapChekBox';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../../../../redux/setGlobalState';
import TextareaInput from '../../../../../../component/input/textareaInput/TextareaInput';
import {yupStringMin} from '../../../../../../functions/validationsYup';

function BudgetPopup({close, showClass, currentItem}) {

    const {t} = useTranslation('package');
    const formRef = useRef();
    const account = useSelector(s => s.account);
    const updateBudgetItemXHR = account.updateBudgetItemXHR;
    const addBudgetItemXHR = account.addBudgetItemXHR;

    const [budgetDefault, setBudgetDefault] = useState(currentItem ? currentItem.budgetDefault : false);

    useEffect(async () => {
        if(updateBudgetItemXHR === 'SUCCESS'||updateBudgetItemXHR === 'FAILED')
            setTimeout(() => {
                setGlobalState('account.updateBudgetItemXHR', null);
            }, 1500);
    }, [updateBudgetItemXHR]);

    useEffect(async () => {
        if(addBudgetItemXHR === 'SUCCESS'||addBudgetItemXHR === 'FAILED')
            setTimeout(() => {
                setGlobalState('account.addBudgetItemXHR', null);
            }, 1500);
    }, [addBudgetItemXHR]);

    const handleSelected = (selected) => {
        setBudgetDefault(selected);
    };

    const onSubmitForm = async (values) => {
        values.budgetDefault = budgetDefault;
        if(currentItem)
            await updateBudgetItem(currentItem.index, {values: values, budgetIndex: currentItem.budgetIndex});

        else
            await addBudgetItem(values);
        setTimeout(() => {
            close();
        }, 2000);
    };

    const acceptClick = async () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const validationSchema = Yup.object().shape({
        budgetTitle: yupStringMin().max(30).required(),
        budgetIndex: Yup.number().required(),
        budgetNote: Yup.string().nullable(true).max(70),
    }
    );

    const initialValues = {
        budgetTitle: currentItem?currentItem?.budgetTitle:'',
        budgetIndex: currentItem?currentItem?.budgetIndex:'',
        budgetNote: currentItem?currentItem?.budgetNote:''
    };

    return (<>
        <Formik innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => { onSubmitForm(values); }}
        >
            <Form>
                <css.wrapPopup>
                    <BottomPopup close={close} showClass={showClass} icon={newBudget} disabledAcceptBtn={false} XHR={updateBudgetItemXHR||addBudgetItemXHR}
                        headerText={currentItem ? t('editBudgetItem') : t('newBudgetItem')} acceptClick={acceptClick} textCancel={t('leftBtn')} textAccept={t('finishBtn')}>
                        <css.wrapAll>
                            <css.wrapInput>
                                <Input name={'budgetTitle'} text={t('budgetItemName')} />
                                <Input name={'budgetIndex'} text={t('budgetItemNumber')} disabled={currentItem?.budgetDefault && true} />
                                <css.wrapCB>
                                    <WrapChekBox item={{text: t('budgetDefault'), selected: budgetDefault}} handleSelected={handleSelected} />
                                </css.wrapCB>
                            </css.wrapInput>
                            <css.wrapInput>
                                <TextareaInput name={'budgetNote'} text={t('budgetNote')}/>
                            </css.wrapInput>
                        </css.wrapAll>
                    </BottomPopup>
                </css.wrapPopup>
            </Form>
        </Formik>

    </>
    );
}

export default BudgetPopup;
