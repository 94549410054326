import Input from '../../../../../component/input/Input';
import {useTranslation} from 'react-i18next';
import * as css from './LocationCourse.css';
import useShow from '../../../../../hooks/useShow';
import LocationPop from '../locationPop/LocationPop';
import DisabledInput from '../../../../../component/input/disabledInput/DisabledInput';
import {Form, Formik, useField} from 'formik';
import {useRef} from 'react';
import * as Yup from 'yup';
import {yupStringMin} from '../../../../../functions/validationsYup';
//description={t('complexDesc')}
//description={t('locationDesc')}
function LocationCourse() {

    const {t}=useTranslation('ticket');
    const show=useShow();
    const [{value:fieldLocation}, , helpersLocation] = useField('location.location');
    const [{value:fieldLocationMap}, , helpersLocationMap] = useField('location.locationMap');

    const getValue = ()=>{
        if(!fieldLocationMap?.title)
            return fieldLocation; 
        else if(!fieldLocation)
            return fieldLocationMap?.title;
        else
            return `${fieldLocation}, ${fieldLocationMap?.title}`;
    };

    const formRef = useRef();

    const onSubmitForm = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const save =(values)=>{
        helpersLocation.setValue(values.locationTemp);
        helpersLocationMap.setValue(values.locationMapTemp);
        show.close();
    };

    return (<>
        <css.location>
            <DisabledInput value={getValue()} text={t('locationText')} icon='location' events={{onClick:()=>show.open()}} dataCyName="locationText"/>
            <Input name={'location.complex'} text={t('complexText')} description={t('complexDesc')}/>
        </css.location>
        <Formik 
            innerRef={formRef}
            enableReinitialize
            initialValues={{locationTemp:fieldLocation||'',locationMapTemp:fieldLocationMap||''}}
            validationSchema={Yup.object().shape({locationTemp:yupStringMin()})}
            onSubmit={save}
        >
            <Form>
                <LocationPop show={show} onSubmitForm={onSubmitForm}/>
            </Form>                
        </Formik>
       
    </>
    );
}

export default LocationCourse;
