import {useFormikContext} from 'formik';
import getUnequalFields from '../../../../functions/getUnequalFields';
import {updateEvent,createDefaultTemplate, updateEventReady} from '../../../../redux/event/eventActions';
import store from '../../../../store';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {newEventInitialValues} from '../../globalSteps';
import {useSelector} from 'react-redux';
import {setCurrentRating} from '../../../components/rating/Rating';
import {newEventContext} from '../newEventForm/NewEventForm';

function useStep(step={}){
    const history=useHistory();
    const {setCurrentStep,failedState:[failed,setFailed],isReady}=useContext(newEventContext);
    const form=useFormikContext();
    const {name,onClick,isLast}=step;
    const meta = form.getFieldMeta(name);
    const event=useSelector(s=>s.event);
    const initialValues=newEventInitialValues(event);

    const afterEventReady=()=>{
        const pathByType={
            hub:'design',
        };
        history.push(`/e/${store.getState().event.eid}/${pathByType[event.type]||'tickets'}`);
        setCurrentRating('createNewEvent');
    };
    if(!step.name)
        return {onSaveStep:()=>{},onContinue:()=>{}};

    const eventReadyActions={
        hub:updateEventReady,
        default:createDefaultTemplate
    };

    const onSaveStep=(newValue=null, isSaveEvent=false)=>{
        const _isSaveEvent = isLast || isSaveEvent;
        const onUpdateFailed=()=> {setFailed(p=>({...p,[name]:true}));};
        const onUpdateSuccess=()=> {
            if(failed[name])
                setFailed(p=>({...p,[name]:false}));
            if(_isSaveEvent && event.ready<5)
                (eventReadyActions[event.type]||eventReadyActions.default)().then(afterEventReady);

        };
        const value=(newValue||newValue==='')?newValue:meta.value;
        const changedValues=getUnequalFields({[name]:initialValues[name]},{[name]:value});
        const fields=(_isSaveEvent&&!isReady&&event.type==='area')?{...changedValues,ready:1}:changedValues;
        if(Object.keys(fields).length>0){
            if(onClick)
                onClick(fields,value,form).then(onUpdateSuccess).catch(onUpdateFailed);
            else
                updateEvent(fields).then(onUpdateSuccess).catch(onUpdateFailed);
        }
        else {
            onUpdateSuccess();     
        }
    };

    const onContinue=(_e,isSaveEvent)=>{
        const {name} = step;
        if(meta.value && typeof meta.value==='object'){
            const touched=Object.keys(meta.value).reduce((all,current)=>({...all,[current]:true}),{});
            form.setFieldTouched(name,touched);
        }
        else if(!meta.touched)
            form.setFieldTouched(name,true);
        if(meta.error)
            return;
        onSaveStep(null,isSaveEvent);
        setCurrentStep(prev=>isReady?-1:prev+1);
    };
    return {onSaveStep,onContinue};
}

export default useStep;