import React from 'react';
import * as css from './Row.css';
import {Draggable} from 'react-beautiful-dnd';
import Checkbox from '../../checkbox/Checkbox';

//put placeForDrag (from Row.css) in the first part of the row (children).
//add class 'row_icon' cancel the borderRadius on hover

function Row({rowKey, index, isDraggingOver, children, checked,rowHeight}) {
    const key = rowKey;
    const {isSelectable, isChecked, onChecked} = checked;

    return (
        < Draggable draggableId={key?.toString()} key={key} index={index} isDragDisabled={isSelectable} >
            {(provided, snapshot) => (
                <css.wrap>
                    <css.row rowHeight={rowHeight} {...provided.draggableProps} ref={provided.innerRef} data-cy='tableRow'
                        isDragging={snapshot.isDragging}
                        isPlaceToHover={!isDraggingOver&&!isSelectable}
                        className={`row ${snapshot.isDragging && ' drag '} ${isChecked && 'checked '}`} >
                        {isSelectable &&
              <css.select >
                  <Checkbox selected={isChecked} onClick={(value) => onChecked(value)} />
              </css.select>
                        }
                        <css.drag {...provided.dragHandleProps} className={`${isSelectable && 'select'} dragSign`} />
                        {children}
                    </css.row>
                </css.wrap>
            )
            }
        </Draggable >
    );
}
export default Row;