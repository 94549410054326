/* eslint-disable spellcheck/spell-checker */
import {useField} from 'formik';
import React, {useCallback} from 'react';
import {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import useEffectUpdate from '../../hooks/useEffectUpdate';
import * as css from './Dropzone.css';
import {useRejectedFiles,validator} from '../../hooks/useRejectedFiles';
import {bounceMessage} from '../../layout/userMessages/UserMessage';
import {useTranslation} from 'react-i18next';
import {fileCompressor} from '../../library/fileCompressor';

function FormikDropzon({name,onUpload=()=>{},text,fileTypes}) {
    const [field,,helpers]=useField(name);
    let img = new Image();
    
    const  {rejectedMessage,checkRejectedOnUpload}=useRejectedFiles();
    const {t} = useTranslation('layout');

    const setFileAfterCompress=(files)=>{
        helpers.setValue({});
        const fileValue={};
        files.map(file => {
            file.preview = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.onload = (event) => {
                fileValue.name=file.name;
                fileValue.base64 = event.target.result;
                helpers.setValue({...field.value,...fileValue});
            };
            reader.readAsDataURL(file);            
            return file;
        });
    };

    const uploadFileHandler = (files,rejectedFiles) => {
        checkRejectedOnUpload(rejectedFiles);
        if(files.length<1){
            bounceMessage(t('upLoudImageWarning'),'warning');
            return ;
        } 
        console.log(files[0]);
        if(files.length<2)
            fileCompressor(files[0],(f)=>setFileAfterCompress([f])); 
    };
    
    img.onload = function () {
        helpers.setValue({...field.value,width : img.width,height :img.height}); 
    };

    useEffect(()=>{
        if(field.value?.base64)
            img.src =field.value?.base64;
    },[field.value?.base64]);

    useEffectUpdate( useCallback(onUpload, [field.value]));

    const {getRootProps, getInputProps} = useDropzone({
        accept: fileTypes,
        maxFiles : 1,
        onDrop:uploadFileHandler,
        validator

    });

    return (
        <css.drop width={'20px'} height={'20px'}><section className="container">
            <div {...getRootProps({className: 'dropzone'})} data-cy="formikDropzone">
                <input {...getInputProps()} />
                <p className={`title ${rejectedMessage?'error':''}`}>{rejectedMessage?rejectedMessage:text}</p>
            </div>
        </section></css.drop>
    );
}

export default FormikDropzon;