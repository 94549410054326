import {updateEventSettings} from '../../../redux/event/eventActions';
import setGlobalState from '../../../redux/setGlobalState';

export const updateNotification = async (setting) => {
    let XHR = null;
    setGlobalState('forms.eventSettings.notificationXHR', 'LOADING');
    if(!setting) {
        XHR = await updateEventSettings('smsSend', true, 'report');
    }
    else {
        XHR = await updateEventSettings('smsSend', false, 'report');
        await updateEventSettings('emailSend', false, 'report');
    }
    setGlobalState('forms.eventSettings.notificationXHR', XHR);
};

export const updateFullSend = async (name,report,setting) => {
    if(setting&&name==='fullSend'&&!(report.reportToEmail||report.reportToWhatsapp)){
        await updateEventSettings('fullSend', true, 'report');
        await updateEventSettings('reportToEmail', true, 'report');
        return;
    }
    await updateEventSettings(name,setting,'report');
};

