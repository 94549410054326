import produce from 'immer';

const getUpdatedScope = (source, value, parts, operation = 'SET') =>
    produce(source, draftState => {
        parts.forEach((key, i) => {
            if(i + 1 < parts.length)
                draftState = draftState[key];
            else {
                switch (operation) {
                    case 'SET':
                        draftState[key] = value;
                        break;
                    case 'PUSH':
                        draftState[key].push(value);
                        break;
                    case 'UNSET':
                        if(Array.isArray(draftState))
                            draftState.splice(Number(key), 1);
                        else delete draftState[key]; //instead of delete draftState[key][value];
                        break;
                    default:
                        draftState[key] = value;
                        break;
                }
            }
        });
    });

const createReducer =
  (actionType, initialState) =>
      (state = initialState, action) => {
          const [path, operation] = action.type
              .replace(/[\[]/g, '.')
              .replace(/[\]]/g, '')
              .split(':');
          const parts = path.split('.');
          const prefix = parts.shift();
          if(prefix === actionType) {
              if(!parts.length)
                  return operation === 'RESET' ? initialState : {...state,...action.payload};
              else {
                  if(operation === 'RESET' && prefix === 'ui'){
                      return getUpdatedScope(state, initialState[parts[0]], parts, 'SET');
                  }
                  return getUpdatedScope(state, action.payload, parts, operation);
              }
          }
          return state;
      };

export default createReducer;
