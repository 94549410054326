import styled from 'styled-components/macro';

export const colors = styled.div`
    display: grid;
    height: fit-content;
    grid-template-columns: repeat(5, 1fr);
    align-content: center;
    justify-content: center;
    grid-gap: 13px;
`;
export const text = styled.div`
    height: 30px;
    font-weight: 500;
`;
export const wrapColors = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const wrapAll = styled.div`
    height: fit-content;
`;