import {useTranslation} from 'react-i18next';
import SettingItem from '../settingItem/SettingItem';
import {useSelector} from 'react-redux';
import {Formik, Form} from 'formik';
import {subSettingInputs} from '../paymentForm/PaymentForm';
import SubSettingItem from '../subSettingItem/SubSettingItem';
import DataItems from '../../../component/dataItems/DataItems';
import {updateEventSettings, updateEventSettingsList} from '../../../redux/event/eventActions';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import useTeamMembers from '../../../hooks/useTeamMembers';

const onClick=async (name,report={},setting,t)=>{
    await updateEventSettings(name,setting,'report');
    const fields=['reportToWhatsapp','reportToEmail'];
    if(report.fullSend&&!setting){
        if(!report[fields.find(f=>f!==name)])
            await updateEventSettings('fullSend', false, 'report');
    }
    if(name==='reportToWhatsapp'&&setting&&['hourly', 'twiceHourly'].includes(report.frequency)){
        bounceMessage(t('reportToWhatsappError'));
        updateEventSettings('frequency','daily','report').then();
    }
};

const frequencyList = ['hourly', 'twiceHourly', 'daily', 'weekly'].map(i => ({key: i, text: i}));
const onFrequencyClick= async (_name,key,report,t)=>{
    if(report.frequency===key)
        return;
    updateEventSettings('frequency',key,'report').then();
    if(['hourly', 'twiceHourly'].includes(key)&&report.reportToWhatsapp){
        bounceMessage(t('frequencyError'));
        updateEventSettings('reportToWhatsapp',false,'report').then();
    }
    
};

function ReportForm() {
    const {t} = useTranslation('event');
    const event = useSelector(state => state.event);
    const report=event.settings?.report||{};
    const settingsXHR = useSelector(state => state.forms.eventSettings);
    const {getList, getToggle} = subSettingInputs(report, 'report', settingsXHR, t);

    const initialValues = {
        fullSend: report?.fullSend,
        frequency: report?.frequency,
        noName: null
    };

    const onChange = (item, actionType, name) => {
        const uid = item.key;
        let serverValue = '';
        let reduxValue = [];
        if(actionType === 'remove') {
            reduxValue = [...report?.[name]]?.filter(user => user?.uid != uid);
            serverValue = reduxValue.map(u => u?.uid).toString();
        }
        
        else if(actionType === 'add') {
            let valueToServer = [...report?.[name]]?.map(u => u?.uid).toString();
            reduxValue = [...report?.[name], {...item, uid: item.key}];
            serverValue = `${valueToServer ? valueToServer + ',' : ''}${uid}`;
        }
        updateEventSettingsList(name, 'report', serverValue, reduxValue);

    };

    const {members}=  useTeamMembers();
    const getMembersList = (name) => {
        const membersList = members.filter(m=>m.status!=='unauthenticated').map(member => {
            const selected = report?.[name]?.some(user => user?.uid == member?.uid);
            return {key: member.uid, text: `${member.nameFirst} ${member.nameLast}`, selected};
        }
        );
        return membersList;
    };

    const getDataItems = (name) => <SubSettingItem >
        <DataItems list={getMembersList(name)} listText={t('addMembers')} onChange={(a, b) => onChange(a, b, name)} icon="person" />
    </SubSettingItem>;
    
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
        >
            <Form>
                {/* דוח סיכום */}
                <SettingItem settingCategory={'report'} name={'fullSend'} >
                    {getList('frequency', frequencyList,(...args)=>onFrequencyClick(...args,t))}
                    {getDataItems('users')}
                    {getToggle('reportToEmail',onClick)}
                    {getToggle('reportToWhatsapp',(...args)=>onClick(...args,t))}
                </SettingItem>
                {/* הודעת רכישה */}
                <SettingItem settingCategory={'report'} name={'notification'} >
                    {getToggle('smsSend')}
                    {getToggle('emailSend')}
                    {getDataItems('transactionUsers')}
                </SettingItem>
                {event.type==='course'&&<SettingItem settingCategory={'report'} name={'attendanceReport'}  />}
            </Form>
        </Formik>);
}

export default ReportForm;
