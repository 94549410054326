import * as css from './Robot.css';
import {useTranslation} from 'react-i18next';
import useShowModal from '../../hooks/useShowModal';
import robotAnimate from '../../assets/animations/robotAnimate.json';
import Animation from '../animation/Animation';
import Btn from '../button/btn/Btn';
import {useRef} from 'react';

function Robot({explanationText,text}) {
    const {t} = useTranslation('event');
    const ref=useRef();
    const {open,close,isOpen,showClass}=useShowModal(ref);

    return (
        <>
            {isOpen&&<css.mobileBlur className={showClass}/>}
            <css.wrap >
                {isOpen&&<css.explanation  ref={ref} className={showClass}>
                    {explanationText}
                </css.explanation>}
                <css.robot >
                    <css.animateWrap>
                        <Animation  animation={robotAnimate} isLoop/>
                    </css.animateWrap>
                    <css.robotText>
                        <css.bubble className={showClass}/>
                        <css.notResting>{t`notResting`}</css.notResting>
                        <css.text>{text}</css.text>
                        {explanationText&&<Btn onClick={isOpen?close:open} >{t(isOpen?'closeThanks':'pleaseExplain')}</Btn>}
                    </css.robotText>
                    <css.bottomBubbles/>
                    
                </css.robot>
            </css.wrap>
        </>
    );
}

export default Robot;
