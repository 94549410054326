import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import AnotherOneHorizontal from '../../../../component/anotherOneHorizontal/AnotherOneHorizontal';
import TicketBox from '../../components/ticketBox/TicketBox';
import TicketsList from '../../components/ticketsList/TicketsList';
import useShow from '../../../../hooks/useShow';
import setGlobalState from '../../../../redux/setGlobalState';
import {updateGroup} from '../../../../redux/tickets/ticketsActions';
import GroupOptions from '../groupOptions/GroupOptions';
import * as css from './TicketsArea.css';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';

function TicketsArea({tickets, gid, openPopup, groupTickets}) {
    const {t} = useTranslation('ticket');
    const {addGroupXHR} = useSelector(s => s.tickets);
    const horizontal = useShow();

    const addTicketToGroup = async (item) => {
        const newData = groupTickets ? [...groupTickets].map(t => {return {tid: t.tid};}) : [];
        newData.push({tid: item.ticket.tid});
        await updateGroup(gid, {tickets: newData});
        horizontal.close();
    };

    useEffect(() => {
        if(addGroupXHR === 'SUCCESS') {
            bounceMessage(t('groupReady'),'attention',undefined,{onClick:()=>setGlobalState('tickets.addGroupXHR', null)},null);
            setGlobalState('tickets.addGroupXHR', null);
        }
    }, [addGroupXHR]);

    return (
        <css.ticketsArea>
            {tickets?.map((item, index) => {
                return (
                    <TicketBox ticket={item} className={'improved'} key={index}>
                        <GroupOptions openPopGroup={() => openPopup(item)} groupInfo={{gid: gid, groupTickets: groupTickets}} tid={item.tid}/>
                    </TicketBox>
                );
            })}
            <css.more  onClick={e => { e.stopPropagation(); horizontal.isOpen ? horizontal.close() : horizontal.open(); }} >
                <AnotherOneHorizontal title={t('assignTicket')}>
                    {horizontal.isOpen && <TicketsList showClass={horizontal.showClass} close={horizontal.close} onClick={addTicketToGroup} exist={tickets} />}
                </AnotherOneHorizontal>
            </css.more>
        </css.ticketsArea>
    );
}

export default TicketsArea;