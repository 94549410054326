import Animation from '../../../../component/animation/Animation';
import * as css from './BtnAnimation.css';
import sadFace from '../../../../assets/animations/sadFace.json';

function BtnAnimation({onClick=()=>{},text}) {

    return (
        <css.button onClick={onClick}>
            <Animation animation={sadFace} isLoop/>
            {text}       
        </css.button>  
    );
}

export default BtnAnimation;

