import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import Btn from '../../../../component/button/btn/Btn';
import * as css from './StepLeft.css';
import {useContext} from 'react';
import {emptyObject} from '../../../../functions/emptyObject';
import {useRequiredFields} from '../../../../hooks/useValidationSchemaContext';
import useStep from './useStep';
import {newEventContext} from '../newEventForm/NewEventForm';
import {useSelector} from 'react-redux';

function StepLeft({children,name}) {
    const {steps,setCurrentStep,isReady}=useContext(newEventContext);

    const step=steps.find(s=>s.name===name);
    const [{value},meta,helpers]=useField(name);
    const isRequired=useRequiredFields(name);
    const {t} = useTranslation('newEvent');
    const {t:globalT} = useTranslation('global');
    const event = useSelector(s=>s.event);

    const {onSaveStep,onContinue} = useStep(step);

    const isEmptyField=step.isEmptyField||(value=>!value);

    const onSkip=()=>{
        helpers.setTouched(true);
        if(isEmptyField(meta.initialValue)&&isEmptyField(value))
            return  setImmediate(()=>{
                if(meta.error)
                    return;
                setCurrentStep(prev=>isReady?-1:prev+1);  
            });
        const emptyValue=emptyObject(value);
        helpers.setValue(emptyValue);
        onSaveStep(emptyValue);
        setImmediate(()=>setCurrentStep(prev=>isReady?-1:prev+1));
    };

    const skipText=t(`${name}SkipText`)===`${name}SkipText`?t('skip',{name:t(name)}):t(`${name}SkipText`);

    return (
        <css.wrapper cssStyle={children&&'justify-content: space-between;'} className={step.isShowErrorMessages?'alignToLeft':''}>
            {children?children:<></>}
            <css.wrapButtons>
                {!isRequired&&<Btn className={'skip'} onClick={onSkip}>{skipText}</Btn>}
                <Btn className={`arrow ${meta.error?'white':'valid'} ${step.isLast && event.createDefaultTemplateXHR}`}
                    onClick={onContinue}
                    disabled={(!isRequired && isEmptyField?isEmptyField(value):false) || (meta.error)}
                >{isReady?globalT('save'):t(step.isLast?'finish':'continue')}</Btn>
            </css.wrapButtons> 
        </css.wrapper>
    );
}

export default StepLeft;
