import styled, {keyframes} from 'styled-components';
import {pencil} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';

export const itemInArray = styled.div`
   display: flex;
   border: 1px solid;
   border-radius: 25px;
   margin: 3px;
   height: 25px;
   line-height: 25px;
   
`;
export const icon = styled.div`
   background-color: "#10C2D1";
   height: 22px;
   border-radius:50%;
   width: 22px;
   margin-${props => props.theme.rtl ? 'right' :'left'}: 1px;
   ${BGImageCenter(pencil,'70%')}
   ${props => (props.iconCss)}
`;
export const title = styled.div`
   padding: 0px 10px;
`;
export const left = styled.div`
   padding-${props => props.theme.rtl ? 'left' :'right'}: 10px;
`;