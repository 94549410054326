import * as css from './AnotherOneHorizontal.css';
import BtnPlus from '../button/btnPlus/BtnPlus';

function AnotherOneHorizontal({title, onClick, width, height, children}) {

    return (
        <css.wrapper onClick={onClick} data-cy="addTicket" >
            <css.addGroup width={width} height={height}>
                <BtnPlus bsize={30} onClick={onClick}/>
                <css.title>{title}</css.title>
            </css.addGroup>
            {children && children}
        </css.wrapper>
    );
}
export default AnotherOneHorizontal;