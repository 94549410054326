import * as acorn from 'acorn';

function isValidJavaScript(code) {
    try {
        acorn.parse(code);
        return true;
    } catch (error) {
        return false;
    }
}
export default isValidJavaScript;