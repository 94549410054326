import request from '../../library/request';
import store from '../../store';
import setGlobalState, {pushToGlobalState, unsetGlobalState} from '../setGlobalState';
import convertToCamelCase from '../../functions/convertToCamelCase';

const getEid = () => store.getState().event.eid;

export const getPixels = async () => {
    const getOrderlyPixels=(pixels,)=>pixels.map(pixel=>{
        pixel.pixelId=pixel.pid;
        delete pixel.pid;
        delete pixel.eid;
        return pixel;
    });
    const res = await request('publish.getPixels', 'get', `/event/${getEid()}/pixel`);
    const specialPixels=getOrderlyPixels(res.specialPixels).map(pixel=>({...pixel,key:convertToCamelCase(pixel.type)}));
    const customPixels=getOrderlyPixels(res.customPixels).map(p=>({...p,key:'customPixel'}));
    setGlobalState('publish.pixel', {...store.getState().publish.pixel,customPixels,specialPixels});
};

const afterDeletePixel=(pixelId,type)=>{
    const index = store.getState().publish?.pixel[type].findIndex(p=>p.pixelId===pixelId);
    if(index>-1)
        unsetGlobalState(`publish.pixel.${type}[${index}]`);
    
};

export const deleteCustomPixel = async (pixelId) => {
    await request('publish.deletePixel', 'delete', `/event/${getEid()}/pixel/${pixelId}`);
    afterDeletePixel(pixelId,'customPixels');
};

export const deleteSpecialPixel = async (pixelId) => {
    await request('publish.deleteSpecialPixel', 'delete', `/event/${getEid()}/specialPixel/${pixelId}`);
    afterDeletePixel(pixelId,'specialPixels');
};
const pidToPixelId=pixel=>{
    pixel.pixelId=pixel.pid;
    delete pixel.pid;
};

export const updateSpecialPixel = async (pixelId,data) => {
    const specialPixels = store.getState().publish?.pixel?.specialPixels;
    const res = await request('publish.updateSpecialPixel', 'put', `/event/${getEid()}/specialPixel/${pixelId}`,data);
    if(res){
        pidToPixelId(res);
        const index = specialPixels.findIndex(p=>p.pixelId===res.pixelId);
        setGlobalState(`publish.pixel.specialPixels[${index}]`, {...specialPixels[index],...res});
    }
};

export const updateCustomPixel = async (pixelId, data) => {
    const customPixels = store.getState().publish?.pixel?.customPixels;
    const res = await request('publish.updateCustomPixel', 'put', `/event/${getEid()}/pixel/${pixelId}`, data);
    if(res){
        pidToPixelId(res);
        const index = customPixels.findIndex(p=>p.pixelId===res.pixelId);
        setGlobalState(`publish.pixel.customPixels[${index}]`, {...customPixels[index],...res});
    }
};

export const addSpecialPixel = async (data) => {
    const res = await request('publish.addSpecialPixel', 'post', `/event/${getEid()}/specialPixel`,{...data,pid:data.pixelId});
    setGlobalState('ui.pixel.newPixel',{key:data.key,status:'created'});
    pidToPixelId(res);
    pushToGlobalState('publish.pixel.specialPixels', {...res,key:convertToCamelCase(res?.type)});
};

export const addCustomPixel = async (data) => {
    const res = await request('publish.addCustomPixel', 'post', `/event/${getEid()}/pixel`, {...data,pid:data.pixelId});
    pidToPixelId(res.customPixel);
    pushToGlobalState('publish.pixel.customPixels', {...res.customPixel,key:'customPixel'});
}; 
