import {useContext, useRef} from 'react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import useTimeout from '../../../../hooks/useTimeout';
import * as css from './StepRight.css';
import {newEventContext} from '../newEventForm/NewEventForm';
import hasSpecificTranslate from '../../../../library/hasSpecificTranslate';

function StepRight({children,name}) {
    const {t} = useTranslation('newEvent');
    const refFocus = useRef();
    const timeout =useTimeout();
    const {steps,currentStep}=useContext(newEventContext);
    const current=steps[currentStep]||{};
    
    useEffect(()=>{
        timeout.current= setTimeout(function (){
            let inputElement = refFocus.current?.querySelector('input')||refFocus.current?.querySelector('textarea');
            inputElement?.focus();
        },600);
    },[]);
    
    const description=current.texts?.description?t(current.texts.description):(hasSpecificTranslate(`${name}Description`,t)||'');
    return (
        <css.wrapper ref={refFocus}>
            <css.header>{t(current.texts?.header||`${name}Header`)}</css.header>
            {description&&<css.description>{description}</css.description>}
            {children}
        </css.wrapper>
    );
}

export default StepRight;

