import {FieldArray, useField} from 'formik';
import Artist from '../artist/Artist';
import useMobile from '../../../../../hooks/useMobile';
export let artistArrayHelpers = {};

function Artists({isTop=false}) {
    const [artistsField]=useField('artists');
    const isMobile = useMobile();
    return (
        <FieldArray
            name="artists"
            render={(arrayHelpers) => {
                artistArrayHelpers = arrayHelpers;
                return <>
                    {!!artistsField.value?.length && artistsField.value?.map((item,index) => (
                        (!isTop||index<(isMobile?2:3))&&
                        <Artist isTop={isTop} key={item.artistId} artist={item} index={index}/>))}
                </>;
            }
            }
        />

    );
}

export default Artists;