import styled from 'styled-components/macro';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../globalVariables/zIndex';

export const tooltipWrap = styled.div`
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        font-weight: 600;
        z-index: ${zIndex.dropDown};
        cursor: default;
        border-radius: 3px;
        box-sizing: border-box;
        font-size: 11px;
        line-height: 1.3;
        height: max-content;
        max-width: 240px;
        width: max-content !important;
        padding: 2px 6px;
        overflow-wrap: break-word;
        background-color:black ;
        color: white;
        ${props => (props.dataTitleIcon ? `
            background-image:url("`+ symbol[props.dataTitleIcon] + `");
            background-size:10px 10px;
            background-repeat:no-repeat;
            padding-`+ (props.theme.rtl ? 'left' : 'right') + `:26px;
            background-position:`+ (props.theme.rtl ? '9px' : 'calc(100% - 9px)') + ` center;
        `: '')}
`;

export const wrapChild = styled.div`
   width: max-content;
   height: max-content;

`;