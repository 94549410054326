import styled from 'styled-components/macro';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';
import * as scroll from '../../component/scrollbar/Scrollbar.css';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
import {state} from '../../component/state/State.css';
import zIndex from '../../globalVariables/zIndex';

export const Navigation = styled.div`
    height:50px;
    color:#FFFFFF;
    display:flex;
    transition:all 400ms;
    width:100%;
    &.max{
        height: 200px;
        border-bottom: 1px solid #ececec;
    }
    @media screen and (max-width: 1000px){
        background: #f2f2f2;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
    }
`;
export const NavigationRouter = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 230px);
    @media screen and (max-width: 1000px){
         width: calc(100% - 30px);
    }
`;

export const NavigationFrame = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin-top:0;
    transition:all 400ms;
    padding-inline-start: 60px;
    &.max{
        display:block;
        margin-top:123px;
    }
    @media screen and (max-width: 1000px){
         width: 100%;
         padding-inline-start: 0px;
    }
`;
export const NavigationBack = styled.div`
    width: 70px;
    height: 50px;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.209' height='10.676' viewBox='0 0 18.209 10.676'%3E%3Cdefs%3E%3Cstyle%3E .cls-1, .cls-2 %7B fill: none; stroke: %23474747; stroke-width: 2px; %7D .cls-1 %7B stroke-linecap: round; %7D %3C/style%3E%3C/defs%3E%3Cg id='Symbol_9' data-name='Symbol 9' transform='translate(17.209 0.707) rotate(90)'%3E%3Cline id='Line_253' data-name='Line 253' class='cls-1' y2='16.209' transform='translate(4.631)'/%3E%3Cline id='Line_254' data-name='Line 254' class='cls-2' x1='4.631' y2='4.631'/%3E%3Cline id='Line_255' data-name='Line 255' class='cls-2' x2='4.631' y2='4.631' transform='translate(4.631)'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    ${props=>!props.theme.rtl&&'transform: rotate(180deg)'};
     @media screen and (max-width: 1000px){
        width: 30px;
        margin-inline-start: 7px;
     }
`;

export const NavigationTitle = styled.div`
    font-size:20px;
    font-weight:600;
    padding:0 30px ;
    transition:all 400ms;
    height:max-content;    
    background-repeat:no-repeat;
    background-size: 18px 18px;
    background-position: ${props=>props.theme.rtl?'right':'left'} center;
    background-image: url("${props => (symbol.symbolEventDark[props.symbol])}");
    &.max{
        font-size: 30px;
        font-weight: 800;        
        padding-${props=>props.theme.rtl?'right':'left'}:45px;
        background-size: 22px 22px;
        background-position: ${props=>props.theme.rtl?'calc(100% - 18px)':'18px'} center;
    }
    @media screen and (max-width: 1000px){
        font-size:17px;
        padding-inline-start:40px;
        background-position: ${props=>props.theme.rtl?'calc(100% - 17px)':'17px'} center;
        min-width:60px;
        line-height: 18px;
        &.arrow{
             ::after{
                content: '';
                ${BGImageCenter(symbol.arrowBtn,'6px')};
                width: 25px;
                height: 20px;
                position: absolute;
                transform: rotate(-90deg);
            } 
        }           
    }
    
`;
export const NavigationLinks = styled(scroll.Scrollbar)`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    line-height: 50px;
`;
export const NavigationBtn = styled.div`    
    width:200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition:all 400ms;
    position: relative;
    &.max{
       -webkit-align-items: flex-end;
        align-items: flex-end;
    }

    @media screen and (max-width: 1000px){
        display:none
    }
`;
export const activeEvent = styled.div`
    height: 23px ;
    z-index: ${zIndex.baseRaised};
    ${state}{
        font-size:12px;  
        font-weight:500 ;
        border:1px solid #D5D5D5;
        }
    }
`;

export const boxoffice = styled.div`
    position: absolute;
    inset-inline-end: 25px;
    bottom: -22px;
    z-index:${zIndex.baseHigh} ;
    &.iframe{
        bottom: -15px; 
    }
`;

export const boxOffice = styled.div`   
    font-weight:800;
    cursor:pointer;
    border-radius: 20px;
    padding:${props=>props.theme.rtl?'0 45px 0 20px':'0 20px 0 45px'} ;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.767' height='27.447' viewBox='0 0 29.767 27.447'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bstroke:%23000;%7D.b%7Bfill:%23fff;%7D.c%7Bstroke:none;%7D.d%7Bfill:none;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-261.36 -77.002)'%3E%3Cpath class='a' d='M964.561,994.943c0-.009,0-.018,0-.028l-1.676-13.4a2.142,2.142,0,0,0-2.1-1.85h-.865v-6.5a.464.464,0,0,0-.464-.464h-6.5a.464.464,0,0,0-.464.464v6.5h-5.1c-5.193.1-5.6.063-6.5,0h-1.329a2.142,2.142,0,0,0-2.1,1.85l-1.676,13.4c0,.009,0,.018,0,.028s-.006.019-.006.03v2.977a1.2,1.2,0,0,0,1.2,1.2h26.37a1.2,1.2,0,0,0,1.2-1.2v-2.977C964.567,994.962,964.561,994.954,964.561,994.943ZM953.431,973.63H959v12.992h-5.568Zm-6.96,6.032h0Zm-8.072,1.965a1.233,1.233,0,0,1,1.175-1.038H952.5v6.032h-1.392a.464.464,0,0,0,0,.928h10.208a.464.464,0,0,0,0-.928h-1.392V980.59h.865a1.233,1.233,0,0,1,1.175,1.038l1.61,12.882H936.79Zm25.239,16.323a.271.271,0,0,1-.27.271H937a.271.271,0,0,1-.271-.271v-2.513h26.911ZM940.672,987.1H948.1a.464.464,0,0,0,.463-.5l-.232-3.248a.464.464,0,0,0-.463-.431H940.9a.464.464,0,0,0-.463.431l-.232,3.248a.464.464,0,0,0,.463.5Zm.664-3.248h6.1l.165,2.32H941.17Z' transform='translate(-673.94 -895.2)'/%3E%3Cg class='b' transform='translate(266 94)'%3E%3Ccircle class='c' cx='1.5' cy='1.5' r='1.5'/%3E%3Ccircle class='d' cx='1.5' cy='1.5' r='1'/%3E%3C/g%3E%3Cg class='b' transform='translate(270 94)'%3E%3Ccircle class='c' cx='1.5' cy='1.5' r='1.5'/%3E%3Ccircle class='d' cx='1.5' cy='1.5' r='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: ${props=>props.theme.rtl?'calc(100% - 10px)':'10px'} center;
    background-size: 25px 25px;
    transition:all 400ms;
    :hover{
        background-color:#43BAE7;
    }
`;

export const pathItem = styled.div`
    width:115px;
    text-align:center;
    &.cur{
        span{
            display: block;
            width: max-content;
            margin: 0 auto;
            position: relative;
            ::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                background: #76D6E8;
                border-radius: 30px;
                left: 0;
                bottom: 0;
                 @media screen and (max-width: 1000px){
                    height: 4px;
                }
            }
        }
    }
     @media screen and (max-width: 1000px){
        width:100px;
     }
`;

export const pathItemImg = styled.div`
    width:115px;
    text-align:center;
    &.cur{
        color:#01acf1;
        font-weight:600;
    }
`;
