import {useTranslation} from 'react-i18next';
import * as css from './StaticActiveInactive.css';

function StaticActiveInactive({isActive}) {

    const {t}=useTranslation('layout');

    return (
        <css.wrapper>
            <css.icon className={isActive&&'active'} />
            <css.text className={isActive&&'active'}>{isActive?t('active'):t('inactive')}</css.text>
        </css.wrapper>
    );
}

export default StaticActiveInactive;
