import * as css from './PricesDetails.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Detail from '../../../../../tickets/components/ticketRow/Detail/Detail';
import Part from '../../../../../tickets/components/ticketRow/part/Part';
import ticketColors from '../../../../../../assets/objects/TicketColors';
import TicketsGroups from '../../../../../tickets/components/ticketRow/ticketGroups/TicketsGroups';
import useOnClickOutside from '../../../../../../hooks/UseOnClickOutside';
import {useRef} from 'react';

const PricesDetails = ({ticket, show}) => {
    const {t} = useTranslation('ticket');
    const currencySymbol = useSelector((state:any) => state.event.currencySymbol);
    const color = `#${ticket?.color}`;
    const ticketsIcon = useSelector((state:any) => state.tickets.ticketsIcon);
    const ref = useRef();
    useOnClickOutside(ref, show.close);
    if(!show.isOpen){
        return <></>;
    }
    return (
        <css.container ref={ref}>
            {ticket?.prices?.map((price, index) =>
                <css.priceWrap key={index}>
                    <css.firstPart className='titleColumn' >
                        <css.placeDrag className=' place ' />
                        <css.ticketIcon icon={ticketColors[`${ticket?.color}`]?.[ticketsIcon]} color={color} className='row_icon' />
                        <css.ticketRight>
                            <css.header>
                                <css.ticketTitle data-cy='titleColumnValue'>{price.title}</css.ticketTitle>
                            </css.header>
                            <TicketsGroups ticket={ticket}/>
                        </css.ticketRight>
                    </css.firstPart>
                    <Part padding={'0'} field={'price'} className={''} cssStyle={undefined}>
                        <css.price>
                            <Detail text='' value={price.price} isSymbol sign={currencySymbol}/>          
                        </css.price>
                    </Part>
                    <Part field={'amount'} className={''} cssStyle={undefined} padding={undefined}>
                        <Detail text='' value={'-'} sign={''}/>
                    </Part>
                    <Part field={'sold'} className={''} cssStyle={undefined} padding={undefined}>
                        <Detail text='' value={price.sold} sign={t('ticketPcs')}/>
                    </Part>
                    <Part field={'left'} className={''} cssStyle={undefined} padding={undefined}>
                        <Detail text='' value={'-'} sign={''}/>
                    </Part>
                    <Part field={'discount'} className={''} cssStyle={undefined} padding={undefined}>
                        <Detail text='' value={price.soldPrice - (price.sold * price.price)} sign={currencySymbol} isSymbol/>
                    </Part> 
                    <Part field={'soldPrice'} className={''} cssStyle={undefined} padding={undefined}>
                        <Detail text='' value={price?.soldPrice} sign={currencySymbol} isSymbol/>
                    </Part>                
                </css.priceWrap>
            )}
        </css.container>
    );
};

export default PricesDetails;