import * as css from './OnPopInfoArea.css';
import {useTranslation} from 'react-i18next';

function OnPopInfoArea(props) {

    const {infoAreaProps, chosen}=props;
    const {t} = useTranslation('mailing');

    const mailingPackages = [
        {title: t('dove'), key: 1, price: 150, emails: 6000, sms: 5000},
        {title: t('hawk'), key: 2, price: 300, emails: 11000, sms: 10000},
        {title: t('eagle'), key: 3, price: 2000, emails: 110000, sms: 100000}
    ];

    const chosenPackage = (key,price,email,sms) => {
        const chosenProps = {key:key,price:price,email:email,sms:sms};
        chosen(chosenProps);
    };

    return (
        <css.infoArea className="infoArea">
            <css.popOnPopInfo className={(infoAreaProps.openUpgrade && 'upgrade ') + 'popOnPopInfo'}>
                {!infoAreaProps.upgradeEnd ?
                    <>
                        <css.infoTitle className="infoTitle">{t('balance') + ' ' +infoAreaProps.msgCount}/{infoAreaProps.balance} {infoAreaProps.mailOrSms == 'sms' ? t('messages') : t('sms')}</css.infoTitle>
                        <css.infoContent className="infoContent">{t('upgradeEasily')} <br /> {t('unbeatablePrices')
                        }</css.infoContent>
                    </>
                    :
                    <>
                        <css.infoTitle className="infoTitle">{t('currentBalance')}</css.infoTitle>
                        <css.infoContent className="infoContent">{infoAreaProps.msgCount}/{infoAreaProps.packages.mailingPackage['email'].amount} {t('emails')}<br /> {infoAreaProps.msgCount}/{infoAreaProps.packages.mailingPackage['sms'].amount} {t('sms')}</css.infoContent>
                    </>
                }
            </css.popOnPopInfo>
            {(!!infoAreaProps.openUpgrade && !infoAreaProps.upgradeEnd) &&
                <css.popOnPopPackages className={'popOnPopPackages'}>
                    {mailingPackages.map((item, i) =>
                        <css.mailingPac className="mailingPac" key={item.key}>
                            <css.pacCheckBox className="pacCheckBox" key={item.key} onClick={() => { chosenPackage(item.key, item.price, item.emails, item.sms); }}>
                                <css.outerSquare className="outerSquare">
                                    {infoAreaProps.selectedPackage == item.key && <css.innerSquare className="innerSquare" />}
                                </css.outerSquare>
                            </css.pacCheckBox>
                            <css.pacTitle className="pacTitle">{t('package')} {item.title}</css.pacTitle>
                            <css.pacSum className="pacSum">₪{item.price}</css.pacSum>
                            <css.pacDesc className="pacDesc">{item.emails} {t('sms')}, {item.sms} {t('emails')}</css.pacDesc>
                        </css.mailingPac>
                    )}
                </css.popOnPopPackages>
            }
        </css.infoArea>
    );
}

export default OnPopInfoArea;