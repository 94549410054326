import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {googleImage} from '../../../../assets/symbols/images/google';
import EnterButton from '../../components/enterButton/EnterButton';
import LoginInput from '../../components/loginInput/LoginInput';
import * as css from './PhoneScreen.css';

function PhoneScreen() {

    const {t} = useTranslation('login');
    const values=useFormikContext().values;

    return (
        <css.container>
            <css.title>{t('enterPhone',{name: `${values.nameFirst} ${values.nameLast}`})}</css.title>
            <css.warpIcons>
                <css.icon icon={values.picture}/>
                <css.icon icon={googleImage}/>
            </css.warpIcons>
                  
            <css.text>{t('needPhone',{email:values.email})}</css.text>
            <LoginInput name="phone" text={t('phone')} description={t('phoneDesc')} type='tel' />
            <EnterButton text={t('signUpButton')} actionName='getTokenToSignUp'  buttonType= 'login'/>
        </css.container>  
    );
}

export default PhoneScreen;
