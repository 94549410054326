import * as css from '../packageAccordion.css';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Input from '../../../../../component/input/Input';
import WrapModeBtn from '../../../../../component/wrapModeBtn/WrapModeBtn';
import {useField} from 'formik';
import {brandingVerification, updatePackageProducer} from '../../../../../redux/account/accountActions';
import EventRolePermission from '../../../../components/rolePermission/EventRolePermission';
import {useLocation, useParams, useHistory} from 'react-router-dom';
import store from '../../../../../store';
import {bounceMessage} from '../../../../../layout/userMessages/UserMessage';
import PhoneInput from '../../../../../component/input/phoneInput/PhoneInput';

function ContactDetails({callBack}) {
    const {t} = useTranslation('package');
    const account = useSelector((state) => state.account);
    const packagesProducer = account?.packagesProducer;
    const role = account?.userAccountRole;
    const updatePackageProducerXHR = packagesProducer?.updatePackageProducerXHR;
    const verifiedEmail = packagesProducer?.verifiedEmail;
    const branding = packagesProducer?.branding;
    const [brandingEmail] = useField('brandingEmail');
    const [brandingSms] = useField('brandingSms');
    const location = useLocation();
    const history = useHistory();
    let verificationParams = null;

    let {pid} = useParams();

    const getStatus = () => {
        return branding == 2 ? 'message' : branding;
    };

    useEffect(async () => {
        if((pid === store.getState().account.pid?.toString()) && packagesProducer) {
            let params = new URLSearchParams(location.search);
            verificationParams = params.get('brandingVerify');
            if(verificationParams) {
                await brandingVerification({verification: verificationParams ,branding:branding});
                history.push({
                    search: '?packagePage=producer'
                });
            }
        }
    }, [account?.pid, packagesProducer]);

    const toggleClick = async () => {
        if(getStatus() == 0) {
            if(role === 'owner') {
                // && !verifiedEmail 
                if(!brandingEmail.value)
                    bounceMessage(t('noEmail'),'warning');
                else
                    await updatePackageProducer({branding: '1', brandingEmail: brandingEmail.value, brandingSms: brandingSms.value}, callBack);
            }
            else {
                bounceMessage(t('notAuthorized'),'warning');
            }
        }
        else {
            if(getStatus() == 1||getStatus() == 'message')
                await updatePackageProducer({branding: '0'});
        }
    };

    return (<>
        <css.bottomTitle>{t('contact')}</css.bottomTitle>
        <css.title>{t('contactExplanation')}</css.title>
        <css.wrapInputs>
            <Input name={'contactName'} text={t('contactName')} description={t('companyName')} />
            <Input name={'contactEmail'} text={t('email')} />
            <PhoneInput className={'contactPhone'} countryFieldName='contactCountryCode' name={'contactPhone'} text={t('phone')} description={t('companyPhone')} />
        </css.wrapInputs>
        <css.bottomTitle>{t('senderDetails')}</css.bottomTitle>
        <EventRolePermission rolePermission="manage package">
            <css.wrapBranding>
                <css.modeBtn>
                    <WrapModeBtn dataTitle={parseInt(branding) === 2 ? t('verification') : ''} text={'branding'} status={getStatus()} actionStatus={updatePackageProducerXHR} click={toggleClick} />
                </css.modeBtn>
            </css.wrapBranding>
            <css.wrapBranding>
                <Input name={'brandingSms'} text={t('senderName')} className={verifiedEmail && 'verified'} description={t('brandingSms')} />
                <Input name={'brandingEmail'} text={t('email')} className={verifiedEmail && 'verified'} description={t('brandingEmail')} />
            </css.wrapBranding>
        </EventRolePermission>
    </>
    );
}
export default ContactDetails;
