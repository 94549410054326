import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import MobilePopupModal from '../../../component/newPopup/mobilePopup/MobilePopupModal';
import faces from '../../../functions/faces';
import AttendanceItem from '../attendanceItem/AttendanceItem';
import * as css from './CourseAttendance.css';
import ExportExcel from '../exportExcel/ExportExcel';

function CourseAttendance({openAttendance,openReport}) {

    const {participants,meetings} = useSelector(s => s.attendance.tickets.find(t => t.tid === s.attendance.currentTid)||[]);
    const {t} = useTranslation('attendance');
    const ticketT = useTranslation('ticket');

    const sheets=[
        {
            name:t('attendance'),
            data:[[ticketT.t('firstName'),ticketT.t('lastName'),ticketT.t('phone'),t('attendance')],
                ...participants.map(p=>[
                    p.firstName,
                    p.lastName,
                    `0${p.phone.toString().slice(3)}`,
                    `${p.presentAmount}/${meetings.length}`
                ])
            ]
        }
    ];
    const title=t('attendanceReport', {title: meetings[0]?.title});

    return (
        <MobilePopupModal {...openAttendance}>
            <ExportExcel  sheets={sheets} fileName={title}/>
            <css.wrapAll>
                <css.title>{title}</css.title>
                <css.wrapAttendanceItem>
                    {participants?.map((item, index) => {
                        return (
                            <AttendanceItem key={item.qid} {...openAttendance}
                                personalInformation={item} 
                                face={faces(index)}
                                openReport={openReport} />
                        );
                    })}
                </css.wrapAttendanceItem>
            </css.wrapAll>
        </MobilePopupModal>
    );
}

export default CourseAttendance;
