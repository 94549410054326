import {hasAccountAccess} from '../../functions/permission';
import {useSelector} from 'react-redux';
import * as css from './SubList.css';
import {Link, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AccountPermission from '../../pages/components/accountPermission/AccountPermission';
import {refreshTokenIfNeed} from '../../library/axios';
import useTablet from '../../hooks/useTablet';
import {Else, If, Then} from 'react-if';
import List from '../../component/list/List';
import {getPageTitle} from '../navigation/Navigation';
import SubTopMenu from '../../pages/components/subTopMenu/SubTopMenu';
import {getTitleByPageType} from '../../functions/navigationFunctions';
import useNavigationList from '../navigation/useNavigationList';
import store from '../../store';
import useCurrentItemUrl from '../../tickchakHooks/useCurrentItemUrl';

export function getTabletList(navigationList,t,idParams,currentItemUrl,onClickItem=()=>{},isShowInnerMenu=true) {
    const event = store.getState().event;   
    const prePath = event ? '/e/' + event.eid : '/p/' + store.getState().account.pid;
    let currentPath = idParams?currentItemUrl?.path.split('/').slice(0, -1).join('/'):currentItemUrl?.path;

    const subMenu = navigationList;

    let tempList=[];
    subMenu?.map(([key, item]) => {
        let accessPackage = item.permission ? hasAccountAccess(item.permission) : true;
        if(!accessPackage)
            return;
        let itemList = {};
        itemList.isLink = true;
        itemList.to = prePath + item.path;
        itemList.onClick = onClickItem;
        itemList.key = key;
        itemList.text = getPageTitle(event.type,t,item.title);
        if(item.path===currentPath){
            itemList.classes ='bold';
        }
        tempList.push(itemList);
       
    });
    if(idParams && isShowInnerMenu){
        let index = subMenu.findIndex(([, item])=>item.path==currentPath);
        let newArr =  [ 
            ...tempList.slice(0, index+1),
            {children:<css.childItem><SubTopMenu title={getTitleByPageType(currentItemUrl?.idDesc,idParams)}/></css.childItem>,
                isLink : true,
                to :`${prePath}${currentPath}/${idParams}`,
                onClick : onClickItem},
            ...tempList.slice(index+1)
        ];
        return newArr;
    }
    return tempList;
}

function SubList({show}) {
    const {t} = useTranslation('layout');
    const event = useSelector(state => state.event);

    const isTablet = useTablet();

    const account = useSelector(state => state.account);
    const prePath = event ? '/e/' + event.eid : '/p/' + account.pid; 
    const params = useParams();
    const {page} = params;
    const currentItemUrl = useCurrentItemUrl();
    let idParams=params[currentItemUrl?.idParams];
    let currentPath = idParams?currentItemUrl?.path.split('/').slice(0, -1).join('/'):currentItemUrl?.path;
    const navigationList = useNavigationList(idParams);
   
    const onClickItem = ()=>{
        show.close();
        refreshTokenIfNeed();
    };
    
    return (
        <>
            <If condition={isTablet&& navigationList.length }>
                <Then>
                    <css.listStyle icon={page}>    
                        {show.isOpen && <List close={show.close} list={getTabletList(navigationList,t,idParams,currentItemUrl,onClickItem)} showClass={show.showClass} title={t(page)} isChild={true} /> }
                    </css.listStyle>
                </Then>
                <Else>
                    {navigationList?.map(([key, item]) => {
                        return <AccountPermission permission={item.permission || true} key={key}>
                            <Link to={prePath + item.path} onClick={refreshTokenIfNeed}>
                                <css.pathItem className={item.path === currentPath && 'cur '} data-cy={item.title} >
                                    <span>{t(item.title)}</span></css.pathItem>
                            </Link>
                        </AccountPermission>;
          
                    })} 
                </Else>
            </If>
        </>
    );
}

export default SubList;
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubList));
