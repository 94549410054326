import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import FormikDropzon from '../dropzone/FormikDropzone';
import * as css from './FileUpload.css';

function downloadURI(url, filename) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(console.error);
}

function FormikFileUpload({name='',text='', desc,  children, className,fileTypes='image/jpeg, image/jpg, application/pdf'}) {
    const {t} = useTranslation('package');
    const [field,,helpers]=useField(name);
    const {value}=field;

    return (
        <css.fileContainer className={className}>
            <css.fileUInput>
                {children && <css.fiIcon>{children}</css.fiIcon>}
                <css.fileUpload desc={desc}>
                    <css.attach />
                    <css.upload>
                        <FormikDropzon name={name} text={value?.name||text} fileTypes={fileTypes} />
                    </css.upload>
                    {(value?.name ) &&
                        <css.download onClick={() => downloadURI(value.base64, value.name)}>{t('download')}</css.download>
                    }
                    <css.garbage onClick={()=>helpers.setValue({})} />
                </css.fileUpload>
            </css.fileUInput>
        </css.fileContainer>
    );
}
export default FormikFileUpload;