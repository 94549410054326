import styled from 'styled-components/macro';
import {menu} from '../../../../assets/symbols/symbols/Symbols.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
  border-bottom: 1px solid #DDD;

`;
export const Close = styled.div`
  width: 50px;
  padding-inline-start: 15px;

`;

export const Right = styled.div`
  width: 50%;
  display: flex;

`;
export const TitleAccount = styled.div`
  width: calc(100% - 50px);
  display: flex;
  letter-spacing: 0.5px;
  white-space: nowrap;
  align-items: center;

  ${mobileMedia} {
    display: block;

  }
`;
export const Title = styled.div`
  letter-spacing: 1px;
`;

export const PackageName = styled.div`
  font-weight: 600;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  ${mobileMedia} {
    .search {
      &.focus {
        position: fixed;
        width: 90vw;
        background-color: #FAFAFA;
        left: 3vw;
        z-index: ${zIndex.baseHigh};
      }

    }
  }
`;
export const search = styled.div`
  width: calc(100% - 120px);
  height:50px;
  .search.focus{
    position: absolute;
    left: 0;
    z-index: ${zIndex.baseHighest};
    background: #fff;
  }
  ${mobileMedia} {
    .searchText {
      padding: 0;
    }
  }
`;
export const menuDiv = styled.div`
  background-image: url("${menu}");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center center;
  width: 60px;
  height: 20px;

`;

