import styled from 'styled-components/macro';

export const infoArea=styled.div`
    height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 750px){  
        height: 330px;
    } 
`;

export const popOnPopInfo=styled.div`
    text-align: center;
    position: relative;
    top: 65px;
    font-size: 16px;
    &.upgrade{
        top:40px;
    }
    &.upgrade .infoTitle{
        font-size: 16px;
    }
    &.upgrade .infoContent{
        font-size: 16px;
    }
    @media screen and (max-width: 750px){  
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    } 
`;
export const infoTitle=styled.div`
    font-weight: 550;
`;
export const infoContent=styled.div`
    font-size: 14px;
`;
export const popOnPopPackages=styled.div`
    height: 209px;
    margin: 74px 31px;
`;

export const mailingPac=styled.div`
    margin-top: 20px;
    height: 50px;
`;

export const pacCheckBox=styled.span`

`;

export const pacTitle=styled.span`
    font-weight: 800;
    margin-right: 10px;
`;
export const pacSum=styled(pacTitle)`
    float: left;
`;
export const pacDesc=styled.div`
    font-size: 15px;
`;
export const innerSquare=styled.div`
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #76d6e8;
    width: 8px;
    height: 9px;
`; 
export const outerSquare=styled.div`
    border:1px solid;
    width:15px;
    height:15px;
    position: relative;
    margin-top: 3px;
    cursor: pointer;
    float: right;
`; 