import BtnPlus from '../../../../component/button/btnPlus/BtnPlus';
import * as css from './AnotherOneCircleLoader.css';

function AnotherOneCircleLoader() {

    return (
        <>
            <css.wrapAll>
                <css.wrapper>
                    <BtnPlus white={true} bsize={''}/>
                </css.wrapper>
                <css.title/>
            </css.wrapAll>
        </>
    );
}
export default AnotherOneCircleLoader;