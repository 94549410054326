import {useTranslation} from 'react-i18next';
import MobilePopupModal from '../../../../component/newPopup/mobilePopup/MobilePopupModal';
import * as css from './ParticipantPop.css';
import {whatsApp,note} from '../../../../assets/symbols/symbols/Symbols.css';
import BtnCircleIcon from '../../../../component/button/btnCircleIcon/BtnCircleIcon';
import EditAttendance from '../../../../component/editAttendance/EditAttendance';
import {getDate} from '../../participantReportItem/ParticipantReportItem';
import {openWhatsApp} from '../../../../library/whatsApp';
import ParticipantPresentDetails from '../participantPresentDetails/ParticipantPresentDetails';
import useShow from '../../../../hooks/useShow';
import ParticipantPopDetails from '../participantPopDetails/ParticipantPopDetails';
import ProgressBar from '@ramonak/react-progress-bar';
import {useSelector} from 'react-redux';
import CircleImageText from '../../../../component/circleImageText/CircleImageText';
import useMobile from '../../../../hooks/useMobile';
import {POPUP_DURATION} from '../../../../globalVariables/GlobalVariables';
import Btn from '../../../../component/button/btn/Btn';
import {sendSmsPaymentDebt} from '../../../../redux/attendance/attendanceActions';
import TextEditor from '../../../../component/textEditor/TextEditor';
import React, {useEffect, useRef, useState} from 'react';
import setGlobalState from '../../../../redux/setGlobalState';
import {XHR_SUCCESS_STATE} from '../../../../assets/constants/constants';
import useTimeout from '../../../../hooks/useTimeout';
import {getPackageProducer} from '../../../../redux/account/accountActions';

function ParticipantPop({face,participant,openPop,meet, debt, qid}) {
    const pastMeetings = useSelector(s=>s?.attendance?.tickets.find(t=>t.tid===s.attendance.currentTid)?.meetings?.reduce((total,item)=>item.date < (new Date().getTime()/1000) ?++total:total,0));
    const title = useSelector(s=>s?.attendance?.tickets.find(t=>t.tid===s.attendance.currentTid)?.meetings[0].title);
    const attendanceSendSmsXHR = useSelector(s=>s?.attendance?.attendanceSendSmsXHR);
    const {packagesProducer,pid,getAccountXHR} = useSelector((s) => s.account);
    const percent = Math.round((participant.presentAmount/pastMeetings)*100);
    const amount = percent>0?percent:2;
    const {t} = useTranslation('attendance');
    const globalT = useTranslation('global');
    const layoutT = useTranslation('layout');
    const openParticipantDetails = useShow(POPUP_DURATION);
    const isMobile = useMobile();
    const openEditor = useShow();
    const quillRef = useRef(null);
    const timerClose = useTimeout();
    const vars = {
        name: debt?.order?.name_first+' ' + debt?.order?.name_last,
        title: title,
        amount: debt?.price,
        month: debt?.dates,
        link: debt?.link,
        phone: packagesProducer?.contactPhone
    };
    const [text, setText] = useState('');

    useEffect(() => {
        setText(t('debtSmsText', vars).replace(/\n/g, '</p><p>'));
    }, [debt, packagesProducer]);
    const closeEditor =  ()=>{
        openEditor.close().then();
        setGlobalState('attendance.attendanceSendSmsXHR', null);
    };
    const sendSms =  ()=>{
        const textToSend = text.replace(/<\/p><p>/g, '\n');
        sendSmsPaymentDebt(qid, textToSend).then();
    };
    useEffect(() => {
        if(attendanceSendSmsXHR===XHR_SUCCESS_STATE);
        timerClose.current = setTimeout(closeEditor, 2500);
    }, [attendanceSendSmsXHR]);
    useEffect( () => {
        if(!packagesProducer) {
            if(pid && ['SUCCESS', 'UPDATED'].includes(getAccountXHR))
                getPackageProducer().then();
        }
    }, [getAccountXHR]);
    const saveData =  (text) => {
        setText(text);
    };
    return (
        <>
            <MobilePopupModal {...openPop} >
                <CircleImageText text={participant.firstName+' ' + participant.lastName} image={face}/>
                <css.wrapAll>
                    <css.wrapTop>
                        <css.rightActs>
                            {<EditAttendance fullDate={getDate(meet?.tdid, globalT)} meeting={meet}/>}
                        </css.rightActs>
                        <css.leftActs>
                            <BtnCircleIcon icon={note} text={t('note')}/>
                            <BtnCircleIcon icon={whatsApp} onClick={() => openWhatsApp(participant.phone)} text={t('whatsApp')}/>
                        </css.leftActs>
                    </css.wrapTop>
                    <css.progressBar percent={percent + '%'} text={t('attendance')}>
                        <ProgressBar animateOnRender={1} dir={'rtl'} height={'4px'} bgColor={'#76D6E8'} completed={amount} customLabel={'   '}/>
                    </css.progressBar>
                    {!isMobile && <ParticipantPresentDetails participant={participant}/>}
                    {isMobile && <css.edit onClick={() => openParticipantDetails.open()} text={t('note')}/>}
                    {openParticipantDetails.isOpen &&
                        <ParticipantPopDetails participant={participant} openDetailsPop={openParticipantDetails}/>}
                    {debt && <css.debt>{t('courseBillingDebt')}<Btn className={'white'} onClick={openEditor.open}>{t('sendReminder')}</Btn></css.debt>}
                </css.wrapAll>
            </MobilePopupModal>{openEditor.isOpen && <MobilePopupModal close={closeEditor} showClass={openEditor.showClass}>
                <css.wrapper>
                    <TextEditor defaultText={!!text && `${text}`} showToolbar={false} quillRef={quillRef} onSave={saveData}/>
                    <css.buttons>
                        <Btn className={'white'} onClick={closeEditor}>{layoutT.t('closeIt')}</Btn>
                        <Btn className={attendanceSendSmsXHR} onClick={sendSms}>{layoutT.t('sendBtn')}</Btn>
                    </css.buttons>
                </css.wrapper>
            </MobilePopupModal>}
        </>
    );
}
export default ParticipantPop;
