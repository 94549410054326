import styled from 'styled-components/macro';
import {DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';
import {row} from '../newRow/Row.css';

export const header = styled(row)`
    height: 40px;
    border-top: 3px solid ${DEFAULT_COLOR};
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-size: 13px;
`;