import {useField} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './SelectDay.css';

function SelectDay({name, day}) {
    const {t} = useTranslation('global');

    const [days, setDays] = useState([{key: 1, value: 'א', selected: false},
        {key: 2, value: 'ב', selected: false},
        {key: 3, value: 'ג', selected: false},
        {key: 4, value: 'ד', selected: false},
        {key: 5, value: 'ה', selected: false},
        {key: 6, value: 'ו', selected: false},
        {key: 7, value: 'ז', selected: false}]);

    const [,,helpers]=useField(name);

    const onClickDay = (item) => {
        setDays(days.map((i) => {
            if(i.selected === true)
                i.selected = false;
            if(item.key === i.key)
                i.selected = true;
            return i;
        }));
        helpers.setValue(item.key);
    };

    useEffect(() => {
        if(day) {
            setDays(days.map((i) => {
                if(i.key === day)
                    i.selected = true;
                return i;
            }));
        }
        else {
            days[0].selected = true;
        }
    }, []);

    return (
        <>
            <css.wrapDays>
                <css.text>{t('weekDay')} </css.text>
                <>
                    {days?.map((item) => {
                        return (<css.wrapDay onClick={() => onClickDay(item)} className={!!item.selected && 'selected'}>
                            <css.day data-cy={`day-${item.key}`}>{item.value}</css.day>
                        </css.wrapDay>);
                    })
                    }</>
            </css.wrapDays>
        </>
    );
}

export default SelectDay;
