import {useSelector} from 'react-redux';
import * as css from './ParticipantsPart.css';
import {useTranslation} from 'react-i18next';

function ParticipantsPart({ticket,eventType}) {
    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const {t} = useTranslation('ticket');

    return (
        <css.block>
            <css.text>
                <css.width45>
                    {t('sale')}
                </css.width45>
                <css.boldText> {(ticket.soldPrice || 0)}</css.boldText>
                {currencySymbol}
            </css.text>
            <css.text>
                <css.width45 >
                    {t(eventType+'Participants')}
                </css.width45>
                <css.boldText>
                    {ticket.amount ? `${ticket.sold||0}${'/'}${ticket.amount}`: t('missing')}
                </css.boldText>
                &nbsp;{t(eventType+'Pcs')}
            </css.text>
        </css.block>
    );
}
export default ParticipantsPart;
