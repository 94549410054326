import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import InputAmount from '../../../../../component/input/inputAmount/InputAmount';
import InputList from '../../../../../component/input/inputList/InputList';
import TextareaInput from '../../../../../component/input/textareaInput/TextareaInput';
import {getCrowds} from '../../../../../redux/crowds/crowdsActions';
import AccountPermission from '../../../../components/accountPermission/AccountPermission';
import * as css from './PaymentsGlobal.css';

function PaymentsGlobal({children}) {
    const {t} = useTranslation('ticket');
    const globalT = useTranslation('global').t;
    const crowds = useSelector((state) => state?.crowds?.crowds);

    const getCrowdsList = () => {
        if(crowds) {
            let copyCrowds = [...crowds];
            return [{crid: null, title: t('noCrowd')}, ...copyCrowds].map((i) => ({
                ...i,
                text: i.title,
                key: i.crid,
            }));
        } else
            return [{crid: null, title: t('noCrowd')}].map((i) => ({
                ...i,
                text: i.title,
                key: i.crid,
            }));
    };

    useEffect(() => {
        if(!crowds?.crowds) {
            const fetchData = async () => {
                await getCrowds();
            };
            fetchData();
        }
    }, []);
    
    return (
        <>
            <css.wrapAll>
                {!!children && (
                    <>
                        <css.text>{t('billingAndPaymentSettings')}</css.text>
                        {children}
                    </>
                )}
                <css.wrapSettings>
                    <css.text>{t('advancedTicketSettings')}</css.text>
                    <TextareaInput name={'text'} text={t('ticketText')} />
                    <AccountPermission permission={'crowds'}>
                        <css.wrapGrid>
                            <css.wrapCrowd>
                                <InputList
                                    text={t('associatingTicket')}
                                    description={t('associatingTicketDesc')}
                                    list={getCrowdsList()}
                                    name="crowd.crid"
                                    t={t}
                                />
                            </css.wrapCrowd>
                            <InputAmount
                                name={'crowd.limit'}
                                text={t('limitCrowd')}
                                description={t('limitCrowdDesc')}
                                amountText={globalT('pcs')}
                            />
                        </css.wrapGrid>
                    </AccountPermission>
                </css.wrapSettings>
            </css.wrapAll>
        </>
    );
}
export default PaymentsGlobal;
