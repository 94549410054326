import {useTranslation} from 'react-i18next';
function MailingVariables(props, textParameters) {
    const {t} = useTranslation('mailing');
    const area = props.mailingProps.area;
    const parameters=textParameters;
    let paramsTitle;
    switch (area) {
        case 'crm':
            paramsTitle = {
                eventTitle: t('eventName'),
                nameFirst: t('firstName'),
                nameLast: t('lastName'),
                timeOpenstartDate: 'תאריך התחלה',
                timeOpenstartTime: 'שעת התחלה',
                timeOpenstartDateHeb: 'תאריך התחלה עברי',
                location: 'מיקום',
                waze: 'קישור לWaze',
                ticketLink: 'קישור לכרטיס',
                ticketLinkPrint: 'קישור להדפסת הכרטיס',
                ticketName: 'שם הכרטיס',
                invoiceLink: 'קישור לקבלה',
                eventLink: 'קישור לאירוע',
                donation: 'סך לתשלום',
                currencySymbol: 'מטבע ההזמנה',
                eventCurrency: 'מטבע האירוע',
                producerName: 'שם המפיק',
                producerPhone: 'טלפון המפיק',
            };

            if(parameters['groups.groupName'])
                paramsTitle['groupName']='שם הקבוצה';
            if(parameters['ticket.ticketText'])
                paramsTitle['ticketText']='טקסט לכרטיס';
        
            break;
        case 'crowd':
            paramsTitle = {
                'crowd.title': t('crowdTitle'),
                address: t('address'),
                city: t('city'),
                birthDate: t('birthDate'),
                firstName: t('firstName'),
                lastName: t('lastName'),
                gender: t('gender'),
                tz: t('identityNumber'),
            };
            break;
        default:
    }
    return paramsTitle;
}
export default MailingVariables;