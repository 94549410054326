import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as css from './AddEventManage.css';
import EventType from './eventType/EventType';
import Close from '../../../../component/close/Close';
import {useTranslation} from 'react-i18next';
import AccountPermission from '../../../../pages/components/accountPermission/AccountPermission';
import {createEvent} from '../../../../redux/account/accountActions';
import setGlobalState from '../../../../redux/setGlobalState';
import {useHistory} from 'react-router-dom';
import {bounceMessage} from '../../../userMessages/UserMessage';
import {useRef} from 'react';

function AddEventManage({onClickClose, closeEvents=()=>{}}) {
    const {t} = useTranslation('layout');
    const permissionT = useTranslation('permission');
    const account = useSelector(state => state.account);
    const [show, setShow] = useState(0);
    const [loadingType, setLoadingType] = useState(null);
    const history = useHistory();

    const arrayTypes = [{type: 'ticket', text: t('ticketEvent'),permission:true},
        {type: 'area', text: t('areaEvent'),permission:'area'},
        {type: 'timetable', text: t('timetableEvent'),permission:'timetable'},
        {type: 'product', text: t('product'),permission:'product'},
        {type: 'listing', text: t('listing'),permission:'listing'},
        {type: 'givechak', text: t('givechak'),permission:'givechak'},
        {type: 'frontpage', text: t('frontpage'),permission:'home'}, 
        {type: 'course', text: t('course'),permission:'course'},
        {type: 'hub', text: t('hub'),permission:'hub'}
    ];

    useEffect(() => {
        if(account.createEvent && account.createEvent.eid) {
            if(loadingType!=='course'){
                history.push(`/e/${account.createEvent.eid}/event`);
                setGlobalState('account.createEvent', null);
                setLoadingType(null);
            }
        }
    }, [account.createEvent]);

    const timer = useRef();

    const closeAll=()=>{
        onClickClose();
        closeEvents();
    };
    const close = (e) => {
        setShow(0);
        timer.current=setTimeout(function () {
            onClickClose(e);
        }, 400);
        if(e) {
            e.stopPropagation();
        }
    };
    const AlertNotAccount = () => {
        bounceMessage(t('alertHaveNotAccount'),'warning');
    };
    useEffect(() => {
        setTimeout(function () {
            setShow(1);
        }, 0);
        if(!accountPid)
            AlertNotAccount();
        return ()=>{
            clearTimeout(timer.current);
        };
    }, []);

    const accountPid = useSelector(s => s.account.pid);

    const getWrapComp = (children) => {
        if(!accountPid)
            return <css.inActive onClick={AlertNotAccount} >
                {children}
            </css.inActive>;
        return children;

    };
    const onCreate = async (type) => {
        setLoadingType(type);
        if(type==='ticket' || type==='area' ){
            setGlobalState('ui.show.isOpenTickchakLoader',true);
        }
        if(type === 'course'){
            const extraPermission = 'billing';
            const billingPermissionStatus = account.permissions?.filter((item) => item.permission === extraPermission)[0]?.status;
            const suspendedStatus=account.permissions?.find((item) => item.permission === 'suspended')?.status;
            if(billingPermissionStatus === 1&&suspendedStatus===1){
                setLoadingType(null);
                setGlobalState('ui.show.isOpenEasyCourseStart',true);
                closeAll();

            }
            else {
                bounceMessage(t('noBillingPermission',{permission:`${billingPermissionStatus !== 1?permissionT.t('billing'):''} ${suspendedStatus!==1?permissionT.t('suspended'):''}`}),'warning');
                setLoadingType(null);
            }
        } 
        else {
            createEvent(type).then(closeAll).catch(() => { setLoadingType(null); });
        }
    };
    return (
        getWrapComp(
            <>
                <css.addEventFrame className={show && 'show'}>
                    <css.addEventTop className={'top'}><Close onClick={close} />
                        <div>{account.pid ? account.title : t('selectAccount')}</div>
                    </css.addEventTop>
                    <css.addEventTitle>{t('addEventTitle')}<br /> {t('addEventTitle1')}</css.addEventTitle>
                    <css.addEventEvents>
                        {arrayTypes.map((item, index) =>
                            <AccountPermission key={item.type} permission={item.permission}>
                                <EventType isDisabled={!!loadingType}
                                    type={item.type} key={index}
                                    status={loadingType === item.type ? 'loading' : (loadingType && 'disabled')} onClick={() => onCreate(item.type)} text={item.text} />
                            </AccountPermission>
                        )}
                    </css.addEventEvents>
                </css.addEventFrame>
            </>

        )

    );

}

export default AddEventManage;
