import * as css from './BrandingPage.css';
import {createContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import * as brandingIcons from '../../assets/symbols/symbols/brandingIcons';
import EmailScreen from './emailScreen/EmailScreen';
import PageWrapper from '../components/pageWrapper/PageWrapper';
import {getEmailData} from '../../redux/branding/brandingActions';
import SideMenu from '../../component/sideMenu/SideMenu';
import SmsScreen from './smsScreen/SmsScreen';
import PreviewScreen from './previewScreen/PreviewScreen';
import {useSelector} from 'react-redux';
import SuspendedScreen from './suspendedScreen/SuspendedScreen';
import {useHistory, useParams} from 'react-router-dom/cjs/react-router-dom.min';
import {getEventSettings} from '../../redux/event/eventActions';
import WhatsAppScreen from './whatsAppScreen/WhatsAppScreen';
import WhatsAppPreview from './whatsAppScreen/whatsAppPreview/WhatsAppPreview';
import EmailScreenPreview from './emailScreenPreview/EmailScreenPreview';
import {hasAccountAccess} from '../../functions/permission';
import BtnLanguages from '../../component/button/btnLanguages/BtnLanguages';
import useMobile from '../../hooks/useMobile';
import {getPackageProducer} from '../../redux/account/accountActions';
import {setCurrentRating} from '../components/rating/Rating';
import setGlobalState from '../../redux/setGlobalState';

export const BrandingContext = createContext();

function BrandingPage() {
    const {t}=useTranslation('branding');
    const history = useHistory();
    const account = useSelector(s=>s.account);
    const event = useSelector(s=>s.event);
    const {currentLang,getBrandingData,updateDesignEmailXHR}=useSelector(s=>s.branding);
    const params = useParams();
    const pageId = params.pageId||'email';
    const [currentEditorName,setCurrentEditorName]=useState();

    const susStatus =event.settings?.suspended?.susStatus;
    const {packagesProducer}=account;
    const suspendedStatus=account.permissions?.find((item) => item.permission === 'suspended')?.status;
    const screensTypes = {
        email:{Comp:EmailScreen,CompPreview:EmailScreenPreview},
        ...((susStatus && suspendedStatus===1) && {suspended: {Comp: SuspendedScreen,CompPreview: PreviewScreen,CompPreviewProps: {brandingPreview: packagesProducer?.brandingSms},},}),
        sms:{Comp:SmsScreen,CompPreview:PreviewScreen, CompPreviewProps:{brandingPreview:packagesProducer?.brandingSms}},
        ...(hasAccountAccess('whatsApp') && {whatsapp:{Comp:WhatsAppScreen,CompPreview:WhatsAppPreview}})
    };

    const {Comp,CompPreview,CompPreviewProps}=screensTypes[pageId]||screensTypes['email'];

    useEffect(()=>{
        if(!event.getEventSettingsXHR)
            getEventSettings().then();
        if(!currentLang && event.lang){
            setGlobalState('branding.currentLang', event.lang);
        }
        return ()=>{
            if(updateDesignEmailXHR==='SUCCESS')
                setCurrentRating('brandingTicket');
        };
    },[]);

    useEffect(()=>{
        if(!getBrandingData?.[`${currentLang}XHR`])
            getEmailData().then();
    },[currentLang]);
    const eventPath=event.type==='course'?'courses':'tickets';
    useEffect(()=>{
        if(!screensTypes[pageId])
            history.push(`/e/${event.eid}/${eventPath}/branding/email`);
    },[pageId]);

    useEffect( () => {
        if(account.pid&& ['SUCCESS','UPDATED'].includes(account.getAccountXHR))
            getPackageProducer().then();
    }, [account.getAccountXHR]);

    const onClickMenu = (item) => {
        history.push(`/e/${event.eid}/${eventPath}/branding/${item}`);
    };
    const isMobile = useMobile();
    const operations=!isMobile?{buttons:[{key: 0, Comp: BtnLanguages}]}:{};
    return (
        <>
            <BrandingContext.Provider value={{textEditor:{currentEditorName,setCurrentEditorName}}}>
                <PageWrapper image={'ticketsGroups'} 
                    routing={{subTitle:t(pageId),title:t('branding'),id:'branding'}} 
                    SubMenuChildren={CompPreview}
                    subMenuProps={CompPreviewProps}
                    operations={operations}
                    leftTitle={t('preview')}
                >         
                    <>
                        <css.container data-cy='brandingPage'>
                            {isMobile&&
                            <css.languageBtn>
                                <BtnLanguages/>
                            </css.languageBtn>}
                            <css.designEmailArea>
                                <SideMenu
                                    icons={brandingIcons} 
                                    arrTypes={screensTypes}
                                    onClick={onClickMenu}
                                    currentPage={pageId}/>
                                <css.left>
                                    <Comp />
                                </css.left>
                            </css.designEmailArea>
                        </css.container>
                    </>
                    <CompPreview {...CompPreviewProps}/>   
                </PageWrapper>
            </BrandingContext.Provider>
        </> 
        
    );
}

export default BrandingPage;