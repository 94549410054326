import {useTranslation} from 'react-i18next';
import MoreOperations from '../../../../component/moreOperations/MoreOperations';
import {pencil} from '../../../../assets/symbols/symbols/Symbols.css';
import {updateGroup} from '../../../../redux/tickets/ticketsActions';

function GroupOptions({openPopGroup, groupInfo, tid}) {
    const {t} = useTranslation('ticket');
    const list = [
        {key: 'removeAssociation', text: t('removeAssociation'), type: 'icon', icon: false, iconClass: 'groups'},
        {key: 'ticketDetails', text: t('ticketDetails'), type: 'icon', icon: pencil, iconClass: 'groups'},
    ];
    const groupTickets = groupInfo.groupTickets;

    const onClickList = (val) => {
        if(val.key === 'removeAssociation') {
            const index = groupTickets.findIndex(obj => obj.tid === tid);
            const newData = [
                ...groupTickets.slice(0, index),
                ...groupTickets.slice(index + 1)
            ];
            updateGroup(groupInfo.gid, {tickets: newData});
        }
        else if(val.key === 'ticketDetails')
            openPopGroup();
    };

    return (
        <>
            <MoreOperations title={t('operations')} list={list} onClick={(val) => onClickList(val)} width="222px" />
        </>
    );
}

export default GroupOptions;
