import React from 'react';
import * as css from './EventMenuStats.css';
import {useTranslation} from 'react-i18next';
import EventMenuStatsItem from './eventMenuStatsItem/EventMenuStatsItem';
import {useSelector} from 'react-redux';
import {formatDecimal, formatNumber} from '../../../library/numberFunction';
import EventRolePermission from '../../../pages/components/rolePermission/EventRolePermission';
import useTablet from '../../../hooks/useTablet';
import TooltipAbsoluteSum from '../../../component/tooltip/tooltipAbsoluteSum/TooltipAbsoluteSum'; 

function EventMenuStats({mini}) {
    const {t} = useTranslation('layout');

    const event = useSelector(state => state.event);
    const stats = useSelector(s => s.realtime?.event?.eventStats);

    let cash = stats?.all?.totalPrice;
    const totalCertainFuture=stats?.all?.totalCertainFuture;
    const hasBillings=useSelector(state=>state.event.settings?.billings?.hasBillings);
    const isTablet = useTablet();
    const amountOrderTotal=stats?.all?.amountOrders;
    return (
        <>    
            <css.EventMenuStats>
                <EventRolePermission rolePermission={'access_crm'} views={[]}>
                    <EventMenuStatsItem title={t('orders')}
                        amountOrders={formatNumber(stats?.all ? (amountOrderTotal) : 0, true)}
                        amountTickets={formatNumber(stats?.all ? stats?.all?.ticketsSold : 0, true)}
                        imgOrders={'orders'} imgTickets={'tickets'} className='orders'
                        toolTip = {{amountOrders: formatNumber(stats?.all ? stats?.all?.amountOrders : 0), amountTickets: formatNumber(stats?.all ? stats?.other?.ticketsSold : 0)}}/>
                </EventRolePermission>
                <EventRolePermission rolePermission={'manage event'} views={[]}>
                    {!hasBillings&&
                (!mini && !isTablet) && <EventMenuStatsItem title={t('today')}
                        amountOrders={formatNumber((stats?.today?.amountOrders) ? (stats?.today?.amountOrders) : 0,true)}
                        amountTickets={formatNumber((stats?.today?.ticketsSold) ? stats?.today?.ticketsSold : 0, true)}
                        imgOrders={'orders'}
                        imgTickets={'tickets'}  className='tickets' 
                        toolTip = {{amountOrders: formatNumber(stats?.today?.amountOrders || 0), amountTickets: formatNumber(stats?.today?.ticketsSold || 0)}}/>}
                    <EventMenuStatsItem title={t('cash')} className='cash'
                        amountOrders={event.currencySymbol + formatDecimal(cash ? cash : 0, true)}
                        imgOrders={'cash'} toolTip = {{currencySymbol: event.currencySymbol, amountOrders: formatNumber(cash || 0)}}/>
                </EventRolePermission>
            </css.EventMenuStats>
            {!!hasBillings&&!mini&&
            <EventRolePermission rolePermission={'manage event'} views={[]}>
                <EventMenuStatsItem title={t('futureCollection')} className='future'>
                    <css.futureWrap>
                        <css.future>
                            <css.futureSum>{event.currencySymbol + formatDecimal(totalCertainFuture|| 0, true)}</css.futureSum>
                            <TooltipAbsoluteSum text={event.currencySymbol + formatDecimal(totalCertainFuture|| 0, true)} amount={`${event.currencySymbol}${formatDecimal((parseInt(totalCertainFuture)||0)+(parseInt(cash)||0), true)}`}/>
                            <css.futureText>{t('collectionBalance')}</css.futureText>
                        </css.future>
                        <css.future className='end'>
                            <css.futureSum>{`${event.currencySymbol}${formatDecimal((parseInt(totalCertainFuture)||0)+(parseInt(cash)||0), true)}`}</css.futureSum>
                            <TooltipAbsoluteSum text={`${event.currencySymbol}${formatDecimal((parseInt(totalCertainFuture)||0)+(parseInt(cash)||0), true)}`} amount={`${event.currencySymbol}${formatDecimal((parseInt(totalCertainFuture)||0)+(parseInt(cash)||0), true)}`}/>
                            <css.futureText>{t('totalIncome')}</css.futureText>
                        </css.future>
                    </css.futureWrap>
                </EventMenuStatsItem>
            </EventRolePermission>}
        </>

    );
}

export default EventMenuStats;