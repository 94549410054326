import React from 'react';
import {MenuBtn} from './UserProfileMenuButton.css';

const UserProfileMenuButton = (props) => {

    return <MenuBtn
        data-cy={`UserProfileMenuButton-${props.type}`}
        className={(props.isActive ? '' : 'clicked ')+(props.isBuilding&&'isBuilding')}
        onClick={(e) => props.clicked(props.idx,e)} >
        {props.name}
    </MenuBtn>;
};

export default UserProfileMenuButton;