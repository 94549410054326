export const getMessages = (t,data) => {
    const getErrorsMessages = (items, type) => {
        if(items && items.length > 0) {
            return `${t(`${type}Error`)}: ${items.map((item) => item.message).join(', ')}. `;
        }
        return '';
    };
  
    const [extrasMessages, paymentsMessages, ticketPriceMessages] = ['extras', 'payments', 'ticketPrice'].map((type) =>
        getErrorsMessages(data[type], type)
    );
  
    return `${extrasMessages}${paymentsMessages}${ticketPriceMessages}`;
};
  
export const hasError = (errors) => {
    for(const key in errors) {
        if(Array.isArray(errors[key]) && errors[key].length > 0) {
            return true;
        }
    }
    return false;
};