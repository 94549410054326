import * as css from './TableTitleItem.css';
import List from '../../../component/list/List';
import useShow from '../../../hooks/useShow';
import {ThemeProvider} from 'styled-components';
import {Fragment} from 'react';
import useTablet from '../../../hooks/useTablet';

function TableTitleItem({item,helpers}) {
    const {open, close, isOpen, showClass} = useShow();
    const {action,field}=item;

    const isTablet=useTablet();
    const cssCondition={wrapperWidth:isTablet?Fragment:css.wrapperWidth};

    return (
        <ThemeProvider theme={{direction: helpers.currentFilter?.direction , action,isCurrent:!!helpers.currentFilter}}>
            <cssCondition.wrapperWidth  className={`defaultColumn ${field} ${field}Column`}>
                <css.wrapper data-cy={`${field}SortBtn`} onClick={action==='filter'?(isOpen?close:open):helpers.onSort} >
                    <css.cancel data-cy={`${field}SortClose`} {...helpers.currentFilter?{onClick:helpers.onCancel,className:'active'}:{}}/>
                    <css.text>{item.text}</css.text>
                    <css.arrow/>
                    {isOpen && <List title={item?.list?.title} close={close} onClick={helpers.onFilter} width={'200px'} list={item?.list?.array?.map(i=>({...i,dataCyName:`${field}Filter`}))} showClass={showClass} />}
                </css.wrapper>
            </cssCondition.wrapperWidth>
        </ThemeProvider>

    );
}

export default TableTitleItem;