import * as css from './Loader.css';
import Animation from '../animation/Animation';
import tableLoader from '../../assets/animations/tableLoader.json';
import isScrollEnd from '../../functions/isScrollEnd';

function Loader({children, XHR, onScrollEnd = () => { },height}){
    
    const onScroll = (e) => {
        if(isScrollEnd(e) &&  XHR !== 'LOADING') {
            onScrollEnd();
        }
    };
    return (
        <css.loaderContainer onScroll={onScroll} height={height}>
            {children}
            {XHR === 'LOADING'&&<css.loader>
                <Animation animation={tableLoader} />
            </css.loader>}
        </css.loaderContainer>

    );

}
export default Loader;

