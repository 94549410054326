import Mailing from '../pages/mailing/Pages/Mailing/Mailing';
import AccountRealtime from '../pages/realtime/accountRealtime/AccountRealtime';

export const menuAccount={
    'crm': {
        'access' : 'manage package',
        'path' : '/crm',
        'title' : 'crm',
        'subtitleItemTitle' : 'crm',
        'menu' : ['main'],
        'component': 'MyIframe',
        'permission': 'package crm'
    },
    'home': {
        'access' : 'manage package',
        'path' : '/home',
        'title' : 'home',
        'component': AccountRealtime
    },

    'cash': {
        'access' : 'manage package',
        'path' : '/cash',
        'title' : 'cash',
        'subtitleItemTitle' : 'cash',
        'menu' : ['main'],
        'component': 'MyIframe',
        'permission': 'package cash'
    },

    'cash/bank': {
        'access' : 'manage package',
        'path' : '/cash/bank',
        'title' : 'bank',
        'subMenu' : 'cash',
        'component': 'MyIframe'
    },
    'package': {
        'access' : 'manage package',
        'path' : '/package',
        'title' : 'package',
        'subtitleItemTitle' : 'package',
        'menu' : ['main'],
        'component': 'MyIframe',
        'permission': 'package package'
    },

    'package/producer': {
        'access' : 'manage package',
        'path' : '/package/producer',
        'title' : 'producer',
        'subMenu' : 'package',
        'component': 'MyIframe'
    },

    'package/bank': {
        'access' : 'manage package',
        'path' : '/package/bank',
        'title' : 'bank',
        'subMenu' : 'package',
        'component': 'MyIframe'
    },
    'package/contact': {
        'access' : 'manage package',
        'path' : '/package/contact',
        'title' : 'contact',
        'subMenu' : 'package',
        'component': 'MyIframe'
    },
    'crowd': {
        'access' : 'manage package',
        'path' : '/crowd',
        'title' : 'crowd',
        'subtitleItemTitle' : 'crowd',
        'menu' : ['main'],
        'component': 'MyIframe'
    },
    'crowd/coupons': {
        'access' : 'manage package',
        'path' : '/crowd/coupons',
        'title' : 'Coupons',
        'subMenu' : 'crowd',
        'component': 'MyIframe'
    },

    'inbox': {
        'access' : 'manage package',
        'path' : '/inbox',
        'title' : 'inbox',
        'subtitleItemTitle' : 'inbox',
        'menu' : ['main'],
        'component': 'MyIframe'
    },

    'mailing': {
        'access' : 'manage package',
        'path' : '/mailing',
        'title' : 'mailing',
        'subtitleItemTitle' : 'mailing',
        'menu' : ['main'],
        'component': Mailing
    },

    '404':{
        'access' : true,
        'front' : '404',
        'title' : 'pageNotFound'
    }
};