import styled from 'styled-components/macro';
import {ticketsGroups, ticketCourseImg} from '../../../../assets/symbols/images/Images.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 100%;
`;
export const frame = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;
export const image = styled.div`
  background-image: url("${p => p.type === 'course' ? ticketCourseImg : ticketsGroups}");
  height: 250px;
  width: 250px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: contain;
  max-height: calc(100% - 100px);
  background-position: center center;
  ${mobileMedia}{
    max-height: calc(100% - 180px);

  }
`;
export const title = styled.div`
  width: 100%;
  padding: 7px;
  text-align: center;
  font-weight: 500;
  margin: 15px auto;
`;

