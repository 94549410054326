import NewEventForm from '../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {newEventInitialValues,newEventSteps, newEventValidationSchema} from '../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

function NewHubEvent() {
    const event=useSelector(s=>s.event);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);
    const hubSteps={
        title:{...newEventSteps.title,texts:{header:'hubTitleHeader'},compProps:{text:'hubTitleHeader',description:'hubDescriptionText'}},
        location:{...newEventSteps.location,texts:{header:'hubLocationHeader'}},
        logo:{...newEventSteps.logo},
        description:{...newEventSteps.description,texts:{description:'hubDescriptionDesc',header:'description'}}
    };
    
    const {title,description,locale,location}=newEventInitialValues(event);
    
    const initialValues = {
        title,
        description,
        locale,
        location,
        logo:event.logo
    };

    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={Yup.object().shape(newEventValidationSchema(t))}
            steps={Object.values(hubSteps)}
        />
    );
}

export default NewHubEvent;
