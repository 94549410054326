import {useRef} from 'react';
import UseMouseUpOutside from '../../hooks/UseMouseUpOutside';

function WrapUseMouseUpOutside({children,callback}) {
    const ref=useRef();
    UseMouseUpOutside(ref,callback);

    return (
        <>
            {children(ref)}
        </>
    );
}

export default WrapUseMouseUpOutside;