import createReducer from '../createReducer';

const initialState = {
    parameters:[],
    sendMailingXHR:null,
    getTextParamsDefaultsXHR:null,
    buyMailingPackageXHR:null
};

const reducer = createReducer('mailing',initialState);

export default reducer;