import {useEffect} from 'react';

let onClickOutsideStatus=true;

export const updateOnClickOutsideStatus=(status)=>{
    onClickOutsideStatus=status;
};

const useOnClickOutside=(ref, handler)=>{
    useEffect(
        () => {
            const listener = (event) => {
                if(!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                if(onClickOutsideStatus)
                    handler(event);
            };
            document.addEventListener('click', listener);
            return () => {
                document.removeEventListener('click', listener);
            };
        },
        [ref, handler]
    );

};
export default useOnClickOutside;