import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as css from './SharePopUp.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {TICK_DOMAIN} from '../../../../config/config';
import PopUp from '../../../../component/popUp/PopUp';
import ImageEditor from '../../../../component/imageEditor/ImageEditor';
import Button from '../../../../component/button/button/Button';
import FileUploader from '../../../../component/dropzone/Dropzone';
import LoadScript from '../../../../library/LoadScript';
import useShow from '../../../../hooks/useShow';
import useMobile from '../../../../hooks/useMobile';

function SharePopUp(props) {
    const event=useSelector(state=>state.event);
    const user=useSelector(state=>state.user);

    const FB = window.FB;
    const {t} = useTranslation('layout');
    const [image, setImage] = useState(event.fbImg);
    const [text, setText] = useState(t('shareEventText',{pageName:event.title, link:props.getDomain(), userName:`${user.nameFirst} ${user.nameLast}`}));
    const [focus, setFocus] = useState('');
    const isMobile = useMobile();
    const openEditImage = useShow();

    const ref = useRef(null);

    const updateText = (e) => {
        setText(e.target.value);
    };
    const uploadImage = (file) => {
        setImage(file[0]);
        openEditImage.open();

    };
    const saveImage = (image) => {
        setImage(image);
        openEditImage.close();
    };
    const eventLink=encodeURI(TICK_DOMAIN + '/' + event.name );

    const eventHandlers = useMemo(() => ({
        onFocus: () => setFocus(true),
        onBlur: () => setFocus(false),
    }), []);

    // function toDataUrl(url, callback) {
    //     var img = new Image();
    //     img.crossOrigin = 'Anonymous';
    //     img.onload = function() {
    //         var canvas = document.createElement('CANVAS');
    //         var ctx = canvas.getContext('2d');
    //         var dataURL;
    //         canvas.height = this.naturalHeight;
    //         canvas.width = this.naturalWidth;
    //         ctx.drawImage(this, 0, 0);
    //         dataURL = canvas.toDataURL('jpeg');
    //         callback(dataURL);
    //     };
    //     img.src = url.src;
    //     // if (img.complete || img.complete === undefined) {
    //     //     img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    //     //     img.src = src;
    //     // }
    // }
    
    useEffect(()=>{
        (function(d, s, id) {
            let js;
            const fjs = d.getElementsByTagName(s)[0];
            if(d.getElementById(id)) {
                return;
            }
            // eslint-disable-next-line prefer-const
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/all.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    },[]);
    function publishOnFacebook() {
        if(typeof FB!=='undefined'){
            FB.init({appId: '176581646024328', status: true, cookie: true,     version    : 'v2.5'});
            FB.ui(
                {
                    method: 'feed',
                    name: 'Facebook Dialogs',
                    link: TICK_DOMAIN+'/'+event.name,
                    picture: event.fbImg,
                    caption: event.title,
                    description: text
                },
                function(response) {
                    if(response && response.postId) {
                        alert('Post was published.');
                    } else {
                        alert('Post was not published.');
                    }
                }
            );
        }

    }
    const handleOnSubmit = async () => {
        const response = await fetch(image);
        // here image is url/location of image
        const blob = await response.blob();
        const file = new File([blob], 'share.jpg', {type: blob.type});
        if(navigator.share) {
            await navigator.share({
                title: event.title,
                text: text,
                url: TICK_DOMAIN + '/' + event.name,
                files: [file]
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error in sharing', error));
        } else {
            console.log('system does not support sharing files.');
        }

    };
    useEffect(()=>{
        LoadScript('https://apis.google.com/js/api.js',function (){

            window.gapi.load('client:auth2', function() {
                window.gapi.auth2.init({clientId: 'YOUR_CLIENT_ID'});
            });

        });
    },[]);

    function authenticate() {
        return window.gapi.auth2.getAuthInstance()
            .signIn({scope: 'https://www.googleapis.com/auth/youtube.force-ssl'})
            .then(function() { console.log('Sign-in successful'); },
                function(err) { console.error('Error signing in', err); });
    }
    function loadClient() {
        window.gapi.client.setApiKey('YOUR_API_KEY');
        return window.gapi.client.load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest')
            .then(function() { console.log('GAPI client loaded for API');
                execute();},
            function(err) { console.error('Error loading GAPI client for API', err); });
    }
    // Make sure the client is loaded and sign-in is complete before calling this method.
    function execute() {
        return window.gapi.client.youtube.commentThreads.insert({
            'part': [
                text,
                'bbb'
            ],
            'resource': {
                'id': ''
            }
        }).then(function(response) {
            // Handle the results here (response.result has the parsed body).
            console.log('Response', response);
        },
        function(err) { console.error('Execute error', err); });
    }

    const share = (type) => {

        switch (type) {
            case 'facebook':
                publishOnFacebook();
                // window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(event.fbImg)+'&t='+encodeURIComponent(''),'sharer','toolbar=0,status=0,width=626,height=436');
                // window.open('https://www.facebook.com/sharer.php?u=' +eventLink + '&imageurl=' + image);
                break;
            case 'whatsapp':
                window.open('https://web.whatsapp.com/send?text=' + text);
                break;
            case 'telegram':
                window.open('https://telegram.me/share/url?url=' +eventLink+ '&text='+text);//@bold[.]("+event.fbImg+")"+''
                break;
            case 'instagram':
                break;
            case 'youTube':
                authenticate().then(loadClient);
                break;
            case 'tikTok':
                // window.open("https://www.tiktok.com/share/item/list?secUid=&id=&type=5&count=30&minCursor=0&maxCursor=0&shareUid=")
                break;
            case 'email':
                window.open('https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su='+event.title+'&body='+text+' '+eventLink+'&ui=2&tf=1&pli=1', 'sharer', 'toolbar=1,status=1,width=648,height=395');
                break;
            case 'sms':
                window.open('sms://?body='+text+' '+eventLink);
                break;
            case 'twitter':
                window.open('http://twitter.com/share?url=' + eventLink + '&text=' + text + '&', 'twitterwindow', 'height=450, width=550, top=0, left=0, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

                break;
        }
    };
    const saveBtnProps = {
        text: t('close'),
        textColor: '#fff',
        backgroundColor: '#76d6e8',
        successColor: '9CB900',
        hoverTextColor: '#76d6e8',
        hoverBackgroundColor: '#fff',
        hoverBorderColor: '#76d6e8',
        onClick: () => props.closePopUp(),
    };

    const arrShare = [{text: 'facebook', image: 'fb'}, {text: 'whatsapp', image: 'whatsapp'}, {text: 'telegram',image: 'telegram'}, {text: 'instagram', image: 'instagram'},{text:'youTube', image: 'youTube'},{text: 'tikTok',image: 'tikTok'},{text:'email', image: 'email'},{text:'sms',image: 'sms'}];
    let popupProps = [];
    popupProps['closePop'] = () => props.closePopUp();
    popupProps['imgPathClass'] = 'share';
    popupProps['title'] = t('shareTitle');
    popupProps['focus'] = focus;
    popupProps['popupContent'] =
        <css.share>
            <css.shareImage ref={ref}>
                <ImageEditor className={'share'} openEditImage={openEditImage} width={isMobile?250:280} height={isMobile?250:280} image={image} borderRadius={0} borderRadiusAfter={'0px'} top={ref?.current?.getBoundingClientRect().y}
                    save={saveImage} cancel={() => openEditImage.close()}/>
                <css.shareImageBase>
                    <css.sIBImage img={image}/>
                    <css.sIBBot>
                        <css.sIBBotChild className="upload"><FileUploader width={'20px'} height={'20px'}
                            onUpload={uploadImage} maxFiles={1}
                            type="image/*"/></css.sIBBotChild>
                        <css.sIBBotChild className="edit" onClick={() => openEditImage.open()}/>
                    </css.sIBBot>
                </css.shareImageBase></css.shareImage>
            <css.shareDetails>
                <css.shareText><textarea  {...eventHandlers}  value={text} onChange={updateText}/></css.shareText>
                <css.shareShare>
                    {isMobile?
                        <css.shareShareBtn onClick={handleOnSubmit}>{t('share')}</css.shareShareBtn>
                        // <Button BtnProps={shareMobileBtn}/>
                        :
                        arrShare.map((item, index) => <css.shareItem key={index} onClick={()=>share(item.text)}>
                            <css.shareItemImg img={item.image}/>
                            <css.shareItemText>{t(item.text)}</css.shareItemText>
                        </css.shareItem>)}
                </css.shareShare>
                {!isMobile&&<css.shareClose><Button BtnProps={saveBtnProps}/></css.shareClose>}
            </css.shareDetails>
        </css.share>;

    return (
        <css.popUp>
            {/*<div id="fb-root"></div>*/}
            <PopUp popUpProps={popupProps}/>
        </css.popUp>
    );
}

export default SharePopUp;