import styled from 'styled-components/macro';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {input} from '../../components/loginInput/LoginInput.css';
import {header} from '../signUpForm/SignUpForm.css';

export const container = styled.div`
    padding: 60px 35px;
    display: flex;
    flex-direction: column;
    ${input}{
        margin-bottom: 60px;
    }
`;

export const text = styled.div`
    font-size:12px ;
    padding-bottom: 15px;
    text-align: center;
`;

export const warpIcons = styled.div`
    display:flex ;
    padding-bottom: 10px;
    margin: 0 auto;
    width: max-content;
`;

export const icon = styled.div`
    width:30px ;
    height:30px ; 
    margin-inline-end: 10px;
    ${p => BGImageCenter(p.icon, 'cover')}
    border-radius:50% ;
`;

export const title = styled(header)`
   padding: 40px 30px;
`;