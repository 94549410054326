import React from 'react';
import './Checkbox.css';

function Checkbox(props) {
    let className = props.selected ? 'checkbox selected' : 'checkbox';
    className = props.processSuccess ? 'checkbox process-success' : className;

    return (
        <div className="checkbox-con" onClick={()=>props.handle('selected', {id:props.id, value:!props.selected})}>
            <div className={className} />
        </div>
    );
}

export default Checkbox;