import {useFormikContext} from 'formik';
import {useEffect} from 'react';
import {useRef} from 'react';
import {useContext} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Btn from '../button/btn/Btn';
import {TabsPopupContext} from '../newPopup/tabsPopup/TabsPopup';
import * as css from './PopupBottom.css';

function PopupBottom({continueBtnText,continueClick,XHR,keys}) {
    const {t} = useTranslation('ticket');
    const form=useFormikContext();
    const [isSaveShow, setIsSaveShow] = useState(false);
    const {currentStageIndex,tabsList}= useContext(TabsPopupContext);
    
    const toShowContinue = () => {
        if(currentStageIndex<0)
            return false;
        const hasError = (object) => object.some(key => form.errors.hasOwnProperty(key));
        if(hasError(keys[tabsList[currentStageIndex].type]||{}))
            return false;
        return true;
    };

    const timer=useRef(null);

    useEffect(()=>{        
        if(['SUCCESS','FIELD'].includes(XHR))
            timer.current = setTimeout(() => setIsSaveShow(false), 1000);
    },[XHR]);

    useEffect(()=>{
        form.setFieldTouched(Object.keys(form.values)[0],false   );
        if(form.dirty)
            setIsSaveShow(true);
    },[form.dirty]);

    return (
        <css.bottom >
            {!!continueBtnText && 
            <css.nextBtn className={toShowContinue()?' show':''}> 
                <Btn className={'white arrow'} onClick={continueClick}>{continueBtnText}</Btn>
            </css.nextBtn>
            }
            <css.saveBtn className={(!isSaveShow && ' hidden ')||(!form.isValid &&' disabled ') }>
                <Btn onClick={()=>{form.handleSubmit();
                    setIsSaveShow(true);}
                } className={XHR}>{t('save')}</Btn>
            </css.saveBtn>
         
        </css.bottom >
    );
}

export default PopupBottom;
