import * as css from './LeadRow.css';
import Status from '../../../../component/status/Status';
import LeadDetail from './leadDetail/LeadDetail';
import {useTranslation} from 'react-i18next';
import Copy from '../../../../component/copy/Copy';
import {useSelector} from 'react-redux';
import {useRef} from 'react';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';
import LeadOptions from '../../leadOptions/LeadOptions';
import useTablet from '../../../../hooks/useTablet';
import {updateLead} from '../../../../redux/publish/leadActions';
import ContactLead from './ContactLead';
import {formatNumber} from '../../../../library/numberFunction';

function LeadRow({lead,leadPopOpen}) {
    const {i18n} = useTranslation('publish');
    const {currencySymbol} = useSelector(s => s.event);
    const isTablet = useTablet();
    const scrollContainerRef = useRef(null);

    const handleScroll = () => {
        const container = scrollContainerRef.current;
        container.scrollTo({
            left: container.scrollLeft + (i18n.dir() === 'rtl' ? -1 : 1) * container.offsetWidth * 0.2,
            behavior: 'smooth'
        });       
    };

    const leadOptionsComponent = (
        <LeadOptions openPopup={leadPopOpen} lead={lead}/>
    );

    return (
        <css.row data-cy="leadRow">
            <css.left ref={scrollContainerRef}>  
                <css.right className='firstColumns'>
                    <Copy link={lead.link}/>
                </css.right>    
                <LeadDetail field={'title'} value={lead.title} className={'title'} isEdit onClick={()=>leadPopOpen(lead)}/>
                <LeadDetail field={'totalPrice'} value={`${formatNumber(lead.totalPrice)} ${currencySymbol}`}/>
                <LeadDetail field={'transactions'} value={lead.transactions} />
                <LeadDetail field={'tickets'} value={lead.tickets}/>
                <LeadDetail field={'ticliCount'} value={lead.ticliCount} />
                <LeadDetail field={'ticliCountUnique'} value={lead.ticliCountUnique} />
                <ContactLead lead={lead} leadPopOpen={leadPopOpen}/>
                <Status lead={lead} className={'status'}/>
                <ModeBtn status={lead.active} className={'small'} click={()=>updateLead(lead.ref,{active:!lead.active})}/>
                {!isTablet&&leadOptionsComponent}                    
            </css.left>
            {isTablet&&
                <css.mobileScroll>
                    {leadOptionsComponent}
                    <css.icon onClick={handleScroll} data-cy='scrollLeadDetails'/>
                </css.mobileScroll>}
        </css.row>
    );
}
export default LeadRow;
