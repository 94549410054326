import * as css from './ContentBottom.css';
import {useTranslation} from 'react-i18next';

function ContentBottom(props) {
    const {contentBottom} = props;
    const {t} = useTranslation('mailing'); 

    return (
        <css.contentBottom className="contentBottom">
            {(contentBottom.contentValueTemp != '' || (contentBottom.subjectTemp != '')) &&
                <css.charsCounter className="charsCounter">{contentBottom.charsCount + ' ' + t('chars')}, {contentBottom.msgCount + ' ' + t('messages')}</css.charsCounter>
            }
            <css.textStyling className="textStyling">
                <css.textEmoji className="textEmoji" />
                <css.fontColor className="fontColor" />
                <css.textUnderline className="textUnderline" />
                <css.textBold className="textBold" />
            </css.textStyling>
        </css.contentBottom>
    );
}

export default ContentBottom;