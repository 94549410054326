import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PageHeader from '../../../components/pageHeader/PageHeader';
import {useSelector} from 'react-redux';
import {getClusterEvents} from '../../../../redux/cluster/clusterActions';
import useMobile from '../../../../hooks/useMobile';

function ClusterPageHeader(props) {
    const {groupClick, setSearchState, searchState, search, setSearch,setIsSelectable,isSelectable} = props;
    const {t} = useTranslation('cluster');
    const [{limit, offset}, setLimitOffset] = useState({limit: 10, offset: 0});
    const getClusterEventsXHR = useSelector((state) => state.cluster.getClusterEventsXHR);
    const event = useSelector((state) => state.event);
    const isMobile = useMobile();
    const searchClick = () => {
        setSearchState(!searchState);
    };
    const updateLimitOffset = (name, value) => {
        setLimitOffset(prevState => ({...prevState, [name]: value}));
    };

    const getClusterEventsSearch = async () => {
        if(getClusterEventsXHR != 'LOADING')
            await getClusterEvents(event?.eid, {limit: limit, offset: offset, search: search});
    };
    const firstRun = useRef(1);
    useEffect(() => {
        if(firstRun) {
            firstRun.current = 0;
            return;
        }
        getClusterEventsSearch();
    }, [limit, offset]);
    const opList = [
        {
            key: 0, state: searchState, icon: 'search', width: searchState ? (isMobile ? 200 : 300) : 55, type: 'search', 
            onClick: searchClick,
            getSearch: () => {
                updateLimitOffset('offset', 0);
                getClusterEventsSearch();
            }, value: search, setSearch: setSearch, text: t('search')
        }
        , {key: 1, state: isSelectable, type: 'save', width: isSelectable ? 130 : 55, icon: 'group', onClick: groupClick, onClickCancel: () => setIsSelectable(false), text: isSelectable ? t('save') : t('createGroup'), showAlways: true}];
    return (
        <PageHeader title={t('clusterEvents')} op={opList} />
    );
}

export default ClusterPageHeader;
