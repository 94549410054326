import styled from 'styled-components/macro';
import {dFlexCenter, DEFAULT_COLOR, BGImageCenter} from '../../globalVariables/GlobalVariables';
import {arrowBtn, op, opWhite} from '../../assets/symbols/symbols/Symbols.css';

export const optionCon = styled.div`
        overflow: hidden;
        background-color: #FFFFFF;
        box-shadow: 0 3px 6px #00000029;
        max-width: 0;
        height: 34px;
        width: fit-content;
        position: absolute;
        top: -4px;
        inset-inline-end:-13px;
        border-radius: 25px;
        display: flex;
        transition: 800ms all;

`;
export const optionItems = styled.div`
       
       visibility: hidden;
        opacity: 0;
        display: flex;
`;
export const optionItem = styled.div`
        width: 25px;
        height: 25px;
        margin: 5px;
        border-radius: 50%;
        transition: 400ms all;
        ${p => BGImageCenter(p.icon, '16px 16px')}
        :hover {
                background-color: #BBBCBD;
        }
`;
export const opOptionIcon=styled.div`
        width: 25px;
        height: 25px;
        margin: 5px;
        border-radius: 50%;
        background-color: ${DEFAULT_COLOR};
        ${BGImageCenter(opWhite,'11px')}
`;

export const optionBtn = styled.div`
        background-image:url("${op}");
        background-repeat: no-repeat;
        width: 10px;
        height: 26px;
       
        box-shadow:unset;
        background-color:#FFFFFF;
        ${dFlexCenter};
        cursor:pointer;
        position: relative;
        :hover{
                .open {
                        max-width: 330px;
                }
                .openItems {
                        visibility: visible;
                        opacity: 1;
                        transition: all 100ms 300ms;
                }
        }

`;
export const opOpen=styled.div`
        width: 20px;
        height: 25px;
        margin: 5px;
        border-radius: 50%;
        opacity: .5;
        cursor: default!important;
        ${BGImageCenter(arrowBtn,'7px')}
        -webkit-transform: rotate(${p=>p.theme.rtl?180:0}deg);
        -ms-transform: rotate(${p=>p.theme.rtl?180:0}deg);
        transform: rotate(${p=>p.theme.rtl?180:0}deg);
       
`;

