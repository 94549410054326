import styled from 'styled-components/macro';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
    padding: 40px;
`;
export const title=styled.div`
    font-weight: 500;
    margin-bottom: 11px;
`;
export const symbolArea=styled.div`
    display: flex;
`;
export const animal=styled.div`
    width: 30px;
    height: 30px;
    background-color: ${props => props.color ? '#'+props.color: '#10C2D1'};
    border-radius: 50%;
    cursor: pointer;
    ${BGImageCenter(props => props.symbol,'20px')};
    background-image: url("${props => props.symbol}");
`;
export const flowers=styled.div`
    width: 30px;
    height: 30px;
    background-color: ${props => props.color ? '#'+props.color: '#10C2D1'};
    border-radius: 50%;
    cursor: pointer;
    ${BGImageCenter(props => props.symbol,'20px')};
    background-image: url("${props => props.symbol}");
`;
export const item=styled.div`
    width: 40px;
    height: 40px; 
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    &.current{
        color: ${props =>'#'+props.color};
        border: 2px solid;
    }

`;