import {useSelector} from 'react-redux';
import {Dot} from 'recharts';
import {BLACK,  LIGHT_GRAY} from '../../../../../globalVariables/GlobalVariables';
import {formatRealtimeDecimal} from '../../../functions';

export function CustomDot({cx, cy, value,elementRef}:{cx?:number, cy?:number, value?:any,elementRef:any} ) {
    const isDarkDisplay = useSelector((s:any) => s.realtime.user.realtimeDesign?.darkDisplay);
    const currencySymbol = useSelector((s:any) => s.event.currencySymbol);

    const text = value[1] > 0 ? `${formatRealtimeDecimal(parseFloat(value[1]).toFixed(0),true)} ${currencySymbol}` : '';

    const textLengthMultiplier = parseFloat(`1.${text.length}`).toFixed(1);
    const textLengthNumber = parseFloat(textLengthMultiplier);
    const lestItemDec = cx >= (elementRef?.current?.offsetWidth - 12) ? -12 : 0;

    const additionalOffset = text.length > 2
        ? (text.length * textLengthNumber - 1) + lestItemDec
        : -1 + lestItemDec;
    return (
        <>
            {value[1] > 0 && <Dot {...{cx, cy, value}} />}
            <text
                x={(cx > 12 ? cx + additionalOffset : 12 + additionalOffset)}
                y={cy + 7}
                fill={isDarkDisplay ? LIGHT_GRAY : BLACK}
                textAnchor="middle"
                fontSize={12}
                fontWeight={700}
            >
                {text}
            </text>
        </>
    );
}

