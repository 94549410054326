import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {subSettingInputs} from '../paymentForm/PaymentForm';
import {updateEventSettings} from '../../../redux/event/eventActions';
import SubSettingItem from '../subSettingItem/SubSettingItem';
import DataItems from '../../../component/dataItems/DataItems';

function CrowdSettings() {
    const {t} = useTranslation('event');
    const access = useSelector(state => state.event.settings?.access);
    const settingsXHR = useSelector(state => state.forms.eventSettings);
    const {getToggle, getInput} = subSettingInputs(access, 'access', settingsXHR, t);

    const crowds = useSelector(state => state.crowds.crowds);

    const getCrowdsList = () => crowds?.map(crowd => {
        let selected = false;
        if(Array.isArray(access?.crowds))
            selected = access.crowds.some(c => c.crid.toString() === crowd.crid.toString());
        return {...crowd, text: crowd.title, selected, key: crowd.crid};
    });

    const changeCrowds = (item, actionType) => {
        let newCrowds;
        if(Array.isArray(access?.crowds))
            newCrowds = access.crowds.map(c => ({title: c.title, crid: c.crid, color: c.color}));
        else newCrowds = [];
        if(actionType === 'add')
            newCrowds.push({title: item.title, crid: item.crid, color: item.color});
        if(actionType === 'remove')
            newCrowds = newCrowds.filter(c => c.crid.toString() !== item.crid.toString());
        updateEventSettings('crowds', newCrowds, 'access');

    };

    return (
        <Formik
            initialValues={{title: access?.title}}
            validationSchema={Yup.object().shape({title: Yup.string()})}
            enableReinitialize={true}
        >
            <Form>
                {getInput('title')}
                {getToggle('date')}
                {getToggle('place')}
                <SubSettingItem >
                    <DataItems list={getCrowdsList()} listText={t('addCrowd')} onChange={changeCrowds} icon="person" listWidth={'max-content'}/>
                </SubSettingItem>
            </Form>
        </Formik>);
}

export default CrowdSettings;