import SettingItem from '../settingItem/SettingItem';
import MasterPermission from '../../components/rolePermission/MasterPermission';
import AddGooglePingItem from '../addGooglePingItem/AddGooglePingItem';
import {useSelector} from 'react-redux';

function MailingForm() {
    const isShowInLive = useSelector(s=>s.event.settings.event.showInLive);

    return (
        <>
            <SettingItem name={'tickchakIndex'} />
            <MasterPermission>
                <SettingItem name={'showInLive'}/>
                <SettingItem disabled={!isShowInLive} name={'promotionInLive'} />
            </MasterPermission>
            <SettingItem name={'showInGlatticks'} disabled={true} />
            <SettingItem name={'isIndexed'}>
                <AddGooglePingItem />
            </SettingItem>
        </>
    );
}

export default MailingForm;
