import styled from 'styled-components/macro';
import {add} from '../../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter} from '../../../../../globalVariables/GlobalVariables';

export const plus = styled.div`
        width:32px;
        height: 32px;
        border-radius: 50%;
        box-shadow:unset;
        background-color:#76D6E8;
        ${dFlexCenter};
        cursor: pointer;
        :hover{
            background-color:#fff;
            border:1px solid #76D6E8;
        }
        :after{
            content: "";
            ${BGImageCenter(add,'10px')}
            width:32px;
            height:32px ;
        }
`;

export const wrapMode = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 60px;
`;
