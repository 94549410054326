import styled from 'styled-components/macro';
import {DEFAULT_COLOR, dFlexCenter} from '../../globalVariables/GlobalVariables';
import {LINE_HEIGH} from '../list/List.css';

export const container=styled.div`
    width:100% ;
    ${dFlexCenter}
    overflow:hidden ;
    height:${LINE_HEIGH}px;
`;

export const input=styled.input`
    inset-inline-end:30px;
    border:none;
    ::placeholder{
        color:${DEFAULT_COLOR}
    }
    width:205px ;
    font-weight: bold;
`;