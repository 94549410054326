import {useSelector} from 'react-redux';
import * as css from './TicketOptions.css';
import useShow from '../../../../hooks/useShow';
import {useTranslation} from 'react-i18next';
import {deleteTicket, duplicateTicket, updateGroup,updateTicket} from '../../../../redux/tickets/ticketsActions';
import ListWrap from '../../../../component/listWrap/ListWrap';
import {bag, blackDuplicate, pen, rectPlus, remove} from '../../../../assets/symbols/symbols/Symbols.css';
import Copy from '../../../../component/copy/Copy';
import getColor from '../../../../functions/getColor';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import getGuideReportOption from './guideReportOption';

function TicketOptions({ticket ,openPopup,left=25,onDeleteCallback=()=>{},onDuplicateCallback}) {
    const {t} = useTranslation('ticket');
    const {open, close, isOpen, showClass} = useShow();
    const event = useSelector(state => state.event);
    const tickets = useSelector(state => state.tickets?.tickets);

    const onDelete = async () => {
        if(ticket?.sold)
            bounceMessage(t('deleteTicketMessage'),'warning', 
                {text: t('thanks'), onClick:()=>{}},ticket.active&&{text: t('delay'), onClick:()=> updateTicket(ticket.tid, {active:0})});
        else {
            await deleteTicket(ticket.tid);
            onDeleteCallback();
        }     
    };

    const groups = useSelector(state => state.tickets.groups);
    const remainderGroups=groups?.filter(g=>!g.tickets?.some(t=>t?.tid===ticket?.tid))||[];

    const addTicketToGroup = async (item) => {
        let group=groups.find(g=>g.gid===item.key);
        const tickets = group.tickets?.map(t => {return {tid: t.tid};})||[];
        await updateGroup(item.key,{tickets:[...tickets,{tid:ticket.tid}]});
        close();
    };

    const onDuplicate = async () => {
        if(onDuplicateCallback) {
            onDuplicateCallback();
            return;
        }
        const title = `${t('copyTicket')} ${ticket.title}`;
        let color = getColor('', tickets);
        duplicateTicket(ticket.tid, {title, color}).then();
        close();
    };

    const optionsList = {
        title: t('ticket'),
        arr: [
            {text: t('duplicate'), onClick: onDuplicate , icon: blackDuplicate,dataCyName:'duplicate'},
            {type: 'icon', text: t('delete'), onClick: onDelete, icon: remove ,dataCyName:'remove'},
            // disabled: !!ticket?.sold
            ...((openPopup)?[{text: t('edit'), onClick: openPopup, icon: pen ,dataCyName:'edit'}]:[]),
            ...(event.type==='course'?[getGuideReportOption(ticket||{},event,t)]:[]),
            {
                type: 'icon', icon: rectPlus, text: t('addToTicketGroup'),
                disabled: !remainderGroups.length,
                list: {
                    title: t('addToGroup'),
                    arr: remainderGroups.map(group => ({
                        text: group.title,
                        key: group.gid,
                        onClick: addTicketToGroup,
                        type: 'checkbox',
                        color: `#${group.color}`
                    })),
                },
                dataCyName:'addToTicketGroup'
            },
            {type: 'icon', text: t('transactionTicket'), icon: bag, dataCyName:'transactionTicket', isLink: true, to: `/e/${event.eid}/crm?tid=${ticket?.tid}`, targetBlank: true},
            {text: `${t('ticket')}: ${ticket?.tid}`, rightChildren:<Copy color={ticket?.color} link={ticket?.tid}  />,dataCyName:'ticket'}
        ],
    };

    return (

        <css.options onClick={e=>{e.stopPropagation();isOpen ? close() : open();}} data-cy="ticketOptions" >
            {isOpen && <ListWrap list={optionsList} top={40}
                left={left+'px'} showClass={showClass} close={close} title={t('ticket')} />}
        </css.options>
    );
}
export default TicketOptions;
