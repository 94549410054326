import {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import PopUp from '../popUp/PopUp';
import Button from '../button/button/Button';
import MenuPopUp from './menuPopUp/MenuPopUp';
import MailingVariables from '../../library/mailingVariables';
import {saveSelection,insertTextAtCaret,restoreSelection} from '../../library/caretPosition';
import paramsParse from '../../library/paramsParse';
import * as css from './Mailing.css';
import {getTextParamsDefaults, buyMailingPackage} from '../../redux/mailing/mailingActions';
import {useTranslation} from 'react-i18next';
import OnPopProgress from './onPopProgress/OnPopProgress';
import OnPopInfoArea from './onPopInfoArea/OnPopInfoArea';
import ContentBottom from './contentBottom/ContentBottom';
import Sender from './sender/Sender';
import Scheduled from './scheduled/Scheduled';
import TestSending from './testSending/TestSending';
import SendBtn from './sendBtn/SendBtn';
import Recipients from './recipients/Recipients';
import Subject from './subject/Subject';
import ContentArea from './contentArea/ContentArea';
import Templates from './templates/Templates';
import useShowModal from '../../hooks/useShowModal';
import {getMailingCount} from '../../redux/account/accountActions';
import setGlobalState from '../../redux/setGlobalState';
import useMobile from '../../hooks/useMobile';

function Mailing(prop) {
    const mailing = useSelector(s=>s.mailing);

    const account=useSelector(state=>state.account);
    const event=useSelector(state=>state.event);
    const user=useSelector(state=>state.user);
    const props = prop.mailingProps;
    const isMobile = useMobile();

    const {t} = useTranslation('mailing');
    const [selection, setSelection] = useState(null);
    const [range, setRange] = useState(null);
    const [inputFocus, setInputFocus] = useState(0);
    const [{charsCount, msgCount}, setMsgCount] = useState({charsCount: 0, msgCount: props.recipientsLength});
    const [{openPopOnPop, templatesShow, mailingBank}, setOpenPopOnPop] = useState({openPopOnPop: 0, templatesShow: 0, mailingBank: 0});
    const [{mailOrSms, mailOrSmsText, activeTab}, setMailOrSms] = useState({mailOrSms: props.mailOrSms, mailOrSmsText: props.mailOrSms == 'email' ? t('email') : t('phone'), activeTab: props.mailOrSms});
    const [pointerEventsBody, setPointerEventsBody] = useState('');
    const [openUpgrade, setOpenUpgrade] = useState(0);
    const [upgradeEnd, setUpgradeEnd] = useState(0);
    const [{selectedPackage, packagePrice, packageEmails, packageSms}, setChosenPackage] = useState({selectedPackage: 0, packagePrice: 0, packageEmails: 0, packageSms: 0});
    const [{popMenuKey, uniqueKey, area}, setPopMenu] = useState({popMenuKey: '', uniqueKey: 0, area: ''});
    const [subjectRef, setSubjectRef] = useState(1);
    const [subjectVal, setSubjectVal] = useState('');
    const [subjectMid, setSubjectMid] = useState('');
    const [contentRef, setContentRef] = useState(1);
    const [contentVal, setContentVal] = useState('');
    const [contentMid, setContentMid] = useState('');
    const [spansArray, setSpansArray] = useState([]);
    const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
    const [{getRefSubject, getRefContent, refSubCurrent, refConCurrent}, setGetRef] = useState({getRefSubject:0, getRefContent:0, refSubCurrent:'', refConCurrent:''});

    const refMenu=useRef();
    const refCurMenu=useRef();
    const popMenuOpenClose=useShowModal(refMenu);
    const curMenuOpenClose=useShowModal(refCurMenu);

    const handlePopOnPop = (open, tmpl, bank) => {
        setOpenPopOnPop({openPopOnPop: open, templatesShow: tmpl, mailingBank: bank});
    };
    const switchTab = (e) => {
        setMailOrSms({mailOrSms: e, mailOrSmsText: e == 'email' ? t('email') : t('phone'), activeTab: e});
    };
    const focusInput = (focus, e) => {
        const onFocus = e && e.target.classList.contains('contentMiddle') ? 1 : focus;
        setInputFocus(onFocus);
    };
    const closePopOnPop = (e) => {
        // if (openUpgrade && e != 0)
        //     setOpenUpgrade(0)
        // else {
        setOpenPopOnPop(0);
        setUpgradeEnd(0);
        // }
    };
    const openPopVariables = (show) => {
        popMenuOpenClose.open();
    };
    const packageUpgrade = (open) => {
        setOpenUpgrade(open);
    };
    const handleMenuPopUp = (e) => {
        restoreSelection(range, selection);
        const parse = paramsParse('[' + e + ']', event, user, mailing.parameters);
        let newNode = document.createElement('span');
        let random = Math.round(Math.random() * 100000);
        newNode.appendChild(document.createTextNode(parse)); newNode.setAttribute('data-param', e); newNode.setAttribute('data-key', random); newNode.setAttribute('contenteditable', false);
        let newSpan = '<span data-param="' + e + '" data-key="' + random + '" contenteditable="' + false + '">' + parse + '</span>';
        setSpansArray(oldArray => [...oldArray, {elem:newSpan, parse:'['+e+']'}]);
        insertTextAtCaret(newNode, activeTab);
        setContentRef(contentRef==1?2:1);
        if(activeTab == 'email')
            setSubjectRef(subjectRef==1?2:1);
        setTimeout(function () {
            popMenuOpenClose.close();
        }, 1000);
    };
    const handleMenuPopUpContent = (key, operation, current, unique, area) => {
        const oldParse = paramsParse('[' + current + ']', event, user, mailing.parameters);
        const newParse = paramsParse('[' + key + ']',event, user, mailing.parameters);
        let content = area == 'content' ? contentMid : subjectMid;
        if(operation == 'delete')
            content = content.replaceAll('<span data-param="' + current + '" data-key="' + unique + '" contenteditable="' + false + '">' + oldParse + '</span>', '');
        else {
            let replaceWith = '<span data-param="' + key + '" data-key="' + Math.round(Math.random() * 100000) + '" contenteditable="' + false + '">' + newParse + '</span>';
            if(content)
                content = content.replaceAll('<span data-param="' + current + '" data-key="' + unique + '" contenteditable="' + false + '">' + oldParse + '</span>', replaceWith);
            setSpansArray(oldArray => [...oldArray, {elem:replaceWith, parse:'['+key+']'}]);
        }
        if(area == 'content') 
            setContentVal(content);
        else 
            setSubjectVal(content);
          
        setTimeout(function () {
            closePopParams(0);
        }, 500);
    };
    const handleDisabledSendBtn = (e) => {
        let isDisabled = false;
        if(account.mailingPackage[mailOrSms]) {
            const balance = account.mailingPackage[mailOrSms].amount;
            const rounded = msgCount / balance * 100;
            const percentage = Math.round(rounded * 100) / 100;
            isDisabled = percentage > 100 ? true : false;
        }
        setSendBtnDisabled(isDisabled);
    };
    const closePopParams = () => {
        setPopMenu((prevState) => ({...prevState, popMenuKey: null, uniqueKey: 0, area: ''}));
        curMenuOpenClose.close();
    };
    const openPopParams = (e, area) => {
        if(e.target.tagName == 'SPAN') {
            const key = e.target.dataset.param;
            const uniqueKey = e.target.dataset.key;
            setPopMenu((prevState) => ({...prevState, popMenuKey: key, uniqueKey: uniqueKey, area: area}));
            curMenuOpenClose.open();
        }
        else {
            const caret =saveSelection();
            setSelection(caret.sel);
            setRange(caret.newrange);
        }
    };
    const chosenPackage = (e) => {
        setChosenPackage({selectedPackage: e.key, packagePrice: e.price, packageEmails: e.email, packageSms: e.sms});
    };
    const upgradeNow = async (key) => {
        let pid = account.pid;
        await buyMailingPackage({pid: pid, packageNumber: selectedPackage});
    };
    const menuPopUpProps = {
        title: t('addingVariables'),
        class: 'mailing',
        popUpCss: {//It is not necessary to send all the values, there are default values
            iconClass: 'mailing',
            btnClass: 'mailingPlus',
            width: '205px',
            maxHeight: '240px',
            backgroundColor: '#fff',
            textColor: '#484848',
            titleColor: '#76D6E8',
            top: '-17px',
        },
        popUpItem: MailingVariables(prop, mailing.parameters),
        show: popMenuOpenClose.showClass,
        onClick: (e) => { handleMenuPopUp(e); }
    };
    const menuPopUpPropsCurrent = {
        title: t('deletingReplacingVariable'),
        class: 'mailing current',
        popUpCss: {//It is not necessary to send all the values, there are default values
            iconClass: 'mailing',
            btnClass: 'mailingPlus',
            width: '205px',
            maxHeight: '240px',
            backgroundColor: '#fff',
            textColor: '#484848',
            titleColor: '#76D6E8',
            left: 'auto',
            right: '-200px',
            rightShow: '31px',
            top: isMobile && inputFocus == 1 ? '118px' : '214px',
        },
        popUpItem: MailingVariables(prop, mailing.parameters),
        current: popMenuKey,
        show: curMenuOpenClose.showClass,
        onClick: (key, operation) => { handleMenuPopUpContent(key, operation, popMenuKey, uniqueKey, area); },
    };
    const onPopBtn = {textColor: '#484848', backgroundColor: '#fff', borderColor: '#484848', hoverBackgroundColor: '#76d6e8', hoverBorderColor: '#76d6e8', onClick: (e) => { closePopOnPop(e); },};
    const contentBottom = {contentMid: contentMid, subjectMid: subjectMid, charsCount: charsCount, msgCount: msgCount}; 
    let onPopCloseBtn = JSON.parse(JSON.stringify(onPopBtn)); 
    let onPopThankBtn = JSON.parse(JSON.stringify(onPopBtn)); 
    let onPopUpgradeBtn = JSON.parse(JSON.stringify(onPopBtn)); 
    let onPopWorkBackBtn = JSON.parse(JSON.stringify(onPopBtn));

    onPopCloseBtn['text'] = t('close'); onPopThankBtn['text'] = t('thanks');
    onPopUpgradeBtn['text'] = t('upgradeNow');
    onPopWorkBackBtn['text'] = t('backToWork');
    onPopThankBtn['onClick'] = onPopCloseBtn['onClick'] = (e) => { closePopOnPop(0); };
    onPopUpgradeBtn['onClick'] = openUpgrade ? (e) => { upgradeNow(e); } : (e) => { packageUpgrade(1); };
    onPopWorkBackBtn['onClick'] = (e) => { closePopOnPop(0); };

    useEffect(() => {
        const charsCount = getRefContent + getRefSubject;
        setMsgCount({charsCount: charsCount, msgCount: contentMid == '' ? props.recipientsLength : Math.ceil(charsCount / 70) * props.recipientsLength});
    }, [getRefSubject, getRefContent]);

    useEffect(() => {
        if(!mailingBank) 
            setOpenUpgrade(0);
    }, [mailingBank]);

    useEffect(() => {
        setSubjectVal(subjectMid);
        const charsCount = activeTab=='email'?(getRefContent + getRefSubject):getRefContent;
        setMsgCount({charsCount: charsCount, msgCount: contentMid == '' ? props.recipientsLength : Math.ceil(charsCount / 70) * props.recipientsLength});
    }, [activeTab]);

    useEffect(() => {
        handleDisabledSendBtn();
    }, [account.mailingPackage[mailOrSms], msgCount]);

    useEffect(() => {
        if(mailing.buyMailingPackageXHR == 'SUCCESS') {
            setGlobalState('account.mailingPackage.email.amount',account.mailingPackage['email'].amount + packageEmails);
            setGlobalState('account.mailingPackage.sms.amount', account.mailingPackage['sms'].amount + packageSms);

            // account.mailingPackage['email'].amount += packageEmails;
            // account.mailingPackage['sms'].amount += packageSms;
            setOpenUpgrade(0);
            setUpgradeEnd(1);
        }
    }, [mailing.buyMailingPackageXHR]);

    useEffect(async() => {
        await getMailingCount();
        if(props.area == 'crm')
            await getTextParamsDefaults({eid: event.eid, source: props.area});
        else
            await getTextParamsDefaults({pid: 447, source: props.area, crid: props.crid});
            // getTextParamsDefaults({ pid: account.pid, source: props.area, crid: props.crid });
    }, []);

    props['pointerEventsBody'] = pointerEventsBody;//Handle pointer event for all pop up, for sending case etc...
    props['focus'] = inputFocus;
    props['popupContent'] =
        <>
            <css.contentTop className={'contentTop ' + (inputFocus && 'focus')}>
                <Recipients inputFocus={inputFocus} isMobile={isMobile} properties={props} />
                <css.subjectAndVars className="subjectAndVars">
                    <Subject mailOrSms={mailOrSms} setSelection={setSelection} setRange={setRange} setInputFocus={setInputFocus} openPopParams={(e, area) => openPopParams(e, area)} subjectRef={subjectRef} subjectVal={subjectVal} setSubjectMid={(s) => setSubjectMid(s)} getRef={(s, cur) =>setGetRef(prevState => ({...prevState, getRefSubject: s, refSubCurrent:cur}))} />
                    <css.variablesArea ref={refMenu} className="variablesArea">
                        <css.variables className="variables" onClick={(e) => openPopVariables(1)}>{t('variables')}</css.variables>
                        <MenuPopUp menuPopUpProps={menuPopUpProps} />
                    </css.variablesArea>
                </css.subjectAndVars>
            </css.contentTop>
            <ContentArea inputFocus={inputFocus} mailOrSms={mailOrSms} setSelection={setSelection} setRange={setRange} setInputFocus={setInputFocus} openPopParams={(e, area) => openPopParams(e, area)} contentRef={contentRef} contentVal={contentVal} setContentMid={(c) => setContentMid(c)} handlePopOnPop={(o,t,b) => handlePopOnPop(o,t,b)} getRef={(c, cur) =>setGetRef(prevState => ({...prevState, getRefContent: c, refConCurrent: cur}))} />
            <div ref={refCurMenu}><MenuPopUp menuPopUpProps={menuPopUpPropsCurrent} /></div>
            <ContentBottom contentBottom={contentBottom} />
        </>;

    props['popupBottom'] =
        <>
            <css.bottomRight className={'bottomRight ' + (inputFocus && 'focus')}>
                <Sender isMobile={isMobile} mailOrSms={mailOrSms}  />
                <Scheduled />
            </css.bottomRight>
            <css.bottomLeft className="bottomLeft">
                <css.bottomBtns className="bottomBtns">
                    <css.exclamationMark className={'exclamationMark ' + (inputFocus && 'focus')} onClick={() => { handlePopOnPop('show', 0, 1); }} />
                    <SendBtn mailOrSmsText={mailOrSmsText} mailOrSms={mailOrSms} pointerEvents={setPointerEventsBody} subject={refSubCurrent} content={refConCurrent} area={props.area} crowd={props.crid?props.crid:null} spansArray={spansArray} crowdField={mailing.parameters['crowdField.*']} handlePopOnPop={() => { handlePopOnPop('show', 0, 1); }} sendBtnDisabled={sendBtnDisabled} recipientsSms={props.recipientsSms} recipientsMails={props.recipientsMails} />
                    <TestSending mailOrSmsText={mailOrSmsText} mailOrSms={mailOrSms} pointerEvents={setPointerEventsBody} subject={refSubCurrent} content={refConCurrent} area={props.area} crowd={props.crid?props.crid:null} spansArray={spansArray} crowdField={mailing.parameters['crowdField.*']} />
                </css.bottomBtns>
            </css.bottomLeft>
        </>;

    props['tabsSwitching'] = [
        {imgClass: 'email', key: 'email', onChange: (e) => { switchTab(e); }},
        {imgClass: 'sms', key: 'sms', onChange: (e) => { switchTab(e); },}
    ];

    props['activeTab'] = {activeTab};

    //Handle pop on pop
    props['topPopClick'] = props['bottomPopClick'] = (e) => { focusInput(e); };
    props['openPopOnPop'] = openPopOnPop;
    props['closePopOnPop']=closePopOnPop;

    if(templatesShow == 1) {
        props['popOnPopTopHeight'] = '102px';
        props['popOnPopTitle'] = t('messageTemplates');
        props['popOnPopTopContent'] = <></>;
        props['popOnPopContent'] =
            <>
                <css.popOnPopEmpty className="popOnPopEmpty" />
                <Templates properties={prop}  area={props.area} setSubject={(s) => setSubjectVal(s)} setContentVal={(c) => setContentVal(c)} closePopOnPop={(c) => closePopOnPop(c)} />
            </>;
        props['popOnPopBottom'] =
            <>
                <Button BtnProps={onPopCloseBtn} />
            </>;
        props['popOnPopImage'] =
            <css.popOnPopImage className="popOnPopImage" />;
    }

    if(mailingBank == 1) {
        const balance = account.mailingPackage[mailOrSms]?account.mailingPackage[mailOrSms].amount:100;
        const rounded = msgCount / balance * 100;
        const percentage = Math.round(rounded * 100) / 100;
        const infoAreaProps={msgCount:msgCount, openUpgrade:openUpgrade, upgradeEnd:upgradeEnd, balance:balance, mailOrSms:mailOrSms, selectedPackage:selectedPackage, packages:account};
        props['popOnPopTopHeight'] = openUpgrade ? '108px' : '335px';
        props['popOnPopTitle'] = t('mailingBank');
        props['popOnPopTopContent'] =
            <>
                {!!upgradeEnd &&
                    <css.endSummary>
                        <css.popOnPopWow className="popOnPopWow">{t('wowYouHave')} {account.mailingPackage[mailOrSms].amount} {mailOrSms == 'sms' ? t('textMessages') : t('messages')} {t('forRealization')}</css.popOnPopWow>
                        <css.popOnPopInvoice className="popOnPopInvoice">{t('chargedTotal')} ₪{packagePrice} <br /> {t('InTheNextInvoice')}</css.popOnPopInvoice>
                    </css.endSummary>
                }
            </>;
        props['popOnPopContent'] =
            <>
                {(!openUpgrade && !upgradeEnd) && <OnPopProgress percentage={percentage} />}
                <OnPopInfoArea infoAreaProps={infoAreaProps} chosen={(e) => chosenPackage(e)} />
            </>;
        props['popOnPopBottom'] =
            <>
                {!upgradeEnd ?
                    <><Button BtnProps={onPopUpgradeBtn} />
                        <Button BtnProps={onPopThankBtn} /></>
                    :
                    <><Button BtnProps={onPopWorkBackBtn} /></>
                }
            </>;
        props['popOnPopImage'] =
            <css.popOnPopImage className={'popOnPopImage bank ' + (openUpgrade && 'upgrade ') + (upgradeEnd && ' upgradeEnd')} />;
    }
    //Till here pop on pop

    return (<PopUp popUpProps={props} />);
}

export default Mailing;
