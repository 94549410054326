import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import {error}  from '../../assets/symbols/symbols/Symbols.css';
export const icon = styled.div`
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background-color: #DD2F21;
      ${BGImageCenter(error, '12px 12px')}
      ${dFlexCenter};
`;