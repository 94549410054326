import styled from 'styled-components/macro';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const table = styled.div`
    width:100% ;
    position:relative ;
    //padding-inline: 20px;
    height:calc(100% - 10px);
    ${mobileMedia}{
      height:calc(100vh - 190px) ;
    }

`;