import PackagePopup from '../componnent/packagePopup/PackagePopup';
import PopUpFrame from '../../../component/popUpFrame/PopUpFrame';
import AccountRolePermission from '../../components/rolePermission/AccountRolePermission';

function Package(props) {
    const {close, showClass} = props;

    return (
        <AccountRolePermission rolePermission={'manage package'} views={[]}>
            <PopUpFrame showClass={showClass} >
                <PackagePopup close={close} showClass={showClass} />
            </PopUpFrame>
        </AccountRolePermission>
        
    );

}
export default Package;
