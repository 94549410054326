import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Copy from '../../../../component/copy/Copy';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';
import {TICK_DOMAIN} from '../../../../config/config';
import useMobile from '../../../../hooks/useMobile';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import {ts2date, ts2time} from '../../../../library/dateFunctions_old_to_be_deprecated';
import {addEventToGroup, deleteEventCluster, deleteEventFromGroup, updateEventActive} from '../../../../redux/cluster/clusterActions';
import setGlobalState from '../../../../redux/setGlobalState';
import store from '../../../../store';
import {addEidsGroup} from '../Cluster';
import {getEvents} from '../clusterEvents/ClusterEvents';
import ClusterOptions from '../clusterOptions/ClusterOptions';
import ClusterPopupMobile from '../clusterPopupMobile/ClusterPopupMobile';
import ClusterRightPart from '../clusterRightPart/ClusterRightPart';
import MobileClusterRow from '../mobileClusterRow/MobileClusterRow';
import * as css from './ClusterEventRow.css';

export const handleState = (active, event, tLayout) => {
    let cgid = null;
    store.getState().cluster?.groups.map((item) => {
        if(cgid == null) {
            if(item.events) {
                item.events.map((item1) => {
                    if(item1.eid == event.eid) {
                        cgid = item.cgid;
                        return;
                    }
                });
            }
        }
        else return;
    });

    if(!active) {
        bounceMessage(tLayout.t('userMessages', {event: event.title}),
            'warning',
            {text: tLayout.t('btnMessage1'), onClick: async () => await updateEventActive(event.eid, {active: active}, cgid)},
            {text: tLayout.t('btnMessage')}
        );

    }
    else {
        updateEventActive(event.eid, {active: active}, cgid);
    }
};

function ClusterEventRow({event, setShowPopup, refTable, myRefs, updateShowEvents, editPopUP ,checked,cgid}) {
    const {t} = useTranslation('cluster');
    const tLayout = useTranslation('layout');
    const isMobile = useMobile();
    const cluster = useSelector((state) => state.cluster);
    const eventState = useSelector((state) => state.event);
    const [groupsList, setGroupsList] = useState([]);
    const [showDetails, setShowDetails] = useState(0);
    const prePath = '/e/' + event.eid;
    const history = useHistory();

    useEffect(() => {
        setGroupsList(cluster.groups?.filter(i => i.cgid).map((g, index) => ({key: index, cgid: g.cgid, text: g.title, option: 'addEventGroup'})));
    }, [cluster.groups]);

    const scrollClick = (cgid) => {
        let index = groupsList.filter(item => item.cgid == cgid)[0].key;
        refTable.current.scrollTo({top: myRefs.current[index].offsetTop - 165, left: 0, behavior: 'smooth'});
    };

    const handleOnClickItem = async (item) => {
        close(); 
        let newEvent;
        switch (item.option) {
            case 'delete':
                bounceMessage(t('errorDeleteEventFromGroup', {event: event.title}),
                    'warning',
                    {text: tLayout.t('btnMessage1'), onClick: async () => await deleteEventCluster(eventState?.eid, event.eid, cgid)},
                    {text: tLayout.t('btnMessage')}
                );
                break;
            case 'duplicate':
                newEvent = Object.assign({}, event);
                newEvent.cgid = cgid;
                setGlobalState('preparingEvent.eventDup', newEvent);
                setShowPopup(1);
                break;
            case 'deleteFromGroup':
                await deleteEventFromGroup(eventState?.eid, event, {cgid: 0}, cgid);
                break;
            case 'addEventGroup':
                scrollClick(item.cgid);
                updateShowEvents(item.cgid, true);
                cluster.groups.map((i) => {
                    if(i.cgid == item.cgid)
                        if(!i.events)
                            getEvents(eventState?.eid, item.cgid);
                });
                await addEventToGroup(eventState?.eid, event, {cgid: item.cgid});
                break;
            case 'createGroup':
                addEidsGroup([event]);
                break;
            case 'transactions':
                history.push(prePath + '/crm');
                break;
            case 'tickets':
                history.push(prePath + '/tickets');
                break;
            case 'subList':
                return [{}, {}];
        }
    };

    return (
        <>{
            isMobile ?
                <MobileClusterRow event={event} openEditPopup={editPopUP} setShowDetails={setShowDetails} checked={checked}/>
                :
                <css.row >
                    <ClusterRightPart event={event} openPopup={editPopUP} />
                    <css.middle>
                        <css.block>
                            <css.text><css.wrapText>{t('date')}</css.wrapText><css.boldText>{ts2date(event?.timeStart)}</css.boldText></css.text>
                            <css.text><css.wrapText >{t('time')}</css.wrapText><css.boldText>{ts2time(event?.timeStart)}</css.boldText></css.text>
                        </css.block>
                    </css.middle>
                    <css.left>
                        <Copy link={`${TICK_DOMAIN}/${store.getState().event?.name}/form/tickets?event=${event.eid}${(cgid ? `&group=${cgid}` : '')}`} />
                        <ModeBtn className=" small " click={(val) => handleState(val, event, tLayout)} status={event.active} />
                        <ClusterOptions event={event} groupsList={groupsList} handleOnClickItem={handleOnClickItem} />
                    </css.left>
                </css.row>
        }
        {(!!showDetails && isMobile) &&
                <ClusterPopupMobile eid={event.eid} cgid={cgid} groupList={groupsList}
                    close={() => setShowDetails(0)}
                    handleClickOpTable={handleOnClickItem}
                />
        }
        </>
    );
}
export default ClusterEventRow;
