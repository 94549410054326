import * as css from './MailingSettings.css';
import Progress from '../progress/Progress';
import {useTranslation} from 'react-i18next';
import {formatDecimal} from '../../../../library/numberFunction';
import MailingPackage from '../mailingPackage/MailingPackage';
import {Form, Formik} from 'formik';
import NumberInput from '../../../../component/input/NumberInput';
import useAccountSettings from '../../hooks/useAccountSettings';
import * as Yup from 'yup';

function MailingSettings() {
    const {account, update, getBillClassName, isMasterState} = useAccountSettings();
    const {t} = useTranslation('package');
    const initialValues = {
        freeTicketToEvent: account.billPackage?.freeTicketsAmount||0,
        messageBeyondPackage: formatDecimal(account.billPackage?.chargeSms),
        messageBeyond: formatDecimal(account.billPackage?.chargeEmail)
    };

    const validationSchema = Yup.object().shape({
        freeTicketToEvent: Yup.number().required().min(0).max(100),
        messageBeyondPackage: Yup.number().required().min(0),
        messageBeyond: Yup.number().required().min(0)
    });

    const getNumberInputProps = (name, icon)=> ({
        name,
        text: t(name),
        icon,
        className: getBillClassName('mailing'),
        events: {onBlur: (name, value) => update(name, value, true)},
        disabled: !isMasterState,
        forwardRef: undefined
    });

    return (<>
        <css.container>
            <css.progresses>
                <Progress title={t('SMS')}
                    partialValue={account?.billPackage?.totalAmounts?.totalSms - account.billPackage?.smsFreeAmount || 0}
                    totalValue={account?.billPackage?.totalAmounts?.totalSms} />
                <Progress title={t('emails')}
                    partialValue={account.billPackage?.totalAmounts?.totalEmails - account.billPackage?.emailFreeAmount || 0}
                    totalValue={account.billPackage?.totalAmounts?.totalEmails} />
                <Progress title={t('freeTickets')}
                    partialValue={account.billPackage?.freeTickets?.tickets }
                    totalValue={account.billPackage?.freeTicketsAmount } />
            </css.progresses>
            <Formik 
                initialValues={initialValues} 
                validationSchema={validationSchema} 
                enableReinitialize
            >
                <Form>
                    <css.inputs>
                        <css.side>
                            <NumberInput {...getNumberInputProps('freeTicketToEvent', 'rect')}/>
                        </css.side>
                        <css.side>
                            <NumberInput {...getNumberInputProps('messageBeyondPackage', 'nisBubble')}/>
                        </css.side>
                        <css.side>
                            <NumberInput {...getNumberInputProps('messageBeyond', 'mail')}/>
                        </css.side>
                    </css.inputs>
                </Form>
            </Formik>
            <css.wrapPackages>
                <css.packages>
                    {account.mailingPackageList?.map(mailingPackage => 
                        <MailingPackage key={mailingPackage.mpid} mailingPackage={mailingPackage} />
                    )}
                </css.packages>    
            </css.wrapPackages>
        </css.container>

    </>
    );
}

export default MailingSettings;
