import styled from 'styled-components/macro';
import zIndex from '../../../globalVariables/zIndex';

export const warp=styled.div`
height: max-content;
position: relative;
`;
export const children=styled.div`
    position: absolute;
    inset-inline-start: 0;
    top:15px;
    z-index: ${zIndex.baseRaised};
`;