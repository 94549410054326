import styled from 'styled-components/macro';
import {dFlexCenter, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';

export const wrapTitle=styled.div`
  display: flex;
  padding-top:20px;
`;

export const title=styled.div`
     font-weight: 400;
`;

export const wrapAll=styled.div`
    ${dFlexCenter};
    flex-direction:column ;
    height:calc(100% - 60px) ;
    ${scrollbar}
`;

export const wrapAttendanceItem=styled.div`
    width:100% ;
    height: calc(100% - 120px);
    ${scrollbar};
    display: flex;
    align-items: center;
    flex-direction: column;
  margin-top: 100px;
  ${mobileMedia}{
    margin-top: 60px;

  }
`;
