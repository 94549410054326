import {useTranslation} from 'react-i18next';
import * as css from './TicketsTableHeader.css';

function TicketsTableHeader() {
    const {t} = useTranslation('realtime');

    return (
        <css.header>
            <css.ticketName>
                {t('ticketName')}
            </css.ticketName>
            <css.item>
                {t('price')}
            </css.item>
            <css.item>
                {t('amount')}
            </css.item>
            <css.item>
                {t('sold')}
            </css.item>
            <css.item>
                {t('left')}
            </css.item>
            <css.item>
                {t('discount')}
            </css.item>
            <css.item>  
                {t('soldPrice')}
            </css.item>
        </css.header>
    );
}
export default TicketsTableHeader;
