import * as css from './CenteredPopup.css';
import PopUpFrame from '../popUpFrame/PopUpFrame';
import CircleClose from '../circleClose/CircleClose';

function CenteredPopup({children, title, close, showClass, forwardRef}) {
    return (<>
        <PopUpFrame showClass={showClass} forwardRef={forwardRef}>
            <css.container>
                <css.top className='centeredPopupTop'>
                    <CircleClose close={close} closeType="centeredPopupClose"/>
                    <css.topText className=''>{title}</css.topText>
                </css.top>
                {!children[1] ?
                    children
                    :
                    <>
                        <css.center className='centeredPopupCenter'>
                            {children[0]}
                        </css.center>
                        <css.buttons className='centeredPopupButtons'>
                            {children[1]}
                        </css.buttons>
                    </>}

            </css.container>
        </PopUpFrame>
    </>
    );
}

export default CenteredPopup;
