import {useSelector} from 'react-redux';

const useTeamMembers=()=>{
    const team=useSelector(s=>s.team);
    const accountMembers=[],eventMembers=[];

    team.members.forEach(member => {
        const accountMember= team.accountMembers.find(accountMember=>accountMember.uid==member.uid);
        if(accountMember)
            accountMembers.push({...member,...accountMember});
        const eventMember= team.eventMembers.find(eventMember=>eventMember.uid==member.uid);
        if(eventMember)
            eventMembers.push({...member,...eventMember});
    });

    return {accountMembers,eventMembers,members:team.members};

};

export const getAccountOnlyMembers=(accountMembers,eventMembers)=>accountMembers.filter(am=>!eventMembers.some(em=>em.uid===am.uid));

export default useTeamMembers;