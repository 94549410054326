import * as css from './MemberSmall.css';
import MemberOptions, {useEditAccountMember} from '../memberOption/MemberOption';
import MemberRoleBtn from '../memberRoleBtn/MemberRoleBtn';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';
import {useOpenMemberPop} from '../memberBig/MemberBig';

function MemberSmall({member,rolePermissionState,rolePermission,isAccountPage}) {
    const editAccountMember=useEditAccountMember();

    const isUnauthenticated=member.status==='unauthenticated';
    const name = !isUnauthenticated?`${member.nameFirst} ${member.nameLast}`:member.email;
    const open=useOpenMemberPop();
    const isAccountMemberInEventPage=(!isAccountPage&& member.isPackageRole);
    return (
        <css.container >
            <css.image  image={member.picture}
                className={isAccountMemberInEventPage?' isPackageRole ':isUnauthenticated &&' disabled'}
                {...(isUnauthenticated?{}:{onClick:isAccountMemberInEventPage?()=>editAccountMember(member):()=>open(member)})}/>
            <css.name >{name}</css.name>
            <MemberRoleBtn member={member}/>
            <EventRolePermission  rolePermission={rolePermission} >
                <css.option>
                    <MemberOptions
                        isAccountPage={isAccountPage}
                        deletePermission={rolePermissionState}
                        right={-25} 
                        member={member}
                    />
                </css.option>
            </EventRolePermission>
        </css.container>
    );
}

export default MemberSmall;
