import styled from 'styled-components/macro';
import {container ,icon} from '../../classes/input';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';

const beforeAfter = (props) =>
    ` ::after{
    content:"${props.theme.type == 'time' ? '' : props.Idate}";
    position: absolute;
    height: 18px;
    bottom: -19px;
    ${props.theme.rtl ? 'right:130px' : 'left:200px'};
    font-size: 11px;
    letter-spacing: 1px;
}
::before{
    content:"${props.theme.type == 'date' ? '' : props.Itime}";
    position: absolute;
    height: 18px;
    bottom: -19px;
    ${props.theme.type == 'time' ?
        (props.theme.rtl ? 'right:120px' : 'left:120px') : (props.theme.rtl ? 'right:185px' : 'left:152px')};
    font-size: 11px;
    letter-spacing: 1px;
}`;

export const inputDate = styled(container)`
    @media screen and (max-width: 750px){  
        margin-bottom: 50px;
  } 
    input{
        border: 0;
        outline: 0;
        font-size:14px;
    }   
`;
export const diIcon = styled(icon)`
    ${props => props.theme.notValid && 'display: none'};
`;
export const lableInputDate = styled.div`
    font-size:14px;
    margin-${props => props.theme.rtl ? 'right' : 'left'}:5px;
    margin-top: 3px;
    width: 110px;
    letter-spacing: 1px;
    ${props => props.theme.notValid && 'color: #dd2f21'};
`;
export const wrapDate = styled.div`
    margin-${props => props.theme.rtl ? 'right' : 'left'}:5px;
    border-${props => props.theme.rtl ? 'right' : 'left'}:0px;
    position: relative;
    display: flex;
    width: 100%;
    border-bottom: 1px solid;
    &.defaultInput{
          border-${props => props.theme.rtl ? 'right' : 'left'}:1px solid;
          border-bottom: 0; 
        ::after{
             content:"";
         }
         ::before{
            content:"";
        }     
        :hover{
            border-right: none;
            border-bottom: 1px solid;
            ${props => beforeAfter(props)}
        }  
     }
    ${props => beforeAfter(props)}

    input[type=${props => props.theme.type}] {
        background:${props => props.theme.bgColor ? props.theme.bgColor : 'white'};
        position: relative;
        font-weight: 500;
        color: ${props => props.theme.notValid ? '#dd2f21' : ''};
    }
    input[type=${props => props.theme.type}]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        background: transparent;
        cursor: pointer; 
    }

    @media screen and (max-width: 750px){
        width: 275px;
    } 

    ${props => props.theme.notValid &&
        `border-bottom: 1px solid #dd2f21;
        ::after{
            content:"`+ props.validationText + `";
            position: absolute;
            height: 20px;
            bottom: -22px;
           `+ (props.theme.rtl ? 'right' : 'left') + `:2px;
            font-size: 11px;
            letter-spacing: 1px; 
            pointer-events: none;
            font-weight: bold;
        }
        ::before{
            content:"";
        }`
    }   

`;

export const isValid = styled.div`
${props => props.theme.notValid && `background-color: #fff ;
     font-size:0;  
     background-repeat:no-repeat;  
     background-image:url("${symbol['ExclamationMark']}"); 
     background-position: center center;
     background-size: 8px 10px;
     pointer-events:none;
     border: 1px solid #dd2f21;
     width: 18px;
     height: 18px;
     border-radius: 50%;
     margin: 3.5px 3.5px 0;`
    } 
`;
