import styled from 'styled-components/macro';
import {desktopMedia, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {side as packageSide} from '../packageSettings/PackageSettings.css';

export const container=styled.div`
     ${mobileMedia}{

    }
    ${desktopMedia}{ 

    }
    padding-top: 10px;
    `;

export const progresses=styled.div`
    display: flex;
    justify-content: space-around;
    ${desktopMedia}{
        padding: 20px 20px;
    }
`;
export const inputs=styled.div`
    padding: 20px 0;

    ${desktopMedia}{
        display: flex;
        padding:0 30px;
    }
`;
export const packages = styled.div`
    display: flex;
    width: max-content;
`;
export const wrapPackages = styled.div`
    ${scrollbar}
`;
export const side=styled(packageSide)``;

export const mailingName=styled.div`
    font-weight: 600;
`;
export const mailingTitle=styled.div`
    font-size: 14px;
`;

