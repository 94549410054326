import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import * as css from './ClusterGroup.css';
import ClusterGroupTitle from './clusterGroupTitle/ClusterGroupTitle';
import ClusterEventRow from '../clusterEventRow/ClusterEventRow';
import {useTranslation} from 'react-i18next';
import DragTable from '../../../../component/newTable/dragTable/DragTable';
import useMobile from '../../../../hooks/useMobile';

function ClusterGroup(props) {
    const {checked, group, index, updateShowEvents, showEvent, setShowPopup, refTable, myRefs, editPopUP} = props;
    const {getEvents, onDragEnd} = props;
    const {t} = useTranslation('cluster');
    const event = useSelector((state) => state.event);
    const isMobile = useMobile();
    const [{limit, offset}, setLimitOffset] = useState({limit: 10, offset: 0});
    const updateLimitOffset = (name, value) => {
        setLimitOffset(prevState => ({...prevState, [name]: value}));
    };
    const onScroll = (e) => {
        if(Math.round(e.target.scrollHeight - e.target.scrollTop) === Math.round(e.target.clientHeight)) {//cluster.getEventsLoading!='loading'
            updateLimitOffset('offset', group.events.length);
        }
    };
    const isFirst = useRef(true);

    useEffect(() => {
        if(isFirst.current) {
            isFirst.current = false;
            return;
        }
        // if (offset % 10 == 0)
        getEvents(event?.eid, group.cgid, limit, offset);
    }, [offset]);

    useEffect(() => {
        if(showEvent && !group.events?.length && group.cgid)
            getEvents(event?.eid, group.cgid, limit, offset);
    }, [showEvent]);

    return (
        <>
            {!!group.cgid && <ClusterGroupTitle group={group} showEvent={showEvent} updateShowEvents={updateShowEvents} getEvents={getEvents} index={index} />}
            <css.groupEvents className={(showEvent && 'open ')} height={group.events?.length} onScroll={onScroll}>
                {!!(group.events) &&
                    <DragTable rowHeight={isMobile ? 115 : 70} checked={(group?.cgid === 0) ? checked : {}} title={t('clusterEvents')}
                        list={group?.events.map(event => ({comp: ClusterEventRow, props: {event,cgid:group.cgid, setShowPopup, refTable, myRefs, updateShowEvents, editPopUP, checked}, key: event.eid}))}
                        onDragEnd={onDragEnd}  />
                }
            </css.groupEvents>
            {!!group.cgid && <css.closeGroup className={(showEvent && 'show ')} color={group?.clusterGroupColor} onClick={() => updateShowEvents(group.cgid, !showEvent)} />}
        </>
    );
}

export default ClusterGroup;
