import React from 'react';
import * as css from './DragFile.css';
import {useTranslation} from 'react-i18next';
import Btn from '../button/btn/Btn';
import {useRejectedFiles} from '../../hooks/useRejectedFiles';
import ImageUpload from '../imageUpload/ImageUpload';

function DragFile({onDrop,isForMobile}) {
    const {t} = useTranslation('newEvent');
    const {t:globalT} = useTranslation('global'); 
    const  {rejectedMessage,checkRejectedOnUpload}=useRejectedFiles();

    const onUploadFile=(base64S,acceptedFiles,rejectedFiles)=>{
        checkRejectedOnUpload(rejectedFiles);
        if(acceptedFiles.length)
            onDrop(base64S,acceptedFiles,rejectedFiles);
    };
      
    return ( 
        <css.container>
            <ImageUpload  onDrop={onUploadFile}>{
                (openFileExplorer)=>
                    <css.uploadWrap>
                        <css.errorMessage className={rejectedMessage?'error':''}>{rejectedMessage}</css.errorMessage>
                        <css.dragToHear>{t(isForMobile?'dragMobile':'dragHere')}</css.dragToHear>
                        <css.supportedFiles>{t('supportedFiles')}</css.supportedFiles>
                        <Btn onClick={openFileExplorer} className={'white'} style='border-color:black;'>
                            {globalT('uploadFile')}
                        </Btn>
                        <css.access>{t('accessImage')}</css.access>
                    </css.uploadWrap>
            }
            </ImageUpload>
        </css.container>
    );
}

export default DragFile
;