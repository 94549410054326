import styled, {keyframes} from 'styled-components/macro';
import {DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';
import {btnPlus} from '../btnPlus/BtnPlus.css';
const shakeHorizontal = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
`;
export const btn = styled.div`
  width: max-content;
  background-color:${DEFAULT_COLOR};
  border-radius: 20px;
  overflow: hidden;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 19px;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;
  &.animation {

    -webkit-animation: ${shakeHorizontal} 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both  ;
    animation: ${shakeHorizontal} 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation-delay: 1000ms;
  }

  ${btnPlus}{
    :hover{

      background-color: ${DEFAULT_COLOR};
    }
    &.open {
      background: ${DEFAULT_COLOR};
    }
  }
`;
export const text = styled.div`
  height: 26px;
  overflow: hidden;
  max-width: 0;
  width: max-content;
  transition:all 0.3s ;
  margin-inline-end: 0;
  white-space:nowrap;
  &.big{
    max-width: 220px;
    margin-inline-end: 20px;
  }
`;

