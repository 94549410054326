import MemberOptions from '../../memberOption/MemberOption';
import MemberRoleBtn from '../../memberRoleBtn/MemberRoleBtn';
import * as css from './MemberSmallLoader.css';
import React from 'react';

function MemberSmallLoader() {

    return (
        <css.container >
            <css.image/>
            <css.name/>
            <MemberRoleBtn member={[]}/>
            <css.option >
                <MemberOptions left={-25} member={[]}/>
            </css.option>
        </css.container>
    );
}
export default MemberSmallLoader;