import styled from 'styled-components/macro';
import { buttonNo } from '../../../../component/button/btnAdd/BtnAdd.css';
import {BGImageCenter, DEFAULT_COLOR, dFlexCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {container as memberSmall} from '../../../team/memberSmall/MemberSmall.css';
import {BACKGROUND_COLOR} from '../packagePopup/PackagePopup.css';

export const container = styled.div``;
export const top = styled.div`
padding: 20px;
font-size: 16px;
font-weight: 700;
letter-spacing: 1px;

`;

export const header = styled.div`
height: 100px;
display: flex;
font-size: 14px;
font-weight: 400;
justify-content: space-between;
align-items: center;
`;
export const title = styled.div`

`;
export const addNew = styled.div`
${buttonNo}{
  zoom: 0.7;
  background-color: transparent;
  border:1px solid ${DEFAULT_COLOR};
}
`;
export const group = styled.div``;
export const team = styled.div`
  padding-top: 40px;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  ${mobileMedia}{
    justify-content:space-evenly;
  }
`;

export const wrapMember=styled.div`
    ${dFlexCenter}
    position: relative;
    /* zoom: 0.85; */
    width: 120px;
    height: 250px;
    border-top: 1px solid #BBB;
    
    ${memberSmall}{
      max-width: 120px;
        padding-top: 25px;
        min-width: 120px;
        height: 240px;
        padding-bottom: 35px;
    }
    &.new{
      >div{
        height:198px;
      ${mobileMedia}{
        zoom: 0.9;
      }
      }
    }
    &.firstInGroup {
      ::before {
        top: -10px;
        inset-inline-start: -15px;
        position: absolute;
        content: "";
        background-color: ${BACKGROUND_COLOR};
        width: 30px;
        height: 10px;
      }
      ::after{
        content: "${p=>p.groupName}";
        position: absolute;
        padding-inline-start:17px ;
        top: -30px;
        inset-inline-start: 15px;
        ${p=>BGImageCenter(p.icon)}
        background-position: ${p=>p.theme.rtl?'right':'left'} center;
      }
  }`;