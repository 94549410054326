import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getSubMenu} from '../../functions/navigationFunctions';
import {hasEventRoleAccess} from '../../functions/permission';
import {menuEvent} from '../menu';
import {menuAccount} from '../menuAccount';
import useTablet from '../../hooks/useTablet';

export default function useNavigationList(idParams,pageId) {

    const isTablet = useTablet();
    const event = useSelector(state => state.event);
    const menuJson = event ? menuEvent : menuAccount;
    const {page} = useParams();
    const pageUrl = pageId||page;

    let subMenu =  getSubMenu(pageUrl,event);

    subMenu = subMenu.filter(([, item]) => ((!item.events || item.events && item.events?.indexOf(event?.type) != -1) && hasEventRoleAccess(item.access)) );
    if(subMenu.length||(isTablet && idParams &&!pageId)){
        let firstItem = Object.entries(menuJson).filter(([, item]) => item.path == `/${event.type==='givechak'&&pageUrl==='publish'?'publishGivechak':pageUrl}`)[0];
        firstItem[1]={...firstItem[1], title: `${pageUrl}Main`};
        subMenu = [firstItem,...subMenu];
    }
    return subMenu;
}
