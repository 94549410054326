import {useTranslation} from 'react-i18next';
import * as css from './RealtimeDetails.css';

function RealtimeDetail({item}) {
    const {t} = useTranslation('realtime');
    return (
        <css.section>
            <css.title>{t(item.title)}</css.title>
            <css.amount>{item.value}</css.amount>
            {!!item.text && <css.text className='free-color'>{item.text}</css.text>}
        </css.section>
    );          
}
export default RealtimeDetail;