import {useTranslation} from 'react-i18next';
import * as css from './ParticipantPresentDetails.css';
import Input from '../../../../component/input/Input';
import FormContext from '../../../components/formContext/FormContext';
import * as Yup from 'yup';
import {Form} from 'formik';
import {yupStringMin} from '../../../../functions/validationsYup';

function ParticipantPresentDetails({participant}) {

    const layoutT = useTranslation('layout');
    const initialValues={
        firstName :participant.firstName,
        lastName :participant.lastName,
        phone :participant.phone,
        email :participant.email,
    };
    const detailsSchema = Yup.object().shape(
        {
            firstName: yupStringMin().max(20).required(),
            lastName: yupStringMin().max(20).required(),
            phone:Yup.string(),
            email:Yup.string().email()
        }
    );
    return (

        <FormContext  initialValues={initialValues} validationSchema={detailsSchema} >
            <Form >
                <css.detailsGrid>
                    <Input text={`${layoutT.t('firstName')}`} disabled={true} name='firstName'  />
                    <Input disabled={true} text={`${layoutT.t('lastName')}`}  name='lastName'  />
                    <Input disabled={true}  text={`${layoutT.t('phone')}`} name='phone'  />
                    <Input disabled={true} text={`${layoutT.t('email')}`}  name='email'  />
                </css.detailsGrid>
            </Form>
        </FormContext>

    );
}

export default ParticipantPresentDetails;
