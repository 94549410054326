import styled from 'styled-components/macro';
import {BGImageCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';

export const container = styled.div`
    display: block;
`;

export const close = styled.div`
    height: 50px;
    padding:15px;
    
`;

export const status = styled.div`
    height: 27px;
    width: 100%;
    display: flex;
    padding: 0 30px;
    justify-content: flex-end;
`;

export const eventWarp = styled.div`
    display: flex;
    align-items: center;
    padding:0 50px;
    ${mobileMedia}{
        padding: 30px 20px;
    }
`;
export const eventPicture = styled.div`
    width:150px;
    height: 120px;
    border-radius:100px;
    ${props=>BGImageCenter(props.image,'cover')}
    ${mobileMedia}{
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }
`;

export const eventSettingWarp = styled.div`
    display: block;
    padding-inline-start:30px;
    width: calc(100% - 150px);
    ${mobileMedia}{
        padding-inline-start:10px;
        width: calc(100% - 70px);

    }
`;

export const eventSetting = styled.div`
    font-size: 40px;
    font-weight: 600;
    ${mobileMedia}{
        font-size: 20px;
    }
`;
export const eventDetailsWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const eventName = styled.div`
    white-space: nowrap;
    font-size: 20px;
    font-weight: 500;
    max-width:50%;
    width: max-content;
    overflow: hidden;
    ${mobileMedia}{
        font-size: 13px;
        max-width: 60%;
    }
`;

export const dateWarp = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width:50%;
   padding-inline-start:10px;
    padding-top:2.5px;

`;

export const eventDate = styled.div`
    font-size: 18px;
    font-weight: 200;
    ${mobileMedia}{
        font-size: 12px;
        padding:0 2px;
        &.end{
        display: none;
    }
    }

`;