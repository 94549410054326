import React from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './EmailSubjectTextEditor.css';
import {useSelector} from 'react-redux';
import {updateDesignEmail} from '../../../redux/branding/brandingActions';
import TextEditor from '../../../component/textEditor/TextEditor';
import {useRef} from 'react';

export const removeTagsFromText = (text) => {
    return text.replace(/<[^>]*>/g, '');
};

function EmailSubjectTextEditor({name='',dependencies}) {
    const {currentLang,data} = useSelector(s => s.branding??{});
    const {t} = useTranslation('branding');
    const subject = data?.[currentLang]?.[name];

    const quillRef = useRef(null);

    const updateSubject = async (subject) => {
        if(subject?.length>1 && subject!=='undefined')
            await updateDesignEmail({[name]:removeTagsFromText(subject)});
    };

    return (   
        <css.wrapper data-cy="EmailSubjectTextEditor">
            <css.wrapEditor text={t('emailSubject')}>
                <TextEditor defaultText={!!subject && `<p>${subject}</p>`} dependencies={dependencies} showToolbar={false} quillRef={quillRef} onSave={updateSubject} limit={{maxChar:80}} lang={currentLang}/>
            </css.wrapEditor>
        </css.wrapper>
    );
}

export default EmailSubjectTextEditor;
