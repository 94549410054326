import * as css from './SubSettingItem.css';

function SubSettingItem({text, children,hasExplanation=false,disabled}) {

    return (
        <css.container className={disabled&&' disabled'}>
            <css.setting>          
                <css.text  className='settingItem'>{text}</css.text>
                <css.leftWarp hasExplanation={hasExplanation}>
                    {children}
                </css.leftWarp>
            </css.setting>
        </css.container>

    );
}

export default SubSettingItem;
