import {useState, useEffect, useRef} from 'react';
import * as css from './InputSearch.css';
import useTimeout from '../../hooks/useTimeout';

function InputSearch(props) {
    const {search,setSearch,onClick,onChange,getSearch,value,autoFocus= {autoFocus:true}}=props;

    const [searchState, setSearchState] = useState(search);
    const timer = useTimeout();

    const ref=useRef(0);
    const handleSearchClick = (e) => {
        setSearchState(searchState ? 0 : 1);
        e.stopPropagation();
    };
    const handleInputClick = (e) => {
        e.stopPropagation();
    };
    const handleSearch=(e)=>{
        if(setSearch)
            setSearch(e.target.value);
        ref.current++;
        if(onChange)
            onChange(e);
    };
    // const blur = (e) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     setSearchState(0);
    // };
    useEffect(() => {
        if(props.inputState)
            props.inputState(searchState);
    }, [searchState]);

    const isFirstRun = useRef(true);
    useEffect (() => {
        if(isFirstRun.current) {
            isFirstRun.current=false;
            return;
        }
        let thisCount=ref.current;
        timer.current=setTimeout(function() {
            if(thisCount !== ref.current)
                return;
            if(getSearch)
                getSearch();
        },700);
    },[value]);
    return (
        <css.Search data-cy={`input-artists`} className={'search ' + (searchState && 'focus')} onClick={handleSearchClick}>
            {/* <css.Search onBlur={blur} className={"search " + (searchState && "focus")} onClick={handleSearchClick}> */}
            {!searchState ? <css.SearchText className="searchText">{props.text}</css.SearchText> :
                <css.SearchInput {...autoFocus} placeholder={props.text} name={props.name} value={value}
                    onChange={handleSearch} onClick={handleInputClick}/>}
            <css.SearchClose className={'searchClose ' + (searchState && 'close')} onClick={onClick} />
        </css.Search>
    );

}

export default InputSearch;
