import * as css from './DaysPicker.css';
import {useTranslation} from 'react-i18next';
import {If, Then, Else} from 'react-if';
import {useField} from 'formik';
import {getDate, getDaysDifference} from '../../library/dates';
import DatePicker from '../FormikDatePicker/DatePicker';

// export function ts2date(ts) {
//     if(!ts)
//         return '';
//     var date = new Date(ts * 1000);
//     var year = date.getFullYear();
//     var month = (1 + date.getMonth()).toString();
//     month = month.length > 1 ? month : '0' + month;
//     var day = date.getDate().toString();
//     day = day.length > 1 ? day : '0' + day;
//     return year + '-' + month + '-' + day;
// }

// export function getDays(start, end) {
//     if(start && end) {
//         const date1 = new Date(start);
//         const date2 = new Date(end);
//         const oneDay = 1000 * 60 * 60 * 24;
//         const diffTime = date2.getTime() - date1.getTime();
//         const diffDays = Math.round(diffTime / oneDay);
//         return diffDays;
//     }
// }

function DaysPicker(props) {
    const {onBlur, className, disabled = false} = props;

    const {start} = props.start;
    const {endName} = props.end;
    // const [days, setDays] = useState();
    const [field, meta] = useField(endName);
    // const {setValue} = helpers;
    const end = field.value;
    const {t} = useTranslation('package');

    // const change = (e) => {
    //     setValue(getTs(e.target.value));
    //     helpers.setTouched(true);
    // };

    const blur = () => {
        if(!meta.error&&meta.touched)        
            onBlur(endName, meta.value);
    };
    
    // useEffect(() => {
    //     setDays(getDaysDifference(start, end));
    // }, [end]);
    const classes=`${className}  ${!!meta.touched && (meta.error ? ' invalid' : ' valid')}`;

    return (<>
        <css.container className={classes}>
            <css.icon className={classes} />
            <css.wrap className={'hasIcon'} >
                <css.inputs>
                    <css.wrapDate>
                        <css.date>{getDate(start)}</css.date>
                        <If condition={disabled}>
                            <Then>
                                <css.date>{getDate(end)}</css.date>
                            </Then>
                            <Else>
                                {/* <Field type="date" value={getDate(end)} name={endName} id="t2" onBlur={blur}/> */}
                                <DatePicker name={endName} onChange={blur}/>
                                {/*<Field*/}
                                {/*    name={endName}*/}
                                {/*    render={() => (*/}
                                {/*        <input type="date" value={meta.value} onChange={change} onBlur={blur}  />*/}
                                {/*    )}*/}
                                {/*/>*/}
                            </Else>
                        </If>
                        <css.sumDays>{getDaysDifference(start, end)}</css.sumDays>
                    </css.wrapDate>
                </css.inputs>
                <css.text>
                    <css.textStartEnd> {t('startDate')} </css.textStartEnd>
                    <css.textStartEnd> {t('endDate')} </css.textStartEnd>
                    <css.sumDaysText> {t('daysToExercise')} </css.sumDaysText>
                </css.text>
            </css.wrap>
        </css.container>
    </>
    );
}

export default DaysPicker;
