import {useTranslation} from 'react-i18next';
import InnerPop from '../../newPopup/innerPop/InnerPop';
import * as css from './WarningPopup.css';
import {useSelector} from 'react-redux';

function WarningPopup({show, setApprove}) {
    const {t} = useTranslation('layout');
    const {nameFirst, nameLast} = useSelector((s:any)=>s.user);
    return (
        <css.wrapAll>
            <InnerPop dataCy='warning' show={show} title={''} buttons={{whiteBtn:{text:t('close')}, blueBtn:{text:t('save'), onClick: ()=>{setApprove(); show.close();}}}}>
                <css.content>
                    <css.image />
                    <css.title>{t('warningPopTitle', {firstName: nameFirst, lastName: nameLast})}</css.title>
                    <css.text>{t('warningText')}</css.text>
                    <css.text className={'color bold'}>{t('warningTextColor')}</css.text>
                    <css.text className={'bold'}>{t('warningTextBold')}</css.text>
                </css.content>
            </InnerPop>
        </css.wrapAll>
    );
}

export default WarningPopup;
