import {useTranslation} from 'react-i18next';
import * as css from './CourseDatePop.css';
import {Form, Formik, useField} from 'formik';
import PopOnPop from '../../../../../component/newPopup/popOnPop/PopOnPop';
import {useRef} from 'react';
import SelectDay from '../../../../../component/selectDay/SelectDay';
import * as Yup from 'yup';
import {addBtn, closeBtn, editBtn} from '../../../../../functions/globalBtns';
import Button from '../../../../../component/button/button/Button';
import InputTimeAndDuration from '../../../../../component/input/inputTimeAndDuration.js/InputTimeAndDuration';
import {getHourMinutesDate, timeToDate} from '../../../../../library/dates';

function CourseDatePop({showPopOnPop, item}) {
    const {t} = useTranslation('ticket');
    const [courseField, , fieldHelpers] = useField('course');
    let course = courseField.value;
    const formRef = useRef();

    const initialValues = {
        day: item ? item.item.day : 1,
        start: item ? timeToDate(item.item.start) : new Date((new Date()).setHours(9,0,0,0)),
        end: item ? timeToDate(item.item.end) : null,
        duration: item ? item.item.duration : '',
    };

    const validationSchema = Yup.object().shape({
        duration: Yup.number().max(720),
    });

    const handleSubmit = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const addDate = (v) => {
        const values = {...v};
        values.start = getHourMinutesDate(v.start);
        values.end = getHourMinutesDate(v.end);
        if(item) {
            let arr = [...course.meetings];
            arr[item.index] = values;
            fieldHelpers.setValue({...course, meetings: arr});
        }
        else {
            if(course.meetings) {
                fieldHelpers.setValue({
                    ...course, meetings: [...course.meetings, values]
                });
            }
            else {
                fieldHelpers.setValue({
                    ...course, meetings: [values]
                });
            }
        }
        showPopOnPop.setShow(0);
    };

    return (
        <>
            <PopOnPop dataCy="courseDate" image={'groupColor'} title={t('meetingSettings')} showPopOnPop={showPopOnPop}>
                <Formik innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={values => { addDate(values); }}
                >
                    <Form>
                        <css.wrapContentPop>
                            <css.wrapAll>
                                <css.wrapDays>
                                    <SelectDay name='day' day={item?.item?.day} />
                                </css.wrapDays>
                                <InputTimeAndDuration inputTimeProps={{text: t('hours'), descTex: t('hoursDesc'), startName: 'start', endName: 'end'}}
                                    inputAmountProps={{name: 'duration', text: t('courseLength'), icon: 'clock', amountText: t('minutesShort')}} />
                            </css.wrapAll>
                        </css.wrapContentPop>
                        <css.bottomPop>
                            <Button BtnProps={closeBtn(t, () => showPopOnPop.setShow(0))} dataCyName="courseDateCloseBtn"> </Button>
                            <Button BtnProps={item ? editBtn(t, handleSubmit) : addBtn(t, handleSubmit)}dataCyName="courseDateDoneBtn" />
                        </css.bottomPop>
                    </Form>
                </Formik>

            </PopOnPop>
        </>
    );
}

export default CourseDatePop;