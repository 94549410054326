import styled from 'styled-components/macro';
import * as symbol from '../../../../../assets/symbols/symbols/Symbols.css';

export const category = styled.div`
  width: 70%;
  margin-${props => props.theme.rtl ? 'right' : 'left'}:5px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid;
  ::after{
           content:"${props => props.categoryText}";
            ${props => props.theme.rtl ? 'right' : 'left'}:31px;
            position: absolute;
            top: 26px;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 1px;
        }
  @media screen and (max-width: 750px){  
    width: 250px;
  } 
  &.defaultInput{
     border-${props => props.theme.rtl ? 'right' : 'left'}: 1px solid;
     border-bottom: 0px;
  }
  input{
    border:none;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
    margin-top: 3px;
    background-color: white;
   
  }
`;
export const wrapCategory = styled.div`
    display: flex;
    margin-bottom: 30px;
    @media screen and (max-width: 750px){  
      margin-bottom: 50px;
    } 
`;
export const IconCategory = styled.div`
    background-image:url("${symbol['category']}");
    height:25px;
    width: 26px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
`;
export const wrapInputTwo = styled.div`
    position: relative;
    width: 50%;
    padding: 0 0 0 48px;
    @media screen and (max-width: 750px){  
      width: 100%;
      padding: 0px;
    } 
`;
export const wrapInput = styled.div`
    width: 50%;
    padding: 0 0 0 48px;
          @media screen and (max-width: 750px){  
            width: 275px;
            padding: 0; 
      }
        .detail{
          @media screen and (max-width: 750px){  
            height: 75px;
        } 
      }
    @media screen and (max-width: 750px){  
      width: 100%;
    } 
    
`;
export const wrapAll = styled.div`
    display: flex;
    width: 100%;
    @media screen and (max-width: 750px){  
      flex-direction: column;
    } 
`;

export const wrapList = styled.div`

`;
export const iconArrow = styled.div`
    background-image:url("${symbol['arrowDown']}");
    height:25px;
    width: 25px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border-bottom: 1px solid;
`;