import CircleProgress, {percentage} from '../../../../../component/circleProgress/CircleProgress';
import * as css from './TicketProgress.css';

function TicketProgress({ticket, onClick, children, styleProps: styleP = {}}) {
    const color = `#${ticket.color}`;
    const styleProps = {pathColor: color, trailColor: '#FFF', pathTransitionDuration: 0.7, strokeWidth: 7, strokeTrailWidth: 0, ...styleP};
    return (
        <>
            <css.circleWrap className='circleWrap' color={color} onClick={onClick}>
                <CircleProgress totalValue={ticket.amount} partialValue={ticket.sold} styleProps={styleProps}>
                    <css.circle className='ticketProgress_circle' color={color}>{percentage(ticket.amount, ticket.sold)}
                        <css.percent>%</css.percent>
                        {children}
                    </css.circle>
                </CircleProgress>
            </css.circleWrap>
        </>

    );
}
export default TicketProgress;
