import styled,{keyframes} from 'styled-components';
const successAnim = keyframes`  
    0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
}
    30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
}
    40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
}
    50% {
    -webkit-transform: scale3d(2.15, 1.85, 1);
    transform: scale3d(2.15, 1.85, 1);
}
    65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
}
    75% {
    -webkit-transform: scale3d(2.05, 1.95, 1);
    transform: scale3d(2.05, 1.95, 1);
}
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
`;
export const save=styled.div`
    position:absolute;
    height:100%;
    ${props=>props.theme.rtl?'right':'left'}:0;
    width:${props=>props.width};
    transition:all ${props=>props.speed=='slow'?'15s':props.speed=='not'?'0ms':'300ms'};
    background-image: ${props=>props.theme.rtl?'linear-gradient(270deg, rgb(216 216 216), transparent)':'linear-gradient(270deg,transparent,#d8d8d8)'};
    opacity:1;
    &.null{
        transition:opacity 100ms,width 100ms 100ms;
        opacity:0;
    }
`;
export const saveSuccess=styled.div`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='51.179' height='51.317' viewBox='0 0 51.179 51.317'%3E%3Cdefs%3E%3Cstyle%3E.a,.b,.c%7Bfill:none;stroke:%23aed11c;%7D.a,.c%7Bstroke-linecap:round;stroke-miterlimit:10;%7D.a%7Bstroke-width:5px;%7D.b,.c%7Bstroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(1 0.319)'%3E%3Cpath class='a' d='M790,13.144l4.045,4.045L802.233,9' transform='translate(-771.141 12.078)'/%3E%3Cpath class='b' d='M817-51' transform='translate(-793.191 51)'/%3E%3Cline class='c' y1='4.961' transform='translate(23.844 0.681)'/%3E%3Cline class='c' y1='4.961' transform='translate(23.844 45.037)'/%3E%3Cline class='c' x2='4.961' transform='translate(44.219 26.153)'/%3E%3Cline class='c' x2='4.961' transform='translate(0 26.153)'/%3E%3Cline class='c' y1='3.123' x2='3.123' transform='translate(37.148 5.957)'/%3E%3Cline class='c' y1='3.123' x2='3.123' transform='translate(5.507 37.688)'/%3E%3Cline class='c' x2='3.123' y2='3.123' transform='translate(37.819 38.583)'/%3E%3Cline class='c' x2='3.123' y2='3.123' transform='translate(6.058 8.152)'/%3E%3C/g%3E%3C/svg%3E");    
    width: 60px;
    height: 50px;
    ${props=>props.theme.rtl?'right: calc(100% - 490px)':'left:calc(100% - 530px)'};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50px 50px;
    transition: all 100ms;
    transform:scale(0);
    position:absolute;
     @media screen and (max-width: 1000px){
        ${props=>props.theme.rtl?'right':'left'}: calc(100% - 125px);
    } 
    &.SUCCESS{
         -webkit-animation: ${successAnim} 0.9s both;
         animation: ${successAnim} 0.9s both;
         transform:scale(1);
    }
    &.FAILED{
        transform:scale(1);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.814' height='21.781' viewBox='0 0 16.814 21.781'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:red;stroke:%23f00000;%7D.a%7Bstroke-linecap:round;stroke-miterlimit:10;stroke-width:5px;%7D.c%7Bstroke:none;%7D.d%7Bfill:none;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-15.402 -12.763)'%3E%3Cpath class='a' d='M794.045,13.818h0L798.863,9' transform='translate(-547.436 576.281) rotate(-45)'/%3E%3Cg class='b' transform='translate(21.09 29.544)'%3E%3Ccircle class='c' cx='2.5' cy='2.5' r='2.5'/%3E%3Ccircle class='d' cx='2.5' cy='2.5' r='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
    
`;