import {FieldArray, useField} from 'formik';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import FormikFileUpload from '../../../component/fileUpload/FormikFileUpload';
import AbsoluteImageEditor from '../../../component/fullEditImage/absoluteImageEditor/AbsoluteImageEditor';
import Input from '../../../component/input/Input';
import {getUserInfo} from '../../../redux/user/userActions';
import store from '../../../store';
import * as css from './About.css';
import SocialNetwork from './socialNetwork/SocialNetwork';

function About() {

    const {t} = useTranslation('team');

    const inputProps=(name)=>({name,text:t(name),description:t(`${name}Desc`),className:name});
    
    const [galleryField]=useField('gallery');

    useEffect(()=>{
        let params = new URLSearchParams(location.search);
        const isCurrentUser=params.get('teamMemberPop')==='user';
        if(isCurrentUser&&!store.getState().user.getUserInfoXHR)
            getUserInfo().then();
    },[]);

    return (
        <>
            <css.container>
                <Input {...inputProps('about')} icon='feather'/>
                <Input {...inputProps('address')} icon='location'/>
                <Input {...inputProps('education')} icon='education'/>
                <Input {...inputProps('quote')} icon='bubble'/>
                <FormikFileUpload name='certificate'  text={ t('certificate')} desc={t('certificateDesc')} />
                <css.galleryTitle className='galleryTitle'>{t('gallery')}</css.galleryTitle>
                <css.gallery >
                    <FieldArray
                        name='gallery'
                        render={(arrayHelpers)=><>
                            {galleryField?.value?.map((item,index)=> <css.image key={ index} image={galleryField.value[index]}data-cy="uploadedImage">
                                <css.removeImage onClick={()=>arrayHelpers.remove(index)} data-cy="removeImage"/>
                            </css.image>)}
                            <css.addImage>
                                <AbsoluteImageEditor setImage={arrayHelpers.push} size={ {width: 150, height: 150}}/>
                            </css.addImage>
                        </>
                        }
                    />
                    
                </css.gallery>
                <SocialNetwork/>
            </css.container>
        </>
       
    );
}

export default About;
