import styled from 'styled-components/macro';
import {item} from '../permissionsItem/PermissionsItem.css';

export const header=styled(item)`
`;

export const title=styled.div`
    font-size: 12px;
    width: 100%;
    height: 70%;
    border-bottom: 1px solid #AAA;
    display:flex;
    letter-spacing: 1.5px;
`;

