import styled from 'styled-components/macro';
import {image, icon, right} from '../../../../../tickets/components/ticketRow/rightPart/RightPart.css';
import {placeForDrag} from '../../../../../../component/newTable/newRow/Row.css';
import {BGImageCenter, VERY_LIGHT_GRAY, ellipsisLines} from '../../../../../../globalVariables/GlobalVariables';
import {simpleArrow} from '../../../../../../assets/symbols/symbols/Symbols.css';

export const container = styled.div`

`;
export const firstPart = styled.div`
    display:flex;
    cursor: pointer;
    height: 100%;
    border-inline-end: 0.5px solid ${VERY_LIGHT_GRAY};
    transition: all 200ms;
    ${p => p.theme.active&& 'transform:scale(1.05)'};
`;

export const placeDrag = styled(placeForDrag)`

`;
export const ticketIcon = styled(icon)`
    background-image: none;
    width: 50px;
    border-radius: 5px;
    ${image}{
      display: none;
    }
`;
export const ticketRight = styled(right)`
    width: 100%;
    padding-inline-end:0;
`;
export const wrapper = styled.div`
`;

export const ticketTitle = styled.div`
    ${ellipsisLines(2)};
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0;
    word-break: break-all;
`;
export const arrow = styled.div`
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
    transform: ${p=>p.isOpen ? 'rotate(180deg)' : 'rotate(90deg)'};
    ${BGImageCenter(simpleArrow, '10px')}
    cursor: pointer;
`;