import * as css from './SubPermission.css';
import {useTranslation} from 'react-i18next';

function SubPermission({name,children}) {
    const {t}=useTranslation('permission');
    return (<>
        <css.header >
            <css.title>{t(name)}</css.title>
        </css.header>
        {children}
    </>
    );
}

export default SubPermission;