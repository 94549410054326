import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {btn} from '../button/btn/Btn.css';
import {ExclamationMark, error} from '../../assets/symbols/symbols/Symbols.css';

export const container = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
  padding-top: 0;

`;
export const uploadWrap = styled.div`
  ${dFlexCenter}
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  letter-spacing: 1px;
  ${btn} {
    border-color: black;
    width: 150px;
    font-size: 14px;
    :hover {
      color: black;
      border-color: black;
    }
  }
  ${mobileMedia}{
    ${btn} {
      margin-bottom: 90px;
    }
  }
`;

export const errorMessage = styled.div`
height: 20px;
color: #DD2F21;
display: flex;
align-items: center;
font-size: 14px;
&.error{
  ::before{
    content: '';
    border-radius: 50%;
    margin-inline-end: 10px;
    border: groove red;
    height: 20px;
    width: 20px;
    display: block;
    ${BGImageCenter(ExclamationMark,'5px')}
  }}
`;

export const dragToHear = styled.div`
  font-size: 27px;
  font-weight: 800;
  padding-bottom: 10px;
${mobileMedia}{
  margin-top: 30px;
}
`;

export const supportedFiles = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 50px;
  ${mobileMedia}{
    margin-bottom: auto;
  }
`;

export const access = styled.div`
  font-size: 13px;
  position: absolute;
  bottom: 0;
  letter-spacing: 0.8px;
  padding:0 15px;
  padding-inline-start: 55px;
  ::before{
    content: '';
    border-radius: 50%;
    height: 20px;
    width: 20px;
    border: solid white;
    background-color: #DD2F21;
    position: absolute;
    inset-inline-start: 15px;
    top: calc(50% - 12px);
    ${BGImageCenter(error,'4px')}
  }
`;
