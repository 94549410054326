import React from 'react';
import * as css from './Header.css';

function Header({Comp}) {
    return (
        <css.header>
            <Comp />
        </css.header>
    );
}
export default Header;