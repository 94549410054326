import * as css from './InputList.css';
import List from '../list/List';
import useShow from '../../hooks/useShow';

function InputList({icon, value, onChange, list, text, isSelectable = true, className, description}) {
    const {open, close, isOpen, showClass} = useShow();

    const click = (item) => {
        if(onChange)
            onChange(item);
        if(isSelectable)
            close();
    };

    const openClose = () => {
        if(isOpen)
            close();
        else
            open();
    };

    return (
        <css.container className={className}>
            <css.icon icon={icon} className={className} />
            <css.wrap>
                <css.input onClick={openClose} className={`inputList ${className}`}>{value}
                    {!!isOpen && <List close={close} onClick={click} list={list} top={25} left={'1px'} showClass={showClass} width={'100%'}  />}
                </css.input>
                <css.title>{text}<span>{description}</span></css.title>
            </css.wrap>
        </css.container>
    );
}
export default InputList;
