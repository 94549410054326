import * as css from './MemberRoleBtn.css';
import React from 'react';
import {office,designer,owner,marketingSeller,analyst,conductor,manager, team} from '../../../assets/symbols/symbols/Symbols.css';
import {YELLOW} from '../../../globalVariables/GlobalVariables';
import {useTranslation} from 'react-i18next';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import {reSendEmail} from '../../../redux/team/teamActions';
export const roleArr ={
    'waiting':{color:'#de0d0d', icon:''},
    'owner':{color:YELLOW,icon:owner},// good icons in the dev///
    'manager':{color:'#3abbd4',icon:manager},
    'designer':{color:'#C6D600',icon:designer},
    'conductor':{color:'#FFDA28',icon:conductor},
    'guide':{color:'#ff9428',icon:office},
    'representative':{color:'#ea28ff',icon:marketingSeller},
    'analyst':{color:'#F26E6E',icon:analyst},
    'allTeam':{color:'#4A90E2', icon: team}
};

function MemberRoleBtn({member, onclick=(role)=>role}) {
    const role =member.status==='unauthenticated'?'waiting': member?.role;// condition to status
    const {t} = useTranslation('layout');
    
    const click = () => {
        if(role==='waiting') {
            bounceMessage(t('reSendEmailMessage'),'attention',{text:t('reSendEmailMessageButton'),onClick:async ()=>{await reSendEmail(member);}}, {text:t('reSendEmailMessageCancelButton')} );
        }
        if(onclick) {
            onclick(role);
        }
    };
    return (

        <css.role role= {role} color={roleArr[role]?.color} icon={roleArr[role]?.icon} onClick={click}>{t(role)}</css.role>

    );
}

export default MemberRoleBtn;
