export const getAllSuppliers = (suppliers, current=false, packageSuppliers) => {
    let suppliersList = [];
    if(current)
        suppliersList.push({key:current.srid, text:`${current.srid}  ${current.description}`});
    else {
        suppliers.forEach(s => {
            suppliersList.push({key:s.srid, text:`${s.srid}  ${s.description}`});
        });
        suppliersList = suppliersList.filter(sl=> !packageSuppliers.some(ps=>sl.key===ps.srid));
    }
    return (suppliersList);
};

export const getAllCurrencies = (currencies) => {
    let currenciesList = [];
    currencies.forEach(c => {
        currenciesList.push({key:c.currency, text:`${c.currency}  ${c.title}`});
    });
    return (currenciesList);
};

export const getAllLanguages = (languages, t) => {
    let languagesList = [{key:'all', text:t('allLanguages')}];
    languages.forEach(l => {
        languagesList.push({key:l.lang, text:l.nativeName});
    });
    return (languagesList);
};

export const getAllEvents = (events) => {
    let eventsList = [];
    events.forEach(e => {
        eventsList.push({key:e.eid, text:`${e.eid}  ${e.title?e.title:''}`, selected: false, type: 'checkbox'});
    });
    return (eventsList);
};

export const getAssignedEvents = (events) => {
    let eventsList = [];
    events.forEach(e => {
        eventsList.push({key:e.eid, text:`${e.eid}  ${e.title?e.title:''}`});
    });
    return (eventsList);
};

export const checkIfBitOrBilling = (srid, allSuppliers) => {
    let supplierType = allSuppliers?.find(s => s.srid===srid).type;
    if(supplierType==='cc')
        supplierType=false;
    return (supplierType);
};

export const bitBillingsValidations = (values, type) => {
    if(type==='bit'){
        values.clearingCurrency='ILS';
        values.currency='ILS';
        values.languageAssign=null;
    }
    if(type==='monthly'){
        values.clearingCurrency=null;
        values.currency=null;
        values.languageAssign=null;
    }
    return (values);
};

export const currencyAndLangValidation = (packageSuppliers, srid, currency, lang) => {
    let sameCurrency = packageSuppliers?.find(s => s.srid!==srid && s.currency===currency && s.type!=='bit' && s.isDefault);
    let sameLang = packageSuppliers?.find(s => s.srid!==srid && s.languageAssign===lang && s.languageAssign!=='' && s.languageAssign!=='all' && s.isDefault);
    return ({sameCurrency,sameLang});
};

export const defaultValidation = (isDefault, bitOrMonthly, packageSuppliers, srid, currency, lang) => {
    if(isDefault && !bitOrMonthly){
        let sameCurrency = packageSuppliers?.find(s => s.srid!==srid && s.currency===currency && s.type!=='bit' && s.isDefault);
        let sameLang = packageSuppliers?.find(s => s.srid!==srid && s.languageAssign===lang && s.languageAssign!=='' && s.languageAssign!=='all' && s.isDefault);
        if(sameCurrency || sameLang)
            return (sameCurrency? 'sameCurrencyText' : 'sameLangText');
    }
    else if(isDefault){
        const isExistBitOrBilling = packageSuppliers?.find(s => s.type===bitOrMonthly && s.srid!=srid);
        if(isExistBitOrBilling)
            return (bitOrMonthly==='bit'?'existBit':'existMonthly');
    }
    return (false);
};

