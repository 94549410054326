import {useField} from 'formik';
import AbsoluteImageEditor from '../../../../component/fullEditImage/absoluteImageEditor/AbsoluteImageEditor';
import * as css from './LogoStep.css';
import {updateEvent} from '../../../../redux/event/eventActions';
// import ImagePopup from '../../../../component/imagePopup/ImagePopup';

export const onClickLogo=async(changedValues,_,form) =>{
    await updateEvent(changedValues).then(res=>{
        form.setFieldValue('logo',res.logo);
    });
};

function LogoStep() {

    const [{value},,helpers]=useField('logo');

    return (
        <css.upload image={value} className={value?'':'empty'}>
            <AbsoluteImageEditor image={value} setImage={helpers.setValue} size={ {width: 150, height: 150}}/>
            {/* <ImagePopup imageProps={
                {   
                    size:{width: 150, height: 150},
                    image: value,
                    setImage: helpers.setValue,
                    type: 'image/jpeg'
                }
            }
            fieldName={'logo'} externalPopup={true} absoluteImage={true}
            /> */}
        </css.upload>
    );
}

export default LogoStep;
