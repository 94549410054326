import * as css from './DuplicateStep.css';
import {useTranslation} from 'react-i18next';
import CheckBoxButton from '../../../../component/checkBoxButton/CheckBoxButton';
import Btn from '../../../../component/button/btn/Btn';
import useStep from '../StepLeft/useStep';
import {useContext} from 'react';
import {newEventContext} from '../newEventForm/NewEventForm';
import {useField} from 'formik';
export let duplicateArrayHelpers = {};

export const getTopDuplicate = (value,t) => {
    const _t=(text)=>t(`layout:${text}`);
    return value.filter(item => item.checked).map(item => _t(item.key)+' ');
};

function DuplicateStep (){
    const {t} = useTranslation('newEvent');
    const [items]=useField('duplicate');
    const {steps}=useContext(newEventContext);
    const step=steps.find(s=>s.name==='duplicate');
    const {onContinue} = useStep(step);

    return (
        <>
            <css.CheckboxWrap>
                {items.value?.map((item, index) => {
                    return <CheckBoxButton key={item.key} name={`duplicate[${index}].checked`}>{t(`layout:${item.key}`)}</CheckBoxButton>;
                })}
            </css.CheckboxWrap >
            <css.wrapBtn>
                <Btn className={'arrow valid'} onClick={onContinue} >{t('finish')}</Btn>
            </css.wrapBtn>
        </>
    );
}
export default DuplicateStep;
