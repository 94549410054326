import * as css from './DataItems.css';
import React from 'react';
import DataItem from '../dataItem/DataItem';
import {remove} from '../../assets/symbols/symbols/Symbols.css';
import InputList from '../input/inputList/InputList';
import SumMoreItems from '../dataItem/SumMoreItems';

function DataItems({list = [], className = '', maxToShow = 2, listText, onChange, icon, listWidth='100%'}) {
    const unSelectedList = () => list.filter(item => !item?.selected).map(item => ({...item, type: 'checkbox'}));
    const selectedList = () => list.filter(item => item?.selected);

    const onClear = (item) => {
        onChange(item, 'remove');
    };

    const onAdd = (name, key) => {
        if(!key)
            return;
        let list = [...unSelectedList()];
        let index = list.findIndex(i => i.key == key);
        let item = list[index];
        onChange(item, 'add');
    };

    const getMoreList = () => (
        [...selectedList()].splice(maxToShow).map((item) => ({
            ...item,
            type: 'icon',
            icon: remove
        }))
    );

    return (

        <css.container className={className}>
            {
                selectedList().map((item, index) => index < maxToShow &&
                    <DataItem key={index} value={item.text} icon={icon} onClear={() => onClear(item, index)} color={item.color} dataCyName ={item.key} />)
            }
            <css.plus>
                {!!getMoreList().length && <SumMoreItems list={getMoreList()} value={selectedList().length - maxToShow + 1} icon={icon} onClick={(item) => onClear(item)} />}
            </css.plus>
            <css.listCheckbox>
                <InputList name='noName' onChange={onAdd} list={unSelectedList()} toCloseInChange={false} text={listText} isSelectable={true} width={listWidth} />
            </css.listCheckbox>
        </css.container>

    );
}
export default DataItems;
