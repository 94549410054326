import {useTranslation} from 'react-i18next';
import Options from '../../../../component/options/Options';
import {blackDuplicate, Lightning, pencil, remove} from '../../../../assets/symbols/symbols/Symbols.css';
import {deleteEvent} from '../../../../redux/suggestions/suggestionsActions';
import {TICK_DOMAIN} from '../../../../config/config';
import {bounceMessageBlock} from '../../../userMessagesBlock/UserMessageBlock';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {dispatchCustomEvent} from '../../../../hooks/dispatchEvent';
import {bounceMessage} from '../../../userMessages/UserMessage';
import {isMaster} from '../../../../functions/permission';

function EventOption ({event, openDuplicateEvent}) {
    const {t} = useTranslation('layout');
    const tGlobal = useTranslation('global');
    const {eid,pid}=useSelector(s=>s.event);
    const history = useHistory();
    const deleteEventOp= () => {
        bounceMessageBlock(t('eventTitleMessage'),t('eventTextMessage'),{
            text: t('deleteEvent'),
            onClick: () => deleteEvent(event.eid).then(()=>{
                bounceMessage(t('successDeleteEvent',{name:event.title}),'success',undefined,{text:tGlobal.t('thank')});
                if(event.eid===eid) {
                    dispatchCustomEvent('changeEvent', {detail: null});
                    history.push(`/p/${pid}/home`);
                }
            })
        },
        {
            text: t('cancelDelete')
        }
        );

    };

    const stopAction=(e)=>{
        e.preventDefault();
        e.stopPropagation();
    };

    const listOptions=[{icon:remove,text:tGlobal.t('delete'),onClick:(e)=>{stopAction(e);deleteEventOp();},dataCyName:'delete'},
        ...(event?.type==='timetable' && !isMaster()? []:
            [{icon:blackDuplicate,text:tGlobal.t('duplicate'),onClick:(e)=> {stopAction(e);openDuplicateEvent();},dataCyName:'duplicate'}]),
        {icon:Lightning,text:tGlobal.t('link'),onClick:(e)=> {stopAction(e);window.open(TICK_DOMAIN + '/' + event.name);},dataCyName:'link'},
        {icon:pencil,text:tGlobal.t('edit'),onClick:()=> {},dataCyName:'edit'}];
    return (
        <Options list={listOptions} />

    );
    
}

export default EventOption;