import {useTranslation} from 'react-i18next';
import * as css from './TickchakContracts.css';
import {TICK_DOMAIN} from '../../../../config/config';
import {useSelector} from 'react-redux';

function TickchakContracts() {
    const {t} = useTranslation('login');

    const onClick = url => {
        window.open(url);
    };

    const isKeyboardOpen=useSelector(s=>s.ui.isKeyboardOpen);

    return (
        <css.links className={isKeyboardOpen&&' keyboardOpen'}>
            <css.link onClick={() => onClick('https://tickchak.co.il/terms')}>
                {t('termsOfUse')}&nbsp;&nbsp; •&nbsp;&nbsp;
            </css.link>
            <css.link onClick={() => onClick(`${TICK_DOMAIN}/privacy`)}>
                {t('privacy')}&nbsp;&nbsp; •&nbsp;&nbsp;
            </css.link>
            <css.link onClick={() => onClick(window.origin)}>
                {t('producersEntrance')}
            </css.link>
        </css.links>
    );
}

export default TickchakContracts;
