import TooltipAbsolute from '../../tooltip/tooltipAbsolute/TooltipAbsolute';
import * as css from './DomainInput.css';
import {Field, useField} from 'formik';
import CopyLink from '../../copyLink/CopyLink';

function DomainInput({className,name,text,descText,isSubDomain}) {
    const [field, meta, helpers] = useField(name);
    const classes = `${className}  ${
    !!meta.touched && (meta.error ? ' invalid' : (meta.value && ' valid'))
  }`;

    const onChange = (e) => {
        if(!meta.touched) helpers.setTouched(true);
        field.onChange(e);
    };
  
    return (
        <css.container className={classes} data-cy={`${name.substring(name.indexOf('.')+1)}Validation`}>
            <CopyLink text={`https://${field.value}${isSubDomain?'.tickchak.co.il':''}`}/>
            <css.icon className={classes}/>
            <css.wrap>
                <css.inputs>
                    <css.text>{text}</css.text>   
                    <css.wrapInput isSubDomain={isSubDomain} data-cy={`${name.substring(name.indexOf('.')+1)}Input`}>
                
                        {'https://'}
                        <Field
                            as='input'
                            type='text'
                            name={name}
                            onChange={onChange}
                            placeholder=""
                        />
                        {isSubDomain&&'.tickchak.co.il'}
                    
                    </css.wrapInput>   
                    {meta.touched && meta.error && (<TooltipAbsolute show={true} text={meta.error} /> )}
                </css.inputs>
                <css.description>{descText}</css.description>
            </css.wrap>
        </css.container>
    );
}

export default DomainInput;
