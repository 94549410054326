import styled from 'styled-components/macro';
import {ItemText, ItemContent} from '../../../component/item/Item.css';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';
import {setting} from '../subSettingItem/SubSettingItem.css';
import {input as subInput} from '../paymentForm/PaymentForm.css';
import {input as inputList} from '../../../component/input/inputList/InputList.css';

export const dataItems = styled.div`
${mobileMedia}{
    ${setting}{
        display:block;
    }
}
`;
export const input = styled.div`
    width:180px;
    ${ItemContent}{
        display:none ;
    }
    ${ItemText}{
        padding-inline-start:20px;
        white-space: break-spaces;
        width: 150px;
    }
    ${inputList}{
        line-height:13px ;
    }
`;

export const blockOrdersSmsText = styled.div`
    ${mobileMedia}{
        ${setting}{
            display:block ;
        }
        ${subInput}{
            margin-inline-end:auto ;
        }
    }
    ${input}{
        width:400px;
    }
`;

export const place=styled.div`
    height:80px;
`;
