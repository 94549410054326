import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {attendanceImg, whatsApp} from '../../../assets/symbols/symbols/Symbols.css';
import setGlobalState from '../../../redux/setGlobalState';
import * as css from './AttendanceItem.css';

function AttendanceItem({close,personalInformation, face,openReport}) {

    const {firstName,lastName,qid,phone,presentAmount} = personalInformation;

    const {t} = useTranslation('attendance');  

    const meetings = useSelector(s => s.attendance.tickets.find(t => t.tid === s.attendance.currentTid)?.meetings);

    const openWhatsApp = () => {
        let urlWhatApp = 'https://api.whatsapp.com/send?phone=' + phone + '&text=' + t('whatsAppText', {'name': firstName+' '+lastName,'sum':presentAmount+'/'+meetings.length,'course':meetings[0]?.title});
        window.open(urlWhatApp);
    };

    const openPop = () => {
        setGlobalState('attendance.currentParticipant',{qid:qid,face:face});
        close();
        openReport.open();
    };

    return (
        <css.item>
            <css.rightPart>
                <css.image face={face} />
                <css.left>
                    <css.boldText>{firstName}&nbsp;{lastName}</css.boldText>
                    <css.boldText>{presentAmount}/{meetings.length}</css.boldText>
                </css.left>
            </css.rightPart>
            <css.leftPart>
                <css.icon icon={attendanceImg} onClick={openPop} />
                <css.icon icon={whatsApp} onClick={openWhatsApp} />
            </css.leftPart>
        </css.item>
    );
}

export default AttendanceItem;
