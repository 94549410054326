import styled from 'styled-components';
import {GRAY, mobileMedia} from '../../globalVariables/GlobalVariables';
import {btn} from '../button/btn/Btn.css';

export const computer = styled.div`
    width: 100%;
    height: 60px;
    background-color: #8DD4E5;
`;

export const wrapAll = styled.div`
    height: 100%;
    min-height: 400px;
`;

export const warpButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    inset-block-end: 15px;
    inset-inline-end: 5px;
    ${mobileMedia}{
        flex-direction: column;
        align-items: center;
    }
    ${btn}{
        ${mobileMedia}{
            width: 90%;
            margin-bottom: 10px;
            margin-inline-end: 0;
            justify-content: center;
        }
        height:35px;
        margin-inline-end: 15px;
        background-color:#8DD4E5;
        display: flex;
        align-items: center;
        gap: 5px;
        &.cancel{
            background-color: ${GRAY};
            border: 2px solid ${GRAY};
        }
    } 
`;