import * as css from './ArtistTopStep.css';
import Artists from '../artists/Artists';

function ArtistTopStep() {

    return (
        <css.wrapper >
            <Artists isTop={true}/>
        </css.wrapper>

    );
}

export default ArtistTopStep;