import * as css from './PackagePopup.css';
import Title from '../title/Title';
import OfficeOptions from '../../../../component/officeOptions/OfficeOptions';
import SettingsBody from '../settingsBody/SettingsBody';
import PackageProducerBody from '../packageProducerBody/PackageProducerBody';
import PackageBank from '../../componnent/packageBank/PackageBank';
import {useRef, useState} from 'react';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import AccountVerification from '../accountVerification/AccountVerification';
import AccountTeam from '../accountTeam/AccountTeam';
import SubscriptionScreen from '../subscriptionScreen/SubscriptionScreen';

function PackagePopup(props) {
    const {title, close, showClass} = props;
    const formRef = useRef();

    const history = useHistory();
    const location = useLocation();
    let params = new URLSearchParams(location.search);
    
    const page = params.get('packagePage');
    const [showBanner,setShowBanner] =useState(true);

    const body = {
        'package':<SettingsBody/>,
        'producer': <PackageProducerBody formRef={formRef}/>,
        'bank': <PackageBank formRef={formRef}/>,
        'team': <AccountTeam/>,
        'subscription': <SubscriptionScreen />
    };

    useEffect(() => () => {
        history.push({
            search:''
        });
    }, []);

    const isShowOptions=page!=='team';

    return (<>

        <css.Popup className={showClass} >
            <Title close={close} title={title} formRef={formRef} popupName={page} isShowOptions={isShowOptions}/>
            {isShowOptions&&
            <>
                <css.Management>
                    <OfficeOptions />
                </css.Management>
                <AccountVerification showBanner={showBanner} setShowBanner={setShowBanner}/>
            </>}
            <css.accordions className={showBanner&&isShowOptions&&'showBanner'}>
                {body[page]}
            </css.accordions>
        </css.Popup>
    </>
    );
}

export default PackagePopup;
