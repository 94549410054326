import React, {useEffect, useRef, useState} from 'react';
import UserImage from './userImage/UserImage';
import UserProfileMenu from './layoutUserProfileMenu/LayoutUserProfileMenu';
import * as css from './LayoutUserProfile.css';
import {useSelector} from 'react-redux';
import useOnClickOutside from '../../../hooks/UseOnClickOutside';
import useTablet from '../../../hooks/useTablet';
import Tooltip from '../../../component/tooltip/Tooltip';
import {useTranslation} from 'react-i18next';

const LayoutUserProfile = (props) => {
    const event=useSelector(state=>state.event);
    const isTablet = useTablet();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const {t} = useTranslation('layout');
    const openMenuHandler = (open) => {
        setIsMenuOpen(open);
        if(!open)
            setShowMenu(true);
    };
    const openCloseMenuHandler = (e) => {
        e.stopPropagation();
        if(!isMenuOpen){
            openMenuHandler(!isMenuOpen);
            setShowMenu(true);
        }
        else
            setShowMenu(false);
    };

    const userImgPath = props.imgPath;
    const refProfile=useRef();
    const ref=useRef();

    useOnClickOutside(ref,()=> {
        setShowMenu(false);
    });
    useEffect(()=>{
        if(isTablet && !event)
            openMenuHandler(true);
    },[]);
    return <Tooltip text={t('yourProfile')}>
        <div ref={ref}>
            <css.UserProfileDivStyled ref={refProfile} onClick={()=>openMenuHandler(true)} >
                <css.UserImg data-cy="userProfileImage"><UserImage class={'layout-img-user'} path={userImgPath}/><css.ProfileOpenClose className={!isMenuOpen&&'close'} onClick={openCloseMenuHandler}/></css.UserImg>
                {/*<css.UserNameSpan>{props.userName}</css.UserNameSpan>*/}
            </css.UserProfileDivStyled>
            {isMenuOpen ? <UserProfileMenu closeMenu={props.closeMenu} hide={!showMenu} showEvents={props.showEvents} close={()=>openMenuHandler(false)} userImgPath={userImgPath} userName={props.userName}/> : null}
        </div>
    </Tooltip>;
};

export default  LayoutUserProfile;