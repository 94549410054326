import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import * as css from './ClusterPicker.css';
import InputSearch from '../../../../component/inputSearch/InputSearch';
import Events from '../../../components/events/Events';
import {addEventToCluster, getEventsSuggestions} from '../../../../redux/cluster/clusterActions';
import useOnClickOutside from '../../../../hooks/UseOnClickOutside';

function ClusterPicker(props) {
    const event = useSelector(state => state.event);
    const {t} = useTranslation('cluster');
    const [search, setSearch] = useState('');
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0);
    const refPicker = useRef();
    const {cgid = 0} = props;
    const cluster = useSelector(state => state.cluster);

    useOnClickOutside(refPicker, props.closePicker);
    const handleSearch = (e) => {
        setSearch(e.target.value);
        setOffset(0);
        setCount(count + 1);
    };

    const addEvent = async (eventToAdd) => {
        await addEventToCluster(event.eid, eventToAdd, {cgid: cgid ? cgid : 0, weight: 0});
        props.closePicker();
    };
    return (
        <css.picker ref={refPicker} right={props.right} bottom={props.bottom}>
            <InputSearch text={t('addEvent')} onChange={handleSearch}/>
            <Events eventsList={cluster.clusterEventsSearch}
                getUserEvents={(fields) => getEventsSuggestions(event?.eid, fields)} onClick={addEvent}
                search={search} type={'active'} limit={5} offset={offset} cnt={count} />
        </css.picker>
    );
}

export default ClusterPicker;