import {useTranslation} from 'react-i18next';
import * as css from './Summary.css';
import {useField,} from 'formik';
import {useSelector} from 'react-redux';

function Summary({total, perMonth}) {
    const {t} = useTranslation('ticket');
    const event = useSelector(s => s.event);
    const [payments] = useField('payments');

    return (
        <css.lightBlue>
            <css.icon/>
            <css.text>{`${t(event.type+'Price')} ${event.currencySymbol}${total} ${t('willBeCharged')} ${event.currencySymbol}${perMonth} ${t('during')} ${payments.value.numberPayments} ${t('months')}`}</css.text>
        </css.lightBlue>
    );
}

export default Summary;
