import NewEventForm from '../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {duplicateInitialValues, newEventInitialValues,newEventSteps, newEventValidationSchema} from '../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {getBudgetItems} from '../../../redux/account/accountActions';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {eventFinishDuplication} from '../../../redux/event/eventActions';
import {openDesignPop} from '../../../layout/Layout';

function NewTicketsEvent() {
    const event=useSelector(s=>s.event);
    const budgetItems = useSelector(s=>s.account.budgetItems);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);
    const {duplicateSourceEid,isOpenLocaleStep} = useSelector(s=>s.ui.event);
    const history = useHistory();
    
    const eventSteps = [
        newEventSteps.title,
        newEventSteps.category(t),
        newEventSteps.location, 
        newEventSteps.date,
        newEventSteps.artists,
        newEventSteps.description,
    ];
    if(isOpenLocaleStep){
        eventSteps.splice(4, 0, newEventSteps.locale);
    }
    const courseSteps = [
        newEventSteps.title,
        newEventSteps.location, 
        newEventSteps.description,
    ];
    const steps = [
        ...(event.type !== 'course' ? eventSteps : courseSteps),
        ...(budgetItems.length ? [newEventSteps.budgetIndex] : []),
        ...(duplicateSourceEid? [newEventSteps.duplicate(t)]: []),
    ];
        
    useEffect(()=>{
        getBudgetItems();
    },[]);

    const duplicateSubmit = (form) => {
        const values = form.getFieldMeta('duplicate').value;
        eventFinishDuplication(event.eid,duplicateSourceEid,values).then(
            history.push(`/e/${event.eid}/${event.type === 'course' ? 'courses' : 'tickets'}`),
            openDesignPop()
        );
    };
    
    const initialValues = {
        ...newEventInitialValues(event),
        ...(duplicateSourceEid ? {duplicate: duplicateInitialValues(event?.type)} : {}),
    };
    const submitBtnProps = {
        onSubmit: duplicateSubmit,
        children: t('newEvent:saveDuplicate'),
    };
    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={Yup.object().shape(newEventValidationSchema(t))}
            steps={steps}
            submitBtnProps={duplicateSourceEid ? submitBtnProps: undefined}
        />
    );
}

export default NewTicketsEvent;
