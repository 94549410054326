import styled from 'styled-components/macro';
import {BGImageCenter, GREEN, dFlexCenter} from '../../../../../globalVariables/GlobalVariables';
import {add, vi} from '../../../../../assets/symbols/symbols/Symbols.css';

export const images = styled.div`
    ${dFlexCenter};
    width:100% ;

`;
export const image = styled.div`
  margin-inline-end: -15px;
  width: 30px;
  position: relative;
  &.selected{
    ::before {
      position: absolute;
      content: '';
      width: 15px;
      height: 15px;
      top:-3px;
      inset-inline-start:-3px ;
      border-radius: 50%;
      background-color:${GREEN};
      ${BGImageCenter(vi,'8px')};
    }
  }

`;
export const openList = styled.div`
    margin-inline-end:-10px;
    width:30px;
    height:30px;
  border-radius:50%;
  border: 2px solid #161616;
  ${BGImageCenter(add, '11px')}
`;
