import styled from 'styled-components/macro';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

export const recommendedSize = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 13px;
    ${mobileMedia}{
      font-weight: normal;
    }
`;