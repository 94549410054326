import TicketBox from '../../ticketBox/TicketBox';
import TicketOptions from '../../ticketOptions/TicketOptions';
import MobileDrag from '../../../../../component/newTable/mobileTicketRow/MobileDrag';

function MobileTicketRow({ticket, openPopup}) {

    return (
        <MobileDrag>
            {(isDrag)=>
                <TicketBox isDrag={isDrag} ticket={ticket} openPopup={openPopup}>
                    <TicketOptions ticket={ticket} openPopup={openPopup} />
                </TicketBox>
            }
        </MobileDrag>
    );
}
export default MobileTicketRow;
