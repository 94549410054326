import * as css from './ArtistUserMessage.css';
import {useTranslation} from 'react-i18next';
import CircleClose from '../circleClose/CircleClose';
import Button from '../button/btn/Btn.js';
import {useField,} from 'formik';
import useShow from '../../hooks/useShow';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {artistArrayHelpers} from '../../pages/newEvent/components/artistsStep/artists/Artists';
import useTimeout from '../../hooks/useTimeout';
import setGlobalState from '../../redux/setGlobalState';
import {addArtists} from '../../redux/event/eventActions';

function ArtistUserMessage() {
    const {t}=useTranslation('layout');
    const artistIds = useSelector(s=>s.ui.artists);
    const artists = useSelector(s=>s.event.artists);

    const {open, close, isOpen, showClass} = useShow();
    const [currentArtist, setCurrentArtist] = useState();
    const [{value}] = useField('artists');
    const timerClose = useTimeout();
    const timerOpen = useTimeout();

    const DURATION=10000;

    useEffect(() => {
        if(isOpen)
            timerClose.current = setTimeout(close, DURATION);
        else {
            const preIndex = artistIds.findIndex(item => item === currentArtist?.artistId);
            if(artistIds[preIndex +1]){
                const foundArtist = findCurrentArtist(artistIds[preIndex +1]);
                setCurrentArtist(foundArtist);
                timerOpen.current = setTimeout(() => {
                    open();
                }, 1200);
            }
            else if(artistIds.length)
                if(JSON.stringify(value.map(a=>a.artistId)) !== JSON.stringify(artists.map(a=>a.artistId)))
                    addArtists(value).then();
        }
        return () => clearTimeout(timerClose.current);
    }, [isOpen]);
    
    function findCurrentArtist(artistId) {
        const foundArtist = value.find(item => item.artistId === artistId);
        return foundArtist;
    }

    useEffect(()=>{
        if(artistIds?.length > 0){
            const foundArtist = findCurrentArtist(artistIds[0]);
            setCurrentArtist(foundArtist);
            open();
        }
    },[artistIds]);

    useEffect(()=>()=>setGlobalState('ui.artists', []),[]);

    function onCancel(){
        const artistIndex = value.findIndex(item => item.artistId === currentArtist?.artistId);
        artistArrayHelpers.remove(artistIndex);
        close();
        clearTimeout(timerClose.current);
    }

    return (
        <css.messageWrapper className={showClass}>
            <CircleClose isDark close={close} closeType = 'stepClose'/>
            <css.messageContent>
                <css.image image={currentArtist?.image?.main?.image}/>
                <css.messageTitleWrapper>
                    <css.messageTitle>{t('artistMessageTitle',{artist:currentArtist?.title})}</css.messageTitle>
                    <css.messageText>{t('artistMessageText',{artist:currentArtist?.title})}</css.messageText>
                </css.messageTitleWrapper>
                <css.messageButtonWrapper>
                    <Button onClick={onCancel} className={'white'}>{t('artistMessageButtonCancel')}</Button>
                    <Button onClick={close}>{t('artistMessageButton')}</Button>
                </css.messageButtonWrapper>
            </css.messageContent>
        </css.messageWrapper>
    );
}
export default ArtistUserMessage;