import styled from 'styled-components/macro';
import {op} from '../../../../assets/symbols/symbols/Symbols.css';
import {link} from '../../../../component/copy/Copy.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const options = styled.div`
width:20px;
height:20px;
cursor: pointer;
${link}{
    width: 26px;
}
>div{
        white-space:nowrap ;
    }
    ${BGImageCenter(op, '10px')}

`;

