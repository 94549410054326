import {useField, useFormikContext} from 'formik';
import {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Button from '../../../../../component/button/button/Button';
import ColorsPicker from '../../../../../component/colorsPicker/ColorsPicker';
import {changeArrColorTemp} from '../../../../eventDetails/cluster/groupImagePopUp/GroupImagePopUp';
import PopOnPop from '../../../../../component/newPopup/popOnPop/PopOnPop';
import {closeBtn} from '../../../../../functions/globalBtns';
import setGlobalState from '../../../../../redux/setGlobalState';
import * as css from './BottomCourse.css';
import {bounceMessageModal} from '../../../../../layout/userMessages/UserMessage';
import Btn from '../../../../../component/button/btn/Btn';
import {getFirstInvalidField} from '../../../../../functions/getFirstErrorFieldName';
import {CourseDetailsContext} from '../coursePopupContent/CoursePopupContent';
import {openDesignPop} from '../../../../../layout/Layout';
import useTimeout from '../../../../../hooks/useTimeout';

function BottomCourse({continueBtnText,  continueClick, onSubmitForm,close}) {
    const {t} = useTranslation('ticket');
    const colorsPicker = useSelector((state) => state.ui.colorsPicker);
    const tickets = useSelector(s => s.tickets);
    const isCourse=useSelector(s=>s.event.type==='course');
    const ticket = tickets?.currentTicket||{};
    const [show, setShow] = useState(0);
    const addTicketXHR = tickets.addTicketXHR;
    const updateTicketXHR = tickets.updateTicketXHR;
    const form = useFormikContext();
    const {errorFieldRef} = useContext(CourseDetailsContext);
    const timer = useTimeout();
    const [{value:isDraft},,{setValue: setIsDraft}]=useField('draft');
    const isValid=form.isValid&&!isDraft;

    const XHR=addTicketXHR || updateTicketXHR;
    const [isClosed,setIsClosed]=useState(false);

    const isFirstRun = useRef(true);

    useEffect(() => {
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if(isValid)
            form.setTouched({});
    }, [form.submitCount]);

    const onClickColor = async (color) => {
        form.setFieldValue('color',color);
        changeArrColorTemp(colorsPicker.currentColor, 'sub');
        changeArrColorTemp(color, 'add');//?
        setGlobalState('ui.colorsPicker.currentColor', color);
    };

    useEffect(() => {
        setGlobalState('ui.colorsPicker.currentColor', ticket?.color);
    }, [ticket]);

    let XHRTimer = useRef(null);

    useEffect(() => {
        form.validateForm();
        if(XHR)
            setGlobalState(`tickets.${updateTicketXHR?'updateTicketXHR':'addTicketXHR'}`, null);
        return () => {
            clearTimeout(XHRTimer.current);
        };
    }, []);
    
    useEffect(()=>{
        if(['SUCCESS','FAILED'].includes(XHR))
            XHRTimer.current=setTimeout(()=>{
                if(isClosed){
                    close();
                }
                else
                    setGlobalState(`tickets.${updateTicketXHR?'updateTicketXHR':'addTicketXHR'}`, null);
            },1000);
    },[XHR]);

    const onSave=()=>{
        setIsDraft(false);
        setImmediate(()=>{
            onSubmitForm();
            errorFieldRef.current?.scrollIntoView({behavior: 'smooth'});
            if(!ticket.tid && !isValid){
                let errorMassage;
                const currentError = getFirstInvalidField(form.values,form.errors);
                if(currentError.value) {
                    errorMassage = t('notValidMessage',{field:t(`${currentError.name}Field`)});
                }
                else {
                    errorMassage = t('notFillMessage',{field:t(`${currentError.name}Field`)});
                }
                bounceMessageModal(errorMassage,'warning');
            }        
        });
    };

    const  onContinue=()=>{
        if(isDraft){
            setIsDraft(false);
        }
        setImmediate(()=>{
            if(XHR==='LOADING')
                return;
            if(isClosed){
                setIsClosed(false);
            }
            if(form.dirty)
                onSave();
            if(isValid)
                continueClick();
        });
    };
    
    const onClose=()=>{
        if(XHR==='LOADING')
            return;
        if(form.dirty && isValid){
            setIsClosed(true);
            timer.current=setTimeout(function() {
                if(!isCourse && !tickets?.tickets.length)
                    openDesignPop();
            },1000);
            onSave();
        }
        else close();
    };

    return (<>
        <css.bottom>
            <>
                <css.wrapColor>
                    <css.colorTicket color={ticket?.color} onClick={() => setShow(1)}/>
                    <css.arrow data-cy='colorTicket' onClick={() => setShow(1)}/>
                </css.wrapColor>
            </>

            <css.wrapBtn>
                <css.nextBtn>
                    <Btn onClick={onClose} className={`white ${isClosed?XHR:''}`}>
                        {isValid?t(form.dirty?'saveAndClose':'close'):t('close')}
                    </Btn>
                </css.nextBtn>
                {(continueBtnText||form.dirty)&&<css.saveBtn  className={!isValid&&' disabled'} > 
                    <Btn onClick={onContinue}  className={`${!isClosed?XHR:''} ${continueBtnText&&'arrow'}`} >
                        {ticket.tid? 
                            (continueBtnText?`${t(form.dirty?'saveAndContinue':'continueTo')}${continueBtnText}`:t('save'))
                            :t(isCourse?'createCourse':'createTicket')}
                    </Btn>
                </css.saveBtn>}
            </css.wrapBtn>
        </css.bottom>
        
        <PopOnPop dataCy='colorPicker' image={'groupColor'} showPopOnPop={{show: show, setShow: setShow}} title={t('colorTicket')} >
            <>
                <css.wrapColorPicker>
                    <ColorsPicker onClickColor={onClickColor}/>
                </css.wrapColorPicker>
                <css.bottomPickerPop> <Button BtnProps={closeBtn(t, () => setShow(0))}/></css.bottomPickerPop>
            </>
        </PopOnPop>
    </>
    );
}

export default BottomCourse;
