import {useTranslation} from 'react-i18next';
import * as css from './LeadInformation.css';
import InfoLeadItem from '../infoLeadItem/InfoLeadItem';
import Status from '../../../component/status/Status';

function LeadInformation({lead}) {

    const {t} = useTranslation('publish');
    const informationData = [
        {title: t('sale'), value: lead?.totalPrice},
        {title: t('transactions'), value: lead?.transactions},
        {title: t('tickets'), value: lead?.tickets},
        {title: t('Entrances'), value: lead?.ticliCount},    
        {title: t('Uniqueness'), value: lead?.ticliCountUnique},
        {title: t('status'), value: <Status lead={lead}/>},
    ];

    return (
        <css.wrapper data-cy="LeadInformation">
            {informationData.map((item,index) => 
                <InfoLeadItem key={index} title={item.title} value={item.value}/>
            )}
        </css.wrapper>
    );
}

export default LeadInformation;