/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components/macro';
import * as scroll  from '../../../component/scrollbar/Scrollbar.css';

export const templateItem=styled.div`
    height: 65px;
    border-bottom: 1px solid #ddcece;
    position: relative;
`;
export const templateTitle=styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
`;
export const templateContent=styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
`;
export const templatePlus=styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #76d6e8;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    font-weight: 500;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    font-size: 0;
    position: relative;
    float: left;
    margin: 12px;
    :after{
        content: '+';
        font-size: 36px;
        -webkit-transition: all 400ms;
        transition: all 400ms;
        left: 10px;
        position: absolute;
    }
`;
export const templateItemIn=styled.div`
    width: 221px;
    padding: 16px;
    line-height: 1;
    float: right;
    cursor: pointer;
`;
export const contentOpened=styled.div`
    position: absolute;
    max-height: 128px;
    width: 220px;
    padding: 13px;
    background: #fff;
    top: -125px;
    box-shadow: 0px 5px 15px #00000052;
    white-space: pre-line;
    :after{
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: -10px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #fff;
    }
`;

export const contentOpenedTitle=styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
`;
export const contentOpenedText=styled(scroll.ScrollBarArrow)`
    font-size: 12px;
    max-height: 85px;
`;