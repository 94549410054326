import {useSelector} from 'react-redux';
import * as css from './LeftChildren.css';

function LeftChildren({title, price}) {
    const currency = useSelector(s=>s.event.currencySymbol);

    return (
        <css.leftChildren>
            <css.title>{title}</css.title>
            <css.price>{price+currency}</css.price>
        </css.leftChildren>
        
    );
}

export default LeftChildren;