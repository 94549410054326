import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import InputList from '../../../../../component/input/inputList/InputList';
import * as css from '../../components/paymentsGlobal/PaymentsGlobal.css';
import MonthInput from '../../../../../component/input/dateInput/MonthInput';
import Summary from '../../components/summary/Summary';
import {formatDecimal} from '../../../../../library/numberFunction';
import paymentsMethod from '../../components/functions/paymentsMethod';
import PaymentsGlobal from '../../components/paymentsGlobal/PaymentsGlobal';
import {If,Then,Else} from 'react-if';
import {hasAccountAccess} from '../../../../../functions/permission';
import CheckboxFormik from '../../../../../component/checkboxFormik/CheckboxFormik';
import FormikModeBtn from '../../../../../component/formikModeBtn/FormikModeBtn';
import {bounceMessage} from '../../../../../layout/userMessages/UserMessage';

const billingOptionsList = (paymentMethod) => {
    let list = [];
    const maxPayments = paymentMethod === 'monthly'? 36 :12;
    for(let i = 2; i <= maxPayments; i++)
        list.push({key: i, text: i});
    return list;
};

function PaymentSettings({onSubmitForm}) {

    const {t} = useTranslation('ticket');
    const tickets = useSelector(state => state.tickets);
    const [overrideMethod, setOverrideMethod]=useState(false);
    const [payments, , helpers] = useField('payments');
    const [prices] = useField('prices');
    const firstTime = useRef(true);

    const paymentsList = [];

    const accountBillingsAccess = hasAccountAccess('billing');
    const eventSettings=useSelector(state=>state.event.settings);
    if(accountBillingsAccess&&eventSettings?.billings?.hasBillings)
        paymentsList.push({key:'monthly', text:t('billings')});
    const accountPaymentsAccess = hasAccountAccess('payments');
    if(accountPaymentsAccess&&eventSettings?.payments?.hasPayments)
        paymentsList.push({key:'payments', text:t('payments')});

    useEffect(() => {
        if(overrideMethod){
            if(payments.value.paymentsMethod === 'monthly')
                bounceMessage(t('maxPayments'));
            helpers.setValue({...payments.value, paymentsMethod: payments.value.paymentsMethod === 'payments' ? 'monthly' : 'payments'});
            firstTime.current = true;
            setOverrideMethod(false);
        }
    }, [overrideMethod]);

    useEffect(() => {
        if(firstTime.current) {
            firstTime.current = false;
            return;
        }
        const opposites = paymentsMethod(tickets, payments.value.paymentsMethod);
        if(opposites.length) {
            const eventPaymentMethod = payments.value.paymentsMethod === 'payments' ? t('billings') : t('payments');
            const paymentMethod = payments.value.paymentsMethod === 'payments' ? t('payments') : t('billings');
            const paymentMethodMessage = `${t('setUpFor')+eventPaymentMethod}, ${t('override')+paymentMethod}?`;
            bounceMessage(t(paymentMethodMessage),'warning',
                {text: t('overrideBtn'), onClick: () => setOverrideMethod(true)},
                {text:t('cancel')}
            );
            helpers.setValue({...payments.value, paymentsMethod: payments.value.paymentsMethod === 'payments' ? 'monthly' : 'payments'});
            firstTime.current=true;
            return;
        }
        if(payments.value.paymentsMethod === 'payments'){
            helpers.setValue({...payments.value, secondPayment: 0});
            if(payments.value.numberPayments>12)
                helpers.setValue({...payments.value, numberPayments: 12});
        }
    }, [payments?.value?.paymentsMethod]);
    const [,,paymentsMethodHelper]=useField('payments.paymentsMethod');

    useEffect(()=>{
        if(paymentsList.length===1){
            paymentsMethodHelper.setValue(paymentsList[0].key);
        }
    },[]);

    return (
        <If condition={paymentsList.length}>
            <Then>
                <PaymentsGlobal onSubmitForm={onSubmitForm}>
                    <css.modeBtn>
                        <FormikModeBtn name="payments.hasBillings" className="small"/>
                    </css.modeBtn>
                    <css.wrapGrid className={!payments?.value?.hasBillings&&'notShow'}>
                        <InputList t={t} name={'payments.paymentsMethod'} text={t('paymentMethod')} description={t('paymentsOrBillings')} list={paymentsList} />
                        <InputList t={t} name={'payments.numberPayments'} text={t('numberPayments')} description={t('numberPaymentsDesc')} list={billingOptionsList(payments?.value?.paymentsMethod)} />
                        <If condition={payments.value.paymentsMethod==='monthly'}>
                            <Then>
                                <css.monthInput className={payments?.value?.paymentsMethod==='payments'&&'notShow'}>
                                    <MonthInput name={'payments.secondPayment'} text={t('secondPayment')} descText={t('secondPaymentDesc')}/>
                                </css.monthInput>
                            </Then>
                        </If>
                    </css.wrapGrid>
                    <css.wrapCB >
                        {payments?.value?.paymentsMethod==='monthly' &&
                        <CheckboxFormik name={'payments.extraException'} text={t('chargingExtensions')} />}
                    </css.wrapCB>
                    {!!(payments?.value?.hasBillings && prices.value.length && payments.value.numberPayments) && 
            <Summary total={formatDecimal(prices?.value[0]?.price)} perMonth = {formatDecimal(prices?.value[0]?.price / payments.value.numberPayments)}/>}
                </PaymentsGlobal>
            </Then>
            <Else>
                <PaymentsGlobal onSubmitForm={onSubmitForm} />
            </Else>
        </If>
    );

}

export default PaymentSettings;
