import React from 'react';
import {PieChart, Pie, Cell, Sector, ResponsiveContainer} from 'recharts';
import * as css  from './GenericPieChart.css';
const GenericPieChart  = ({data,dataKey, setActiveIndex, activeIndex, onMouseEnter, onMouseLeave, hoverState, inlineRect}) => {
    const renderActiveShape = ({cx, cy, startAngle, endAngle, fill, payload} ) => {
        const isHovered = payload.index === activeIndex;
        const innerRadius = isHovered ? 57 : 60;
        const outerRadius = isHovered ? 83 : 80;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    style={{transition: 'all 0.8s ease-in-out'}}
                />
            </g>
        );
    };
    const handleMouseEnter = (e,index) => {
        setActiveIndex(index);
        onMouseEnter && onMouseEnter(index);
    };
    const handleMouseLeave = () => {
        setActiveIndex(null);
        onMouseLeave && onMouseLeave();
    };
    return (
        <>
            {<ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        dataKey={dataKey}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        activeIndex={activeIndex}
                        activeShape={hoverState ? renderActiveShape : {}}
                        innerRadius={60}
                        outerRadius={80}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={`#${data[index].color}`}
                                style={{
                                    transition: 'all 0.3s ease-in-out',
                                    opacity: activeIndex === null || activeIndex === index ? 1 :( hoverState ? 0.6 : 1),
                                }}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>}
            <css.summeryText>{inlineRect}
            </css.summeryText>
        </>
    );
};
export default GenericPieChart;
