import {VERY_DARK_GRAY} from '../../../../../globalVariables/GlobalVariables';
import WindowTemplate from '../../WindowTemplate/windowTemplate';

const grid = `"sectionTitle1 sectionTitle1 pas sectionTitle2"
                        "amountBold1 amountBold1 pas amountBold2"
                        "subtext subtext subtext subtext"
                        "insightsNumber1 span1 span1 progressWrapper"
                        "insightsNumber2 span2 span2 progressWrapper"
                        "insightsNumber3 span3 span3 progressWrapper"`;
const gridMoreStyle={                    
    'grid-auto-columns': '7% 32% 7% 42%'
};
const settings = [
    {
        gridArea: 'sectionTitle1',
        width: '100%',
        height: '29px'
    },
    {
        gridArea: 'amountBold1',
        width: '100%',
        height: '65px'
    },
    {
        gridArea: 'pas', 
        width: '0',
        height: 'inherit',
        moreStyle: {
            border: `1px solid ${VERY_DARK_GRAY}`,
            opacity: 0.44
        }
    },
    {
        gridArea: 'sectionTitle2',
        width: '100%',
        height: '29px'

    },
    {
        gridArea: 'amountBold2',
        width: '100%',
        height: '65px'
        
    },
    {
        gridArea: 'subtext',
        width: '45%',
        height: '23px',
        moreStyle:{
            'place-self': 'baseline'
        }
    },
    {
        gridArea: 'insightsNumber1',
        width: '50px',
        height: '21px',
        moreStyle:{
            'place-self': 'flex-start'
        },
    },
    {
        gridArea: 'insightsNumber2',
        width: '50px',
        height: '21px',
        moreStyle:{
            'place-self': 'flex-start'
        },
    },
    {
        gridArea: 'insightsNumber3',
        width: '50px',
        height: '21px',
        moreStyle:{
            'place-self': 'flex-start'
        }
    },
    {
        gridArea: 'span1',
        width: '100px',
        height: '21px' 
    },
    {
        gridArea: 'span2',
        width: '100px',
        height: '21px'
    },
    {
        gridArea: 'span3',
        width: '100px',
        height: '21px'
    },
    {
        gridArea: 'progressWrapper',
        width: '90px',
        height: '90px',
        moreStyle:{
            'box-shadow':' 0 3px 6px #00000029',
            'border-radius': '50%'
        }
    }

];

function SoldTicketsTemplate() {
   
    return <WindowTemplate gridMoreStyle={gridMoreStyle} gridAreas={grid} settings={settings} />;

}
export default SoldTicketsTemplate;