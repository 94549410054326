import * as css from './PopUp.css';
import {useState, useEffect, useRef} from 'react';
import Close from '../close/Close';
import PopOnPop from './popOnPop/PopOnPop';
import List from '../list/List';
import useOnClickOutside from '../../hooks/UseOnClickOutside';
import useShow from '../../hooks/useShow';
import useClose from '../../hooks/useClose';
import useMobile from '../../hooks/useMobile';

function PopUp(props) {
    const {popUpProps}=props;
    const isMobile = useMobile();

    const ref = useRef();

    const [openPop, setopenPop] = useState(0);
    const closePop = () => {
        setopenPop(0);
        popUpProps.closePop();
    };

    const topPopClick = () => {
        popUpProps.topPopClick && popUpProps.topPopClick(0);
    };
    const bottomPopClick = () => {
        popUpProps.bottomPopClick && popUpProps.bottomPopClick(0);
    };

    const [showList, setShowList] = useState(false);

    const close = useClose(setShowList, 300);
    useOnClickOutside(ref, () => close.setClose());

    const topHeight = popUpProps.popOnPopTopHeight ? popUpProps.popOnPopTopHeight : '102px';
    const middleHeight = isMobile ? (window.innerHeight - parseInt(topHeight) - 100) : 477 - parseInt(topHeight);
    
    const popOnPopOpenClose=useShow();
    const popOnPop = {
        topHeight:topHeight,
        popOnPopTitle: popUpProps.popOnPopTitle,
        popOnPopTopContent: popUpProps.popOnPopTopContent,
        popOnPopImage: popUpProps.popOnPopImage,
        middleHeight: middleHeight,
        popOnPopContent: popUpProps.popOnPopContent,
        popOnPopBottom: popUpProps.popOnPopBottom
    };

    const popOnPopClose = (close) => {
        popUpProps.closePopOnPop();
    };
    
    useEffect(() => {
        if(popUpProps.openPopOnPop)
            popOnPopOpenClose.open();
        else
            popOnPopOpenClose.close();
    }, [popUpProps.openPopOnPop]);

    return (
        <css.popUpWrapper className={'popWrapper ' + (openPop == 1 && ' show ')}>
            <css.popUpBody className={'popBody ' + (popUpProps.pointerEventsBody && popUpProps.pointerEventsBody)}>
                <css.popUpTop className="popTop" onClick={topPopClick}>
                    <Close  onClick={closePop} />
                    <css.popTitle className="popTitle">{popUpProps.title}</css.popTitle>
                    {popUpProps.tabsSwitching &&
                        <css.tabsSwitching className="tabsSwitching">
                            {popUpProps.tabsSwitching?.map(item => (
                                <css.tabItem className={item.imgClass} onClick={() => item.onChange(item.key)} active={popUpProps.activeTab} key={item.key} />
                            ))}
                        </css.tabsSwitching>
                    }
                    {popUpProps.moreTabs && <css.popTabMore ref={ref} className="popTabMore" onClick={() => setShowList(1)}>
                        {!!showList && <List close={close.setClose}  isClose={close.isClose} title={popUpProps.listTitle} list={popUpProps.moreTabs} left="0" />}
                    </css.popTabMore>}
                </css.popUpTop>
                <css.popUpImage className={'popImage ' + (popUpProps.focus && ' focus')}>
                    {/* <DateTimePicker /> */}
                    <css.image className={'image ' +(popUpProps.imgPathClass)} />
                    {popUpProps.popUpImage}
                </css.popUpImage>
                <css.popUpContent className={'popUpContent ' + (popUpProps.focus && 'focus')} bot={popUpProps.popupBottom ? 1 : 0}>
                    {popUpProps.popupContent}
                </css.popUpContent>
                <css.popUpBottom className="popUpBottom" bot={popUpProps.popupBottom ? 1 : 0} onClick={bottomPopClick}>
                    {popUpProps.popupBottom}
                </css.popUpBottom>

                {popOnPopOpenClose.isOpen && <div><PopOnPop popOnPop={popOnPop}  show={popOnPopOpenClose.showClass} close={() => popOnPopClose()} /></div>}
            </css.popUpBody>
        </css.popUpWrapper>
    );
}

export default PopUp;
