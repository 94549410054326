import styled from 'styled-components/macro';
import {BGImageCenter,dFlexCenter} from '../../../globalVariables/GlobalVariables';
import {sendSecurity} from '../../../assets/symbols/images/Images.css';
import {requiredVerification} from '../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../globalVariables/zIndex';

export const wrap = styled.div`

>div{
    white-space: pre-line;
    font-size:13px ;
}
.popUpFrame{
    z-index:${zIndex.upperModal}
}
.centeredPopupCenter{
    ${p => p.isLevel3 ? '' : 'height:50% ;'}
 }
&.send{
    .centeredPopupTop{
        height:65% ;
    }
    .centeredPopupCenter{
        height:20% ;
    }

}
.centeredPopupTop{
    ${p => p.isLevel3 ? '' : 'height:35% ;'}
    background-color:#DD2162 ;
    >div{
        color:#FFF ;
    }
    ::after{
        content:'' ;
        ${p => BGImageCenter(p.isLevel3 ? sendSecurity : requiredVerification, '150px')}
        position:absolute ;
        bottom:-45px;
        width: 100%;
        height:130px ;
  }

}


`;

export const bottom = styled.div`
height:50% ;

`;
export const text = styled.div`
    height:80% ;
    padding:60px 30px 0 30px ;


`;

export const bottomButtons = styled.div`
    height:20% ;
${dFlexCenter}
>div{
    white-space: nowrap;
}

`;

