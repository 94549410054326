import {useEffect} from 'react';
import setGlobalState from '../../../redux/setGlobalState';
import  * as css  from './TeamMemberPop.css';
import {updateEventMemberStatus} from '../../../redux/team/teamActions';
import {useSelector} from 'react-redux';

import ModeBtn from '../../../component/modeBtn/ModeBtn';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';
import React from 'react';
import SaveAjax from '../../components/saveAjax/SaveAjax';
import {useState} from 'react';
import {getRolePermission} from '../Team';
import store from '../../../store';

function TeamMemberStatus({currentUser}) {
    const eid=useSelector(s=>s.event.eid);
    const userRole = useSelector(s => s.event.userEventRole);
    const [statusClass, setStatusClass] = useState();
    const updateStatusXHR = useSelector(s=>s.team?.updateEventMemberStatusXHR);

    const status=(!(currentUser?.status&&currentUser.status==='inactive'));
    
    useEffect(() => {
        return () => {
            setGlobalState('team', {
                ...store.getState().team,
                currentMemberUid: null,
                existsUser:  null,
                createEventTeamMemberXHR:  null,
                updateEventTeamMemberXHR:  null,
                updateEventMemberStatusXHR:  null,      
            });
        };
    }, []);

    const updateStatus = async () =>{
        await updateEventMemberStatus(currentUser?.uid,eid,status);
    };

    useEffect(()=>{
        if(updateStatusXHR==='SUCCESS') {
            setTimeout(() => {
                setStatusClass('SUCCESS');
                setTimeout(() => {
                    setStatusClass('');
                }, 1200);
            }, 300);
        }
    },[updateStatusXHR]);

    return (
        <css.wrapMode className={statusClass}>
            {currentUser&& <EventRolePermission rolePermission={getRolePermission(currentUser,userRole)}  >
                {(currentUser?.status!=='unauthenticated')&&
                            <>
                                {statusClass==='SUCCESS'&&<SaveAjax/>}
                                <ModeBtn status={status} click={updateStatus} />
                            </>
                }
            </EventRolePermission>}
        </css.wrapMode>
       
    );
}

export default TeamMemberStatus;