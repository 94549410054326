import * as css from './OnPopProgress.css';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from '../../../library/AnimatedProgressProvider';
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';
import {easeQuadInOut} from 'd3-ease';

function OnPopProgress(props) {
    const {percentage}=props;

    return (
        <css.popOnPopProgress className="popOnPopProgress">
            <AnimatedProgressProvider
                valueStart={0}
                valueEnd={percentage == 'Infinity' ? 101 : percentage}
                duration={1.4}
                easingFunction={easeQuadInOut}
            >
                {value => {
                    const roundedValue = value < percentage ? Math.round(value) : percentage;
                    return (
                        <CircularProgressbarWithChildren
                            value={value}
                            strokeWidth={12}
                            background
                            pathPadding={20}
                            styles={buildStyles({
                                backgroundColor: '#fff',
                                textColor: '#484848',
                                pathColor: percentage > 100 ? '#ff0000' : '#76D6E8',
                                trailColor: percentage > 100 ? '#f8cbb9' : '#B9EEF8',
                            })}>
                            <img style={{width: 22, marginBottom: 11, transform: 'rotate(140deg)'}} src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.286' height='15' viewBox='0 0 14.286 15'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235a5b5e;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M397.316,761.544l-12.863-6.625a.705.705,0,0,0-.764.083.693.693,0,0,0-.223.732l1.914,6.481-1.945,6.74a.7.7,0,0,0,.235.736.67.67,0,0,0,.434.151.737.737,0,0,0,.33-.08l12.895-6.986a.69.69,0,0,0,.366-.621A.7.7,0,0,0,397.316,761.544ZM385.265,756.9l9.346,4.814h-7.921Zm1.424,5.809h7.834l-9.29,5.037Z' transform='translate(397.694 769.844) rotate(180)'/%3E%3C/svg%3E" alt="doge" />
                            <div style={{fontSize: 18, marginTop: -5}}>
                                <strong>{(percentage > 100 ? <span style={{color: 'red'}}>!</span> : `${roundedValue}%`)}</strong>
                            </div>
                        </CircularProgressbarWithChildren>
                    );
                }}
            </AnimatedProgressProvider>
        </css.popOnPopProgress>
    );
}

export default OnPopProgress;