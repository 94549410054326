import {Form, useField} from 'formik';
import {useSelector} from 'react-redux';
import {addBtn, closeBtn, editBtn} from '../../../../../functions/globalBtns';
import Button from '../../../../../component/button/button/Button';
import {useTranslation} from 'react-i18next';
import PopOnPop from '../../../../../component/newPopup/popOnPop/PopOnPop';
import Input from '../../../../../component/input/Input';
import InputAmount from '../../../../../component/input/inputAmount/InputAmount';
import * as css1 from '../../courseTicket/pricesPop/PricesPop.css';
import * as css2 from './PricesPop.css';

const css = {...css1, ...css2};

function PricesPop({showPopOnPop, index}) {

    const {t} = useTranslation('ticket');

    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const [pricesField, , pricesHelpers] = useField('prices');
    const [priceField] = useField(`prices[${index}]`);

    const addPrice = () => {
        if(index===null) {
            pricesHelpers.setValue([
                ...pricesField.value,priceField.value
            ]);
        }
        showPopOnPop.setShow(0);
    };

    return (<>
        <PopOnPop dataCy={'price'} image={'groupColor'} title={t('priceList')} showPopOnPop={showPopOnPop} topHeight={190}>
            <Form >
                <css.wrapContentPop>
                    <css.wrapAll>
                        <Input dataCy={'priceListName'} name={`prices[${index}].title`} text={t('priceListName')} description={t('priceListNameDesc')} />
                        <InputAmount name={`prices[${index}].price`} text={t('price')} description={t('priceToBeCharged')} type={'input'} amountText={currencySymbol} />

                    </css.wrapAll>
                </css.wrapContentPop>
                <css.bottomPop>
                    <Button BtnProps={closeBtn(t, () => showPopOnPop.setShow(0))}> </Button>
                    <Button BtnProps={index !==null ? editBtn(t, addPrice) : addBtn(t, addPrice)} />
                </css.bottomPop>
            </Form>

        </PopOnPop >
    </>
    );
}

export default PricesPop;
