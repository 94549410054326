import React, {useState, useEffect} from 'react';
import * as css from './SaveAjax.css';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../redux/setGlobalState';
import useTimeout from '../../../hooks/useTimeout';

function SaveAjax() {
    const [width,setWidth]=useState(0);
    const [speed,setSpeed]=useState('');
    let putXHR=useSelector(s=>s.ui.putXHR);
    const timeout1=useTimeout();
    const timeout2=useTimeout();
    
    useEffect(()=>{
        if(putXHR==='SUCCESS'||putXHR==='FAILED')
            timeout1.current= setTimeout(function (){
                setGlobalState('ui.putXHR',null);
            },1500);

        setWidth(putXHR==='SUCCESS'?'100':putXHR==='LOADING'?'20':'0');
        if(putXHR==='LOADING'){
            setSpeed('not');
            timeout2.current= setTimeout(function (){
                setSpeed('slow');
                setWidth('80');
            },1);
        }
        else
            setSpeed('fast');
    },[putXHR]);

    return (
        <>
            <css.save className={putXHR} speed={speed} width={width+'%'} />
            <css.saveSuccess className={putXHR} />
        </>
    );
}

export default SaveAjax;