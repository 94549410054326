import styled from 'styled-components/macro';
import {container} from '../../../../component/modeBtn/ModeBtn.css';
import {arrowDown, op, pen} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter, ellipsis, scrollbar, tabletMedia} from '../../../../globalVariables/GlobalVariables';
import {cssLeadColumns} from './leadDetail/LeadDetail.css';

const RIGHT_WIDTH = '50px';

export const row = styled.div`
    width:calc(100vw - 20px);
    max-width: 100%;
    display: flex;
    height:70px ;
    transition:all 300ms;
    ${scrollbar}
   ${tabletMedia}{
      overflow: hidden;
   }
    &.delete{
        opacity:0 ;
    }
`;

export const right = styled.div`
   width: ${RIGHT_WIDTH};    
   min-width: ${RIGHT_WIDTH};    
   border-inline-end: 1px solid #00000029;
   ${dFlexCenter}
   height: 80%;
`;

export const left = styled.div`
    ${cssLeadColumns}
   width: 100%;
   display: flex;
   align-items: center;
   position: relative;
   ${tabletMedia}{
      width: calc(100% - 20px);
       ${scrollbar}
   }
   ${container}{
      width: 80px;
      display: flex;
      justify-content: center;
   }
`;

export const options = styled.div`
    width:40px;
    height:20px;
    cursor: pointer;
    ${BGImageCenter(op, '10px')}
    flex-shrink: 0;
`;

export const text = styled.div`
   ${ellipsis}
`;

export const boldText = styled(text)`
   font-weight: 600;
`;

export const widthMaxContent = styled.div`
   width: max-content;
   position: relative;
   cursor: pointer;
   margin-inline-end: 10px;
   :hover{
      ::after{
         content: "";
         position: absolute;
         width: 20px; 
         height: 20px;
         ${BGImageCenter(pen,'10px')}
         transition: all 300ms;
         inset-inline-end: -22px;
         top: calc(50% - 10px);
      }
   }
`;

export const wrapper = styled.div`
   text-align: center;
   display: flex;
   justify-content: center;
    ${ellipsis}
   ${tabletMedia}{
      flex-shrink: 0;
   }
`;

export const icon=styled.div`
   ${BGImageCenter(arrowDown, '9px')};
   width: 100%;
   height: 30px;
   cursor: pointer;
   transform: rotate(90deg);
   transform:rotate(${p => p.theme.rtl ? '90deg' : '-90deg'});
`;

export const mobileScroll=styled.div`
   width: 25px;
   height: 100%;
`;