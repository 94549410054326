import styled from 'styled-components/macro';
import {crm, nisBell, packageImage, team} from '../../../assets/symbols/symbols/Symbols.css';

export const PackageManagement=styled.div`
    height:190px;
    padding: 20px 20px 10px 20px;
    @media screen and (max-width: 800px){
       height:140px;
    }
`;
export const PackageManagementTitle=styled.div`
    height:30px;
    
`;
export const PackageManagementContain=styled.div`
 @media screen and (max-width: 800px){
    display: flex;
    align-items: center;
 }

    `;
export const PackageManagementOp=styled.div`
    height:100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width: 800px){
        width:calc(100% - 110px)
    }
    &.expanded{
        @media screen and (max-width: 800px){
            width:100%;
        }
    }
`;
export const PMtOpType=styled.div`
    font-size:14px;
    background-repeat:no-repeat;
    background-size: 25px 25px;
    background-position: center 2px;
    padding-top: 25px;
    cursor:pointer;
    position:relative;    
    @media screen and (max-width: 800px){
        background-size: 20px 20px;
         
    }
`;
export const PMtOpTypeCrm=styled(PMtOpType)`
    background-image: url("${crm}");

`;
export const PMtOpTypeDefinition=styled(PMtOpType)`
    background-image: url("${packageImage}");
`;
export const PMtOpTypeTeam=styled(PMtOpType)`
    background-image: url("${team}");
`;
export const PMtOpTypeCash=styled(PMtOpType)`
    background-image: url("${nisBell}");
`;
export const PMtOpTypeCrowd=styled(PMtOpType)`
    background-image: url("${team}");
`;
export const PackageManagementManage=styled.div`
    height:30px;
    font-weight:600;
    background-color:#FFFFFF;
    border: 1px solid #000713;
    width: max-content;
    padding: 0 35px 0 20px;
    margin: 0 auto;
    border-radius: 16px;
    line-height: 30px;
    background-position: calc(100% - 5px) center;
    background-size: 25px 25px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27.935' height='30.356' viewBox='0 0 27.935 30.356'%3E%3Cdefs%3E%3Cstyle%3E .cls-1, .cls-2 %7B fill: none; stroke: %23484848; stroke-linejoin: round; stroke-width: 1.5px; %7D .cls-2 %7B stroke-linecap: round; %7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1852' data-name='Group 1852' transform='translate(1.05 0.956)'%3E%3Cpath id='Path_2219' data-name='Path 2219' class='cls-1' d='M144.464,56.188,142.549,64a3.44,3.44,0,1,1-6.682-1.637l1.915-7.814Z' transform='translate(-132.421 -49.185)'/%3E%3Cpath id='Path_2220' data-name='Path 2220' class='cls-2' d='M129.941,86.977c.7-.519,2.479-.576,4.18-.159s3.256,1.292,3.634,2.074' transform='translate(-129.941 -62.792)'/%3E%3Cline id='Line_834' data-name='Line 834' class='cls-1' x1='1.778' y2='6.681' transform='translate(4.297 17.097)'/%3E%3Cpath id='Path_2221' data-name='Path 2221' class='cls-1' d='M153.941,61.188,155.856,69a3.44,3.44,0,1,0,6.682-1.637l-1.915-7.814Z' transform='translate(-140.149 -51.311)'/%3E%3Cpath id='Path_2222' data-name='Path 2222' class='cls-2' d='M169.115,90.977c-.7-.519-2.48-.576-4.181-.159s-3.256,1.292-3.634,2.074' transform='translate(-143.28 -64.493)'/%3E%3Cline id='Line_835' data-name='Line 835' class='cls-1' x2='1.778' y2='6.106' transform='translate(19.76 19.97)'/%3E%3Cline id='Line_836' data-name='Line 836' class='cls-2' x1='3.629' transform='translate(5.044 10.038)'/%3E%3Cline id='Line_837' data-name='Line 837' class='cls-2' x1='4.583' y2='0.003' transform='translate(14.975 13.667)'/%3E%3Cline id='Line_838' data-name='Line 838' class='cls-2' x1='1.411' y1='2.929' transform='translate(10.769 0.966)'/%3E%3Cline id='Line_839' data-name='Line 839' class='cls-2' y1='1.411' x2='2.929' transform='translate(17.853 4.832)'/%3E%3Cline id='Line_840' data-name='Line 840' class='cls-2' y1='2.712' x2='0.949' transform='translate(16.416)'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat:no-repeat;
    cursor:pointer;
    &.expanded{
        display:none;
    }
    @media screen and (max-width: 800px){
        font-size: ${props => (props.theme.rtl ? '14px' : '11px')};
        width: 110px;
        padding: 0 25px 0 5px;
        background-size: 18px 18px;
    }
    
`;