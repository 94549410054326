import createReducer from '../createReducer';

const initialState = {
    members:[],
    eventMembers: [],
    accountMembers:[],
    currentMemberUid:null,
    existsUser:null,

    getEventTeamXHR:null,
    updateEventTeamMemberXHR:null,
    updateEventMemberStatusXHR:null,
    createEventTeamMemberXHR:null,

    getAccountTeamXHR:null,
    updateAccountTeamMemberXHR:null,
    createAccountTeamMemberXHR:null,
};

const reducer = createReducer('team', initialState);

export default reducer;