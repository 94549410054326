import {formatSuffix, parseNumber} from '../../library/numberFunction';

export const formatRealtimeNumber = (input, kFormat = false) => {
    const num = parseNumber(input);
    return (kFormat && Math.abs(num) >= 100000) ? formatSuffix(num) : num.toLocaleString();
};

export const formatRealtimeDecimal = (input, kFormat = false) => {
    const num = parseFloat(`${input}`.replaceAll(',',''));
    if(isNaN(num)) return input;

    if(kFormat && Math.abs(num) >= 100000) {
        return formatSuffix(num);
    }
    return num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2});
};