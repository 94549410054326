import * as css from './EventMenuStatsItem.css'; 
import TooltipAbsoluteSum from '../../../../component/tooltip/tooltipAbsoluteSum/TooltipAbsoluteSum'; 

function EventMenuStatsItem({children,title,className,imgOrders,amountOrders,amountTickets,imgTickets,toolTip}) {
    return (
        <css.EMSFrame>
            <css.EMSFrameTitle >{title}</css.EMSFrameTitle>
            <css.EMSFrameContent className={'today '+className}>
                {!!imgOrders&&<css.EMSFrameImage className={imgOrders} />}
                <css.EMSFrameText className={children?'hasChildren':''}>{children?children:amountOrders}
                    <TooltipAbsoluteSum text={`${toolTip?.currencySymbol||''}${toolTip?.amountOrders || ''}`} amount={amountOrders}/>
                </css.EMSFrameText>                    
            </css.EMSFrameContent>
            {['orders','tickets'].includes(className)&&
                < css.EMSFrameContent className={'all'}>
                    <css.EMSFrameImage className={imgTickets} /><css.EMSFrameText>{amountTickets}
                        <TooltipAbsoluteSum text={toolTip?.amountTickets} amount={amountTickets}/>
                    </css.EMSFrameText>
                </css.EMSFrameContent>
            }
        </css.EMSFrame>

    );
}
export default EventMenuStatsItem;
