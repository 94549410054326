export const bank = [{
    key: '99',
    text: 'bankIsrael'
},
{
    key: '12',
    text: 'workers'
},
{
    key: '10',
    text: 'leumi'
},
{
    key: '11',
    text: 'discount'
},
{
    key: '20',
    text: 'mizrahi'
},
{
    key: '31',
    text: 'firstBenLumi'
},
{
    key: '14',
    text: 'otzarHchyal'
},
{
    key: '17',
    text: 'marcantil'
},
{
    key: '9',
    text: 'haduar'
},
{
    key: '13',
    text: 'e-good'
},
{
    key: '46',
    text: 'masad'
},
{
    key: '52',
    text: 'poalayAgudat'
},
{
    key: '90',
    text: 'discuntLemashcata'
},
{
    key: '77',
    text: 'LeumiLemashcanta'
},
{
    key: '4',
    text: 'yahav'
},
{
    key: '26',
    text: 'yoovnak'
},
{
    key: '7',
    text: 'lepitooach'
},
{
    key: '8',
    text: 'hasapanoot'
},
{
    key: '6',
    text: 'adanim'
},
{
    key: '54',
    text: 'jerusalemBank'
},
{
    key: '34',
    text: 'aravi'
},
{
    key: '1',
    text: 'yoroBank'
},
{
    key: '19',
    text: 'hachaklaoot'
},
{
    key: '48',
    text: 'koopatHuved'
},
{
    key: '23',
    text: 'HSBC'
},
{
    key: '67',
    text: 'ArabLandBank'
},
{
    key: '25',
    text: 'BNPParibasIsrael'
},
{
    key: '39',
    text: 'SBIState'
},
{
    key: '22',
    text: 'cityBank'
},
{
    key: '68',
    text: 'otzahr'
},
{
    key: '18',
    text: 'bankOneZero'
}];
