import styled from 'styled-components/macro';
import {mobileMedia,  scrollbar} from '../../../globalVariables/GlobalVariables';
import {simpleArrow} from '../../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../../component/button/btn/Btn.css';
import zIndex from '../../../globalVariables/zIndex';

export const Popup = styled.div`
    height:100%;
    width:100%;    
    border-radius: ${props => (props.theme.rtl?'10px 0px 0px 10px':'0px 10px 10px 0px' )} ;
    overflow: hidden;
    .react-datepicker{
        div{
            color:#222;
        }
        .react-datepicker__day--selected{
            color:#FFF;
        }
    }
    input{
        color: #EEE;
    }
    div{
    color:#EEE;
    letter-spacing: 1.5px;
    .inputList{
        div{
            color:#000;
        }
        }
        ${btn}{
            color:#2F2F2F ;  
    }
    }
    .packageUpgradeIcon{
        inset-inline-end:110px;
        left: unset;
        ${mobileMedia}{
            inset-inline-end:45px;
        }
    }
    font-size: 14px;
    position: fixed;
    bottom:0;
    inset-inline-start:-1000px;
    width: 1000px;
    max-width: 95vw;
    background: #2F2F2F;
    transition: all 300ms; 
    z-index:${zIndex.basicModal};
    &.show{        
        inset-inline-start:0;
    }
    ${mobileMedia}{
        width: 95vw;
        &.show{
            inset-inline-start:0px !important;
        }
    }

    & .inputList{
        ::after{
            background-image: unset ;
            background-color: #29ACE3;
            -webkit-mask-image: url("${simpleArrow}");
            mask-image: url("${simpleArrow}");
            -webkit-mask-position: ${props=>props.theme.rtl?'left ':'right '} center;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 9px ;
        }
    }



`;

export const accordions = styled.div`
    height: calc(100% - 200px);
    ${scrollbar}   
    margin-top:20px;
    margin-bottom: 20px;
    padding: 20px 0;
    & .accordion{
        padding:0;
    }
    & .accordionHeader{
        padding: 0 30px;
        font-weight: 700;
        ${mobileMedia}{
            padding: 0;
        }
    }
    & .accordionArrow{
        ::before{
        background-image: unset ;
        background-color: #29ACE3;
        -webkit-mask-image: url("${simpleArrow}");
        mask-image: url("${simpleArrow}");
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 10px ;
        }
    }

`;

