import {useEffect, useState} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import * as css from './CountriesPhoneList.css';
import List from '../../list/List';
import useShow from '../../../hooks/useShow';
import heList from 'react-phone-number-input/locale/he';
import enList from 'react-phone-number-input/locale/en';
import {getCountryCallingCode} from 'react-phone-number-input';
import countriesMetadata from 'libphonenumber-js/metadata.max.json';
import useMobile from '../../../hooks/useMobile';
import InputSearch from '../../inputSearch/InputSearch';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import {DEFAULT_COUNTRY} from '../../../globalVariables/locals';

const countriesList = Object.keys(countriesMetadata.countries);
const PROMOTED_COUNTRIES = ['IL', 'US'];

export function CountriesPhoneList(props) {
    const {t, fieldName, onChange, onUpdate, text, listHeight, listWidth, styleObj = {}, defaultCountry} = props;
    const cssObj = {...css, ...styleObj};

    const {i18n} = useTranslation('login');

    const {open, close, isOpen, showClass} = useShow();
    const [field, , helper] = fieldName ? useField(fieldName) : [];
    const isMobile = useMobile();

    const initCountry = (countryCode) => {
        if(!countryCode) return {};
        const dialCode = getCountryCallingCode(countryCode);
        const text = i18n.language === 'he' ? heList[countryCode] : enList[countryCode];
        const country = {heName: heList[countryCode], enName: enList[countryCode], code: countryCode, dialCode, text};
        return country;
    };
    const [countryList, setCountryList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(initCountry(DEFAULT_COUNTRY));
    const [showImage, setShowImage] = useState(false);

    useEffect(() => {
        if(defaultCountry) {
            setSelectedCountry(initCountry(defaultCountry));
            helper?.setValue(defaultCountry);
        }
    }, [defaultCountry]);

    useEffect(() => {
        field?.value && setSelectedCountry(initCountry(field.value));
    }, [field?.value]);

    useEffectOnUpdate(() => {
        onUpdate && onUpdate(field.value);
    }, [field?.value]);

    useEffectOnUpdate(() => {
        setSelectedCountry(initCountry(selectedCountry.code));
    }, [searchText, i18n.language]);

    useEffect(() => {
        setCountries();
    }, [searchText, i18n.language]);

    const setCountries = async () => {
        const newList = countriesList.reduce((list, countryCode) => {
            const country = initCountry(countryCode);
            if(!PROMOTED_COUNTRIES.find(code => code === countryCode) && (country.enName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || country.heName.includes(searchText) || country.dialCode.includes(searchText)))
                return [...list, {
                    key: country.code,
                    text: ` ${country.text}     (+${country.dialCode}) `,
                    icon: `https://flagcdn.com/w40/${country.code.toLocaleLowerCase()}.png`,
                    onClick: () => onClick(country)
                }];
            return list;

        }, []);

        const promotedList = PROMOTED_COUNTRIES.reduce((list, countryCode) => {
            const country = initCountry(countryCode);
            if(country.enName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || country.heName.includes(searchText) || country.dialCode.includes(searchText))
                return [...list, {
                    key: country.code,
                    text: ` ${country.text}     (+${country.dialCode}) `,
                    icon: `https://flagcdn.com/w40/${country.code.toLocaleLowerCase()}.png`,
                    onClick: () => onClick(country)
                }];
            return list;
        }, []);
        setCountryList(promotedList.concat(newList.sort((a, b) => a.text.localeCompare(b.text))));
    };

    const onClick = (country) => {
        setSelectedCountry(country);
        helper?.setValue(country.code);
        onChange && onChange(country);
        setShowImage(false);
    };

    const listTitle = <cssObj.searchWrapper className='searchWrapper'>
        <cssObj.chosenItemTitle className={isOpen && 'open'}>
            <img
                src={`https://flagcdn.com/w20/${selectedCountry.code.toLocaleLowerCase()}.png`}
                width={24}
                height={17}
                onLoad={() => setShowImage(true)}
                style={{visibility: showImage ? '' : 'hidden'}}
                alt={selectedCountry.text} />
            {selectedCountry.text}
        </cssObj.chosenItemTitle>
        <InputSearch search={searchText ? 1 : 0} onClick={() => setSearchText('')} value={searchText} onChange={e => setSearchText(e.target.value)} text={text || t('search')} />
    </cssObj.searchWrapper>;

    return (<>
        <cssObj.container className='phone-container'>
            <cssObj.filter className={isOpen && 'open'} onClick={isOpen ? close : open}>
                <cssObj.chosenItem className={isOpen && 'open'} selected={selectedCountry}>
                    <img
                        src={`https://flagcdn.com/w20/${selectedCountry.code?.toLocaleLowerCase()}.png`}
                        width="20"
                        onLoad={() => setShowImage(true)}
                        style={{visibility: showImage ? '' : 'hidden'}}
                        alt={selectedCountry.text} />
                </cssObj.chosenItem>
                {isOpen && <List
                    title={listTitle}
                    showClass={showClass}
                    close={close}
                    list={countryList}
                    width={!isMobile && `${(listWidth || 280)}px`}
                    height={isMobile ? 1000 : (listHeight || 170)}
                    left={i18n.language === 'he' ? '' : (listWidth || 280) - 43 + 'px'}
                    t={t}
                    classes={'countries-list'}
                />}
            </cssObj.filter>
        </cssObj.container>
    </>);
}