import * as css from './AccountRealtime.css';
import {useSelector} from 'react-redux';
import useShow from '../../../hooks/useShow';
import UserProfileMenu from '../../../layout/topMenu/layoutUserProfile/layoutUserProfileMenu/LayoutUserProfileMenu';
import {ThemeProvider} from 'styled-components';
import {Profile} from '../components/profile/Profile';
import BtnAdd from '../../../component/button/btnAdd/BtnAdd';
import {useTranslation} from 'react-i18next';
import useGlobalShow from '../../../hooks/useGlobalShow';
import useAccountMenu from '../../../tickchakHooks/useAccontMenu';
import ItemIcon from '../../../component/itemIcon/ItemIcon';
import React, {useEffect} from 'react';
import {unVerified, verified} from '../../../assets/symbols/symbols/Symbols.css';
import useNavigationLink from '../../../hooks/useNavigationLink';
import useMobile from '../../../hooks/useMobile';
import {getRealtimeText} from '../../../redux/realtime/realtimeActions';
import {EXCLUDE_ACCOUNT_MANAGER_MANAGEMENT} from '../../../config/config';
import store from '../../../store';

export  const showDetailsAccountPixel=()=>{
    const {pid,userAccountRole}=store.getState().account;
    return (userAccountRole!=='manager' || !EXCLUDE_ACCOUNT_MANAGER_MANAGEMENT.includes(pid));

};
function AccountRealtime () {
    const event = useSelector((s: any) => s.event);
    const account = useSelector((s: any) => s.account);
    const user = useSelector((s: any) => s.user);
    const realtimeDesign = useSelector((s: any) => s.realtime.user.realtimeDesign);
    const showProfile = useShow();
    const showManageEvent = useGlobalShow('isOpenCreateEvent');
    const {t} = useTranslation('package');
    const accountList = useAccountMenu();
    const accountVerified = account.bankVerified;
    const verificationPop = useNavigationLink('verificationPop', 'open');
    const isMobile = useMobile();
    const {realtimeText,getRealtimeTextXHR} = useSelector((s:any) => s.realtime.event);

    const handleMenuClick = (item) => {
        if(item.onClick) {
            item.onClick(item);
            return;
        }
        if(item.onClickMenu)
            item.onClickMenu(item);

    };
    useEffect(() => {
        if(!realtimeText?.he && !getRealtimeTextXHR)
            getRealtimeText().then();
    }, []);
    const accountVerification = () => showDetailsAccountPixel() &&<css.accountVerification isVerified={!!accountVerified}
        icon={accountVerified ? verified : unVerified}
        onClick={() => {
            !accountVerified && verificationPop.open();
        }}>{!isMobile&&t(`${accountVerified ? '' : 'un'}verifiedAccount`)}</css.accountVerification>;

    return (
        <ThemeProvider theme={{isDarkMode: realtimeDesign?.darkDisplay}}>
            <css.wrapper data-cy="accountRealtime" colors={event.colors}>
                {!!showProfile.isOpen &&
                    <UserProfileMenu close={showProfile.close} closeMenu={showProfile.close} hide={false}
                        showEvents={() => {
                        }} userImgPath={user.picture} userName={`${user.nameFirst} ${user.nameLast}`}/>}
                {isMobile && <css.top>
                    <css.title>{account.title}</css.title>
                    {accountVerification()}
                </css.top>}
                <css.gridTop>
                    <Profile openProfile={showProfile.open}/>
                    <css.accountDetails>
                        {!isMobile && <css.title><span>{t('accountName')}</span>{account.title}</css.title>}
                        <css.menu>
                            {showDetailsAccountPixel()&&<css.menuScroll>
                                {accountList().arr.map((item) => {
                                    if(['accountVerification', 'packagePid'].includes(item.key))
                                        return;
                                    return (
                                        <ItemIcon icon={item.icon} text={t(`layout:${item.text}`)} key={item.key}
                                            onClick={() => handleMenuClick(item)}/>
                                    );
                                })}
                            </css.menuScroll>}
                        </css.menu>
                        <css.colors/>
                        <css.team/>
                        <css.accountState>{accountVerification()}</css.accountState>
                    </css.accountDetails>
                    <css.eventSection>
                        <css.image/>
                        <css.text>{t('firstPageText')}</css.text>
                        <BtnAdd text={t('firstPageBtn')} state={showManageEvent.isOpen} onClick={showManageEvent.open}/>
                    </css.eventSection>
                </css.gridTop>
            </css.wrapper>

        </ThemeProvider>
    );
}

export default AccountRealtime;
