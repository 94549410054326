import styled from 'styled-components/macro';
import {BLACK, DARK_GRAY, GRAY92, mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../fonts';

export const container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 5px;
  height: 100%;
  justify-content: space-between;
`;

export const title = styled.div`
  ${realtimeFonts.windowTitle}
`;

export const amount = styled.div`
  ${realtimeFonts.mainDataBold}
  margin-block: 9px 10px;
  ${mobileMedia}{
    line-height: 40px;
  }
`;

export const stats = styled.div`
  margin-top: 10px;
  ${realtimeFonts.text}

`;

export const statsItem = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-start;
  gap: 10px;
`;
export const averageNumber = styled.div`
  width: 56px;
  height: 21px;
  line-height: 21px;
  font-weight: bold;
  background: ${GRAY92};
  color: ${p=>p.theme.isDarkMode?DARK_GRAY:BLACK};
  border-radius: 11px;
  text-align: center;`
;
