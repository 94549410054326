import * as css from './TicketsGroups.css';
import {useTranslation} from 'react-i18next';
import useShow from '../../../../../hooks/useShow';
import List from '../../../../../component/list/List';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import getSegment from '../../../../../library/getSegment';

function TicketsGroups({ticket}) {
    
    const groups = useSelector(state => state.tickets.groups);
    const event = useSelector(state => state.event);
    const history = useHistory();
    const ticketGroups = groups?.filter(g => g.tickets?.some(t => t.tid === ticket.tid)).map(i=>({...i,rightChildren:<css.rect color={i.color}/>}));

    const {t} = useTranslation('ticket');

    const navigateToGroup =(group)=>{
        const segment = getSegment(event.type);
        history.push(`/e/${event.eid}/${segment}/groups/${group}`);
    };

    const {open, close, isOpen, showClass} = useShow();

    if(ticket.draft)
        return    <css.container>
            <css.group className='draft' color='DD2F21'>{t('draft')}</css.group>
        </css.container >;

    if(!ticketGroups)
        return (<></>);

    return (
        <css.container>
            {ticketGroups?.length === 1 &&
        <css.group className='TicketsGroups' color={ticketGroups[0]?.color} onClick={()=>navigateToGroup(ticketGroups[0]?.gid)}>{ticketGroups[0]?.title}</css.group>}
            {ticketGroups?.length > 1 &&
        <css.group className='TicketsGroups groupsList' color={ticketGroups[0]?.color} onClick={e => { e.stopPropagation(); isOpen ? close() : open(); }}>{ticketGroups.length + ' ' + t('groups')}
        </css.group>}
            {isOpen && <List list={ticketGroups} close={close} showClass={showClass} left={'-75px'} top={20} onClick={(g)=>navigateToGroup(g.gid)}/>}

        </css.container >

    );
}
export default TicketsGroups;
