import {useSelector} from 'react-redux';
import * as css from './ClusterMobile.css';
import {useTranslation} from 'react-i18next';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';
import {ts2date, ts2time} from '../../../../library/dateFunctions_old_to_be_deprecated';
import {handleState} from '../clusterEventRow/ClusterEventRow';

function ClusterMobile({event, openEditPopup, openPopup, isDrag, checked}) {
    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const tLayout = useTranslation('layout');
    const price = event?.ticketsPrice;

    return (
        <css.container className={checked?.isSelectable && 'selectable'}>
            <css.placeDrag className={isDrag ? 'isDrag' : ''} />
            <css.mobileRow className={isDrag ? 'isDrag' : ''} onClick={openPopup}>
                <css.iconWrap className={(event.fbImg ? 'image' : '')}>
                    <css.image image={event.fbImg} />
                    <css.editPop onClick={(e) => { e.stopPropagation(); openEditPopup(event,event.cgid);}} />
                </css.iconWrap>
                <css.left>
                    <css.part>
                        <css.topRight>
                            <css.boldText>{event.title}</css.boldText>
                            <css.amount>
                                <css.text>{ts2date(event?.timeStart)}&nbsp;{'-'}&nbsp;</css.text>
                                <css.text>{ts2time(event?.timeStart)}</css.text>
                            </css.amount>
                        </css.topRight>
                        <css.topLeft>
                            <ModeBtn className=" small " click={(val, e) => { e.stopPropagation(); handleState(val, event, tLayout); }} status={event.active} />
                            <css.options />
                        </css.topLeft>
                    </css.part>
                    <css.part>
                        <css.details>
                            <css.price>{price['min'] === price['max'] ? Math.trunc(price['max']) : Math.trunc(price['min']) + '-' + Math.trunc(price['max'])}&nbsp;<span>{currencySymbol}</span></css.price>
                        </css.details>
                    </css.part>
                </css.left>
            </css.mobileRow >
        </css.container>
    );
}
export default ClusterMobile;
