import Accordion from '../accordion/Accordion';
import {useTranslation} from 'react-i18next';
import CompanyDetails from './companyDetails/CompanyDetails';
import ContactDetails from './contactDeatils/contactDeatils';
import {getPackageProducer, updatePackageProducer} from '../../../../redux/account/accountActions';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import {validateData} from './validatePackage';
import {initPhoneNumber} from '../../../../functions/intrernationalPhoneNumber';
import validator from 'validator';
import useHashScroll from '../../../../hooks/useHashScroll';
import {yupStringMin} from '../../../../functions/validationsYup';

function PackageProducerBody({formRef}) {

    const {t} = useTranslation('package');

    const {packagesProducer,pid,getAccountXHR} = useSelector((s) => s.account);
    const [isBusiness, setIsBusiness] = useState();

    useEffect( () => {
        // if (!packagesProducer)
        if(pid&& ['SUCCESS','UPDATED'].includes(getAccountXHR))
            getPackageProducer().then();
    }, [getAccountXHR]);
    Yup.addMethod(Yup.mixed, 'validateAccount', function(keyName,message) {
        return this.test('validateAccount', message, function(value) {
            return  validateData(keyName,value,isBusiness);
        });
    });
    Yup.addMethod(Yup.mixed, 'phone', function(message, countryField) {
        return this.test('phone', message, function(value) {
            const country = this.resolve(Yup.ref(countryField));
            const local = validator.isMobilePhoneLocales.find(local => local.includes(country));
            const phone = initPhoneNumber({nationalNumber: value, country: country});
            return validator.isMobilePhone(value || '', local) || validator.isMobilePhone('+'+phone.internationalNumber || '', local);
        });
    });
    const validationSchema = Yup.object().shape({
        billingName: yupStringMin().max(30).required(),
        phone: Yup.string().phone(t('numberMessage'), 'countryCode').required(),
        billingId: Yup.string().required().validateAccount('billingId'),///////
        email: Yup.string().nullable(true).required().validateAccount('email'),
        city: yupStringMin().max(30).nullable(true),
        zipCode: Yup.string().nullable(true),
        address: yupStringMin().max(30).nullable(true),
        contactName: yupStringMin().max(30).nullable(true),
        contactEmail: Yup.string().nullable(true).validateAccount('email'),
        contactPhone: Yup.string().phone(t('numberMessage'),'contactCountryCode').nullable(true),
        brandingSms: Yup.string().max(10).nullable(true).validateAccount('brandingSms'),
        brandingEmail: Yup.string().nullable(true).validateAccount('email')
    }
    );

    const initialValues = {
        billingName: packagesProducer?.billingName,
        phone: packagesProducer?.phone,
        countryCode: packagesProducer?.countryCode,
        billingId: packagesProducer?.billingId,
        email: packagesProducer?.email,
        city: packagesProducer?.city,
        zipCode: packagesProducer?.zipCode,
        address: packagesProducer?.address,
        contactName: packagesProducer?.contactName,
        contactEmail: packagesProducer?.contactEmail,
        contactPhone: packagesProducer?.contactPhone,
        contactCountryCode: packagesProducer?.contactCountryCode,
        brandingSms: packagesProducer?.brandingSms,
        brandingEmail: packagesProducer?.brandingEmail,
    };

    const callBack = () => {
        bounceMessage(t('verifyEmail'),'success');
    };
    const producerDetailsRef = useRef(null);
    const contactDetailsRef = useRef(null);

    const hashRefMap = {
        'producerDetails': producerDetailsRef,
        'contactDetails': contactDetailsRef
    };
    useHashScroll(hashRefMap);
    const onSubmitForm = async (values) => {
        const newValues = {...values};
        if(values.phone === '')
            newValues.phone = null;
        if(values.phone && values.countryCode)
            newValues.phone = initPhoneNumber({nationalNumber: values.phone, country: values.countryCode}).internationalNumber;
        if(values.contactPhone === '')
            newValues.contactPhone = null;
        if(values.contactPhone && values.contactCountryCode)
            newValues.contactPhone = initPhoneNumber({nationalNumber: values.contactPhone, country: values.contactCountryCode}).internationalNumber;
        if(values.zipCode === '')
            newValues.zipCode = null;
        newValues.isBusiness = isBusiness;
        await updatePackageProducer(newValues, callBack);
    };

    return (
        <Formik innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={values => { onSubmitForm(values); }}
        >
            <Form >
                <Accordion title={t('producerDetails')} isAlwaysOpen={true} forwardRef={producerDetailsRef} >
                    <CompanyDetails isBusiness={isBusiness} setIsBusiness={setIsBusiness} />
                </Accordion>
                <Accordion forwardRef={contactDetailsRef} title={t('contactDetails')} isAlwaysOpen={true}  >
                    <ContactDetails callBack={callBack} />
                </Accordion>
            </Form>
        </Formik>
    );
}

export default PackageProducerBody;
