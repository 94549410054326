import BtnPlus from '../btnPlus/BtnPlus';
import * as css from './BtnAddWhite.css';

function BtnAddWhite({onClick,text}) {

    return (
        <css.buttonNoWhite onClick ={onClick}>
            <BtnPlus  hasText={false} onClick={onClick} />
            <css.buttonNoTextWhite>
                {text}
            </css.buttonNoTextWhite>
        </css.buttonNoWhite>
    );
}
export default BtnAddWhite;