import {useContext} from 'react';
import List from '../../../component/list/List';
import useShow from '../../../hooks/useShow';
import * as css from './OptionsList.css';
import {PageWrapperContext} from '../pageWrapper/PageWrapper';

function OptionsList() {
    const {open, close, isOpen, showClass} = useShow();
    const {operations} = useContext(PageWrapperContext);
    const {list ,...listProps} = operations.optionsList;

    return (
        <css.optionsIcon onClick={isOpen?close:open}>
            {isOpen&& <List showClass={showClass} close={close} list={list} {...listProps}/> }
        </css.optionsIcon>
    );
}

export default OptionsList;
