import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ProgressPopup from '../../../component/progressPopup/ProgressPopup';
import useNavigationLink from '../../../hooks/useNavigationLink';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import EmailVerification from '../emailVerification/EmailVerification';
import BankVerification from './bankVerification/BankVerification';
import TermsApproval from './termsApproval/TermsApproval';

function VerificationAccountPopup({show}) {
    const account = useSelector(s=>s.account);
    const  verificationPop = useNavigationLink();
    const {t} = useTranslation('layout');
    
    const closeAnyWay = () => {
        verificationPop.close('verificationPop');
        show.close();
    };

    const closePop = ()=>{
        if(!(account.bankVerified && account.termsVerified && account.ownerEmailVerified===1)){
            bounceMessage(
                t('verificationIsNotYetComplete'),'warning',{
                    text:t('continueTheProcess'),
                    onClick:()=>{},
                },{
                    text: t('close'),
                    onClick: () => {closeAnyWay();}
                }
            );
        }
        else {
            closeAnyWay();
        }
    };

    useEffect(()=>{
        show.open();
    },[]);

    const steps=[{
        text:t('emailVerification'),
        comp:<EmailVerification/>,
        isComplete:()=>account.ownerEmailVerified===1
    },{
        text:t('termsApproval'),
        comp:<TermsApproval />,
        height:'750px',
        isComplete:()=>!!account.termsVerified
    },{
        text:t('bankApproval'),
        comp:<BankVerification close={closePop}/>,
        isComplete:()=>!!account.bankVerified
    },
    ];
    return (<>
        {show.isOpen&&<ProgressPopup title={t('accountVerification')} {...show} steps={steps} close={closePop}/>}
    </>);
}

export default VerificationAccountPopup;