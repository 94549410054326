import {useTranslation} from 'react-i18next';
import Input from '../../../../../component/input/Input';
import List from '../../../../../component/list/List';
import ModeBtn from '../../../../../component/modeBtn/ModeBtn';
import useShow from '../../../../../hooks/useShow';
import InputAmount from '../../../../../component/input/inputAmount/InputAmount';
import * as css from './ExtensionsRow.ccs';
import {useSelector} from 'react-redux';
import {useField} from 'formik';
import DisabledInput from '../../../../../component/input/disabledInput/DisabledInput';
import {Else, If, Then} from 'react-if';
import Copy from '../../../../../component/copy/Copy';
import {blackDuplicate, remove} from '../../../../../assets/symbols/symbols/Symbols.css';
import FormikModeBtn from '../../../../../component/formikModeBtn/FormikModeBtn';
import useMobile from '../../../../../hooks/useMobile';

function ExtensionsRow({index, extra, setShow}) {
    const {t} = useTranslation('ticket');
    const isMobile = useMobile();
    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const [extras, , helpers] = useField('extras');

    const {open, close, isOpen, showClass} = useShow();

    const listOptions = [
        {key: 'dup', text: t('duplicate'), type: 'icon', icon: blackDuplicate},
        {key: 'delete', text: t('delete'), type: 'icon', icon: remove},
        extra['xid'] && {key: 'xid', text: `${t('extra')}: ${extra['xid']}`, rightChildren: <Copy color={extra.color} link={extra['xid']} />}
    ].filter(Boolean);

    const toggleClick = (e, val) => {
        e?.stopPropagation();
        helpers.setValue(extras.value.map((i, indexE) => {
            if(index === indexE)
                return {...i, active: !!val === true ? 1 : 0};
            return i;
        }));
    };

    const onClickOptionsList = (item) => {
        switch (item.key) {
            case 'delete': {
                let array = [...extras.value]; // make a separate copy of the array
                if(index !== -1) {
                    array.splice(index, 1);
                    helpers.setValue(array);
                }
                helpers.setTouched(true);
                break;
            }
            case 'dup': {
                const selectedItem = extras.value.filter((_i, indexE) => index === indexE)[0];
                const {...extraItem} = selectedItem;
                let arr = [...extras.value];
                arr.push(extraItem);
                helpers.setValue(arr);
                helpers.setTouched(true);
                break;
            }
            default:
                break;
        }
        close();
    };
    
    const fields = <>
        <css.field> <InputAmount name={`extras.${index}.price`} text={t('price')} amountText={currencySymbol} disabled/></css.field>
        <css.field> <InputAmount name={`extras.${index}.amount`} text={t('amountField')} amountText={t('pcs')} disabled /></css.field>
        <If condition={extras?.value[index]?.limitByTickets}>
            <Then>
                <css.fieldLimit><DisabledInput value={t('asTicketsNum')} text={t('limitField')} /></css.fieldLimit>
            </Then>
            <Else>
                <css.fieldLimit> <InputAmount name={`extras.${index}.limit`} text={t('limitField')} amountText={t('pcs')} disabled /></css.fieldLimit>
            </Else>
        </If>
    </>;

    return (
        <>
            <css.row onClick={() => { setShow(index); }} data-cy='extensionsRow'>
                <css.placeDrag className=' place ' />
                {isMobile ?
                    <><css.top><css.wrapInput>
                        <Input name={`extras.${index}.title`} text={t('extensionName')} disabled />
                    </css.wrapInput>
                    <css.wrapMBtn>
                        <ModeBtn click={(val, e) => toggleClick(e, val)} status={extras?.value[index]?.active} className={'small'} />
                    </css.wrapMBtn>
                    <css.wrapList>
                        <css.options onClick={isOpen ? close : open} >
                            {isOpen && <List top={20} left={'0px'} width={'200px'} showClass={showClass} close={close} list={listOptions} onClick={onClickOptionsList} />}
                        </css.options>
                    </css.wrapList>
                    </css.top>
                    <css.bottom>{fields} </css.bottom>
                    </>
                    :
                    <><css.wrapInput>
                        <Input name={`extras.${index}.title`} text={t('extensionName')} disabled />
                    </css.wrapInput>
                    {fields}
                    <css.wrapMBtn>
                        <FormikModeBtn name={`extras.${index}.active`} className={'small'} />
                    </css.wrapMBtn>
                    <css.wrapList>
                        <css.options  onClick={e => {
                            if(isOpen) close();
                            else open();
                            e.stopPropagation();
                        }} >
                            {isOpen && <List top={20} left={'0px'} width={'200px'} showClass={showClass} close={close} list={listOptions} onClick={onClickOptionsList} />}
                        </css.options>
                    </css.wrapList> </>}
            </css.row>
        </>
    );
}

export default ExtensionsRow;
