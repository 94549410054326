import React from 'react';
import * as ImCss from './UserImage.css';

const UserImage = (props) => {
    return <ImCss.ImageWrapperStyledDiv className={props.class} height={props?.size?.height} width={props.size?.width}>
        <img alt="" src={props.path} width="100%" height="100%" style={{borderRadius:'50%'}}/>
        {props.children&&props.children}
    </ImCss.ImageWrapperStyledDiv>;
};

export default UserImage;