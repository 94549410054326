import styled from 'styled-components/macro';
import {dFlexCenter} from '../../globalVariables/GlobalVariables';
import {text} from '../colorsPicker/ColorsPicker.css';

export const wrapColorPicker=styled.div`
    background:#fbfbfb ;
    padding: 40px 0 0 0;
    height: calc(100% - 189px);
    border-bottom: 1px solid #EEE;
    ${text}{
        margin-inline-start: 40px;
    }
`;
export const bottomPickerPop=styled.div`
   height: 79px;
   ${dFlexCenter}
`;
