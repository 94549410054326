import createReducer from '../createReducer';
let account = JSON.parse(localStorage.getItem('account'));
// account ? account : 

account = {
    pid: 0,
    title: null,
    bankVerifiedSent: null,
    bankVerified: 0,
    packageVerified: null,
    userAccountRole: null,
    hasMoneyTransaction: null,
    mailingJson: null,
    countEvents: null,
    permissions: [],
    userPermissions: [],
    termsVerified:null
};

let localAccount = {
    langloc: null,
    defaultTimeZone: null,
    type: null,
    billPackage: {},
    endDate: null,
    freeTickets: null,
    mailingPackage: [],
    packageType: null,
    startDate: null,
    supplier: null,
    packagesProducer: null,
    packagesBankDetails: null,
    bank: {},
    budgetItems:[],
    owner:null,
    updated:null,
    createEvent: null,
    packageSuppliers:[],
    packageEvents:[],
    ownerEmail:null,
    ownerEmailVerified:0,
    securityBankToken:null,

};

let xhr = {
    updatePackageProducerXHR: null,
    updateAccountPermissionXHR: null,
    getAccountXHR: null,
    getAccountSettingsXHR: null,
    getMailingCountXHR: null,
    updateAccountXHR: null,
    sendPackageVerificationXHR: null,
    getPackageBankXHR: null,
    updatePackageBankXHR: null,
    updatePackageVerificationXHR: null,
    addPackageBankXHR: null,
    addBudgetItemXHR:null,
    updateBudgetItemXHR:null,
    createEventXHR:null,
    getAccountSuppliersXHR:null,
    deleteAccountSupplierXHR:null,
    updateAccountSuppliersXHR:null,
    addAccountSuppliersXHR:null,
    getSuppliersInfoXHR:null,
    updateAccountBillXHR:null,
    createEasyCourseXHR:null,
    updateVerifiedEmailXHR:null,
    getAccountUserPermissionsXHR:null,
};

export const accountInitialState = {
    ...account,
    ...localAccount,
    ...xhr
};

const reducer = createReducer('account', accountInitialState);

export default reducer;

