import * as css from './SettingsPopupWrapper.css';
import Header from '../header/Header';

function SettingsPopupWrapper({showClass, close,children}) {
   
    return (
        <css.Popup className={showClass} >
            <Header close={close} />
            <css.accordions>
                {children}
            </css.accordions>
        </css.Popup>
    );
}

export default SettingsPopupWrapper;
