import styled from 'styled-components/macro';
import {arrowDark,simpleArrow,ex} from '../../assets/symbols/symbols/Symbols.css';
import {Scrollbar} from '../scrollbar/Scrollbar.css';
import {BGImageCenter, mobileMedia, popupScreen} from '../../globalVariables/GlobalVariables';
import zIndex from '../../globalVariables/zIndex';

export const LINE_HEIGH=60;

export const blur  =  styled.div`
    display: none;
    &:not(.desktopBehavior) {
        ${mobileMedia} {
            display: block;
            ${popupScreen};
            z-index: ${zIndex.dropDown};
            transition: all  300ms;
        }
    }
`;
export const List  =  styled.div`
    z-index: ${zIndex.dropDown};
    position: absolute;
    ${(p)  =>  p.isFixed  &&  'position:fixed'};
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px  13px  24px  #00000029;
    overflow: hidden;
    transition: all  300ms;
    ${(p) => 'inset-inline-end:' + p.left};
    ${(p) => (p.right ? 'inset-inline-start:' + p.right : '')};
    ${(p) => p.top  &&  'top:' + p.top + 'px'};
    ${(p) => p.bottom  &&  'bottom:' + p.bottom + 'px'};
    height: 0;
    width: ${(p)  =>  p.width};
    &.current,
    &.notCurrent {
        height: ${(p)  =>  p.height + 'px'};
    }
    &.show {
        height:${p => p.height}px;
    }
    &.return.current {
        width: ${(p)  =>  p.width};
        inset-inline-start: 0;
    }
    &.return.notCurrent {
        width: 0;
        inset-inline-end: 0;
    }
    &.current:not(.return) {
        width: ${(p)  =>  p.width};
    }
    &.notCurrent:not(.return) {
        width: 0px;
        inset-inline-start: 0;
        inset-inline-end: unset;
    }
    &.arrow {
        overflow: unset;
        ::after {
            content: "";
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: #fff;
            position: absolute;
            ${(p)  =>  p.top  &&  'top:' + '-5px'};
            ${(p)  =>  p.bottom  &&  'bottom:' + '-5px'};
            right: calc(50%  -  5px);
        }
    }
    //small
    &:not(.desktopBehavior) {
        ${mobileMedia} {
            width: 100%;
            position: fixed;
            left: 0;
            top: 100%;
            right: unset;

            &.return.current {
                width: 100%;
                left: unset;
            }
            &.current:not(.return) {
                width: 100%;
            }
            &.show {
                width: 100%;
                height: 70%;
                top: 30%;
            }
        }
    }
`;

export const wrap  =  styled.div`
    &:not(.desktopBehavior) {
        ${mobileMedia} {
            height: calc(100%  -  60px);
        }
    }
`;
export const Items  =  styled(Scrollbar)`
    overflow-y: scroll;
    height: ${(p)  =>  p.height + 'px'};
`;

export const headerArrow  =  styled.div`
    width: 40px;
    height: 40px;
    background-image: url("${arrowDark}");
    transform: rotate(${(p)  =>  (p.theme.rtl  ?  '0deg' : '180deg')});
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    background-position: center  center;
    background-size: 12px;
`;
export const ArrowWrap  =  styled.div`
    width: 40px;
    height: 40px;
`;

export const ListLi  =  styled.div`
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const ListTitle  =  styled(ListLi)`
    font-weight: 600;
    border-bottom: 1.4px  solid  #76d6e8;
    color: #76d6e8;
    height: ${LINE_HEIGH}px;
`;

export const ScrollBottom  =  styled(ListLi)`
    border-bottom: 1px  solid  #eee;
    cursor: pointer;
    height: 20px;
    background-image: url("${simpleArrow}");
    background-repeat: no-repeat;
    background-position: center  center;
    background-size: 8px;
    padding-bottom: 8px;
    &.disable {
        filter: brightness(2.5);
        border-bottom: 1px  solid  #555;
    }
`;

export const close  =  styled.div`
    display: none;
    &:not(.desktopBehavior) {
        ${mobileMedia} {
            display: flex;
            height: 60px;
            width: 100%;
            ${BGImageCenter(ex,  '17px')}
            border-top: 1px  solid  #EEE;
        }
    }
`;

export const searchWrapper = styled.div`
    border-bottom: 1px solid #ccc;
    direction: rtl;
`;