import * as css from './Animation.css';
import lottie from 'lottie-web';
import React, {useEffect, useRef} from 'react';

const Animation=React.memo(function Animation({animation,isLoop=true,cssStyle}) {
    const ref = useRef(null);

    useEffect(() => {
        const instance=  lottie.loadAnimation({
            
            container: ref.current,
            // renderer: "svg",
            // autoplay: true,
            loop: isLoop,
            animationData: animation,
        });
        return () => instance.destroy();
    }, []);

    return (
        <css.animate className='animation' cssStyle={cssStyle} data-cy='animation'>
            <div ref={ref} />
        </css.animate>
    );
});

export default Animation;
