import styled from 'styled-components/macro';
import {btn} from '../../../component/button/btn/Btn.css';
import Close from '../../../component/close/Close';

export const status = styled.div`
    height:100%;
    position: relative;
    ${btn}{
        color: #474747; 
    }

/* & .active, .notActive{

} */
& .yellow{
    background-color:transparent;
    &.active{
    background-color:transparent;
    border:1.5px solid #AED11C;
    :hover{
        ::after{
        box-shadow: 0px 0px 7px 2px #AED11C;
            }
        }
    }
    &.notActive{      
        border:2px solid #404040;
    }
}

`;
