import styled from 'styled-components/macro';
import {DEFAULT_COLOR, dFlexCenter,mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {inputs} from '../../../../component/input/dateInput/DateInput.css';
import {ItemContent, ItemText} from '../../../../component/item/Item.css';
import {input} from '../../../../component/input/inputList/InputList.css';
import {INPUT_PADDING_START} from '../../../../component/classes/input';

export const wrapper=styled.div`
      ${ItemContent}{
        display: none;
        
      }
      ${ItemText}{
        width: 100%;
        font-size: 12px !important;
          overflow: visible;
      }
      ${input}{
        height: 40px;
      }
    padding: 15px 0;
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    grid-template-rows: repeat(5, auto);
    justify-items: center;
    width: 100%;
    ${mobileMedia}{
      grid-template-columns: 1fr;
    }
    >div:nth-child(2n + 1){
      ${inputs}{
        position: relative;
        ::after{
          content: '';
          bottom: 0;
          height: 1px;
          width: 55px;
          background-color: transparent;
          border-bottom: 1px solid;
          inset-inline-end: -55px;
          position: absolute;
          }
        }
        ${mobileMedia}{
          ${inputs}{
        ::after{
           display:none ;
          }
        }
        }
    }
    ${mobileMedia}{
      ${inputs}{
        ::after{
          display:none;
        }
    }
  }
    `;
    
export const wrapMode=styled.div`
  margin-top:10px ;
  ${mobileMedia}{
    margin: 0 0 15px 0;
    ${dFlexCenter}
  }
`;
    
export const timeZoneWrap=styled.div`
  width:100%;
  padding-inline-start:${INPUT_PADDING_START}px;
  grid-column: 1 / -1;
`;
export const timeZoneAndLang=styled.div`
  ${dFlexCenter}
  position:  relative;
  img{
    position: absolute;
    inset-inline-end: 4px;
  }
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  padding-inline-end: 15px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  border: 1px solid;
  transition: all 500ms;
  &.open{
    background-color: ${DEFAULT_COLOR};
  }
  &.ready{
    cursor: unset;
  }
  ${mobileMedia}{
    width: 100%;
  }
`;
