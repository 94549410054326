let num = 0;

export const verifyBankDetails = (bank, branch, accountNumber) => {
    accountNumber=accountNumber?.toString();
    branch=branch?.toString();
    bank=bank?.toString();

    switch (bank) {
        case '10':
        case '13':
        case '34':
            accountNumber = oInFirst(accountNumber, 8);
            num = dupNum(branch, accountNumber, [7, 6, 5, 4, 3, 2], [10, 9, 8]);
            num += parseInt(accountNumber.substring(6));
            num = num.toString();
            num = parseInt(num.substring(num.length - 2));

            if([90, 72, 70, 60, 20].includes(num) != -false)
                return true;
            break;
        case '12':
            accountNumber=oInFirst(accountNumber, 6);
            if(accountNumber.length != 6)
                return false;
            if(verifyMod(branch, accountNumber, 11, [0, 2, 4, 6], true, 6))
                return true;
            break;
        case '4':
            if(accountNumber.length != 6)
                return false;
            if(verifyMod(branch, accountNumber, 11, [0, 2], true, 6))
                return true;
            break;
        case '20':
            if(accountNumber.length != 6)
                return false;
            if(branch > 400)
                branch = (branch - 400).toString();
            if(verifyMod(branch, accountNumber, 11, [0, 2, 4], true, 6))
                return true;
            break;
        case '11':
        case '17':
            if(verifyMod(branch, accountNumber, 11, [0, 2, 4]))
                return true;
            break;
        case '31':
        case '52':
            if(verifyMod(branch, accountNumber, 11, [0, 6], false, false, true))
                return true;
            break;
        case '9':
            num = dupNum(branch, accountNumber, [1, 2, 3, 4, 5, 6, 7, 8, 9]);
            if((num % 10) == 0)
                return true;
            break;
        case '22':
            if(accountNumber.length != 9)
                return false;
            num = dupNum(branch, accountNumber, [3, 2, 7, 6, 5, 4, 3, 2]);
            if((11 - (num % 11)) == accountNumber.substr(accountNumber.length - 1))
                return true;
            break;
        case '46':{
            let arrTrue = ([192, 191, 183, 181, 178, 166, 154, 539, 527, 516, 515, 507, 505, 503].includes(parseInt(branch)) != false) ? [0, 2] : [0];
            if(verifyMod(branch, accountNumber, 11, arrTrue, true))
                return true;
            if(verifyMod(branch, accountNumber, 11, [0], false, false, true))
                return true;
            break;}
        case '14':{
            let arrT = ([385, 384, 365, 347].includes(parseInt(branch))) != false ? [0, 2] : ([361, 362, 363].includes(parseInt(branch))) != false ? [0, 2, 4] : [0];
            if(verifyMod(branch, accountNumber, 11, arrT, true))
                return true;
            if(verifyMod(branch, accountNumber, 11, [0], false, false, true))
                return true;
            break;}
        case '18':{
            if(branch.length>3||accountNumber.length>9)
                return false;
            const lastTwoDigits = Number(accountNumber.slice(-2));          
            const firstDigits=`${accountNumber.slice(0, -2)}`.padStart(7,0);
            const fullNumber=Number(`${branch}${firstDigits}`);
            const  remainder= fullNumber%97;
            const result=98-remainder;
            if(lastTwoDigits===result)                 
                return true;
            break;}
        case '54':
        case '26':
            return true;
    }
    return false;
};
const verifyMod = (branch, accountNumber, modNum, arrayTrue, isbranch, check_num, test2) => {
    let num=null;
    if(isbranch)
        num = dupNum(branch, accountNumber, [6, 5, 4, 3, 2, 1], [9, 8, 7]);
    else
        num = dupNum(branch, accountNumber, [9, 8, 7, 6, 5, 4, 3, 2, 1]);
    if(arrayTrue.includes((num % modNum)) != false)
        return true;
    if(test2) {
        branch = branch.substr(3);
        num = dupNum(branch, accountNumber, [6, 5, 4, 3, 2, 1]);
        if(arrayTrue.includes((num % modNum)) != false)
            return true;
    }

};
const dupNum = (branch, accountNumber, dupAccount, dupBranch) => {
    num = 0;
    branch = oInFirst(branch, 3);
    accountNumber = oInFirst(accountNumber, dupAccount.length);
    if(dupBranch)
        for(var i = 0; i < 3; i++)
            num += parseInt(branch[i]) * dupBranch[i];
    for(i = 0; i < dupAccount.length; i++)
        num += parseInt(accountNumber[i]) * dupAccount[i];

    return num;
};
const oInFirst = (string, numO) => {//add 0 to branch and accountNumber
    for(var i = (string).toString().length; i < numO; i++)
        string = '0' + string;
    return string;
};

