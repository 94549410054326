import styled from 'styled-components/macro';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
import {arrowBtn} from '../../assets/symbols/symbols/Symbols.css';

export const filter = styled.div`
     border-radius: 20px;
     position: relative;
     padding: 3px 15px;
     padding-inline-end: 25px;
     cursor: pointer;
     :hover{
        border: 1px solid;
     }
   ::before{
     content:'';
     ${BGImageCenter(arrowBtn,'6px')};
      position:absolute;
      background-size: 8px 8px;
      background-repeat: no-repeat;
      background-position: center center;
      width: 8px;
      height: 8px;
      inset-inline-end: 7px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(-90deg);
      top: 9px;
   }
 
`;