import styled from 'styled-components/macro';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';

export const inputEdit=styled.div`
    width:${props=>props.width};
    height:${props=>props.height};
    display:flex;

`;
export const inputEditIcon=styled.div`
    width:${props=>props.width}; 
    background-repeat: no-repeat;
    background-position: center center;
    background-image:url("${props=>symbol[props.icon]}"); 
`;
export const inputEditInput=styled.div`  
    padding: 0 10px;
    border-${props=>props.theme.rtl?'right':'left'}: 1px solid;

`;

export const inputEditInputInput=styled.input`
    border: 0;
    outline: unset;
    font-size: 16px;
    padding: 3px;
    width: max-content;
    background-color: transparent;
    display:block;
    :hover{
        border-bottom: 1px solid;
    }
    :focus{
        border-bottom: 1px solid;
    }
`;