import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as css from './weather.css';
import {getHourMinutes, tsGetDayOfWeek} from '../../../../../library/dates';
import {getWeather} from '../../../../../redux/realtime/realtimeActions';
import {useTranslation} from 'react-i18next';
// import {WEATHER_ICONS} from '../../../../../config/config';
import * as weatherSymbols from '../../../../../assets/symbols/symbols/weather';
export type TWeatherData = {
    sunset: string;
    windSpeed: number;
    temperature: number;
    conditions: string;
    icon: string;
}
const icons=
    {'partly-cloudy-day':'partlyCloudyDay',
        'clear-night':'clearDay',
        'clear-day':'clearDay',
        'sunny':'clearDay',
        'cloudy':'cloudy',
        'rain':'rain'
    };
function Weather(){
    const weather: any = useSelector((s: any) => s.realtime.event.weather);
    const event = useSelector((s:any) => s.event);
    const {t: globalT} = useTranslation('global');
    const {t} = useTranslation('realtime');


    const day =  `${globalT('day')} ${globalT(`fullDay${tsGetDayOfWeek(event?.timeStart)}`)}`;
    const sunset = weather?.sunset?.slice(0, 5);
    
    return (
        weather?
            <css.wrapper>
                <css.title>
                    <span>{t('weatherTitle')}</span>
                    <css.locationText>
                        <css.locationIcon /> 
                        <p>{event?.location}</p>
                    </css.locationText>
                </css.title>
                <css.weather>
                    <css.weatherIcon
                        src={weatherSymbols[icons[weather?.icon]]}
                        alt="weather icon"
                    />
                    <div>{weather?.conditions}, {day}</div>
                </css.weather>
                <css.temperature>{Math.round(weather?.temperature)}°</css.temperature>
                <css.weatherDetails>                    
                    <css.weatherDetail><css.clockIcon />{getHourMinutes(event?.timeOpen)}</css.weatherDetail>
                    <css.weatherDetail> <css.windIcon />{weather?.windSpeed} {t('kmH')}</css.weatherDetail>
                    <css.weatherDetail><css.sunsetIcon /> {t('sunset')} {sunset}</css.weatherDetail>
                </css.weatherDetails>
            </css.wrapper>
            :<></>
    );
}

export default Weather;
