import styled from 'styled-components/macro';
import {remove, attachment} from '../../assets/symbols/symbols/Symbols.css';
import {ellipsis} from '../../globalVariables/GlobalVariables';
import * as input from '../classes/input';

export const fileContainer = styled(input.container)`
overflow: hidden;
`;
export const fileUInput = styled.div`
    display:flex;
    height:25px;
    position:relative;
    width:100%;
    cursor: pointer;
`;
export const fiIcon = styled(input.icon)`
    background-color: #76d6e8;
    border-radius: 14px;
`;
export const fileUpload = styled.div`
    width: 100%;
    border: 1px dashed;
    display: flex;
    margin-${props => props.theme.rtl ? 'right' :'left'}: 6px;
    ::after{
            content:"${props => props.desc}";
            position: absolute;
            ${props => props.theme.rtl ? 'right' : 'left'}:37px;
            height: 20px;
            bottom: -19px;
            font-size: 11px;
            letter-spacing: 1px; 
            font-weight: bold;
        }
`;

export const garbage = styled.div`
    background-repeat:no-repeat;  
    background-image: url("${remove}");
    background-size: contain;
    width: 15px;
    height: 100%;
    background-position: center;
    position: absolute;
    ${props => props.theme.rtl ? 'left' :'right'}: 6px;
`;
export const title = styled.div`
    line-height: 23px;
`;
export const upload = styled.div`
    width: calc(100% - 46px);
    ${ellipsis}
    line-height: 23px;
    .title{
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
    }
`;
export const attach = styled.div`
    background-repeat:no-repeat;  
    background-image: url("${attachment}");
    background-size: contain;
    width: 18px;
    height: 100%;
    background-position: center;
`;

export const download = styled.div`
    position: relative;
    ${props => props.theme.rtl ? 'left' :'right'}: 40px;
    line-height: 23px;
    font-weight: 800;
    font-size: 12px;
`;