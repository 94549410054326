import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const maxMB=10;

export const maxSize = maxMB * 1024 * 1024;
export const  validator= file => (file.size > maxSize) ? `File size exceeds the limit of ${maxMB} MB.`:null;
export const fileTypes={
    image:'image/png, image/jpeg, image/svg+xml',
    PDF:'application/pdf'
};

export const useRejectedFiles=()=>{
    const [rejectedMessage,setRejectedMessage]=useState('');
    const {t} = useTranslation('newEvent');

    const checkRejectedOnUpload=(rejectedFiles)=>{
        if(rejectedMessage)
            setRejectedMessage('');
        if(rejectedFiles?.length > 0) {
            const message=rejectedFiles[0]?.errors[0]?.code=='file-invalid-type'?t('fileNotSupportedError'):t('bigFileError',{size:maxMB});
            setRejectedMessage(message);
        }
    };
    return {rejectedMessage,checkRejectedOnUpload};
};
