import * as css from './Title.css';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Button from '../../../../component/button/button/Button';
import InputSearch from '../../../../component/inputSearch/InputSearch';
import Close from '../../../../component/close/Close';
import {useTranslation} from 'react-i18next';
import List from '../../../../component/list/List';
import useShow from '../../../../hooks/useShow';
import {packageList} from '../../../../layout/events/packageManagement/PackageManagement';
import {useHistory} from 'react-router-dom';
import {packageListClick} from '../../../../layout/events/packageManagement/PackageManagement';
import EventRolePermission from '../../../components/rolePermission/EventRolePermission';

function Title({close, popupName, formRef,isShowOptions=true}) {
    const history = useHistory();
    const account = useSelector(state => state.account);
    const putXHR = useSelector(state => state.ui.putXHR);
    const {t} = useTranslation('layout');
    const packageT = useTranslation('package');
    const [isWait, setIsWait] = useState(false);
    const list = packageList.filter((item) => item.text !== popupName);
    const listOC = useShow();
    let timer = null;

    useEffect(() => {
        clearTimeout(timer);
        setIsWait(putXHR);
        timer = setTimeout(() => {
            setIsWait(null);
        }, 3000);
    }, [putXHR]);
    useEffect(() => () => clearTimeout(timer), []);

    const handleSubmit = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const btnProps = {
        text: t('save'),
        width: 60,
        onClick: handleSubmit,
        class: isWait,
    };

    return (<>
        <css.Header  >
            <css.Right>
                <css.Close ><Close size="13px" onClick={close}/></css.Close>
                <css.TitleAccount>
                    <css.Title>{packageT.t('packageSettings')} &#8226;</css.Title>
                    <css.PackageName>{account.title}</css.PackageName>
                </css.TitleAccount>
            </css.Right>
            {isShowOptions&& <css.Left>
                <css.search><InputSearch /></css.search>
                <EventRolePermission rolePermission="manage package">
                    <Button BtnProps={btnProps} />
                </EventRolePermission>
                <css.menuDiv onClick={listOC.isOpen ? listOC.close : listOC.open} >
                    {listOC.isOpen && <List close={listOC.close} title={t('packageSettings')} showClass={listOC.showClass}
                        list={list} top="40" left="10px" onClick={(item) => packageListClick(item, history, listOC.close)} t={t} />}
                </css.menuDiv>
            </css.Left>}
        </css.Header>

    </>
    );
}

export default Title;
