import * as css from './AttendancePage.css';
import PageWrapper from '../../components/pageWrapper/PageWrapper';
import {useTranslation} from 'react-i18next';
import Details from '../../eventDetails/details/Details';
import Meeting from '../meeting/Meeting';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getEventTickets} from '../../../redux/tickets/ticketsActions';
import AttendanceFilter from './attendanceFilter/AttendanceFilter';
import AttendanceFrame from './attendanceFrame/AttendanceFrame';
import useTablet from '../../../hooks/useTablet';

function AttendancePage() {
    const getEventTicketsXHR=useSelector(s=>s.tickets.getEventTicketsXHR);
    const tickets=useSelector(s=>s.tickets.tickets||[]);

    const layoutT=useTranslation('layout');
    const isTablet = useTablet();
    const [courseFilter,setCourseFilter ]=useState(0);
    const [guidesFilter,setGuidesFilter ]=useState(null);

    useEffect(()=>{
        if(!getEventTicketsXHR)
            getEventTickets().then();
    },[]);

    const getTicketByFilter=(tickets)=>{
        if(courseFilter)
            tickets= tickets.filter(t=>t.tid===courseFilter);
        if(guidesFilter)
            tickets= tickets.filter(t=>t.users.some(u=>u.uid===guidesFilter));
        return tickets;
    };
    return (<>
        <css.container>
            <PageWrapper image=""
                operations={[]}
                SubMenuChildren={Details}
                leftTitle={layoutT.t('courseDetails')}
            >
                <>
                    <css.main>
                        <AttendanceFrame />
                        <css.leftContainer>
                            <AttendanceFilter courseFilter ={{courseFilter ,setCourseFilter}}  guidesFilter ={{guidesFilter ,setGuidesFilter}} />
                            <css.grid>
                                {getTicketByFilter(tickets).map((ticket,index)=>
                                    <Meeting key={index}  tid={ticket.tid} />)}
                            </css.grid>
                        </css.leftContainer>
                    </css.main>
                </>
                {!isTablet&&<Details />}
            </PageWrapper>
        </css.container>
    </>
    );
}

export default AttendancePage;
