import t from '../../TranslateHe';

const mailingTable = {
    header: {
        search: {
            active: true,
            specialSearch: {
                active: false
            }
        },
        tableAction: {
            active: true,
            option: [
            ]
        },
        tableSort: {
            active: true,
            field: 'date',
            type: 'date'
        },
        switcherButton: {
            active: true,
            options : [
                {image: 'email', text: t('email'), type: 'email'},
                {image: 'sms', text: t('sms'), type: 'sms'}
            ]
        }
    },
    content: {
        regular: [
            {width: '1', type: 'checkbox', border: true},
            {width: '50', type: 'icon', imageFiled:'messageType', above: 'scheduled', open: true},
            {type: 'textBold', field: 'subject', secondRow:'body', mini: true, open: true},
            {width: '50', title: 'event', type: 'text', field: 'eid'},
            {width: '180', title: 'date', type: 'date', field: 'sendingTime'},
            {width: '70', title: 'date', type: 'date-interval', field: 'scheduled'},
            {width: '30', title: 'date', type: 'icon', field: 'scheduledChange'},
            {title: 'date', width: '40', type: 'iconV', field: 'sendingStatus', hover:'mailingStatusHover'},
            {title: 'date', width: '70', type: 'text', field: 'sendingAmount'},
            {width: '120', type: 'status', field: 'status', mini:  true},
            {width: '50', type: 'icon', image:'star11111'},
            {width: '50', type: 'icon', image:'plane111111'},
            {width: '40', type: 'icon', image:'settings', hover:'mailingSettingHover'},
        ],
        small: [
            {width: '50', type: 'icon', imageFiled:'messageType', above: 'scheduled'},
            {type: 'textBold', field: 'name'},
            {title: 'title', type: 'text', field: 'recipient'},
            {title: 'date', width: '130', type: 'date', field: 'sendingTime'},
            {title: 'date', width: '40', type: 'iconV', field: 'sendingStatus', hover:'mailingStatusHover'},
            {width: '90', type: 'status', field: 'status'},
            {width: '40', type: 'icon', image:'settings'},
        ]

    }
};

export default function (mailingType, tableType) {
    let table = {type: mailingType};
    switch (tableType) {
        case 'regular':
            table.header = mailingTable.header;
            break;
    }
    table.content = mailingTable.content[tableType];
    return table;
}

