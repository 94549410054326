import styled from 'styled-components/macro';
import {BGImageCenter, BLACK, OLIVE_GREEN, scrollbar} from '../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../fonts';
import {vi} from '../../../../../assets/symbols/symbols/Symbols.css';

export const container = styled.div`
    height: 100%;
    min-height: 300px;
    ${p=>p.image&&BGImageCenter(p.image, 'contain')}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 30px;
    &.no-title {   
        display: block;
        a{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }

    }
`;

export const title = styled.div`
    ${realtimeFonts.windowTitle}
    text-align: center;
    color: ${BLACK}
`;
export const message = styled.div`
    ${realtimeFonts.text};
    white-space: pre-wrap;
    color: ${BLACK};
    max-height: 100%;
    ${scrollbar}
`;
export const buttons = styled.div`
    display: flex;
    align-self: self-end;
    gap: 5px;
    width: fit-content;
`;
export const approved = styled.span`
    background: ${OLIVE_GREEN};
    border-radius:50%;
    ${BGImageCenter(vi,'10px 10px')};
    width:20px;
    height: 20px;
    margin-inline-start: -10px;
    margin-inline-end: 5px;
    
`;