import {useField} from 'formik';
import {registerLocale} from 'react-datepicker';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getTimeArray, setHours, timeToDateTs, tsToDate} from '../../library/dates';
import useMobile from '../../hooks/useMobile';
import {ThemeProvider} from 'styled-components';
import he from 'date-fns/locale/he';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as css from './FormikDatePicker.css';
import {useTranslation} from 'react-i18next';
import {onDatePickerChange,onKeyDown,onFocus} from './DatePickerFunctions';
import {useRef} from 'react';

function TimePicker({name, onChange = () => {}, isTimestamp = false, readOnly = false, isFixed = false, minTime, maxTime, ...props}) {
    const [{value}, , helpers] = useField(name);
    const lang = useSelector((state) => state.user.lang);
    const ref=useRef(null);
    const {t}=useTranslation('global');

    useEffect(() => {
        if(lang === 'he') {
            registerLocale('he', he);
        }
    }, []);

    const handleDateChange = (val, e) => {  
        let time=val;
        if(isTimestamp){
            const [hours,minutes]=isMobile?e.target.value.split(':'):
                [val.getHours(),val.getMinutes()];
            time=setHours([hours,minutes],value);
        }      
        onDatePickerChange(time, helpers, e);
        onChange(time);
    };

    const isMobile = useMobile();

    const getTimestamp=()=>{
        const timeArray=getTimeArray(value);
        return  new Date().setHours(timeArray[0],timeArray[1]);
    };
        
    const getMobileTime=()=>{
        if(!value)
            return '';
        const date=isTimestamp?new Date(getTimestamp()):value;
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const DatePickerComponent = isMobile ? 'input' : DatePicker;
    const datePickerProps = isMobile
        ? {
            type: 'time',
            placeholder: '',
            value: getMobileTime(),
            min: minTime ? tsToDate(minTime, null, null, 'HH:mm') : '',
            max: maxTime ? tsToDate(maxTime, null, null, 'HH:mm') : '',
            onChange: (e) => handleDateChange(timeToDateTs(e.target.value),e),
            className: value && 'hasValue',
        }
        : {
            ...(value?{selected:isTimestamp?getTimestamp():value}:{}),
            ...((minTime||maxTime)?{
                minTime:minTime?minTime*1000:new Date().setHours(0,0,0),
                maxTime:maxTime?maxTime*1000:new Date().setHours(23,59,0)
            }:{}),
            dateFormat: 'HH:mm',
            onChange: handleDateChange,
            showTimeSelect: true,
            showTimeSelectOnly: true,
            timeCaption: t('time'),
            timeIntervals: 15,
            readOnly: readOnly,
            popperProps: isFixed && {strategy: 'fixed'},
            openToDate: minTime ? new Date(minTime * 1000) : new Date().setHours(8, 0, 0),
            inputMode: 'time',
            popperModifiers:[ {name: 'arrow', options: {padding: 24}}],
            onFocus:()=>onFocus(ref,value,'time',props),
            onKeyDown:e=>onKeyDown(e,ref,'time',props)
        };

    return (
        <ThemeProvider theme={{type: 'time'}}>
            <css.container ref={ref} data-cy={`${name ? name : 'default'}DatePicker`}>
                <DatePickerComponent
                    locale="he"
                    className="noBorder"
                    placeholderText="HH:mm"
                    {...props}
                    {...datePickerProps}
                />
            </css.container>
        </ThemeProvider>
    );
}

export default TimePicker;
