import * as css from './Navigation.css';

function Navigation({currentIndex,onClick}) {

    return (
        <css.nav>
            {[0,1,2].map(i=><css.circle key={i} className={currentIndex===i&&'current'} onClick={()=>onClick(i)}/>)}
        </css.nav>

    );
}

export default Navigation;
