import styled from 'styled-components/macro';
import {BGImageCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {defaultBuild} from '../../../../assets/symbols/images/build';

export const defaultLeft=styled.div`
  display: flex;
  flex-direction: column;   
  height: calc(100% - 200px);
  ${mobileMedia}{
    min-height: 200px;
  }
`;
export const defaultText=styled.div`
   white-space: pre-line;
    text-align: center;
    font-size: 14px;
    font-weight: 800;
    -webkit-box-flex: 0;
    flex: 0 1 50%;
    max-height: 250px;
    display: flex;
    -webkit-box-align: end;
    align-items: end;
    -webkit-box-pack: center;
    justify-content: center;
    padding-bottom: 3vh;
    line-height: 14px;
`;
export const defaultImage=styled.div`
    ${BGImageCenter(defaultBuild,'contain')};
    background-position: top center;
    width: 80%;
    margin: 0 auto;
    flex-basis: 50%;
    flex-grow:1;
    flex-shrink: 0;
    
`;
