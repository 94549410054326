import * as css from './AnotherOne.css';
import BtnPlus from '../button/btnPlus/BtnPlus';

function AnotherOne({title, onClick,dataCyName}) {

    return (
        <css.wrapper onClick={onClick}>
            <css.addGroup>
                {/*<css.addBtn/>*/}
                <BtnPlus onClick={onClick} bsize={30} dataCyName={dataCyName}/>
                <css.title>{title}</css.title>
            </css.addGroup>
        </css.wrapper>
    );
}
export default AnotherOne;