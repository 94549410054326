import * as css from './CircleClose.css';
import Close from '../close/Close';

function CircleClose({close , styleCss,isDark=false ,closeType}) {
    return (
        <css.topClose onClick={close} styleCss={styleCss} data-cy={closeType}>
            <Close onClick={close} {...(isDark?{}:{className:' white'})}/>
        </css.topClose>
    );
}

export default CircleClose;
