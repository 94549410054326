import * as css from '../paymentForm/PaymentForm.css';
import {useTranslation} from 'react-i18next';
import SettingItem from '../settingItem/SettingItem';
import {useSelector} from 'react-redux';
import {Formik, Form} from 'formik';
import {subSettingInputs} from '../paymentForm/PaymentForm';
import SubSettingItem from '../subSettingItem/SubSettingItem';
import DataItems from '../../../component/dataItems/DataItems';
import {updateEventSettingsList} from '../../../redux/event/eventActions';
import * as Yup from 'yup';

function SaleForm() {
    const {t} = useTranslation('event');
    const globalT = useTranslation('global').t;
    const blockOrder = useSelector(state => state.event.settings?.blockOrder);
    const settingsXHR = useSelector(state => state.forms.eventSettings);
    const {getList, getToggle, getInput,getAmountInput} = subSettingInputs(blockOrder, 'blockOrder', settingsXHR, t);

    const limitList = [{key: 'orders', text: 'orders'}, {key: 'tickets', text: 'tickets'}];

    const initialValues = {
        limit: blockOrder?.limit,
        countLimit: blockOrder?.countLimit || 1,
        fids: blockOrder?.fids,
        fields: blockOrder?.fields,
        // crowd: blockOrder?.crowd,
        blockOrdersSms: blockOrder?.blockOrdersSms,
        blockOrdersSmsText: blockOrder?.blockOrdersSmsText || t('DefaultSmsText'),
    };
    const getCustomFieldsList = () => blockOrder?.fieldCustom?.map(item => {
        const selected = blockOrder?.fids?.some(i => i === item.fid);
        return {...item, key: item.fid, text: item.title, selected};
    });

    const getFields = () => {
        const fields = [{key: 'email', text: t('email')}, {key: 'phone', text: t('phone')}];
        let fieldsList = fields.map(item => {
            const selected = blockOrder?.fields?.includes(item.key);
            return {...item, selected};
        });
        let customFieldsList = getCustomFieldsList()||[];
        return fieldsList.concat(customFieldsList)||[];
    };

    const onChange = (item, type) => {
        let serverValue;
        let name = ['phone', 'email'].includes(item.key) ? 'fields' : 'fids';
        const arr=blockOrder[name]||[];
        if(type === 'add')
            serverValue = [...arr, item.key];
        else
            serverValue = arr.filter(i => i !== item.key);
        updateEventSettingsList(name, 'blockOrder', serverValue, serverValue);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({countLimit: Yup.number().min(1)})}
            enableReinitialize={true}
            render={({values})=>
                <Form>
                    <SettingItem settingCategory={'blockOrder'} name={'limit'} >
                        {getList('limit', limitList)}
                        {getAmountInput('countLimit',t(`${values.limit}Num`),globalT('pcs'))}
                        <css.blockOrdersSmsText>
                            <SubSettingItem text={t('customerIdentification')}>
                                <DataItems list={getFields()} listText={t('fields')} icon="person" onChange={onChange} />
                            </SubSettingItem>
                        </css.blockOrdersSmsText>
                        {/* {getToggle('crowd')} */}
                        {getToggle('blockOrdersSms')}
                        <css.blockOrdersSmsText>
                            {getInput('blockOrdersSmsText')}
                        </css.blockOrdersSmsText>
                    </SettingItem>
                    <css.place />
                </Form>
            }
        />);
}

export default SaleForm;
