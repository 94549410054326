import * as css from './BtnAddEvent.css';
import BtnPlus from '../btnPlus/BtnPlus';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import useTimeout from '../../../hooks/useTimeout';
import AccountRolePermission from '../../../pages/components/rolePermission/AccountRolePermission';

function BtnAddEvent({onClick,state}) {

    const [isText, setIsText] = useState(false);
    const timer = useTimeout();

    useEffect(() => {
        timer.current=setTimeout(() => {
            setIsText(true);
        }, 2000);
    }, []);

    // const  animation = ()=>{
    // timer.current = setTimeout(() => {
    //
    //         //if((!e || e === undefined)&&!isOpen)
    //         //  setIsText(false);
    //     }, 4000);
    //
    // };

    const {t} = useTranslation('layout');
    return (
        <AccountRolePermission rolePermission={'manage package'} views={[]}>
            <css.btn onClick={onClick} onMouseEnter={()=>setIsText(true)}  onMouseLeave={()=>setIsText(false)} className={isText?' animation':''}>
                <BtnPlus text={t('createEvent')} hasText={false}  white={false}  state={state} onClick={onClick}/>
                {<css.text  className={(isText||state)?' big':''} >{t('createSalesPage')} </css.text>}
            </css.btn>
        </AccountRolePermission>
    );
}
export default BtnAddEvent;