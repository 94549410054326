import {useRef} from 'react';
import {useEffect, useState} from 'react';

export default function useShow(duration = 300) {
    const [isOpen, setIsOpen] = useState(false);
    const [showClass, setShowClass] = useState('');

    let timeout=useRef(null);

    const close =(e) => new Promise((resolve)=>{
        if(!isOpen)
            return;
        if(e)
            e.stopPropagation();
        setShowClass('');
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setIsOpen(false);
            resolve();
        }, duration);
    });

    const open = () => {
        setIsOpen(true);
        clearTimeout(timeout.current);
        timeout.current = setImmediate(() => {
            setShowClass(' show');
        });
    };
    useEffect(() => () => clearTimeout(timeout.current), []);

    return ({open, close, isOpen, showClass});
}

