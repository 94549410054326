import styled from 'styled-components/macro';
import {hand, longHand} from '../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../component/button/btn/Btn.css';
import {BGImageCenter, mobileMedia, tabletPlusMedia} from '../../globalVariables/GlobalVariables';
import zIndex from '../../globalVariables/zIndex';

export const SupportTitle = styled.div`
    font-size:15px;
    font-weight:800;
    color: #292828;
    padding-inline-start: 15px;
    transition: opacity 1s;
    opacity: 1;
    ${mobileMedia} {
        &.open {
            display: none;
        }
        &.isWait {
            display: none;
        }
    }
`;

export const handIcon = styled.div`
    ${BGImageCenter(hand,'25px')}
    width:30px ; 
    background-position: center top 5px;
    transition: all 0.2s;
    bottom: 0px;
    height: 77px;
    position: absolute;
    inset-inline: 10px;
    ${mobileMedia}{
        background-position: center top 32px;
        background-size: 20px;
        inset-inline: 5px;
        &.open{
        background-size: 18px;
        inset-inline-start: 7px;
    }
    }
`;

export const container = styled.div`
    position:fixed;
    bottom: 19px;
    inset-inline-end: 19px;
    cursor:pointer;
    z-index:${zIndex.baseHighest};
    transition: all 1.2s , inset-inline-end 400ms;
    ${mobileMedia}{
        ${p => p.isInlineMode && 'display: none;'}
        bottom: 10px;
    }
    ${btn}{
        box-shadow: 0px 3px 10px #00000029;
        background-color:#FFFFFFB8 ;
        border: none;
        height: 40px;
        width: 120px;
        ${mobileMedia}{
            height: 30px;
            width: 120px;
            &.open {
                width: 40px;
                height: 130px;
            }
            &.isWait{
                width: 40px;
                height: 30px;
            }
        }
        :hover{
            background-color:#FFFFFFB8 ;
            .handIcon{
                ${BGImageCenter(longHand,'27px')}
                height:90px ;
                width:30px ;
            }
        }
    }
    &.show{
        bottom: 66px ;
        ${mobileMedia}{
            bottom: 169px ;
        }
    }
    ${tabletPlusMedia}{
    &.margin{
        inset-inline-end: 372px;
    }
    }
`;

export const SupportOptionsCtr = styled.div`
    display:flex;
    justify-content: space-evenly;
    align-items:center ;
    padding-inline-start: 20px;
    ${mobileMedia}{
        display: block;
        margin-top: -40px;
        padding-inline-start: 0px;
    }
`;

export const text = styled.div`
`;

export const SupportOpBtn = styled.div`
    width: 33px;
    height: 32px;
    cursor:pointer;
    ${p=>BGImageCenter(p.icon,'25px')};
    transition: all 300ms;
    &:hover{
        background-size:28px ;
    };
    margin-top: 5px;
`;

