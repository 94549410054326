import {useEffect, useRef, useState} from 'react';
import * as css from './EventPopUp.css';
import PopUp from '../../../../component/popUp/PopUp';
import {useTranslation} from 'react-i18next';
import Stages from '../../../../component/stages/Stages';
import EventDtails from './eventDetails/EventDetails';
import {useSelector} from 'react-redux';
import FullEditImage from '../../../../component/fullEditImage/FullEditImage';
import useOpen from '../../../../hooks/useOpen';
import EventPopUpBottom from './eventPopUpBottom/EventPopUpBottom';
import {ts2time, ts2DateTime} from '../../../../library/dateFunctions_old_to_be_deprecated';
import {updateEventDetails} from '../../../../redux/preparingEvent/preparingEventActions';
import {getCategories} from '../../../../redux/categories/categoriesActions';
import setGlobalState from '../../../../redux/setGlobalState';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import {eventDuplication} from '../../../../redux/event/eventActions';
import {uploadFile} from '../../../../redux/user/userActions';

function EventPopUp(props) {
    const {t} = useTranslation('layout');
    const clusterT = useTranslation('event');
    const {closePopUp, eventPreparing, type, callBack} = props; //type= update or duplicate 
    const event = useSelector((state) => state.event);
    const [showPopUp, setShowPopUp] = useState(1);
    const [show, setShow] = useState(1);
    const [focus, setFocus] = useState(0);
    const [current, setCurrent] = useState('details');
    const [closeCategory, setCloseCategory] = useState(0);
    const [image, setImage] = useState(eventPreparing?.fbImg);
    let dateNow = new Date().toJSON().slice(0, 10);
    const [inputsDate, setInputsDate] = useState([
        {name: 'timeStart', text: t('eventBedin'), type: 'datetime-local', value: type == 'update' ? ts2DateTime(eventPreparing?.timeStart) : (dateNow + 'T00:00'), validationType: 'startDate', validation: {type: 'startDate', text: t('errorEventStart')}, notValid: 0},
        {name: 'timeOpen', text: t('eventOpenGates'), type: 'time', value: type == 'update' ? ts2time(eventPreparing?.timeOpen) : '00:00', validation: {type: 'openDate', text: t('errorEventOpen')}, notValid: 0},
        {name: 'timeEnd', text: t('eventEnd'), type: 'datetime-local', value: type == 'update' ? ts2DateTime(eventPreparing?.timeEnd) : (dateNow + 'T00:00'), validation: {type: 'endDate', text: t('errorEventEnd')}, notValid: 0}
    ]);
    const [textaeraInput, setTextareaInput] = useState({
        name: 'description',
        value: eventPreparing?.description, url: 'feather', textareaPlaceholder: t('eventDescription'), sumChars: '200', textareaHeight: '150px', validation: {type: 'text', text: t('syntaxError')}, notValid: 0
    });
    const [inputs, setInputsVal] = useState([
        {name: 'title', text: t('eventDetails'), value: type == 'duplicate' ? eventPreparing?.title + ' ' + t('dup') : eventPreparing?.title, icon: 'event', validation: {type: 'text', text: t('syntaxError')}, notValid: 0},
        {name: 'location', text: t('eventLocation'), value: eventPreparing?.location, icon: 'location', validation: {type: 'text', text: t('syntaxError')}, notValid: 0}
    ]);
    const [valCategory, setValCategory] = useState(!(eventPreparing?.category) ? 0 : eventPreparing?.category);
    const [sendBtnDisabled, setSendBtnDisabled] = useState(1);
    const duplicateEvents = useRef([]);
    const isFirstRun = useRef(true);
    const isOpen = !useOpen();
    let flag = 0;
    let imageDup = eventPreparing?.fbImg;
    // useOnClickOutside(ref, () => setClose());

    const onClickStage = (stage) => {
        switch (stage) {
            case 'details':
                setCurrent('details');
                break;
            case 'image':
                setCurrent('image');
                break;
            case 'ticket':
                setCurrent('ticket');
                break;
            default:
                break;
        }
    };

    let stages = [
        {type: 'details', oCircleColor: '#fff', iCircleColor: '#43BAE7', borderColor: '#43BAE7', stageText: t('details'), stageTextColor: '#5D5D5D', onClickStage: onClickStage, currentStage: current == 'details'},
        {type: 'image', oCircleColor: '#fff', iCircleColor: '#43BAE7', borderColor: '#43BAE7', stageText: t('image'), stageTextColor: '#5D5D5D', onClickStage: onClickStage, currentStage: current == 'image'},
        // { type: 'ticket', oCircleColor: "#fff", iCircleColor: "#43BAE7", borderColor: "#43BAE7", stageText: t('tickets'), stageTextColor: "#5D5D5D", onClickStage: onClickStage, currentStage: current == "ticket" }
    ];

    const [itemsDup, setItemDup] = useState([
        {
            key: 'tickets',
            text: t('tickets'),
            selected: true,
            type: 'checkbox',
            contentClasses: 'classicCheckbox'
        }, {
            key: 'coupons',
            text: t('coupons'),
            selected: true,
            type: 'checkbox',
            contentClasses: 'classicCheckbox'
        }, {
            key: 'seats',
            text: t('seats'),
            selected: true,
            type: 'checkbox',
            contentClasses: 'classicCheckbox'
        },  {
            key: 'pixels',
            text: t('pixels'),
            selected: false,
            type: 'checkbox',
            contentClasses: 'classicCheckbox'
        }, {
            key: 'fields',
            text: t('fields'),
            selected: false,
            type: 'checkbox',
            contentClasses: 'classicCheckbox'
        }, {
            key: 'groups',
            text: t('groups'),
            selected: false,
            type: 'checkbox',
            contentClasses: 'classicCheckbox'
        }, {
            key: 'team',
            text: t('eventTeam'),
            selected: false,
            type: 'checkbox',
            contentClasses: 'classicCheckbox'
        }
    ]);

    useEffect(async () => {
        if(eventPreparing.eid) {
            await getCategories(eventPreparing.eid);
        }
        isDisableBtnSend();
    }, []);

    useEffect(() => {
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        isDisableBtnSend();
    }, [textaeraInput, inputs, inputsDate]);

    const close = () => {
        
        setTimeout(function () {
            setShowPopUp(0);
            setTimeout(function () {
                if(closePopUp)
                    closePopUp();
            }, 150);
        }, 50);
    };

    const handleInputValid = (item, isValid, validation) => {
        setInputsVal(inputs.map((i) => {
            if(i.name == item.name)
                return {...i, notValid: isValid, validation: validation ? validation : i.validation};
            return i;
        }));
    };

    const handleInputDateValid = (name, isValid, validation) => {
        setInputsDate(inputsDate.map((i) => {
            if(i.name == name)
                return {...i, notValid: isValid, validation: validation ? validation : i.validation};
            return i;
        }));
    };

    const isDisableBtnSend = () => {
        if((!inputs[0].value) || (inputs[0].notValid) || (inputs[1].notValid) || (!textaeraInput.value) || (textaeraInput.notValid)) {
            setSendBtnDisabled(1);
            flag = 1;
            return;
        }
        inputsDate.map(ipt => {
            if(ipt.notValid) {
                setSendBtnDisabled(1);
                flag = 1;
                return;
            }
        });
        if(!flag) {
            setSendBtnDisabled(0);
        }
    };

    const isEmptyFields = () => {
        let flagError = 0;
        let validation = {type: null, text: ''};
        if(!inputs[0].value) {
            handleInputValid(inputs[0], 1, validation);
            flagError = 1;
        }
        if(!textaeraInput.value) {
            setTextareaInput(prevState => ({...prevState, notValid: 1, validation: validation}));
            flagError = 1;
        }
        if(flagError) {
            bounceMessage(t('emptyFieldsError'));
            onClickStage('details');
        }
        else {
            flagError = 0;
        }
        saveEvent(flagError);
    };

    const saveEvent = async (flagError) => {
        if(!flagError && !sendBtnDisabled) {
            let updateEvent = {};
            inputs.map(ipt => {
                updateEvent[ipt.name] = ipt.value;
            });
            inputsDate.map(ipt => {
                let date;
                if(ipt.type == 'time') {
                    if(inputsDate[0]) {
                        let m;
                        date = new Date(inputsDate[0].value);
                        m = ((date.getMonth()) + 1);
                        if(m < 10) {
                            m = '0' + m;
                        }
                        date = date.getFullYear() + '-' + m + '-' + date.getDate() + 'T' + ipt.value;
                    }
                    updateEvent[ipt.name] = (new Date(date).getTime()) / 1000;
                }
                else {
                    let dateT = (new Date(ipt.value).getTime()) / 1000;
                    updateEvent[ipt.name] = dateT;
                }
            });
            updateEvent['description'] = textaeraInput.value;
            updateEvent['fbImgBase64'] = await uploadFile(image,'','hoFbImg', event.eid);
            updateEvent['category'] = valCategory;
            itemsDup?.map((i) => {

                if(i.selected == true)
                    duplicateEvents.current.push(i.key);
                return i;
            });
            updateEvent['duplicateEvent'] = duplicateEvents.current;
            updateEvent['locationMap'] = '';
            updateEvent['lang'] = eventPreparing?.lang;
            updateEvent['clusterEid'] = event.eid;
            updateEvent['cgid'] = eventPreparing.cgid;
            if(type == 'update')
                await updateEventDetails(eventPreparing ? eventPreparing.eid : null, updateEvent, callBack);
            else {
                await eventDuplication(eventPreparing ? eventPreparing.eid : null, updateEvent, callBack);
            }
        }
    };

    const clickList = (val) => {
        setValCategory(val.target.value);
        setCloseCategory(1);
    };

    let popUpProps = [];
    popUpProps['closePop'] = () => close();
    popUpProps['imgPathClass'] = 'duplicateEventTitle';
    popUpProps['pointerEventsBody'] = 'bodySize';
    popUpProps['focus'] = focus;
    popUpProps['title'] = type == 'update' ? (clusterT.t('updateEvent')) : t('duplicateEventTitle');
    popUpProps['popUpImage'] = <Stages stages={stages}/>;
    popUpProps['popupContent'] = <>
        {current == 'details' ?
            <css.eventDtails>
                <EventDtails eid={eventPreparing.eid} inputs={inputs} setInputsVal={setInputsVal} inputsDate={inputsDate} handleInputValid={handleInputValid} handleInputDateValid={handleInputDateValid}
                    setInputsDate={setInputsDate} textaeraInput={textaeraInput} setTextareaInput={setTextareaInput} valCategory={valCategory} clickList={clickList} setFocus={setFocus} closeCategory={closeCategory} setCloseCategory={setCloseCategory}> </EventDtails>
            </css.eventDtails>
            : <FullEditImage setImage={setImage} image={image}/>}
    </>;
    popUpProps['popupBottom'] = <EventPopUpBottom type={type} eventPreparing={eventPreparing} setItemDup={setItemDup}
        itemsDup={itemsDup} isEmptyFields={isEmptyFields} current={current} onClickStage={onClickStage} sendBtnDisabled={sendBtnDisabled}  close={close} />;

    return (
        <>
            {show && <css.wrapPopUp className={(showPopUp ? (isOpen && ' showPop ') : '')}> <PopUp popUpProps={popUpProps} /></css.wrapPopUp>}
        </>
    );
}
export default EventPopUp;

