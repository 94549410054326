import {useTranslation} from 'react-i18next';
import * as css from './DayAndTime.css';
import * as cssPlus from '../coursePopupContent/CoursePopupContent.css';
import GeneralItem from '../../../../../component/generalItem/GeneralItem';
import {FieldArray, useField} from 'formik';
import {remove} from '../../../../../assets/symbols/symbols/Symbols.css';
import BasicItem from '../../../../../component/basicItem/BasicItem';
import {useSelector} from 'react-redux';
import Tooltip from '../../../../../component/tooltip/Tooltip';
import ModeBtn from '../../../../../component/modeBtn/ModeBtn';

function DayAndTime({setShowCourseDate, setItemDate}) {

    const {t} = useTranslation('ticket');

    const [courseField, , helpers] = useField('course');
    const [{value:hideScheduled},,hideScheduledHelpers] = useField('hideScheduled');
    const event = useSelector(s=>s.event);
    let course = courseField.value;

    const listOptions = [{key: 'delete', text: t('delete'), type: 'icon', icon: remove ,dataCyName: 'delete'}];

    const onClickDate = (item) => {
        setItemDate(item);
        setShowCourseDate(1);
    };

    const getDay = (day) => {
        switch (day) {
            case 1:
                return 'א';
            case 2:
                return 'ב';
            case 3:
                return 'ג';
            case 4:
                return 'ד';
            case 5:
                return 'ה';
            case 6:
                return 'ו';
            case 7:
                return 'ז';
            default:
                break;
        }
    };

    const onClickOptionsList = (item, index) => { 
        let array;
        switch (item.key) {
            case 'delete':
                array = [...course.meetings]; // make a separate copy of the array
                if(index !== -1) {
                    array.splice(index, 1);
                    helpers.setValue({...course, meetings: array});
                }
                break;
        }
    };

    return (
        <css.wrapAll>
            <css.top><css.text>{t('meetingsField')}</css.text><ModeBtn text={t('hideDataText')} status={!!hideScheduled} click={(status)=>{hideScheduledHelpers.setValue(status?1:0);}} /> </css.top>
            <css.wrapPrice>
                {!course?.meetings?.length && <BasicItem onClick={() => onClickDate(null)} icon={'clock'} name={'course.meetings'}>
                    <css.wrapHour data-cy="chooseDate">{t('chooseDate')}</css.wrapHour>
                </BasicItem>}
                <FieldArray
                    name="meetings"
                    render={() => (
                        <>
                            {course?.meetings?.map((item, index) => {
                                return <GeneralItem key={index} onClick={() => onClickDate({item: item, index: index})} number={index + 1} listOptions={{list: listOptions, onClickList: (item) => onClickOptionsList(item, index)}} dataCyName={'dayAndTime'}>
                                    <css.wrapHour data-cy="dayName"> {getDay(item.day)}</css.wrapHour>
                                    <css.wrapValue data-cy="dayAndTimeStart" > {item.start}</css.wrapValue>
                                    <css.wrapValue data-cy="dayAndTimeEnd">{item.end} </css.wrapValue>
                                    <css.iconClock/>
                                    <css.wrapHour data-cy="dayAndTimeDuration"> {item.duration + ' '}  {t('minutes')}</css.wrapHour>
                                </GeneralItem>;
                            })}
                            {!!(course?.meetings?.length && event.type === 'course') &&    <Tooltip text={t('addMeetDay')}><css.wrapPlus><cssPlus.plus onClick={() => onClickDate(null)} /></css.wrapPlus></Tooltip>}
                        </>
                    )}
                />
            </css.wrapPrice>
        </css.wrapAll>
    );
}

export default DayAndTime;