import styled from 'styled-components/macro';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';

export const btnContainer=styled.div`
  pointer-events: all;
  position:relative ;
  width: 50px;
  height:30px ;
  ::after{
    text-align: center;
    content:"${p => p.text}";
    position: absolute;
    height: 20px;
    width: 50px;
    bottom: -20px;
    right:0;
    font-size: 14px;
    pointer-events: none;
    transition: all 200ms;
  }
`;
export const btn=styled.div`
  margin-inline-start:10px;
  height:30px;
  width:30px ;
  cursor: pointer;
  transition: all 300ms;
  border-radius:50% ;
  background-color:${p => (p.color?p.color: '#76D6E8')};
 
  ${p=>BGImageCenter(p.icon,'12px')};
  :hover{
  
    transform: scale(0.9);
  }
`;