import * as css from './ListField.css';
import {useTranslation} from 'react-i18next';
import CircleBtn from '../circleBtn/CircleBtn';
import {add, vi} from '../../../../../assets/symbols/symbols/Symbols.css';
import {FieldArray, useField} from 'formik';
import {useEffect, useState} from 'react';
import EditInput from '../../../../../component/input/editInput/EditInput';
import {Else, If, Then} from 'react-if';

let arrayHelpers;

function ListField({refParent}) {
    const {t} = useTranslation('ticket');
    const [field, , helper] = useField('options.list');
    const list = field.value;
    const [type] = useField('type');
    const [editedIndex, setEditedIndex] = useState(-1);
    const onAdd = () => {
        setEditedIndex(list.length);
        arrayHelpers.push('');
    };
    const onRemove = (e, index) => {
        e.stopPropagation();
        arrayHelpers.remove(index);
        setEditedIndex(-1);
    };

    // useEffect(() => {
    //     if(type.value !== 'list')
    //         helper.setValue([]);
    // }, [type.value]);

    useEffect(() => {
        const emptyIndex = list?.indexOf('');
        if(emptyIndex !== -1 && (editedIndex !== emptyIndex))
            arrayHelpers?.remove(emptyIndex);
    }, [editedIndex]);

    if(type.value !== 'list')
        return <css.space />;

    return (
        <css.container >
            <FieldArray
                name="options.list"
                render={_arrayHelpers => {
                    arrayHelpers = _arrayHelpers;
                    return (
                        <>
                            <css.add>
                                <CircleBtn icon={add} onClick={onAdd} disabled={editedIndex > -1} dataCyName="addListItem" />
                                <span>
                                    {t`addItem`}
                                </span>
                            </css.add>
                            <css.items >
                                {field.value.map((item, index) => (
                                    <If condition={editedIndex === index} key={index} >
                                        <Then>
                                            <EditInput 
                                                name={`options.list.${editedIndex}`}
                                                refParent={refParent}
                                                close={() => setEditedIndex(-1)}
                                                onPressEnter={index === list.length - 1 ? onAdd : null}
                                                onCancel={!item ? () => {
                                                    arrayHelpers.remove(editedIndex);
                                                } : null}
                                                text={t`itemName`}
                                            />
                                        </Then>
                                        <Else>
                                            <css.item key={index} onClick={() => setEditedIndex(index)} >
                                                <CircleBtn icon={vi} onClick={(e) => onRemove(e, index)} dataCyName="removeItem"/>
                                                <css.text data-cy="newItem">{item}</css.text>
                                            </css.item>
                                        </Else>
                                    </If>
                                )
                                )}
                            </css.items>

                        </>);
                }}
            />
        </css.container >);

}
export default ListField;