import * as css from './TestSending.css';
import {useState, useEffect, useRef} from 'react';
import {connect, useSelector} from 'react-redux';
import validation from '../../detailInput/validation';
import {sendMailing} from '../../../redux/mailing/mailingActions';
import replaceParameters from '../replaceParameters';
import validateText from '../validateText';
import {useTranslation} from 'react-i18next';

function TestSending(props) {
    const {mailOrSmsText, mailOrSms, pointerEvents, subject, content, area, crowd, spansArray, crowdField}=props;
    const mailing = useSelector(s=>s.mailing);

    const refTryBtn = useRef();

    const {t} = useTranslation('mailing');

    const [{trySendingInput, tryBtnText, tryBtnClass}, setTryBtn] = useState({trySendingInput: 0, tryBtnText: t('sendTest'), tryBtnClass: 'default'});
    const [tryValue, setTryValue] = useState('');
    const [testSend, setTestSend] = useState(0);

    const changeTryInput = (e) => {
        setTryValue(e.target.value);
    };

    const validateInput = (e) => {
        const type = mailOrSms == 'email' ? 'email' : 'phone';
        if(validation(type, e.target.getAttribute('value')))
            tryInProcess();
        else
            inCorrectVal();
    };
    const setPointerEventsBody = (e)=>{
        pointerEvents();
    };

    const tryInProcess = (e) => {
        if(!validateText(content, subject,mailOrSms, t('fillSubject'), t('fillContent')))
            return;
        setTestSend(1);
        const newText = replaceParameters(content, subject, area, mailOrSms, spansArray, crowdField);
        changeTryBtn(0, t('workingOnIt'), 'open process');
        setPointerEventsBody('disabled');
        if(area=='crowd')
            sendMailing({pid:447, source: area, crid: crowd, bodyMessage: newText.newContent, subject: newText.newSubject?newText.newSubject:' ', messageType: mailOrSms, recipient:tryValue ,test:1});
            //sendMailing({ pid:props.user.package.pid, source: area, crid: crowd, bodyMessage: newText.newContent, subject: newText.newSubject, messageType: mailOrSms, recipient:tryValue ,test:1})
        else 
            sendMailing({eid:props.events.event.eid, source: area, bodyMessage: newText.newContent, subject: newText.newSubject?newText.newSubject:' ', messageType: mailOrSms, recipient:tryValue ,test:1});
    };

    const inCorrectVal = (e) => {
        changeTryBtn(0, t('oops') + mailOrSmsText + t('error'), 'open incorrect');
        setTimeout(function () { changeTryBtn(1, t('enter') +' '+ mailOrSmsText +' '+ t('forTest'), 'open'); }, 3000);
    };

    const changeTryBtn = (input, text, classname) => {
        setTryBtn({trySendingInput: input, tryBtnText: text, tryBtnClass: classname});
    };

    // useOnClickOutside(refTryBtn, ()=>changeTryBtn(0, "שלחו ניסיון", "default"));

    useEffect(() => {
        if(!testSend)
            return;
        if(mailing.sendMailingXHR == 'SUCCESS')
            changeTryBtn(0, t('yeshSend'), 'open success');
        else if(mailing.sendMailingXHR == 'FAILED')
            changeTryBtn(0, t('wentWrong'), 'open failed');
        setPointerEventsBody('');
        setTimeout(function () {
            changeTryBtn(0, t('sendTest'), 'default');
            setTestSend(0);
        }, 3000);
    }, [mailing.sendMailingXHR]);

    return (
        <css.sendTry ref={refTryBtn} className={'sendTry ' + tryBtnClass} onClick={() => { tryBtnClass == 'default' && changeTryBtn(1, t('enter') +' '+ mailOrSmsText +' '+ t('forTest'), 'open'); }}>{tryBtnText}
            {!!trySendingInput &&
                <>
                    <css.sendTryInput type={mailOrSmsText} placeholder={t('enter') +' '+ mailOrSmsText +' '+ t('forTest')} className="sendTryInput" onChange={(e) => { changeTryInput(e); }} />
                    <css.sendTryBtn value={tryValue} className="sendTryBtn" onClick={(e) => { validateInput(e); }} />
                </>
            }
        </css.sendTry>
    );
}

const mapStateToProps = state => ({
    user: state.user.user,
    events: state.event
});

export default connect(mapStateToProps)(TestSending);
