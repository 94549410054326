import React, {useState, useEffect} from 'react';
import './Countdown.css';

export default function Countdown(props) {
    const [timeLeft, setTimeLeft] = useState(calculateFromNow(props.date, props.handle, props.id));
    useEffect(() => {
        if(!timeLeft) return;
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(calculateFromNow(props.date, props.handle, props.id));
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    if(!props.date){
        return null;
    }

    return (
        <div className="count-down">
            {timeLeft}
        </div>
    );
}

function calculateFromNow(date, handle, id) {
    let newScheduled = (new Date(date)).getTime() / 1000 - new Date() / 1000;
    let days = Math.floor(newScheduled / 86400);
    newScheduled -= days * 86400;
    let hours = Math.floor(newScheduled / 3600) % 24;
    newScheduled -= hours * 3600;
    hours += (days*24);
    let minutes = Math.floor(newScheduled / 60) % 60;
    newScheduled -= minutes * 60;
    let seconds = parseInt(newScheduled % 60);

    if(hours < 0 || minutes < 0 || seconds < 0){
        if(handle){
            handle('countdownStopped', {id:id});
        }
        return false;
    }
    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
}