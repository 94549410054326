import styled from 'styled-components/macro';
import { BGImageCenter } from '../../../../globalVariables/GlobalVariables';
import { arrowBtn } from '../../../../assets/symbols/symbols/Symbols.css';
import { List } from '../../../../component/list/List.css';


export const mainContainer = styled.div`
    display: flex;
    &:has(.countries-list.show) {
        .loginWrapper {
            width: 0;
            div:has(input[name="phone"]) {
                border: 0;
                padding: 0;
            }
        }
    }
`
export const container = styled.div`
    width: 30%;
    &:has(.countries-list.show) {
        width: 100%
    }
`
export const loginWrapper = styled.div`
    width: 70%;
    transition: width 300ms;
    div:has(input[name="phone"]) {
        border-top-${p => p.theme.rtl ? 'left' : 'right'}-radius: 0;
        border-bottom-${p => p.theme.rtl ? 'left' : 'right'}-radius: 0;
        width: 100%;
    }
`

export const filter = styled.div`
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    direction: initial;
    background-color: white;
    border-top-${p => p.theme.rtl ? 'right' : 'left'}-radius: 0;
    border-bottom-${p => p.theme.rtl ? 'right' : 'left'}-radius: 0;
    height: 35px;
    transition: all 300ms;
    &.open{
        border-radius: 20px;
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    :hover{
        background:#eaeaea;
    }
    ::before{
        content:'';
        ${BGImageCenter(arrowBtn, '6px')};
        position:absolute;
        background-size: 8px 8px;
        background-repeat: no-repeat;
        background-position: center center;
        width: 8px;
        height: 8px;
        inset-inline-end: 7px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(-90deg);
        top: calc(50% - 4px);
    } 

    ${List}{
        inset-inline-end: 0;
    }
    .countries-list {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .itemIcon {
        background-size: cover;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
    }
`

export const chosenItem = styled.div`
    height: 100%;
    font-weight: bold;
    display:flex;   
    align-items: center;
    justify-content: space-evenly;
    margin-right: 15px;
    ::after {
        content: "${p => p.theme.rtl? ("+"+p.selected.dialCode):""}";
        font-weight: bolder;
    }
    &.open::after {
       content: "${p => p.selected.text}";
    }
    img {
        background-color: lightgray;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        box-shadow: 0px 3px 6px #00000029;
    }
`

export const chosenItemTitle =styled.div`
    display: none;
`