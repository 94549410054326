import styled, {css} from 'styled-components/macro';
import {BGImageCenter, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {blackSuccess} from '../../../../assets/symbols/symbols/Symbols.css';

export const pixelsArea = styled.div`
  display: flex;
  align-items: flex-start;
  ${mobileMedia}{
        flex-direction: column;
        align-items: center;
    }
`;

export const left = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;  
    width: calc(100% - 80px);
    ${mobileMedia}{
        justify-content: center;
        align-items: center;
        width:100%;
        padding-bottom: 85px;
    }
`;

export const right = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 80px;
    ${mobileMedia}{
        flex-direction: row;
        width: 95%;
        padding: 5px 7px 25px 7px;
        ${scrollbar}
    }
`;

export const pixelAnimate=css`
    transform: scale(0);
    transition: all 500ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
    &.show{
        transform: scale(1);
    }
`;

export const pixelIcon=styled.div`
    cursor: pointer;
	width:40px;
	height:40px;
    border-radius: 50%;
	${p=>BGImageCenter(p.icon,'23px')}
    position: relative;
    ${pixelAnimate}
    :hover{
        box-shadow: 0px 3px 6px #00000029;
        transition: all 300ms;
    }
    &.selected{
        cursor: unset;
        pointer-events: none;
        ::before{
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: #C6D628;
            border-radius: 50%;
            top: -2px;
            inset-inline-start: -4px;
            ${BGImageCenter(blackSuccess,'8px')}
    }
        border: 1px solid #43BAE7;
    }
`;

export const container=styled.div`
	height: 100%;
	width: 100%;
`;