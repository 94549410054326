import Compressor from 'compressorjs';

function compressAndEncodeToBase64(file,onLoadEnd) {
    new Compressor(file, {
        quality: 0.8,
        success: (compressedResult) => {   
            let reader = new FileReader();
            reader.readAsDataURL(compressedResult);  
            reader.onloadend = function() {
                onLoadEnd(reader.result);
            };
        },
    });
}

export function fileCompressor(file, onLoadEnd) {
    if(file.type == 'application/pdf') {
        onLoadEnd(file);
    }
    else {
        const originalSize = file.size;
        const targetSize = originalSize * 0.8; 
  
        new Compressor(file, {
            maxSize: targetSize,
            success: (compressedResult) => {
                const compressedFile = new File([compressedResult], file.name, {
                    type: file.type,
                    lastModified: file.lastModified,
                });
  
                onLoadEnd(compressedFile);
            },
        });
    }
}
export default compressAndEncodeToBase64;

