import createReducer from '../createReducer';
let user = JSON.parse(localStorage.getItem('user'));

export const initialUser= {
    uid:null,
    nameFirst: null,
    nameLast: null,
    email: null,
    phone: null,
    picture: null,
    lastConnect: null,
    langloc: null,
    lang: null,
    locale: null,
    isRtl: 0,
    isMaster: false,
    defaultPid:null,
    defaultEid:null,
    ownerEmailVerified:null,
    info:{},
};
export const initialUserXHR={
    loginByEmailXHR:null,
    signUpXHR:null,
    getUserXHR:null,
    restoreRequestXHR:null,
    loginUserXHR:null,
    updateUserDetailsXHR:null,
    getTokenToSignUpXHR:null,
    updateUserInfoXHR:null,
    getUserInfoXHR:null,
};

user=user?{...initialUser,...user}: initialUser;

const initialState ={
    ...user,
    ...initialUserXHR
};

const reducer = createReducer('user',initialState);

export default reducer;