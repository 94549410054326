import styled from 'styled-components/macro';
import {desktopMedia, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';

// export const detailsTitle = styled.div`
//     height: 75px;
//     line-height: 75px;
//     padding: 0 20px;
//     font-weight: 600;
//     @media screen and (max-width:1000px) {
//         line-height: 50px;
//         padding: 0 45px;
//     }
//     `;
export const detailsFrame = styled.div`
    /* padding-inline-start:20px; */
    padding-inline-end:6%;
    ${desktopMedia}{
        overflow: visible;
    }
    ${mobileMedia}{
        ${scrollbar}
        &.keyboard{
            height:calc(100% - 220px);
            overflow: scroll;
        }


}
`;

export const saveBtn = styled.div`
    height:50px;
    display: flex;
    justify-content:${props => (props.theme.rtl ? 'left' : 'right')};
    padding:0 16%;
    align-items: end;
  ${mobileMedia}{
    justify-content: center;
    align-items: center;
  }
`;

export const location = styled.div`
    width: 100% ;
    display: flex;
    justify-content: flex-end;
    position: relative;
    >img{
        width:calc(100% - 25px);
        height: 90px;
    }
`;

