import styled from 'styled-components/macro';
import {mail} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {loginEllipse} from '../loginInput/LoginInput.css';

export const toEmail=styled(loginEllipse)` 
    cursor: pointer;
    font-size:14px;
    ${dFlexCenter}
    justify-content: flex-start;
    font-weight: 500;
    width: 100%;
    transition: all 300ms;
    max-width: 160px;
    margin: 0 auto;
    :hover{
        background-color: rgba(255,255,255,0.25);
    }
`;
 
export const icon=styled.div` 
    ${BGImageCenter(mail,'15px')}
    flex-basis: 32px;
    height: 30px;
`;
 