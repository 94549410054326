import React from 'react';
import './Link.css';

class Link extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            linkBtnImage: 'copy',
        };

        this.handleCopy = this.handleCopy.bind(this);
    }

    handleCopy(){
        const el = document.createElement('textarea');
        el.value = this.props.link;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({linkBtnImage: 'v'});
        setTimeout(function () {
            this.setState({linkBtnImage: 'copy'});
        }.bind(this), 2000);
    }

    render(){
        return (
            <div className="link" image="before-link">
                <div className="link-copy">
                    <input type="text" readOnly={true} defaultValue={this.props.link} onClick={()=>{window.open(this.props.link, 'Blank');}}/>
                    <div className="link-copy-btn" image={this.state.linkBtnImage} data-title="copied" onClick={this.handleCopy} />
                </div>
            </div>
        );
    }
}

export default Link;