import styled from 'styled-components/macro';
import {BGImageCenter, scrollbar, tabletMedia} from '../../globalVariables/GlobalVariables';
import {arrowDown, ex} from '../../assets/symbols/symbols/Symbols.css';

export const wrapper=styled.div`
   display:grid;
   grid-template-columns: repeat(3 , 1fr);
   gap: 10px;
   transition: max-height 400ms ease-in-out;
   overflow: hidden;
   padding: 10px;
   max-height:150px; 
   width: 100%;
   background-color: #F6F6F6;
   box-shadow: 0px 1px 4px #00000029;
   border-radius: 5px;
   ${scrollbar}
   ${p => p.theme.isExpanded && `
        height: max-content;
        max-height:1000px
   `};
   ${tabletMedia}{
        width: 100%;
        display:flex;
        flex-wrap:nowrap;
        height: max-content;
   }
`;

export const icon=styled.div`
    ${p=>BGImageCenter(p.theme.isExpanded ? ex : arrowDown, '9px')};
    width: 15px;
    height: 20px;
    cursor: pointer;
    margin-inline-start:50%;
`;