import React from 'react';
import * as css from './SuspendedDealEditor.css';
import {useContext} from 'react';
import {BrandingContext} from '../BrandingPage';
import {useTranslation} from 'react-i18next';
import EditorWrapper from '../../../component/textEditor/editorWrapper/EditorWrapper';
import {DEFAULT_LANG} from '../../../globalVariables/locals';

function SuspendedDealEditor({name,onSave, defaultText, lang=DEFAULT_LANG,dependencies}) {
    const {t} = useTranslation('branding');
    const {textEditor}= useContext(BrandingContext);

    return (   
        <>
            <css.title>
                <css.name>{t(name)}</css.name>
            </css.title>
            <EditorWrapper defaultText={defaultText} showToolbar={true} events={{onSave,onFocus:()=>textEditor.setCurrentEditorName(name)}} dependencies={dependencies} lang={lang}/>
        </>
    );
}

export default SuspendedDealEditor;
