import styled from 'styled-components/macro';
import * as images from '../../../assets/symbols/images/Images.css';
import isLightColor from '../../../functions/isLightColor';
import {mobileScreen, scrollbar} from '../../../globalVariables/GlobalVariables';
import {Scrollbar} from '../../scrollbar/Scrollbar.css';
import {button} from '../../button/buttonScroll/ButtonScroll.css';
export const BOTTOM_HEIGTH='70px';

export const image=styled.div`
    background-repeat: no-repeat;
    height: 115px;
    width: 175px;
    position: absolute;
    inset-inline-end: 90px;
    background-image: url("${p=>images[p.image]}");
    bottom: 0;    
    background-size:contain;
    @media screen and (max-width:${mobileScreen}px){
        bottom: 56px; 
        height: 78px;
        inset-inline-end: 80px;
    }
`;

export const popUpImage = styled.div`
    background-color:${p=>p.color?'#'+p.color:'#76d6e8'} ;
    div{
    ${p=>isLightColor(p.color)?'':'color:#EEE;'}
    }
    height: 60px;
    min-height: 60px;
    position: relative;
    transition: all 200ms;
    &.focus{
        @media screen and (max-width:${mobileScreen}px){
            height:0;
            opacity:0;
        }
    }
`;

export const popUpContent=styled.div`
    ${scrollbar};
    height: calc(100% - ${p=>p.hasBottom?190:120}px);
    min-height: ${p=>p.hasBottom?290:430}px;
    transition: all 200ms;
    /* &.focus{
       @media screen and (max-width:${mobileScreen}px){
            height: calc(100% - 200px);
        }
    } */
    overflow-x: hidden;
`;

export const popUpContentRegular=styled(Scrollbar)`
    height: calc(100% - 200px);
    transition: all 200ms;
    overflow: auto;
      ${button}{
        bottom: 85px;
      }
`;
