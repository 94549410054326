import {defaultImage} from '../../../../assets/symbols/images/defaultImage';
import * as css from './ClusterRightPart.css';

function ClusterRightPart({event,openPopup}) {
    return (
        <>
            <css.firstPart onDoubleClick={()=>openPopup(event,event.cgid)} >
                <css.placeDrag className=' place ' />
                <css.icon>
                    <css.image image={event.fbImg||defaultImage} />
                    <css.circleWrap className='circleWrap' onClick={()=>openPopup(event,event.cgid)}> </css.circleWrap>
                </css.icon>  
                <css.right>
                    <css.header>
                        <css.title>{event.title}</css.title>
                    </css.header>
                </css.right>
            </css.firstPart>
        </>

    );
}
export default ClusterRightPart;
