import {useTranslation} from 'react-i18next';
import AnotherOneHorizontal from '../../../../component/anotherOneHorizontal/AnotherOneHorizontal';
import TicketBox from '../../components/ticketBox/TicketBox';
import * as css from './TicketsArea.css';
import TicketOptions from '../../components/ticketOptions/TicketOptions';
import TicketTable from '../../components/ticketTable/TicketTable';
import {Else, If, Then} from 'react-if';
import GridDrag from '../../../../component/gridDrag/GridDrag';
import {updateTicketWeight} from '../../../../redux/tickets/ticketsActions';
import TableTitle from '../../../components/tableTitle/TableTitle';
import {cssTicketColumns} from '../../components/ticketRow/TicketRow.css';

function TicketsArea({tickets, openPop,isTablePreview}) {
    const {t} = useTranslation('ticket');

    const getTicketBoxProps=item=>({
        ticket:item,
        className:'improved',
        openPopup:()=>openPop(item),
        children:<TicketOptions ticket={item} openPopup={()=>openPop(item)} left={-100}/>
    });
    const titleTableArray= [
        {text: t('ticket'), field:'title', action: 'sort'},
        {text: t('price'), field:'price', action: 'sort'},
        {text: t('amount'), field:'amount', action: 'sort'},
        {text: t('sold'), field:'sold', action: 'sort'},
        {text: t('sale'), field:'soldPrice', action: 'sort'},
        {text: t('extras'), field:'countExtra', action: 'sort'}

    ];

    return (
        <>
            <If condition={isTablePreview}>
                <Then>
                    <TableTitle titleTableArray={titleTableArray} cssColumns={cssTicketColumns}/>
                    <TicketTable/>
                </Then>
                <Else > 
                    <css.ticketsArea>  
                        <GridDrag Comp={TicketBox} 
                            idName={'tid'} 
                            onDragEnd={updateTicketWeight}
                            minSize='314px' 
                            getProps={getTicketBoxProps}
                            items={tickets}
                            undraggableChildren={<AnotherOneHorizontal title={t('newTicket')} onClick={()=>openPop()}/>} />
                    </css.ticketsArea>

                </Else>
            </If>

        </>
    );
}

export default TicketsArea;