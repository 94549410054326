import React from 'react';
import * as css from './BtnIcon.css';

function BtnIcon(props) {
    const {onClick, item, showChild} = props;
    return (
        <>
            {showChild ?
                props.children
                :
                <css.btnIcon key={item.key} onClick={onClick} >
                    <css.opItemIcon className={'opIcon'} icon={item.icon} />
                    <css.opItemText className={'opText ' + (item.showAlways && 'showAlways')}>{item.text}</css.opItemText>
                </css.btnIcon>

            }
        </>
    );
}

export default BtnIcon;
