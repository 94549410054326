import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ModeBtn, {statuses} from '../../../../component/modeBtn/ModeBtn';
import {updateAccountPermission} from '../../../../redux/account/accountActions';
import EventRolePermission from '../../../components/rolePermission/EventRolePermission';
import {CSVDownload} from 'react-csv';
import {TICK_DOMAIN} from '../../../../config/config';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';

function ModeBtnPermission({index, openPopup,rolePermission}) {
    const {proposal, inactive, active, message} = statuses;
    const permission = useSelector(state => state.account.permissions[index]);
    const {isMasterState} = useSelector(state => state.ui);
    const updatePermissionIndex = useSelector(state => state.ui.account.updatePermissionIndex);
    const updateAccountPermissionXHR = useSelector(state => state.account.updateAccountPermissionXHR);
    const {t} = useTranslation('permission');
    const [sendStatus, setSendStatus] = useState(convertStatus(permission.status));

    useEffect(() => {
        setSendStatus(convertStatus(permission.status));
    }, [permission.status]);

    function convertStatus(s) {
        switch (s) {
            case -1:
                return proposal;
            case 0:
                return inactive;
            case 1:
                return active;
        }
    }

    const [downloadCSV,setDownloadCSV]=useState(false);

    async function updateServer(status) {
        const res= await updateAccountPermission(permission.permission, status, index);
        if(!res?.eventsUsage)
            return;
        const download=()=>{
            const array=[[t('event'),t`link`]];
            res.eventsUsage.forEach(event => {
                array.push([event.title,`${TICK_DOMAIN}/e/${event.eid}`]);
            });
            setDownloadCSV(array);   
        };
        bounceMessage(t('eventsUsageMessage'),'attention',{text:t`download`,onClick:download},null);
    }
    useEffect(()=>{
        if(downloadCSV){
            setDownloadCSV(null);
        }
    },[downloadCSV]);
    function doInactive() {
        if(sendStatus !== message)
            updateServer(0);
    }
    function plusClick() {
        openPopup(index);
    }
    function doActive() {
        updateServer(1);
    }

    function toggleClick() {
        switch (permission.status) {
            case -1:
                plusClick();                
                break;
            case 0:                
                doActive();
                break;
            default:
                doInactive();
                break;
        }
    }

    return (<>
        {downloadCSV&&
        <CSVDownload data={downloadCSV} target="_blank" />
        }
        <EventRolePermission rolePermission={rolePermission[permission.status+1]} views={[]}>
            <ModeBtn click={toggleClick}
                status={sendStatus}
                requestStatus={updatePermissionIndex === index ? updateAccountPermissionXHR : null}
                disabled={!isMasterState && permission.status === -1} />
        </EventRolePermission>
    </>
    );
}

export default ModeBtnPermission;
