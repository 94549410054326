import * as css from './MobileTicketsRow.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Detail from '../../../../../../tickets/components/ticketRow/Detail/Detail';
import TicketProgress from '../../../../../../tickets/components/ticketRow/ticketProgress/TicketProgress';
import ticketColors from '../../../../../../../assets/objects/TicketColors' ;
import TicketsGroups from '../../../../../../tickets/components/ticketRow/ticketGroups/TicketsGroups';
import useShow from '../../../../../../../hooks/useShow';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ThemeProvider} from 'styled-components';
import TicketMobilePart from './ticketMobilePart/TicketMobilePart';
import {getDiscount} from '../TicketsRow';

const MobileTicketsRow = ({ticket,active}) => {
    const {t} = useTranslation('realtime');
    const currencySymbol = useSelector((state:any) => state.event.currencySymbol);
    const color = `#${ticket.color}`;
    const ticketsIcon = useSelector((state:any) => state.tickets.ticketsIcon);
    const maxPrice = ticket.prices?.length ? ticket.prices?.reduce((max, current) => Math.max(max, Number(current.price)), 0) : ticket.price;
    const minPrice = ticket.prices?.length ? ticket.prices?.reduce((min, current) => Math.min(min, Number(current.price)), Number(maxPrice)) : null;
    const pricesShow = useShow();
    const history = useHistory();
    const unlimited=ticket.amount===null;
    const sold=ticket.sold??0;
    const ticketOnClick = () =>{
        if(ticket.isMoreTickets){
            history.push(`/e/${ticket.eid}/tickets`);
        }
        else if(ticket.prices?.length > 1 && ticket.scheduled){
            pricesShow.open();
        }
    };
    return (
        <ThemeProvider theme={{active}}>
            <css.firstPart className='titleColumn' onClick={ticketOnClick}>
                <css.placeDrag className=' place ' />
                <css.ticketIcon icon={ticketColors[`${ticket.color}`]?.[ticketsIcon]} color={color} className='row_icon'>
                    <TicketProgress onClick={(e) => { e.stopPropagation();} } ticket={ticket}>
                        <></>
                    </TicketProgress>
                </css.ticketIcon>
                <css.ticketRight>
                    <css.header>
                        <css.text>{t('ticketName')}</css.text>
                        <css.ticketTitle data-cy='titleColumnValue'>{ticket.title}</css.ticketTitle>
                    </css.header>
                    <TicketsGroups ticket={ticket}/>
                </css.ticketRight>
            </css.firstPart>
            <TicketMobilePart details={[{title: t('amount'), value: ticket.amount??t('unlimited'),...(unlimited ? {className:'unlimited'}: {}), sign: !unlimited&&t('ticketPcs'), isSymbol: false}]} ticketOnClick={ticketOnClick}>
                <> 
                    <css.text>{t('price')}</css.text>
                    <css.price>
                        {(minPrice !== maxPrice) && <><Detail text='' value={minPrice} sign={''} className={'click'}/> - </> }
                        <Detail text='' value={maxPrice} isSymbol sign={currencySymbol} className={'click'}/>
                    </css.price>
                </>
            </TicketMobilePart>
            <TicketMobilePart details={[{title: t('sold'), value: sold, sign: t('ticketPcs'), isSymbol: false}, {title: t('left'), value: unlimited?'-':(ticket.amount - sold), sign: !unlimited&&t('ticketPcs'), isSymbol: false}]} ticketOnClick={ticketOnClick}/>
            <TicketMobilePart details={[{title: t('discount'), value: ticket.discount ?? getDiscount(ticket), sign: currencySymbol, isSymbol: true}, {title: t('soldPrice'), value: ticket.soldPrice, sign: currencySymbol ,isSymbol: true}]} ticketOnClick={ticketOnClick}/>
        </ThemeProvider>
    );
};

export default MobileTicketsRow;