import styled from 'styled-components/macro';
import {arrowDark} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {btn} from '../button/btn/Btn.css';
import {BOTTOM_HEIGTH} from '../newPopup/tabsPopup/TabsPopup.css';

export const bottom=styled.div`
display:flex;
justify-content: flex-end;
align-items: center;
height: ${BOTTOM_HEIGTH};
border-top: 1px ;
border-top: 1px solid #EEE;

`;
export const wrapBtn=styled.div` 
    width: 55%;
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
    ${mobileMedia}{
        width: 75%;
        padding: 0;
        padding-inline-end:30px
    }`;
export const nextBtn=styled.div`
    transition:all 400ms ;
    opacity:0 ;
    pointer-events: none;
    &.show{
        opacity:1 ;
        pointer-events: unset;
    }
    ${btn}{
        margin-inline-end:10px;
        min-width: 80px;
    }
`;

export const saveBtn=styled.div`
    margin-inline-end: 30px;
    transition:all 300ms; 
    width:70px ;
    overflow:hidden ;
    &.hidden{
       width:0;
       /* transform:scale(0.5) ; */
    }
  &.disabled{
    ${btn}{
    /* min-width: 70px; */
      opacity: 0.7;
      cursor: pointer;
      :hover{
        border: 1px solid #FFF;
        background-color: #76D6E8;
      }
    }
  }
    
`;