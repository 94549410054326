import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Fragment} from 'react';
import * as css from './Navigation.css';
import {useContext} from 'react';
import useStep from '../StepLeft/useStep';
import {newEventContext} from '../newEventForm/NewEventForm';

export const useStepClick=()=>{

    const {steps,currentStep,setCurrentStep}=useContext(newEventContext);
    const form=useFormikContext();
    const isValid=(name)=>!form.errors[name];
    const isTouched=(name)=>form.touched[name];
    const {onSaveStep} = useStep(steps[currentStep]);

    return (index)=>{
        if(index<=currentStep||currentStep===-1)
            return setCurrentStep(index);
        const current=steps[currentStep].name;
        if(index===currentStep+1){
            if(!isTouched(current))
                form.setTouched({...form.touched,[current]:true});
            if(isValid(current)){
                setCurrentStep(index);
                onSaveStep();
            }   
            return;
        }
        if(steps.slice(currentStep,index).every(s=>isValid(s.name))){
            if(!isTouched(current))
                form.setTouched({...form.touched,[current]:true});
            setCurrentStep(index);
            onSaveStep();
        }
    };
};

function Navigation() {
    const {steps,currentStep}=useContext(newEventContext);
    const {t} = useTranslation('newEvent');
    const form=useFormikContext();
    const onClick=useStepClick();

    return (
        <css.scrollWrapper>
            <css.wrapper >
                {steps.map(({name},index)=><Fragment key={name}>
                    <css.tab
                        name={t(name)}
                        icon={name}
                        data-cy ={name}
                        className={`${currentStep===index?'current':''}`}
                        onClick={()=>onClick(index)}
                    >
                        {!form.errors[name]&&form.touched[name]&&<css.vi/>}
                    </css.tab>
                    {index<steps.length-1&&<css.line />}
                </Fragment>
                )}
            </css.wrapper>
        </css.scrollWrapper>
    );
}

export default Navigation;