import * as css from './Groups.css';
import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PageWrapper from '../../components/pageWrapper/PageWrapper';
import {getEventGroups, getEventTickets} from '../../../redux/tickets/ticketsActions';
import Group from './group/Group';
import AnotherOne from '../../../component/anotherOne/AnotherOne';
import {useTranslation} from 'react-i18next';
import PopUp from './popUp/PopUp';
import store from '../../../store';
import {useHistory, useLocation} from 'react-router-dom';

function Groups() {
    const {t} = useTranslation('ticket');
    const tickets = useSelector(s => s.tickets);
    const groupsInfo = tickets.groups;
    const [{popup, item}, setPopUp] = useState({popup: false, item: false});
    const history = useHistory();
    const location = useLocation();
    const groups = useSelector(s => s.tickets.groups);
    const ticket = useSelector(s => s.tickets);

    const operations = [{
        key: 0, text: t('createGroup'), onClick: () => {
            handlePopUp(true, false);
        }
    }];

    const handlePopUp = (show, item) => {
        setPopUp({popup: show, item: item});
    };

    const openGroup = (group) => {
        history.push(`${location.pathname}/${group}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            if(!store.getState().tickets.groups.length)
                await getEventGroups();
            if(!store.getState().tickets.tickets.length)
                await getEventTickets();
        };
        fetchData()
            .catch(console.error);
    }, []);

    useEffect(() => {
        if(tickets.updateGroupXHR === 'SUCCESS' || tickets.addGroupXHR === 'SUCCESS')
            setPopUp({popup: false, item: false});
    }, [tickets.updateGroupXHR, tickets.addGroupXHR]);

    useEffect(() => {
        if(ticket.addGroupXHR === 'SUCCESS') 
            openGroup(groups[groups.length - 1].gid);
    }, [groups]);

    return (
        <>
            <PageWrapper image={'ticketsGroups'} operations={{buttons: operations}} className={'ticketsGroups'}>
                {
                    <>
                        <css.groupsArea data-cy="groups">
                            {groupsInfo?.map((item, index) => (
                                <Group key={index} item={item} color={item.color} openPopUp={(item) => handlePopUp(true, item)} index={index} openGroup={openGroup} />
                            ))}
                            <AnotherOne title={t('addGroup')} onClick={() => handlePopUp(true, false)}  dataCyName ="groups" />
                            {popup && <PopUp closePop={() => handlePopUp(false, false)} item={item}/>}
                        </css.groupsArea>
                    </>}
            </PageWrapper>
        </>
    );
}

export default Groups;
