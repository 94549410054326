import * as css from './Security.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import React, {useEffect, useRef} from 'react';
import {resetGlobalState} from '../../../redux/setGlobalState';
import useShowModal from '../../../hooks/useShowModal';
import {permissionToCamelCase} from '../../package/componnent/upgradePopup/UpgradePopup';
import {askPermissionFromOffice, cancelVerification, requestAfterVerification} from '../../../functions/securityRequest/highSecurityRequest';
import CenteredPopup from '../../../component/centeredPopup.js/CenteredPopup';
import SMSCode from '../../../component/SMSCode/SMSCode';
import Btn from '../../../component/button/btn/Btn';
import {getToken} from '../../../redux/security/securityActions';
import CountButton from '../../../component/button/countButton/CountButton';

function Security() {
    const security = useSelector(state => state.security);

    const {t} = useTranslation('security');
    const tPermission = useTranslation('permission');
    const forwardRef = useRef();
    const {open, close, isOpen, showClass} = useShowModal(forwardRef);

    useEffect(() => {
        if(security.permission)
            open();
    }, [security.permission]);

    useEffect(() => {
        if(isOpen)
            return;
        cancelVerification();
        resetGlobalState('security');
    }, [isOpen]);

    const onUpdateCode = (code) => {
        requestAfterVerification(code);
    };
    function onAccept() {
        if(level === 3)
            askPermissionFromOffice(security.permission);
        else
            requestAfterVerification();
    }
    const perm = tPermission.t(permissionToCamelCase(security.permission || ''));
    const level = security.level;
    const accountOwner = useSelector(state => state.account.owner);
    const {nameFirst,nameLast}=useSelector(s=>s.user);

    const getCenter = () => {
        if(security.isVerification)
            return <></>;
        switch (level) {
            case 1:
                return `${t('attention')} ${t('activation')} ${perm} ${t('defaultMessage')}`;
            case 2:
                return <>
                    {perm==='course'?t('courseWarning')
                        :`${t('attention')} ${t('activation')} ${perm} ${t('message2')} ${accountOwner} ${t('messageNext2')}`}
                    <SMSCode onUpdate={onUpdateCode} sendAgainClick={isVoiceCode => getToken(security.permission, isVoiceCode)} text={t('verificationCode',{name:`${nameFirst||''} ${nameLast||''}`})}/>
                </>;
            case 3:
                return `${t('attention')} ${t('activation')} ${perm} ${t('message3')}`;
            default:
                break;
        }
    };

    const getButtons = () => {
        if(security.isVerification)
            return <CountButton count={10} callBack={close} onClick={close}>
                {t('thanks')}
            </CountButton>;
        const style = 'min-width:90px; margin:0 5px;';
        switch (level) {
            case 1:
                return <>
                    <Btn onClick={close} className=" white " style={style}>{t('no')}</Btn>
                    <Btn onClick={onAccept} style={style}> {t('accept1')}</Btn>
                </>;
            case 2:
                return <Btn onClick={onUpdateCode} style={style + 'width:200px'}>{t('btn2')}</Btn>;
            case 3:
                return <>
                    <Btn onClick={close} className=" white " style={style}>{t('no')}</Btn>
                    <Btn onClick={onAccept} style={style}>{t('yesThank')}</Btn>
                </>;
        }
    };

    return (<>
        {isOpen &&
            <css.wrap className={(security.isVerification && 'send ')} isLevel3={level === 3}>
                <CenteredPopup showClass={showClass} forwardRef={forwardRef} close={close}
                    title={!security.isVerification ? t('securityTitle') : (level === 3 ? t('success3') : (`${t('successMessage1')} ${perm} ${t('successMessageNext1')}`))}>
                    {getCenter()}
                    {getButtons()}
                </CenteredPopup>
            </css.wrap>}
    </>
    );
}

export default Security;