import {useTranslation} from 'react-i18next';
import * as css from './LeadDetail.css';

function LeadDetail({value,field,className,isEdit=false,onClick}) {
    const {t} = useTranslation('publish');

    return (
        <css.detail className={`defaultColumn ${className}`} data-cy="leadDetail">
            <css.boldText data-cy={`${field}ColumnTitle`}>{t(field)}</css.boldText>
            <css.text data-cy={`${field}ColumnValue`} className={isEdit&&'isEdit'} onClick={onClick}>
                {value}
            </css.text>
        </css.detail>
    );
}
export default LeadDetail;
