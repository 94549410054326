import * as css from './TicketsRow.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Part from '../../../../../tickets/components/ticketRow/part/Part';
import Detail from '../../../../../tickets/components/ticketRow/Detail/Detail';
import TicketProgress from '../../../../../tickets/components/ticketRow/ticketProgress/TicketProgress';
import ticketColors from '../../../../../../assets/objects/TicketColors' ;
import PricesDetails from '../pricesDetails/PricesDetails';
import useShow from '../../../../../../hooks/useShow';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ThemeProvider} from 'styled-components';
import {formatNumber} from '../../../../../../library/numberFunction';

export const getDiscount = (ticket) =>{
    let discount = 0;
    if(ticket.prices.length >1){
        ticket.prices.forEach((prices)=>{
            discount += ((prices.soldPrice??0) - ((prices.sold??0) * prices.price));
        });
    } else {
        discount = (ticket.soldPrice??0) - ((ticket.sold??0) * ticket.price);
    }
    return discount;
};

const TicketsRow = ({ticket,active,index,setActiveIndex}) => {
    const {t} = useTranslation('ticket');
    const currencySymbol = useSelector((state:any) => state.event.currencySymbol);
    const color = `#${ticket.color}`;
    const ticketsIcon = useSelector((state:any) => state.tickets.ticketsIcon);
    const maxPrice = ticket.prices?.length ? ticket.prices?.reduce((max, current) => Math.max(max, Number(current.price)), 0) : ticket.price;
    const minPrice = ticket.prices?.length ? ticket.prices?.reduce((min, current) => Math.min(min, Number(current.price)), Number(maxPrice)) : null;
    const pricesShow = useShow();
    const history = useHistory();

    const ticketOnClick = () =>{
        if(ticket.isMoreTickets){
            history.push(`/e/${ticket.eid}/tickets`);
        }
        else if(ticket.prices?.length > 1){
            pricesShow.open();
        }
    };
    const unlimited=ticket.amount===null;
    const sold=ticket.sold??0;
    return (
        <ThemeProvider theme={{active}}>
            <css.firstPart className='titleColumn' onClick={ticketOnClick} onMouseEnter={()=>setActiveIndex(index)} onMouseLeave={()=>setActiveIndex(null)}>
                <css.placeDrag className=' place ' />
                <css.ticketIcon icon={ticketColors[`${ticket.color}`]?.[ticketsIcon]} color={color} className='row_icon'>
                    <TicketProgress onClick={(e) => { e.stopPropagation();} } ticket={ticket}>
                        <></>
                    </TicketProgress>
                </css.ticketIcon>
                <css.ticketRight>
                    <css.wrapper>
                        <css.ticketTitle data-cy='titleColumnValue'>{ticket.title}</css.ticketTitle>
                    </css.wrapper>
                </css.ticketRight>
            </css.firstPart>
            <Part padding={'0'} field={'price'} className={''} cssStyle={undefined} onClick={ticketOnClick}>
                <css.wrapper>
                    {(minPrice !== maxPrice) && <Detail text='' value={minPrice} isSymbol sign={currencySymbol} className={'click numberRange'} number={true}/>}
                    <Detail text='' value={maxPrice} isSymbol sign={currencySymbol} className={'click'} number={true}/>
                </css.wrapper>
            </Part>
            <Part field={'amount'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                <Detail text='' value={ticket.amount??t('unlimited')} sign={!unlimited && t('ticketPcs')} className={`click ${unlimited &&'unlimited'}`} number={true}/>
            </Part>
            <Part field={'sold'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                <Detail text='' value={sold} sign={t('ticketPcs')} className={'click'}/>
            </Part>
            <Part field={'left'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                <Detail text='' value={unlimited?'-':(ticket.amount - sold)} sign={!unlimited&&t('ticketPcs')} className={'click'} number={true}/>
            </Part>
            <Part field={'discount'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                <Detail text='' value={ticket.discount ?? getDiscount(ticket)} sign={currencySymbol} isSymbol className={'click'} number={true}/>
            </Part> 
            <Part field={'soldPrice'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                <Detail text='' value={ticket.soldPrice} sign={currencySymbol} isSymbol className={'click'} number={true}/>
                {ticket.prices?.length > 1 && <css.arrow isOpen={pricesShow.isOpen}/>}
            </Part>
            {!!pricesShow.isOpen && <PricesDetails ticket={ticket} show={pricesShow}/>}
        </ThemeProvider>
    );
};

export default TicketsRow;