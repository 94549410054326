import request from '../../library/request';
import setGlobalState, {pushToGlobalState, unsetGlobalState} from '../setGlobalState';
import store from '../../store';

const getStatusTitle = (lead) => {
    const {totalPrice, ticliCount, ts} = lead;
    const intPrice = parseInt(totalPrice);
    const isRecent = ts && Date.now() - 86400000 < new Date(ts * 1000);
    const status = isRecent && ticliCount === 0
        ? 'new'
        : intPrice === 0 && ticliCount === 0
            ? 'deepSleep'
            : intPrice === 0 && ticliCount > 0
                ? 'worksInVain'
                : intPrice > 0 && ticliCount > 0 ? 'effective' : 'unknown';
    return status;
};

const getEid = () => store.getState().event.eid;

export const getLeads = async () => {
    const limit = 20;
    const storeLeads=store.getState().publish.lead.leads;
    const res = await request('publish.getLeads', 'get',  `/event/${getEid()}/leads`,{limit, offset:storeLeads?.length});
    let leads = res.leads?.map(item => {return {...item,statusTitle: getStatusTitle(item)};});
    leads=[...storeLeads,...leads];
    setGlobalState('publish.lead.leads', leads);
    if(res.leads.length < limit) 
        return;
    else 
        setImmediate(()=>{
            getLeads();
        });
};
export const addLead = async (data) => {
    const res = await request('publish.addLead', 'post', `/event/${getEid()}/lead`, {...data,});
    pushToGlobalState('publish.lead.leads',{...res,tickets:0,transactions:0,totalPrice:0,ticliCountUnique:0,ticliCount:0,statusTitle:'new',active:1});
};

export const updateLead = async (ref, data) => {
    const res = await request('publish.updateLead', 'put', `/event/${getEid()}/lead/${ref}`, data);
    if(res){
        const leads = store.getState().publish?.lead?.leads;
        const index = leads.findIndex(p=>p.ref===res.ref);
        setGlobalState(`publish.lead.leads[${index}]`, {...leads[index],...res});
    }
};

export const getAutomatedLeads = async () => {
    const res = await request('publish.getAutomatedLeads', 'get', `/event/${getEid()}/automatedLeads`);
    const automatedLeads = res.map(lead=>({...lead,totalPrice:`${lead.totalPrice}`}));
    setGlobalState('publish.lead.automatedLeads', automatedLeads);
};

export const deleteLead = async (ref) => {
    const res = await request('publish.deleteLead', 'delete', `/event/${getEid()}/lead/${ref}`);
    if(res){
        const leads = store.getState().publish?.lead?.leads;
        const index = leads.findIndex(p=>p.ref===ref);
        unsetGlobalState(`publish.lead.leads[${index}]`);
    }
};