import MobilePopupModal from '../../../component/newPopup/mobilePopup/MobilePopupModal';
import * as css from './EasyCourseFirstScreen.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Btn from '../../../component/button/btn/Btn';
import BtnWhatsAppHelp from '../../../component/button/btnWhatsAppHelp/BtnWhatsAppHelp';
import {createEasyCourse} from '../../../redux/account/accountActions';
import {useState} from 'react';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import {deleteCourse} from '../../../redux/suggestions/suggestionsActions';
import {useRef} from 'react';
import setGlobalState from '../../../redux/setGlobalState';
import InputFrame from '../../../component/input/inputFrame/InputFrame';
import {updateEvent} from '../../../redux/event/eventActions';
import {string} from 'yup';
import useTimeout from '../../../hooks/useTimeout';

function EasyCourseFirstScreen({openPop}) {
    const {t} = useTranslation('event');
    const tLayout= useTranslation('layout');
    const history = useHistory();
    const [newEid,setNewEid]=useState(null);
    const isDeleteCourse = useRef(false);
    const [{isErrorText,valueText},setIsErrorText]= useState({isErrorText:true,valueText:''});

    const createEasyCourseXHR=useSelector(s=>s.account.createEasyCourseXHR);

    const openCourse=()=>{
        updateEvent({title:valueText}, newEid);
        history.push('?openTicket=open');
        setGlobalState('account.createEasyCourseXHR',null);
        setTimeout(()=>{
            setGlobalState('ui.show.isOpenEasyCourseStart',false);
        },100);
    };
    
    useEffect(() => {
        createEasyCourse().then(eid=>{
            setNewEid(eid);
            if(isDeleteCourse.current)
                deleteCourse(eid);
            if(eid)
                history.push(`/e/${eid}/courses`);

        });
    }, []);

    const timer = useTimeout();

    useEffect(() => {
        if(createEasyCourseXHR==='SUCCESS'||createEasyCourseXHR==='FAILED')
            timer.current=setTimeout(() => {
                setGlobalState('account.createEasyCourseXHR',null);            
            }, 2000);
    }, [createEasyCourseXHR]);

    const onCloseProgressPop=()=>{   
        if(newEid){
            deleteCourse(newEid);
            history.goBack(); 
        }   
        isDeleteCourse.current=true;
        setGlobalState('ui.show.isOpenEasyCourseStart',false);
    };

    const checkIfValidText=(e)=>{ 
        const value = e.target.value; 
        setIsErrorText({isErrorText: !(string().min(2).max(30).isValidSync(value)),
            valueText:value}
        );
    };

    const closeProgressPop=()=>{ 
        bounceMessage(tLayout.t('deleteCourseWarning'),'warning', 
            {text: tLayout.t('btnMessage1'), onClick:onCloseProgressPop},
            {text: tLayout.t('btnMessage'), onClick:()=>{
            }});
    };

    return (
        <css.createCourse>
            <MobilePopupModal {...openPop} close={closeProgressPop}>
                <>
                    <css.container>
                        <css.title>{t('createCourseExcitementTitle')}</css.title>
                        <css.note>{t('courseTempName')}</css.note>
                        <InputFrame placeholder={t('projectName')} onChange={checkIfValidText} error={valueText&&isErrorText} dataCyName ="projectName"/>
                        <css.text>{t('goToCourseDetails')}</css.text>
                    </css.container>
                    <css.actions className={createEasyCourseXHR}>
                        <BtnWhatsAppHelp />
                        <Btn disabled={createEasyCourseXHR==='LOADING'||isErrorText} className={createEasyCourseXHR} onClick={openCourse} >{t('letMeWork')}</Btn>
                    </css.actions>
                </>
            </MobilePopupModal>
        </css.createCourse>
    );
}

export default EasyCourseFirstScreen;
