import React from 'react';
import * as css from './Row.css';
import Checkbox from '../../checkbox/Checkbox';

function Row({children, checked}) {
    const {isSelectable, isChecked, onChecked} = checked;

    return (
        <css.row     
            isSelectable={isSelectable}
            className={`row ${isChecked && 'checked '}`} 
        >
            {!!isSelectable&&
              <css.select >
                  <Checkbox selected={isChecked} onClick={(value) => onChecked(value)} />
              </css.select>
            }
            {children}
        </css.row>
            
    );
}
export default Row;