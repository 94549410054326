import React from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './EmailArtist.css';
import Btn from '../../../component/button/btn/Btn';
import {useSelector} from 'react-redux';
import EyeHideSection from '../../components/eyeHideSection/EyeHideSection';
import {YELLOW_ORANGE} from '../../../globalVariables/GlobalVariables';

function EmailArtistItem({artist}) {
    const {t} = useTranslation('branding');
    return (
        <css.wrapper data-cy='emailArtistItem'>
            <css.image image={artist.image} />
            <css.left>
                <span>        
                    <css.text>{artist.name}</css.text>
                    <css.smallText>{t('joinPage',{artist:artist.name})}</css.smallText>
                </span>              
                <Btn style={style}>{t('follow')}</Btn>
            </css.left>
        </css.wrapper>
    );
}

const style = `height: 40px;
               font-size: 16px;
               background-color:${YELLOW_ORANGE};
               border:none;
               padding: 0px 20px;`;

function EmailArtist() {
    
    const {artists} = useSelector(s => s.branding??{});

    if(!artists?.length)
        return <></>;
        
    return (  
        <EyeHideSection sectionName={'artist'}>
            {artists.map(artist => (
                <EmailArtistItem artist={artist} key={artist.artistId}/>
            ))}
        </EyeHideSection>
    );
}

export default EmailArtist;
