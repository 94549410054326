import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './CourseFields.css';
import {FieldArray, useField} from 'formik';
import CheckboxFormik from '../../../../../component/checkboxFormik/CheckboxFormik';
import CourseFieldsPop from '../courseFieldsPop/CourseFieldsPop';
import Tooltip from '../../../../../component/tooltip/Tooltip';

export let arrayHelpers = null;

function CourseFields() {
    const {t} = useTranslation('ticket');
    const [field] = useField('courseFields');
    let courseFields = field.value;
    const [show, setShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);

    const openEditor = (index) => {
        setCurrentIndex(index);
        setShow(true);
    };

    return (
        <css.wrapAll>
            <css.container>
                <css.title>{t('addFieldTitle')+'\n'}</css.title>
                <css.items>
                    <FieldArray
                        name="courseFields"
                        render={(_arrayHelpers) => {
                            arrayHelpers = _arrayHelpers;
                            return (
                                <>
                                    {courseFields?.map((item, index) => {
                                        return <css.item className={show&&' disabled '} key={index} data-cy={`${item.type}Item`}>
                                            <css.checkbox>
                                                <CheckboxFormik name={`courseFields.${index}.active`} />
                                            </css.checkbox>
                                            <css.text className={courseFields[index].required && ' required'} onClick={()=>openEditor(index)}>{item.title}&nbsp;</css.text>
                                            <css.typeWrap>
                                                <css.type type={courseFields[index].type}/>
                                                <css.edit className='courseFields_edit' onClick={()=>openEditor(index)} data-cy="courseFieldsEdit" />
                                            </css.typeWrap>
                                        </css.item>;
                                    })}
                                </>); }
                        }
                    />
                </css.items>
                <css.wrapPlus className={show&&' disabled'}><Tooltip text={t('addField')}><css.plusBtn onClick={() => { setCurrentIndex(-1); setShow(true); }} data-cy="addField"/></Tooltip>&nbsp;&nbsp;{t('addField')}</css.wrapPlus>
                <CourseFieldsPop showPopOnPop={{show, setShow}} currentIndex={currentIndex} courseFields={courseFields} />
            </css.container>
        </css.wrapAll>
    );
}
export default CourseFields;