import * as css from './EasyCourseLastScreen.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Btn from '../../../component/button/btn/Btn';
import BtnWhatsAppHelp from '../../../component/button/btnWhatsAppHelp/BtnWhatsAppHelp';
import {updateEventReady} from '../../../redux/event/eventActions';
import {useHistory} from 'react-router-dom';
import MobilePopupModal from '../../../component/newPopup/mobilePopup/MobilePopupModal';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';

function EasyCourseLastScreen({popShow}) {
    const {t} = useTranslation('event');
    const {nameFirst,nameLast,phone}= useSelector(s => s.user);
    const ticket=useSelector(s=>s.tickets.tickets[0]||{});
    
    const {ready,eid}=useSelector(s=>s.event);
    const history=useHistory();
    
    const disabledClose=ready<4;

    const close=()=>{
        if(disabledClose)
            return  bounceMessage(t('closeMabrkMessage'),'warning');
        popShow.close().then(()=>{
            if([3,4].includes(ready)&&ticket?.tid){
                updateEventReady(5).then();
                history.push(`/e/${eid}/design`);
            }
        });
    };  
    
    if(!popShow.isOpen)
        return <></>;

    return (
        <css.createCourse>
            <MobilePopupModal {...popShow} close={close} height={'500px'}>
                <>
                    <css.container>
                        <css.title>{t('mabruk')}</css.title>
                        <css.text>{t('courseReady')}</css.text>
                    </css.container>
                    <css.actions>
                        <BtnWhatsAppHelp messageText={t('finishCourseWhatsAppHelp',{name:`${nameFirst||''} ${nameLast||''}`,phone:phone,courseName:ticket.title})} />
                        <Btn disabled={disabledClose} onClick={close}>{disabledClose?t('working'):t('takeMeThere')}</Btn>
                    </css.actions>
                </>
            </MobilePopupModal>
        </css.createCourse>
    );
}

export default EasyCourseLastScreen;
