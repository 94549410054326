import styled from 'styled-components/macro';
import {mobileMedia} from '../../globalVariables/GlobalVariables';

export const wrapper = styled.div`
    height: 140px;
    margin-top: 10px;
    ${mobileMedia}{
        margin-top: 20px;
    }
`;

export const children = styled.div`
    height: calc(100% - 10px);
`;

export const content = styled.div`
    padding: 15px;
    height:  115px;
    background-color: #F3F3F3;
    border-radius: 10px;
`;

export const title = styled.div`
    font-size: 14px;
    inset-inline-start: 5px;
    direction: rtl;
    margin-bottom: 3px;
`;

export const error=styled.div`
    color: red;
    direction: rtl;
`;