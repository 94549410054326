import {add} from './Symbols.css';

export const googleRemarketing="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='91' height='146' viewBox='0 0 91 146'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.954' x2='0' y2='1.106' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23f14336'/%3E%3Cstop offset='0.406' stop-color='%23fbbb00'/%3E%3Cstop offset='0.724' stop-color='%2328b446'/%3E%3Cstop offset='1' stop-color='%23518ef8'/%3E%3C/linearGradient%3E%3Cfilter id='Rectangle_2571' x='0' y='0' width='91' height='146' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg id='Group_3710' data-name='Group 3710' transform='translate(-1033.568 -758.549)'%3E%3Cg transform='matrix(1, 0, 0, 1, 1033.57, 758.55)' filter='url(%23Rectangle_2571)'%3E%3Cpath id='Rectangle_2571-2' data-name='Rectangle 2571' d='M0,0H68a5,5,0,0,1,5,5V123a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(9 6)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3Cpath id='Rectangle_2572' data-name='Rectangle 2572' d='M0,0H68a5,5,0,0,1,5,5V33a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 765)' fill='%23f14336'/%3E%3Crect id='Rectangle_2573' data-name='Rectangle 2573' width='73' height='33' transform='translate(1043 796)' fill='%23fbbb00'/%3E%3Crect id='Rectangle_2574' data-name='Rectangle 2574' width='73' height='31' transform='translate(1043 829)' fill='%2328b446'/%3E%3Cpath id='Rectangle_2575' data-name='Rectangle 2575' d='M0,0H73a0,0,0,0,1,0,0V28a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 860)' fill='%23518ef8'/%3E%3C/g%3E%3C/svg%3E%0A";

export const googleAnalytics="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='91' height='146' viewBox='0 0 91 146'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.954' x2='0' y2='1.106' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23f14336'/%3E%3Cstop offset='0.406' stop-color='%23fbbb00'/%3E%3Cstop offset='0.724' stop-color='%2328b446'/%3E%3Cstop offset='1' stop-color='%23518ef8'/%3E%3C/linearGradient%3E%3Cfilter id='Rectangle_2571' x='0' y='0' width='91' height='146' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg id='Group_3710' data-name='Group 3710' transform='translate(-1033.568 -758.549)'%3E%3Cg transform='matrix(1, 0, 0, 1, 1033.57, 758.55)' filter='url(%23Rectangle_2571)'%3E%3Cpath id='Rectangle_2571-2' data-name='Rectangle 2571' d='M0,0H68a5,5,0,0,1,5,5V123a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(9 6)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3Cpath id='Rectangle_2572' data-name='Rectangle 2572' d='M0,0H68a5,5,0,0,1,5,5V33a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 765)' fill='%23f14336'/%3E%3Crect id='Rectangle_2573' data-name='Rectangle 2573' width='73' height='33' transform='translate(1043 796)' fill='%23fbbb00'/%3E%3Crect id='Rectangle_2574' data-name='Rectangle 2574' width='73' height='31' transform='translate(1043 829)' fill='%2328b446'/%3E%3Cpath id='Rectangle_2575' data-name='Rectangle 2575' d='M0,0H73a0,0,0,0,1,0,0V28a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 860)' fill='%23518ef8'/%3E%3C/g%3E%3C/svg%3E%0A ";

export const facebookPixel="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='91' height='146' viewBox='0 0 91 146'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%233c67dc'/%3E%3Cstop offset='1' stop-color='%234880f6'/%3E%3C/linearGradient%3E%3Cfilter id='Path_7530' x='0' y='0' width='91' height='146' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Path_7530)'%3E%3Cpath id='Path_7530-2' data-name='Path 7530' d='M0,0H68a5,5,0,0,1,5,5V123a5,5,0,0,1-5,5H0Z' transform='translate(9 6)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A";

export const googleAds = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='91' height='146' viewBox='0 0 91 146'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.954' x2='0' y2='1.106' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23f14336'/%3E%3Cstop offset='0.406' stop-color='%23fbbb00'/%3E%3Cstop offset='0.724' stop-color='%2328b446'/%3E%3Cstop offset='1' stop-color='%23518ef8'/%3E%3C/linearGradient%3E%3Cfilter id='Rectangle_2571' x='0' y='0' width='91' height='146' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg id='Group_3707' data-name='Group 3707' transform='translate(-1033.568 -758.549)'%3E%3Cg transform='matrix(1, 0, 0, 1, 1033.57, 758.55)' filter='url(%23Rectangle_2571)'%3E%3Cpath id='Rectangle_2571-2' data-name='Rectangle 2571' d='M0,0H68a5,5,0,0,1,5,5V123a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(9 6)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3Cpath id='Rectangle_2573' data-name='Rectangle 2573' d='M0,0H68a5,5,0,0,1,5,5V44.725a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 764.549)' fill='%23fbbb00'/%3E%3Crect id='Rectangle_2574' data-name='Rectangle 2574' width='73' height='42' transform='translate(1043 807.55)' fill='%2328b446'/%3E%3Cpath id='Rectangle_2575' data-name='Rectangle 2575' d='M0,0H73a0,0,0,0,1,0,0V38.451a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 849.549)' fill='%23518ef8'/%3E%3C/g%3E%3C/svg%3E%0A";

export const googleTagManager="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='91' height='146' viewBox='0 0 91 146'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.954' x2='0' y2='1.106' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23f14336'/%3E%3Cstop offset='0.406' stop-color='%23fbbb00'/%3E%3Cstop offset='0.724' stop-color='%2328b446'/%3E%3Cstop offset='1' stop-color='%23518ef8'/%3E%3C/linearGradient%3E%3Cfilter id='Rectangle_2571' x='0' y='0' width='91' height='146' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg id='Group_3709' data-name='Group 3709' transform='translate(-1033.568 -758.549)'%3E%3Cg transform='matrix(1, 0, 0, 1, 1033.57, 758.55)' filter='url(%23Rectangle_2571)'%3E%3Cpath id='Rectangle_2571-2' data-name='Rectangle 2571' d='M0,0H68a5,5,0,0,1,5,5V123a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(9 6)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3Cpath id='Rectangle_2573' data-name='Rectangle 2573' d='M0,0H68a5,5,0,0,1,5,5V64.725a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 764.549)' fill='%238ab4f8'/%3E%3Cpath id='Rectangle_2575' data-name='Rectangle 2575' d='M0,0H73a0,0,0,0,1,0,0V58.727a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 829.273)' fill='%234285f4'/%3E%3C/g%3E%3C/svg%3E%0A";

export const googleAnalytics4="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='91' height='146' viewBox='0 0 91 146'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.954' x2='0' y2='1.106' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23f14336'/%3E%3Cstop offset='0.406' stop-color='%23fbbb00'/%3E%3Cstop offset='0.724' stop-color='%2328b446'/%3E%3Cstop offset='1' stop-color='%23518ef8'/%3E%3C/linearGradient%3E%3Cfilter id='Rectangle_2571' x='0' y='0' width='91' height='146' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg id='Group_3708' data-name='Group 3708' transform='translate(-1033.568 -758.549)'%3E%3Cg transform='matrix(1, 0, 0, 1, 1033.57, 758.55)' filter='url(%23Rectangle_2571)'%3E%3Cpath id='Rectangle_2571-2' data-name='Rectangle 2571' d='M0,0H68a5,5,0,0,1,5,5V123a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(9 6)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3Cpath id='Rectangle_2573' data-name='Rectangle 2573' d='M0,0H68a5,5,0,0,1,5,5V64.725a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 764.549)' fill='%23fbbb00'/%3E%3Cpath id='Rectangle_2575' data-name='Rectangle 2575' d='M0,0H73a0,0,0,0,1,0,0V58.727a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z' transform='translate(1043 829.273)' fill='%23e37400'/%3E%3C/g%3E%3C/svg%3E%0A";

export const meta="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='91' height='146' viewBox='0 0 91 146'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%233c67dc'/%3E%3Cstop offset='1' stop-color='%234880f6'/%3E%3C/linearGradient%3E%3Cfilter id='Path_7530' x='0' y='0' width='91' height='146' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Path_7530)'%3E%3Cpath id='Path_7530-2' data-name='Path 7530' d='M0,0H68a5,5,0,0,1,5,5V123a5,5,0,0,1-5,5H0Z' transform='translate(9 6)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A";
