import styled from 'styled-components/macro';
import {BUTTON_HEIGHT} from '../../../../../globalVariables/GlobalVariables';
import {container} from '../../../../../component/popUpBubble/PopUpBubble.css';

export const wrapButton = styled.div`
    position: absolute;
    inset-inline-start: ${p=>(p.length*7+60<240?p.length*7+60:240)}px;
    top: calc(50% - ${BUTTON_HEIGHT/2}px);
    .close{
        height: 30px;
    }
    ${container}{
        position: absolute;
        inset-inline-end: -174px;
        top:45px;
        ::before{
            margin-inline-start: 87px;
        }
    }
    `;
export const draftPop = styled.div`
    width: 300px;
    height: 120px;
    white-space: pre-line;    
    padding: 20px;
    font-weight: 500;
    text-align: center;
    
    `;