import styled from 'styled-components/macro';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';
import * as input from '../../classes/input';
import * as basicInput from '../basicInput/BasicInput.css';

export const container = styled(input.container)`
  width:100% ;
  input{
        border: 0;
        outline: 0;
        width: 100%;
        border-bottom: 1px solid;
        margin-inline-start: 5px;
        font-size: 14px;
        border-right: 0;
        -webkit-transition: all 100ms;
        transition: all 100ms;
        resize: none;
        background: transparent;
        ${mobileMedia}{
            width:100% ;
        }
    }
  ${mobileMedia}{  
        width:100% ;
        }
`;

export const wrapInput = styled(basicInput.wrapInput)`
      height: 25px;
`;

export const textWrap = styled(basicInput.textWrap)`
`;
export const titleWrap= styled(basicInput.titleWrap)`
`;

export const title = styled.div`
width: max-content;
margin-inline-end: 5px;
font-weight:${input.INPUT_WEIGHT} ;
`;

export const description = styled(basicInput.description)`
  
`;

export const icon = styled(input.icon)`
`;