import {useRef} from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import setGlobalState from '../redux/setGlobalState';
import useEffectOnUpdate from './useEffectOnUpdate';

export default function useGlobalShow(name='',duration = 300) {
    const isOpen=useSelector(s=>s.ui.show[name]);
    const [showClass, setShowClass] = useState('');

    let timeout=useRef(null);

    const close =(e) => {
        if(!isOpen&&!showClass)
            return;
        if(e)
            e.stopPropagation();
        timeout.current = setTimeout(() => {
            setShowClass('');
        }, duration);
    };

    const open = () => {
        timeout.current = setImmediate(() => {
            setShowClass(' show');
        });
    };

    useEffectOnUpdate(()=>{
        if(isOpen)
            open();
        else
            close();
    },[isOpen]);
    
    useEffect(() => () => clearTimeout(timeout.current), []);

    return ({
        isOpen:showClass||isOpen, 
        showClass:isOpen&&showClass,
        open:()=>{
            if(!isOpen)
                setGlobalState(`ui.show.${name}`,true);
        },
        close:()=>{
            if(isOpen)
                setGlobalState(`ui.show.${name}`,false);
        }
    });
}

