import styled from 'styled-components/macro';
import {save, saveSuccess} from '../../components/saveAjax/SaveAjax.css';
import {text, container} from '../../../component/modeBtn/ModeBtn.css';
export const wrapMode = styled.div`
    display: flex;
    justify-content: flex-end;
  align-items: center;
    height: 60px;
  &.SUCCESS {
    ${container} {
      transition: all 800ms;
      opacity: 0;
    }
  }
  ${container} {
    opacity: 1;
    transition: all 800ms;
  }
  ${text}{
    margin-inline-end: 7px;
  }
  ${saveSuccess}{
    ${p=>p.theme.rtl?'right':'left'}: unset;
  }
  ${save}{
    background-image: unset;
  }
`;
