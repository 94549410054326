import * as css from './VerificationForm.css';
import {useTranslation} from 'react-i18next';
import {useState, useEffect} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import RoundCheckbox from '../../../../../component/roundCheckbox/RoundCheckbox';
import FileUpload from '../../../../../component/fileUpload/FileUpload';
import Btn from '../../../../../component/button/btn/Btn';
import {useSelector} from 'react-redux';
import store from '../../../../../store';
import setGlobalState from '../../../../../redux/setGlobalState';
import {updatePackageVerification} from '../../../../../redux/account/accountActions';
import {verifyBankDetails} from '../../../../../library/verifyBank';
import BankList from '../../bankList/BankList';
import fileExtension from '../../../../../library/fileExtension';
import {useHistory} from 'react-router-dom';
import {bounceMessage} from '../../../../../layout/userMessages/UserMessage';
import VerificationInput from './VerificationInput';
import {yupStringMin} from '../../../../../functions/validationsYup';

const onSubmit = async (values, tickArray, t, bankKey) => {
    values = JSON.parse(JSON.stringify(values));//Get copy of the object not reference
    values.bankNumber = bankKey;
    let radioFilled=true;
    for(var key in tickArray) {
        if(tickArray.hasOwnProperty(key)) {
            if(!tickArray[key] && key!='bankVerificationFile')
                radioFilled=false;
        }
    }
    if(!radioFilled){
        bounceMessage(t('vRequired'),'warning');
        return;
    }

    if(!verifyBankDetails(values.bankNumber, values.bankBranch, values.bankAccount)){
        bounceMessage(t('incorrectBankDetails'),'warning');
        return;
    }

    if(store.getState().ui.fileBase64){
        let file = store.getState().ui.fileBase64;
        values.bankVerificationFile = file.base64;
    }
    await updatePackageVerification(values);
};

const deleteFile = () => {
    setGlobalState('ui.fileBase64.base64', '');
    setGlobalState('ui.fileBase64.title', '');
};

function VerificationForm() {
    const {t} = useTranslation('package');
    const account=useSelector(s => s.account);
    const bank = account.bank;
    const file = useSelector(s => s.ui.fileBase64);
    const [tickArray, setTickArray] = useState({billingId: false, bankTransferOnBehalf: false, bankNumber: false, bankBranch: false, bankAccount: false, bankVerificationFile:false});
    const [bankKey, setBankKey] = useState();
    const tick = (key) => {
        setTickArray({...tickArray, [key]: tickArray[key] === 'checked' ? false : 'checked'});
    };
    Yup.addMethod(Yup.mixed, 'verified', function(keyName) {
        return this.test('verified', t('verifiedField'), function() {
            return  tickArray[keyName];
        });
    });
    const SignUpSchema = Yup.object().shape(
        {
            billingId: Yup.number().min(2).required().verified('billingId'),
            bankTransferOnBehalf: yupStringMin().max(100).required().verified('bankTransferOnBehalf'),
            bankBranch: Yup.number().min(0).required().verified('bankBranch'),
            bankAccount: Yup.number().min(2).required().verified('bankAccount'),
        }
    );

    const history = useHistory();
    const cancel = () => {
        history.push('/');
    };
    
    useEffect(() => {
        setGlobalState('ui.fileBase64.base64', bank?.bankVerificationFile);
        setGlobalState('ui.fileBase64.title',  bank?.bankVerificationFile && bank.bankVerificationFile!=''?'verification.'+fileExtension(bank.bankVerificationFile):'');
    }, [bank?.bankVerificationFile]);

    useEffect(() => {
        setBankKey(bank?.bankNumber);
    }, [bank?.bankNumber]);

    return (
        <Formik
            initialValues={
                {
                    billingId: bank?.billingId?bank.billingId:'',
                    bankNumber: bank?.bankNumber,
                    bankTransferOnBehalf: bank?.bankTransferOnBehalf?bank.bankTransferOnBehalf:'',
                    bankBranch: bank?.bankBranch?bank.bankBranch:'',
                    bankAccount: bank?.bankAccount?bank.bankAccount:'',
                    bankVerificationFile: bank?.bankVerificationFile,
                }
            }
            validationSchema={SignUpSchema}
            enableReinitialize={true}
            onSubmit={values => {
                onSubmit(values, tickArray, t, bankKey);
            }}
        >
            <Form>
                <css.inForm>
                    <VerificationInput name={'billingId'} text={t('idBn')} checked={tickArray.billingId} onChecked={tick} />
                    <VerificationInput name={'bankTransferOnBehalf'} text={t('companyName')} checked={tickArray.bankTransferOnBehalf} onChecked={tick} />
                    <BankList name={'bankNumber'} t={t} valid={tickArray.bankNumber} bankKey={(key)=>setBankKey(key)}>
                        <RoundCheckbox onClick={() => tick('bankNumber')} checked={tickArray.bankNumber} />
                    </BankList>
                    <VerificationInput name={'bankBranch'} text={t('branch')} checked={tickArray.bankBranch} onChecked={tick} />
                    <VerificationInput name={'bankAccount'} text={t('bankAccount')} checked={tickArray.bankAccount} onChecked={tick} />
                    <FileUpload name={'bankVerificationFile'} title={file.title && file.title !=''?file.title:t('uploadTitle')} desc={t('uploadDesc')} remove={deleteFile} value={file.base64} className={(tickArray.bankVerificationFile&&file.base64)?'valid':''}>
                        <RoundCheckbox onClick={() => tick('bankVerificationFile')} checked={tickArray.bankVerificationFile} />
                    </FileUpload>
                </css.inForm>
                <css.whiteBottom>
                    <css.anotherTime>
                        <Btn onClick={cancel}>{t('anotherTime')}</Btn>
                    </css.anotherTime>
                    <css.finishNow type="submit">
                        <Btn>{t('finishVerification')}</Btn>
                    </css.finishNow>
                </css.whiteBottom>
            </Form>

        </Formik>
    );
}

export default VerificationForm;