import {Link} from 'react-router-dom';
import * as css from './Item.css';
import {simpleArrow} from '../../assets/symbols/symbols/Symbols.css';
import Checkbox from '../checkbox/Checkbox';
import RadioBtn from '../radioBtn/RadioBtn';
import AccountPermission from '../../pages/components/accountPermission/AccountPermission';
import {useEffect, useRef} from 'react';

function Item(props) {
    const {index, handleOnClick, item, t = val => val,scrollToMe,close} = props;
    let content = {};
    content.svg = simpleArrow;
    let comp;
    const isLink = item.isLink ? item.isLink : false;

    if(item.icon)
        comp = <css.ItemContentIcon className='itemIcon' icon={item.icon} />;
    if(item.rightChildren)
        comp = item.rightChildren;

    let onClick = (e) => {
        e.stopPropagation();
        if(handleOnClick)
            handleOnClick(item.list ? index : item, e);
        if(!item.list)
            close();
    };

    switch (item.type) {
        case 'checkbox':
            onClick=() => { handleOnClick(item, !item.selected); };
            comp = <Checkbox color={item.color} onClick={selected => { handleOnClick(item, selected); }} selected={item.selected} classes={item.contentClasses ? item.contentClasses : 'listCheckbox'} />;
            break;
        case 'radioBtn':
            onClick=() => { handleOnClick(item, !item.selected); };
            comp = <RadioBtn color={item.color} selected={item.selected} onClick={(selected) => { handleOnClick(item, selected); }} classes={item.classes ? item.classes : 'listRadioBtn'} name={item.groupName} />;
            break;
        default:
            break;
    }

    const ref=useRef();
    useEffect(()=>{
        if(item.scrollToMe)
            scrollToMe(ref);
    },[]);

    const itemComp = 
    item.children? item.children :
        <css.Item data-cy={`${item.dataCyName||item.key||''}Item`} ref={ref} key={item.key || index} onClick={(!isLink && !item.disabled) ? onClick : null} className={(item.classes ? item.classes : '') + ' ' + (item.list ? ' arrow ' : '') + (item.disabled && ' disabled ')}>
            <css.ItemContent className='itemContent'>{comp}</css.ItemContent>
            <css.ItemText>{item.leftChildren || t(item.title || item.text)}</css.ItemText>
        </css.Item>;

    return (<>
        {isLink ? <div onClick={item.onClick?()=>item.onClick(item):()=>{}}> <Link to={item.to} id={item.title || item.text} target={item.targetBlank && '_blank'}>{itemComp}</Link></div> :
            item.permission ? <AccountPermission permission={item.permission}>{itemComp}</AccountPermission>
                : itemComp}
    </>
    );
}

export default Item;
