import * as css from './PixelBox.css';
import PixelIcon from '../pixelIcon/PixelIcon';
import * as pixelIcons from '../../../../assets/symbols/symbols/pixelIcons';
import * as pixelIconsColor from '../../../../assets/symbols/symbols/pixelIconsColor';
import {useTranslation} from 'react-i18next';
import Btn from '../../../../component/button/btn/Btn';
import {useSelector} from 'react-redux';
import PixelOptions from '../pixelOptions/PixelOptions';
import Input from '../../../../component/input/Input';
import {useState} from 'react';
import {useContext} from 'react';
import {editOnPopupPixels, pixelContext} from '../../PixelsPage/PixelsPage';

function PixelBox({pixel,submitForm,isEditMode,setIsEditMode}) {

    const XHR=useSelector(s=>s.publish.updateSpecialPixelXHR||s.publish.addSpecialPixelXHR);
    const [isDeleted,setIsDeleted]=useState(false);
    const {key,title,type}= pixel;
    const {getOpenPixel}=useContext(pixelContext);
    const open=getOpenPixel(pixel);
    
    const {t}=useTranslation('publish');
    
    const editClass=isEditMode?{className:'edit'}:{};
    const click=editOnPopupPixels.includes(pixel.key)?{onClick:open}:{};
    
    return (
        <css.container className={isDeleted?'deleted':''} data-cy={`${key ? key : 'default'}PixelBox`} {...click} >
            <css.right {...editClass} color={pixelIconsColor[key]} >
                <PixelIcon {...editClass} icon={pixelIcons[key]}/>
            </css.right>
            <css.left {...editClass} >
                <css.options>
                    {!isEditMode&&<PixelOptions setIsDeleted={setIsDeleted} {...{setIsEditMode,pixel}}/>}
                </css.options>
                <css.texts>
                    <css.title {...editClass} data-cy={'pixelTitle'}>
                        {title||(pixel.key==='facebookPixel'&&t('facebookPixel')||type)}
                    </css.title>
                    <css.text {...editClass}  >
                        <Input name="id" text={t('serialNumber')} disabled={!isEditMode} />
                    </css.text>
                </css.texts>
                <Btn className={`white ${XHR}`} onClick={submitForm}>
                    {t('save')}
                </Btn>
            </css.left>
        </css.container>
    );
}
export default PixelBox;
