import {useTranslation} from 'react-i18next';
import PackageSettings from '../packageSettings/PackageSettings';
import Accordion from '../accordion/Accordion';
import ClearingFees from '../clearingFees/ClearingFees';
import MailingSettings from '../mailingSettings/MailingSettings'
function SubscriptionScreen() {

    const {t} = useTranslation('package');

    return (<>
        <Accordion title={t('mySubscription')} dataCyTitle={'mySubscription'} forwardRef={undefined}>
            <PackageSettings />
        </Accordion>
        <Accordion title={t('clearingFeesAndTickets')} dataCyTitle={'clearingFeesAndTickets'} forwardRef={undefined}>
            <ClearingFees />
        </Accordion>
        <Accordion title={t('mailingSettings')} dataCyTitle={'mailingSettings'} forwardRef={undefined}>
            <MailingSettings />
        </Accordion>
    </>
    );
}
export default SubscriptionScreen;
