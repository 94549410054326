import {Field} from 'formik';
import React, {useEffect, useState} from 'react';
import * as css from './CheckboxFormik.css';

function CheckboxFormik({name, text,color, className=''}) {
    let timer=null;
    const [isBorder,setIsBorder]=useState(false);
    const onClick=()=>{
        setIsBorder(true);
        setTimeout(() => {
            setIsBorder(false);
        }, 300);
    };
    useEffect(()=>()=>clearTimeout(timer),[]);
    return (
        <css.wrapCheckbox className={`checkboxFormik ${isBorder && 'border'} ${className}`} onClick={onClick} color={color}>
            <Field type="checkbox" name={name} />
            <css.text>{text}</css.text>
        </css.wrapCheckbox>
    );
}
export default CheckboxFormik;
