import {useSelector} from 'react-redux';
import ticketColors from '../../../../assets/objects/TicketColors';
import * as css from './Ticket.css';

function Ticket({item, color}) {
    const ticketsIcon = useSelector(s => s.tickets.ticketsIcon);
    return (
        <css.ticket animalSymbol={ticketColors[item.color]?.[ticketsIcon]} color={'#' + color}/>
    );
}

export default Ticket;