import * as css from './EyeHideSection.css';
import {updateDesignEmail} from '../../../redux/branding/brandingActions';
import ConditionalWrapper from '../../../component/conditionalWrapper/ConditionalWrapper';
import {useSelector} from 'react-redux';
function EyeHideSection({children, sectionName}){
    const currentLang = useSelector(s=>s.branding.currentLang);
    const hideSection = useSelector(s=>s.branding.data[currentLang]?.hideSection);
    const showSection = (!hideSection || hideSection[sectionName]===undefined) ? true : hideSection[sectionName];

    return (
        <css.wrapper data-cy="eyeHideSection">
            <ConditionalWrapper data-cy={`hideSection-${sectionName}`} condition={!showSection} wrapper={children=><css.hide>{children}</css.hide>}>
                {children}
            </ConditionalWrapper>
            <css.eyeSection data-cy="eyeHideClick" onClick={()=>updateDesignEmail({section:sectionName, status:!showSection,hideSection:'hideSection'})} show={showSection} />
        </css.wrapper>
    );
}
export default EyeHideSection;
 