import {useRef} from 'react';
import * as css from './windowTemplate.css';

function WindowTemplate({settings, gridAreas, gridMoreStyle}) {
    const ref = useRef();

    return (
        <css.container ref={ref} windowWidth={((ref.current as any)?.offsetWidth || 1)+'px'} gridMoreStyle={gridMoreStyle} gridAreas={gridAreas}>
            {settings.map(element=><css.generalElement gridArea ={element.gridArea} key={element.gridArea} width={element.width} height={element.height} moreProps={element.moreStyle} />)}
        </css.container>
    );
}

export default WindowTemplate;
