import {Fragment, ReactNode} from 'react';
import Detail from '../../../../../../../tickets/components/ticketRow/Detail/Detail';
import Part from '../../../../../../../tickets/components/ticketRow/part/Part';
import * as css from './TicketMobilePart.css';

const TicketMobilePart = ({ticketOnClick, details, children} : {ticketOnClick: any, details: any[], children?: ReactNode}) =>{
    return (
        <Part field={'sold'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
            <css.detail>
                {children}
                {details.map((detail,index)=> {
                    return <Fragment key={index}>
                        <css.text>{detail.title}</css.text>
                        <Detail text='' value={detail.value} sign={detail.sign} className={`click ${detail.className}`}
                            isSymbol={detail.isSymbol} number={true}/>
                    </Fragment>;
                }
                )}
            </css.detail>
        </Part>
    );
   
};

export default TicketMobilePart;