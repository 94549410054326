import styled from 'styled-components/macro';
import {ex} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
export const groupEvents=styled.div`
    height:0;
    transition:all 300ms;
    overflow: hidden;
    max-height: 0;
    ${scrollbar};
    &.open{  
      max-height: 800px;
      height:${props=> props.height?props.height*80:0}px;
      ${mobileMedia}{
        height:${props=> props.height?props.height*125:0}px;
        max-height: 1250px;
       } 
    }
    &.dragStatus .row div{
       @media screen and (max-width: 1000px){
            /* pointer-events:none; */
       } 
    }
 
`;

export const closeGroup=styled.div`
   width:100% ;
   height:0;
   background-color:#${props=> props.color} ;
   border-radius:0 0 5px 5px;
   margin:5px 0;
   ${BGImageCenter(ex,'13px')};
   &.show{
   height:30px ;
 }
`;

