import createReducer from '../createReducer';

const initialState = {
    events:[],
    searchEvents:[],
    eventsByRole:[],
    isEventsFound:false,
    accounts:[],
    permissions:[],
    eventsReport:[],
    getEventsByRoleXHR:null,
    getEventsXHR:null,
    getAccountsXHR:null,
    deleteEventXHR:null,
    getEventsReportXHR:null
};

const reducer = createReducer('suggestions',initialState);

export default reducer;