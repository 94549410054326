import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useShow from '../../../hooks/useShow';
import {getEventGroups, getEventTickets} from '../../../redux/tickets/ticketsActions';
import store from '../../../store';
import PageWrapper from '../../components/pageWrapper/PageWrapper';
import TicketsArea from './ticketsArea/TicketsArea';
import TicketsPopupContent from '../ticketsPopUp/basicTicket/ticketsPopupContent/TicketsPopupContent';
import NoTicketsScreen from '../components/noTicketsScreen/NoTicketsScreen';
import {Else, If, Then} from 'react-if';
import {POPUP_DURATION} from '../../../globalVariables/GlobalVariables';
import {cardsPreview,  tablePreview} from '../../../assets/symbols/symbols/Symbols.css';
import getTicketsIconList, {ticketOptionsCssStyle} from '../functions/getTicketsIconList';
import useMobile from '../../../hooks/useMobile';
import TableContext from '../../components/tableContext/TableContext';

function Tickets() {
    const {t} = useTranslation('ticket');
    const {tickets,getEventTicketsXHR,ticketsIcon,getEventGroupsXHR} = useSelector(s => s.tickets);
    const [ticket, setPopUp] = useState({});
    const popShow = useShow(POPUP_DURATION);
    const [isTablePreview,setIsTablePreview]=useState(tickets?.length>4?true:false);
    const previewList={table:{icon:cardsPreview,text:t('cardsPreview')},cards:{icon:tablePreview,text:t('tablePreview')}};
    const currentPreview = previewList[isTablePreview?'table':'cards'];
    const ticketsOperations = [{
        key: 0, text: t('newTicket'), onClick: () => {
            newTicket();
        }
    }];
    const iconOperations = [{
        key: 1, icon:currentPreview?.icon,tooltipText:currentPreview?.text,color:'transparent', onClick: () => {
            setIsTablePreview(!isTablePreview);
        }
    }];

    const operations={buttons: ticketsOperations,
        icons: iconOperations,
        optionsList:getTicketsIconList(ticketsIcon,t),
        cssStyle: ticketOptionsCssStyle 
    };
    
    const newTicket = () => {
        setPopUp({});
        popShow.open();
    };
    const openPopUp = (ticket) => {
        setPopUp(ticket);
        popShow.open();
    };

    useEffect(() => {
        const fetchData = async () => {  
            if(!store.getState().tickets.tickets.length){
                getEventTickets().then(t=>{
                    if(t.length>4)
                        setIsTablePreview(true);
                }); 
            }  
            if(!getEventGroupsXHR)
                await getEventGroups();
        };
        fetchData()
            .catch(console.error);
    }, []);

    const isMobile=useMobile();

    useEffect(()=>{
        if(isMobile&&!isTablePreview)
            setIsTablePreview(true);        
    },[isMobile]);

    return (
        <TableContext data={tickets} searchColumns={['countExtra', 'price', 'title', 'soldPrice', 'sold']}>
            <PageWrapper image={'ticketsGroups'} operations={operations} className={'ticketsGroups'} >
                {<>
                    <If condition={tickets.length||getEventTicketsXHR==='LOADING'||!getEventTicketsXHR}>
                        <Then>
                            <TicketsArea tickets={tickets} isTablePreview={isTablePreview} openPop={(ticket) => openPopUp(ticket)}/>
                        </Then>
                        <Else>
                            <NoTicketsScreen onClick={newTicket}/>
                        </Else>
                    </If>
                    {popShow.isOpen && <TicketsPopupContent tid={ticket?.tid} close={popShow.close} showClass={popShow.showClass}/>}
                </>}
            </PageWrapper>
        </TableContext>
    );
}

export default Tickets;
