import styled from 'styled-components/macro';
import {BGImageCenter, ellipsis} from '../../globalVariables/GlobalVariables';
import {simpleArrow} from '../../assets/symbols/symbols/Symbols.css';
import * as inputClasses from '../classes/input';

export const container = inputClasses.container;

export const icon = styled(inputClasses.icon)`
`;

export const wrap = inputClasses.wrap;

export const input = styled(inputClasses.input)`
    position: relative;
    display: flex;
    align-items: center;
    ${ellipsis}
    ::after{
        content: "";
        position: absolute;
        width: 20px; 
        height: 20px;
        cursor: pointer;
        ${props=>props.theme.rtl?'left':'right'}:0px;
        top:5px;
        ${BGImageCenter(simpleArrow,'9px')}
        background-position: ${props=>props.theme.rtl?'left ':'right '} center;

    }
`;
export const title = inputClasses.title;