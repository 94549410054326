import * as css from './PixelIcon.css';

function PixelIcon({icon,className}) {

    return (
        <css.pixelCircle className={className}>
            <css.pixelIcon 
                icon={icon} 
                className={className}
            />
        </css.pixelCircle>
    );
}

export default PixelIcon;