import styled from 'styled-components/macro';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';
import {BRANDING_PAGE_WIDTH} from '../BrandingPage.css';

export const wrapper=styled.div`
    width: ${BRANDING_PAGE_WIDTH};
    max-width: 100%;
    ${mobileMedia}{
        padding: 7px;
    }
`;

export const top=styled.div`
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    width: 95%;
`;

export const text=styled.div`
    font-size: 15px;
`;