import * as css from './MobileTicketRow.css';
import {useEffect, useRef, useState} from 'react';
import useOnClickOutside from '../../../hooks/UseOnClickOutside';

function MobileDrag({children}) {

    const [isDrag, setIsDrag] = useState(false);
    //remember htmlescape
    let timer = null;
    const onTouchStart = () => {
        timer = setTimeout(() => {
            setIsDrag(true);
        }, 1000);
    };

    const onTouchEnd = () => {
        clearTimeout(timer);
    };

    useEffect(()=>{
        clearTimeout(timer);
    },[]);

    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        if(isDrag)
            setIsDrag(false);
    });

    return (
        <css.container ref={ref}  onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
            {children(isDrag)}
        </css.container>
    );
}
export default MobileDrag;
