import {useEffect} from 'react';
import * as css from './soldTickets.css';
import {useSelector} from 'react-redux';
import {getSoldTickets} from '../../../../../redux/realtime/realtimeActions';
import {useTranslation} from 'react-i18next';
import {DEFAULT_COLOR} from '../../../../../globalVariables/GlobalVariables';
import TicketProgress from '../../../../tickets/components/ticketRow/ticketProgress/TicketProgress';
import {formatRealtimeNumber} from '../../../functions';
import {eventTypes} from '../../../eventRealtime/eventTypes';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import SoldTicketsTemplate from './soldTicketTemplate';

function SoldTickets() {
    const {eid, currencySymbol, type: eventType} = useSelector((s: any)=>s.event);
    const allStats = useSelector((s:any) => s.realtime?.event);
    const stats = allStats.soldTickets;
    const eventConfig = eventTypes[stats?.isScheduled? 'scheduled': eventType].ticketsTitle || eventTypes.default.ticketsTitle;
    const {t} = useTranslation('realtime');
    const totalCash = allStats?.eventStats?.all?.totalPrice;
    const isLoading = useSelector((s:any)=>s.realtime?.event?.soldTicketsXHR === XHR_LOADING_STATE);
    
    useEffect(()=>{
        if(eid) getSoldTickets(eid);
    },[eid]);
    
    if(isLoading) {
        return <SoldTicketsTemplate />;
    }
    return ( stats ? <css.container>
        <css.content>
            {eventConfig.soldTitle && <css.section>
                <css.sectionTitle>{t(eventConfig.soldTitle)}</css.sectionTitle>
                <css.amountBold>{formatRealtimeNumber(stats.sold || 0, true)} <css.units>{t('ticketPcs')}</css.units></css.amountBold>
                
            </css.section>}
            <css.pas />
            {eventConfig.leftTitle && <css.section>
                <css.sectionTitle>{t(eventConfig.leftTitle)}</css.sectionTitle>
                <css.amount>{formatRealtimeNumber(stats.available || 0, true)} <css.units>{t('ticketPcs')}</css.units></css.amount>
            </css.section>}
            
        </css.content>
        <css.subtext className="free-color"> {t(eventConfig.amountTitle, {amount: formatRealtimeNumber(stats.amount || 0, true)})}</css.subtext>
        <css.insights>
            <css.insightsNumbers eventType={eventType}>
                {eventConfig.purchaseProcess && <css.insightsItem>
                    <css.insightsNumber className="free-color">{stats.process || 0}</css.insightsNumber>
                    <span>{t(eventConfig.purchaseProcess)}</span>
                </css.insightsItem>}
                {eventConfig.free && <css.insightsItem>
                    <css.insightsNumber className="free-color">{stats.free || 0}</css.insightsNumber>
                    <span>{t(eventConfig.free)}</span>
                </css.insightsItem>}
                {eventConfig.close &&                     <css.insightsItem>
                    <css.insightsNumber className="free-color">{stats.closed || 0}</css.insightsNumber>
                    <span>{t(eventConfig.close)}</span>
                </css.insightsItem>
                }
                {eventConfig.saved &&  <css.insightsItem>
                    <css.insightsNumber className="free-color">{stats.saved || 0}</css.insightsNumber>
                    <span>{t(eventConfig.saved)} {stats.savedTo && t('savedTo',{name: stats.savedTo})}</span>
                </css.insightsItem>
                }
                {eventConfig.averagePricePerTicket && <css.insightsItem>
                    <css.insightsNumber className="free-color">{currencySymbol}{formatRealtimeNumber(totalCash/(stats.sold ||1)) || 0}</css.insightsNumber>
                    <span>{t(eventConfig.averagePricePerTicket)}</span>
                </css.insightsItem>}
            </css.insightsNumbers>
            <css.progressWrapper className='free-color' >
                <TicketProgress ticket={{color: DEFAULT_COLOR, amount: (stats.amount), sold: stats.sold}} onClick={()=>{}} styleProps={{strokeTrailWidth: '0'}} >
                    <css.goalText>{t('goalText')}</css.goalText>
                </TicketProgress>
            </css.progressWrapper>
        </css.insights>
    </css.container>:<></>
    );
}
export default SoldTickets;