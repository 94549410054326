import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../component/button/button/Button';
import DisabledInput from '../../../../../component/input/disabledInput/DisabledInput';
import FormikModeBtn from '../../../../../component/formikModeBtn/FormikModeBtn';
import Input from '../../../../../component/input/Input';
import InputList from '../../../../../component/input/inputList/InputList';
import PopOnPop from '../../../../../component/newPopup/popOnPop/PopOnPop';
import {addBtn, closeBtn, editBtn} from '../../../../../functions/globalBtns';
import {arrayHelpers} from '../courseFields/CourseFields';
import * as css from './CourseFieldsPop.css';
import * as Yup from 'yup';
import ListField from '../listField/ListField';
import {useRef} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {yupStringMin} from '../../../../../functions/validationsYup';

function CourseFieldsPop({showPopOnPop, currentIndex, courseFields = []}) {
    const {t} = useTranslation('ticket');
    const refParent = useRef(null);
    const ref = useRef(null);
    const [currentInput,setCurrentInput]=useState(null);

    const initialPopOnPop = currentIndex === -1 ? {active: true, type: 'text',options:null} : {...courseFields[currentIndex]};
    const validationSchema = Yup.object().shape(
        {
            title: yupStringMin().required(),
        }
    );
    const fieldType=courseFields[currentIndex]?.type;
    let isTypeName=['firstName', 'lastName'].includes(fieldType);
    const typeList = isTypeName?[{key: fieldType, text: fieldType}]:[{key: 'text', text: 'text'}, {key: 'list', text: 'list'}];
    const {setShow} = showPopOnPop;

    const onAdd = (values) => {
        const _values={...values};
        if(values.options?.list&&values.options.list[values.options?.list.length-1]==='')
            _values.options.list.pop();
        if(currentIndex === -1)
            arrayHelpers.push({active: true, required: false, type: 'text', ..._values});
        else
            arrayHelpers.replace(currentIndex, {..._values});
        setShow(false);

    };

    useEffect(()=>{
        if(!ref.current)
            return;
        setTimeout(function(){
            const offset = ref.current.getBoundingClientRect();
            const topPosition = offset.top;
            refParent.current.scroll({top: topPosition, left: 0, behavior: 'smooth'});
        },500);
    },[currentInput]);

    return (
        <>
            <PopOnPop dataCy='courseFields' image={'desktop'} title={t('')} showPopOnPop={showPopOnPop}>
                <Formik
                    initialValues={initialPopOnPop}
                    validationSchema={validationSchema}
                    render={({values,setFieldValue}) => (
                        <Form>
                            <css.popOnPopupContent ref={refParent}>
                                <css.mode>
                                    <FormikModeBtn name={'active'} className='small' />
                                </css.mode>
                                <InputList onChange={(_name,key)=>{
                                    if(key==='list')
                                        setFieldValue('options',{list:[],Alphabet:false});
                                    else setFieldValue('options',null);
                                }} t={t} list={typeList} name={'type'} text={t('fieldType')} description={t('fieldTypeDescription')} />
                                <Input dataCy={'fieldName'} forwardRef={currentInput==='title'?ref:null} events={{onFocus:()=>setCurrentInput('title')}} name={'title'} text={t('fieldName')} description={t('fieldNameDescription')} />
                                <DisabledInput text={t('requiredField')} description={t('requiredFieldDesc')}>
                                    <css.required>
                                        {t('requiredField')}
                                        <FormikModeBtn forwardRef={currentInput==='mode'?ref:null} click={()=>setCurrentInput('mode')} name={'required'} className='small' />
                                    </css.required>
                                </DisabledInput>
                                <ListField refParent={refParent}/>
                            </css.popOnPopupContent>
                            <css.bottomPopup>
                                {(currentIndex !== -1&& !isTypeName) && <css.deleteButton onClick={() => { arrayHelpers.remove(currentIndex); setShow(false); }} />}
                                <Button BtnProps={closeBtn(t, () => setShow(false)) } dataCyName="close"  />
                                <Button BtnProps={currentIndex !== -1 ? editBtn(t, () => onAdd(values)) : addBtn(t, () => onAdd(values))} dataCyName="done"
                                    disabled={!values.title || (values.type === 'list' &&  (!values.options?.list?.length||(values.options?.list[0]===''&&values.options?.list?.length===1)))} />
                            </css.bottomPopup>
                        </Form>)
                    } />
            </PopOnPop>
        </>);

}

export default CourseFieldsPop;