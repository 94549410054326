import styled from 'styled-components/macro';
import convertHexToRgbA from '../../../../../functions/ConvertHexToRgbA';
import {BGImageCenter, DEFAULT_COLOR} from '../../../../../globalVariables/GlobalVariables';

export const circle = styled.div`  
  background-color:${p=>p.color?p.color:DEFAULT_COLOR} ;
  border-radius:50%;
  width:22px;
  height:22px ;
  cursor: pointer;
  ${p=>BGImageCenter(p.icon,'9px')}
  transition:all 200ms ;
  :hover{
    box-shadow:0px 0px 0px 5px rgba(${p => convertHexToRgbA(p.color?p.color:DEFAULT_COLOR)}, 0.4);
  }
  &.disabled{
    opacity:0.6 ;
    pointer-events:none;
    cursor: unset;
  }

`;