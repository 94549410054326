import ClusterMobile from '../clusterMobile/ClusterMobile';
import {getChildEventStats} from '../../../../redux/cluster/clusterActions';
import MobileDrag from '../../../../component/newTable/mobileTicketRow/MobileDrag';

function MobileClusterRow({event, openEditPopup, setShowDetails, checked}) {

    const openDetailPopUP = async () => {
        setShowDetails(1);
        await getChildEventStats(event.eid);
    };

    return (
        <MobileDrag>
            {(isDrag) =>
                <ClusterMobile event={event} isDrag={isDrag} openEditPopup={openEditPopup} openPopup={openDetailPopUP} checked={{isSelectable:checked?.isSelectable}} />
            }
        </MobileDrag>
    );
}
export default MobileClusterRow;
