import * as css from './Explanation.css';
import {useTranslation} from 'react-i18next';
import Close from '../../../component/close/Close';

function Explanation(props) {
    const {name = 'newsletter' ,showClass,close} = props;
    const {t} = useTranslation('event');

    return (
        <css.container className={showClass} data-cy={`${name}Explanation`}>
            <css.close>
                <Close size="10px" onClick={close} className=" white" />
            </css.close>
            <css.text>{t(`${name}Desc`)}</css.text>
        </css.container>
    );
}

export default Explanation;
