import styled from 'styled-components/macro';
import {mobileMedia} from '../../globalVariables/GlobalVariables';

export const expanded = styled.div`
    width: 100%;
    background-color: #222222;
    inset-inline-start:0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    max-height: 0;
    &.show{
        overflow:visible ;
        opacity: 1;
        max-height: 450px;
        transition: all 0.25s  ease-in;
        ${mobileMedia}{
            max-height: 1500px;
        } 
    }
    &.overflowHidden{
        overflow:hidden;
    }
 
`;
