import RightPart from './rightPart/RightPart';
import LeftPart from './leftPart/LeftPart';
import TicketRow from './TicketRow';
import TicketsPopupContent from '../../ticketsPopUp/basicTicket/ticketsPopupContent/TicketsPopupContent';
import Part from './part/Part';
import Detail from './Detail/Detail';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getTicketPrice} from '../ticketBox/TicketBox';

function ClassicTicketRow({ticket}) {
    const {t} = useTranslation('ticket');
    const currencySymbol = useSelector(state => state.event.currencySymbol);

    return (
        <TicketRow TicketPopup={TicketsPopupContent} ticket={ticket}>
            {(popupShow)=>(
                <>
                    <RightPart ticket={ticket} openPopup={popupShow.open} />
                    <Part padding={'0'} field={'price'}>
                        <Detail text={t('price')} value={getTicketPrice(ticket)} isSymbol sign={currencySymbol}/>
                    </Part>
                    <Part field={'amount'}>
                        <Detail text={t('amount')} value={ticket.amount} sign={t('ticketPcs')}/>
                    </Part>
                    <Part field={'sold'}>
                        <Detail text={t('sold')} value={ticket.sold} sign={t('ticketPcs')}/>
                    </Part>
                    <Part field={'soldPrice'}>
                        <Detail text={t('sale')} value={ticket.soldPrice} sign={currencySymbol} isSymbol/>
                    </Part>
                    <Part padding={'0 2%'} cssStyle={'border-inline-end:none;'} field={'countExtra'}>
                        <Detail text={t('extras')} value={ticket.countExtra}/>
                    </Part>
                    <LeftPart ticket={ticket} courseShow={popupShow}/>
                </>
            )}
        </TicketRow>
    );
}
export default ClassicTicketRow;
