import styled from 'styled-components/macro';
import {BGImageCenter, DEFAULT_COLOR, ellipsis, ellipsisLines, LIGHT_BLUE_GRAY, mobileMedia, OLIVE_GREEN, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {btn} from '../../../../component/button/btn/Btn.css';
import {blackSuccess} from '../../../../assets/symbols/symbols/Symbols.css';

export const container=styled.div`
    position: relative;
    height: 280px;
    width: 180px;
    border: 1px solid ${DEFAULT_COLOR};
    border-radius: 5px;
    margin: 20px;
    text-align: center;
    padding: 15px;
    line-height: 1.5;
    ${btn}{
        margin: 8px auto 0 auto;
    }
    &.selected{
        background-color: ${LIGHT_BLUE_GRAY};
    }
`;
export const checkbox = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid ${OLIVE_GREEN};
    ${BGImageCenter(blackSuccess, '6px 4px')}
    transition: all 400ms;
    &.selected{
        width: 20px;
        height: 20px;
        background-color: ${OLIVE_GREEN};
    }
`;
export const title = styled.div`
    font-size: 16px;
    font-weight: 700;
    width: max-content;
    margin: auto;
    margin-bottom: 4px;
    ::after{
        content: '';
        display: block;
        inset-block-start: 4px;
        width: calc(100% - 20px);
        position: relative;
        margin: auto;
        border-bottom: 2px solid ${DEFAULT_COLOR};
        border-radius: 5px;
    }
`;

export const detail = styled.div`
    font-size: 12px;
    ${ellipsis}
    >span{
        font-size: 10px;
    }
`;
export const price = styled.div`
    font-size: 18px;
    font-weight: 700;
`;
export const packageDetails = styled.div`
    font-size: 16px;
    font-weight: 500;
`;
export const detailsTitle = styled.div`
    ${ellipsisLines(2)}
    position: relative;
    margin-block: 3px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
`;
export const popupContent = styled.div`
    height: calc(100% - 170px);
    padding-top:60px;
    padding-right:100px;
    padding-left:100px;
    font-size: 16px;
    font-weight: 500;
    white-space: pre-line;
    ${mobileMedia} { 
        text-align: center;
        margin-top:100px;
        margin-bottom: 50px;
        padding:15px;
        height: calc(100% - 340px);
        padding-top: 0;
        ${scrollbar}
    }
`;