import styled from 'styled-components/macro';
import * as OptionsList from '../../../../../component/optionsList.js/OptionsList.css';
import {dFlexCenter} from '../../../../../globalVariables/GlobalVariables';

export const text = styled.div`
    font-size:16px;
    width: 120px;
    
`;

export const wrapMode = styled.div`
    ${dFlexCenter}
    
`;
export const modeWidth = styled.div`
    width:90px;
    
`;

export const container = styled.div`
  display:flex ;
  flex-flow: wrap;
  align-items:center ;
  width:100%; 
`;
export const plus = styled(OptionsList.plus)`
    margin:4px;
`;
export const wrapText = styled(OptionsList.wrapText)`
  
`;

export const values = styled(OptionsList.values)`
  
`;
