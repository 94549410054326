import styled from 'styled-components/macro';
export const Resize = styled.div`
    ${props=>props.theme.Horizontal?  
    '    height:40px;' +  
    '    display: flex;' +
    '    align-items: center;' +
    '    justify-content: center;':''}
    width: ${props=>props.width};
`;
export const ResizeBase = styled.div`
    width: ${props=>props.theme.Horizontal?props.width:'3'}px;
    height: ${props=>props.theme.Horizontal?'3':props.width}px;    
    background: #ccc;
    position: relative;
    margin: 0 auto;
    border-radius: 10px;
`;
export const ResizeProgress = styled.div`
     ${props=>props.theme.Horizontal?'width':'height'}: ${p=>(p.position)}%;
     ${props=>props.theme.Horizontal?'height':'width'}: 3px;
    transition: height 0.1s ease-out;
    top:0px;
    position:relative;
    background: #01acf1;
      border-radius: 10px;
`;
export const ResizeDrag = styled.div`
      ${p=>p.theme.Horizontal?(p.theme.rtl?'right:':'left:'):'top:'} calc(${p=>(p.position)}% - 10px);
      transition: left 0.1s ease-out;
      ${p=>p.theme.Horizontal?'top':'right'}:-9px;
      position: absolute;
      border-radius: 50%;
      box-shadow: 0 0 7px 4px #00000029;
      height: 4px;
      width: 4px;
      background: #01acf1;
      border: 8px solid #fff;
      box-sizing: content-box;
      cursor: grab;
      :active{
            cursor: grabbing;
      }
`;
export const ResizeBtn = styled.div`
    width: 20px;
    font-size: 20px;
    font-weight:500 ;
    height: 20px;
    line-height: ${props=>props.theme.Horizontal?'16px':'0px'};
    cursor: pointer;
    text-align: center;
    user-select: none;
    margin: 0 auto;
    
    &.plus{
        /* font-size: 26px; */
        line-height: ${props=>props.theme.Horizontal?'20':'36'}px;
        /* ${props=>props.theme.Horizontal?'margin-right:10px':''}; */
    }
    &:not(.plus){
       /* ${props=>props.theme.Horizontal&&'transform:rotate(90deg)'}; */
       /* ${props=>props.theme.Horizontal?'margin-left:10px':''}; */
    }
`;