import {useSelector} from 'react-redux';
import * as css from './CourseRowDetails.css';
import {useTranslation} from 'react-i18next';
import {getTicketPrice} from '../../../components/ticketBox/TicketBox';
import CourseGuides from '../courseGuides/CourseGuides';
import * as cssDetailsPart from '../../../components/ticketRow/detailsPart/DetailsPart.css';
import {Else, If, Then} from 'react-if';

export const getDays = (meetings,t) => {
    return meetings?.map((item, index) => `${(index >0 ? ',' : '') + t.t(`day${item.day}`)}`);
};

function CourseRowDetails({ticket}) {
    const {t} = useTranslation('ticket');
    const globalT = useTranslation('global');
    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const price = getTicketPrice(ticket);
    const meetingsSun = ticket.course?.meetings?.reduce((accumulator, object) =>  accumulator + object.amount , 0);
    const days=getDays(ticket.course?.meetings,globalT);
    const isMissing=ticket?.draft && !days;

    return (
        <cssDetailsPart.wrap>
            <css.block>
                <css.text><css.boldText>{ticket.countExtra || 0}&nbsp;</css.boldText>{t('extensions')}</css.text>
                <css.text>
                    <css.boldText className='noWarp'>
                        <If condition={ticket.draft && !ticket.prices.length}>
                            <Then>{t('missingPrice')}&nbsp;</Then>
                            <Else>
                                <Then>{price || 0}&nbsp;</Then>
                            </Else>
                        </If>
                    </css.boldText>
                    {currencySymbol}
                </css.text>
            </css.block>
            <css.block>
                <css.text>
                    <css.boldText>
                        <If condition={ticket.draft && !meetingsSun}>
                            <Then>{t('missing')}&nbsp;</Then>
                            <Else>
                                <Then>{meetingsSun || 0}&nbsp;</Then>
                            </Else>
                        </If>
                    </css.boldText>
                    {t('meetings')}
                </css.text>
                <css.text reverse={isMissing}>
                    {(ticket.course?.meetings?.length<2?t('day'):t('days'))}
                    <css.boldText>{isMissing?<>{t('missing')}&nbsp;</>:<>{days}&nbsp;</>}</css.boldText>
                </css.text>
            </css.block>
            {ticket?.users? <CourseGuides guides={ticket?.users} openList={true}/>: <css.image>{t('missing')}</css.image>}
            
        </cssDetailsPart.wrap>
    );
}
export default CourseRowDetails;