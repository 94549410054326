import React, {useState} from 'react';
import * as css from './DetailInput.css';
import validation from './validation';

function DetailInput(props) {
    const {onBlur=()=>null,className}=props;
    const [valid, setValid] = useState(0);
    const {handleInputValid, index=null, item, doOnfocus=false, setFocus=false} = props;

    const validInput = (e) => {
        if(setFocus)
            setFocus(0);
        if(item.validation && handleInputValid && e.target.value) {
            if(validation(item.validation.type, e.target.value)) {
                handleInputValid(item, 1);//not valid
                setValid(0);
            }
            else {
                handleInputValid(item, 0);//valid
                setValid(1);
            }
        }
    };

    const handleOnChange = (e) => {
        if(props.onChange)
            props.onChange(e);
    };

    return (
        <css.detail className={'detail ' + className}>
            <css.detailInput dataDesc={item.description} dataText={item.text} className={(!item.value && ' defaultInput') + (' dInput') + (!!item.notValid && ' notValid')} validationText={item.validation?.text}>
                <css.diIcon className={(!!item.notValid && ' notValid')+ ' '+ className} icon={item.icon} />
                <css.isValid className={(!!item.notValid && ' notValid')} />
                <input value={item.value} name={item.name} className={'deInput ' + (!!item.notValid && ' notValid ') + (!item.value && ' defaultInput')}
                    onFocus={() => doOnfocus && doOnfocus(item, index)} onBlur={(e) => {validInput(e, item); onBlur(e);}} onChange={handleOnChange} />
                <css.valid className={valid && 'valid'} />
            </css.detailInput>
        </css.detail>
    );
}
export default DetailInput;
