/* eslint-disable spellcheck/spell-checker */
import styled, {css} from 'styled-components/macro';
import {BGImageCenter, DEFAULT_COLOR, dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {op, pen} from '../../../../assets/symbols/symbols/Symbols.css';

const RIGHT_PART_WIDTH=45;
const PART_WIDTH=8;
const LEFT_WIDTH=100-RIGHT_PART_WIDTH-(5*PART_WIDTH);

export const cssTicketColumns=css`
    .defaultColumn{
        width: ${PART_WIDTH}%;
    }
    .titleColumn{
        width: ${RIGHT_PART_WIDTH}%;
        min-width:${RIGHT_PART_WIDTH}%;
    }
    .lastColumn{
        width: ${LEFT_WIDTH}%;
    }
`;

export const row = styled.div`
    ${cssTicketColumns}
    width:100% ;
    display: flex;
    height:70px ;
    transition:all 300ms;
    &.delete{
        /* margin-top:-70px ; */
        /* transform:scaleY(0) ; */
        opacity:0 ;
    }
    :hover{
        .circleWrap{
            transition:background-color 400ms ;
            background-color: #aaa;
            /* background-color: ${DEFAULT_COLOR}; */
            ${BGImageCenter(pen,'16px'  )}
            >div{
            display:none;
            }
        }

    }
    `;

export const boldText = styled.div`
    font-weight:600 ;
    font-size:14px ;
    margin-inline-start: 7px;
`;
export const text = styled.div`
    display:flex ;
    font-size:12px ;
    line-height:15px ;
`;

export const options = styled.div`
    position:absolute ;
    inset-inline-end:3%;
    width:20px;
    height:20px;
    >div{
            background-size: 14px !important;
        }
        ${BGImageCenter(op, '10px')}

`;

export const left=styled.div` 
    width: 100%;
    ${dFlexCenter}
    justify-content: flex-end;
`;
