import React, {useEffect} from 'react';
import {GoogleMap, LoadScript, Marker, StandaloneSearchBox} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import * as css from './GoogleMaps.css';
import {useCallback} from 'react';
import useEffectUpdate from '../../hooks/useEffectUpdate';
import {GOOGLE_KEY} from '../../config/config';
import {useRef} from 'react';
import Input from '../input/Input';

const DEFAULT_LOCATION = {lat:31.786961, lng:35.190009};

function GoogleMaps({locationMapName, place, zoom = 17,mapStyle = {width: '100%',height: '100%'},inputStyle = '',locationInputProp={},children}) {
  
    const {t} = useTranslation('global');

    const {placeName,placeText,description}= place;

    const [placeField,,placeHelpers]=useField(placeName);
    const [locationField,,locationHelpers]=useField(locationMapName);

    const getLocationMap = ()=> {
        if(locationField?.value)
            return {lat:locationField?.value?.lat,lng:locationField?.value?.lng};
        else 
            return DEFAULT_LOCATION;
    };

    const searchBoxRef= useRef(null);

    const setValues = (result) =>{
        locationHelpers.setValue({
            lat: parseFloat(result.geometry.location.lat()),
            lng: parseFloat(result.geometry.location.lng()),
            title:result.formatted_address,
            googlePlaceId:result.place_id});
    };

    const geocoderFunction = (type)=>{
        const geocoder =new window.google.maps.Geocoder();
        geocoder.geocode(type, (results, status) => {
            if(status === 'OK') {
                if(results[0]) {
                    setValues(results[0]);
                } 
            } 
        });
    };

    const onPlacesChanged = () => {
        if(searchBoxRef.current != null) {
            const places = searchBoxRef.current.getPlaces();
            setValues(places[0]);
        }
    };

    const onClickMap = (e) => {
        geocoderFunction({location: e.latLng});
    };

    useEffectUpdate( useCallback(()=>{
        if(placeField?.value)
            geocoderFunction({address: placeField.value});
    }, [placeField?.value]));

    useEffect(()=>{
        placeHelpers.setTouched(locationField?.value?.title && !!placeField?.value);
    },[placeField?.value,locationField?.value.title]);

    return (
        <LoadScript
            googleMapsApiKey = {GOOGLE_KEY}
            libraries={['places']}
        >
            <css.wrapInput inputStyle={inputStyle}>
                {place && <Input name={placeName} text={placeText} description={description||''} icon='location'/> }
                <StandaloneSearchBox
                    onLoad={(ref)=>searchBoxRef.current = ref}
                    onPlacesChanged={onPlacesChanged}  
                > 
                    <Input name={`${locationMapName}.title`} 
                        text={t('chooseAddress')}
                        description={t('chooseAddressDesc')} 
                        icon='address'
                        dataCy='locationTitle'
                        {...locationInputProp}
                    />
                </StandaloneSearchBox>  
            </css.wrapInput> 
            {children}
            <GoogleMap
                mapContainerStyle={mapStyle}
                center={getLocationMap()||DEFAULT_LOCATION}
                zoom={zoom}
                options={{
                    streetViewControl:false,
                    mapTypeControl:false,
                    
                }}
                onClick={onClickMap}
            >
                <Marker position={getLocationMap()||DEFAULT_LOCATION} />
            </GoogleMap>  
        </LoadScript>
    );
}

export default GoogleMaps;
