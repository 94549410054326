import {useEffect, useState} from 'react';
import * as css from './Tabs.css';
import List from '../list/List';
import useShow from '../../hooks/useShow';
import {useTranslation} from 'react-i18next';

function Tabs({list,listTitle,countTabs,currentTab,onClick}) {
    const {t} = useTranslation('layout');

    const {open, close, isOpen, showClass} = useShow(500);
    const [moreTabs, setMoreTabs] = useState([]);

    const moreClick=()=>{
        open();
    };
    const tabClick=(item,more)=>{
        const finalList = [];
        if(more){
            list.map((lItem,i)=>{
                if(i === 0) {
                    finalList.push(lItem);
                    finalList.push(item);
                } else {
                    if(lItem.key!==item.key)
                        finalList.push(lItem);
                }
            });
        }
        onClick(more&&finalList,item.key);
        close();
    };
    useEffect(()=>{
        if(currentTab>=countTabs)
            tabClick(list.filter(item=>item.key===currentTab)[0],1);
    },[]);

    useEffect(()=> {
        setMoreTabs(list?.slice(countTabs,list.length));
    }, [list,countTabs]);

    return (
        <css.Tabs>
            {list?.map((item, index) => {
                if(index < countTabs)
                    return <css.tabChild key={item.key} data-cy={`${item.type}Tab`} className={'TabChild ' + (item.key === currentTab && 'current')} onClick={()=> tabClick(item)}>{t(item.text)}</css.tabChild>;
            })}
            {!!moreTabs.length && <css.TabMore  onClick={()=>moreClick(1)}>
                {isOpen&&<List close={close} t={t} showClass={showClass} title={listTitle} list={moreTabs} top={5} left={'15px'} onClick={(item)=>tabClick(item,1)}/>}
            </css.TabMore>  }
        </css.Tabs>
    );
}

export default Tabs;
