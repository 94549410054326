import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import * as css from './Menu.css';
import useOnClickOutside from '../../hooks/UseOnClickOutside';
import EventMenu from '../eventMenu/EventMenu';
import {Else, If, Then} from 'react-if';
import AccountMenu from '../accountMenu/AccountMenu';

//for mobile only

function Menu({updateLayout, close, children}) {
    const event = useSelector(state => state.event);
    const [showMenu, setShowMenu] = useState(0);
    const refMenu = useRef();
    const closeMenu = () => {
        setShowMenu(0);
        updateLayout(0);
        setTimeout(function () {
            close();
        }, 400);
    };
    useOnClickOutside(refMenu, closeMenu);
    useEffect(() => {
        setShowMenu(1);
    }, []);

    return (
        <css.Menu ref={refMenu} className={!!showMenu && 'show'}>
            <css.MenuTop>
                {children}
            </css.MenuTop>
            <If condition={event.eid}>
                <Then>
                    <EventMenu closeMenu={closeMenu} />
                </Then>
                <Else>
                    <AccountMenu closeMenu={closeMenu}/>
                </Else>
            </If>
        </css.Menu>
    );
}

export default Menu;
