import Rating from '../rating/Rating';
import {useLocation} from 'react-router-dom';
import UpgradePermissionPopup from '../upgradePermissionPopup/UpgradePermissionPopup';
import useShow from '../../../hooks/useShow';
import Package from '../../package/pages/Package';
import {packageList} from '../../../layout/events/packageManagement/PackageManagement';
import Security from '../security/Security';
import Welcome from '../../login/signIn/Welcome/Welcome';
import UserMessage from '../../../layout/userMessages/UserMessage';
import UserMessageBlock from '../../../layout/userMessagesBlock/UserMessageBlock';
import VerificationAccountPopup from '../../popups/verificationAccount/VerificationAccountPopup';
import TeamMemberPop from '../../team/teamMemberPop/TeamMemberPop';
import {POPUP_DURATION} from '../../../globalVariables/GlobalVariables';
import EasyCourseFirstScreen from '../../createCourseEvent/easyCourseFirstScreen/EasyCourseFirstScreen';
import useGlobalShow from '../../../hooks/useGlobalShow';
import TickchakLoader from '../../../component/tickchakLoader/TickchakLoader';
import DesignPopup from '../../../component/designPopup/DesignPopup';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
// import DesignLandingPopup from '../../../component/designLandingPopup/DesignLandingPopup';

function UniversalPopups() {
    const location = useLocation();
    let params = new URLSearchParams(location.search);
    const packagePage = params.get('packagePage');
    const verificationPop = params.get('verificationPop');
    const teamMemberPop = params.get('teamMemberPop');

    const packageShow = useShow();
    const teamAboutShow = useShow(POPUP_DURATION);
    const openCourseStart = useGlobalShow('isOpenEasyCourseStart',POPUP_DURATION);
    const showLoader = useGlobalShow('isOpenTickchakLoader');
    const showDesignPop = useGlobalShow('isOpenDesignPop');
    const verificationShow = useShow();
    const history = useHistory();

    useEffect(() => {
        if(!packagePage)
            return;
        if([...packageList,{text:'team'}].some(p => p.text === packagePage)) 
            packageShow.open();       
        else {
            if(packageShow.isOpen)
                packageShow.close();
            history.push(location.pathname);
        }  
    }, [packagePage]);

    useEffect(() => {
        if(verificationPop && verificationPop==='open')
            verificationShow.open();
    }, [verificationPop]);

    useEffect(() => {
        if(teamMemberPop){
            teamAboutShow.open();
        }
    }, [teamMemberPop]);

    return (
        <>
            {showDesignPop.isOpen&& <DesignPopup show={showDesignPop}/>}
            {showLoader.isOpen&& <TickchakLoader showClass={showLoader.showClass} cssStyle={'position:fixed ;'}/>}
            <UserMessage/>   
            <UserMessageBlock/>
            <Welcome />
            <Security/>
            {packageShow.isOpen && <Package close={packageShow.close} showClass={packageShow.showClass}/>}
            {verificationShow.isOpen && <VerificationAccountPopup show={verificationShow}/>}
            {teamAboutShow.isOpen && <TeamMemberPop showClass={teamAboutShow.showClass} close={teamAboutShow.close}/>}
            {openCourseStart.isOpen && <EasyCourseFirstScreen openPop={openCourseStart}/>}
            <Rating />
            <UpgradePermissionPopup />
            {/* <DesignLandingPopup /> */}
        </>
    );
}
export default UniversalPopups;
