import React, {useEffect, useRef, useState} from 'react';
import * as css from './LayoutButtons.css';
import Boxoffice from '../boxoffice/Boxoffice';

function LayoutButtons() {
    return (
        <css.LayoutButtons>
            <Boxoffice />
        </css.LayoutButtons>

    );
}

export default LayoutButtons;