import styled from 'styled-components/macro';
import {BGImageCenter, ellipsisLines, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {container,charNum} from '../../../../component/input/textareaInput/TextareaInput.css';
import {container as containerBasicInput, textWrap, titleWrap, icon as textAreaIcon, title} from '../../../../component/input/basicInput/BasicInput.css';
import {btn} from '../../../../component/button/btn/Btn.css';
import {tooltip} from '../../../../component/tooltip/tooltipAbsolute/TooltipAbsolute.css';
import {gptDescription} from '../../../../assets/symbols/symbols/newEventIcons';
import {context} from '../stepContext/StepContext.css';
import {wrapper as wRight} from '../stepRight/StepRight.css';
import {wrapper as wLeft} from '../StepLeft/StepLeft.css';
import zIndex from '../../../../globalVariables/zIndex';

export const wrapper=styled.div`
    width: 100%;
    position: relative;
    ${btn}{
        position: absolute;
        top: 13px;
        inset-inline-end: 25px;
        z-index: ${zIndex.baseHighest};
        background-color: #FFFFFF;
        border-color:#000000;
        ${mobileMedia}{
            top: 20px;
            inset-inline-end: 3px;
            padding: 0 8px;
            font-size: 10px;
        }
    }
`;

export const wrapAll=styled.div`
${context}{
    display:block;
}
${wRight}{
    width:100%;
}
${wLeft}{
    width:100%;
    margin-top:5px;
}
`;

export const Textarea=styled.div`
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    background: rgb(245, 245, 245);
    position: relative;

    ${containerBasicInput}{
        ::before{
            display:none ;
        }
    }
    ${container}{
        height:170px ;
        border-bottom: 2px solid;
    }
    ${'textarea'}{
        ${scrollbar};
        ${mobileMedia}{
            margin-inline-end: 20px;
        }
        height:150px ;
        width: 90%;
        border-bottom: none;
    }
    ${charNum}{
        display:none ;
    }
    ${textWrap}{
        inset-inline-start: 0;
        height: 60px;
        div {
            ${ellipsisLines(2)}
        }
    }
    ${titleWrap}{
        &.empty{
            height: 100%;
            width: 100%;
            bottom: 20px;
            visibility: visible;
            ${title}{
                font-weight: 500;
            }
        }
        .required{
            visibility: hidden;
        }
        visibility: hidden;
    }
    ${textAreaIcon}{
         display: none;
    }
    ${title}{
        height: 100%;
        white-space: pre-line;
        position: absolute;
        width: 100%;
        ${mobileMedia}{
            top: 15px;
            font-size: 8px;
        }
    }
    ${tooltip}{
        top: 25px;
    }
`;

export const icon=styled.div`
    ${BGImageCenter(gptDescription,'contain')}
    width: 15px;
    height: 100%;
    margin-inline-end:5px;
    ${mobileMedia}{
        margin-inline-end:4px;
        width: 10px;
    }
`;