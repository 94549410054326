import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Accordion from '../../package/componnent/accordion/Accordion';
import {getEventSettings} from '../../../redux/event/eventActions';
import {useSelector} from 'react-redux';
import EditImage from '../editImage/EditImage';
import HubMailingForm from './hubMailingForm/HubMailingForm';
import SettingsPopupWrapper from '../settingsPopupWrapper/SettingsPopupWrapper';
import HubMultipleAccount from './HubMultipleAccount/HubMultipleAccount';
import {getAccounts} from '../../../redux/event/eventActions';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';

function HubSettingsPopup({showClass, close}) {
    const {t} = useTranslation('event');
    const {settings,getEventSettingsXHR,getAccountsXHR}=useSelector(s=>s.event);
    const roles = ['master', 'manager', 'owner'];
    
    useEffect(() => {
        if(!getEventSettingsXHR)
            getEventSettings().then();
    }, []);
    useEffect(() => {
        if(getEventSettingsXHR==='SUCCESS'){
            if(!getAccountsXHR)
                getAccounts({search: '', limit: 10, offset: 0}).then();
        }
    }, [getEventSettingsXHR]);
    return (
        <SettingsPopupWrapper showClass={showClass} close={close}>
            <>
                <Accordion title={t('generalSettings')} dataCyTitle='generalSettings'>
                    <EditImage langDesign={settings?.langDesign}/>
                </Accordion>
                <EventRolePermission rolePermission='manage event' views={roles}>
                    <Accordion title={t('managedAccounts')}  dataCyTitle='managedAccounts'>
                        <HubMultipleAccount/>
                    </Accordion>
                </EventRolePermission>
                <Accordion title={t('advertisingMarketing')} isClose dataCyTitle='advertisingMarketing'>
                    <HubMailingForm />
                </Accordion>
            </>
        </SettingsPopupWrapper>
    );
}

export default HubSettingsPopup;
