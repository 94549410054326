import request from '../../library/request';
import setGlobalState from '../setGlobalState';
import store from '../../store';
import {reloadIframe} from '../../layout/myIframe/MyIframe';

export const authRefreshToken = async () => {
    const isMaster = store.getState().user?.isMaster;
    const socket = store.getState().auth?.socket;
    const res = await request('auth.refreshToken', 'post', '/user/refreshToken/', {socket});
    if(res.exp) {
        localStorage.setItem('exp', res.exp.toString());
        setGlobalState('auth.exp', res.exp.toString());
        if(isMaster){
            reloadIframe();
        }
    }
};