import {useSelector} from 'react-redux';
import * as css from './PixelsScreen.css';
import {oldPixelTypes} from '../../PixelsPage/PixelsPage';
import {Fragment} from 'react';
import Tooltip from '../../../../component/tooltip/Tooltip';
import {useTranslation} from 'react-i18next';
import WrapPixelBox from '../wrapPixelBox/WrapPixelBox';
import PixelSelection from './PixelSelection';
import {useState,useEffect,useRef} from 'react';
import setGlobalState from '../../../../redux/setGlobalState';
import {WithPermission} from '../../customPixel/CustomPixel';

function PixelsScreen({pixelTypes}) {
    const {t}=useTranslation('publish');
    const {pixel,updateSpecialPixelXHR,addSpecialPixelXHR}=useSelector(s=>s.publish);
    const {newPixel} = useSelector(s=>s.ui.pixel);
    const [currentIndex,setCurrentIndex]=useState(pixelTypes.length);
    const interval=useRef();

    const shouldDisplayOldPixels = pixel.customPixels.some(px => px.type === 'Google Analytics (old)');
    const pixelData = shouldDisplayOldPixels ? pixelTypes.concat(oldPixelTypes) : pixelTypes;
    useEffect(()=>{

        interval.current=setInterval(() => {
            setCurrentIndex(prev=>prev-1);
        }, 25);
    },[]);
    
    useEffect(()=>{
        if(updateSpecialPixelXHR==='SUCCESS')
            setGlobalState('publish.updateSpecialPixelXHR',null);
        if(addSpecialPixelXHR==='SUCCESS')
            setGlobalState('publish.addSpecialPixelXHR',null);
    },[updateSpecialPixelXHR,addSpecialPixelXHR]);

    useEffect(()=>{
        if(currentIndex===0)
            clearInterval(interval.current);
    },[currentIndex]);
    return (
        <>
            <css.container data-cy='pixelsOnSideScreen'>
                <css.pixelsArea>
                    <css.right>
                        {pixelData.map((px, index) =>
                            <Fragment key={px}>
                                <Tooltip text={t(px)}>
                                    <PixelSelection pixelKey={px} className={index >= currentIndex ? 'show' : ''} />
                                </Tooltip>
                            </Fragment>
                        )}
                    </css.right>
                    <css.left>
                        {pixel.specialPixels.map((pixel) => (
                            <WrapPixelBox pixel={pixel} key={pixel.pixelId} />                
                        ))}
                        {newPixel?.key && <WrapPixelBox pixel={newPixel} key={`new${newPixel.key}`}/> }         
                        {pixel.customPixels.map((pixel) => (
                            <WithPermission key={pixel.pixelId}>
                                <WrapPixelBox pixel={pixel} key={pixel.pixelId}/>  
                            </WithPermission>          
                        ))} 
                    </css.left>
                </css.pixelsArea>
            </css.container>
        </>
    );
}

export default PixelsScreen;