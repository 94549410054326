import {useField} from 'formik';
import {Else, If, Then} from 'react-if';
import BasicInput from './basicInput/BasicInput';
import DisabledInputFormik from './disabledInput/DisabledInputFormik';

function EmailInput({name, text = '', description = '', icon = '', disabled = false, events, className}) {
    const [,meta, helpers] = useField(name);

    const onChange=(e)=>{
        if(!meta.touched)
            helpers.setTouched(true);
        helpers.setValue(e.target.value);
    };

    return (
        <If condition={disabled}>
            <Then>
                <DisabledInputFormik
                    events={events}
                    name={name}
                    text={text}
                    description={description}
                    icon={icon}
                />
            </Then>
            <Else>
                <BasicInput
                    types={{fieldType:'input',inputType:'email'}}
                    events={{...events,onChange}}
                    name={name}
                    text={text}
                    description={description}
                    icon={icon}
                    className={className} />
            </Else>
        </If>

    );
}

export default EmailInput;
