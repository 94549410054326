import styled from 'styled-components/macro';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';
import {listStyle} from '../../subList/SubList.css';
import {dFlexCenter} from '../../../globalVariables/GlobalVariables';

export const menuItem = styled.div`
    color: #fff;
    height: 60px;
    ${p=>(!p.theme.realtime || !p.theme.mini) && 'border-top: 1px solid #696969'};
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction:${p=>p.theme.mini?'column':'row'} ;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor:pointer;
    position:relative;
    link{
    position:absolute;
        width:100%;
        height:100%;
    }
    @media screen and (max-height: 800px){
        height:50px;
    }
    @media screen and (max-width: 1000px){
        padding-${props => props.theme.rtl ? 'right' : 'left'}:20px;
    }
    ::before{
        content:'';
        ${props => props.current && 'background:#01acf1;'}
        position: absolute;
        width: 3px;
        height: calc(100% - 20px);
        ${props => props.theme.rtl ? 'right' : 'left'}:0;
        }

`;

export const wrapItem = styled(listStyle)`
    .itemIcon{
        transform: rotate(${props => (props.theme.rtl ? '90deg' : '270deg')});
        background-size:9px ;
    }
`;
export const menuItemImg = styled.div`
    width:  ${props => props.theme.mini ? '30' : '50'}px;
    height:${props => props.theme.mini ? '35px' : '100%'};
    background-repeat:no-repeat;
    background-size: 20px 20px;
    background-position: center ${p=>p.theme.mini?'bottom':'center'};
    background-image: url("${props => (symbol.symbolEvent[props.className])}");
    // ${props => (symbol.symbolEvent[props.className])};
     @media screen and (max-width: 1000px){
            width:50px;
        }
    &.event{
        background-size: 25px 25px;
    }

`;
export const menuItemText = styled.div`
        color: #fff;
        font-size: ${p => p.theme.mini ? (p.theme.rtl ? 10: 8) : 16}px;
        ${p => (p.theme.mini && p.theme.realtime)  && `
                padding-inline: 5px;
                line-height: 13px;
                font-size: 13px;
                text-align: center;
                ${dFlexCenter};
                height:25px;
        `};
        @media screen and (max-width: 1000px){
            font-size:16px;
        } 
`;
export const menuItemCircle = styled.div`
        position: absolute;
        ${props => props.theme.rtl ? 'left' : 'right'}:10%;
        width:22px;
        height:22px;
        background-color: #5D5D5D;
        border-radius: 50%;

`;

export const menuItemArrow = styled.div`
            background-color: #fff;
            /* transform:rotate(${props => props.theme.rtl ? '90deg' : '-90deg'}); */
            width: 22px;
            height:22px;
            -webkit-mask-image: url("${symbol.simpleArrow}");
            mask-image: url("${symbol.simpleArrow}");
            -webkit-mask-position: center;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 10px ;
`;