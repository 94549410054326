import {useField} from 'formik';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getTs, tsToDate} from '../../library/dates';
import useMobile from '../../hooks/useMobile';
import {ThemeProvider} from 'styled-components';
import he from 'date-fns/locale/he';
import * as css from './FormikDatePicker.css';
import {onDatePickerChange} from './DatePickerFunctions';

function MonthPicker({name, minDate, onChange = () => {}, props}) {
    const [field, , helpers] = useField(name);
    const lang = useSelector((state) => state.user.lang);
    useEffect(() => {
        if(lang === 'he') registerLocale('he', he);
    }, []);

    const handleDateChange = (value) => {
        onDatePickerChange(value, helpers);
        onChange(value);
    };

    const isMobile = useMobile();

    const MonthPickerComponent = isMobile ? 'input' : ReactDatePicker;
    const monthPickerProps = isMobile
        ? {
            type: 'month',
            placeholder: '',
            value: field.value ? tsToDate(field.value, null, null, 'YYYY-MM') : '',
            min: tsToDate(minDate ? (minDate/1000): getTs(), null, null, 'YYYY-MM'),
            onChange: (e) => handleDateChange(getTs(e.target.value)),
            className: field.value && 'hasValue',
        }
        : {
            selected:field?.value && field.value * 1000,
            minDate: minDate ? minDate : getTs()*1000,
            dateFormat: 'MM/yyyy',
            showMonthYearPicker: true,
            showFullMonthYearPicker: true,
            onChange: (val) => handleDateChange(val / 1000),
        };
    return (
        <ThemeProvider theme={{type: 'month'}}>
            <css.container>
                <MonthPickerComponent
                    locale={'he'}
                    className="noBorder"
                    placeholderText="MM/yyyy"
                    {...monthPickerProps}
                    {...props}
                />
            </css.container>
        </ThemeProvider>
    );
}

export default MonthPicker;
