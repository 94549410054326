import {useField} from 'formik';
import RightChildrenInput from '../../../../../component/input/rightChildrenInput/RightChildrenInput';
import RoundCheckbox from '../../../../../component/roundCheckbox/RoundCheckbox';
import {useEffect} from 'react';

function VerificationInput({name,text,onChecked,checked}) {
    const [,,helpers]=useField(name);
    useEffect(()=>{
        helpers.setTouched(true);
    },[checked]);

    return (
        <RightChildrenInput name={name} text={text} radio={true}>
            <RoundCheckbox onClick={()=> onChecked(name)} checked={checked} />
        </RightChildrenInput>
    );
}

export default VerificationInput;