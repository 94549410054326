import {createContext} from 'react';
import {Formik, getIn} from 'formik';
import getUnequalFields from '../../../functions/getUnequalFields';
export const ValidationContext = createContext({requiredFields: undefined});

function FormContext({validationSchema,children,...props}){

    const requiredFields = {};
    const fields = validationSchema.describe().fields;
    for(const key in fields) {
        if(fields.hasOwnProperty(key)) {
            const fieldValidationSchema = fields ? getIn(fields, key) : false;
            const tests = fieldValidationSchema ? fieldValidationSchema.tests : false;
            requiredFields[key] = tests ? !!tests.find((test) => test.name === 'required') : false;
        }
    }

    const onSubmit=(values,actions)=>{
        const changedValues=getUnequalFields(values,props.initialValues);
        props.onSubmit(changedValues,values,actions);
    };
    return (
        <ValidationContext.Provider value={{requiredFields}} >
            <Formik {...props} onSubmit={onSubmit}  validationSchema={validationSchema}>
                {children}
            </Formik>
        </ValidationContext.Provider>

    );
}
export default FormContext;