import React, {useEffect, useRef, useState} from 'react';
import UserImage from '../userImage/UserImage';
import UserProfileMenuButton from '../userProfileMenuButton/UserProfileMenuButton';
import * as All from './LayoutUserProfileMenu.css';
import EditUserProfile from '../editUser/editUserProfile/EditUserProfile';
import EditUserPreference from '../editUser/EditUserPreference';
import EditUserSecurity from '../editUser/EditUserSecurity';
import {useSelector} from 'react-redux';
import {tsToDate} from '../../../../library/dates';
import {useTranslation} from 'react-i18next';
import {iframeClick} from '../../../Layout';
import useOnClickOutside from '../../../../hooks/UseOnClickOutside';
import useNavigationLink from '../../../../hooks/useNavigationLink';
import {getUserInfo} from '../../../../redux/user/userActions';

const UserProfileMenu = ({closeMenu,close,hide,showEvents,userImgPath, userName}) => {
    const user=useSelector(s=>s.user);
    const {t} = useTranslation('layout');
    const aboutNavigation = useNavigationLink('teamMemberPop', 'user');
    
    const [buttonsList, setButtonsList] = useState([  
        {name:t('editProfile'),type:'edit', isActive: true},
        {name:t('about'),type:'about', isActive: true},
        {name:t('yourEvents'),type:'events', isActive: true},
        {name:t('securing'),type:'security', isActive: true,isBuilding:true},
        {name:t('preferences'),type:'preference', isActive: true ,isBuilding:true},
        {name:t('logout'),type:'logout', isActive: true}
    ]);

    const [editScreen, setEditScreen] = useState('');
    const screenHandler = (type) =>{
        setEditScreen(type);
    };
    const [show, setShow] = useState(0);

    const [screenComponent,setScreenComponent]=useState('');
    const getScreenComponent=()=>{
        switch (screenComponent) {
            case 'EditUserProfile':
                return <EditUserProfile close={closeProfileMenu}/>;                
            case 'EditUserSecurity':
                return <EditUserSecurity/>;    
            case 'EditUserPreference':
                return <EditUserPreference/>;    
            default:
                break;
        }
    };
    const resetBtnsHandler = () => {
        let temp = buttonsList.map(btn => ({...btn, isActive:true}));
        setButtonsList(temp);
        screenHandler('');
        return temp;
    };
    const timer=useRef();
    useEffect(()=>{
        setTimeout(()=>setShow(1),0);
        return clearTimeout(timer.current);
    },[]);
    
    const closeProfileMenu=()=>{
        setShow(0);
        if(closeMenu)
            closeMenu();
        timer.current=setTimeout(close,400);

    };

    const isFirstTime=useRef(true);
    useEffect(()=>{
        if(isFirstTime.current){
            isFirstTime.current=false;
            return;
        }
        if(hide)
            closeProfileMenu();
    },[hide]);
    let lastConnection = JSON.parse(user.lastConnect);
    let formatted = tsToDate(lastConnection?.last, user.langloc, user?.timeZone, 'dateAndTime');

    const btnClickHandler = (index,e) => {
        let temp = resetBtnsHandler();
        temp[index].isActive = !temp[index].isActive;
        setButtonsList(temp);
        let type = temp[index].type;

        switch (type){
            case 'edit':
                screenHandler('open '+ type);
                setScreenComponent('EditUserProfile');
                break;
            case 'about':
                aboutNavigation.open();
                closeProfileMenu();
                break;
            case 'events':
                e.stopPropagation();
                closeProfileMenu();
                showEvents();
                break;
            case 'security':
                setScreenComponent('EditUserSecurity');
                break;
            case 'preference':
                setScreenComponent('EditUserPreference');
                break;
            case 'logout':
                iframeClick('logout');
                break;
            default:
                break;

        }
    };

    const profileHeader = <All.ProfileMenuStyledHeaderCtr>
        <All.UserProfileTop><UserImage class={'profile-menu-user-img'} path={userImgPath}/>
            <All.UserNameStyled>{userName}</All.UserNameStyled>
            <All.UserConnectionTimeStyled>{t('lastLogin') + formatted}</All.UserConnectionTimeStyled>
        </All.UserProfileTop>
    </All.ProfileMenuStyledHeaderCtr>;

    const ref=useRef(null);
    useOnClickOutside(ref,closeProfileMenu);

    return <All.ProfileMenuStyledCtr ref={ref} className={show&&'show'}>
        <All.ProfileMenuStyledSideCtr className={editScreen}>
            <All.ProfileMenuStyledClose className={editScreen&&'closeEdit'} onClick={editScreen?resetBtnsHandler:closeProfileMenu}/>
            {profileHeader}
            <All.MenuBtnList>{
                buttonsList.map((btn,idx) => {
                    return <UserProfileMenuButton isActive={btn.isActive} clicked={btnClickHandler} isBuilding={btn.isBuilding} type={btn.type} key={idx} idx={idx} name={btn.name}/>;
                })
            }</All.MenuBtnList>
        </All.ProfileMenuStyledSideCtr>
        <All.ProfileMenuStyledEditCtr className={editScreen}>
            {getScreenComponent()}
        </All.ProfileMenuStyledEditCtr>
    </All.ProfileMenuStyledCtr>;
};

export default UserProfileMenu;