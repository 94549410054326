export function insertTextAtCaret(html,activeTab) {
    let sel, range;
    if(window.getSelection) {
        sel = window.getSelection();
        if(sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            let element = range.startContainer.parentNode.parentNode;
            if(element.classList.contains('menuItem') || element.classList.contains('menuItems') || ((element.classList.contains('subjectAndVars') || element.classList.contains('contentTop')) && activeTab == 'sms')) {
                document.getElementById('contentMiddle').appendChild(html);
                return;
            }
            range.deleteContents();
            range.insertNode(html);
        }
    } else if(document.selection && document.selection.createRange) {
        document.selection.createRange().text = html;
    }
}

export function restoreSelection(range, selection) {
    if(range) {
        if(window.getSelection) {
            selection.removeAllRanges();
            selection.addRange(range);
        } else if(document.selection && range.select) {
            range.select();
        }
    }
}

export function saveSelection() {
    if(window.getSelection) {
        let sel = window.getSelection();
        if(sel.getRangeAt && sel.rangeCount)
            var newrange = sel.getRangeAt(0);
        return ({sel:sel,newrange:newrange});
    } else if(document.selection && document.selection.createRange) {
        return document.selection.createRange();
    }
    return null;
}
export default {insertTextAtCaret, restoreSelection, saveSelection};