import styled from 'styled-components/macro';
import {desktopMedia} from '../../../../globalVariables/GlobalVariables';
import {header} from '../subPermissions/SubPermission.css';

export const container = styled.div`
        ${desktopMedia}{ 
            display: grid;
            justify-content: flex-start;
            align-content: space-around;
            flex-wrap: wrap;
            grid-template-rows: repeat(12,1fr);
            grid-auto-flow: column;
        }
        padding-top: 10px;
        `;

export const masterWrap = styled.div`
    ${header}{
        width: 100%;
    }
`;
export const master = styled(container)`
    grid-template-rows: unset;
    grid-auto-flow: unset;
    grid-template-columns: repeat(3,1fr);
 `;
