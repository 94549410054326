import {useTranslation} from 'react-i18next';
import * as css from './CopyLink.css';
import copyToClipboard from '../../library/copyToClipboard';
import {useEffect, useState} from 'react';
import useTimeout from '../../hooks/useTimeout';

function CopyLink({text,title}) {
    const {t} = useTranslation('global');
    const [copied, setCopied] = useState(0);
    const timer = useTimeout();

    useEffect(() => {
        if(copied)
            timer.current=setTimeout(() => {
                setCopied(0);
            },3000); 
    }, [copied]);
 
    return (
        <>
            <css.copy onClick={()=>{copyToClipboard(text);setCopied(1);}}>
                {title||t('copyLink')}
                <css.iconLink />
                {!!copied&& <css.copied>{t('copied')}</css.copied>}
            </css.copy>
      
        </>
    );
}

export default CopyLink;
