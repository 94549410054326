import {useTranslation} from 'react-i18next';
import * as css from './ClearingHeader.css';

function ClearingHeader() {
    const {t} = useTranslation('package');

    return (
        <>
            <css.supplierName>
                {t('supplierTitle')}
            </css.supplierName>
            <css.commission>
                {t('commission')}
            </css.commission>
            <css.type>
                {t('type')}
            </css.type>
            <css.language>
                {t('language')}
            </css.language>
            <css.currency>
                {t('currency')}
            </css.currency>
            <css.isDefault>
                {t('default')}
            </css.isDefault>
            <css.assignedEvents>  
                {t('affiliationToEvents')}
            </css.assignedEvents>
        </>
    );
}
export default ClearingHeader;
