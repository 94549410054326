import React from 'react';
import * as css from './ExplainText.css';

function ExplainText(props) {

    return (
        <css.explainText className={props.className&&props.className} explain={props.explain}>{props.text}</css.explainText>
    );
}
export default ExplainText;
