import React from 'react';
import './StatusHover.css';
import t from '../../../TranslateHe';

const RtlCss = React.lazy(() => import('./StatusHover-rtl'));

export default function StatusHover(props) {
    const type = props.type;
    const item = props.item;
    const status = props.status;
    let fails, pending, sent, opened;
    if(type === 'regular'){
        fails = item.error ? item.error : 0;
        opened = item.open ? item.open : 0;
        sent = item.sent ? (item.sent - opened) : 0;
        pending = (item.recipientCount - fails - sent - opened) >= 0 ? (item.recipientCount - fails - sent - opened) : 0;
    } else {
        fails = status === 'ERROR' ? 1 : 0;
        opened = item.open ? 1 : 0;
        sent = (status === 'SENT' && !opened) ? 1 : 0;
        pending = status === 'PENDING' ? 1 : 0;
    }
    return (
        <>
            {(props.lang === 'he') && <RtlCss />}
            <div className="mailing-table-hover">
                <div className="m-t-h-row"><div className="m-t-h-icon" image="fails" /><div className="m-t-h-title">{t('Fails')}</div><div className="m-t-h-value">{fails}</div></div>
                <div className="m-t-h-row"><div className="m-t-h-icon" image="v_1" /><div className="m-t-h-title">{t('Pending')}</div><div className="m-t-h-value">{pending}</div></div>
                <div className="m-t-h-row"><div className="m-t-h-icon" image="v_2" /><div className="m-t-h-title">{t('Sent')}</div><div className="m-t-h-value">{sent}</div></div>
                {item.messageType === 'email' && <div className="m-t-h-row"><div className="m-t-h-icon" image="v_3" /><div className="m-t-h-title">{t('Open')}</div><div className="m-t-h-value">{opened}</div></div>}
            </div>
        </>
    );
}

