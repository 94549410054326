import * as css from './OfficePopup.css';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import  Button  from '../../../../component/button/button/Button';
import ManagerDetails from '../managerDetails/ManagerDetails';
import OfficeOptions from '../../../../component/officeOptions/OfficeOptions';
import useShowModal from '../../../../hooks/useShowModal';
import {useSelector} from 'react-redux';

function OfficePopup(props) {
    const {item={}}=props;
    const {t} = useTranslation('package');
    const ref = useRef();
    // const [TAValue, setTAValue] = useState('סיפור יפה מאוד, קצת ארוך אבל לא פשוט למשפחה גדולה כל כך');

    const {open, close, isOpen, showClass} = useShowModal(ref, 500);

    const saveClick=()=>{
        
    };

    const btnProps={
        width:80,
        text: t('save'),
        onClick:saveClick,
    };

    const inputProps1={name:t('eventTitle'),text:t('eventTitle'),value:null,icon:'event',placeholderInput:'רבקה'};
    const inputProps2={name:t('eventTitle'),text:t('eventTitle'),value:null,icon:'event',placeholderInput:'רבקה'};

    // const textaeraInput={
    //     name: 'description',
    //     value: TAValue, icon: 'feather', textareaPlaceholder:'place:)', sumChars: 80, textareaHeight: '150px', notValid: 0,
    //
    // };

    const handleOnChange=()=>{

    };  
    //
    // const TAOnChange=(e)=>{
    //     setTAValue(e.target.value);
    // };
    const {isMasterState}=useSelector(state=>state.ui);

    return (<>

        {isMasterState&& <><OfficeOptions onClick={open} /> 

            {!!isOpen &&<css.popUpScreen className={showClass}>
                <css.Popup ref={ref} className={showClass} >
                    <css.header>
                        <css.headerClose onClick={close} />
                        <css.headerTitle>{'הוראות קבע'}<css.headerId>1234556</css.headerId></css.headerTitle>
                        <css.headerIcon ><css.headerOffice>{t('tickchakOffice')}</css.headerOffice></css.headerIcon>
                    </css.header>
                    <css.body>
                        <css.leftRight />
                        <css.leftRight>
                            <css.leftInput>{item.price}</css.leftInput>
                        </css.leftRight>

                    </css.body>
                    <css.bottom>
                        <ManagerDetails/>
                        <Button BtnProps={btnProps} />
                    </css.bottom>

                </css.Popup>
            </css.popUpScreen>}
        </> }
    </>
    );
}

export default OfficePopup;
