import {useEffect} from 'react';
import {getRealtimeMessage} from '../../../../../redux/realtime/realtimeActions';
import {useSelector} from 'react-redux';
import MobilePopup from '../../../../../component/newPopup/mobilePopup/MobilePopup';
import useShow from '../../../../../hooks/useShow';
import SystemMessage from './systemMessage';
import {BGImageCenter} from '../../../../../globalVariables/GlobalVariables';
import {TSystemMessage} from '../../../../../redux/realtime/realtime.type';

function SystemMessageDisplayPop({messagePreview, onClose}:{messagePreview?: TSystemMessage, onClose?: any}){
    const  systemMessage = messagePreview || useSelector((s: any) => s.realtime.systemMessage) || {};
    const show = useShow();
    const systemMessageXHR = useSelector((s:any) => s.realtime.getSystemMessageXHR);
    useEffect(()=>{
        if(!systemMessageXHR)
            getRealtimeMessage();
        if(systemMessage?.displayInPop)
            show.open();
    },[]);
    useEffect(()=>{
        if(systemMessage?.displayInPop)
            show.open();
    },[systemMessage?.displayInPop]);
    
    const close = () => {
        show.close();
        onClose && onClose();
    };

    return (
        (systemMessage?.displayInPop || messagePreview) && show.isOpen? <MobilePopup
            showClass={show.showClass}
            close={close} 
            title={undefined}
            list={undefined}
            forwardRef={undefined}
            dataCyName={'systemMessagePop'}
            cssStyle={`height:380px; width:440px;
            ${ systemMessage?.image && BGImageCenter(systemMessage?.image, 'cover')};`}
        >
            <SystemMessage messagePreview={messagePreview} close={close} isPop={true} />
        </MobilePopup>:<></>
    );
}

export default SystemMessageDisplayPop;