function validateText(content, subject,mailOrSms, fillSubject, fillContent) {

    if(content.innerHTML==''){
        alert(fillContent);
        return false;
    }
    else if(mailOrSms=='email' && subject.innerHTML==''){
        alert(fillSubject);
        return false;
    }

    return true;
}

export default validateText;