import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Else, If, Then} from 'react-if';
import EmailVerificationForm from './emailVerificationForm/EmailVerificationForm';
import VerificationSuccess from '../verificationAccount/verificationSuccess/VerificationSuccess';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import {updateUserEmail} from '../../../redux/user/userActions';
import {updateVerifiedEmail} from '../../../redux/account/accountActions';

function EmailVerification() {

    const {t}=useTranslation('security');
    const tLayout = useTranslation('layout');
    const packageT = useTranslation('package');
    const {ownerEmail,pid}=useSelector(s => s.account);
    const ownerEmailVerified=useSelector(s => s.account.ownerEmailVerified);

    const verifyEmail=async(_changedValues)=>{
        if(!_changedValues.email){
            bounceMessage(packageT.t('noEmail'),'warning');
            return;
        }
        if(ownerEmail?.toLowerCase().trim()!==(_changedValues.email)?.toLowerCase().trim())
            await updateUserEmail({email:_changedValues.email,pid:pid});
        updateVerifiedEmail({userEmail:_changedValues.email});
        
    };

    return (

        <If condition={ownerEmailVerified!==1}>
            <Then>
                <Formik
                    initialValues={{email:ownerEmail}}
                    validationSchema={Yup.object().shape({email:Yup.string().email().required()})}
                    enableReinitialize={true}
                    onSubmit={verifyEmail}
                >{({handleSubmit})=>
                        <Form>
                            <EmailVerificationForm name={'email'} onSubmit={handleSubmit}/>
                        </Form>
                    }
                </Formik>
            </Then>
            <Else>
                <VerificationSuccess text={t('emailVerified',{email:ownerEmail})} title ={tLayout.t('emailVerification')} ><div /></VerificationSuccess>
            </Else>
        </If>

    );

}

export default EmailVerification;
