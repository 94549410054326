import store from '../store';

const setGlobalState =(path,value) => {
    store.dispatch({type: `${path}`, payload: value});
};

const pushToGlobalState =(path,value)=> {
    store.dispatch({type: `${path}:PUSH`, payload: value});
};

const resetGlobalState = (path) => {
    store.dispatch({type: `${path}:RESET`, payload: null});
};

const unsetGlobalState = (path) => {
    store.dispatch({type: `${path}:UNSET`, payload: null});
};

export default setGlobalState;

export {pushToGlobalState, unsetGlobalState, resetGlobalState};