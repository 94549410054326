import styled from 'styled-components/macro';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, ellipsisLines} from '../../../globalVariables/GlobalVariables';
import {state} from '../../../../src/component/state/State.css';
import {palette} from '../../../assets/symbols/symbols/Symbols.css';
const DivShadow=styled.div`
`;
export const Event =styled.div`
    width:calc(100% - 40px);    
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    height: 110px;
    background: #fff;
    border-radius: 6px;
    margin: 10px auto;
    box-shadow: 0px 3px 6px #00000029;   
    position:relative;
    // transform: scale(0);
    opacity:0;
    cursor:pointer;
    transition: all 100ms;
    &.show{
        // transform: scale(1);
        opacity:1;
    }
    &.expanded{
        @media screen and (min-width: 800px){
            width:325px;
            margin:0;
        }
    }
    ::after {
        content: '';
        position: absolute;
        height: 5px;
        width: 0px;
        background: #76D6E8;
        bottom: 0px;
        border-radius: 30px;
        left: 0;
        transition: all 200ms;
        max-width:100%;
    }
    :hover{
        ::after {
            width: ${props => props.status+'px'};
        }
    }
`;
export const EventContent =styled.div`
    width:calc(100% - 135px);
    height:100%;  
    padding: 10px;
    
`;
export const EventContentMain =styled.div`
   width:100%;
   height:70px;
  display: flex;
  justify-content: space-between;
  &.palette{
    ${BGImageCenter(palette,'16px')};
    background-position: ${p=>p.theme.rtl?'right':'left'} calc(100% - 5px);
  }
 
`;
export const ECMainData=styled.div`
    width: 100%;
`;
export const ECMainOptions=styled.div`
`;
export const ECMainTitle =styled.div`
    ${ ellipsisLines(3)}
    font-weight: 800;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.5px;
    width: 90%;  
  &.palette{
    ${ ellipsisLines(2)}

  }
`;
export const ECMainDate =styled.div`    
    font-size: 13px;
    color: #525252;    
    direction: ltr;
    text-align:${p=>p.theme.rtl?'right':'left'} ;
    
`;
export const EventContentBot =styled.div`
    display: flex;

`;

export const EventContentPack =styled.div`
    width: calc(100% - 60px);
    height: 20px;
    font-size: 13px;
    padding-right: 15px;
    position:relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ::before{
        content:'';
        background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024.069%2026.831%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%23000%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20id%3D%22Path_517%22%20data-name%3D%22Path%20517%22%20class%3D%22cls-1%22%20d%3D%22M12.505.089%2C23.469%2C5.571a1.053%2C1.053%2C0%2C0%2C1%2C.6.942h0V20.3a.928.928%2C0%2C0%2C1-.685.942L12.505%2C26.727a.924.924%2C0%2C0%2C1-.942%2C0L.6%2C21.246A1.087%2C1.087%2C0%2C0%2C1%2C0%2C20.3H0V6.513a1%2C1%2C0%2C0%2C1%2C.685-.942L11.563.089a1.752%2C1.752%2C0%2C0%2C1%2C.942%2C0ZM8.394%2C4.029h0l9.165%2C4.54%2C3.683-1.8L11.991%2C2.145l-3.6%2C1.884ZM2.827%2C6.77h0l9.165%2C4.54L16.1%2C9.254%2C7.024%2C4.715%2C2.827%2C6.77Zm-.771.942h0V19.618l9.336%2C4.711V12.423L2.056%2C7.712ZM12.677%2C24.329h0l9.336-4.711V7.712c-3.084%2C1.542-6.253%2C3.084-9.336%2C4.711V24.329Z%22%20transform%3D%22translate%280%20-0.025%29%22%2F%3E%20%3C%2Fsvg%3E');
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        width: 14px;
        height: 14px;
        background-size: contain;
        right: 0px;
        top: 2px;
    }
    
`;
export const EventImg =styled.div`
    width:135px;
    height:100%; 
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image:url("${props => (props.image)}");
    :hover .eventImgEdit{
        display:block;
    }
    
`;
export const EventImgOption =styled.div`

    
    
`;
export const EventStateBtn =styled.div`
    width: 60px;
    height:20px;
  font-size: 11px;
  ${state} {
    padding: unset;
    padding-inline-start: 15px;
    padding-inline-end: 4px;
    ::after {
      inset-inline-start: 4px;
    }

  }
  ${state}.active{
    padding: unset;
    padding-inline-start: 4px;
    padding-inline-end: 15px;
    ::after {
      inset-inline-start: calc(100% - 13px);
    }
  }
    
`;
export const EventImgEdit =styled.div`
    display:none;
    width:30px;
    height:30px;
    border-radius:50%;
    background:#76D6E8;
    margin:40px auto;
    cursor:pointer;
    background-image:url("${symbol['pen']}"); 
    background-position: center center;
    background-repeat: no-repeat;
    
    
`;