import styled from 'styled-components/macro';

export const goalText = styled.span`
  position: relative;
  inset-block-start: 10px;
  font-size: 10px;
  line-height: 13px;
  font-weight: normal;
`;
export const title = styled.div`
  position: relative;
  inset-block-start: 10px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;      
`;