import * as css from './RolePermission.css';
import {Else, If, Then} from 'react-if';
import {useTranslation} from 'react-i18next';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';

function RolePermission({hasAccess,role,views=['all'],children}) {
    const {t} = useTranslation('global');

    return (
        <If condition={hasAccess}>
            <Then>{children}</Then>
            <Else>
                <If condition={views[0]==='all'||views.includes(role)}>
                    <Then>
                        <css.inactive onClick={()=>bounceMessage(t('inactiveRole'),'warning')}>
                            {children}
                        </css.inactive>
                    </Then>
                </If>
            </Else>
        </If>

    );
}

export default RolePermission;
