import {useTranslation} from 'react-i18next';
import ticketColors from '../../assets/objects/TicketColors';
import store from '../../store';
import * as css from './AnimalFlower.css';

function AnimalFlower({onClickSymbol}) {
    const {t} = useTranslation('ticket');
    const currentColor = store.getState().ui.colorsPicker.currentColor;
    const currentSymbol = store.getState().ui.colorsPicker.currentSymbol;

    return (
        <css.wrapper>
            <css.title>{t('selectSymbol')}</css.title> 
            <css.symbolArea>
                <css.item className={currentSymbol==='animal'&&'current'} color={currentColor}>
                    <css.animal onClick={()=>onClickSymbol('animal')} color={currentColor} symbol={ticketColors[currentColor]['animal']}/>
                </css.item>
                <css.item className={currentSymbol==='flower'&&'current'} color={currentColor}>
                    <css.flowers onClick={()=>onClickSymbol('flower')} color={currentColor} symbol={ticketColors[currentColor]['flower']}/>
                </css.item> 
            </css.symbolArea>
        </css.wrapper>
    );
}

export default AnimalFlower;
