import {useTranslation} from 'react-i18next';
import EditAttendance from '../../../component/editAttendance/EditAttendance';
import * as css from './ParticipantReportItem.css';
import {tsGetDateAndMonth} from '../../../library/dates';

export  const getDate=(meetingDate,globalT)=>{
    let d = new Date(meetingDate*1000);
    let newDate =tsGetDateAndMonth(meetingDate);
    let options = {month:'long', day:'2-digit'};
    return globalT.t('day') +' '+ `${globalT.t('fullDay'+(d.getDay()+1))}` +' '+ newDate+' '+d.toLocaleString('he',{...options,calendar:'hebrew'});
};
function ParticipantReportItem({_meeting,meetingDate}) {
    const globalT = useTranslation('global');

    return (
        <css.wrapItems>
            <css.item>
                <css.rightPart>
                    <css.icon />
                    <css.date>{getDate(meetingDate,globalT)}</css.date>
                </css.rightPart>
                <css.leftPart>
                    {/* <css.text>חיסרה עקב אירוע משפחתי</css.text> */}
                    <EditAttendance fullDate={getDate(meetingDate,globalT)} meeting={_meeting}/>
                </css.leftPart>
            </css.item>
        </css.wrapItems>
    );
}

export default ParticipantReportItem;
