import {VERY_DARK_GRAY} from '../../../../../globalVariables/GlobalVariables';
import WindowTemplate from '../../WindowTemplate/windowTemplate';

const grid = `"mainTitle title1 title2 title3 title4 title5"
                "subtext amount1 amount2 amount3 amount4 amount5"
                "time amount1 amount2 amount3 amount4 amount5"
`;
const gridMoreStyle={                    
    'grid-auto-columns': '10% 14% 14% 14% 14% 14%'
};
const settings = [
    {
        gridArea: 'mainTitle',
        width: '118px',
        height: '29px'
    },
    {
        gridArea: 'title1',
        width: '100%',
        height: '29px',
        moreStyle:{
            'margin-inline': '1%'
        }
    },
    {
        gridArea: 'title2',
        width: '100%',
        height: '29px',
        moreStyle:{
            'margin-inline': '1%'
        }
    },
    {
        gridArea: 'title3',
        width: '100%',
        height: '29px',
        moreStyle:{
            'margin-inline': '1%'
        }
    },
    {
        gridArea: 'title4',
        width: '100%',
        height: '29px',
        moreStyle:{
            'margin-inline': '1%'
        }
    },
    {
        gridArea: 'title5',
        width: '100%',
        height: '29px',
        moreStyle:{
            'margin-inline': '1%'
        }
    },
    {
        gridArea: 'subtext', 
        width: '118px',
        height: '16px'
    },
    {
        gridArea: 'time',
        width: '110px',
        height: '24px'

    },
    {
        gridArea: 'amount1',
        width: '100%',
        height: '50px'
        
    },
    {
        gridArea: 'amount2',
        width: '100%',
        height: '50px'
        
    },
    {
        gridArea: 'amount3',
        width: '100%',
        height: '50px'
        
    },
    {
        gridArea: 'amount4',
        width: '100%',
        height: '50px'
        
    },
    {
        gridArea: 'amount5',
        width: '100%',
        height: '50px'
        
    },

];

function RealtimeDataTemplate() {
   
    return <WindowTemplate gridMoreStyle={gridMoreStyle} gridAreas={grid} settings={settings} />;

}
export default RealtimeDataTemplate;