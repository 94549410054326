import {useField} from 'formik';
import DisabledInput from './DisabledInput';

function DisabledInputFormik({name, icon, text = '',  children, description = '', events, className=''}) {
    const [field]=useField(name);
    return (
        <DisabledInput value={field.value} icon={icon} text={text} description={description} events={events} dataCyName ={name} className={className}>
            {children}
        </DisabledInput>
    );
}

export default DisabledInputFormik;
