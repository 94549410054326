import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import BtnAddWhite from '../../../../component/button/btnAddWhite/BtnAddWhite';
import Table from '../../../../component/newTable/Table';
import useMobile from '../../../../hooks/useMobile';
import useShow from '../../../../hooks/useShow';
import {getAccountSuppliers} from '../../../../redux/account/accountActions';
import {getSuppliersInfo} from '../../../../redux/supplier/supplierActions';
import store from '../../../../store';
import ClearingHeader from '../clearingHeader/ClearingHeader';
import ClearingPopup from '../clearingPopup/ClearingPopup';
import ClearingRow from '../clearingRow/ClearingRow';
import * as css from './ClearingSettings.css';

function ClearingSettings() {
    const {t} = useTranslation('package');
    const isMobile = useMobile();
    const suppliers = useSelector(s=> s.account?.packageSuppliers);
    const account = useSelector(s=> s.account);
    const [currentItem, setCurrenItem] = useState(null);
    const {isMasterState}=useSelector(state=>state.ui);

    const {open, close, isOpen, showClass} = useShow();

    const newTerminal = ()=>{
        open();
    };

    const openPop = (supplier)=>{
        setCurrenItem(supplier);
        open();
    };

    const closePop = ()=>{
        setCurrenItem(null);
        close();
    };

    useEffect(()=>{
        if(!(store.getState().account.getAccountSuppliersXHR==='SUCCESS'))
            getAccountSuppliers().then();
        if(!(store.getState().account.getSuppliersInfoXHR==='SUCCESS'))
            getSuppliersInfo().then();
    },[]);

    useEffect(()=>{
        getAccountSuppliers().then();
        getSuppliersInfo().then();
    },[account.pid]);

    return (
        <>
            <css.ClearingSettings>
                <css.descriptionAndButton>
                    <css.description>
                        <css.title>{t('clearingManagingAndAdding')}</css.title>
                        <css.extension>{t('clearingDesc')}</css.extension>
                    </css.description>
                    {isMasterState&&<BtnAddWhite onClick={newTerminal} text={t('newTerminal')} />}
                </css.descriptionAndButton>
                <Table rowHeight={isMobile ? 115 : 70}
                    header={ClearingHeader}
                    list={suppliers?.map(supplier => ({comp: ClearingRow, props: {supplier, openPop}, key: supplier.srid}))}>
                    <css.header />
                </Table>
            </css.ClearingSettings>
            {isOpen && <ClearingPopup close={closePop} showClass={showClass} currentItem={currentItem}/>}
        </>
    );
}
export default ClearingSettings;
