import {useEffect, useRef} from 'react';

const useEffectOnUpdate=(callback,deps)=>{

    const isFirstRun = useRef(true);
    useEffect(() => {
        if(isFirstRun.current) {
            isFirstRun.current = false; 
            return;
        }
        callback();
    }, deps);

};
export default useEffectOnUpdate;