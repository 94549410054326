import {hasAccountRoleAccess} from '../../../functions/permission';
import {useSelector} from 'react-redux';
import RolePermission from './RolePermission';

function AccountRolePermission({rolePermission,views=['all'],children}) {

    let hasAccess=hasAccountRoleAccess(rolePermission);
    const role=useSelector(s=>s.account.userAccountRole);

    return (
        <RolePermission hasAccess={hasAccess} role={role} views={views}>
            {children}
        </RolePermission>
    );
}

export default AccountRolePermission;
