import * as css from '../modeBtn/ModeBtn.css';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

function FormikModeBtn({name,  className, forwardRef, click=()=>{}}) {
    const {t} = useTranslation('permission');
    const [, meta, helpers] = useField(name);
    const status=meta.value;
    const onClick=(e)=>{
        e.stopPropagation();
        helpers.setValue(!status);
        helpers.setTouched(true);
        click();
    };
    
    const getText = () =>  ((status) ? 'active' : (!status ? 'inactive' : status));

    return (<>
        <css.container className={className+' formikModeBtn ' }>
            <css.text data-cy ={`${name ? name: "default"}Text`}  className={className+' formikModeBtnText '+getText()}>{t(getText())}</css.text>
            <css.toggle data-cy ={`${name ? name: "default"}Toggle`} ref={forwardRef} text={getText()} onClick={onClick} className={className+' toggle '+getText()} />
        </css.container>
    </>
    );
}

export default FormikModeBtn;
