import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import faces from '../../../functions/faces';
import {POPUP_DURATION} from '../../../globalVariables/GlobalVariables';
import {getTicketAttendance, getMeetingAttendance} from '../../../redux/attendance/attendanceActions';
import PageWrapper from '../../components/pageWrapper/PageWrapper';
import Details from '../../eventDetails/details/Details';
import Participant from './participant/Participant';
import * as css from './ParticipantsPage.css';
import ParticipantTop from './participantTop/ParticipantTop';
import {getEventTickets} from '../../../redux/tickets/ticketsActions';
import useShow from '../../../hooks/useShow';
import CourseAttendance from '../courseAttendance/CourseAttendance';
import ParticipantOptions from '../../attendance/participantsPage/participantOptions/ParticipantOptions';
import ParticipantReportPop from '../../attendance/participantReportPop/ParticipantReportPop';
import setGlobalState from '../../../redux/setGlobalState';
import RightHeader from '../../../component/rightHeader/RightHeader';
import useMobile from '../../../hooks/useMobile';
import PageTitle from '../../../component/pageTitle/PageTitle';

function ParticipantsPage() {
    const {participants,getTicketAttendanceXHR} = useSelector(s=>({
        participants:s.attendance.tickets.find(t => t.tid === s.attendance.currentTid)?.participants||[],
        getTicketAttendanceXHR:s.attendance.getTicketAttendanceXHR}));
    const {pageId} = useParams();
    const tdid = pageId;
    const isMobile = useMobile();
    const {t} = useTranslation('attendance');
    const layoutT=useTranslation('layout');

    const {ticketTitle,getEventTicketsXHR} = useSelector(s=>({
        ticketTitle:s.tickets.tickets?.find(t => t.tid === s.attendance.currentTid)?.title,
        getEventTicketsXHR:s.tickets.getEventTicketsXHR}));
    const [showParticipant, setShowParticipant] = useState('');
    const timer = useRef(null);
    
    const openAttendance = useShow(POPUP_DURATION);
    const openParticipantReport = useShow(POPUP_DURATION);

    const getParticipants=()=>{        
        setShowParticipant('');
        getMeetingAttendance(tdid).then(()=> {
            timer.current = setTimeout(()=>{
                setShowParticipant('show');
            },150);
        });
    };
    useEffect(async()=>{
        if(!(getEventTicketsXHR))
            getEventTickets().then();            
        if(!getTicketAttendanceXHR)
            await getTicketAttendance(tdid).then(getParticipants);
    },[]);

    const isFirstRun=useRef(false);
    useEffect(async()=>{
        if(!getTicketAttendanceXHR && !isFirstRun.current){
            isFirstRun.current=true;
            return;
        }
        if(getTicketAttendanceXHR)
            getParticipants();
    },[tdid]);
    
    useEffect(() => () =>{
        setGlobalState('attendance.getTicketAttendanceXHR',null);
        clearTimeout(timer.current);}, []);

    return (
        <>
            <PageWrapper leftTitle={layoutT.t('courseDetails')} routing={{title:t('attendance'), subTitle:ticketTitle, id:tdid}}>
                <>
                    <RightHeader icons={<ParticipantOptions openAttendance={openAttendance} />}>
                        <PageTitle title={t('attendanceManagement')} subTitle={ticketTitle} />
                        {/* <Routing outerTitle={t('attendance')} title={ticketTitle} id={tdid}/> */}
                    </RightHeader>
                    <ParticipantTop />
                    <css.participantsGrid>
                        {[...participants]?.sort((a,b)=>a.firstName<b.firstName?-1:1).map((item, index) =>
                            <Participant 
                                showParticipant={showParticipant}
                                key={item.qid} 
                                participant={item}
                                face={faces(index)}/>
                        )}
                    </css.participantsGrid>
                </>
                {!isMobile&&<Details />}
            </PageWrapper>
            {!!openAttendance.isOpen && <CourseAttendance openAttendance={openAttendance} openReport={openParticipantReport} />}
            {!!openParticipantReport.isOpen && <ParticipantReportPop openReport={openParticipantReport}/>}
        </>
    );
}

export default ParticipantsPage;