import React from 'react';
import {useEffect,Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import AnotherOneCircle from '../../../../component/anotherOneCircle/AnotherOneCircle';
import BtnAdd from '../../../../component/button/btnAdd/BtnAdd';
import useNavigationLink from '../../../../hooks/useNavigationLink';
import {getAccountTeam} from '../../../../redux/team/teamActions';
import MemberSmall from '../../../team/memberSmall/MemberSmall';
import {rolePermission, getRolePermission, roles} from '../../../team/Team';
import {roleIcons} from '../../../team/teamMemberPop/teamMember/TeamMember';
import * as css from './AccountTeam.css';
import useTeamMembers from '../../../../hooks/useTeamMembers';
function AccountTeam() {
    const {open} = useNavigationLink('teamMemberPop', 'teamMember');

    const {accountMembers}=useTeamMembers();
    const {pid,userAccountRole}=useSelector(s=>s.account);
    const userEmail = useSelector(s => s.user.email);
    const {getAccountTeamXHR,getEventTeamXHR}=useSelector(s=>s.team);
    const {t}=useTranslation('event');
    const {t:teamT}=useTranslation('team');
    const {t:ticketT}=useTranslation('ticket');
    const {t:layoutT}=useTranslation('layout');
        
    const membersByRoles=roles.reduce((all,role)=>{
        const roleMembers=accountMembers.filter(({role:teamRole})=>teamRole===role);
        return roleMembers.length?{...all,[role]:roleMembers}:all;
    },{});

    const getRolePermissionState=(member,userAccountRole)=>
        getRolePermission(member,userAccountRole,true)&&!(member.role==='owner'&&membersByRoles.owner.length<=1);

    useEffect(()=>{
        
        if(pid && !(getAccountTeamXHR ||getEventTeamXHR))
            getAccountTeam().then();
    },[pid]);

    return (<css.container>
        <css.top>
            {layoutT('teamAccount')}
            <css.header>
                <div>
                    {teamT('accountTeamHeader')}
                    <css.title>
                        {teamT('accountTeamTitle')}
                    </css.title>
                </div>
                <css.addNew>
                    <BtnAdd onClick={open} text={ticketT('newStaffM')} />
                </css.addNew>
            </css.header>
        </css.top>
        <css.team>{
            Object.keys(membersByRoles).map(role=><Fragment key={role}>
                {membersByRoles[role].map((member,index)=><css.wrapMember
                    key={member.uid} 
                    className={index===0?'firstInGroup':''}
                    groupName={teamT(role)}
                    icon={roleIcons[role]}>
                    <MemberSmall member={member} 
                        isAccountPage
                        rolePermission={rolePermission(member,userEmail)}
                        rolePermissionState={getRolePermissionState(member,userAccountRole)}/>
                </css.wrapMember>   )}
            </Fragment>)}
        <css.wrapMember className='new'>
            <AnotherOneCircle title={t('addMembers')} onClick={open}/>
        </css.wrapMember>
        </css.team>
    </css.container>
    );
}

export default AccountTeam;