import * as css from './CircleImageText.css';
function CircleImageText({text,image,size}) {

    return (
        <css.imageContainer size={size}>
            <css.wrapAll>
                <css.image image={image}  size={size}/>
                <css.name>{text}</css.name>
            </css.wrapAll>
        </css.imageContainer>

    );
}

export default CircleImageText;
