const qwertyMapping = {
    ת: ',',
    ',': 'ת',
    ף: ';',
    ';': 'ף',
    ץ: '.',
    '.': 'ץ',
    ש: 'a',
    a: 'ש',
    נ: 'b',
    b: 'נ',
    ב: 'c',
    c: 'ב',
    ג: 'd',
    d: 'ג',
    ק: 'e',
    e: 'ק',
    כ: 'f',
    f: 'כ',
    ע: 'g',
    g: 'ע',
    י: 'h',
    h: 'י',
    ן: 'i',
    i: 'ן',
    ח: 'j',
    j: 'ח',
    ל: 'k',
    k: 'ל',
    ך: 'l',
    l: 'ך',
    צ: 'm',
    m: 'צ',
    מ: 'n',
    n: 'מ',
    ם: 'o',
    o: 'ם',
    פ: 'p',
    p: 'פ',
    '/': 'q',
    q: '/',
    ר: 'r',
    r: 'ר',
    ד: 's',
    s: 'ד',
    א: 't',
    t: 'א',
    ו: 'u',
    u: 'ו',
    ה: 'v',
    v: 'ה',
    '\'': 'w',
    w: '\'',
    ס: 'x',
    x: 'ס',
    ט: 'y',
    y: 'ט',
    ז: 'z',
    z: 'ז'
};

const correctChar = (oldChar) => {
    if(qwertyMapping[oldChar] === undefined) {
        return oldChar;
    } else {
        return qwertyMapping[oldChar];
    }
};

const invertedString = (oldString) => {
    let newString = '';
    for(let i = 0; i < oldString.length; i++) {
        newString += correctChar(oldString[i].toLowerCase());
    }
    return newString;
};

export {invertedString};
