import styled from 'styled-components/macro';
export const drop= styled.div`
p{
    max-width: 100%;
    ${props=>props.height?'height:'+props.height+';':''}
    ${props=>props.width?'width:'+props.width+';':''}
    &.error{
        color: red;
    }
    }
`;