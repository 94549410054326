import {useTranslation} from 'react-i18next';
import CircleProgress, {percentage} from '../../../../component/circleProgress/CircleProgress';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';
import MoreOperations from '../../../../component/moreOperations/MoreOperations';
import ticketColors from '../../../../assets/objects/TicketColors';
import {pencil, remove, symbolDefDark} from '../../../../assets/symbols/symbols/Symbols.css';
import {deleteGroup, updateGroup} from '../../../../redux/tickets/ticketsActions';
import store from '../../../../store';
import * as css from './GroupTop.css';
import Copy from '../../../../component/copy/Copy';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';

function GroupTop({openGroup, openPopUp, item, color, editState}) {
    const {t} = useTranslation('ticket');
    let totalGroup = 0;
    let totalSoldGroup = 0;

    item?.tickets?.forEach(function (item) {
        totalGroup += Number(item.amount);
        totalSoldGroup += Number(item.sold);
    });

    const updateStatus = async (e) => {
        e.stopPropagation();
        await updateGroup(item?.gid, {active: item?.active ? 0 : 1, tickets:item?.tickets});
    };

    const removeGroup = async () => {
        await deleteGroup(item.gid);
    };

    const list = [
        {key: 'editingTickets', text: t('EditingTickets'), type: 'icon', icon: pencil, iconClass: 'groups'},
        {key: 'groupSetting', text: t('groupSetting'), type: 'icon', icon: symbolDefDark, iconClass: 'groups'},
        {key: 'delete', text: t('deleteGroup'), type: 'icon', icon: remove, iconClass: 'groups'},
        {key: 'copyGid', type: 'text', text: `${t('group')}: ${item.gid}`, rightChildren: <Copy color={item.color} link={item.gid}/>}
    ];

    const onClickList = (val) => {
        switch (val.key) {
            case 'editingTickets':
                openGroup(item.gid);
                break;
            case 'groupSetting':
                openPopUp(item);
                break;
            case 'delete':
                bounceMessage(t('deleteAttention'),'warning',{text: t('continueAnyway'), onClick: removeGroup},{text: t('hoNoStop')},7);
                break;
            default: 
                break;
        }
    };

    const styleProps = {pathColor: '#' + color, trailColor: '#FFF', pathTransitionDuration: 0.7, strokeWidth: 7, strokeTrailWidth: 0};

    return (
        <css.top color={color}>
            <css.topTop>
                <css.topTopRight bg={ticketColors[color][item?.groupSymbol ? item?.groupSymbol : store.getState().tickets?.ticketsIcon]} />
                <css.topTopLeft>
                    <css.isActive>
                        <ModeBtn
                            click={(val, e) => updateStatus(e, val)}
                            status={item.active}
                            className="white" />
                    </css.isActive>
                    <MoreOperations title={t('operations')} list={list} onClick={(val) => onClickList(val)} width="200px" />
                </css.topTopLeft>
            </css.topTop>
            <css.groupProgress className={editState} onClick={(e) => { e.stopPropagation(); openGroup(item.gid);}}>
                {!editState &&
                    <CircleProgress
                        totalValue={totalGroup}
                        partialValue={totalSoldGroup}
                        styleProps={styleProps}
                    >
                        <css.circle color={color}>
                            {percentage(totalGroup, totalSoldGroup)}
                            <css.percent>%</css.percent>
                        </css.circle>
                    </CircleProgress>
                }
            </css.groupProgress>
        </css.top>
    );
}

export default GroupTop;