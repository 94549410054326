import Accordion from '../accordion/Accordion';
import Permissions from '../permissions/Permissions';
import {useTranslation} from 'react-i18next';

function SettingsBody() {
    const {t} = useTranslation('package');

    return (<>
        <Accordion  title={t('packagePermissions')}>
            <Permissions />
        </Accordion>
    </>
    );
}

export default SettingsBody;
