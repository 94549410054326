import styled from 'styled-components/macro';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';

export const eventDtails = styled.div`
    width: 100%;
    height: 100%;
    @media screen and (max-width: 750px){  
        overflow:auto; 
        width: max-content;
    ::-webkit-scrollbar {
        width: 0px;
        height: 0;
    } 
}
`;
export const wrapPopUp = styled.div`
    .duplicateEventTitle{
      ${props => props.theme.rtl ? 'right' : 'left'}:150px;
       @media screen and (max-width: 750px){  
            bottom: 58px;
            ${props => props.theme.rtl ? 'right' : 'left'}:140px;
        } 
    }
    .popBody{
        width: 753px;
        @media screen and (max-width: 750px){  
        width: 100%;
        height: 0;
        transition: all 300ms 150ms cubic-bezier(0.42, 0, 0.1, 1.36);
        overflow:unset;
       } 
    }
    &.showPop{
        @media screen and (max-width: 750px){  
            .popBody{
            height:90%
         }
       }  
    }
    .popUpBottom{
       display: flex;
       align-items: center;
       height:80px;
       padding: 0 10px 0 20px;
    } 
    .popImage{
        &.focus{
            @media screen and (max-width: 800px){
                height:10px;
                opacity:1;
            }
        }    
        &.focus{
            .image ,.stage{
            @media screen and (max-width: 750px){ 
                display:none;
            }    
         }
      }  
    }
    .popUpContent{
        height: calc(100% - 200px);
        padding: 35px 35px;  
        ${dFlexCenter};
        @media screen and (max-width: 750px){
           overflow:auto;
           padding: 30px 18px  
        }   
        &.focus{
            @media screen and (max-width: 800px){
                height: calc(100% - 150px);
                padding: 10px 18px  
            }
        }
}
`;
export const eventPopUpBottom = styled.div`
`;
