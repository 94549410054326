import styled from 'styled-components/macro';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const links = styled.div`
    position: fixed;
    bottom: 10px;
    inset-inline-start: 10px;
    display: flex;
    font-size: 11px;
    font-weight: 500;
    ${mobileMedia}{
        &.keyboardOpen{
            display: none;
        }
    }
`;

export const link=styled.div`
    cursor: pointer;
`;