import {useRef} from 'react';
import * as css from './Tooltip.css';

function Tooltip({children, text, dataTitleIcon}) {

    const refChild = useRef();
    const refTooltip = useRef();
    //, dir = "bottom"

    const handleMouseEnter = () => {
        let childPosition = refChild.current.getBoundingClientRect();
        let top = childPosition.top, left = childPosition.left;
        let tooltipPosition = refTooltip.current;
        tooltipPosition.style.left = (left - tooltipPosition.offsetWidth / 2 + refChild.current.offsetWidth / 2) + 'px';
        tooltipPosition.style.top = (top + refChild.current.offsetHeight + 5) + 'px';
        tooltipPosition.style.visibility = 'visible';
    };

    const handleMouseLeave = () => {
        refTooltip.current.style.visibility = 'hidden';
    };
    return (<>
        <css.wrapChild ref={refChild} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
        </css.wrapChild>
        <css.tooltipWrap data-cy='ticketDisplayText' ref={refTooltip} dataTitleIcon={dataTitleIcon}>{text}</css.tooltipWrap>
    </>
    );
}
export default Tooltip;
