import * as css from './PopOnPop.css';
import {useEffect, useRef} from 'react';

function PopOnPop(props) {
    const {popOnPop={},show, close} = props;
    //popOnPop={topHeight,popOnPopTitle,popOnPopTopContent,popOnPopImage,middleHeight,popOnPopContent,popOnPopBottom,transition}
    
    const ref = useRef();

    useEffect(() => {
        return ()=>{
            close();
        };
    }, []);

    return (
        <css.popOnPop ref={ref} className={'popOnPop ' + popOnPop.transition+show}>
            <css.popOnPopClose className="popOnPopClose" onClick={() => close()} />
            <css.popOnPopContent className="popOnPopContent">
                <css.popOnPopTop className="popOnPopTop" height={popOnPop.topHeight}>
                    <css.popOnPopTitle className="popOnPopTitle">{popOnPop.popOnPopTitle}</css.popOnPopTitle>
                    {popOnPop.popOnPopTopContent}
                    {popOnPop.popOnPopImage}
                </css.popOnPopTop>
                <css.popOnPopMiddle className="popOnPopMiddle" height={popOnPop.middleHeight}>{popOnPop.popOnPopContent}</css.popOnPopMiddle>
                <css.popOnPopBottom className="popOnPopBottom">{popOnPop.popOnPopBottom}</css.popOnPopBottom>
            </css.popOnPopContent>
        </css.popOnPop>
    );
}

export default PopOnPop;
