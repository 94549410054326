import React, {useEffect, useRef, useState} from 'react';
import * as css from './AbsoluteImageEditor.css';
import ImageEditor from '../../imageEditor/ImageEditor';
import FileUploader from '../../dropzone/Dropzone';
import setGlobalState from '../../../redux/setGlobalState';
import {defaultImage} from '../../../assets/symbols/images/defaultImage';
import useShow from '../../../hooks/useShow';
import useMobile from '../../../hooks/useMobile';

function AbsoluteImageEditor({setImage = () => { }, image, size, update, children, limitMB=1,type}) {

    const isMobile = useMobile();
    let newSize = !size ? (isMobile ? {width: 300, height: 200} : {width: 390, height: 260}) : size;
    const isEditImageTrue=()=>{setGlobalState('ui.editImage.isEditImage', true);};
    const isEditImageFalse=()=>{setGlobalState('ui.editImage.isEditImage', false);};
    const [uploadedImage,setUploadedImage]= useState('');

    const ref = useRef(null);

    const openEditImage = useShow();

    const onLoadEnd = (file) => {
        setUploadedImage(file);
        setGlobalState('ui.editImage.isShowAnimate',0);  
    };

    const uploadImage = (files) => {
        openEditImage.open();  
        onLoadEnd(files[0]);
        isEditImageTrue();
    };
    
    const saveImage = (image) => {
        setImage(image);
        isEditImageFalse();
        setUploadedImage('');
        openEditImage.close();
        if(update)
            update(image);
    };

    useEffect(()=> ()=>{isEditImageFalse();} ,[]);

    return (<>
        <css.imageStage data-cy="AbsoluteImageEditor" ref={ref} >
            <ImageEditor limitMB={limitMB} setUploadedImage={setUploadedImage} className={'share'} width={newSize.width} openEditImage={openEditImage}
                height={newSize.height} image={uploadedImage||image||defaultImage} borderRadius={0} borderRadiusAfter={'0px'} border={0} type={type}
                save={saveImage} cancel={() => isEditImageFalse()} top={ref?.current?.getBoundingClientRect().y-25}/> 
            {children?
                children(image,newSize,ref,uploadImage,openEditImage)
                :<css.upload>
                    <FileUploader width={'20px'} height={'20px'} onUpload={uploadImage} maxFiles={1} type="image/*" />
                </css.upload>
            }
        </css.imageStage>
    </>
    );
}
export default AbsoluteImageEditor;
