import styled from 'styled-components/macro';
import {BGImageCenter, LIGHT_GREEN, STRONG_BLUE, mobileMedia, scrollbar} from '../../globalVariables/GlobalVariables';
import {blackSuccess} from '../../assets/symbols/symbols/Symbols.css';

export const designEmailIcon=styled.div`
    cursor: pointer;
	width:40px;
	height:40px;
    border-radius: 50%;
	${p=>BGImageCenter(p.icon,'22px')}
    position: relative;
    :hover{
        box-shadow: 0 3px 6px #00000029;
        transition: all 300ms;
    }
    &.selected{
        cursor: unset;
        pointer-events: none;
        ::before{
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: ${LIGHT_GREEN};
            border-radius: 50%;
            top: -2px;
            inset-inline-start: -4px;
            ${BGImageCenter(blackSuccess,'8px')}
    }
        border: 1px solid ${STRONG_BLUE};
    }
`;

export const wrapper = styled.div`
    width:85px;
    display: flex;
    align-items: center;
    flex-direction: column;
    ${mobileMedia}{
        width: max-content;  
        margin: 0 20px;
    }

`;

export const text = styled.div`
  font-size: 12px;
  text-align: center;
`;

export const right = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    ${mobileMedia}{
        flex-direction: row;
        justify-content: center;
        width: 95%;
        padding: 5px 7px 25px 7px;
        ${scrollbar};
        max-width: 630px;
        gap: 0;
    }
`;
