import {Else, If, Then} from 'react-if';
import BasicInput from '../basicInput/BasicInput';
import DisabledInputFormik from '../disabledInput/DisabledInputFormik';
import {CountriesPhoneList} from '../countriesPhoneList/CountriesPhoneList';
import * as css from './phoneInput.css';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {useRef} from 'react';

function PhoneInput({countryFieldName = '', name, text = '', description = '', icon = '', disabled = false, events, className}) {
    const {t} = useTranslation('global');
    const [field] = useField(countryFieldName);
    const ref = useRef();

    return (
        <css.phoneInputWrapper ref={ref}>
            <If condition={disabled}>
                <Then>
                    <DisabledInputFormik
                        events={events}
                        name={name}
                        text={text}
                        description={description}
                        icon={icon}
                    />
                    <css.countryImage>
                        <img src={`https://flagcdn.com/w20/${field.value?.toLocaleLowerCase()}.png`} width="20" />
                    </css.countryImage>
                </Then>
                <Else>
                    <BasicInput
                        types={{fieldType:'input',inputType:'tel'}}
                        events={events}
                        name={name}
                        text={text}
                        description={description}
                        icon={icon}
                        className={className}/>
                    <CountriesPhoneList
                        onUpdate={events?.onBlur}
                        fieldName={countryFieldName}
                        t={t}
                        listHeight={100}
                        listWidth={ref.current?.offsetWidth - 25} />
                </Else>
            </If>
        </css.phoneInputWrapper>

    );
}

export default PhoneInput;
