import MobilePopupModal from '../mobilePopup/MobilePopupModal';
import {DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';
import useMobile from '../../../hooks/useMobile';
import PlusIconToPop from '../../../layout/plusIconToPop/PlusIconToPop';
import ColorAndImageTitle from './colorAndImageTitle/ColorAndImageTitle';

function PopUpWithHeader({show, title, color=DEFAULT_COLOR, image, topChildren, children, dataCyName}) {
    const isMobile = useMobile();
    return (
        <MobilePopupModal close={show.close} showClass={show.showClass} dataCyName={dataCyName}>
            <>
                {isMobile && <PlusIconToPop show={show}/>}
                {topChildren}
                <ColorAndImageTitle color={color} image={image} text={title}/>
                {children}
            </>
        </MobilePopupModal>
    );
}

export default PopUpWithHeader;