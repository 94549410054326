import {useMemo, useRef, useState} from 'react';
import * as css from './InputEdit.css';

function InputEdit(props) {
    const {value,saveInput,height,width,icon,widthIcon,onClick}=props;
    const [inputValue,setInputValue]=useState(value);
    const handleInputChange=(e)=>{
        setInputValue(e.target.value);
    };
    const refInput=useRef();
    const save=()=>{
        if(refInput.current.value!=value)
            saveInput(refInput.current.value);
    };
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            save();
        }
    };

    const blur = useMemo((e) => ({
        onBlur: () => save(),
    }), []);
    return (
        <css.inputEdit height={height} width={width}>
            <css.inputEditIcon width={widthIcon} icon={icon} />
            <css.inputEditInput onClick={onClick}><css.inputEditInputInput {...blur} ref={refInput} value={inputValue} onChange={handleInputChange} onKeyPress={handleKeyPress}/></css.inputEditInput>
        </css.inputEdit>
    );
}

export default InputEdit;
