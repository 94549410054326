import ImagePopup from '../../../../../component/imagePopup/ImagePopup';
import * as css from './CourseDetails.css';
import {useField} from 'formik';

function CourseImage() {
    const [imageField,,imageHelpers] = useField('imgData.image');
    return (
        <css.wrapImage>
            <ImagePopup 
                imageProps={
                    {   
                        size:{width: 160, height: 160},
                        image:imageField.value,
                        setImage: imageHelpers.setValue,
                        type: 'image/png'
                    }
                }
                fieldName={'imgData'}
            />            
        </css.wrapImage>
    );
}

export default CourseImage;