import styled from 'styled-components/macro';
import {smallUnVerified, smallVerified} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const white=styled.div`
    color: #FFF;
`;

export const item=styled(white)`
    font-size: 12px;
    font-weight: 500;
    display: flex;
    padding-top:2px
`;

export const icon=styled(white)`
    height: 17px;
    width: 17px;
    &.verified{
        ${BGImageCenter(smallVerified,'11px')};
    }
    &.unVerified{
        ${BGImageCenter(smallUnVerified,'11px')};
    }
  
`;

export const text=styled(white)`

`;
