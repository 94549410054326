import * as css from './Templates.css';
import mailingTemplates from '../../../library/mailingTemplates';
import {useState} from 'react';
import {useSelector} from 'react-redux';

function Templates(props) {
    const {properties, area, setSubject, setContentVal, closePopOnPop} = props;
    const [{templateContent, indexContent}, setTemplateContent] = useState({templateContent: 0, indexContent: -1});
    const textParameters=useSelector(s=>s.mailing.parameters);
    const openTemplateContent = (open, index) => {
        setTemplateContent({templateContent: open, indexContent: index});
    };
    const templateSelection = (subject, newContent) => {
        setSubject(subject);
        setContentVal(newContent);
        closePopOnPop('');
    };

    return (
        <>
            {mailingTemplates(area, properties.events.event, properties.user, textParameters)?.map((item, index) => (
                <css.templateItem className="templateItem" key={index}>
                    <css.templateItemIn className="templateItemIn" key={index} onMouseOver={() => { openTemplateContent(1, index); }} onMouseLeave={() => { openTemplateContent(0, index); }}>
                        <css.templateTitle className="templateTitle">{item.title}</css.templateTitle>
                        <css.templateContent className="templateContent">{(item.line)}</css.templateContent>
                        {templateContent == 1 && index == indexContent ? (
                            <css.contentOpened className="contentOpened">
                                <css.contentOpenedTitle className="contentOpenedTitle">{item.title}</css.contentOpenedTitle>
                                <css.contentOpenedText className="contentOpenedText">{item.opened}</css.contentOpenedText>
                            </css.contentOpened>
                        ) : <></>}
                    </css.templateItemIn>
                    <css.templatePlus className="templatePlus" onClick={() => { templateSelection(item.title, item.content); }} />
                </css.templateItem>
            ))}
        </>
    );
}

export default Templates;