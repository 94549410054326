import styled from 'styled-components/macro';
import {LIGHT_GRAY, OLIVE_GREEN} from '../../../globalVariables/GlobalVariables';
import { text } from '../../../component/status/Status.css';

export const wrapper = styled.div`
    height: 70px;
    background-color: ${LIGHT_GRAY};
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    ${text}{
        display: none;
    }
`;
export const status = styled.div`
    height: 20px;
    width: 60px;
    background-color: ${OLIVE_GREEN};
    border-radius: 10px;
    text-align: center;
`;