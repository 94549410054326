import Btn from '../../../component/button/btn/Btn';
import * as css from './AttachedMessage.css';
import AttachedMessageWrapper from '../AttachedMessageWrapper/AttachedMessageWrapper';

function AttachedMessage({texts, icon, background = 'dd2162',className, close, showClass,btnArr=[]}) {

    return (
        <AttachedMessageWrapper background={background} close={close} showClass={showClass}>
            <css.main>
                <css.icon icon={icon}/>
                <css.text>
                    <css.title className={className}>{texts.title}</css.title>
                    <css.content className={className}>{texts.content}</css.content>
                </css.text>
            </css.main>
            <css.buttons>
                {btnArr?.map((btn, index) => {
                    return <Btn key={index} onClick={btn.onClick} className={'white'}>{btn.text}</Btn>;
                })}
            </css.buttons>
        </AttachedMessageWrapper>
    );
}

export default AttachedMessage;
