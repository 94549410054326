import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import useEffectUpdate from '../../hooks/useEffectUpdate';
import setGlobalState from '../../redux/setGlobalState';
import * as css from './Dropzone.css';
import {useRejectedFiles ,validator} from '../../hooks/useRejectedFiles';
import {bounceMessage} from '../../layout/userMessages/UserMessage';
import {useTranslation} from 'react-i18next';
import {fileCompressor} from '../../library/fileCompressor';

function FileUploader({onUpload,maxFiles,width,height,btnTitle,type}) {
    const [files, setFiles] = useState([]);
    const  {rejectedMessage,checkRejectedOnUpload}=useRejectedFiles();
    const {t} = useTranslation('layout');
    
    const setFileAfterCompress=(files)=>{
        let newFiles = files.map(file => {
            setGlobalState('ui.editImage.isShowAnimate',1);

            return file;
        });
        setFiles(newFiles);
    };

    const uploadFileHandler = (files, rejectedFiles, e) => {
        e.stopPropagation();
        checkRejectedOnUpload(rejectedFiles);
        if(files.length<1){
            bounceMessage(t('upLoudImageWarning'),'warning');
            return ;
        }
        console.log(files[0]);
        if(files.length<2)
            fileCompressor(files[0],(f)=>setFileAfterCompress([f]));
    };

    const _onUpload =()=>{
        if(onUpload)
            onUpload(files);
    };
    useEffectUpdate( useCallback(_onUpload, [files]));

    const {getRootProps, getInputProps} = useDropzone({
        accept: type,
        maxFiles : maxFiles,
        multiple : maxFiles === 1 && false,
        validator,
        onDrop: (acceptedFiles, fileRejections, event) => {
            uploadFileHandler(acceptedFiles, fileRejections, event);
        }
    });

    // useEffect(() => () => {
    //     // files.forEach(file => URL.revokeObjectURL(file.preview));
    //     onUpload(files);
    // }, [files]);

    return (
        <css.drop width={width} height={height}><section className="container">
            <div {...getRootProps({className: 'dropzone'})} data-cy="dropzone" >
                <input {...getInputProps()} />
                <p className="title">{rejectedMessage?rejectedMessage:btnTitle}</p>
            </div>
        </section></css.drop>
    );
}

export default FileUploader;