import React, {useState, useEffect} from 'react';
import './Button.css';
import OppositeColor from '../../Functions/OppositeColor';

export default function Button(props) {
    const [buttonColor, setButtonColor] = useState({});
    const [image, setImage] = useState({});
    const [imageColor, setImageColor] = useState({});
    useEffect(() => {
        setButtonColor(props.colorButton ? {colorButton: props.colorButton} : {});
        setImage(props.img ? {image: props.img} : {});
        setImageColor(props.colorImg ? {imageColor: props.colorImg} : {});
    }, [props]);

    const colorHover = {
        '01acf1': 'ffffff',
        '7f9810': 'ffffff',
        'ff0000': 'ffffff',
    };

    return (
        <div
            className={'button' + (props.additionalClass ?  ' '+ props.additionalClass : '')}
            {...buttonColor}
            {...image}
            {...imageColor}
            onClick={(event)=> props.handle ? props.handle(event) : undefined}
            onMouseEnter={() => {
                setButtonColor(props.colorButton ? {colorButton: colorHover[props.colorButton] ? colorHover[props.colorButton] : OppositeColor(props.colorButton)} : {});
                setImageColor(props.colorImg ? {imageColor: colorHover[props.colorImg] ? colorHover[props.colorImg] : OppositeColor(props.colorImg)} : {});
            }}
            onMouseLeave={() => {
                setButtonColor(props.colorButton ? {colorButton: props.colorButton} : {});
                setImageColor(props.colorImg ? {imageColor: props.colorImg} : {});
            }}
        >
            <span>
                {props.text}
            </span>
            {props.active && <div className="loading before-loading" />}
            {props.error && <div className="button-error">!</div>}
        </div>
    );
}
