import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export default function useHashScroll(refMap) {

    const location = useLocation();

    useEffect(() => {
        // Extract the hash from the current URL (without the '#' prefix)
        const hashValue = location.hash.substring(1);
        // Find the corresponding ref in the provided map
        const targetRef = refMap[hashValue];

        // If a ref is found, scroll it into view
        if(targetRef?.current) {
            targetRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, []); // The empty dependency array ensures this runs once on mount
}
