import {useEffect, useRef} from 'react';
import useShow from '../../../hooks/useShow';
import TopPopup from '../topPopup/TopPopup';
import * as css from './WrapPopup.css';

function WrapPopup({children, closePop, title,list,height,type}) {

    const {open, isOpen, showClass} = useShow();

    // const ref = useRef(null);
    // useOnClickOutside(ref, () => {
    //     closePop()
    // })

    const isFirstRun = useRef(true);

    useEffect(() => {
        open();
    }, []);

    useEffect(() => {

        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if(isOpen === false)
            closePop();
    }, [isOpen]);
    //remember : closepopup
    return (
        <>
            <css.popUpWrapper data-cy={`${type}WrapPopup`}>
                <css.popUpBody className={showClass} height={height}>
                    <TopPopup closePop={closePop} title={title} list={list}>
                        {children[0] ? children[0] : <></>}
                    </TopPopup>
                    {children[1] || children}
                </css.popUpBody>
            </css.popUpWrapper>
        </>
    );
}

export default WrapPopup;
