import {useTranslation} from 'react-i18next';
import * as css from './SearchLine.css';

function SearchLine({filter, setFilter,action, title}) {
    const {t} = useTranslation('global');

    const onChange = (e) => {
        e.stopPropagation();
        setFilter(e.target.value);
        if(action){
            action(filter);
        }
    };

    return (
        <>
            <css.container onClick={e=>e.stopPropagation()} >
                <css.input value={filter} autoFocus autocomplete="off" name='search' placeholder={title || t('searchPlaceHolder')} onChange={onChange} />
            </css.container>
        </>
    );
}

export default SearchLine;
