import * as css from './DateInput.css';
import {useField} from 'formik';
import DatePicker from '../../FormikDatePicker/DatePicker';
import TooltipAbsolute from '../../tooltip/tooltipAbsolute/TooltipAbsolute';

function DateInput({className,name,text,descText,onChange,datePickerProps = {},readOnly,isFixed,icon}) {
    const [, meta] = useField(name);
    const classes = `${className}  ${
    !!meta.touched && (meta.error ? ' invalid' : ' valid')
  }`;
    const hasIcon=icon!==false;
    return (
        <>
            <css.container className={classes}>
                {hasIcon&&<css.icon className={classes}
                    data-cy={`${name ? name.substring(name.indexOf('.') + 1) : 'default'}Validation`}/>}
                <css.wrap className={hasIcon&&'hasIcon'}>
                    <css.inputs>
                        <css.wrapDate data-cy={`${name ? name.substring(name.indexOf('.')+1): 'default'}Input`}>
                            <css.dateText>{text}</css.dateText>   
                            <css.wrapPicker >
                                <DatePicker
                                    name={name}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                    isFixed={isFixed}
                                    {...datePickerProps}
                                    
                                />
                                {meta.touched && meta.error && (
                                    <TooltipAbsolute show={true} text={meta.error}  />
                                )}
                            </css.wrapPicker>
                        </css.wrapDate>
                    </css.inputs>
                    <css.text>
                        <css.descText> {descText} </css.descText>
                    </css.text>
                </css.wrap>
            </css.container>
        </>
    );
}

export default DateInput;
