import {hasEventRoleAccess} from '../../../functions/permission';
import store from '../../../store';
import RolePermission from './RolePermission';

const getRole=() => {
    if(store.getState().event.eid)
        return store.getState().event.userEventRole;
    return store.getState().account.userAccountRole;
};

function EventRolePermission({rolePermission,views=['all'],children}) {

    let hasAccess=hasEventRoleAccess(rolePermission);
    const role=getRole();

    return (
        <RolePermission hasAccess={hasAccess} role={role} views={views} >
            {children}
        </RolePermission>

    );
}

export default EventRolePermission;
