import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import * as css from './ClusterEvents.css';
import ClusterGroup from '../clusterGroup/ClusterGroup';
import {useTranslation} from 'react-i18next';
import useShow from '../../../../hooks/useShow';
import EventPopUp from '../eventPopUp/EventPopUp';
import {getClusterGroupEvents, updateEventsWeight} from '../../../../redux/cluster/clusterActions';
import setGlobalState from '../../../../redux/setGlobalState';
import {changeArrColorTemp} from '../groupImagePopUp/GroupImagePopUp';
import store from '../../../../store';
import Table from '../../../../component/newTable/Table';
import ClusterEventRow from '../clusterEventRow/ClusterEventRow';
import updateArrayAfterDrag from '../../../../functions/updateArrAfterDrag';
import useTablet from '../../../../hooks/useTablet';

export const getEvents = async (eventId, cgid, limit, offset) => {
    if(store.getState().cluster.getClusterGroupEventsXHR !== 'LOADING')
        await getClusterGroupEvents(eventId, cgid, {limit: limit ? limit : 10, offset: offset ? offset : 0});
};

function ClusterEvents(props) {
    const {checked, setShowPopup, updateLimitOffset, searchState, search} = props;
    const {t} = useTranslation('cluster');
    const refTable = useRef();
    const myRefs = useRef([]);
    const cluster = useSelector((state) => state.cluster);
    const eventState = useSelector((state) => state.event);
    const groups = useSelector((state) => state.cluster.groups);
    const colorsPicker = useSelector((state) => state.ui.colorsPicker);
    const searchEvents = useSelector((state) => state.cluster.searchEvents);
    const [showEvent, setShowEvent] = useState(() => {
        let isShowEvent = {};
        groups?.forEach((item) => {
            isShowEvent[item.cgid] = item.cgid === 0;
        });
        return isShowEvent;
    });

    const [eventEdit, setEventEdit] = useState(null);
    const isTablet = useTablet();

    const openClose = useShow(0);

    const updateShowEvents = (cgid, isShow) => {
        setShowEvent(prev => ({...prev, [cgid]: isShow}));
    };

    useEffect(() => {
        if(cluster?.groups && !colorsPicker.isAllColorsDisabled) {
            cluster?.groups.forEach((g) => {
                if(g.cgid) {
                    changeArrColorTemp(g.clusterGroupColor, 'add');
                }
            });
        }
    }, []);

    const editPopUP = (event, cgid) => {
        let newEvent = Object.assign({}, event);
        newEvent.cgid = cgid;
        setEventEdit(newEvent);
        openClose.open();
    };

    const doOnUpdate = (res) => {
        let groupIndex = cluster.groups.findIndex(item => item.cgid === eventEdit.cgid);
        let eventIndex = cluster.groups[groupIndex]?.events.findIndex(item => item.eid === eventEdit.eid);
        setGlobalState(`cluster.groups[${groupIndex}].events[${eventIndex}]`, res.event ? res.event : eventEdit);
    };

    const onScroll = (e) => {
        if(Math.round(e.target.scrollHeight - e.target.scrollTop) === Math.round(e.target.clientHeight) && cluster.getClusterEventsXHR != 'LOADING') {
            updateLimitOffset('offset', cluster.groups[0].events.length);
        }
    };

    const onDragEnd = async (drag, cgid) => {
        const newArr = updateArrayAfterDrag(drag, cluster?.groups.find((item) =>item.cgid === cgid)?.events);
        const updateWeight = newArr.map((i, index) => ({eid: i.eid, weight: index}));
        await updateEventsWeight(eventState?.eid, {clusterEventsUpdate: updateWeight}, newArr, cgid ? cgid : 0);
    };

    return (
        <>
            <css.pfTable ref={refTable} onScroll={onScroll}>
                {!!searchState && search ?
                    <Table rowHeight={isTablet ? 115 : 70} title={t('clusterEvents')} list={searchEvents.map(event => ({comp: ClusterEventRow, props: {event, editPopUP}, key: event.eid}))}  />
                    :
                    <>
                        {groups?.map((item, index) => {
                            return <css.group key={item.cgid} ref={(el) => (myRefs.current[index] = el)}>
                                {<ClusterGroup
                                    group={item}
                                    index={index}
                                    getEvents={getEvents}
                                    onDragEnd={(e) => onDragEnd(e, item.cgid)}
                                    updateShowEvents={updateShowEvents}
                                    showEvent={showEvent[item.cgid]}
                                    setShowPopup={setShowPopup}
                                    refTable={refTable}
                                    myRefs={myRefs}
                                    editPopUP={editPopUP}
                                    checked={checked}
                                />}
                            </css.group>;
                        })}
                    </>
                }

            </css.pfTable>
            {!!openClose.isOpen && <EventPopUp eventPreparing={eventEdit} closePopUp={() => openClose.close()} type={'update'} callBack={doOnUpdate} />}
        </>
    );
}

export default ClusterEvents;
