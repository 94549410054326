import React, {useState} from 'react';
import * as css from './CodeEditor.css';
import {useTranslation} from 'react-i18next';
import Editor from '../editor/Editor';
import {useField} from 'formik';
import {ThemeProvider} from 'styled-components';

function CodeEditor({name,title,type,dataCyName}) {
    const {t} = useTranslation('publish');
    const upperType = type?.toUpperCase();
    const [isExpanded, setIsExpanded] = useState(false);
    const [, meta]=useField(name);

    return (
        <ThemeProvider theme={{isExpanded:isExpanded}}>
            <css.wrapper>
                <css.title>
                    <css.wrapName className={type} onClick={() => setIsExpanded(!isExpanded)}>
                        {upperType}
                    </css.wrapName>
                    <css.codePixel>{title}</css.codePixel>
                </css.title>
                <css.editor>
                    <css.copyCode>{t('copyCode')}</css.copyCode>
                    <Editor type={type} name={name} isExpanded={isExpanded} dataCyName={dataCyName}/>
                </css.editor>
                {meta.touched && meta.error && <css.error>{meta.error}</css.error>}
            </css.wrapper>
        </ThemeProvider>
    );
}

export default CodeEditor;
