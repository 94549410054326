import * as css from './Shaking.css';

function Shaking({children, className}) {

    return (
        <css.shaking className={className}>
            {children}
        </css.shaking>
    );
}

export default Shaking;
