import styled from 'styled-components/macro';
import zIndex from '../../../globalVariables/zIndex';
import {state} from '../../../component/state/State.css';
import {boxOffice} from '../../../layout/boxoffice/Boxoffice.css';
import {BGImageCenter, BLACK, DARK_GRAY, LIGHT_GRAY2, WHITE, ellipsisLines, mobileMedia, scrollbar, tabletMedia, tabletPlusMedia, resolveImage, BUTTON_HEIGHT, desktopMedia} from '../../../globalVariables/GlobalVariables';
import {ProfileMenuStyledCtr} from '../../../layout/topMenu/layoutUserProfile/layoutUserProfileMenu/LayoutUserProfileMenu.css';
import {EventMenuFormMain, smallBtn, EventMenuForm} from '../../../layout/eventMenu/formButtton/FormButtton.css';
import {wrapChild} from '../../../component/tooltip/Tooltip.css';
import {status} from '../../components/eventActive/EventActive.css';
import {displayMode, displayModeWhite, cashRegister, dragPoints} from '../../../assets/symbols/symbols/Symbols.css';
import {gridWrapper} from '../../../component/gridDrag/GridDrag.css';
import {dragHandle, item, warpChildren} from '../../../component/gridItemDrag/GridItemDrag.css';
import {windowWrapper} from '../components/window/window.css';
import realtimeFonts from '../fonts';
import {container} from '../../../component/popUpBubble/PopUpBubble.css';
import fonts from '../fonts';
import {profile} from '../components/profile/Profile.css';
import {getEventDetailsBackground} from '../style.css';

const defaultGrid = showWeather => `
  "cash cash cash cash soldTickets soldTickets soldTickets soldTickets transactions transactions transactions transactions"
  "realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData realtimeData "
  "tickets tickets tickets tickets tickets tickets tickets tickets ${showWeather?'weather weather weather weather':'message message message message'}"
    ${showWeather ? '"message message message message . . . . . . . ."':''}
`;

const defaultMobileGrid = showWeather => `
  "cash"
  "soldTickets"
  "transactions"
  "realtimeData"
  "tickets"
  ${showWeather?'"weather"':''}
  "message"
`;
const defaultTabletGrid = showWeather =>  `
  "cash transactions"  
  "soldTickets soldTickets"
  "realtimeData realtimeData"
  "tickets tickets"
  "${showWeather?'weather message':'message .'}"
  
`;

export const getColor = (isDarkMode) => {
    return isDarkMode ? WHITE : BLACK;
};
export const getBackground = (isDarkMode) => {
    return isDarkMode ? DARK_GRAY : WHITE;
};

export const wrapper = styled.div`
    background: ${p => {
    if(p.colors && p.colors.length > 0) {
        const colors = p.colors.slice(1, 3);
        colors.push(getBackground(p.theme.isDarkMode));
        const length = colors.length;
        return `linear-gradient(180deg, ${
                    colors.map((color, index) => `${color} ${index * (100 / (length - 1))}%`).join(', ')
                })`;
    } else {
        return '';
    }}};
    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, #64949C 0%, #32ACD4 50%, #FFFFFF 100%);
        opacity: ${p=>p.colors && p.colors.length > 0 ? 0 : 1};
        transition: opacity 400ms;
    }
    transition: all 0.5s;
    height: 100%;
    ${scrollbar};
    color: ${p=>getColor(p.theme.isDarkMode)};
    ${ProfileMenuStyledCtr}{
        &.show{
            top: 0;            
        }
    }
 `;

export const gridTop = styled.div`
    position: relative;
    width: 80%;
    inset-inline-start: 10%;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    ${mobileMedia}{
        width: 96%;
        inset-inline-start: 2%;
        ${profile}{
            display: none;
        }
    }

`;

export const eventDetails = styled.div`  
    width: calc(100% - 200px);
    display: grid;
    grid-template-areas: 
    "type buttonsTop"
    "name buttonsTop"
    "colors buttonsBottom"
    "details buttonsBottom"
    ;
    ${desktopMedia}{
        grid-template-columns: calc(100% - 175px) 175px;
        ${getEventDetailsBackground};
    }
    ${tabletMedia}{
        grid-template-areas: 
        "type buttonsTop"
        "name buttonsTop"
        "colors buttonsBottom"
        "details details"
        ;
    }
    ${mobileMedia}{
        width: 100%;
        grid-template-columns: min-content auto;
        grid-template-areas: 
        "buttonsTop buttonsBottom"
        "name name"
        "type type"
        "details details";
        position: relative;
        height: max-content;
        padding: 0;
    }
    
 `;

export const type = styled.div`
    grid-area: type;
    ${realtimeFonts.text}
    height: 26px;
    align-items: center;
    display: flex;
    position: relative;
    padding-inline-start: 30px;
    color: ${p=>getColor(p.theme.isDarkMode)};
    ${mobileMedia}{
        height: 22px;
        inset-inline-start: 5px;
        padding-inline-start: 20px;
    }
    ::before {
        content: '';
        height: 20px;
        width: 20px;
        position: absolute;
        background-color: ${p=>p.theme.isDarkMode? WHITE : BLACK};
        background-image: none;
        -webkit-mask-image: url("${p=>resolveImage(p.icon)}");
        mask-image: url("${p=>resolveImage(p.icon)}");
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 18px 18px ;
        inset-block-start: 3px;
        inset-inline-start: 0;
        ${mobileMedia}{
            height: 16px;
            width: 16px;
            -webkit-mask-size: 14px 14px ;
        }
    }
 `;

export const eventName = styled.div`
    grid-area: name;
    ${fonts.title}
    color: ${p=>getColor(p.theme.isDarkMode)};
    ${ellipsisLines(2)}
    ${mobileMedia}{
        inset-inline-start: 5px;
        height: 50px;
        align-items: center;
        ${ellipsisLines(2)}
    }
 `;

export const colors = styled.div`
    grid-area: colors;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
     ${mobileMedia}{
        display: none;
    }
 `;

export const color = styled.div`
    width: 12px;
    height: 13px;
    border-radius: 5px;
    background-color: ${p => p.color};
 `;

export const details = styled.div`
    grid-area: details;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
    flex-wrap: wrap;
    ${desktopMedia} {
        min-width: max-content;
    }
    ${tabletMedia}{
        min-width: fit-content;
    }
    ${mobileMedia} {
        width: 100%;
        ${getEventDetailsBackground};
        padding: 15px;
    }
 `;

export const buttons = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    min-height: max-content;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
`;
export const buttonsBottom = styled(buttons)`
    grid-area: buttonsBottom;
    justify-content: flex-end;
    ${EventMenuFormMain}{
        position: relative;
        top: unset;
        margin-inline-start: unset;
            flex-direction: row-reverse;
    ${mobileMedia}{
        flex-direction: row;
    }
         ${smallBtn}{
            box-shadow: 0 3px 6px #00000029;
        }
    
        ${EventMenuForm}{
            width: 110px;
            ${desktopMedia}{
                margin-inline-end: 0;
            }
            a{   
                margin-inline: auto;
            }
        }
    }
    ${mobileMedia}{
        width: 100%;
        height: 44px;
    }
    ${status}{
        height: 30px;
        z-index: ${zIndex.baseRaised};
        position: relative;
        width: fit-content;
        ${state}{
            width: 110px;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid ${LIGHT_GRAY2};
            box-shadow: 0 3px 6px #00000029;
        }
        ${mobileMedia}{
            display: none;
        }
    }
 `;
export const buttonsTop = styled(buttons)`
    margin-inline-start: 20px;
    grid-area: buttonsTop;
    justify-content: flex-start;
    ${mobileMedia} {
        flex-direction: row-reverse;
        gap:0;
        width: fit-content;
        margin-bottom: 15px;
        margin-inline-start: unset;

    }
`;
export const wrapButton = styled.div`
    position: relative;
    inset-inline-end: 67px;
    bottom: ${BUTTON_HEIGHT + 5}px;
    .close{
        height: 30px;
        top: 0;
        inset-inline-start: 0;
    }
    ${container}{
        position: absolute;
        inset-inline-end: -205px;
        top:-105px;
        ::before{
            margin-inline-start: 90px;
            top: auto;
            bottom: -8px;
        }
    }
`;
export const reportPop = styled.div`
    width: 300px;
    height: fit-content;
    white-space: pre-line;    
    padding: 20px;
    font-weight: 500;
    text-align: center;
`;
export const top = styled.div`
    height: 60px;
    display: flex;
    justify-content: flex-end;
    width: 80%;
    position: relative;
    inset-inline-start: 10%;
    ${mobileMedia}{
        position: absolute;
        width: 50%;
        inset-block-end: -30px;
        inset-inline-start: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        z-index: ${zIndex.baseHigh};
    }
    ${wrapChild}{
        ${boxOffice}{
            ${tabletPlusMedia}{
                width: 35px;
                height: 60px;
                background-color: ${p=>p.theme.isDarkMode? WHITE : BLACK};
                background-image: none;
                -webkit-mask-image: url("${resolveImage(cashRegister)}");
                mask-image: url("${resolveImage(cashRegister)}");
                -webkit-mask-position: center;
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: 25px 25px ;
                box-shadow: none;
            }
        }
    }
 `;

export const backgroundDisplay = styled.div`
    height: 60px;
    width: 35px;
    cursor: pointer;
    ${p=>BGImageCenter(p.theme.isDarkMode ? displayModeWhite : displayMode, '25px 25px')};
    transition:all 400ms;
    :hover{
        transform: scale(1.1);
    }
    ${mobileMedia}{
        position: absolute;
        inset-block-end: -25px;
        ${BGImageCenter(displayMode, '25px 25px')}
    }
 `;
 
export const oldSystem = styled.div`
    cursor: pointer;
    position: relative;
    inset-inline-start: 10%;
    inset-block-end: 10px;
    width: max-content;
    color: ${p=>getColor(p.theme.isDarkMode)};
    margin-top: 10px;
    ${mobileMedia}{
        margin-block:10px 20px;
    }
    :hover{
        text-decoration: underline;
    }
`;

export const gridContainer = styled.div`
    ${gridWrapper} {
        grid-gap: 20px;
        grid-template-areas: ${p=>defaultGrid(p.showWeather)};
        /* grid-template-areas: ${p=>p.gridAreas}; */
        z-index: ${zIndex.base};
        width: 80%;
        inset-inline-start: 10%;
        grid-template-columns: repeat(12, 1fr);
        position: relative;
        
        ${tabletMedia}{
        grid-template-areas:${p=>defaultTabletGrid(p.showWeather)};
        grid-template-columns: repeat(2, 1fr);
        }
        ${mobileMedia}{
            width: 96%;
            inset-inline-start: 2%;
            grid-template-areas: ${p=>defaultMobileGrid(p.showWeather)};
            grid-template-columns: repeat(1, 1fr);
            z-index: unset;
        }
    }
    ${warpChildren} {
        width: 100%;
        z-index: unset ;//todo update when use with drag 
    }
    ${item} {
        width: 100%;
        position: relative;
        box-shadow: none;
        color: inherit;
        background-color: #F8F8F8;
        border-radius: 15px;
        transform: unset;//todo update when use with drag  
        z-index: unset !important;//todo update when use with drag  
        :hover{
            ${warpChildren}{
                &:has(.draggable) {
                    width: calc(100% - 20px);
                    transition: all 300ms;
                }
                margin-left: 0;
                transform: unset; //todo update when use with drag  

            }
            ${windowWrapper}.draggable {
                transition: all 300ms;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        ${props=>props.windowsList?.map(win=> `&:has(.${win}) {grid-area: ${win}}`)};
        ${dragHandle} {
            width: 20px;
            transition: width;
            background-color: #F8F8F8;
            ${p=>p.theme.rtl?`
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            `:`
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            `};
            box-shadow: none;
            ${p=>BGImageCenter(p.theme.isDarkMode?dragPoints:dragPoints, '24px')}
        }
}
`;