import React from 'react';
import './TableAction.css';
import Checkbox from '../../Checkbox/Checkbox';
import t from '../../../TranslateHe';

function TableAction(props) {
    return (
        <div className="table-action">
            <div className="select-all">
                <Checkbox selected={props.selected} handle={props.handle} id="selectAll"/>
            </div>
            <div className="text">{t('Select all')}</div>
        </div>
    );
}

export default TableAction;