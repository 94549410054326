import React from 'react';
import './TableFilter.css';

function TableFilter(props) {
    if(!props.filter || !props.filter.active){
        return null;
    }
    return (
        <div className="table-filter">
            <div className="table-filter-items">
                <div className="table-filter-icon" image="filter" imageColor="ffffff" />
                <div className="table-filter-text">filter</div>
                <div className="table-filter-list" image="list" imageColor="ffffff" />
            </div>
        </div>
    );
}

export default TableFilter;