import styled from 'styled-components/macro';
import {dFlexCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const CheckboxWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    padding: 0px 20px;
    margin-top:10px;
    margin-bottom:50px;
`;
export const wrapBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom:10px;
    margin-inline-end:10px;
    ${mobileMedia}{
    ${dFlexCenter};
    gap: 10px;
  }
`;