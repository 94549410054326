import styled from 'styled-components/macro';
import {remove} from '../../../../../assets/symbols/symbols/Symbols.css';
import {dFlexCenter,scrollbar} from '../../../../../globalVariables/GlobalVariables';
import {circle} from '../circleBtn/CircleBtn.css';

export const space = styled.div`  
  height:35px ;
`;
export const container = styled.div`  
width:100% ;
padding-inline-start:25px;
height:150px;

  `;
export const items = styled.div`  
  ${scrollbar}
  height:120px;
`;

export const item = styled.div`  
  margin:8px 0 ;
  width:100% ;
  background-color:#F2F2F2 ;
  border-radius:20px;
  border:1px solid;
  padding:0 2px ;
  height:30px ;
  ${dFlexCenter}
  :hover{
    ${circle}{
      background-image:url("${remove}") ;
    }
  }
`;
export const text=styled.div` 
  width:calc(100% - 25px) ;
  height:25px ;
  line-height:25px ;
  padding-inline-start:10px;
 `;
export const add=styled.div`
  padding-inline-start:5px;
  display:flex ;
  span{
    padding-inline-start:10px ;
  }
`;