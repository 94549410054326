import * as css from './Page404.css';
import Btn from '../../component/button/btn/Btn';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import getPathByEventRole from '../../functions/getPathByEventRole';
import {useEffect} from 'react';
import TickchakLoader from '../../component/tickchakLoader/TickchakLoader';
import {getDefaultPath} from '../../Routes';

function Page404({eidParam,pidParam}) {
    const {t}=useTranslation('global');
    const history=useHistory();
    const event = useSelector(s=>s.event);
    const account = useSelector(s=>s.account);
    const {defaultPid,defaultEid} = useSelector(state => state.user);
    useEffect(()=>{
        if(eidParam && Number(eidParam)===event.eid && ['SUCCESS','FAILED','UPDATED'].includes(event.getEventXHR)) {
            const defaultEventPath=getPathByEventRole(event);
            history.push(defaultEventPath);
        }
    },[event.eid,eidParam]);

    let isWait=!((
        (eidParam)
        &&['SUCCESS','FAILED','UPDATED'].includes(event.getEventXHR))
    ||(
        (![account.getAccountUserPermissionsXHR,account.getAccountPermissionsXHR].includes('LOADING'))
        &&
        (pidParam&&['SUCCESS','FAILED','UPDATED'].includes(account.getAccountXHR))
    ));

    if(eidParam && event.eid && event.eid!==Number(eidParam))
        isWait=true;

    if(pidParam && account.pid && account.pid!==Number(pidParam))
        isWait=true;
    if(isWait)
        return <TickchakLoader showClass={' show'}/>;
        
    if([event?.getEventXHR, event?.getEventPermissionsXHR, event?.getAccountXHR].includes('FAILED')){
        history.push(`/${getDefaultPath(defaultPid,defaultEid)}`);
        return <></>;
    }
    return (
        <css.container>
            <css.image/>
            <css.text>
                {t('404Text')}
            </css.text>
            <Btn onClick={()=>history.push('/')}>{t('404Button')}</Btn>
        </css.container>
    );
}

export default Page404;
