import React from 'react';

const translate = {
    'email': 'אימיילים',
    'sms': 'מסרונים',
    'New': 'חדש',
    'Success': 'הצלחה',
    'Failure': 'כישלון',
    'Waiting': 'ממתין',
    'Off': 'כבוי',
    'Pending': 'ממתין',
    'Error': 'שגיאה',
    'Details and information': 'פרטים ומידע',
    'Sent in': 'נשלח ב',
    'Sent on': 'נשלח ב',
    'Recipients': 'מספר נמענים',
    'Sent': 'נשלח',
    'Open': 'נקרא',
    'From': 'מאת',
    'Sender email': 'מייל השולח',
    'Will send in': 'תשלח ב',
    'Select all': 'בחר הכל',
    'Search': 'חיפוש',
    'Sending': 'משלוח',
    'Timing': 'תזמון',
    'Messages': 'הודעות',
    'Opened': 'נפתח',
    'Fails': 'נכשל',
    'Change message timing': 'שנה את תזמון ההודעה',
    'Save': 'שמור',
    'Note! It is not possible to set a date that has already passed.': 'שים לב! לא ניתן לקבוע לתאריך שכבר חלף.',
    'Subject': 'נושא ההודעה',
    'Message content': 'תוכן ההודעה',
    'Send first message!': 'שלח הודעה ראשונה!',
    'Order completed': 'הזמנתכם הושלמה',
};

export default function (text) {
    if(translate[text]){
        return translate[text];
    } else {
        return text;
    }
}