import styled from 'styled-components/macro';
import {office,arrowDark} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter, mobileMedia ,popupScreen,scrollbar} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const popUpScreen=styled.div`
    ${popupScreen}

`;

export const Popup=styled.div`
    position: fixed;
    height: 420px;
    width: 700px;
    transition: all 500ms; 
    z-index:${zIndex.basicModal};
    top:calc(50% - 210px);
    left:calc(50% - 350px);
    border-radius:12px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 8px #00000029;
    &.show{        
    }
    ${mobileMedia}
    {
        height: 500px;
        max-height: calc(100% - 35px);
        top:unset;
        left:0;
        width: 100%;
        border-radius:12px 12px 0px 0px;
        bottom:-500px;
        &.show{    
            bottom:0;
    }
    }



`;

export const header=styled.div`
    height: 120px;
    background-color: #76D6E8;
    border-radius:12px;
    display: flex;
    align-items: center;
    ${mobileMedia}{
        height: 90px;
        border-radius:12px 12px 0 0;
    }
`;

export const headerClose=styled.div`
    ${BGImageCenter(arrowDark,'18px')}
    ${props=>props.theme.rtl?'':'transform: rotate(180deg);'}
    width: 50px;
    height: 20px;
    cursor: pointer;
`;

export const headerTitle=styled.div`
    ${mobileMedia}{
        position: absolute;
        top: 105px;
        padding: 20px;
    }
    font-size: 15px;
    font-weight: 600;
`;
export const headerId=styled.div`
    font-size: 10px;
    line-height: 25px;
    font-weight: 200;
`;
export const headerIcon=styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    top:-35px;
    ${props=>props.theme.rtl?'left':'right'}:10%;
    background-color: #76D6E8;
    overflow: visible;
    ${dFlexCenter}
    ::before{
        position: absolute;
        content: "";
        ${BGImageCenter(office,'150px')}
        width: 150px;
        height: 150px;
    }

`;

export const headerOffice=styled.div`
    padding-top: 110px;
    ${mobileMedia}{
        font-size: 14px;
        font-weight: 900;
        position: absolute;
        width: max-content;
        left: calc(50vw - 45px);
        bottom: 32px;
    }


`;
export const body=styled.div`
    display: flex;
    height: calc(100% - 190px);
    padding-top:80px;
    padding-inline:100px;  
    ${mobileMedia}{
        ${scrollbar}
        display: block;
        padding-inline:5%;
        padding-top: 0px;
        margin-top:80px;
        height: calc(100% - 240px);
    }

`;

export const leftRight=styled.div`
    width:50%;
    ${mobileMedia}{
        width: 100%;
        
    }
`;

export const leftInput=styled.div`      

`;
export const bottom=styled.div`
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding-inline: 30px;
    padding-bottom: 35px;
    align-items: center;
    ${mobileMedia}{
        border-top: 1px solid #EEE;
        padding-bottom: 0;
    }
`;