import * as css from './ExternalImagePopup.css';
import {useTranslation} from 'react-i18next';
import {Formik, FormikProps} from 'formik';
import {defaultImage} from '../../../assets/symbols/images/defaultImage';
import useShow from '../../../hooks/useShow';
import WarningPopup from '../warningPopup/WarningPopup';
import MobilePopup from '../../newPopup/mobilePopup/MobilePopup';
import Btn from '../../button/btn/Btn';
import {useEffect, useRef, useState} from 'react';
import {creditForm, TImageProps} from '../ImagePopup';
export type TCreditProps = {
    creditName: string,
    license: string,
    alt: string
}
function ExternalImagePopup({imageProps, creditProps, absoluteImage}: {imageProps: TImageProps, creditProps: TCreditProps, absoluteImage?: boolean}) {
    const {t} = useTranslation('layout');
    const {open, close, showClass, isOpen} = useShow();
    const [imageData, setImageData] = useState({image: imageProps.image, credit: {...creditProps}});
    const warningShow =  useShow();
    const formRef = useRef<FormikProps<any>>(null);

    const initialValues = {
        credit: {...creditProps}
    };
    const saveImage = (credit, userApproveWithoutCredit = 0) => {
        const image = {image: imageData.image, imageId: imageProps.imageId, credit: {...credit, userApproveWithoutCredit}};
        if(imageProps?.update)
            imageProps?.update(image);
        if(imageProps?.setImage)
            imageProps?.setImage(image);
        close();
    };

    const onSubmit = async (values) => {
        if(!imageData?.image){
            setImageData({image: null, credit: {creditName: null, license: null, alt: null}});
            saveImage({creditName: null, license: null, alt: null});
        }
        else if(!values?.credit?.creditName){
            warningShow.open();
        }
        else {
            saveImage(values?.credit);
        }
    };

    const onSubmitForm = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };
    useEffect(() => {
        if(formRef.current) {
            const {values} = formRef.current;
            setImageData({image: imageData.image, credit: values?.credit});
        }
    }, [formRef.current?.values]);

    return (<>
        <css.wrapPopup>
            <css.image data-cy={'ExternalImagePopup'} absoluteImage={absoluteImage} img={imageProps.image||defaultImage} width={imageProps.size.width} height={imageProps.size.height} onClick={open}/>
            {!!isOpen && <MobilePopup title={t('imageEditTitle')} showClass={showClass} close={close} list={undefined} forwardRef={undefined} dataCyName={'ExternalImagePopup'} >
                <css.top />
                <css.wrapAll size={imageProps?.size?.width}> 
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    >    
                        {({dirty, isValid}) => (
                            <>
                                {creditForm('credit', {...imageProps, image: imageData.image, setImage:(image)=>setImageData({...imageData, image: image}), update: ()=>{}}, t)}
                                <css.buttons>
                                    <Btn className={'cancel'} onClick={close} disabled={false}>{t('close')}</Btn>
                                    <Btn onClick={onSubmitForm} className={''} disabled={!dirty && imageData.image === imageProps.image || !isValid}>{t('add')}</Btn>
                                </css.buttons>
                            </>
                        )}
                    </Formik>
                    {!!warningShow.isOpen && <WarningPopup setApprove={()=>saveImage(imageData.credit, 1)} show={warningShow}/>}
                </css.wrapAll>
            </MobilePopup>}
           
        </css.wrapPopup>
    </>
    );
}

export default ExternalImagePopup;
