import BankVerificationForm from '../bankVerificationForm/BankVerificationForm';
import VerificationSuccess from '../verificationSuccess/VerificationSuccess';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getPackageBank} from '../../../../redux/account/accountActions';
import {Else, If, Then} from 'react-if';
import useNavigationLink from '../../../../hooks/useNavigationLink';
import Btn from '../../../../component/button/btn/Btn';

function BankVerification({close}) {
    const {t} = useTranslation('package');
    const account=useSelector(s => s.account);
    const bankVerified = account?.bankVerified;

    useEffect( () => {
        getPackageBank().then();

    }, []);
    const verificationPop=useNavigationLink('packagePage','bank');
    const onClick=()=>{
        verificationPop.open();
        close();
    };
    return (
        <If condition={bankVerified}>
            <Then>
                <VerificationSuccess text={t('accountVerificationSuccess')} title ={t('bankVerification')} >
                    <Btn onClick={onClick}>{t('changeDetails')}</Btn>
                </VerificationSuccess>
            </Then>
            <Else>
                <BankVerificationForm />
            </Else>
        </If>

    );
}

export default BankVerification;