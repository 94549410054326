function getColor(color, groupsInfo) {
    const arrColor = ['10C2D1', '8700AF', 'FF8900', 'BB80C3', 'D11069', 'EBE205', 'FF4E00', 'CAD110', '5AD110', '1087D1', 'ed2df0', '07E6B3', 'C61010', 'F14E8d'];
    const randomColor = arrColor[Math.floor(Math.random() * (arrColor.length - 1))];
    let newColor = '';
    if(color)
        newColor = color;
    else {
        groupsInfo?.forEach((item) => {
            const index = arrColor.indexOf(item.color);
            if(index > -1) {
                arrColor.splice(index, 1);
            }
        });
        newColor = arrColor.length ? arrColor[0] : randomColor;
    }
    return newColor;
}
export default getColor;