import * as css from './PricesPop.css';
import {Form, useField} from 'formik';
import {useSelector} from 'react-redux';
import {addBtn, closeBtn, editBtn} from '../../../../../functions/globalBtns';
import Button from '../../../../../component/button/button/Button';
import {useTranslation} from 'react-i18next';
import PopOnPop from '../../../../../component/newPopup/popOnPop/PopOnPop';
import Input from '../../../../../component/input/Input';
import InputAmount from '../../../../../component/input/inputAmount/InputAmount';
// import PreSale from './preSale/PreSale';
import {getMonthDifference} from '../../../../../library/dates';
import {useRef} from 'react';
import * as Yup from 'yup';
import FormContext from '../../../../components/formContext/FormContext';
import {priceArrayHelpers} from '../monthlyPrice/MonthlyPrice';
import {yupStringMin} from '../../../../../functions/validationsYup';

function PricesPop({showPopOnPop, index}) {
    const {t} = useTranslation('ticket');
    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const [priceField,,priceHelper] = useField(`prices[${index}]`);
    const [courseField,] = useField('course');
    // const [preSaleField, ] = useField('preSale');
    // const preSaleFieldValue=preSaleField.value;
    let course = courseField.value;
    // const preSales=priceField?.value?.preSale;
    // const preSalesArr = preSales?.length && preSales[0]?.discount ? preSales:(preSaleFieldValue && Object.keys(preSaleFieldValue).length?[{tpsid:preSaleFieldValue.tpsid||0,discount:preSaleFieldValue.discount}]:null);
    const formRef = useRef();
    const initialValues = {
        tpid: priceField.value?.tpid||0,
        price: priceField.value?.price||'',
        title: priceField?.value?.title||'',
        // preSale: preSales,
    };

    const handleSubmit = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const validationSchema = Yup.object().shape({
        price: Yup.number().required(),
        title: yupStringMin().required()
    }
    );
    const addPrice = (_changedValues,values) => {
        if(index || index===0)
            priceHelper.setValue(values);
        else
            priceArrayHelpers.push(values);
        showPopOnPop.setShow(0);
    };

    return (<>
        <PopOnPop dataCy='price' image={'groupColor'} title={t('priceList')} showPopOnPop={showPopOnPop} topHeight={190}>
            <FormContext innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={addPrice}
            >
                {
                    ({values})=>
                        <Form>
                            <css.wrapContentPop>
                                <css.wrapAll>
                                    <Input  dataCy={'priceListName'} name={'title'}  text={t('priceListName')} description={t('priceListNameDesc')} />
                                    <InputAmount name={'price'} text={t('pricePerMonth')} description={t('pricePerMonthDesc')} type={'input'} amountText={currencySymbol} />
                                    <css.wrapMonths>
                                        <css.text data-cy="monthSum">{((course.startDate && course.endDate) ? getMonthDifference(course.startDate , course.endDate) : 0) + ' ' + t('months')}</css.text>
                                        <css.text data-cy="sum">{t('total') + ' ' + ((course.startDate && course.endDate) ? (values.price||0) * getMonthDifference(course.startDate,course.endDate) : 0) + ' ' + currencySymbol}</css.text>
                                    </css.wrapMonths>
                                    {/*{preSalesArr&&<FieldArray name={`prices[${index}].preSale`}>*/}
                                    {/*    {() => (*/}
                                    {/*        preSalesArr?.map((_item,indexPreSale)=>{*/}
                                    {/*            return <PreSale key={_item.tpsid} index={indexPreSale} priceIndex={index} />;*/}
                                    {/*        })*/}
                                    {/*    )}*/}
                                    {/*</FieldArray>}*/}
                                </css.wrapAll>
                            </css.wrapContentPop>
                            <css.bottomPop  >
                                <Button BtnProps={closeBtn(t, () => showPopOnPop.setShow(0))} dataCyName="priceCloseBtn"> </Button>
                                <Button BtnProps={index!==null ? editBtn(t, handleSubmit) : addBtn(t, handleSubmit)} dataCyName="priceDoneBtn" />
                            </css.bottomPop>

                        </Form>
                }
            </FormContext>
        </PopOnPop >
    </>
    );
}

export default PricesPop;