import * as css from './CoursePage.css';
import {useEffect} from 'react';
import PageWrapper from '../../components/pageWrapper/PageWrapper';
import {useTranslation} from 'react-i18next';
import TicketTable from '../components/ticketTable/TicketTable';
import useShow from '../../../hooks/useShow';
import {POPUP_DURATION} from '../../../globalVariables/GlobalVariables';
import Details from '../../eventDetails/details/Details';
import CoursePopupContent from '../ticketsPopUp/courseTicket/coursePopupContent/CoursePopupContent';
import PopUp from '../groups/popUp/PopUp';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import NoTicketsScreen from '../components/noTicketsScreen/NoTicketsScreen';
import {Else, If, Then} from 'react-if';
import {getEventGroups, getEventTickets} from '../../../redux/tickets/ticketsActions';
import useQueryStrings from '../../../hooks/useQueryStrings';
import EasyCourseLastScreen from '../../createCourseEvent/easyCourseLastScreen/EasyCourseLastScreen';
import {createTopSectionImage} from '../../../redux/event/eventActions';
import LoadingRobot from '../../../component/robot/LoadingRobot';
import store from '../../../store';
import useTablet from '../../../hooks/useTablet';
import getTicketsIconList, {ticketOptionsCssStyle} from '../functions/getTicketsIconList';
import TableContext from '../../components/tableContext/TableContext';
import TableTitle from '../../components/tableTitle/TableTitle';
import {cssTicketColumns} from '../components/ticketRow/TicketRow.css';

function CoursePage() {
    const {t} = useTranslation('ticket');
    const layoutT=useTranslation('layout');
    const eventT=useTranslation('event');

    const eventReady=useSelector(s=>s.event.ready);

    const isTablet = useTablet();

    const courseShow = useShow(POPUP_DURATION);
    const tickets = useSelector(s => s.tickets);
    
    const groups = tickets.groups;
    const history = useHistory();
    const location = useLocation();
    const [{groupsPopup, item}, setPopUp] = useState({groupsPopup: false, item: false});
    const xhr=useSelector(s=>s.event.createTopSectionImageXHR);
    useEffect(() => {
        const getTicket = async () => {//remember
            if(!tickets.tickets?.length) {
                await getEventTickets();
            }
            if(!tickets?.getEventGroupsXHR){
                await getEventGroups();
            }
        };
        getTicket().then();
        if([3,4].includes(eventReady))
            easyCurseEndScreenShow.open();
    }, []);
    const handlePopUp = (show, item) => {
        setPopUp({groupsPopup: show, item: item});
    };
    
    useEffect(() => {
        if(tickets.addGroupXHR === 'SUCCESS')
            handlePopUp(false, false);
    }, [tickets.addGroupXHR]);

    useEffect(() => {
        if(tickets.addGroupXHR === 'SUCCESS'){
            const newGid = groups[groups.length - 1].gid;
            history.push(`${location.pathname}/groups/${newGid}`);
        }
    }, [groups]);
    const easyCurseEndScreenShow=useShow(POPUP_DURATION);

    useEffect(async() => {
        if(eventReady===3){
            const {title, tid, users} = tickets?.tickets[0] || {};
            const guideName = users?.[0]?.nameFirst && users?.[0]?.nameLast ? `${users[0].nameFirst} ${users[0].nameLast}` : '';
            createTopSectionImage({title, tid, guideName});
        }
    }, [eventReady]);

    const closeCoursePop=()=>{
        courseShow.close();
        const ready=store.getState().event.ready;
        if(ready<5&&ready>2)
            easyCurseEndScreenShow.open();
    };

    useQueryStrings('openTicket',courseShow.open );
    const buttons = [{key: 0, onClick: courseShow.open, text: `${t('newCourse')}`},
        {key: 1, onClick: () => handlePopUp(true, false), text: `${t('newGroup')}`}];
    const operations={
        buttons,
        optionsList:getTicketsIconList(tickets.ticketsIcon,t),
        cssStyle: ticketOptionsCssStyle 
    };
    const titleTableArray=[
        {text: t('ticket'), field:'title', action: 'sort'},
        {text: t('sale'), field:'soldPrice', action: 'sort'},
        {text: t('courseParticipants'), field:'sold', action: 'sort'}

    ];
    return (<>
        <TableContext data={tickets.tickets}>
            <css.container className={xhr==='LOADING'&&' smallPopUp'} data-cy="CoursePage">
                <PageWrapper  leftTitle={layoutT.t('courseDetails')} image="" operations={operations} SubMenuChildren={Details}>
                    <If condition={tickets.tickets.length||tickets.getEventTicketsXHR==='LOADING'||!tickets.getEventTicketsXHR}>
                        <Then>
                            <TableTitle titleTableArray={titleTableArray}  cssColumns={cssTicketColumns}/>
                            <TicketTable />
                        </Then>
                        <Else>
                            <NoTicketsScreen onClick={courseShow.open}/>
                        </Else>
                    </If>
                    {!isTablet&&<Details />}
                </PageWrapper>
                <LoadingRobot xhr={xhr} text={eventT.t('robotText')} explain={eventT.t('robotDescription')}/>
                {easyCurseEndScreenShow.isOpen&&<EasyCourseLastScreen popShow={easyCurseEndScreenShow}/>}
            </css.container>
            {courseShow.isOpen && <CoursePopupContent close={closeCoursePop} tid={null}  showClass={courseShow.showClass}/>}
            {groupsPopup && <PopUp closePop={() => handlePopUp(false, false)} item={item}/>}
        </TableContext>
    </>
    );
}

export default CoursePage;
