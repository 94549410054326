import {useSelector} from 'react-redux';
import ModeBtn from '../../../../../component/modeBtn/ModeBtn';
import {updateTicket} from '../../../../../redux/tickets/ticketsActions';
import TicketOptions from '../../ticketOptions/TicketOptions';
import Part from '../part/Part';
import * as css from '../TicketRow.css';

function LeftPart({ticket,courseShow}) {
    const {currentTicket,updateTicketXHR} = useSelector(state => state.tickets);

    return (
        <>
            <Part field='updateTicket' padding={'0 2%'} cssStyle={'border-inline-end:none;'}  className='lastColumn'>
                <css.left>
                    <ModeBtn className=" small " click={(val) => updateTicket(ticket.tid, {active: val})} status={ticket.active} requestStatus={currentTicket?.tid===ticket.tid && updateTicketXHR} disabled={ticket.draft} />
                    <TicketOptions ticket={ticket} openPopup={courseShow.open} />
                </css.left>
            </Part>
        </>
    );
}
export default LeftPart;
