import * as css from './CircleBtn.css';

function CircleBtn({onClick, icon, color, disabled ,dataCyName}) {

    return (
        <css.circle
            icon={icon}
            onClick={onClick}
            color={color}
            className={disabled && ' disabled'}
            data-cy={`${dataCyName ? dataCyName : "default"}CircleBtn`} />
    );
}

export default CircleBtn;