import ChildrenLoader from '../ChildrenLoader';
import TickchakLoader from './TickchakLoader';

function TickchakLoaderXHR({xhr,cssStyle}) {
    return (
        <ChildrenLoader xhr={xhr} >{
            (show)=>
                <TickchakLoader showClass={show.showClass} cssStyle={cssStyle} />
        }
        </ChildrenLoader>
    );
}

export default TickchakLoaderXHR;
