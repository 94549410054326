import {useField} from 'formik';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CheckboxFormik from '../../../../../component/checkboxFormik/CheckboxFormik';
import {defaultValidation} from '../../../functions/clearingInfo';
import * as css from './clearingDefault.css';
import {bounceMessage} from '../../../../../layout/userMessages/UserMessage';

function ClearingDefault({name, bitOrMonthly}) {
    const {t} = useTranslation('package');
    const [isDefault, meta, helpers] = useField('isDefault');
    const [srid] = useField('srid');
    const [currency] = useField('currency');
    const [lang] = useField('languageAssign');
    const packageSuppliers = useSelector(s=>s.account.packageSuppliers);

    useEffect(()=>{
        const sameValues = defaultValidation(isDefault.value, bitOrMonthly, packageSuppliers, srid.value, currency.value, lang.value);
        if(sameValues){
            helpers.setValue(false);
            bounceMessage(t(sameValues),'warning');
        }
    },[isDefault]);

    return (
        <css.wrapCB>
            <CheckboxFormik name={name} text={t('defaultSupplierInPackage')}/>
        </css.wrapCB>
    );
}
export default ClearingDefault;
