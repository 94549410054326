import styled, {keyframes} from 'styled-components';
import {arrowBtn, error} from '../../../assets/symbols/symbols/Symbols.css';
import {
    BGImageCenter,
    BUTTON_HEIGHT,
    DEFAULT_COLOR,
    dFlexCenter,
    YELLOW
} from '../../../globalVariables/GlobalVariables';

const loading = keyframes`
    from { transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(11px) rotate(-360deg); }
    
    from { -webkit-transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { -webkit-transform: rotate(360deg) translateX(11px) rotate(-360deg); }
    
    from { -moz-transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { -moz-transform: rotate(360deg) translateX(11px) rotate(-360deg); }
    
    from { -o-transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { -o-transform: rotate(360deg) translateX(11px) rotate(-360deg); }
`;

export const btn = styled.div`
    white-space:nowrap;
    padding: 0 16px;
    border: 1px solid;
    border-radius: 25px;
    height: ${BUTTON_HEIGHT}px;
    width: fit-content;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    ${dFlexCenter};
    border-color:${DEFAULT_COLOR} ;
    background-color:${DEFAULT_COLOR};
    position: relative;
    transition: all 300ms;
    &.disabled{
        opacity: 0.7;
        cursor: unset;
        pointer-events:none;
    }
    :hover{
        border-color:${DEFAULT_COLOR};
        background-color:#FFF;
        box-shadow: rgb(45 45 45 / 5%) 0 2px 2px, rgb(49 49 49 / 5%) 0 4px 4px, rgb(42 42 42 / 5%) 0 8px 8px, rgb(32 32 32 / 5%) 0 16px 16px, rgb(49 49 49 / 5%) 0 32px 32px, rgb(35 35 35 / 5%) 0 64px 64px;
    }
    &.arrow{
       padding-inline-end:5px;
       ::after{
            content: '';
            width: 25px;
            height: 6px;
            ${BGImageCenter(arrowBtn,'6px')}
            transform: rotate(${p => p.theme.rtl?0:180}deg);
            &.white{
                :hover{
                    ::after{                
                        background-color: ${DEFAULT_COLOR};
                        background-image: unset ;
                        -webkit-mask-image: url("${arrowBtn}");
                        mask-image: url("${arrowBtn}");
                        -webkit-mask-position:center center;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-size: 6px ;
                    }        
                }
            }
    }

    }
    &.white{
        background-color:#fff;
        :hover{
            border-color:${DEFAULT_COLOR};
            color:${DEFAULT_COLOR} ;
        }
    }
    &.yellow{
        background-color:${YELLOW};
        border-color:${YELLOW};
    }

    ${props => (props.btnCss)}

    &.LOADING , &.SUCCESS ,&.FAILED{
        font-size: 0px;
    }

    &.LOADING{
        width:30px;
        border-radius: 50%;
        padding: 0px;
        ::after{
            content:'';
            width:7px;
            height:7px;
            border-radius:50%;
            background:#000;
            position:absolute; 
            top: 11px;
            left: 12px;
            -webkit-animation: ${loading} 1s linear  infinite;
            animation: ${loading} 1s linear  infinite;
            -moz-animation:  ${loading}  1s linear infinite; 
            -o-animation: ${loading} 1s linear infinite; 

        };
    }

    &.SUCCESS{
        background-repeat:no-repeat;  
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.09 31.47'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23${props => (props.btnCss.successColor ? props.btnCss.successColor : '484848')};stroke-miterlimit:10;stroke-width:8px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Ev%3C/title%3E%3Cline class='a' x1='1.49' y1='14.68' x2='15.23' y2='29.95'/%3E%3Cline class='a' x1='46.8' y1='1.53' x2='13.23' y2='29.95'/%3E%3C/svg%3E");
        background-size: 18px 18px;
        background-position:center; 
        pointer-events:none;
        padding: 0 30px;
        :after{
            content: none;
        }
    } 
    &.FAILED{
        ${BGImageCenter(error,'22px 22px')};
        background-color: #DD2F21 !important;
        :after{
            content: none;
        }
    }
`;