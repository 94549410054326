import React from 'react';
import GenericPieChart from '../genericPieChart/GenericPieChart';
import * as css from './TicketsChart.css';
import {useTranslation} from 'react-i18next';
import {formatDecimal} from '../../../../library/numberFunction';
import {useSelector} from 'react-redux';

function TicketsChart ({tickets,activeIndex,setActiveIndex}){
    const {sumOfSold, sumOfAmount, totalValue, unsoldValue, soldPrice} = tickets?.reduce(
        (acc, ticket) => {
            const ticketValue = ticket?.totalPrice ?? ((ticket?.amount ?? 0) * (ticket?.price ?? 0));
            const unsoldTicketValue = ticketValue - (ticket?.soldPrice ?? 0) ;

            return {
                sumOfSold: acc.sumOfSold + (ticket?.sold ?? 0),
                sumOfAmount: acc.sumOfAmount + (ticket?.amount ?? 0),
                totalValue: acc.totalValue + ticketValue,
                unsoldValue: acc.unsoldValue + unsoldTicketValue,
                soldPrice: acc.soldPrice + ticket?.soldPrice ?? 0
            };
        },
        {sumOfSold: 0, sumOfAmount: 0, totalValue: 0, unsoldValue: 0, soldPrice: 0}
    ) ?? {sumOfSold: 0, sumOfAmount: 0, totalValue: 0, unsoldValue: 0, soldPrice: 0};

    const {t}= useTranslation('ticket');
    const {currencySymbol} = useSelector(s => s.event);
    const  textCenter=() => {
        const unlimited=tickets[activeIndex]?.amount===null;
        if(activeIndex!==null)
            return {
                name: tickets[activeIndex]?.title,
                sold: unlimited? `${tickets[activeIndex]?.sold} ${t('ticketPcs')}`:`${(((tickets[activeIndex]?.sold / tickets[activeIndex]?.amount) || 0) * 100).toFixed(0)}%`,
                amount: unlimited?'':`${tickets[activeIndex]?.sold}/${tickets[activeIndex]?.amount}`,
            };
        return  {
            name: t('allTicketText'),
            sold: `${((sumOfSold / sumOfAmount) * 100).toFixed(0)}%`,
            amount: `${sumOfSold}/${sumOfAmount}`
        };

    };

    const inlineRect = (
        <>
            <css.ticketName>{textCenter().name}</css.ticketName>
            <css.sold>{textCenter().sold}</css.sold>
            <css.amounts>{textCenter().amount}</css.amounts>
        </>
    );

    return (
        <css.container>
            <css.chartContainer>
                {<GenericPieChart
                    data={tickets.map((ticket, index) => ({
                        ...ticket,
                        index,
                        percentage: (ticket.sold / (ticket.amount || 1000)) || 0.01
                    }))}
                    dataKey='percentage'
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    hoverState={true}
                    inlineRect={inlineRect}
                />}

            </css.chartContainer>
            {soldPrice > 0 && <css.summaryContainer>
                <css.summaryTitle>{`${t('totalSales')} `}<span>{`${currencySymbol}${formatDecimal(soldPrice)}`}</span></css.summaryTitle>
                <css.summary><span> {`${currencySymbol}${formatDecimal(totalValue)}`}</span> {` ${t('maxEventRevenue')}`}</css.summary>
                <css.summary><span> {`${currencySymbol}${formatDecimal(unsoldValue)}`}</span>{` ${t('salesPotential')}`}</css.summary>
            </css.summaryContainer>}
        </css.container>
    );
}

export default TicketsChart;
