import * as css from './MultipleAccount.css';
import {useState} from 'react';
import AccountItem from '../accountItem/AccountItem';
import InputListSearch from '../input/inputList/InputListSearch';
import {useTranslation} from 'react-i18next';
import {useField,} from 'formik';
import {updateAutomaticDataAccount, updateMultipleAccount} from '../../redux/event/eventActions';
import {useSelector} from 'react-redux';
import ModeBtn from '../modeBtn/ModeBtn';
import List from '../list/List';
import SearchLine from '../search/SearchLine';
import useShow from '../../hooks/useShow';
import {getAccounts} from '../../redux/event/eventActions';
import setGlobalState from '../../redux/setGlobalState';

function MultipleAccount() {
    const {t} = useTranslation('event');

    const accounts=useSelector(s=>s.event?.settings?.multipleAccounts?.accounts);
    const {getAccountsXHR}=useSelector(s=>s.event);

    const {open, close, isOpen, showClass} = useShow();

    const maxToShow = 2;
    const [{value},,] = useField('assignedAccounts');
    const [{value:isAll}]=useField('allAccounts');

    let unSelectedList =accounts?.filter(a=>!value?.some(selected=>selected.pid===a.pid));
    const {pid} = useSelector(s=>s.account);
    const selectedAll = {key: 0, index: 0, text: t('selectAll'), selected: false, type: 'radioBtn'};
    let assignedList = Array.isArray(value) ? value : [];
    const [filter,setFilter]=useState('');
    
    const onClickAssignedItem = (e,status, item) => {
        e.stopPropagation();
        updateAutomaticDataAccount({pid: item.pid, automaticData: status ? 1 : 0}).then();
        return;
    };
    const initList = (list) => list?.map((item) => {
        const newItem = {
            ...item,
            key: item.pid,
            index: item.pid,
            text: item.title,
            type: 'radioBtn',
        };
        if(!newItem.hasOwnProperty('automaticData')) {newItem.automaticData = 1;}
        return newItem;
    });
    const initAssignedList = (list) => list?.map((item) => {
        const newItem = {
            ...item
        };
        newItem.leftChildren = (
            <css.warpLeft>
                {item.title}
                <ModeBtn text={t('automatically')} click={(status, e) => {onClickAssignedItem(e, status,item);}} status={item.automaticData} className='small'
                /> 
            </css.warpLeft>
        );
        newItem.selected = true;
        return newItem;
    });
    
    const onClear = (item) => {
        const data = {
            action: 'remove',
            account: {pid: item.pid, automaticData: item.automaticData}
        };
        updateMultipleAccount(data).then();
    };
    const onAdd = (name, key) => {
        const action = key === 0 ? 'selectedAll' : 'add';
        let addAccount = {};
        if(key !== 0) {
            const item = initList(unSelectedList).find(i => i.pid === key);
            if(item) {
                addAccount = {pid: item.pid, automaticData: item.automaticData};
            }
        }
        const data = {
            action: action,
            account: addAccount 
        };
        updateMultipleAccount(data).then();
    };
    const getMoreAccounts = (fields) => {
        if(getAccountsXHR === 'SUCCESS'){
            if(fields.offset == 0)
                setGlobalState('event.settings.multipleAccounts.accounts', []);
            getAccounts(fields);
        }
    };

    let moreList = (filter?
        [...assignedList].splice(maxToShow).filter(a=>a.title.includes(filter)||`${a.pid}`.includes(filter))
        : [...assignedList].splice(maxToShow));
    moreList.unshift({
        children:<SearchLine title={t('searchTitle')} filter={filter} setFilter={setFilter} />,
    });
    const getUnSelectedList = () => {
        let list = initList(unSelectedList);
        if(pid !== 2000 && unSelectedList?.length > 0) {
            list.unshift(selectedAll);
        }
        return list;
    };
    return (
        <css.container className={`${isAll && 'disabled'}`} >
            {(moreList?.length > 1 || filter) && (
                <css.plus>
                    <css.wrap onClick={open}>
                        <css.text>{'+' + (moreList.length - 1)}</css.text>
                    </css.wrap>
                    {isOpen && <List onClick={onClear} close={close} width={'350px'} list={initAssignedList(initList(moreList))} showClass={showClass} /> }
                </css.plus>
            )}
            {
                initAssignedList(initList(assignedList)).map((item, index) => index < maxToShow &&
                    <AccountItem key={item} onClear={onClear} item={item} />)
            }
            <css.listCheckbox>
                <InputListSearch name='unSelectedList' onChange={onAdd} list={getUnSelectedList()} text={t('selectAccount')} isSelectable={true}  
                    onSearch={(value)=>{getMoreAccounts({search: value, limit: 10, offset: 0});}} 
                    getMoreAction={()=>getMoreAccounts({search: '', limit: 10, offset: accounts?.length})} 
                />
            </css.listCheckbox>
        </css.container>

    );
}
export default MultipleAccount;
