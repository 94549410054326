import styled from 'styled-components/macro';

export const wrapper=styled.div`
    height: max-content;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    cursor: pointer;
    padding-top: 20px; 
    gap: 7px;

`;