import {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import InputSearch from '../../component/inputSearch/InputSearch';
import Packages from './packeges/Packages';
import Events from '../../pages/components/events/Events';
import * as css from './UserPackages.css';
import useOnClickOutside from '../../hooks/UseOnClickOutside';
import {useTranslation} from 'react-i18next';
import {getEventsByRole} from '../../redux/suggestions/suggestionsActions';
import {useHistory} from 'react-router-dom';
import store from '../../store';
import {bounceMessage} from '../userMessages/UserMessage';

export const changeAccountMessage=(event,packageT,history)=>{
    const prevPath=window.location.pathname;
    if(store.getState().account.pid&&event?.pid === store.getState().account.pid)
        return;
    bounceMessage(
        packageT.t('changePackage', {event: event?.pTitle}),
        'attention',
        {text: packageT.t('stayInPackage'),onClick: () => {
            history.push(prevPath);
            
        }},
        null,
        5);
};

function UserPackages({openEvents,closePackages}) {
    const suggestions = useSelector(state => state.suggestions);
    const isMasterUser = useSelector(s => s.user.isMaster);
    const {t} = useTranslation('layout');
    const packageT = useTranslation('package');
    const [cnt, setCnt] = useState(0);
    const [search, setSearch] = useState('');
    const history = useHistory();
    
    const onClickEvent = (event,packageT) => {
        history.push('/e/' + event.eid+'/realtime');
        changeAccountMessage(event,packageT,history);
    };
    const [{limit, offsetEvents, offsetPackages}, setLimitOffset] = useState({limit: 3, offsetEvents: 0, offsetPackages: 0});

    const updateLimitOffset = (name, value) => {
        setLimitOffset(prevState => ({...prevState, [name]: value}));
    };
    const setOffset = (offsetEvents, offsetPackages) => {
        updateLimitOffset('offsetEvents', offsetEvents);
        updateLimitOffset('offsetPackages', offsetPackages);
    };
    const handleSearch = (e) => {
        setSearch(e.target.value);
        setOffset(0, 0);
        setCnt(cnt + 1);
    };
    const close = () => {
        closePackages();
        setOffset(0, 0);
    };

    const ref = useRef();
    useOnClickOutside(ref, close);
    const onScroll = e => {
        if(Math.round(e.target.scrollHeight - e.target.scrollTop) === Math.round(e.target.clientHeight) && suggestions.getEventsByRoleXHR != 'loading')
            setOffset(suggestions.eventsByRole.length, suggestions.accounts.length);
    };
    return (
        <css.packages ref={ref} onScroll={onScroll}>
            <InputSearch text={t('selectAccount')} onChange={handleSearch} />
            <css.PackagesEvents>
                <Packages search={search} limit={limit} offset={offsetPackages} onClick={close} openEvents={openEvents} cnt={cnt} />
                {isMasterUser && <Events eventsList={suggestions.eventsByRole} getUserEvents={(fields) => getEventsByRole(fields)} search={search} type={'onlyEventRole'} limit={limit} offset={offsetEvents} cnt={cnt} title={true}
                    onClick={(event) => onClickEvent(event, packageT)} />}
                <css.MoreItems className={'moreItems'} onClick={() => { setOffset(suggestions.eventsByRole.length, suggestions.accounts.length); updateLimitOffset('limit', 5); }} />
            </css.PackagesEvents>
        </css.packages>

    );
}

export default UserPackages;
