import {useTranslation} from 'react-i18next';
import {useField,} from 'formik';
import InputList from '../../../../../component/input/inputList/InputList';
import {getMonthDifference} from '../../../../../library/dates';
import Summary from '../../components/summary/Summary';
import {formatDecimal} from '../../../../../library/numberFunction';
import MonthInput from '../../../../../component/input/dateInput/MonthInput';
import PaymentsGlobal from '../../components/paymentsGlobal/PaymentsGlobal';
import * as css from '../../components/paymentsGlobal/PaymentsGlobal.css';
import {If,Else,Then} from 'react-if';
import {useSelector} from 'react-redux';
import {hasAccountAccess} from '../../../../../functions/permission';
import CheckboxFormik from '../../../../../component/checkboxFormik/CheckboxFormik';

const billingOptionsList = (course) => {
    let list = [{
        text: 'byMonthNum'
        , key: getMonthDifference(course.startDate, course.endDate )
    }];
    return list;
};

function PaymentSettings({onSubmitForm}) {

    const {t} = useTranslation('ticket');
    const [course] = useField('course');
    const [payments] = useField('payments');
    const [prices] = useField('prices');
    const courseMonths = getMonthDifference(course?.value.startDate,course?.value.endDate);
    const totalCourse = prices?.value[0]?.price * courseMonths;

    const eventSettings=useSelector(state=>state.event.settings);
    const accountBillingsAccess = hasAccountAccess('billing');

    return (
        <If condition={accountBillingsAccess&&eventSettings?.billings?.hasBillings}>
            <Then>
                <PaymentsGlobal onSubmitForm = {onSubmitForm}>
                    <css.wrapGrid className={!payments.value.hasBillings && 'notShow'}>
                        <InputList t={t} name={'payments.numberPayments'} text={t('numberPayments')} description={t('numberPaymentsDesc')} list={billingOptionsList(course.value)} />
                        <MonthInput name={'payments.secondPayment'} text={t('secondPayment')} descText={t('secondPaymentDesc')} />
                    </css.wrapGrid>
                    <css.wrapCB>
                        <CheckboxFormik name={'payments.extraException'} text={t('chargingExtensionsCourse')}/>
                    </css.wrapCB>
                    {!!(payments?.value?.hasBillings && prices.value.length && payments.value.numberPayments) && <Summary total={formatDecimal(totalCourse)} perMonth={formatDecimal(totalCourse/payments.value.numberPayments)} />}
                </PaymentsGlobal>
            </Then>
            <Else>
                <PaymentsGlobal onSubmitForm = {onSubmitForm}/>
            </Else>
        </If>
    );

}

export default PaymentSettings;
