import request from '../../library/request';
import store from '../../store';
import setGlobalState from '../setGlobalState';
import {uploadFile} from '../user/userActions';

const getEid = () => store.getState().event.eid;

export const updateDesignEmail = async (data) => {
    const eid = getEid();
    if(data.imageMain?.image){data.imageMain.image = await uploadFile(data.imageMain.image, '', 'designEmail', eid);}
    if(data.suspendedImage?.image){data.suspendedImage.image = await uploadFile(data.suspendedImage.image, '', 'designEmail', eid);}
    const lang=store.getState().branding?.currentLang;
    const res = await request('branding.updateDesignEmail', 'put', `/event/${eid}/designEmail`, {...data,lang});
    setGlobalState(`branding.data.${lang}`, {...store.getState().branding.data[lang],...res});
    setGlobalState('branding.previewKey',store.getState().branding.previewKey+1);
};

export const getEmailData = async () => {
    const lang=store.getState().branding?.currentLang;
    const res = await request(`branding.getBrandingData.${lang}`, 'get', `/event/${getEid()}/branding`,{lang});
    const branding =store.getState().branding;
    setGlobalState('branding', {...branding, ...res.data, data: {...branding.data, [lang]: res.commonData}});
};
export const getEmailPreview = async (emailContent) => {
    const lang=store.getState().branding.currentLang;
    const res = await request('branding.getEmailPreview', 'get', `/event/${getEid()}/brandingPreview`,{lang,emailContent});
    return res.html;
};

export const updateEmailStatus = async (field,value) => {
    const lang=store.getState().branding?.currentLang;
    const res = await request('branding.updateEmailStatus', 'put', `/event/${getEid()}/designEmailStatus`, {lang,field:field,value:value});
    setGlobalState(`branding.data.${lang}`, {...store.getState().branding.data[lang],...res});
};