import styled from 'styled-components/macro';
import {desktopMedia} from '../../../globalVariables/GlobalVariables';
import {List} from '../../list/List.css';

export const phoneInputWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    .valid::before {
        margin-inline-end: -45px;
    }
    ${List} {
        ${desktopMedia} {
            &.show{
                    height: 220px;
            }
        }
    }
    input{
      margin-inline: 5px;
    }
`;
export const countryImage = styled.div`
    border-bottom: 1px solid;
    height: 25px;
    margin-top: 10px;
    img {
        border-radius: 50%;
        width: 21px;
        height: 21px;
        box-shadow: 0 3px 6px #00000029;
        border: 0.5px solid;
    }
`;