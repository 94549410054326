import {DEFAULT_COLOR} from '../globalVariables/GlobalVariables';

export function continueBtn(text, onClick) {
    return {
        text: text,
        class: ' arrow ',
        iconBtn: 'arrowBtn',
        onClick: onClick,
        borderColor:DEFAULT_COLOR,
        backgroundColor:'#FFF',
        hoverBorderColor:DEFAULT_COLOR,
        hoverTextColor:DEFAULT_COLOR,        
    };
}

export function addBtn(t,onClick) {
    return {
        width: 60,
        text: t('add'),
        onClick: onClick,
    };
}

export function editBtn(t,onClick) {
    return {
        width: 60,
        text: t('save'),
        onClick: onClick,
    };
}

export function closeBtn(t,onClick) {
    return {
        width: 60,
        text: t('close'),
        onClick: onClick,
        backgroundColor: '#FFF',
        borderColor: '#76d6e8',
        hoverBackgroundColor: '#76d6e8',
        hoverBorderColor: '#fff',
    };
}

