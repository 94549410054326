import React from 'react';
import './TableRow.css';
import Checkbox from '../../Checkbox/Checkbox';
import Countdown from '../../Countdown/Countdown';
import Link from '../../Link/Link';
import EditableDiv from '../../EditableDiv/EditableDiv';
import ProgressBar from '../../ProgressBar/ProgressBar';
import TableStatus from '../TableStatus/TableStatus';
import {calculateGrid, foramTableContent} from '../TableFunctions';

const RtlCss = React.lazy(() => import('./TableRow-rtl'));

export default function TableRow(props) {
    const content = foramTableContent(props.content, props.mini);
    const style = {gridTemplateColumns: calculateGrid(content, props.mini)};
    return (
        props.data.map((data) => {
            let rowClassName = 'table-row' + (data.selected ? ' selected' : '');
            return (
                <>
                    {(props.lang === 'he') && <RtlCss />}
                    <div className={rowClassName} key={data.id} style={style} data-id={data.id}>
                        {content.map((col, k) => {
                            return columnCase(col, data, props.mini, props.handleEvent, k, props.lang, props.isEvent);
                        })}
                    </div>
                </>
            );
        })
    );
}

function columnCase(col, data, mini, handle, key, lang, isEvent) {
    let text = data[col.field];
    let className = 'table-column column-' + col.type + ' ' + (col.class ? col.class : '');
    className += col.secondRow ? ' second-row' : '';
    let columnText = text;
    className += col.border ? ' border' : '';
    let image = col.image;
    switch (col.type) {
        case 'text':
            if(isEvent && col.title === 'event'){
                columnText = '';
            }
            break;
        case 'textEditable':
            columnText = <EditableDiv text={text} name={col.field} handle={handle} id={data.id}/>;
            break;
        case 'date-interval':
            columnText = <Countdown date={text} handle={handle} id={data.id}/>;
            break;
        case 'status':
            columnText = <TableStatus status={text} mini={mini}  lang={lang}/>;
            break;
        case 'link':
            columnText = <Link link={data.link} />;
            break;
        case 'iconV':
            col.image = 'v_' + text;
            columnText = '';
            break;
        case 'icon':
            if(!col.image && col.imageFiled){
                image = data[col.imageFiled];
            }
            break;
        case 'checkbox':
            let progress = data.process === 'start' ? 95 : (data.process === 'end' ? 100 : 0);
            if(data.process === 'end'){
                setTimeout(()=> {handle('change', {id:data.id, name:'process', value:false});},2000);
            }
            columnText = <div>
                <Checkbox selected={data.selected} handle={handle} id={data.id} processSuccess={data.process === 'end'}/>
                <ProgressBar progress={progress} size={26} strokeWidth={2} circleTwoStroke='#01acf0' id={data.id}/>
            </div>;
            break;
        default:
            break;
    }
    if(col.above && col.above === 'scheduled' && data.scheduled){
        columnText = <div className="image-above-icon" image="clock" />;
    }
    if(col.hover){
        className += col.hover;
        columnText = data[col.hover];
    }
    if(col.secondRow){
        columnText = <div>{text}<div className="column-second-row" maxWidth="300px">{data[col.secondRow]}</div></div>;
    }
    return (
        <div
            className={className}
            onClick={()=>{
                if(col.open){
                    handle('showHideDetails', {id:data.id});
                }
            }}
            image={image}
            key={key}
        >
            {columnText}
        </div>
    );
}