import Btn from '../button/btn/Btn';
import * as css from './UserMessagePop.css';

function UserMessagePop({text,firstButton,secondButton}) {

    return (
        <css.message>
            <css.wrapAll>
                <css.text>{text}</css.text>    
                <css.wrapBtns>
                    <css.wrapBtn><Btn onClick={firstButton.onClick}>{firstButton.text}</Btn></css.wrapBtn>
                    <Btn className={'white'} onClick={secondButton.onClick}>{secondButton.text}</Btn>
                </css.wrapBtns>
            </css.wrapAll>
        </css.message>
    );
}

export default UserMessagePop;