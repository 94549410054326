import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import GridItemDrag from './GridItemDrag';

const SortableItem =({id,children,zIndex}) => {
    const {isDragging, listeners,setNodeRef,transform,transition} = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
        zIndex
    };

    return (
        <GridItemDrag
            forwardRef={setNodeRef}
            style={style}
            isSourceDragging={isDragging}
            {...listeners}
        >
            {children }
        </GridItemDrag>
    );
};

export default SortableItem;
