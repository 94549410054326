import styled from 'styled-components/macro';
import {container as containerInput} from '../../../../../component/classes/input';
import {dFlexCenter, scrollbar} from '../../../../../globalVariables/GlobalVariables';
import {popOnPopContent, deleteBtn} from '../../basicTicket/extensionsPop/ExtensionsPop.css';
import {container} from '../listField/ListField.css';

export const popOnPopupContent = styled(popOnPopContent)`
    padding-top: 15px;
    padding-bottom:5px ;
    position: reletive;
    ${scrollbar}
    ${containerInput},${container}{
      margin-top: 40px;
    }
    .formikModeBtn{
        margin-inline-start: auto;
    }
`;
    
export const required = styled.div`
  display:flex ;
  justify-content:space-between ;
  width:100% ;
  font-weight:600 ;
  letter-spacing:0.7px ;
`;

export const deleteButton=deleteBtn;

export const bottomPopup = styled.div`  
   height: 70px;
   ${dFlexCenter}
   `;

export const inputWrapper = styled.div`
    margin-top:30px;    
  `;

export const mode = styled.div`
  width: 100%;
  .formikModeBtn{
    float: left;
  }
`;