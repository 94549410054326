import styled from 'styled-components/macro';
import {ex, op} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const options=styled.div`
  opacity:0;
cursor: pointer;
  ${BGImageCenter(op,'12px')};
  height: 24px;
  transform: rotate(90deg);
  transition: all 200ms;
  :hover{

    background-size: 16px;
  }
`;
export const cancelTimer=styled.div`
margin-top: 5px;
    ${BGImageCenter(ex,'10px')}
    width: 15px;
    height: 15px;
    cursor: pointer;
`;

export const send=styled.div`
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
  `;