import {useTranslation} from 'react-i18next';
import * as css from './FacebookPixelPopContent.css';
import {Else, If, Then} from 'react-if';
import useMobile from '../../../hooks/useMobile';
import Input from '../../../component/input/Input';
import DomainInput from '../../../component/input/domainInput/DomainInput';
import Btn from '../../../component/button/btn/Btn';
import {useSelector} from 'react-redux';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import DomainVerificationSection from './DomainVerificationSection';

function FacebookPixelPopContent({submitForm,tickchakDomain}) {
    const {t} = useTranslation('publish');
    const isMobile = useMobile();
    const form = useFormikContext();
    const {addSpecialPixelXHR,updateSpecialPixelXHR} = useSelector((state) => state.publish);
    const {setIsTickchakDomain,isTickchakDomain}= tickchakDomain;

    useEffect(form.validateForm, [isTickchakDomain]);
    
    return (
        <>
            <If condition={isMobile}>
                <Then>
                    <css.mobileIcon title={t('facebookPixel')}/>
                </Then>
                <Else>
                    <css.background>
                        <css.title>{t('facebookPixel')}</css.title>
                        <css.icon />
                    </css.background>
                </Else>
            </If>
            <css.content>
                <Input name="id" text={t('serialNumber')}/>
                <css.wrap>
                    <css.text>{t('domainSettings')}</css.text>
                    <ModeBtn status={!isTickchakDomain} className={'small'} text={t('privateDomain')} click={()=>setIsTickchakDomain(!isTickchakDomain)} dataCyName="privateDomain"/>
                </css.wrap>
                <DomainInput name="domain" descText={!isTickchakDomain&&t('domainMessage')} text={t(isTickchakDomain?'changeSubDomain':'changeDomain')} isSubDomain={isTickchakDomain}/>
                <DomainVerificationSection />
            </css.content>
            <css.bottom>
                <Btn className={addSpecialPixelXHR||updateSpecialPixelXHR} onClick={submitForm}>{t('save')}</Btn>
            </css.bottom>
        </>
    );
}

export default FacebookPixelPopContent;
