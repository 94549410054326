import {useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import * as css from '../Table.css';
import Row from '../newRow/dragRow';
import {useTranslation} from 'react-i18next';
import {LINE_MARGIN} from '.././newRow/Row.css';
import WarpTable from '../WrapTable';

function DragTable({
    list, 
    onDragEnd = () => { }, 
    checked, 
    buttonsChildren, 
    title = '', 
    scrollProps = {}, 
    searchProps ,
    rowHeight=70,
    header=false}) {
        
    const [dragIndex, setDragIndex] = useState(null);
    const {t} = useTranslation('global');

    const onDragUpdate = (drag) => {
        if(drag.destination)
            setDragIndex(drag.destination.index);
    };

    const props={list,checked,title,scrollProps,searchProps,header,buttonsChildren};

    return (
        <WarpTable {...props}>
            {(getCheckedProps)=>
                <DragDropContext onDragEnd={onDragEnd}
                    onDragUpdate={onDragUpdate}>
                    <Droppable droppableId="tableDrag">
                        {(provided, snapshot) => (
                            <>
                                <css.table  {...provided.droppableProps} ref={provided.innerRef} >
                                    {list.map((item, index) => (
                                        <Row rowHeight ={rowHeight } key={index} index={index} rowKey={item.key} isDraggingOver={snapshot.isDraggingOver}
                                            checked={getCheckedProps(item.key)}>
                                            <item.comp {...item.props} index={index} />
                                        </Row>))}
                                </css.table>
                                {<css.placeHolder rowHeight={rowHeight} className={snapshot.isDraggingOver && ' dragging '} top={dragIndex * (rowHeight + LINE_MARGIN) + LINE_MARGIN} >{t('drag')}</css.placeHolder>}
                                {provided.placeholder}
                            </>)
                        }
                    </Droppable>
                </DragDropContext >
            }
        </WarpTable>

    );

}
export default DragTable;

