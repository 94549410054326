import styled from 'styled-components/macro';
import {placeForDrag} from '../../../../component/newTable/newRow/Row.css';
import {BGImageCenter, ellipsisLines} from '../../../../globalVariables/GlobalVariables';
import * as event from '../clusterEventRow/ClusterEventRow.css';

export const firstPart = styled.div`
    width:45%;
    display:flex;
    cursor: pointer;
`;
export const placeDrag = styled(placeForDrag)`

`;
export const icon = styled.div`
    overflow:visible;
    width:140px;
    background-color: ${p => p.color};
    ${p => BGImageCenter(p.icon, '25px')}
    position:relative ;
    display:flex ;
    align-items:center ;
    border-radius:${p=>p.theme.rtl? '0 5px 5px 0': '5px 0 0 5px'};
`;

export const image = styled.div`
    border-radius:${p=>p.theme.rtl? '0 5px 5px 0': '5px 0 0 5px'};
    /* width:90px; */
     width:140px;
    ${p => BGImageCenter(p.image, '25px')}
    background-size:cover;
    position:absolute ;
    inset-inline-start:0;
    height:100% ;
`;
export const right = styled(event.part)`
    width:calc(100% - 140px);
    letter-spacing:0.7px ;
    padding-inline-start:18px !important;
    padding-inline-end:10px;
   
`;
export const header = styled.div`
    width:calc(100% - 70px); 
`;

export const title = styled.div`
    ${ellipsisLines(2)}
    font-weight:600 ;
    font-size:14px ;
`;

export const circleWrap = styled.div`
    cursor:pointer ;
    background-color:#fff ;
    position:absolute ;
    width:36px ;
    height:36px ;
    background-color:#fff;
    padding:2px ;
    inset-inline-end:-18px;
    border-radius:50% ;
    display:none ;
`;

export const boldText = event.boldText;
export const text = event.text;
