import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, useField} from 'formik';
import {useSelector} from 'react-redux';
import Input from '../../../../../component/input/Input';
import TextareaInput from '../../../../../component/input/textareaInput/TextareaInput';
import NewStaffPop from '../../courseTicket/newStaffPop/NewStaffPop';
import CourseDatePop from '../../courseTicket/courseDatePop/CourseDatePop';
import * as css1 from '../../courseTicket/courseDetails/CourseDetails.css';
import * as css2 from './TicketDetails.css';
import PricesPop from '../pricesPop/PricesPop';
import NumberInput from '../../../../../component/input/NumberInput';
import {Else, If, Then} from 'react-if';
import {Link} from 'react-router-dom';
import ConditionalWrapper from '../../../../../component/conditionalWrapper/ConditionalWrapper';
import InputAmount from '../../../../../component/input/inputAmount/InputAmount';
import ImagePopup from '../../../../../component/imagePopup/ImagePopup';

const css = {...css1, ...css2};

function TicketDetails() {

    const {t} = useTranslation('ticket');
    const type = useSelector(s => s.event?.type);
    const [imgDataField,,imgDataHelpers] = useField('imgData.image');
    const [amountField,] = useField('amount');
    const [{value: priceValue}] = useField('prices[0].price');
    const [itemDate] = useState(null);
    const [itemPrice] = useState(null);
    const [showNewStaff, setShowNewStaff] = useState(0);
    const [showCourseDate, setShowCourseDate] = useState(0);
    const [pricePop, setPricePop] = useState(0);
    const event = useSelector(state => state.event);
    const currentTicket = useSelector(state => state.tickets.currentTicket);

    const onKeyPress = (e) => {
        const keyCode = e.which || e.keyCode;
        const currentValue = e.target.value + String.fromCharCode(keyCode);
        if(!isNaN(currentValue)) {
            const parsedValue = parseFloat(currentValue);
            if(parsedValue > 10000) {
                e.preventDefault();
            }
        }
    };

    return (
        <>
            <css.wrapForm>
                <Form>
                    <css.container>
                        <css.wrapAll>
                            <css.firstPart>
                                <css.wrapInputs>
                                    <Input name={'title'} text={t('ticketName')} description={t('ticketNameDesc')} icon={'event'}/>
                                    <Input name={'description.summary'} text={t('moreInfo')} description={t('ticketSummaryDesc')} icon={'pen'} />
                                    <TextareaInput name={'description.description'} text={t('extendedInformation')} description={t('ticketDescriptionDesc')}  icon={'clusterDetails'} limit={999}/>
                                </css.wrapInputs>
                                <css.wrapImage><ImagePopup 
                                    imageProps={{   
                                        size:{width: 160, height: 160},
                                        image:imgDataField.value,
                                        setImage: imgDataHelpers.setValue,
                                    }}
                                    fieldName={'imgData'}
                                /></css.wrapImage>
                            </css.firstPart>
                            <css.wrapDate>
                                <css.price>
                                    <css.wrapInput className={priceValue?'':'empty'} length={`${priceValue}`.length}>
                                        <InputAmount name={'prices[0].price'} text={t('priceTicket')} description={t('priceTicketDescription')} amountText={event.currencySymbol} events={{onKeyPress}}/>
                                    </css.wrapInput>
                                    <css.payments>
                                        <NumberInput name={'limit'} text={t('limitField')} description={t('ticketLimit')} icon="pen" />
                                        <If condition={type==='area'}>
                                            <Then>
                                                <css.wrapAmount >
                                                    <css.boldText>{t('mapTicketAmount',{'amount':amountField.value})}</css.boldText>
                                                    <ConditionalWrapper
                                                        condition={currentTicket?.tid}
                                                        wrapper={children =>  <Link to={`/e/${event.eid}/tickets/seats`}> {children} </Link>}>
                                                        <css.link>{t('editMap')} </css.link>
                                                    </ConditionalWrapper>
                                                </css.wrapAmount>
                                            </Then>
                                            <Else>
                                                <NumberInput name={'amount'} text={t('amountField')} description={t('ticketAmount')}/>
                                            </Else>
                                        </If>                                 
                                    </css.payments>
                                </css.price>
                            </css.wrapDate>
                        </css.wrapAll>
                        {/*<BottomCourse continueBtnText={t('ticketExtension')} onSubmitForm={onSubmitForm} continueClick={() => setGlobalState('ui.tabsPopup.currentStage', 'extensions')} />*/}
                        <NewStaffPop showPopOnPop={{show: showNewStaff, setShow: setShowNewStaff}} />
                        <CourseDatePop showPopOnPop={{show: showCourseDate, setShow: setShowCourseDate}} item={itemDate} />
                        <PricesPop showPopOnPop={{show: pricePop, setShow: setPricePop}} index={itemPrice} />
                    </css.container>
                </Form>
            </css.wrapForm>
        </>
    );
}

export default TicketDetails;