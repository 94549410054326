import Close from '../../../component/close/Close';
import * as css from './AttachedMessageWrapper.css';

function AttachedMessageWrapper({children,  background = 'dd2162',close, showClass}) {
    return (
        <css.wrapper color={background} className={showClass}>
            <Close className={'white'} onClick={close}/>
            {children}
        </css.wrapper>
    );
}

export default AttachedMessageWrapper;
