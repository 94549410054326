function readCookie(name) {
    let nameEQ = encodeURIComponent(name) + '=';
    let ca = document.cookie.split(';');
    for(let i = 0; i <= ca.length - 1; i++) {
        let c = ca[i];
        while(c.charAt(0) === ' ') c = c.substring(1, c.length);
        if(c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

export default readCookie;