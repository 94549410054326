import {useField} from 'formik';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SelectItem from '../../../../component/selectItem/SelectItem';
import {getCategories} from '../../../../redux/categories/categoriesActions';
import * as css from './CategoryStep.css';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';
import store from '../../../../store';
import getSpecialTranslation from '../../../../functions/getSpecialTranslation';

export const getTopCategory=(value,t)=>{
    const categories=store.getState().categories?.eventCategories;
    const _t=(text)=>t(`layout:${text}`);
    return getSpecialTranslation(`category${value}`,_t)||categories.find(c=>c.catId===value)?.title||'';
};

function CategoryStep() {
    const {t} = useTranslation('newEvent');
    const tLayout = useTranslation('layout');
    const categories = useSelector((s) => s.categories.eventCategories);
    let categoriesList = [...categories];
    categoriesList.push({catId: 0, title: t('category0')});
    const [categoryField, ,categoryHelpers] = useField('category');

    const iconList= ['speaker','tours','cake','arts','conventions','ball','smilingFace','fundraiser','sunHat','generalMenu'];
    useEffectOnUpdate(()=>{
        categoryHelpers.setTouched(true);
    },[categoryField.value]);
    useEffect(() => {
        const getData = async () => {
            await getCategories();
        };
        getData().then();
    }, []);

    const onClose=(e)=>{
        e.stopPropagation();
        categoryHelpers.setValue(null);
    };

    return (
        <css.wrapper>
            {categoriesList?.map((c,index)=>
                <SelectItem key={c.catId} 
                    index={c.catId} 
                    text={getSpecialTranslation(`category${c.catId}`,tLayout.t)||c.title} 
                    selected={categoryField.value}
                    icon={index>9?'packageImg':iconList[index]} 
                    onClose={onClose} 
                    onSelected={(key)=>categoryHelpers.setValue(key)}/>)}
        </css.wrapper>
    );
}

export default CategoryStep;
