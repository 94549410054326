import {useTranslation} from 'react-i18next';
import * as css from './Button.css';

function Button(props) {

    const {t} = useTranslation('layout');
    const {BtnProps, className, disabled = false} = props;

    return (
        <css.Button className={'Button ' + (BtnProps.class && BtnProps.class) + ' ' + (className && className) + (disabled ? ' disabled ' : '')}
            onClick={(e)=>{
                e.stopPropagation();
                if(!disabled)
                    BtnProps.onClick();
            }}
            btnCss={BtnProps} data-cy = {`${props.dataCyName ? props.dataCyName : "default"}Btn`} >{t(`${BtnProps.text}`)}{props.children && props.children} </css.Button>
    );
}

export default Button;
