import React from 'react';
import * as css from './emailScreen/EmailScreen.css';
import {useSelector} from 'react-redux';
import {updateDesignEmail} from '../../redux/branding/brandingActions';
import {defaultImage} from '../../assets/symbols/images/defaultImage';
import EyeHideSection from '../components/eyeHideSection/EyeHideSection';
import ConvertDecimalToHex from '../../functions/ConvertDecimalToHex';
import {givechakImage} from '../../assets/symbols/images/givechakImage';
import ExternalImagePopup from '../../component/imagePopup/externalImagePopup/ExternalImagePopup';

function EmailImage({name}) {
    const {currentLang} = useSelector(s => s.branding??{});
    const {colorMain} = useSelector(s => s.branding.data?.[currentLang]??{});
    const hexColor = colorMain && ConvertDecimalToHex(colorMain); 
    const {lang: defaultLang, type} = useSelector(s => s.event);
    const data = useSelector((s) => s.branding.data);
    const brandingData = data?.[currentLang];
    const image = brandingData?.[name] || brandingData?.imageMain || data?.[defaultLang]?.[name] || data?.[defaultLang]?.imageMain;
    const imageId = brandingData?.[`${name}Id`] || brandingData?.imageMainId || data?.[defaultLang]?.[`${name}Id`] || data?.[defaultLang]?.imageMainId;
    const credit = brandingData?.[`${name}Credit`] || brandingData?.imageMainCredit || data?.[defaultLang]?.[`${name}Credit`] || data?.[defaultLang]?.imageMainCredit;

    let fbImg = useSelector(s => s.event.fbImg);
    if(fbImg === defaultImage && type=== 'givechak'){
        fbImg = givechakImage;
    }
    const widthWindowWidth=useSelector(s=>s.ui.windowWidth);
    const imageHeight=Math.min(parseInt((widthWindowWidth-20)/3.94),160);

    const imageEditorProps = {
        size: {width: Math.min(630,widthWindowWidth-20), height:imageHeight},
        image: image,
        imageId: imageId,
        setImage: (newImage) => updateDesignEmail({[name]: newImage}),
    };

    return (  
        <EyeHideSection sectionName={'image'}>
            <css.emailImage color={hexColor} height={`${imageHeight}px`} data-cy={`${name}Image`}>
                {!image && <css.image className={type} image={fbImg|| (type=== 'givechak' ? givechakImage : defaultImage)}/>}
                <ExternalImagePopup imageProps={imageEditorProps} creditProps={credit} absoluteImage={!image}/>
                <css.uploadIcon/>
            </css.emailImage>
        </EyeHideSection>
    );
}

export default EmailImage;
