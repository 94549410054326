import React from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './ParticipantSection.css';
import EyeHideSection from '../../../components/eyeHideSection/EyeHideSection';
import {useSelector} from 'react-redux';

function ParticipantSection() {
    const {t} = useTranslation('branding');
    const name = useSelector(s => `${s.user.nameFirst} ${s.user.nameLast}`);  
    const currencySymbol=useSelector(s=>s.event.currencySymbol);

    return (  
        <css.wrapper>
            <EyeHideSection sectionName={'participant'}>
                <css.participant>
                    <css.right><span>{t('participant')}</span> {name}</css.right>
                    <css.left>{`100${currencySymbol} ${t('perMonth')}`}</css.left>
                </css.participant>
            </EyeHideSection>
        </css.wrapper>
    );
}

export default ParticipantSection;
