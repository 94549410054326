import * as css from './VerifyScreen.css';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {getAccount, getPackageBank} from '../../../../redux/account/accountActions';
import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import VerificationForm from './verificationForm/verificationForm';
import {If, Then, Else} from 'react-if';
import store from '../../../../store';
import UserMessage, {bounceMessage} from '../../../../layout/userMessages/UserMessage';

function VerifyScreen() {
    const {t} = useTranslation('package');
    let {pid} = useParams();
    const account=useSelector(s => s.account);
    const endScreen = account.updatePackageVerificationXHR==='SUCCESS';
    const history = useHistory();

    useEffect(async () => {
        if(pid != store.getState().account.pid)
            await getAccount(pid);
        await getPackageBank();
    }, []);

    useEffect(async () => {
        if((account.updated || pid === store.getState().account.pid) && account.userAccountRole!=='owner')
            bounceMessage(t('goAway'),undefined,undefined,{onClick:()=>{ history.push('/');}},5);
    }, [account.updated]);

    return (
        <>
            <css.outerWrapper className={endScreen && 'endScreen'}>
                <css.verifyWrapper>
                    <If condition={endScreen}>
                        <Then>
                            <css.lightBlueOverAll>
                                <css.tickLogo />
                                <css.nowCalm>{t('nowCalm')}</css.nowCalm>
                                <css.greenCertificateSymbol />
                                <css.cloudSymbol />
                            </css.lightBlueOverAll>
                        </Then>
                        <Else>
                            <css.gray>
                                <css.tickLogo />
                                <css.verifyLogo />
                            </css.gray>
                            <css.LightBlue>
                                <css.LightBlueTitle>{t('preciousMoney')}</css.LightBlueTitle>
                            </css.LightBlue>
                            <css.whiteSection>
                                {account.userAccountRole=='owner'&&
                                    <css.whiteMain>
                                        <css.whiteMainIn>
                                            <css.inTitle>{t('verifyYourDetails')} 😉</css.inTitle>

                                            <VerificationForm />
                                        </css.whiteMainIn>
                                    </css.whiteMain>
                                }
                            </css.whiteSection>
                        </Else>
                    </If>
                </css.verifyWrapper>
            </css.outerWrapper>
            <UserMessage />
        </>
    );
}

export default VerifyScreen;