function openEmailInBrowser(email) {
    const domain = email && email.split('@')[1];
    let url;
    switch (domain) {
        case 'outlook.com':
        case 'hotmail.com':
        case 'live.com':
        case 'msn.com':
            url = 'https://outlook.live.com/owa/';
            break;
        case 'yahoo.com':
        case 'rocketmail.com':
        case 'y7mail.com':
            url = 'https://compose.mail.yahoo.com/';
            break;
        case 'aol.com':
            url = 'https://mail.aol.com/webmail-std/en-us/';
            break;
        case 'icloud.com':
        case 'mac.com':
        case 'me.com':
            url = 'https://www.icloud.com/#mail';
            break;
        case 'protonmail.com':
            url = 'https://mail.protonmail.com/';
            break;
        case 'zoho.com':
            url = 'https://mail.zoho.com/mailservice/inbox/';
            break;
        case 'mail.ru':
            url = 'https://e.mail.ru/compose/';
            break;
        default:
            url = `https://mail.google.com/mail/u/${email}`;
            break;
    }
    window.open(url);
}
export default openEmailInBrowser;