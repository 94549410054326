import * as css from './UpgradePermissionPopup.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Animation from '../../../component/animation/Animation';
import {useEffect, useRef, useState} from 'react'; import Button from '../../../component/button/button/Button';
import {DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';
import PopUpFrame from '../../../component/popUpFrame/PopUpFrame';
import setGlobalState from '../../../redux/setGlobalState';
import Close from '../../../component/close/Close';
import useOnClickOutside from '../../../hooks/UseOnClickOutside';
import {permissionToCamelCase} from '../../package/componnent/upgradePopup/UpgradePopup';
import stars from '../../../assets/animations/stars.json';
import {updateAccountPermission} from '../../../redux/account/accountActions';
import CountButton from '../../../component/button/countButton/CountButton';

function UpgradePermissionPopup() {
    const permission = useSelector(state => state.ui.account.upgradePermission);
    const permissionName = permission ? permissionToCamelCase(permission) : permission;
    const {t} = useTranslation('permission');

    const ref = useRef(null);
    useOnClickOutside(ref, close);
    const [isSendStatus, setIsSendStatus] = useState(false);
    const [showClass, setShowClass] = useState('');
    let timer = null;
    useEffect(() => () => clearTimeout(timer), []);

    useEffect(() => {
        setIsSendStatus(false);
        if(permission)
            timer = setTimeout(() => {
                setShowClass(' show');
            }, 100);
    }, [permission]);

    const cancelProps = {
        width: 100,
        text: t('noThanks'),
        backgroundColor: '#FFF',
        borderColor: '#5D5D5D',
        hoverBorderColor: DEFAULT_COLOR,
        onClick: close
    };

    function onAccept() {
        setIsSendStatus(true);
        updateAccountPermission(permission, 1);
    }

    function close(e) {
        if(permission) {
            e?.stopPropagation();
            setShowClass(false);
            timer = setTimeout(() => {
                setGlobalState('ui.account.upgradePermission', null);
            }, 300);
        }
    }

    return (<>
        <css.popup>
            {permission && <PopUpFrame showClass={showClass}>
                <css.container ref={ref}>
                    <css.top className={isSendStatus && ' send'} >
                        {isSendStatus && <Animation animation={stars} isLoop={false} />}
                        <css.topClose onClick={close}><Close onClick={close} className=' white' /></css.topClose>
                        <css.topImage />
                        <css.topText>{t(isSendStatus ? 'yourRequestSend' : 'upgradeHeader')}</css.topText>
                    </css.top>
                    <css.bottom className={isSendStatus && ' send'}>
                        {isSendStatus ?
                            <>
                                <css.bottomDescription>
                                    {t('requestSend')}
                                </css.bottomDescription>
                                <css.bottomButtons>
                                    <CountButton onClick={close} count={6} callBack={close} >
                                        {t('thanks')}
                                    </CountButton>
                                </css.bottomButtons>
                            </>
                            : <>
                                <css.bottomTitle>{t(`${permissionName}`)}</css.bottomTitle>
                                <css.bottomDescription>{t(`${permissionName}Long`)}</css.bottomDescription>
                                <css.bottomButtons>
                                    <Button BtnProps={cancelProps}  />
                                    <Button BtnProps={{text: t('acceptBtn'), onClick: onAccept, width: 100}}  />
                                </css.bottomButtons>
                            </>}
                    </css.bottom>
                </css.container>
            </PopUpFrame>}
        </css.popup>
    </>
    );
}

export default UpgradePermissionPopup;
