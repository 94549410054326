import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter, ellipsisLines} from '../../../../../globalVariables/GlobalVariables';
import {placeForDrag} from '../../../../../component/newTable/newRow/Row.css';
import * as ticket from '../TicketRow.css';
import {part} from '../part/Part.css';

export const firstPart = styled.div`
    display:flex;
    cursor: pointer;
`;
export const placeDrag = styled(placeForDrag)`

`;
export const icon = styled.div`
    overflow:visible;
    width:140px;
    background-color: ${p => p.color};
    ${p => BGImageCenter(p.icon, '25px')}
    position:relative ;
    display:flex ;
    align-items:center ;
    border-radius:${p=>p.theme.rtl? '0 5px 5px 0': '5px 0 0 5px'};
`;

export const image = styled.div`
    border-radius:${p=>p.theme.rtl? '0 5px 5px 0': '5px 0 0 5px'};
    width:90px;
    ${p => BGImageCenter(p.image, '25px')}
    background-size:cover;
    position:absolute ;
    inset-inline-start:0;
    height:100% ;
`;
export const right = styled(part)`
    width:calc(100% - 140px);
    letter-spacing:0.7px ;
    padding-inline-start:18px !important;
    padding-inline-end:10px;
    justify-content: flex-start;
`;
export const header = styled.div`
    width:calc(100% - 70px); 
`;

export const title = styled.div`
    ${ellipsisLines(2)}
    font-weight:600 ;
    font-size:14px ;
   
`;
export const group = styled.div`
    position: relative;
    width:70px ;
    height:20px ;
    border-radius: 9px;
    background-color:#${p => p.color} ;
    ${dFlexCenter}
    .itemContent{
        background-color:#${p => p.color}  ;
        margin-inline:10px;
        border-radius:4px ;
    }
`;

export const boldText = ticket.boldText;
export const text = ticket.text;
