import * as css from './DateInput.css';
import {useField} from 'formik';
import TimePicker from '../../FormikDatePicker/TimePicker';
import TooltipAbsolute from '../../tooltip/tooltipAbsolute/TooltipAbsolute';
import Checkbox from '../../checkbox/Checkbox';

function TimeInput({
    className,
    name,
    text,
    descText,
    onChange,
    readOnly,
    isTimestamp,
    isFixed,
    checkbox=false,
    timePickerProps = {},
    icon
}) {
    const [, meta] = useField(name);

    const classes = `${className}  ${
    !!meta.touched && (meta.error ? ' invalid' : ' valid')
  }`;
    const hasIcon=icon!==false;

    return (
        <>
            <css.container className={classes}>
                {hasIcon&&<css.icon className={classes} data-cy={`${name.substring(name.indexOf('.') + 1)}Validation`}/>}
                <css.wrap className={hasIcon&&'hasIcon'}>
                    <css.inputs>
                        <css.wrapDate data-cy={`${name.substring(name.indexOf('.')+1)}Input`}>
                            {checkbox && <Checkbox selected={checkbox.selected} onClick={checkbox.onClick} classes={'VCheckbox'}/>} 
                            <css.timeText className={checkbox&&'hasCheckbox'}>{text}</css.timeText>
                            <css.wrapTimePicker>
                                <TimePicker
                                    name={name}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                    isTimestamp={isTimestamp}
                                    isFixed={isFixed}
                                    {...timePickerProps}
                                />
                                {meta.touched && meta.error && (
                                    <TooltipAbsolute show={true} text={meta.error} />
                                )}
                            </css.wrapTimePicker>
                        </css.wrapDate>
                    </css.inputs>
                    <css.text>
                        <css.descText> {descText} </css.descText>
                    </css.text>
                </css.wrap>
            </css.container>
        </>
    );
}

export default TimeInput;
