import ParticipantPresentDetails from '../participantPresentDetails/ParticipantPresentDetails';
import MobilePopupModal from '../../../../component/newPopup/mobilePopup/MobilePopupModal';
import * as css from './ParticipantPopDetails.css';
import CircleImageText from '../../../../component/circleImageText/CircleImageText';
import {pencil} from '../../../../assets/symbols/symbols/Symbols.css';
function ParticipantPopDetails({participant,openDetailsPop}) {

    return (
        <MobilePopupModal {...openDetailsPop} >
            <css.container>
                <CircleImageText text={participant.firstName+' ' + participant.lastName} size={70} image={pencil}/>
            </css.container>
            <ParticipantPresentDetails participant={participant}/>
        </MobilePopupModal>

    );
}

export default ParticipantPopDetails;
