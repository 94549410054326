import * as css from './SMSCode.css';
import {useEffect, useState} from 'react';
import CodeInput from '../codeInput/CodeInput';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import setGlobalState from '../../redux/setGlobalState';
import {getTime} from '../../library/dates';
import {useTimer} from '../../hooks/useTimer';

export const codeExpiration=3*60;

export const resetCodeInput=()=>{
    setGlobalState('ui.codeInput.reset',true);
};

export const updateExpiredTime=()=>{
    const expiredTime= new Date();
    expiredTime.setSeconds(expiredTime.getSeconds()+codeExpiration);
    setGlobalState('ui.codeInput.expiredTime',expiredTime);
};

function SMSCode({amount = 6, onUpdate, sendAgainClick,address,text}) {
    const codeInput = useSelector(state => state.ui.codeInput);
    const {isWrong,expiredTime,reset}=codeInput||{};
    const [isWrongText, setIsWrongText] = useState(false);
    const [toResetCode, setToResetCode] = useState(false);
    const {timer,setTimer}=useTimer(expiredTime?(expiredTime-new Date().getTime())/1000:codeExpiration);

    const {t} = useTranslation('global');
    const phone = useSelector(state => state.user.phone);
    let phoneString = address?address:(phone?('XXX-XXX' +phone?.substr(6, phone.length)):'');

    function resetAll() {
        setTimer(codeExpiration);
        setToResetCode(true);
        setImmediate(() => setToResetCode(false));
        if(isWrong){
            setGlobalState('ui.codeInput.isWrong',false);
            setIsWrongText(false);
        }
        
    }
    
    useEffect(()=>{
        if(reset) 
            resetAll();
        setGlobalState('ui.codeInput.reset',false);
    },[reset]);

    useEffect(() => {
        if(isWrong && !isWrongText)
            setIsWrongText(true);
    }, [isWrong]); 
    
    useEffect(()=>{
        if(expiredTime)
            setTimer((expiredTime-new Date().getTime())/1000);
    },[expiredTime]);

    const optionClick = (isVoiceCode) => {
        if(sendAgainClick)
            sendAgainClick(isVoiceCode);
        resetAll();
        updateExpiredTime();
    };
''
    return (<>
        <css.container data-cy = 'temporaryCodeMSG' >
            <css.text >{isWrongText ? t('wrongCode') : (text||t('SMSSent')) + phoneString} {t('expireCode',{time:getTime(timer)})}</css.text>
            <css.code id='loginPasswordSquares'  data-cy='loginPasswordSquares'>
                <CodeInput amount={amount} onUpdate={onUpdate} isWrong={isWrong} toResetCode={toResetCode}  />
            </css.code>
            <css.options>
                <css.option onClick={() => optionClick()} data-cy= 'tryAgainLogin'>{t('sendAgain')}</css.option>
                {/* <css.option onClick={() => optionClick(true)}>{t('voiseCode')}</css.option> */}
            </css.options>
        </css.container>
    </>
    );
}

export default SMSCode;
