import validator from 'validator';

export const validateData = (key, value, isBusiness = false) => {
    switch (key) {
        case 'email':
        case 'contactEmail':
        case 'brandingEmail':
            return (!value || validator.isEmail(value));
        // case 'phone':
        // case 'contactPhone':
            // return error = (!value || validator.isMobilePhone(value, ['he-IL']) ? null : "error";////////////////todo
        case 'brandingSms':
            return (!value || validator.isLength(value, [2, 11]) && validator.isAlphanumeric(value.replace(/\s/g, ''))) ;
        case 'billingId':
            return ((!isBusiness && value && validator.isIdentityCard(value, ['he-IL'])) ||
                (isBusiness && validator.isLength(value, [8, 11]))) ;////////////////////TODO
        case 'branding':
            return  (!value || validator.isWhitelisted(value, [0, 1, '0', '1'])) ;
    }
};
