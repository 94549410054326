import * as css from './CheckBoxButton.css';
import Btn from '../button/btn/Btn';
import {useField} from 'formik';

function CheckBoxButton({children,name} ) {
    const [{value:checked},,helpers] = useField(name);
    const onChecked=()=>{
        helpers.setValue(!checked);
    };
    return (
        <css.wrapper data-cy={`${name.substring(name.indexOf('.')+1)}CheckBoxButton`}>
            <Btn onClick={onChecked}>
                <css.checkbox className={checked&&'checked'}/>
                {children}
            </Btn>
        </css.wrapper>
    );
}
export default CheckBoxButton;
