import * as css from './Group.css';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import store from '../../../../store';
import Ticket from '../ticket/Ticket';
import GroupTop from '../groupTop/GroupTop';
import Copy from '../../../../component/copy/Copy';

function Group({item, color, openPopUp, openGroup}) {
    const {t} = useTranslation('ticket');
    const [editState, setEditState] = useState(false);

    const editDrag = (state) => {
        if(!state)
            setEditState(false);
        else {
            setEditState('edit');
        }
    };

    return (
        <css.wrapper onMouseOver={() => editDrag('edit')} onMouseLeave={() => editDrag(false)}>
            <GroupTop openGroup={openGroup} openPopUp={openPopUp} item={item} color={color} editState={editState}/>
            <css.body>
                <css.title>{item.title}</css.title>
                <css.bottom>
                    <css.ticketsWrapper>
                        <css.tickets>
                            {item.tickets?.map((item) => (
                                <Ticket item={item} key={item.tid} color={item.color} />
                            ))}
                        </css.tickets>
                    </css.ticketsWrapper>
                    <css.amountLink>
                        <css.amount>{item?.tickets?.length} {t('tickets')}</css.amount>
                        <css.link>
                            <Copy link={`tic.li/gr/${store.getState().event?.name}/${item.gid}`} className="groups" />
                        </css.link>
                    </css.amountLink>
                </css.bottom>
            </css.body>
        </css.wrapper>
    );
}

export default Group;