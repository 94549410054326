import styled from 'styled-components/macro';
import {
    BGImageCenter,
    DEFAULT_COLOR,
    ellipsis,
    ellipsisLines,
    mobileMedia,
    mobileScreen
} from '../../../../../globalVariables/GlobalVariables';
import {placeForDrag} from '../../../../../component/newTable/newRow/Row.css';
import {op, pen} from '../../../../../assets/symbols/symbols/Symbols.css';
import {container, icon , wrap} from '../../../../../component/input/disabledInput/DisabledInput.css';

export const row = styled.div`
    width:580px ;
    display: flex;
    align-items:center ;
    height:60px ;
    cursor: pointer; 
     ${mobileMedia}{
        max-width: calc(100vw - 50px);
        width: 450px;
        flex-direction:column ;
        height:100%;
        padding: 10px;
    }
    :hover{
        .circleWrap{
            transition:background-color 400ms ;
            background-color: #aaa;
            /* background-color: ${DEFAULT_COLOR}; */
            ${BGImageCenter(pen,'16px'  )}
            >div{
            display:none;
            }
        }
    }
  
    `;
export const part = styled.div`
    ${icon}{
        display:none ;
    }
    ${wrap}{
        width:100%
    }
    ${container}{
        width:100%;
      ${ellipsis};
      
    }
`;

export const text = styled.div`
    display:flex ;
    font-size:14px ;
    line-height:15px ;
    ${ellipsisLines(2)}
`;

export const wrapMBtn = styled(part)`
    width:60px;
    display: flex;
    justify-content: end;
    .modeBtnText{
        margin-inline-end:2px;
        font-size:9px ;
        width:15px;
        color:black;
    }
`;

export const wrapList = styled(part)`
    position:relative;
    width:30px;
    padding-inline-start:5px;
    .toggle{
        width:35px ;
        height:16px;
        border:none; 
        :after{
            inset-inline-start:4px;
        }  
        &.active{
            background-color: ${DEFAULT_COLOR};
            :after{
                background-color:#fff ;
                inset-inline-start:calc(100% - 13px);
                }  
        }
    }
`;
export const options = styled.div`
    inset-inline-end:4%;
    width:20px;
    height:20px;
    ${BGImageCenter(op, '10px')}
`;

export const wrapInput =  styled(part)`
    padding-inline-start:15px;
    width: 25%;
    display: flex;
    justify-content: space-around;
    margin-inline-end: 17px;
    @media screen and (max-width:${mobileScreen}px) {
        width:calc(100% - 90px);
        display:block ;
        padding:0 ;
    }
`;
export const field =  styled(part)`
    margin-inline-end:20px;
        width: 14%;
    @media screen and (max-width:${mobileScreen}px) {
        width: 33%;
    }
`;
export const fieldLimit =  styled(field)`
    width: 18%;
    @media screen and (max-width:${mobileScreen}px) {
        width: 33%;
    }
`;

export const placeDrag = styled(placeForDrag)`

`;

export const bottom = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const top = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height:50% ;
`;