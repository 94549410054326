import styled from 'styled-components/macro';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';
import * as input from '../../classes/input';
import {calendar} from '../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../globalVariables/zIndex';

export const container = input.container;

export const icon = styled(input.icon)`
    ${BGImageCenter(calendar,16)}
    `;
export const wrap = input.wrap;

export const inputs = styled.div`
    display: flex;
`;

export const wrapDate = styled(input.input)`
    display: flex;
`;

export const wrapInput = styled(input.input)` 
    border:none;
    width:35%;   
.react-datepicker-popper{
    position:fixed  !important;
    top:${p=>p.top}px;
    z-index:${zIndex.baseHigh};
}
`;
export const descText = styled.div`
    display: flex;
    font-size: 11px;
    font-weight: bold;
    position:absolute ;
`;
export const dateText = styled.div`
       width: 30%;
       font-weight:500;
`;
