import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import InputList from '../../../component/inputListOld/InputList';
import {getCategories} from '../../../redux/categories/categoriesActions';
import getSpecialTranslation from '../../../functions/getSpecialTranslation';

const getTextById = (id, categories, t) => {
    let item;
    if(categories.length) {
        item = categories.find((c) => id === c.catId);
        if(!item)
            item = {title: t('category0')};
    }
    return item?.title;

};

function CategoryListNotFormik(props) {
    const {value, onChange, eid} = props;
    const {t} = useTranslation('layout');
    const categories = useSelector((state) => state.categories.eventCategories);

    const getCategoryList = () => {
        if(categories.length) {
            let categories1 = [{id: 0, key: t('category0'), text: t('category0')}];
            categories?.forEach(c => {
                categories1.push({id: c.catId, key: c.title, text: getSpecialTranslation(`category${c.catId}`,t)||c.title});
            });
            return categories1;
        }
        return [];
    };

    useEffect(async () => {
        await getCategories(eid);
    }, []);

    const change = (item) => {
        onChange({target: {name: 'category', value: item.id}});
    };

    return (
        <>
            <InputList text={t('category')} list={getCategoryList()} icon={'category'} onChange={change} value={getTextById(value, categories, t)} t={t}/>

        </>
    );
}

export default CategoryListNotFormik;

