import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Btn from '../../../../component/button/btn/Btn';
import RegularPopup from '../../../../component/newPopup/regularPopup/RegularPopup';
import Snail from '../../components/snail/Snail';
import GroupForm from '../groupForm/GroupForm';
import SymbolsAndColors from '../../../../component/symbolsAndColors/SymbolsAndColors';
import * as css from './PopUp.css';
import store from '../../../../store';
import getColor from '../../../../functions/getColor';
import {useSelector} from 'react-redux';
import Copy from '../../../../component/copy/Copy';
import setGlobalState from '../../../../redux/setGlobalState';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';

function PopUp({closePop, item}) {
    const {t} = useTranslation('ticket');
    const [show, setShow] = useState(0);
    const tickets = useSelector(s => s.tickets);
    const groupsInfo = useSelector(s => s.tickets.groups);
    const currentColor = useSelector((state) => state.ui.colorsPicker?.currentColor);
    const currentSymbol = useSelector((state) => state.ui.colorsPicker?.currentSymbol);
    const [active, setActive] = useState(item?.active);
    const formGroupRef = useRef();

    const addUpdateGroup = () => {
        if(formGroupRef.current)
            formGroupRef.current.handleSubmit();
    };
    const openColorsPop = () => {
        setShow(1);
    };

    const onClickColor = async (color) => {
        setGlobalState('ui.colorsPicker.currentColor', color);
    };
    const onClickSymbol = async (symbol) => {
        setGlobalState('ui.colorsPicker.currentSymbol', symbol);
    };

    const updateStatus = async (e, val) => {
        setActive(val);
    };

    useEffect(() => {
        const color = getColor(item.color, groupsInfo);
        const symbol = item.groupSymbol ? item.groupSymbol : store.getState().tickets.ticketsIcon;
        setGlobalState('ui.colorsPicker.currentColor', color);
        setGlobalState('ui.colorsPicker.currentSymbol', symbol);
        setGlobalState('tickets.updateGroupXHR', null);
    }, []);

    useEffect(() => {
        if(tickets.updateGroupXHR === 'SUCCESS') {
            setTimeout(function () {
                setGlobalState('tickets.updateGroupXHR', null);
            }, 3000);
        }
    }, [tickets.updateGroupXHR]);

    return (
        <>
            <RegularPopup color={currentColor} top={{title: item?.title ? item.title : t('group'), closePop: closePop, type:'group'}} image={'ticketsGroups'}>
                <css.topTopChildren>
                    <ModeBtn status={active} click={(val, e) => updateStatus(e, val)} className={'small'} />
                </css.topTopChildren>
                <css.topChildren>
                    {item.gid && <css.link>
                        <Copy link={`tic.li/gr/${store.getState().event?.name}/${item.gid}`} />
                    </css.link>}
                </css.topChildren>
                <css.bodyChildren>
                    <css.groupForm>
                        <GroupForm item={item} formGroupRef={formGroupRef} color={currentColor} symbol={currentSymbol} active={active} />
                    </css.groupForm>
                    <SymbolsAndColors showPopOnPop={{show: show, setShow: setShow}} symbols={true} onClickColor={onClickColor} onClickSymbol={onClickSymbol} />
                </css.bodyChildren>
                <css.bottomChildren>
                    <css.snail>
                        <Snail onClick={openColorsPop} color={currentColor ? currentColor : '049627'} />
                    </css.snail>
                    <css.btns>
                        <Btn onClick={closePop}>{t('close')}</Btn>
                        <Btn onClick={addUpdateGroup} className={tickets?.updateGroupXHR}>{t('save')}</Btn>
                    </css.btns>
                </css.bottomChildren>
            </RegularPopup>
        </>
    );
}

export default PopUp;