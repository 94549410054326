import * as css from './InputAmount.css';
import NumberInput from '../NumberInput';

function InputAmount(props) {
    return (
        <>
            <css.wrapInput >
                <NumberInput {...props} />
                <css.amount>{props.amountText}</css.amount>
            </css.wrapInput>
        </>
    );
}

export default InputAmount;
