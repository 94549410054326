import React from 'react';
import * as css from './BtnWhatsAppHelp.css';
import {openWhatsApp} from '../../../library/whatsApp';
import {useTranslation} from 'react-i18next';

function BtnWhatsAppHelp({messageText}) {
    const {t} = useTranslation('event');
    return (
        <css.btnHelp  onClick={()=>openWhatsApp('972732077577',messageText||'')} >
            {t('helpFromRepresentative')}
        </css.btnHelp>

    );
}

export default BtnWhatsAppHelp;
