import styled from 'styled-components';
import {desktopMedia, ellipsis, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {getColor} from '../../eventRealtime/EventRealtime.css';
import realtimeFonts from '../../fonts';

export const item = styled.div`
    ${desktopMedia}{
        max-width: 20%;
    }
`;
export const title = styled.div`
    ${realtimeFonts.text}
    ${mobileMedia}{
        font-size: 12px;
    }
    color: ${p=>getColor(p.theme.isDarkMode)};
`;
export const value = styled.div`
    font-size: 18px;
    font-weight: 600;
    ${ellipsis}
    ${mobileMedia}{
        font-size: 14px;
    }
    color: ${p=>getColor(p.theme.isDarkMode)};
`;
