import styled from 'styled-components/macro';
import convertHexToRgbA from '../../../../../functions/ConvertHexToRgbA';
import {dFlexCenter} from '../../../../../globalVariables/GlobalVariables';

export const circleWrap = styled.div`
    cursor:pointer ;
    background-color:#fff ;
    position:absolute ;
    width:36px ;
    height:36px ;
    background-color:#fff;
    padding:2px ;
    inset-inline-end:-18px;
    border-radius:50% ;
`;

export const circle = styled.div`
    width:24px ;
    height:24px ;
    background-color:rgba(${p => convertHexToRgbA(p.color)}, 0.4); 
    border-radius:50% ;
    ${dFlexCenter};
    font-size:12px ;
    font-weight:600 ;
    ${dFlexCenter};
    flex-direction:column ;
    line-height:8px ;
    padding-top:2px ;

`;
export const percent = styled.div`
    font-size:9px ;
`;

