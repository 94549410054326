import styled from 'styled-components/macro';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';

export const container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;
export const icon = styled.div`
    width: 17px ;
    height: 17px;
    border-radius: 50%;
    ${props=>BGImageCenter(props.icon,'17px')};
    margin-inline-end:10px;
`;

export const text = styled.div`

    font-size: 12px;
`;
export const plus = styled.div`
    position: relative;
`;

export const listCheckbox = styled.div`
    width: 180px;
`;