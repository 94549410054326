import React, {useEffect} from 'react';
import {useRef} from 'react';
import LoadScript from '../../../../../src/library/LoadScript';
import {GOOGLE_CLIENT_ID} from '../../../../config/config';
import {isIframe} from '../../signUp/SignUp';
import {useSelector} from 'react-redux';
import store from '../../../../store';

const LoginByGoogle = ({text='signin_with',callback=()=>{}}) => {

    const divRef = useRef(null);
    const lang = useSelector((state) => state.user.lang);

    useEffect(() => {
        LoadScript('https://accounts.google.com/gsi/client',() => {
            window.google.accounts.id.initialize({
                client_id: GOOGLE_CLIENT_ID,
                callback: (response) => {
                    callback(response);
                    window.google.accounts.id.cancel();
                },
                hl: store.getState().user.lang
            });
            window.google.accounts.id.renderButton(divRef.current, {
                theme: 'outline',
                size: 'large',
                type: 'standard',
                text: text,
                shape:'pill',
                width: 280,
                locale: store.getState().user.lang
            });
            if(!isIframe && !store.getState().auth?.isLoggedIn)
                window.google.accounts.id.prompt();
        });
    }, [divRef.current,lang]);
    
    return <div ref={divRef} />;
};

export default LoginByGoogle;
