import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import TicketsList from '../../components/ticketsList/TicketsList';
import useShow from '../../../../hooks/useShow';
import {getEventGroups, getEventTickets, updateGroup} from '../../../../redux/tickets/ticketsActions';
import store from '../../../../store';
import * as css from './GroupPage.css';
import PageWrapper from '../../../components/pageWrapper/PageWrapper';
import PopUp from '../popUp/PopUp';
import TicketsArea from '../ticketsArea/TicketsArea';
import getComponentByEventType from '../../functions/getComponentByEventType';
import RightHeader from '../../../../component/rightHeader/RightHeader';
import PageTitle from '../../../../component/pageTitle/PageTitle';

function GroupPage() {
    const {t} = useTranslation('ticket');
    const groupsInfo = useSelector(s => s.tickets.groups);
    const allTickets = useSelector(s => s.tickets.tickets);
    const popShow = useShow();
    const [ticket, setTicket] = useState({});
    const [tickets, setTickets] = useState([]);
    const [{popup, item}, setPopUp] = useState({popup: false, item: false});
    const [groupName, setGroupName] = useState(false);
    const [groupTickets, setGroupTickets] = useState([]);

    const top = useShow();
    let {pageId} = useParams();
    const gid = Number(pageId);

    const openPopup = (ticket) => {
        setTicket(ticket);
        popShow.open();
    };

    const handlePopUp = (show, item) => {
        setPopUp({popup: show, item: item});
    };

    const addTicketToGroup = async (item) => {
        const newData = groupTickets ? [...groupTickets] : [];
        newData.push(item.ticket);
        await updateGroup(gid, {tickets: newData});
        top.close();
    };

    const groupOperations = [{
        key: 0, text: t('assignTicket'),  withoutPlus:true, onClick: () => {
            top.open();
        }
    }, {
        key: 1, text: t('groupSetting'),  withoutPlus:true, onClick: () => {
            const index = groupsInfo.findIndex(obj => obj.gid === Number(gid));
            const current = groupsInfo[index];
            handlePopUp(true, current);
        }
    }];

    useEffect(() => {
        const fetchData = async () => {
            if(!store.getState().tickets.groups.length)
                await getEventGroups();
            if(!store.getState().tickets.tickets.length)
                await getEventTickets();
        };
        fetchData()
            .catch(console.error);
    }, []);

    useEffect(() => {
        groupsInfo?.forEach((g) => {
            if(g.gid === gid) {
                setGroupTickets(g.tickets);
                setGroupName(g.title);
            }
        });
    }, [groupsInfo, allTickets]);

    useEffect(() => {
        let ticketsArr = [];
        allTickets?.forEach((t) => {
            groupTickets?.forEach((g) => {
                if(g.tid === t.tid) {
                    ticketsArr.push(t);
                }
            });
        });
        setTickets(ticketsArr);
    }, [groupTickets, allTickets]);
    const Comp=getComponentByEventType();
    return (
        <PageWrapper image={'ticketsGroups'} operations={{buttons: groupOperations}} className={'ticketsGroups'}
            routing={{title:t('groups'), subTitle:t(groupName), id:gid}}>
            <>
                <RightHeader>
                    <PageTitle title={t('groupManagement')} subTitle={groupName} />
                </RightHeader>
                <TicketsArea tickets={tickets} gid={gid} openPopup={openPopup} groupTickets={groupTickets}/>

                {popShow.isOpen && <Comp tid={ticket?.tid} setShow={popShow.close}/>}
                {popup && <PopUp closePop={() => handlePopUp(false, false)} item={item}/>}
                <css.ticketsList  onClick={e => { e.stopPropagation(); top.isOpen ? top.close() : top.open(); }}>
                    <TicketsList showClass={top.showClass} onClick={addTicketToGroup} exist={tickets} close={top.close}/>
                </css.ticketsList>
            </>
            <></>
        </PageWrapper>
    );
}

export default GroupPage;
