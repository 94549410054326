import * as css from './PermissionsItem.css';
import ModeBtnPermission from '../modeBtnPermission/ModeBtnPermission';
import {useTranslation} from 'react-i18next';

const defaultRolePermissions=['owner','manage event','manage event'];
const rolePermissionsByAccountPermission={
};

function PermissionsItem({permission,disabled,index,openPopup} ) {
    const {t} =useTranslation('permission');
    return (<>
        <css.item disabled={disabled}>
            <css.right>
                <css.name>{t(permission)}</css.name>
                {t(`${permission}Desc`)}
            </css.right>
            <ModeBtnPermission rolePermission={rolePermissionsByAccountPermission[permission.permission]||defaultRolePermissions} index={index} openPopup={openPopup} />
        </css.item>
    </>
    );
}

export default PermissionsItem;