import Btn from '../../../../component/button/btn/Btn';
import * as css from './LoginButton.css';
import {useTranslation} from 'react-i18next';

function LoginButton({text, className,onClick,errorText='incorrectDetails', buttonType}) {
    const {t}=useTranslation('login');
    return (
        <css.button id="loginButton" data-cy = {`${buttonType}Button`}>
            <Btn onClick={onClick} className={className}>
                {className==='FAILED'?t(errorText):text}
            </Btn>
        </css.button>
    );
}

export default LoginButton;