import {useTranslation} from 'react-i18next';
import * as css from './Status.css';

export const statusLeadColor = {new:'#FBBB00',worksInVain:'#FF5252',deepSleep:'#0DABFF',effective:'#C6D600'};

function Status({lead,className}) {
    const {t} = useTranslation('publish');

    return (
        <css.wrapper data-cy="status" className={className}>
            <css.text data-cy="statusTitle">{t('status')}</css.text>
            <css.status color={statusLeadColor[lead.statusTitle]}>
                {t(lead.statusTitle)}
            </css.status>
        </css.wrapper>
    );
}

export default Status;
