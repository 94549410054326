import useShow from '../../hooks/useShow';
import List from '../list/List';
import * as css from './FilterList.css';

function FilterList({listProps,filter,onClick=()=>{}}) {
    const {open, close, isOpen, showClass} = useShow();

    return (

        <css.filter onClick={open}>
            <div>{listProps.list?.find(item=>item.key===filter)?.text}</div>
            {isOpen && <List  {...listProps}   showClass={showClass} close={close} onClick={onClick} />
            }
        </css.filter>
    );
}
export default FilterList;