import TopPopup from '../topPopup/TopPopup';
import * as css from './MobilePopup.css';

function MobilePopup({children, close, showClass, title, list,height='max-content',top=null,forwardRef, dataCyName, cssStyle='', closeProps={}}) {
    
    return (
        <>
            <css.popUpWrapper className={showClass}>
                <css.popUpBody data-cy={`${dataCyName ? dataCyName : 'default'}PopUp`} { ...(forwardRef?{ref:forwardRef}:{})} className={showClass} height={height} onClick={(e)=>e.stopPropagation()} top={top} cssStyle={cssStyle}>
                    <TopPopup closePop={close} title={title} list={list} closeProps={closeProps}>
                        {children[0] ? children[0] : <></>}
                    </TopPopup>
                    {children[1] || children}
                </css.popUpBody>
            </css.popUpWrapper>
        </>
    );
}

export default MobilePopup;
