import styled from 'styled-components/macro';
import {
    BGImageCenter,
    dFlexCenter,
    mobileMedia,
    RED,
    scrollbar,
    WHITE
} from '../../../../globalVariables/GlobalVariables';
import {exit, pencil} from '../../../../assets/symbols/symbols/Symbols.css';
import {wrapEdit} from '../../../../component/editAttendance/EditAttendance.css';
import zIndex from '../../../../globalVariables/zIndex';
import {variableDropdown} from '../../../../component/textEditor/TextEditor.css';
import {btn} from '../../../../component/button/btn/Btn.css';
export const title=styled.div`
  font-size: 20px;
  font-weight: 400;
  ${mobileMedia}{
    font-size: 18px;
  }
`;
export const debt=styled.div`
    width: 100%;
    height: 60px;
    background-color: ${RED};
    bottom: -70px;
    position: absolute;
    border-radius: 6px;
    display: flex;
    font-size: 22px;
    font-weight: 500;
    padding-inline: 20px;
    color: ${WHITE};
    align-items: center;
    justify-content: space-between;

    ${mobileMedia} {
        font-size: 16px;
        bottom: 0px;
        padding-inline: 12px;
    }
`;
export const sendReminder=styled.div`
    padding: 5px;
    background-color:${WHITE};
    border-radius: 25px;
   
`;

export const progressBar=styled.div`
  position: relative;
  width: 230px;
  margin-top: 30px;
  margin-bottom: 20px;
  ${mobileMedia}{
    margin-top: 60px;
  }
  ::before{
    position: absolute;
    top: -20px;
    inset-inline-end: 2px;
    content:'${p=>p.percent}';
    width: fit-content;
    height: 20px;
  }
  ::after{
    position: absolute;
    top: -20px;
    inset-inline-start: 2px;
    content:'${p=>p.text}';
    width: fit-content;
    height: 20px;
  }
  `;
export const wrapTop=styled.div`
  height: 120px;
  display:flex ;
  justify-content:space-between;
  width: 100%;
  padding: 42px;
  pointer-events: none;
  z-index: ${zIndex.baseHigh};
  transition: all 400ms;
  ${mobileMedia}{
    position: absolute;
    top: -30px;
  }
  ${wrapEdit}{
    transform: scale(1.25);
    transition: all 400ms;
  }
  ${wrapEdit}:hover{
    transform: scale(1.1);
    transition: all 400ms;
  }
  
`;
export const leftActs=styled.div`
  display: flex;
    ${mobileMedia}{
    position: absolute;
    top: 16px;
      ${p => p.theme.rtl ? 'left' : 'right'}: calc(25% - 65px);
  }
`;
export const rightActs=styled.div`
  padding-inline-start: 14px;
  padding-top: 6px;
  
`;
export const edit=styled.div`
  width: 20px;
  height: 20px;
  ${BGImageCenter(pencil,'15px')};
  margin-bottom: 30px;
`;
export const wrapAll=styled.div`
    ${dFlexCenter};
    flex-direction:column ;
    height:calc(100% - 60px) ;
    ${scrollbar}
    form{
      width:100%;
    }


`;

export const present=styled.div`
  ${BGImageCenter(exit,'15px')};
  height:27px;
  width:27px ;
  cursor: pointer;
  transition: all 300ms;
  border-radius:50% ;
  :hover{
    box-shadow:2px 2px 3px #00000070;
    background-color: white;
  }
`;
export const wrapper=styled.div`
  padding-inline: 10px;
  padding-bottom: 20px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    ${variableDropdown}{
        display: none;
    }
`;
export const buttons=styled.div`
    padding-top: 10px;
    display: flex;
    ${btn}{
        &.white {
            margin-inline: 10px !important;
        }
    }
`;

