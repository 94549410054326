import styled from 'styled-components/macro';
import {filter} from '../../../../component/filterList/FilterList.css';
import {image, images} from '../../../tickets/ticketsPopUp/courseTicket/courseGuides/CourseGuides.css';
import {ItemContent} from '../../../../component/item/Item.css';
import {title} from '../AttendancePage.css';

export const mainTitle=styled(title)`

`;

export const titleContainer=styled.div`
  display:flex;
  align-items: center;

`;
export const titleFilters=styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  ${filter}{
    margin-inline-start: 5px ;    
}
  ${images}{
    width: unset;
    margin-inline-end: 15px;
  }
 
  ${image}{
    margin-inline-end: -11px;
 
  }
  ${ItemContent}{
    margin: 0 9px;
    width: 30px;
    height: 30px;
  }
`;
