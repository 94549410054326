import * as css from './MobileTableTitle.css';
import ListWrap from '../../../../component/listWrap/ListWrap';
import useShow from '../../../../hooks/useShow';
import TableTitleItem from '../../tableTitleItem/TableTitleItem';
import {useTranslation} from 'react-i18next';

function MobileTableTitle({titleTableArray,getHelpers,currentFilters}) {
    const {open, close,isOpen, showClass} = useShow();
    const {t}=useTranslation('global');

    const getLines={
        sort:(item)=>({
            leftChildren:<css.line>{item.text}
                <css.lineSort>{getHelpers(item).isNumber?'10-0':t('sortDirection')}</css.lineSort>
            </css.line>,
            onClick:getHelpers(item).onSort,
            ...item}),
        filter:(item)=>({
            ...item,
            list:{
                title:item.list.title,
                arr:item.list.array,
                onClick:getHelpers(item).onFilter
            }
        })
    };
        
    const list={arr:titleTableArray.map(item=>getLines[item.action](item))};
    
    return (
        <>	
            <css.sortIcon onClick={isOpen?close:open}>
                {isOpen&&<ListWrap list={list} showClass={showClass} close={close}/>}
            </css.sortIcon>
            {currentFilters.map((filter)=>{
                const item=titleTableArray.find(({field})=>field===filter.field);
                return <TableTitleItem key={item.field} item={item} helpers={getHelpers(item)}/>;
            }
            )}
            
        </>
    );
}

export default MobileTableTitle;