import styled from 'styled-components/macro';
import {arrowDark} from '../../../../assets/symbols/symbols/Symbols.css';
import {topClose} from '../../../../component/circleClose/CircleClose.css';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const container = styled.div`
      padding: 0 35px 35px 35px;
      ${dFlexCenter};
      align-items: start;
      font-size: 20px;
      font-weight: 800;
      flex-direction: column;
      height: 100%;
      /* min-height: 400px; */
      white-space: pre-line;
      form {
            height: 70%;
            width: 100%;
            z-index: ${zIndex.baseRaised};
      }
      z-index: ${zIndex.baseRaised};
      height: 100%;
      width: 100%;
      transition: all 300ms;
      &.password {
            height: 70%;
      }
      >div{
            width: 100%;
      }
`;

export const password = styled.div`
      overflow: hidden;
      transition: all 300ms;
      height: 0;
      &.password {
            height: 70px;
      }
      ${mobileMedia} {
            input{
                  direction: ltr;
                  text-align: end;
                  padding-inline-end: 5px;   
            }    
      }
`;
export const back = styled(topClose)`
      ${BGImageCenter(arrowDark,'13px')}
      cursor: pointer;
`;

export const button = styled.div`
      flex-shrink: 2  ;
      &.enter{
            >div{
              flex-basis: 100%;
              ${dFlexCenter}
            }
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex:0 9 80px;
      >div{
        flex-basis: calc(50% - 5px);
        padding-inline-start: 0;
        text-align: center;
      }
`;

export const header = styled.div`
      flex-basis: 150px;
      padding-top: 70px;
      flex-shrink:3       ;
`;

export const wrapGoogle=styled.div`  
    height:40px ;
`;

const line=`
      content: '';
      height: 50%;
      border-bottom: 1px solid #00000029;
      width: 33%;
      top:0;
      position:absolute;
`;
export const likeBefore=styled.div`
      flex-basis: 60px;
      min-height: 20px;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      ${dFlexCenter}
      flex-shrink: 3      ;
      width: 100%;
      ::before{
            ${line}
            right: 0;
      }
      ::after{
            ${line}
            left: 0;
      }
      

`;
export const lineLink=styled.div`
      min-height: 20px;
      position: relative;
      ::before{
            ${line}
            width: 100%;
      }
`;
export const linkWarp=styled.div`
      position: relative;
      font-size: 12px;
      height: 40px;
      a{
            text-decoration: underline;
            color: #007acc;
            margin-inline-start: 3px;
      }
     
`;
export const loginWith = styled.div`
      height: 30%;
      width: 100%;
      transition: all 300ms;
      &.password {
            height: 30%;
      }
`;

export const loginByPassword = styled.div`
      height: max-content;
      transition: all 300ms;
      &.password {
            height: 0;
      }
`;
