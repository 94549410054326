import React from 'react';
import * as css from './BtnCircleIcon.css';
import Tooltip from '../../tooltip/Tooltip';
import ConditionalWrapper from '../../conditionalWrapper/ConditionalWrapper';

function BtnCircleIcon({onClick,icon, text,tooltipText,color}) {

    return (
        <ConditionalWrapper
            condition={tooltipText}
            wrapper={children =>  <Tooltip text={tooltipText}>{children}</Tooltip>}>
            <css.btnContainer  text={text} >
                <css.btn data-cy='changeDisplayBtn' color={color} icon={icon} onClick={onClick} />
            </css.btnContainer>
        </ConditionalWrapper>        
    );
}

export default BtnCircleIcon;