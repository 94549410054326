import styled from 'styled-components/macro';
import {warpButtons} from '../designLandingPopup/DesignLandingPopup.css';
import {popUpTop} from '../newPopup/topPopup/TopPopup.css';
import {popUpBody} from '../newPopup/mobilePopup/MobilePopup.css';
import {LIGHT_BLUE, mobileMedia} from '../../globalVariables/GlobalVariables';
import {imageStage} from '../fullEditImage/absoluteImageEditor/AbsoluteImageEditor.css';
import {sIBImage} from '../fullEditImage/FullEditImage.css';
import {bottom} from '../newPopup/innerPop/InnerPop.css';
export const image = styled(sIBImage)`
    ${p=>p.absoluteImage && `
        border: unset;
        background-image: unset;
    `}
`;
export const wrapPopup = styled.div`
    ${popUpTop}{
        background-color: ${LIGHT_BLUE};
        border-radius: 5px 5px 0 0;
    }
    ${popUpBody}{
        width: max-content;
        height: max-content;
        max-height: max-content;
    }
    ${bottom}{
        gap: 10px;
    }
`;

export const wrapAll = styled.div`
    position: relative;
    height: max-content;
    width: max-content;
    padding: 40px;
    ${imageStage}{
        position: relative;
        inset-inline-start: calc(50% - ${p=>p.size/2}px);
        margin-block-end: 60px;
    }
`;
export const top = styled.div`
    width: 100%;
    height: 60px;
    top: 0;
    background-color: ${LIGHT_BLUE};
    border-radius: 5px 5px 0 0;
`;
export const buttons = styled(warpButtons)`
    inset-inline-end: calc(50% - 90px);
    width: 100%;
    padding-top: 10px;
    ${mobileMedia}{
        inset-inline-end: 0px;
    }
`;
export const details=styled.div`
    position: relative;
    width: 100%;
    height: max-content;
    inset-inline-end: 12px;
    margin-bottom: 20px;
`;