import Accordion from '../accordion/Accordion';
import {useTranslation} from 'react-i18next';
import BankDetails from './bankDeatails/BankDetails';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {addPackageBank, getPackageBank, updatePackageBank} from '../../../../redux/account/accountActions';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {verifyBankDetails} from '../../../../library/verifyBank';
import BudgetItem from './budgetItem/BudgetItem';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import getUnequalFields from '../../../../functions/getUnequalFields';
import setGlobalState from '../../../../redux/setGlobalState';
import {yupStringMin} from '../../../../functions/validationsYup';
import ClearingSettings from '../clearingSettings/ClearingSettings';

function PackageBank({formRef}) {
    const {t} = useTranslation('package');
    const [dataWasLoaded, setDataWasLoaded] = useState(false);
    const [bankKey, setBankKey] = useState();

    const bank = useSelector((state) => state.account.bank);

    useEffect( () => {
        setTimeout(()=>{
            getPackageBank().then(() => {
                setDataWasLoaded(true);
            });
        },600);

    }, []);

    useEffect(() => {
        setBankKey(bank?.bankNumber);
    }, [bank?.bankNumber]);

    const callBack = () => {
        bounceMessage(t('accountReVerified'),'warning');

    };

    const onSubmitForm = async (values) => {
        const changesValue=getUnequalFields(formRef.current.initialValues,values);
        if(!Object.keys(changesValue).length){
            setGlobalState('ui.putXHR','SUCCESS');
            return;
        }
        values = JSON.parse(JSON.stringify(values));
        if(!verifyBankDetails(values.bankNumber, values.bankBranch, values.bankAccount)) {
            bounceMessage(t('incorrectBankDetails'),'warning');
            return;
        }
        if(bank === null)
            await addPackageBank(values);
        else
            await updatePackageBank(values, callBack);

    };

    const validationSchema = Yup.object().shape({
        bankTransferOnBehalf: yupStringMin().max(100).required().test(
            'no-double-quotes',
            t('accountOwnerValidationMsg'),
            value => !value?.includes('"')
        ),
        bankNumber: yupStringMin(1).max(30),
        bankBranch: Yup.number().min(0).required(),
        bankAccount: Yup.number().min(2).required(),///////
    }
    );

    const initialValues = {
        bankTransferOnBehalf: bank?.bankTransferOnBehalf,
        bankNumber: bank?.bankNumber,
        bankBranch: bank?.bankBranch,
        bankAccount: bank?.bankAccount,
        bankVerificationFile: bank && bank.bankVerificationFile?JSON.parse(bank.bankVerificationFile):{}
    };

    return (<>
        <Formik innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={!dataWasLoaded}
            onSubmit={values => { onSubmitForm(values); }}
        >
            <Form >
                <Accordion title={t('bankDetails')} isAlwaysOpen={true}>
                    <BankDetails bankKey={bankKey} setBankKey={setBankKey} />
                </Accordion>
                <Accordion title={t('budgetItem')} isAlwaysOpen={true}>
                    <BudgetItem />
                </Accordion>
                <Accordion title={t('clearingAndPayments')} >
                    <ClearingSettings />
                </Accordion>
            </Form>
        </Formik>
    </>
    );
}

export default PackageBank;