import React from 'react';
import {useTranslation} from 'react-i18next';
import List from '../list/List';
import useShow from '../../hooks/useShow';
import * as css from './TextEditor.css';
import {Lightning} from '../../assets/symbols/symbols/Symbols.css';
import {useVariables, variableTexts} from '../../assets/objects/Variables';
import {useSelector} from 'react-redux';

const VariableDropdown = ({quillRef, direction='rtl',remainingChars}) => {
    const {defaultVariables, disabledVariables} = useVariables();
    const variables = Object.entries(defaultVariables);
    const {t} = useTranslation('variables');
    const {open, close, isOpen, showClass} = useShow();
    const type = useSelector(s=>s.event.type);
    const getItem = (variable, disabled) => ({
        key: variable[0],
        value: variable[1],
        icon: Lightning, 
        leftChildren: <css.item>{(!!variableTexts[type] && t(variableTexts[type][variable[0]])) || t(variable[0])}</css.item>,
        disabled: disabled
    });
    const variablesList = [
        ...variables.filter((variable) => (variable[0]!=='timeOpenstartText')).map((variable) => (getItem(variable, false))),
        ...Object.entries(disabledVariables).map((variable) => (getItem(variable, true)))
    ];
    const insertVariable = (key) => {
        const variable = variables.find((item) => item[0] === key);
        if(!variable)
            return;
        if(remainingChars<(variable[1].length+2))
            return;
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection(true);
        if(range) {
            quill.insertText(range.index, ' ');
            quill.insertEmbed(range.index + 1, 'variable', variable);
            quill.insertText(range.index + 2, ' ');
            quill.setSelection(range.index + 3, 0);
        }
    };

    return (
        <>
            <css.variableDropdown direction={direction} onClick={open}>
                {t('variables')}
            </css.variableDropdown>
            {!!isOpen && <List showClass={showClass} close={close} title={t('addVariables')} height={300} width={200} left={'10px'} top={10}
                list={variablesList}
                onClick={(item) => {insertVariable(item.key);}}
            />}
        
        </>
    );
};

export default VariableDropdown;