import {useTranslation} from 'react-i18next';
import * as css from './ClearingRow.css';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';
import MoreOperations from '../../../../component/moreOperations/MoreOperations';
import {pencil, remove} from '../../../../assets/symbols/symbols/Symbols.css';
import useShow from '../../../../hooks/useShow';
import List from '../../../../component/list/List';
import {getAssignedEvents} from '../../functions/clearingInfo';
import {deleteAccountSupplier, updateAccountSuppliers} from '../../../../redux/account/accountActions';
import {useSelector} from 'react-redux';
import store from '../../../../store';

function ClearingRow({supplier, openPop}) {
    const {t} = useTranslation('package');
    const {open, close, isOpen, showClass} = useShow(500);
    const index = store.getState().account.packageSuppliers.findIndex(s => s.srid === supplier.srid);
    const supplierActive = store.getState().account.packageSuppliers[index].isActive;
    const supplierDefault = store.getState().account.packageSuppliers[index].isDefault;
    const {isMasterState}=useSelector(state=>state.ui);

    const list = [
        {key: 'edit', text: t('edit'), type: 'icon', icon: pencil, iconClass: 'groups'},
        {key: 'remove', text: t('deleteBudget'), type: 'icon', icon: remove, iconClass: 'groups'},
    ];

    const updateIsActive = async()=>{
        await updateAccountSuppliers({isActive:supplierActive?0:1, srid:supplier.srid});
    };

    const onClickList = async(val) => {
        switch (val.key) {
            case 'edit':
                openPop(supplier);
                break;
            case 'remove':
                await deleteAccountSupplier(supplier.srid);
                break;
            default:
                break;
        }
    };

    const handleOnClickItem=()=>{
        close();
    };

    return (
        <>
            <css.supplierName>
                <css.supplierDescription>
                    {supplier.description}
                </css.supplierDescription> 
                <css.supplierClearer>
                    {t(supplier.clearer)}
                </css.supplierClearer> 
            </css.supplierName>
            <css.commission>
                {supplier.commission}
            </css.commission>
            <css.type>
                {t(supplier.type)}
            </css.type>
            <css.language>
                {t(supplier.languageAssign)}
            </css.language>
            <css.currency>
                {supplier.currency}/{supplier.clearingCurrency}
            </css.currency>
            <css.padding>
                <css.isDefault className={supplierDefault && 'yes'}/>  
            </css.padding>
            <css.assignedEvents onClick={(e)=>{e.stopPropagation();open();}}>  
                {supplier.assignedEvents.length} {t('assignedEvents')}
                {!!isOpen && <List close={close} showClass={showClass} list={getAssignedEvents(supplier.assignedEvents)} onClick={handleOnClickItem} />}
            </css.assignedEvents>
            <css.toggle>
                <ModeBtn className=" small " click={(val, e) => { e.stopPropagation(); isMasterState&&updateIsActive({active: val}); }} status={supplierActive}/>
            </css.toggle>
            {isMasterState&&
            <css.moreOp>
                <MoreOperations title={t('operations')} list={list} onClick={(val) => onClickList(val)} width="200px"  fixedTop={false}/>
            </css.moreOp>}
        </>
    );
}
export default ClearingRow;
