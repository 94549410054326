import styled, {keyframes} from 'styled-components';
import {snail} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const snailBtn=styled.div`
    background-color:#C6D600;
    height: 25px;
    width: 25px;
    border-radius:30px;
    cursor: pointer;
    position: relative;
    ${BGImageCenter(snail,'17px')};
    background-image: url("${p=>p.icon}");
    background-color: #${p=>p.color};
`;