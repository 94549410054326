import React, {useEffect} from 'react';
import * as css from './DetailItem.css';
import {useSelector} from 'react-redux';
import {getDate, getHourMinutes, tsGetDayOfWeek} from '../../../../library/dates';
import {useTranslation} from 'react-i18next';
import Timer from '../timer/Timer';
import useMobile from '../../../../hooks/useMobile';
import getSpecialTranslation from '../../../../functions/getSpecialTranslation';
import {getCategories} from '../../../../redux/categories/categoriesActions';

function DetailItem ({title}:{title: string}) {
    const event = useSelector((s:any)=>s.event);
    const {t} = useTranslation('realtime');
    const {t: globalT} = useTranslation('global');
    const isMobile = useMobile();
    const {eventCategories, getCategoriesXHR} = useSelector((s:any) => s.categories);

    const category = eventCategories.find((item)=>item.catId === event.category);

    useEffect(() => {
        const getData = async () => {
            await getCategories();
        };
        if(!getCategoriesXHR)
            getData().then();
    }, []);
    
    let value;
    switch (title) {
        case 'date':
            value = event?.timeStart && `${globalT('fullDay'+tsGetDayOfWeek(event?.timeStart, event?.timeZone))}, ${getDate(event?.timeStart)}`;
            break;
        case 'timeOpen':
            value = event?.timeOpen && getHourMinutes(event?.timeOpen);
            break;
        case 'timeStart':
            value = event?.timeStart && getHourMinutes(event?.timeStart);
            break;
        case 'timer':
            value = !!event.timeStart && !isMobile  && <Timer />;
            break;
        case 'category':
            if(!event.category)
                value = t('category0');  
            else 
                value = getSpecialTranslation(`category${event.category}`, t) || category?.title;
            break;
        default:
            value = t(event[title]);
            break;
    }
    if(!value)
        return <></>;
    return (
        <css.item>
            <css.title>{t(title)}</css.title>
            <css.value>{value}</css.value>
        </css.item>
    );
}

export default DetailItem;
