import {useTranslation} from 'react-i18next';
import * as css from './CustomPixel.css';
import * as Yup from 'yup';
import {useRef} from 'react';
import {ExtractContent, RemoveTagContent} from '../../../library/ExtractContent';
import isValidJavaScript from '../../../library/isValidJavaScript';
import {addCustomPixel, updateCustomPixel} from '../../../redux/publish/publishActions';
import FormContext from '../../components/formContext/FormContext';
import {Form} from 'formik';
import InputList from '../../../component/input/inputList/InputList';
import CodeEditor from '../components/codeEditor/CodeEditor';
import Btn from '../../../component/button/btn/Btn';
import Input from '../../../component/input/Input';
import AccountPermission from '../../components/accountPermission/AccountPermission';
import {isMaster} from '../../../functions/permission';
import {yupStringMin} from '../../../functions/validationsYup';

export function WithPermission({children}) {
    const {t} = useTranslation('global');
    if(isMaster())
        return children;
    return (
        <AccountPermission permission="custom pixel" toShowMessage={true} statusArr={[0, -1]} message={t('inactiveRole')}>
            {children}
        </AccountPermission>
    );
}

function CustomPixel({show, pixel}) {

    const {t} = useTranslation('publish');
    const eventList = [{key:'page_load',text:t('activateOnPageLoad')},{key:'form_open',text:t('activateOnFormLoad')},{key:'success',text:t('success')}];
    const locationList = [{key:'head',text:t('head')},{key:'body',text:t('body')}];
    const formRef = useRef();

    const initialValues = {
        title:pixel?.title||'',
        event:pixel?.event||'',
        location:pixel?.location||'',
        html: pixel? RemoveTagContent(pixel?.pixel) : '',
        js: pixel? ExtractContent(pixel?.pixel, 'script'): '',
        css: pixel? ExtractContent(pixel?.pixel, 'style'): '',
    };

    const validationSchema = Yup.object().shape({
        title: yupStringMin().required().matches(/(\S.*\S)/),
        event: Yup.string().required(),
        location: Yup.string().required(),
        html: Yup.string()
            .test('html', t('codeError'), function(value) {
                return value || this.parent.js || this.parent.css;
            })
            .matches(
                /^<([a-z]+)([^<]+)*(?:>([\s\S]*)<\/\1>|\/>)$/i,
                t('htmlError')
            ),
        js: Yup.string().test('js', t('codeError'), function(value) {
            if(!isValidJavaScript(value)){
                return this.createError({message: t('jsError')});
            }
            return value || this.parent.html || this.parent.css ;
        }),
        css: Yup.string().test('css', t('codeError'), function(value) {
            return value || this.parent.html || this.parent.js;
        }).matches(/[a-zA-Z-]+:\s*[^;]+;/g,t('cssError')),
    });

    const onSubmit = async (_changedValues,values) => {
        // const htmlPixel=`<html>${values.html}</html>`;
        // const jsPixel=`<script>${values.js}</script>`;
        // const cssPixel=`<style>${values.css}</style>`;
        // values.pixel = htmlPixel + jsPixel + cssPixel;
        if(pixel?.pixelId){
            await updateCustomPixel(pixel.pixelId,values).then(show.close);
        }
        else
            await addCustomPixel(values).then(show.close);
    };

    const handleSubmit = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const propsCodeEditor=(name)=>({
        name:name,
        type:name,
        title:t('pixelCode'),
        dataCyName:`CustomPixel${name}`
    });

    return (
        <>
            <css.wrapper data-cy = "customPixel">
                <FormContext
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                >
                    <WithPermission>
                        <Form>
                            <Input name={'title'} text={t('popupTitle')} description={t('popupDesc')} />
                            <css.wrapList>
                                <InputList list={eventList} name={'event'} description={t('activateOnPageLoad')}/>
                                <InputList list={locationList} name={'location'} description={t('codeEmbeddingArea')}/>
                            </css.wrapList>
                            <CodeEditor {...propsCodeEditor('html')}/>
                            <CodeEditor {...propsCodeEditor('js')}/>
                            <CodeEditor {...propsCodeEditor('css')}/>
                        </Form>
                    </WithPermission>
                </FormContext>
            </css.wrapper>
            <css.saveButton>
                <WithPermission>
                    <Btn onClick={handleSubmit}>{t('save')}</Btn>
                </WithPermission>
            </css.saveButton >     
        </>
    );
}

export default CustomPixel;