import styled from 'styled-components/macro';
import {mobileMedia} from '../../globalVariables/GlobalVariables';

export const header=styled.div`  
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    font-size: 16px;
    font-weight: 800;
    &.changeToEnd{
      justify-content: flex-end;
    }
    ${mobileMedia}{
      height: 60px;
    }

`;

export const icons=styled.div`  
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

