import styled from 'styled-components/macro';
import {googleImage} from '../../../../assets/symbols/images/google';
import {bolt} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {loginEllipse} from '../loginInput/LoginInput.css';

export const button = styled(loginEllipse)`
    background-color: rgba(255,255,255,0.45);
    width: 100%;
    height: 35px;
    line-height:30px ;
    padding-inline-start:40px;
    font-size:14px ;
    font-weight:500 ;
    color:#555555e0;
    position:relative ;
    cursor: pointer;
    transition: all 300ms;
    overflow: hidden;
    :hover{
        background-color: #FFF;
    } 
    ${mobileMedia}{
    &.hidden{
        height: 0;
    }}
`;

export const logo = styled.div`  
    position:absolute ;
    width:35px ;
    height:100% ; 
    inset-inline-start:2px;
    ${BGImageCenter(googleImage, '16px')}
    &.password{
        ${BGImageCenter(bolt, '11px')}
    }
`;

export const text = styled.div`  
    height: 35px;
    line-height: 35px;
`;
