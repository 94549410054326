import SettingItem from '../../settingItem/SettingItem';
import MultipleAccountForm from '../../multipleAccountForm/MultipleAccountForm';

function HubMultipleAccount() {
    return (
        <SettingItem name={'managedAccounts'} settingCategory={'multipleAccounts'}>
            <MultipleAccountForm />
        </SettingItem>
    );
}

export default HubMultipleAccount;