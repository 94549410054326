import React from 'react';
import * as css from './WhatsAppScreen.css';
import {updateDesignEmail, updateEmailStatus} from '../../../redux/branding/brandingActions';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import EditorWrapper from '../../../component/textEditor/editorWrapper/EditorWrapper';
import {useSelector} from 'react-redux';
import {removeTagsFromText} from '../emailSubjectTextEditor/EmailSubjectTextEditor';
import {useTranslation} from 'react-i18next';

function WhatsAppScreen() {
    const {currentLang,data,getBrandingData} = useSelector(s => s.branding??{});
    const {whatsappContent,whatsappTicket}=data?.[currentLang]??{};
    const dependencies=[currentLang,getBrandingData[`${currentLang}XHR`]];
    const eventType = useSelector(s => s.event.type);
    const {t} = useTranslation('branding');
    const updateStatus = async () => {
        await  updateEmailStatus('whatsappTicket', whatsappTicket===1?0:1);
    };
    const saveText = (content) =>{
        content = content.replace(/<\/p><p>/g, '\\n');
        updateDesignEmail({whatsappContent:removeTagsFromText(content)});
    };
    const text = whatsappContent?.replace(/\\n|\n/g, '</p><p>');
    return (   
        <css.wrapper data-cy={'whatsAppScreen'}>
            <css.top>
                <ModeBtn className={'small'} status={whatsappTicket} click={updateStatus}/>
            </css.top>
            <css.startLine>{t('hello')}</css.startLine>
            <EditorWrapper data-cy={'whatsAppEditor'} dependencies={dependencies} defaultText={`<p>${text}</p>`} showToolbar={false} events={{onSave:(content)=>saveText(content)}}  lang={currentLang}/>
            <css.endLine>{eventType==='givechak'? t('endLineGivechak'): t('endLine')}</css.endLine>
        </css.wrapper>
    );
}

export default WhatsAppScreen;
