import styled from 'styled-components/macro';

export const popOnPopProgress=styled.div`
    height: 108px;
    width: 108px;
    position: absolute;
    top: -52px;
    right: calc(50% - 54px);
    .CircularProgressbar-path {
        stroke-width: 6;
    }
`;