import * as css from './InputStartEndDate.css';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import {addMonth, getMonthDifference, getPrevMonth, subtractMonth} from '../../../../../library/dates';
import MonthInput from '../../../../../component/input/dateInput/MonthInput';
import InputList from '../../../../../component/input/inputList/InputList';
import {useState} from 'react';
import RegularInput from '../../../../../component/input/regularInpit/RegularInput';

function InputStartEndDate({endName, startName, selectedProps = {}, descText, text}) {
    const {selectText, listSelect = [], selectName} = selectedProps;
    const [fieldS, ,helpersS ] = useField(startName);
    const [fieldE, , helpersE] = useField(endName);
    const [monthValue,setMonthValue] = useState(false);
    const {t} = useTranslation('ticket');
 
    const handleOnChange = (e)=>{
        const val = e.target.value;
        if(val<1)
            return;
        setMonthValue(val);
        if(fieldS.value){
            helpersE.setValue(addMonth(fieldS.value,val-1));
            helpersE.setTouched(true);
        }
        else if(fieldE.value){
            helpersS.setValue(subtractMonth(fieldE.value,val-1));
            helpersS.setTouched(true);
        }        
    };

    const onChangeStart = (value)=>{
        if(!fieldE.value&&monthValue){
            helpersE.setValue(addMonth(value,monthValue-1));
            helpersE.setTouched(true);
        }
    };

    const onChangeEnd = (value)=>{
        if(!fieldS.value&&monthValue){
            helpersS.setValue(subtractMonth(value,monthValue-1));
            helpersS.setTouched(true);
        }     
    };

    return (<>
        <css.container data-cy="InputStartEndDate">
            <css.icon/>
            <css.wrapTexts>
                <css.dateText>{text}</css.dateText>
                <css.descText> {descText} </css.descText>
            </css.wrapTexts>
        </css.container>
        <css.inputs>
            <RegularInput 
                value={(fieldS.value&&fieldE.value)? getMonthDifference(fieldS.value,fieldE.value):monthValue} 
                setValue={handleOnChange}
                text={t('sumMonth')}
                dataCyName="sumMonth"
            />
            <MonthInput name={startName} text={t('startDateField')} descText={t('startDate')} minDate={getPrevMonth()*1000} onChange={onChangeStart}/>
            <MonthInput name={endName} text={t('endDateField')} descText={t('endDate')} minDate={fieldS.value*1000}  onChange={onChangeEnd}/>
        </css.inputs>
        <css.wrapInputList> <InputList name={selectName} list={listSelect} description={selectText}/> </css.wrapInputList>
    </>
    );
}

export default InputStartEndDate;
