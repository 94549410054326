import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {GLASSIX_API_KEY, GLASSIX_SNIPPET_ID, GLASSIX_SNIPPET_ID_LOGIN} from '../config/config';

window.GlassixWidgetClient = undefined;

const useGlassixWidget = () => {
    const account = useSelector((state) => state.account);
    const user = useSelector((state) => state.user);
	
    useEffect(() => {
        const loadGlassixScript = (callback) => {
            if(window.GlassixWidgetClient) {
                callback();
                return;
            }
			
            const script = document.createElement('script');
            script.src = 'https://cdn.glassix.net/clients/widget.1.2.min.js';
            script.async = true;
            script.onload = () => callback();
            document.body.appendChild(script);
        };

        // Initialize or update the Glassix widget
        const initGlassixWidget = () => {
            let widgetOptions = {
                apiKey: GLASSIX_API_KEY,
                snippetId: GLASSIX_SNIPPET_ID_LOGIN,
            };
            if(user?.uid){
                widgetOptions = {...widgetOptions,
                    snippetId: GLASSIX_SNIPPET_ID,
                    field2: account?.pid,
                    field5: `${user?.nameFirst} ${user?.nameLast}`,
                    field6: user?.email,
                    field7: window.location.href,
                    field8: user?.phone
                };
            }
			
            if(window.GlassixWidgetClient && typeof window.GlassixWidgetClient === 'function') {
                window.widgetClient = new window.GlassixWidgetClient(widgetOptions);
                window.widgetClient.attach();
            }
        };
		
        loadGlassixScript(initGlassixWidget);
    }, [account, user, window.location.href]); // Re-run when account, user, or location changes
};

export default useGlassixWidget;
