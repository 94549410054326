import * as css from './DesignPopup.css';
import {useEffect, useState} from 'react';
import FirstScreenDesign from './firstScreen/FirstScreenDesign';
import Btn from '../button/btn/Btn';
import {useTranslation} from 'react-i18next';
import DesignUploadImage from './secondScreen/DesignUploadImage';
import LastScreen from './lastScreen/LastScreen';
import {createDesignImage, updateDesignPop, publishDesign} from '../../redux/event/eventActions';
import {useHistory, useParams} from 'react-router-dom/cjs/react-router-dom.min';
import {useSelector} from 'react-redux';
import MobilePopup from '../newPopup/mobilePopup/MobilePopup';
import {bounceMessage} from '../../layout/userMessages/UserMessage';
import setGlobalState from '../../redux/setGlobalState';
import {reloadIframe} from '../../layout/myIframe/MyIframe';
import useMobile from '../../hooks/useMobile';
import {hotjar} from 'react-hotjar';
import {HOTJAR_ID, HOTJAR_SV} from '../../config/config';
import {isBase64Url} from '../../functions/isBase64Url';
import GenerateImageScreen from './generateImageScreen/GenerateImageScreen';
import {uploadFile} from '../../redux/user/userActions';

export const btnStyle=`
    border-color: transparent;
    background-color: transparent;
`;

function DesignPopup({show}) {

    const {t} = useTranslation('event');

    const [currentScreen, setCurrentScreen]= useState(0);
    const [prevScreen, setPrevScreen]= useState(0);
    const [image, setImage] = useState();
    const [mobileImage, setMobileImage] = useState();
    const [isForMobile,setIsForMobile]=useState(null);
    const history = useHistory();
    const {eid,createDesignImageXHR}=useSelector(s=>s.event);
    const {page} = useParams();
    const isMobile=useMobile();
    const onSave= async ()=>{
        if(!image) {
            return;
        }
        const imagePath = isBase64Url(image)? await uploadFile(image, '', 'design', eid): image;
        const mobileImagePath = await uploadFile(mobileImage, '', 'design', eid);
        createDesignImage({image:imagePath,...(mobileImage===image?{}:{mobileImage: mobileImagePath})}).then(()=>{
            show.close();
            updateDesignPop();
            if(isMobile){
                publishDesign().then();
                return;
            }
            if(page==='design')
                reloadIframe();
            else history.push(`/e/${eid}/design`);
        });
    };
    useEffect(() => {
        hotjar.initialize(parseInt(HOTJAR_ID), parseInt(HOTJAR_SV));
        return () => { setGlobalState('event.createDesignImageXHR',null);};
    }, []);

    const onUploadImage=(base64)=>{
        if(!base64)
            return;
        if(isForMobile)
            setMobileImage(base64);
        else {
            setImage(base64);
            if(isForMobile===null)
                setMobileImage(base64);
        }
        setCurrentScreen(3);
    };

    let screenList = [
        {Comp:FirstScreenDesign,hasBackBtn:false},
        {Comp:DesignUploadImage},
        {Comp:GenerateImageScreen,continueBtnClick:onSave,onDrag:setImage},
        {Comp:LastScreen, hasTopImage: false,continueBtnText:'continueToStudio',continueBtnClick:onSave,onDrag:setImage}
    ];

    const {Comp,continueBtnText,continueBtnClick,hasTopImage=true,hasBackBtn=true,onDrag=onUploadImage}= screenList[currentScreen];

    const setScreen=(value)=>{
        setPrevScreen(currentScreen);
        setCurrentScreen(value);
    };

    const hasBottom=hasBackBtn||continueBtnClick;

    const onClose=()=>{
        show.close();
        //bounceMessage(t('designPopWarningMessage'), 'attention',{text:t('closeThanks'), onClick:()=>show.close()},{text:t('continue')});
    };
  
    return (
        <css.wrapper>
            <MobilePopup showClass={show.showClass} close={onClose}>
                <>
                    {hasTopImage && <css.computer />} 
                    <css.container className={`${hasBottom?'':'noBottom'} ${hasTopImage?'':'noTopImage'}`}>
                        <Comp
                            setCurrentScreen={setScreen}
                            image={image} 
                            mobileImage={mobileImage} 
                            isForMobile={isForMobile}
                            setIsForMobile={setIsForMobile} 
                            setImage={onDrag}
                            setMobileImage={setMobileImage}
                        />
                    </css.container>
                    {hasBottom&&<css.bottom>
                        {hasBackBtn && <Btn onClick={()=>setCurrentScreen(prevScreen)} style={btnStyle}>
                            <css.arrow/>
                            {t('back')}
                        </Btn>}
                        {continueBtnClick && <Btn onClick={continueBtnClick} className={'white ' && createDesignImageXHR}>
                            {t(continueBtnText||'continue')}
                        </Btn>}
                    </css.bottom>}
                </>
            </MobilePopup>
        </css.wrapper>
    );
}

export default DesignPopup;