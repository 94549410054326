import {useTranslation} from 'react-i18next';
import ColorsPicker from '../colorsPicker/ColorsPicker';
import PopOnPop from '../newPopup/popOnPop/PopOnPop';
import * as css from './SymbolsAndColors.css';
import Btn from '../button/btn/Btn';
import AnimalFlower from '../animalFlower/AnimalFlower';

function SymbolsAndColors({showPopOnPop, symbols = false, onClickColor, onClickSymbol}) {
    const {t} = useTranslation('ticket');

    return (
        <>
            <PopOnPop image={'groupColor'} showPopOnPop={showPopOnPop} dataCy='symbolsAndColors'>
                <>
                    <css.wrapColorPicker>
                        <ColorsPicker onClickColor={onClickColor} />
                        {symbols&&<AnimalFlower onClickSymbol={onClickSymbol}/>}
                    </css.wrapColorPicker>
                    <css.bottomPickerPop>
                        <Btn onClick={() => showPopOnPop.setShow(0)}>
                            {t('close')}
                        </Btn>
                    </css.bottomPickerPop>
                </>
            </PopOnPop>
        </>
    );
} 

export default SymbolsAndColors;