import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Tooltip from '../../tooltip/Tooltip';
import * as css from './Colors.css';

function Colors({onClickColor, color, disableColor = false}) {

    const {t} = useTranslation('global');
    const colorsPicker = useSelector((state) => state.ui.colorsPicker);
    const currentColor = colorsPicker.currentColor;

    const updateSelected = (color) => {
        if(onClickColor)
            onClickColor(color);
    };

    return (
        <>
            {disableColor && (currentColor !== color) ?
                <Tooltip text={t('colorCaught')}>
                    <css.color className={' disabled '} color={color} onClick={() => updateSelected(color)} />
                </Tooltip> :
                <css.color className={(currentColor === color && ' selected ')} color={color} onClick={() => updateSelected(color)} />}
        </>
    );
}

export default Colors;
