import * as css from './EditInput.css';
import {useField} from 'formik';
import CircleBtn from '../../../pages/tickets/ticketsPopUp/courseTicket/circleBtn/CircleBtn';
import {ex, vi} from '../../../assets/symbols/symbols/Symbols.css';
import {useEffect, useRef, useState} from 'react';

function EditInput({name, text, close, onCancel, onPressEnter, refParent}) {
    const [field, , helpers] = useField(name);
    const [val, setVal] = useState(field.value);
    const ref = useRef(null);

    useEffect(() => {
        ref.current.focus();
    }, []);

    const onAccept = () => {
        helpers.setValue(val);
        close();
    };

    const _onCancel = () => {
        close();
        if(onCancel)
            onCancel();
    };

    const onKeyDown = e => {
        if(onPressEnter && val && (e.key === 'Enter' || e.key === 'Tab')) {
            helpers.setValue(val);
            onPressEnter();
            e.preventDefault();
        }
        else if(e.key === 'Enter')
            onAccept();
    };

    const onFocus = e => {
        if(!ref.current)
            return;
        setTimeout(function(){
            const offset = ref?.current?.getBoundingClientRect();
            const topPos = offset.top;
            refParent.current.scroll({top: topPos, left: 0, behavior: 'smooth'});
        },500);
    };

    return (
        <>
            <css.input>
                <input ref={ref} type='text' onChange={e => setVal(e.target.value)} onKeyDown={onKeyDown} placeholder={text} value={val} onFocus={onFocus} data-cy="newItemInput"/>
                <css.btn>
                    <CircleBtn icon={vi} onClick={onAccept} color='#77d154' disabled={!val} dataCyName="confirmItem"/>
                </css.btn>
                <css.btn>
                    <CircleBtn icon={ex} onClick={_onCancel} color='#BBB' dataCyName="removeItem" />
                </css.btn>
            </css.input>
        </>
    );
}

export default EditInput;
