import {useRef} from 'react';
import * as css from './NewStaffPop.css';
import PopOnPop from '../../../../../component/newPopup/popOnPop/PopOnPop';
import Button from '../../../../../component/button/button/Button';
import {useTranslation} from 'react-i18next';
import {addBtn, closeBtn} from '../../../../../functions/globalBtns';
import Input from '../../../../../component/input/Input';
import SelectFormik from '../../../../../component/selectFormik.js/SelectFormik';
import CheckboxFormik from '../../../../../component/checkboxFormik/CheckboxFormik';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';

function NewStaffPop({showPopOnPop}) {
    
    const {t} = useTranslation('ticket');

    const currentGuideItem = useSelector(state => state.ui.courses.currentGuideItem);
    // const [fieldInput, fieldMeta, fieldHelpers] = useField('courses');

    const formRef = useRef();

    const initialValues = {

    };

    const validationSchema = Yup.object().shape({

    }
    );

    const handleSubmit = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <>
            <PopOnPop dataCy='newStaff' image={'groupColor'} title={currentGuideItem ? t('editStaffM') : t('newStaffM')} showPopOnPop={showPopOnPop}>
                <>
                    <Formik innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        // enableReinitialize={!dataWasLoaded}
                        // onSubmit={values => { addStaff(values) }}
                        render={() => (
                            <Form>
                                <css.wrapContentPop>
                                    <Input name={'extensionName'} text={t('name')} description={t('fieldTypeDesc')} />
                                    <Input name={'extensionName'} text={t('lastName')} description={t('fieldTypeDesc')} />
                                    <Input name={'extensionName'} text={t('phone')} description={t('fieldTypeDesc')}  />
                                    <Input name={'extensionName'} text={t('E-mail')} description={t('fieldTypeDesc')}  />
                                    <SelectFormik name={'icecream'} text={t('role')} />
                                    <css.wrapCheckbox>
                                        <CheckboxFormik name={'isAwesome'} text={t('addTeam')}> </CheckboxFormik>
                                    </css.wrapCheckbox>

                                </css.wrapContentPop>
                                <css.bottomPop>
                                    <Button BtnProps={closeBtn(t,()=>showPopOnPop.setShow(0))} dataCyName="newStaffCloseBtn"> </Button>
                                    <Button BtnProps={addBtn(t, handleSubmit)}  dataCyName="newStaffDoneBtn"/>
                                </css.bottomPop>
                            </Form>
                        )
                        } />
                </>
            </PopOnPop>
        </>
    );

}

export default NewStaffPop;
