import React, {useEffect, useState} from 'react';
import * as css from './Textarea.css';

function Textarea(props) {

    const {textaeraInput, onChange, setTextareaInput, doOnfocus, index=null, setFocus, className} = props;
    const [inputFocus, ] = useState(0);
    const [charsCount, setCharsCount] = useState(0);

    // const eventHandlers = useMemo((e) => ({
    //     onFocus: () => setInputFocus(true),
    //     onBlur: (e) => {
    //         setInputFocus(false);
    //         validInput(e)
    //     },
    // }), []);

    const handleOnChange = (e) => {
        if(onChange)
            onChange(e);
    };

    const validInput = (e) => {
        if(setFocus)
            setFocus(0);
        if(textaeraInput.validation) {
            if((/<|>/.test( e.target.value))&&setTextareaInput) {
                setTextareaInput(prevState => ({...prevState, notValid: 1}));
            }
        }
    };

    useEffect(() => {
        setCharsCount(textaeraInput?.value?.length ? textaeraInput?.value.length : 0);
    }, [textaeraInput?.value]);

    return (
        <css.detail className={className}>
            <css.detailInput className={'detailInput ' + (!!inputFocus && ' focus ') + (!textaeraInput?.value && ' defaultInput') + (!!textaeraInput?.notValid && ' notValid')} validationText={textaeraInput?.validation?.text} textareaHeight={textaeraInput?.textareaHeight} charsCount={charsCount} sumChars={textaeraInput?.sumChars} >
                <css.diIcon className={(!!textaeraInput?.notValid && ' notValid')} icon={textaeraInput?.icon} />
                <css.isValid className={(!!textaeraInput?.notValid && ' notValid')}/>
                <textarea value={textaeraInput?.value} maxLength={textaeraInput?.sumChars} name={textaeraInput?.name} onChange={handleOnChange}
                    placeholder={textaeraInput?.textareaPlaceholder} className={(!textaeraInput?.value && 'defaultInput ') + (!!textaeraInput?.notValid && ' notValid')} onFocus={() => doOnfocus && doOnfocus(textaeraInput, index)} onBlur={(e)=>validInput(e)} />
            </css.detailInput>
        </css.detail>
    );
}
export default Textarea;
