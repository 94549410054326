import * as css from './PackageSettings.css';
import {useTranslation} from 'react-i18next';
import useShow from '../../../hooks/useShow';
import ListWrap from '../../../component/listWrap/ListWrap';
import useAccountMenu from '../../../tickchakHooks/useAccontMenu';
import Tooltip from '../../../component/tooltip/Tooltip';
import {useSelector} from 'react-redux';

function PackageSettings() {
    const account = useSelector(s=>s.account);
    const {open, close, isOpen, showClass} = useShow( 300);
    const {t} = useTranslation('layout');
    const list=useAccountMenu(close);

    return (
        <css.packageSettings {...((account.userAccountRole && ['owner', 'manager'].includes(account.userAccountRole)) ? {onClick:(isOpen ? close : open)} : {className:' noAccountRole'})} >
            {isOpen && <ListWrap close={close} showClass={showClass} list={list()} top="36" t={t} />}
            <Tooltip text={t('accountDetails')}>
                <css.packageIcon data-cy ='symbolDefDark'/>
            </Tooltip>
            {!(account.ownerEmailVerified ===1 && account.termsVerified && account.bankVerified)&&<css.unverifiedAccount/>}
        </css.packageSettings>
    );
}

export default PackageSettings;