import axios from 'axios';
import store from '../store';
import setGlobalState from '../redux/setGlobalState';
import getStoreByPath from '../functions/getStoreByPath';
import {bounceMessage} from '../layout/userMessages/UserMessage';

export const updateRequestStatus = (action, method, status, errorMessage = null) => {
    setGlobalState(`${action}XHR`, status);

    if(method === 'put') {
        setGlobalState('ui.putXHR', status);
    }
    if(errorMessage) {
        if(!store.getState().auth.isLoggedIn)
            setGlobalState('auth.message',errorMessage);
        else {
            bounceMessage(errorMessage,'warning',undefined,undefined,5);
        }
    }
};

const request = async (action, method, ...args) => {
    if(getStoreByPath(`${action}XHR`)==='LOADING'){
        await new Promise(setImmediate);
        if(getStoreByPath(`${action}XHR`)==='LOADING')
            return Promise.reject(`${action} is blocked`);
    }
    updateRequestStatus(action, method, 'LOADING');
    // await new Promise((res)=>setTimeout(res,300));
    console.assert( ['get', 'delete', 'head', 'options', 'post', 'put', 'patch'].includes(method),{method:method,action:action});
    try {
        if(method==='get')
            args[1]={params:args[1]};
        const response = await axios[method](...args);
        setImmediate(()=>{
            updateRequestStatus(action, method, 'SUCCESS');
        });
        return response.data;
    } catch (e) {
        const {errorMessage} = e.response && e.response.data ? e.response.data : '';
        updateRequestStatus(action, method, 'FAILED', errorMessage);

        if(e?.response?.data?.masterUserNeedPassword && e?.response?.data?.errorMessage){
            alert(e.response.data.errorMessage);
        }
        throw e;
    }
};

export default request;
