import Cookies from 'js-cookie';

const tickchakQueue = (key, value) => {
    const tickchakQueue = Cookies.get('tickchakQueue');
    if(tickchakQueue) {
        const dataObject = JSON.parse(tickchakQueue);
        dataObject[key] = value;
        Cookies.set('tickchakQueue', JSON.stringify(dataObject));
    }
    else {
        const cookieValue = JSON.stringify({[key]: value});
        Cookies.set('tickchakQueue', cookieValue);
    }

};
export default tickchakQueue;