import React from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './VerificationItem.css';

function VerificationItem({className, text}) {

    return (
        <css.item>
            <css.icon className={className}/>
            <css.text>{text}</css.text>
        </css.item>
    );
}

export default VerificationItem;