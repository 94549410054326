import {useEffect, useState} from 'react';
import * as css from './EventPopUpBottom.css';
import List from '../../../../../component/list/List';
import Button from '../../../../../component/button/button/Button';
import WrapChekBox from '../../../../../component/wrapChekBox/WrapChekBox';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useShow from '../../../../../hooks/useShow';
import setGlobalState from '../../../../../redux/setGlobalState';
import {bounceMessage} from '../../../../../layout/userMessages/UserMessage';
import useMobile from '../../../../../hooks/useMobile';

function EventPopUpBottom(props) {

    const {t} = useTranslation('layout');
    const [moreChekBox, setMoreChekBox] = useState([]);
    const preparingEvent = useSelector((state) => state.preparingEvent);
    const isEditImage = useSelector(state => state.ui.editImage.isEditImage);
    const updateEventDetailsXHR = preparingEvent.updateEventDetailsXHR;
    const eventDuplicationXHR = preparingEvent.eventDuplicationXHR;
    const errorMessage = useSelector((state) => state.cluster.errorMessage);
    const isMobile = useMobile();
    const listOC = useShow();
    const {type, eventPreparing, isEmptyFields, setItemDup, itemsDup, current, onClickStage, sendBtnDisabled, close} = props;

    const continueBtnProps = {
        text: t('continueBtn'),
        letterSpacing: '1.2px',
        iconBtn: 'arrowBtn',
        class: ' arrow ',
        onClick: () => onClickStage('image'),
    };

    const saveBtnProps = {
        text: (updateEventDetailsXHR === 'SUCCESS' || eventDuplicationXHR === 'SUCCESS') ? t('saved') : t('sendBtn'),
        letterSpacing: '1.2px',
        disabled: sendBtnDisabled,
        class: 'save ' + eventDuplicationXHR + ' ' + updateEventDetailsXHR + ' ' + 'btnCss' + ' ' + (isEditImage && ' disabled '),
        onClick: isEmptyFields,
    };

    const handleSelected = (selected, item) => {
        setItemDup(itemsDup?.map((i) => {
            if(i.key == item.key)
                i.selected = selected;
            return i;
        }));
    };

    const handleSelectedList = (item, selected) => {
        handleSelected(selected, item);
    };

    useEffect(() => {
        setItemDup(itemsDup?.filter(item =>
            (eventPreparing.type !== 'area' && item.key !== 'seats') ||
                eventPreparing.type === 'area'
        ));
    }, []);

    useEffect(() => {
        setMoreChekBox([]);
        itemsDup?.map((item, index) => {
            if(index >= (isMobile ? 2 : 5)) {
                setMoreChekBox(oldArray => [...oldArray, item]);
            }
        });
    }, [itemsDup]);

    useEffect(() => {
        let saveS = eventDuplicationXHR ? eventDuplicationXHR : updateEventDetailsXHR;
        if(saveS == 'SUCCESS' || saveS == 'FAILED') {
            setTimeout(function () {
                if(saveS == 'SUCCESS')
                    close();
                if(type == 'duplicate')
                    setGlobalState('preparingEvent.eventDuplicationXHR', null);
                if(type == 'update')
                    setGlobalState('preparingEvent.updateEventDetailsXHR', null);
            }, 1500);
        }
    }, [eventDuplicationXHR, updateEventDetailsXHR]);

    useEffect(() => {
        let saveS = eventDuplicationXHR ? eventDuplicationXHR : updateEventDetailsXHR;
        if(saveS == 'FAILED' && errorMessage) {
            bounceMessage(errorMessage,'error');
        }
    }, [errorMessage]);
    return (
        <>
            <css.continueBtn>
                <css.CheckboxWrap>
                    {type == 'duplicate' && <>
                        {itemsDup?.map((item, index) => {
                            if(index < (isMobile ? 2 : 5))
                                return <WrapChekBox key={index} item={item} handleSelected={(selected) => handleSelected(selected, item)} />;
                        })}
                        {(!!moreChekBox.length) &&
                            <css.arrowMoreCB icon={'arrowDown'} onClick={() => listOC.open()}>
                                {!!listOC.isOpen && <List close={listOC.close} showClass={listOC.showClass} list={moreChekBox} width={'175px'} bottom={'5'} left={'0px'} onClick={handleSelectedList} />}
                            </css.arrowMoreCB>}</>}
                </css.CheckboxWrap >
                <css.wrapBtn>
                    {current == 'details' ? <Button BtnProps={continueBtnProps} /> : <Button BtnProps={saveBtnProps} />}
                </css.wrapBtn>
            </css.continueBtn>
        </>
    );
}

export default EventPopUpBottom;
