import moment from 'moment-timezone';
import {DEFAULT_TIME_ZONE} from '../globalVariables/locals';

function getCountryByTimeZone(timeZone,isDateFormat=true) {
    
    if(isDateFormat&&timeZone===DEFAULT_TIME_ZONE)
        return '';
    const zone = moment.tz.zone(timeZone);
    if(zone && zone.countries) {
        const countries = zone.countries();
        if(countries && countries.length > 0) {
            const correctCountry = countries[0].toLowerCase().replace('ps','il');
            return isDateFormat?`(${correctCountry})`:correctCountry; 
        }
    }
    return '';
}

export default getCountryByTimeZone;