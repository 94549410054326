import {useEffect} from 'react';
import setGlobalState from '../redux/setGlobalState';
import useTimeout from './useTimeout';

const useRestartXHR=(XHR,path,duration=1500)=>{
    
    const timer = useTimeout();

    useEffect(()=>{
        if(XHR==='SUCCESS'||XHR==='FAILED'){
            timer.current=setTimeout(() => {
                setGlobalState(path,'UPDATED');
            },duration);      
        }
    },[XHR]);

};
export default useRestartXHR;