import {useEffect} from 'react';
import getColor from '../functions/getColor';
import setGlobalState from '../redux/setGlobalState';
import {useSelector} from 'react-redux';
import {getFirstDayInMonth, getLastDayInMonth} from '../library/dates';
import {addTicket, updateTicket} from '../redux/tickets/ticketsActions';
import {useTranslation} from 'react-i18next';
import {getValidationSchema} from '../pages/tickets/ticketsPopUp/functions/validationSchema';
import {getInitialValues} from '../pages/tickets/ticketsPopUp/functions/TicketInitialValues';
import {defaultImage} from '../assets/symbols/images/defaultImage';
import {bounceMessage} from '../layout/userMessages/UserMessage';
import {getMessages, hasError} from '../pages/tickets/tickets/getErrorMessage';

export default function useTicket() {
    const {t} = useTranslation('ticket');

    const tickets = useSelector((s) => s.tickets?.tickets);
    const ui = useSelector((s) => s.ui);
    const currentColor = ui?.colorsPicker?.currentColor;
    const event = useSelector((s) => s.event);
    const ticket = useSelector((s) => s.tickets?.currentTicket);

    useEffect(() => {
        const color = getColor(currentColor, tickets);
        setGlobalState('tickets.currentTicket', {color});
    }, []);

    const save = (_changedValues,values) => {
        let newValues = {...values};
        if(values.draft){
            newValues.active=0;
        }
        if(values.imgData !== defaultImage) {
            newValues.imgData = values.imgData;
        }
        if(currentColor) newValues.color = currentColor;
        newValues.description = JSON.stringify(values.description);
        newValues.typeTicket = event.type;
        newValues.ticketFieldCustom = values.courseFields;
        newValues.payments = {...newValues.payments};
        if(newValues.course.startDate&&newValues.course.endDate)
            newValues.course = {
                ...newValues.course,
                startDate: getFirstDayInMonth(newValues.course.startDate),
                endDate: getLastDayInMonth(newValues.course.endDate),

            };
        newValues.location = {
            ...newValues.location,
            skipHolidays: [newValues.location.skipHolidays],
            draft:values.draft,
            hideGuide:values.hideGuide,
            hideScheduled:values.hideScheduled,
        };
        // if(newValues.preSale && (newValues.preSale.ableFrom ||newValues.preSale.discount!==0)){
        //     newValues.preSale =[newValues.preSale];
        //     newValues.prices=newValues.prices.map(itemPrice=>{
        //         if(!itemPrice.preSale.length)
        //             return {...itemPrice,preSale:[{tpsid:newValues.preSale[0].tpsid||0,discount:newValues.preSale[0].discount}]};
        //         return itemPrice;
        //     });
        //
        // }
        // else
        //     newValues.preSale =null;
        Object.keys(newValues.extras).forEach((key) => {
            if(newValues.extras[key].active === false)
                newValues.extras[key].active = 0;
            else if(newValues.extras[key].active === true)
                newValues.extras[key].active = 1;
        });

        if(!ticket?.tid) {
            addTicket(newValues).then();
            if(!tickets.length && event.type === 'course')
                setGlobalState('ui.courses.feedback', true);
        } else {
            updateTicket(ticket?.tid, newValues).then((errors)=>{
                const error = hasError(errors);
                if(error)
                    bounceMessage(getMessages(t,errors), 'warning' );
            });
        }
    };

    const mergedInitialValues = (values) => {
        return {
            ...values.details,
            ...values.extras,
            ...values.payments,
            ...values.customFields,
        };
    };

    return {
        save,
        validationSchema: getValidationSchema(t),
        generalInitialValues: getInitialValues(ticket || {}, t, tickets),
        mergedInitialValues,
    };
}
