import InputList from '../../../../../component/input/inputList/InputList';
import {useSelector} from 'react-redux';
import {checkIfBitOrBilling, getAllSuppliers} from '../../../functions/clearingInfo';
import {useTranslation} from 'react-i18next';

function Supplier({currentItem, disabledCurrencyAndLang}) {
    const {t} = useTranslation('package');
    const account = useSelector(s=> s.account);
    const supplierInfo = useSelector(s=> s.supplier);
    const packageSuppliers = account.packageSuppliers;
    const allSuppliers = supplierInfo?.allSuppliers;

    const changeSupplier = (n, k)=>{
        const bitOrBilling = checkIfBitOrBilling(k, allSuppliers);
        disabledCurrencyAndLang(bitOrBilling);
    };

    return (
        <InputList name='srid' list={getAllSuppliers(allSuppliers, currentItem, packageSuppliers)} text={t('supplierTitle')} description={t('selectFromList')} onChange={changeSupplier}/>
    );
}
export default Supplier;
