import * as css from './AutomatedLeadsArea.css';
import {useSelector} from 'react-redux';
import {AutomatedLead} from '../automatedLead/AutomatedLead';
import {ThemeProvider} from 'styled-components';
import {useState} from 'react';
import useTablet from '../../hooks/useTablet';
import {parseNumber} from '../../library/numberFunction';

const LEAD_BASE_LENGTH = 6; 

function AutomatedLeadsArea() {
    const {automatedLeads} = useSelector(s=>s.publish.lead);
    const [isExpanded, setIsExpanded] = useState(false);
    const isTablet = useTablet();
    const sortedLeads = automatedLeads.slice().sort((LeadA, LeadB) => {
        if(LeadA.name === 'LIVE') {
            return -1;
        } else if(LeadB.name === 'LIVE') {
            return 1;
        } else {
            const priceA = parseNumber(LeadA.totalPrice);
            const priceB = parseNumber(LeadB.totalPrice);
            return priceB - priceA;
        }
    });

    return (
        <ThemeProvider theme={{isExpanded}}>
            <css.wrapper data-cy='automatedLeadsArea'>
                {sortedLeads.map((lead) => (
                    <AutomatedLead key={lead.title} lead={lead}/>            
                ))} 
            </css.wrapper> 
            {(!isTablet&&automatedLeads?.length>LEAD_BASE_LENGTH)&&<css.icon onClick={()=>setIsExpanded(!isExpanded)} data-cy="showAllAutomatedLeads"/>}
        </ThemeProvider>
    );
}

export default AutomatedLeadsArea;
