import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Area, AreaChart, ResponsiveContainer, Tooltip} from 'recharts';
import List from '../../../../component/list/List';
import useShow from '../../../../hooks/useShow';
import * as css from './CashGraph.css';
import {LIGHT_BLUE} from '../../../../globalVariables/GlobalVariables';
import {formatRealtimeNumber} from '../../functions';
import {CustomDot} from './customDot/CustomDot';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
}

export function CashGraph() {
    const realtime = useSelector((s: any) => s.realtime);
    const {isOpen, open, showClass, close} = useShow();
    const event = useSelector((s: any) => s.event);
    const {t} = useTranslation('realtime');
    const elementRef = useRef(null);

    const defaultPeriods = ['todayOrders', 'weekOrders', 'monthOrders', 'allOrders'];
    const initialList = defaultPeriods.reduce(
        (acc, period, index) => {
            const orders = realtime?.event?.orders?.[period] || [];
            if(orders.length){
                acc.data.push(
                    orders.map((order) => ({
                        date: order.date,
                        amount: parseFloat(order.amount),
                        count: parseFloat(order.count),
                        title: order.title,
                    }))
                );
                acc.list.push({
                    key: acc.data.length - 1,
                    text: [t('today'), t('thisWeek'), t('thisMonth'), t('allTimes')][index],
                });
            }
            return acc;
        },
        {data: [], list: []}
    );

    const [key, setKey] = useState(0); // Default value set to 0

    useEffect(() => {
        const weekOrdersIndex = initialList.list.findIndex(item => item.text === t('thisWeek'));
        if(weekOrdersIndex !== -1){
            setKey(weekOrdersIndex);
        }
    }, []);

    const title = initialList.list[key]?.text || '';

    const onClickItem = (item) => {
        setKey(item.key);
        close();
    };
    const getUpdatedList = () => {
        return initialList.list.filter((listItem) => listItem.text !== title);
    };

    const CustomTooltip: React.FC<CustomTooltipProps> = ({active, payload}) => {
        if(active && payload && payload.length) {
            return (
                <css.customTooltip className={'free-color'}>
                    <css.tooltipText>{`${payload[0]?.payload?.title}`}</css.tooltipText>
                    <css.tooltipText>{`${event.currencySymbol}${formatRealtimeNumber(payload[0]?.payload?.amount)}`}</css.tooltipText>
                    <css.tooltipText>{`${t('tickets')} ${formatRealtimeNumber(payload[0]?.payload?.count)}`}</css.tooltipText>
                </css.customTooltip>
            );
        }
        return null;
    };
    if(!event?.timeStart || !event?.timeEnd)
        return null;
    return (
        <css.container  hasOrders={initialList?.data[key]?.length>1}>
            {getUpdatedList().length > 0 && (
                <css.list className='free-color' onClick={open} data-cy="graphTimesList">
                    <css.period>{`${t('showGraphBy')} ${title}`}</css.period>
                    {isOpen && (
                        <List
                            height={60}
                            width={'200px'}
                            list={getUpdatedList()}
                            title={title}
                            showClass={showClass}
                            close={close}
                            onClick={onClickItem}

                        />
                    )}
                </css.list>
            )}
            <css.chartContainer ref={elementRef} >
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={initialList.data[key]} margin={{top: 52, right: 0, left: 0, bottom: 0}}>
                        <Tooltip content={<CustomTooltip />} cursor={{stroke: 'none'}} />
                        <Area
                            type="linear"
                            dataKey="amount"
                            stroke={LIGHT_BLUE}
                            strokeOpacity="0.6"
                            fill={LIGHT_BLUE}
                            dot={<CustomDot elementRef={elementRef}/>}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </css.chartContainer>
        </css.container>
    );
}
