import styled from 'styled-components/macro';
import {cssEscape} from '../../../../library/charEscape';
import {css} from '../../previewScreen/PreviewScreen.css';
import {teamMemberPhoto} from '../../../../assets/symbols/images/teamMemberPhoto';
import {BGImageCenter, LIGHT_BROWN, DARK_GREEN, WHITE2, mobileMedia, tabletMedia} from '../../../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
    display: flex;
    position: relative;
    border-radius: 10px;
    background: ${LIGHT_BROWN} 0% 0% no-repeat padding-box;
    width: calc(100% - 10px);
    height: calc(100% - 200px);
    flex-direction: column;
    justify-content: space-between;
    align-items: center;    
    padding-bottom: 30px;
    ${mobileMedia && tabletMedia}{
        width: calc(100% - 40px);
        margin-inline: 20px;
    }
`;

export const profile = styled.div`
    height: 35px;
    width: 35px;
    ${BGImageCenter(teamMemberPhoto,'contain')}
    border-radius: 50%;
    border: 2px double white;
    margin-inline-end: 8px;
`; 
export const text=styled.div`
    position: relative;
    font-size: 12px;
    width: 260px;
    min-height: 120px;
    max-height: max-content;
    border-radius: 10px;
    background: ${WHITE2} 0% 0% no-repeat padding-box;
    width: calc(100% - 80px);
    padding: 10px;
    inset-inline-end: 10px;
    ::after{
        content:"${p=>(cssEscape(p.time))}";
        inset-block-end: 8px;
        inset-inline-end: 10px;
        ${css}
    }
    ::before{
        content: '';
        position: absolute;
        ${p => p.theme.rtl ? 'left' : 'right'}: -25px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 40px solid ${WHITE2};
        border-bottom: 30px solid transparent;
        border-radius: 10px;
        border-top-left-radius: 40px;
        top: ${p => p.theme.rtl ? -5 : 0}px;       
        transform: rotate(${p => p.theme.rtl?13:270}deg);
        border-top-left-radius: 40px;
        border-bottom-left-radius:30px;
    }
`;

export const title=styled.div`
    width: 100%;
    height: 62px;
    background: ${DARK_GREEN} 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 10px 10px 0 0;
    color: white;
    padding: 10px;
    font-size: 17px;
`;
