import React from 'react';
import * as css from './Options.css';
import Tooltip from '../tooltip/Tooltip';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import {useTranslation} from 'react-i18next';

function Options({list = []}) {
    const {t} = useTranslation('ticket');
    const openList=(e)=>{
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <css.optionBtn data-cy="eventOptionBtn">
            {<css.optionCon className={'open'}>
                <css.opOpen onClick={openList}/>
                <css.optionItems  className={'openItems'}>
                    {list.map((item, index) =>
                        <ConditionalWrapper key={index}
                            condition={item.text}
                            wrapper={children =>  <Tooltip text={item.text}>{children}</Tooltip>}>
                            <css.optionItem data-cy={`${item.dataCyName}Event`} key={index} icon={item.icon} className={item.type} onClick={(e)=>item.onClick(e)} />
                        </ConditionalWrapper>
                    )}
                </css.optionItems>
                <css.opOptionIcon onClick={openList}/>
            </css.optionCon>}
        </css.optionBtn>
    );
}
export default Options;
