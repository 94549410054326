import {useTranslation} from 'react-i18next';
import TickchakContracts from '../TickchakContracts/TickchakContracts';
import * as css from './Stage.css';
// import image1 from '../../../assets/images/login/image1.png';
// import image2 from '../../../assets/images/login/image2.png';
import image3 from '../../../../assets/images/login/image3.png';
import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../../redux/setGlobalState';
import Close from '../../../../component/close/Close';
import {isIframe, sendPostMessage} from '../../signUp/SignUp';

function Stage({children, isHeightHeader,isDark}) {
    const {t} = useTranslation('login');
    const loginImageIndex=useSelector(s=>s.ui.layout.loginImageIndex);

    const images=[image3];
    // const images=[image1,image2,image3];
    const imageIndexRef=useRef(loginImageIndex==null?Math.floor(Math.random() * images.length):loginImageIndex);

    useEffect(()=>{
        if(!loginImageIndex)
            setGlobalState('ui.layout.loginImageIndex',imageIndexRef.current);
    },[loginImageIndex]);
    const isKeyboardOpen=useSelector(s=>s.ui.isKeyboardOpen);

    const attributes = {image: 'none', backgroundColor: false};
    if(window.parent === window.self){
        attributes.image = images[imageIndexRef.current];
        attributes.backgroundColor = true;
    }

    return (
        <css.background {...attributes} className={isIframe&&'isIframe'}>
            <css.stageContainer isDark={isDark}>
                {isIframe &&<css.close><Close onClick={()=>sendPostMessage('close')}/> </css.close>}
                <css.top className={isHeightHeader && 'height'} />
                <css.goAndProduce data-cy="goAndProduceBadge" className={isKeyboardOpen&&' keyboardOpen'}>{t('goProduce')}</css.goAndProduce>
                <css.childrenWrap className={isHeightHeader && 'height'} >
                    {children}
                </css.childrenWrap>
            </css.stageContainer>
            <TickchakContracts />
        </css.background>
    );
}

export default Stage;
