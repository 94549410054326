import styled, {keyframes} from 'styled-components';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../globalVariables/zIndex';

const loading = keyframes`
    from { transform: rotate(0deg) translateX(15px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(15px) rotate(-360deg); }
    
    from { -webkit-transform: rotate(0deg) translateX(15px) rotate(0deg); }
    to   { -webkit-transform: rotate(360deg) translateX(15px) rotate(-360deg); }
    
    from { -moz-transform: rotate(0deg) translateX(15px) rotate(0deg); }
    to   { -moz-transform: rotate(360deg) translateX(15px) rotate(-360deg); }
    
    from { -o-transform: rotate(0deg) translateX(15px) rotate(0deg); }
    to   { -o-transform: rotate(360deg) translateX(15px) rotate(-360deg); }
`;

export const Button = styled.div`  
    white-space: nowrap;
    position: relative;
    border: 1px solid  ${props => props.btnCss.borderColor?props.btnCss.borderColor:'#000'};
    color:${props => (props.btnCss.textColor?props.btnCss.textColor:'#484848')};
    background-color:${props => (props.btnCss.backgroundColor?props.btnCss.backgroundColor:'#76d6e8')};
    border: 1px solid ${props => (props.btnCss.borderColor?props.btnCss.borderColor:'#fff')};
    height: 30px;
    display: flex;
    padding: 0 10px;
    border-radius: 28px;
    line-height: 32px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    letter-spacing:${props => (props.btnCss.letterSpacing)};
    cursor:${props => (props.btnCss.disabled ? 'default' : 'pointer')};
    width: fit-content;
    ${props => (props.btnCss.width?'width:'+props.btnCss.width+'px':'')};
    ${props => (props.btnCss.height?'height:'+props.btnCss.height:'')};
    z-index:${zIndex.baseRaised};
    opacity: ${props => (props.btnCss.disabled ? '0.5' : '1')};  
      &.arrow{
        padding-${props => props.theme.rtl ? 'left' : 'right'}:30px;
    }
    transition: opacity 300ms;//all??
    &.disabled{
        opacity: 0.7;
        cursor: unset;
        pointer-events:none;
    }
    :hover{
        box-shadow: rgb(45 45 45 / 5%) 0 2px 2px, rgb(49 49 49 / 5%) 0 4px 4px, rgb(42 42 42 / 5%) 0 8px 8px, rgb(32 32 32 / 5%) 0 16px 16px, rgb(49 49 49 / 5%) 0 32px 32px, rgb(35 35 35 / 5%) 0 64px 64px;
        :not(.disabled){
        color:${props => (!props.btnCss.disabled &&(props.btnCss.hoverTextColor?props.btnCss.hoverTextColor:'#484848'))};
        background-color:${props => ((!props.btnCss.disabled) && (props.btnCss.hoverBackgroundColor?props.btnCss.hoverBackgroundColor:'#fff'))};
        border: ${props => (!props.btnCss.disabled && '1px solid ' + (props.btnCss.hoverBorderColor? props.btnCss.hoverBorderColor:'#76d6e8'))};
    }
}
    ::before{
        content: "";
        position: absolute;
        background-image:url("${props => symbol[props.btnCss.iconBtn]}");  
        background-repeat: no-repeat;
        height: 9px;
        width: 20px;
        background-position: 0 center;
        transform: rotate${props => props.theme.rtl ? '(0deg)' : '(180deg)'};
        ${props => props.theme.rtl ? 'left' : 'right'}:13px;
    }
   
    &.SUCCESS{
        font-size:0;
        background-repeat:no-repeat;  
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.09 31.47'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23${props => (props.btnCss.successColor ? props.btnCss.successColor : '484848')};stroke-miterlimit:10;stroke-width:8px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Ev%3C/title%3E%3Cline class='a' x1='1.49' y1='14.68' x2='15.23' y2='29.95'/%3E%3Cline class='a' x1='46.8' y1='1.53' x2='13.23' y2='29.95'/%3E%3C/svg%3E");
        background-size: 18px 18px;
        background-position:center; 
        pointer-events:none;
    } 
    &.LOADING{
        font-size:0;    
        border-radius:50%;
        width:36px !important;
        height:36px  !important;
        position:relative;
        ::before{
            position: unset;
        }
        ::after{
            content:'';
            width:7px;
            height:7px;
            border-radius:50%;
            background:#000;
            position:absolute;  
            top: calc(50% - 3.5px);
            right: calc(50% - 3.5px);
            -webkit-animation: ${loading} 1s linear  infinite;
            animation: ${loading} 1s linear  infinite;
            -moz-animation:  ${loading}  1s linear infinite; 
            -o-animation: ${loading} 1s linear infinite; 

        };
        :hover{
            background-color:${props => (props.btnCss.backgroundColor)};
        }
    }
    &.FAILED{
        background-color: #dd2f21 ;
        font-size:0;  
        background-repeat:no-repeat;  
        background-image: url("${symbol.error}");
        background-position:center; 
        background-size: 18px 18px;
        pointer-events:none;
        border: 1px solid #dd2f21;
    }
`;

