import styled from 'styled-components/macro';

export const explainText = styled.div`
    position:relative;
    padding-bottom: 12px;
    ::after{
        content:'${props=>props.explain}';
        position: absolute;
        font-size: 12px;
        bottom: 5px;
        width: 100%;
        height: 13px;
        right: 0;
        font-weight:400;
    }
    &.mobileHide{
        @media screen and (max-width:1000px){
            display:none;
        }
        
    }
    &.white{
        color:#fff;
    }
`;