function getUnequalFields(sourceObject, newObject) {
    let result = {};
    for(const key in newObject) {
        if(!sourceObject?.hasOwnProperty(key)) {
            result[key] = newObject[key];
            continue;
        }
        if(Array.isArray(newObject[key])) {
            if(sourceObject[key]?.length !== newObject[key]?.length) {
                result[key] = newObject[key];
                continue;
            }
            for(let i = 0; i < newObject[key]?.length; i++) {
                if(
                    typeof sourceObject[key][i] === 'object' &&
            typeof newObject[key][i] === 'object'
                ) {
                    let diff = getUnequalFields(sourceObject[key][i], newObject[key][i]);
                    if(Object.keys(diff).length > 0) {
                        result[key] = newObject[key];
                        break;
                    }
                } else if(sourceObject[key][i] !== newObject[key][i]) {
                    result[key] = newObject[key];
                    break;
                }
            }
        } else if(typeof newObject[key] === 'object' && newObject[key] !== null) {
            let diff = getUnequalFields(sourceObject[key], newObject[key]);
            if(Object.keys(diff).length > 0) {
                result[key] = newObject[key];
            }
        } else if(sourceObject[key] !== newObject[key]) {
            result[key] = newObject[key];
        }
    }
    return result;

}
export default getUnequalFields;