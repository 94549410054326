import styled from 'styled-components/macro';

export const detail = styled.div`
    text-align: center;
    font-weight: 600;
    span{
        margin-inline-start: 2px;
        font-size: 11px;
        font-weight: normal;
    }
    &.click{
        cursor: pointer;
    }
    &.numberRange {
        ::before {
            content: "-";
            padding-inline-end: 2px;
        }
    }
    &.unlimited{
        font-size: 12px;
    }
`;
export const text = styled.div`
font-size: 13px;
font-weight: normal;


`;
