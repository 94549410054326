import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import MobilePopupModal from '../../../component/newPopup/mobilePopup/MobilePopupModal';
import {tsGetDateAndMonth} from '../../../library/dates';
import {getParticipantReportOfCourse} from '../../../redux/attendance/attendanceActions';
import ExportExcel from '../exportExcel/ExportExcel';
import ParticipantReportItem from '../participantReportItem/ParticipantReportItem';
import * as css from './ParticipantReportPop.css';
import CircleImageText from '../../../component/circleImageText/CircleImageText';

function ParticipantReportPop({openReport}) {

    const {currentParticipant,currentTid,ticket} = useSelector(s => ({
        currentParticipant:s.attendance.currentParticipant,
        currentTid:s.attendance.currentTid,
        ticket:s.attendance.tickets.find(t=>t.tid===s.attendance.currentTid)}));
    const title=useSelector(s=>s.tickets.tickets.find(t=>t.tid===currentTid)?.title);
    const participant = useSelector(s=>(s.attendance.tickets.find(t => t.tid === s.attendance.currentTid)?.participants?.find(p => p.qid === currentParticipant.qid)));
    const {t} = useTranslation('attendance');
    const ticketT = useTranslation('ticket');

    useEffect(() => {
        const fetchData = async () => {
            await getParticipantReportOfCourse(currentTid, currentParticipant.qid);
        };
        fetchData()
            .catch(console.error);
    }, []);

    const getMeetingDate =(tdid)=> ticket?.meetings?.find(m => m.tdid === tdid)?.date;
    let attendances =['',t('present'),t('late'),t('missing')];
    const style={font:{bold:true}};
    const meetings=participant._meetings.map(a=>[tsGetDateAndMonth(getMeetingDate(a.tdid)),attendances[a.present]]);
    const sheets=[{
        name:'attendance',
        data:[
            [`${t('ParticipantName')}:`,{value:participant.firstName+' '+participant.lastName,style},'','',t('meetingsSum'),participant._meetings.length],
            [`${ticketT.t('course')}:`,{value:title,style}],
            [],
            [t('date'),t('attendance')],
            ...meetings,
            ['',{value:t('attendanceSum'),style}],
            ['',meetings.filter(m=>m[1]===t('present')).length],
            ['',{value:t('lateSum'),style}],
            ['',meetings.filter(m=>m[1]===t('late')).length],
            ['',{value:t('missingSum'),style}],
            ['',meetings.filter(m=>m[1]===t('missing')).length]
        ],
    }
    ];

    return (
        <MobilePopupModal {...openReport}>
            <>
                <ExportExcel  
                    headerLine={3}
                    sheets={sheets}
                    fileName={`${t('participantReport') } ${participant.firstName} ${participant.lastName}`}/>
                <CircleImageText text={<css.wrapTitle><css.title>{t('participantReport')}&nbsp;</css.title><div>{participant.firstName+' '+participant.lastName}</div></css.wrapTitle>} image={currentParticipant.face}/>

            </>
            <css.wrapAll>

                <css.wrapAttendanceItem>
                    {participant._meetings?.map((item) => 
                        <ParticipantReportItem _meeting={item} key={item.tdid} meetingDate={getMeetingDate(item.tdid)}/>
                    )}
                </css.wrapAttendanceItem>
            </css.wrapAll>
        </MobilePopupModal>
    );
}

export default ParticipantReportPop;
