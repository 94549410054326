import changeAccount from '../../functions/changeAccount';
import request from '../../library/request';
import store from '../../store';
import setGlobalState, {pushToGlobalState, unsetGlobalState} from '../setGlobalState';
import securityRequest from '../../functions/securityRequest/securityRequest';
import activatingPermissionRequest from '../../functions/securityRequest/activatingPermissionRequest';
import {updateLocalStorage, uploadFile} from '../user/userActions';
import highSecurityRequest from '../../functions/securityRequest/highSecurityRequest';
import {initPhoneNumber} from '../../functions/intrernationalPhoneNumber';

const getPid = () => store.getState().account.pid;

const budgetItems = () => store.getState().account.budgetItems;
//getPackage

export const getAccount = async (pid) => {
    try {
        const res = await request('account.getAccount', 'get', `/package/${pid}`);
        if(res?.package) {
            const account = JSON.stringify(res.package);
            localStorage.setItem('account', account);
            // setGlobalState("account", res.package)
            res.package.getAccountXHR='SUCCESS';
        }
        changeAccount(res?.package);
        if(res.packageOwner){
            if(res.packageOwner.email)
                setGlobalState('account.ownerEmail', res.packageOwner.email);
            setGlobalState('account.ownerEmailVerified', res.packageOwner.ownerEmailVerified);
        }
        return res;
    }
    catch (e) {
        changeAccount({getAccountXHR:'FAILED'});
    }
};

// //getMailingCount
// export const getMailingCount = async () => {
//     const res = await request("account.getMailingCount", "get", `/package/${pid}/mailing`)
//     if (res)
//     setGlobalState("account", res.packageInfo)
// }

//getPackageSettings
export const getAccountSettings = async () => {
    let res = await request('account.getAccountSettings', 'get', `/package/${getPid()}/settings`);
    if(res) {
        setGlobalState('account', {...store.getState().account,...res});
    }
    return true;
};

//updatePackageSettings
export const updateAccount = async (name, value) => {
    await request('account.updateAccount', 'put', `/package/${getPid()}`, {[name]: value});
    setGlobalState(`account.${name}`, value);
    localStorage.setItem('account', JSON.stringify({...store.getState().account,[name]:value}));
    // setGlobalState(`account.getAccountSettingsXHR`,"get")

};

export const updateAccountBill = async (name, value) => {
    request('account.updateAccountBill', 'put', `/package/${getPid()}/bill`, {[name]: value});
    setGlobalState(`account.billPackage.${name}`, value);
};

//getPackageUserPermission
export const getAccountUserPermissions = async () => {
    let permissionsFromLocalStorage = (localStorage.getItem('userAccountPermissions_' + getPid()));
    if(!permissionsFromLocalStorage || permissionsFromLocalStorage === 'undefined') {
        const res = await request('account.getAccountUserPermissions', 'get', `/package/${getPid()}/permission`);
        if(res.permissions) {
            const permissions = JSON.stringify(res.permissions);
            localStorage.setItem('userAccountPermissions_' + getPid(), permissions);
            setGlobalState('account.userPermissions',res.permissions);
        }
    }
    else  setGlobalState('account.userPermissions',JSON.parse(permissionsFromLocalStorage));
};

//  getPackagePermission
export const getAccountPermissions = async () => {
    const res = await request('account.getAccountPermissions', 'get', `/package/${getPid()}/packagePermission`);
    if(res.permissions) {
        setGlobalState('account.permissions', res.permissions);
        let account = JSON.parse(localStorage.getItem('account')) || {};
        account.permissions = res.permissions;
        localStorage.setItem('account', JSON.stringify(account));
    }
};

//updatePackagePermissions
export const updateAccountPermission = async (permission, status, index) => {
    setGlobalState('ui.account.updatePermissionIndex', index);
    const requestFunction = status ?activatingPermissionRequest : securityRequest;
    const res=await requestFunction(permission,'manage event','account.updateAccountPermission', 'put', `/package/${getPid()}/packagePermission`,
        {packagePermission: permission, packagePermissionStatus: status});
    if(res.eventsUsage){
        return res;
    }
    let newPermissions = [...(store.getState().account?.permissions || [])];
    newPermissions[index] = {...newPermissions[index], status};
    if(permission==='course'&&status){
        const billingIndex=newPermissions.findIndex(p=>p.permission==='billing');
        newPermissions[billingIndex]={...newPermissions[billingIndex],status:1};
        const suspendedIndex= newPermissions.findIndex(p=>p.permission==='suspended');
        newPermissions[suspendedIndex]={...newPermissions[suspendedIndex],status:1};
    }
    if(permission==='user cancel ticket' && status === 0){
        await requestFunction(permission,'manage event','account.updateAccountPermission', 'put', `/package/${getPid()}/packagePermission`,
            {packagePermission: 'default user cancel ticket', packagePermissionStatus: 0});
        const defaultIndex=newPermissions.findIndex(p=>p.permission==='default user cancel ticket');
        newPermissions[defaultIndex]={...newPermissions[defaultIndex],status:0};
    }
    setGlobalState('account.permissions', newPermissions);
};

//getMailingCount
export const getMailingCount = async () => {
    const res = await request('account.getMailingCount', 'get', `/package/${getPid()}/mailing`);
    setGlobalState('account.mailingPackage', res);
};

export const getPackageProducer = async () => {
    const res = await request('account.getPackageProducer', 'get', `/package/${getPid()}/producer`);
    const res2 = convertPhoneNumber(res);
    setGlobalState('account.packagesProducer', res2);
};

export const updatePackageProducer = async (fields, callBack) => {
    const account = store.getState().account;
    // const role = account.userAccountRole;
    // if (role === "owner" || role === "manager") {
    const res = await request('account.updatePackageProducer', 'put', `/package/${getPid()}/producer`, fields);
    if(callBack && (res.updateFields?.branding == 2))
        callBack();
    const updateFields = convertPhoneNumber(res.updateFields);
    setGlobalState('account.packagesProducer', {...account.packagesProducer, ...updateFields});
    // }
};

export const getPackageBankDetails = async () => {
    const res = await request('account.getPackageBankDetails', 'get', `/package/${getPid()}/bank`);
    setGlobalState('account.packagesBankDetails', res);
};

//sendPackageVerification
export const sendPackageVerification = async () => {
    await request('account.sendPackageVerification', 'post', `/package/${getPid()}/sendPackageVerification`);
};

//getPackageBank
export const getPackageBank = async () => {
    if(!store.getState().account.getPackageBankXHR) {
        const res = await request('account.getPackageBank', 'get', `/package/${getPid()}/bank`);
        setGlobalState('account.bank', res.bankInfo);
    }
};
const uploadBankVerificationFile = async (file)=>{
    if(file?.base64){
        file.base64 = await uploadFile(file.base64, '', 'bankVerify', getPid());
        file = JSON.stringify(file);
    }
    return file;
};
//updatePackageBank
export const updatePackageVerification = async (fields) => {
    fields.bankVerificationFile = await uploadBankVerificationFile(fields.bankVerificationFile);
    const  res = await request('account.updatePackageVerification', 'put', `/package/${getPid()}/packageVerification`, {fields});
    setGlobalState('account.bank', {...store.getState().account.bank, ...res});
};

export const updatePackageBank = async (fields, callBack) => {
    fields.bankVerificationFile = await uploadBankVerificationFile(fields.bankVerificationFile);
    const res = await request('account.updatePackageBank', 'put', `/package/${getPid()}/bank`, {fields});
    setGlobalState('account.bank', {...store.getState().account.bank, ...res.updateFields});
    if(callBack && (store.getState().account.bankVerified == 1 && res.updateFields.bankVerified == 0))
        callBack();
    setGlobalState('account.bankVerified', res.updateFields?.bankVerified);
};

export const addPackageBank = async (fields) => {
    fields.bankVerificationFile = await uploadBankVerificationFile(fields.bankVerificationFile);
    const res = await request('account.addPackageBank', 'post', `/package/${getPid()}/bank`, fields);
    setGlobalState('account.bank', res.insertValues);
};

export const brandingVerification = async (fields) => {
    const res = await request('account.brandingVerification', 'post', `/package/${getPid()}/brandingVerify`, fields);
    const updateFields = convertPhoneNumber(res.updateFields);
    setGlobalState('account.packagesProducer', {...store.getState().account.packagesProducer, ...updateFields});
};
export const getBudgetItems = async () => {
    const pid = store.getState().event.pid||getPid();
    const res = await request('account.getBudgetItems', 'get', `/package/${pid}/budgetItems`);
    setGlobalState('account.budgetItems', res.budgetItems);
};

export const updateBudgetItem = async (index, fields) => {
    let budgetIndexFound;
    const res = await request('account.updateBudgetItem', 'put', `/package/${getPid()}/budgetItem/${fields.budgetIndex}`, fields);
    if(fields.values?.budgetDefault) {
        budgetIndexFound = budgetItems().findIndex(item => item.budgetDefault == true);
        setGlobalState(`account.budgetItems[${budgetIndexFound}]`, {...budgetItems()[budgetIndexFound], ...{budgetDefault: 0}});
    }
    setGlobalState(`account.budgetItems[${index}]`, {...budgetItems()[index], ...res?.updateFields});
};

export const addBudgetItem = async (fields) => {
    let budgetIndexFound;
    await request('account.addBudgetItem', 'post', `/package/${getPid()}/budgetItem`, fields);
    fields.budgetStatus = 1;
    if(fields.budgetDefault) {
        budgetIndexFound = budgetItems().findIndex(item => item.budgetDefault == true);
        setGlobalState(`account.budgetItems[${budgetIndexFound}]`, {...budgetItems()[budgetIndexFound], ...{budgetDefault: 0}});
    }
    pushToGlobalState('account.budgetItems', fields);
};

export const deleteBudgetItem = async (index, budgetIndex, countEvents) => {
    let budgetIndexFound;
    if(countEvents) {
        budgetIndexFound = budgetItems().findIndex(item => item.budgetDefault == true);
        setGlobalState(`account.budgetItems[${budgetIndexFound}].countEvents`, budgetItems()[budgetIndexFound].countEvents + countEvents);
    }
    await request('account.deleteBudgetItem', 'delete', `/package/${getPid()}/budgetItem/${budgetIndex}`);
    unsetGlobalState(`account.budgetItems[${index}]`);
};

export const createEvent = async (eventType) => {
    const res = await request('account.createEvent', 'post', `/package/${getPid()}/createEvent`, {eventType});
    setGlobalState('account.createEvent', res);
};
export const getAccountSuppliers = async () => {
    const res = await request('account.getAccountSuppliers', 'get', `/package/${getPid()}/packageSuppliers`);
    setGlobalState('account.packageSuppliers', res.suppliersInfo);
};

export const addAccountSuppliers = async (fields) => {
    const res = await request('account.addAccountSuppliers', 'post', `/package/${getPid()}/packageSuppliers`, fields);
    setGlobalState('account.packageSuppliers', [...store.getState().account.packageSuppliers, res]);
};

export const updateAccountSuppliers = async (fields) => {
    const res = await request('account.updateAccountSuppliers', 'put', `/package/${getPid()}/packageSuppliers`, fields);
    const index = store.getState().account.packageSuppliers.findIndex(s => s.srid === fields.srid);
    const value = store.getState().account.packageSuppliers[index];
    setGlobalState(`account.packageSuppliers[${index}]`, {...value, ...res.updateFields});
};

export const deleteAccountSupplier = async (srid) => {
    await request('account.deleteAccountSupplier', 'delete', `/package/${getPid()}/packageSuppliers/${srid}`);
    const index = store.getState().account.packageSuppliers.findIndex(s => s.srid === srid);
    unsetGlobalState(`account.packageSuppliers[${index}]`, null);
};

export const updateVerifiedEmail = async (fields) => {
    const res = await request('account.updateVerifiedEmail', 'put', `/package/${getPid()}/emailVerification`, fields);
    setGlobalState('account.ownerEmailVerified', res.update);
    if(store.getState().account.ownerEmail.toLowerCase().trim() === store.getState().user.email.toLowerCase().trim())
    {
        setGlobalState('user.ownerEmailVerified', res.update);
        updateLocalStorage('user',{ownerEmailVerified:res.update});

    }

};

export const emailVerification = async (fields) => {
    const res = await request('account.emailVerification', 'post', `/package/${getPid()}/sendEmailVerification`, fields);
    setGlobalState('account.ownerEmailVerified', res.updateFields.ownerEmailVerified);
    if(store.getState().account.ownerEmail.toLowerCase().trim()===store.getState().user.email.toLowerCase().trim()){
        setGlobalState('user.ownerEmailVerified', res.updateFields.ownerEmailVerified);
        updateLocalStorage('user',{ownerEmailVerified:res.updateFields.ownerEmailVerified});

    }
};

export const sendSmsVerificationBank = async ()=>{

    const res =   await request('account.sendSmsVerification', 'post', `/package/${getPid()}/verificationBank`);
    setGlobalState('account.securityBankToken',res.securityToken);

};
export const updateVerificationBank = async (code)=>{
    await request('account.updateVerificationBank', 'put', `/package/${getPid()}/verificationBank`, {code:code,securityToken:store.getState().account.securityBankToken});
    setGlobalState('account.bankVerified',1);

};

export const createEasyCourse = async ()=>{
    const args = ['account.createEasyCourse', 'post', `/package/${getPid()}/easyCourse`];
    const res=await highSecurityRequest('billing','manage event', ...args);
    return res.newEvent?.eid;
    // setGlobalState('event', {...res.newEvent,eid:store.getState().event.eid,pid:store.getState().event.pid,});

};

const convertPhoneNumber = (res) => {
    const packagesProducer = store.getState().account.packagesProducer;
    const phoneObj = initPhoneNumber({internationalNumber: res?.phone});
    const contactPhoneObj = initPhoneNumber({internationalNumber: res?.contactPhone});
    res.countryCode = phoneObj.country;
    res.phone = phoneObj.nationalNumber||packagesProducer.phone;
    res.contactCountryCode = contactPhoneObj.country;
    res.contactPhone = contactPhoneObj.nationalNumber||packagesProducer?.contactPhone;
    return res;
};