import * as css from '../packageAccordion.css';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Input from '../../../../../component/input/Input';
import WrapRadioBtn from '../../../../../component/wrapRadioBtn/WrapRadioBtn';
import {Else, If, Then} from 'react-if';
import PhoneInput from '../../../../../component/input/phoneInput/PhoneInput';

function CompanyDetails({setIsBusiness, isBusiness}) {

    const {t} = useTranslation('package');

    const account = useSelector((state) => state.account);
    const user = useSelector((state) => state.user);
    const packagesProducer = account?.packagesProducer;
    const packageVerified = account?.packageVerified;
    
    const notChangeBillingId = account.hasMoneyTransaction && ![7, 2069, 7423].includes(user?.uid);

    useEffect(() => {
        setIsBusiness(packagesProducer?.isBusiness);
    }, [packagesProducer]);

    return (<>
        <css.bottomTitle>{t('producerDetails')}</css.bottomTitle>
        <css.title>{t('fillCompanyDetails')}</css.title>
        <css.wrapRB>
            <WrapRadioBtn text={'business'} onClick={() => setIsBusiness(1)} selected={isBusiness} name={'isBusiness'} />
            <WrapRadioBtn text={'privatePerson'} onClick={() => setIsBusiness(0)} selected={!isBusiness} name={'isBusiness'} />
        </css.wrapRB>
        <css.wrapInputs>
            <If condition={isBusiness}>
                <Then>
                    <Input name={'billingName'} text={t('companyName')} description={t('companyNameDesc')}  />
                    <Input name={'billingId'} text={t('companyId')} description={t('companyIdDesc')}
                        className={(packageVerified && account.bankVerified) && ('verified' + ' ' + (notChangeBillingId && ' notChange'))}  />
                </Then>
                <Else>
                    <Input name={'billingName'} text={t('name')} description={t('invoice')} />
                    <Input name={'billingId'} text={t('ID')} description={t('')}
                        className={(packageVerified && account.bankVerified) && ('verified' + ' ' + (notChangeBillingId && ' notChange'))} />
                </Else>
            </If>
            <PhoneInput countryFieldName='countryCode' name={'phone'} text={t('phone')} description={t('companyPhone')} />
            <Input name={'email'} text={t('E-mail')} description={t('companyEmail')}  />
        </css.wrapInputs>
        <css.bottomTitle>{t('companyAddress')}</css.bottomTitle>
        <css.wrapInputs>
            <Input name={'address'} text={t('companyAddress')}  />
            <Input name={'city'} text={t('companyCity')}  />
        </css.wrapInputs>
    </>
    );
}
export default CompanyDetails;

