import styled from 'styled-components/macro';
import {mobileMedia, searchResult} from '../../../src/globalVariables/GlobalVariables';

export const item=styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        width: 100%;
        padding: 10px;
        position: relative;
        margin:1px 0 ;
        ${mobileMedia}{
         width: 100%;
         padding-right: 20px;
         padding-left: 20px;
        }
        ${searchResult()  }

    `;

export const right=styled.div`
        display: block;
        font-size: 10px;
        font-weight: 500;
`;
export const name=styled.div`
        font-size: 14px;
        font-weight: 600;

`;

