import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import InputList from '../../../component/input/inputList/InputList';
import {getCategories} from '../../../redux/categories/categoriesActions';
import getSpecialTranslation from '../../../functions/getSpecialTranslation';

function CategoryList() {
    const {t} = useTranslation('layout');
    const categories = useSelector((state) => state.categories.eventCategories);

    useEffect(() => {
        const getData = async () => {
            await getCategories();
        };
        if(!categories.length)
            getData();
    }, []);

    const getCategoryList = () => {
        if(!categories.length)
            return [];
        let categoriesList = categories?.map(c=> ({key: c.catId, text: getSpecialTranslation(`category${c.catId}`,t)||c.title}));
        categoriesList.unshift({key: 0, text: t('category0')});
        return categoriesList;
    };

    return (
        <InputList name='category' icon={'category'}  text={t('category')} list={getCategoryList()} />
    );
}

export default CategoryList;