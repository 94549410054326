import request from '../../library/request';
import setGlobalState from '../setGlobalState';
import store from '../../store';
const getEid = () => store.getState().event.eid;

export const getLanguages = async () => {
    const res = await request('languages.getLanguages', 'get', `/event/${getEid()}/languages`);
    setGlobalState('languages.languages', res['languages']);
};

export const getAllLanguages = async () => {
    const res = await request('languages.getAllLanguages', 'get', '/languages');
    setGlobalState('languages.allLanguages', res.languages);
};

