import {useFormikContext} from 'formik';
import {useEffect} from 'react';
import InputTimeStartEnd from '../inputTimeStartEnd.js/InputTimeStartEnd';
import InputAmount from '../inputAmount/InputAmount';

function InputTimeAndDuration({inputTimeProps, inputAmountProps}) {

    const form = useFormikContext();
    const {start, duration, end} = form.values;

    function setEndByStartAndDuration() {
        if(!duration || !parseInt(duration))
            return;
        const endDate = new Date(start);
        endDate.setMinutes(start.getMinutes() + parseInt(duration));
        form.setValues({...form.values, end: endDate});
    }

    useEffect(() => {
        if(!form.touched.hasOwnProperty('duration'))
            return;
        setEndByStartAndDuration();
        if(form.touched.end) {
            const obj = {...form.touched};
            delete obj.end;
            form.setTouched(obj);
        }
    }, [duration]);

    const setDurationByStartEnd = () => {
        if(!start || !end)
            return;
        if(form.touched.duration) {
            const obj = {...form.touched};
            delete obj.duration;
            form.setTouched(obj);
        }
        let diff = (end.getTime() - start.getTime()) / 1000;
        diff /= 60;
        diff = Math.abs(Math.round(diff));
        form.setValues({...form.values, duration: diff});
    };

    useEffect(() => {
        if(!form.touched.start)
            return;
        setEndByStartAndDuration();
    }, [start]);

    useEffect(() => {
        if(form.touched.end)
            setDurationByStartEnd();
    }, [end]);

    return (<>
        <InputAmount {...inputAmountProps} />
        <InputTimeStartEnd {...inputTimeProps} />
    </>
    );
}

export default InputTimeAndDuration;
