import React from 'react';
import PixelBox from '../pixelBox/PixelBox';
import FormContext from '../../../components/formContext/FormContext';
import * as Yup from 'yup';
import {Form} from 'formik';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import setGlobalState from '../../../../redux/setGlobalState';
import {addSpecialPixel, updateSpecialPixel} from '../../../../redux/publish/publishActions';
import {useState} from 'react';

function WrapPixelBox({pixel}) {
    const newPixel=useSelector(s=>s.ui.pixel.newPixel||{});
    const isCreated=newPixel?.key&&(newPixel.key===pixel.key && newPixel.status==='created');
    const [isEditMode,setIsEditMode] = useState(isCreated);

    useEffect(()=>{
        if(isCreated){
            setImmediate(() => {setIsEditMode(false); });
            setGlobalState('ui.pixel.newPixel',{});
        }
        else if(newPixel?.key&&(newPixel?.key===pixel.key))
            setIsEditMode(true);     
    },[]);

    const onSave = (_changesValues,values)=>{
        // if(!Object.keys(values).length)
        //     return;
        values.type=pixel.title;
        if(pixel?.pixelId)
            updateSpecialPixel(pixel.pixelId,values).then(()=>{
                setIsEditMode(false);
            });
        else {
            addSpecialPixel({...values,key:pixel.key}).then();
        }
    };

    return (
        <FormContext 
            initialValues={{id:pixel?.id||''}}
            validationSchema={Yup.object().shape({id: Yup.string().required().matches(/^[a-zA-Z0-9-]{1,25}$/)})}
            onSubmit={onSave}
        >{({submitForm})=>
                <Form>
                    <PixelBox pixel={pixel}
                        submitForm={submitForm}
                        isEditMode={isEditMode} setIsEditMode={setIsEditMode}
                    />
                </Form>
            }
        </FormContext>      
    );
}
export default WrapPixelBox;
