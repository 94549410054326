import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {sendMailing} from '../../../redux/mailing/mailingActions';
import replaceParameters from '../replaceParameters';
import Button from '../../button/button/Button';
import validateText from '../validateText';
import {useTranslation} from 'react-i18next';

function SendBtn(props) {
    const {mailOrSms, pointerEvents, subject, content, area, crowd, spansArray, crowdField, handlePopOnPop, sendBtnDisabled, recipientsMails, recipientsSms} = props;
    const mailing = useSelector(s => s.mailing);
    const event=useSelector(s => s.event);

    const {t} = useTranslation('mailing');

    const [{sendBtnText, sendBtnClass}, setSendBtn] = useState({sendBtnText: t('send'), sendBtnClass: 'default', sendDisabled: false});

    const [realSend, setRealSend] = useState(0);

    const changeSendBtn = (text, classname) => {
        setSendBtn({sendBtnText: text, sendBtnClass: classname});
    };

    const handleRealSending = async (e) => {
        if(sendBtnDisabled) {
            handlePopOnPop('show', 0, 1);
            return;
        }
        if(!validateText(content, subject, mailOrSms, t('fillSubject'), t('fillContent')))
            return;
        setRealSend(1);
        sendBtnClass == 'default' && changeSendBtn('', 'process ');
        setPointerEventsBody('disabled');
        const newText = replaceParameters(content, subject, area, mailOrSms, spansArray, crowdField);
        const recipientArr = mailOrSms == 'sms' ? recipientsSms : recipientsMails;
        if(area == 'crowd')
            await sendMailing({pid: 447, source: area, crid: crowd, bodyMessage: newText.newContent, subject: newText.newSubject ? newText.newSubject : ' ', messageType: mailOrSms, recipient: recipientArr});
        //sendMailing({ pid:props.user.package.pid, source: area, crid: crowd, bodyMessage: newText.newContent, subject: newText.newSubject?newText.newSubject:" ", messageType: mailOrSms, recipient: recipientArr})
        else
            await sendMailing({eid:event.eid, source: area, bodyMessage: newText.newContent, subject: newText.newSubject, messageType: mailOrSms});
    };

    const sendBtnProps = {
        text: sendBtnText,
        textColor: '#484848',
        backgroundColor: '#76d6e8',
        borderColor: '#76d6e8',
        hoverTextColor: '#484848',
        hoverBackgroundColor: '#fff',
        hoverBorderColor: '#76d6e8',
        disabled: sendBtnDisabled,
        class: sendBtnClass,
        onClick: (e) => { handleRealSending(e); },
    };

    const setPointerEventsBody = (e) => {
        pointerEvents();
    };

    useEffect(() => {
        if(!realSend)
            return;
        if(mailing.sendMailingXHR == 'SUCCESS')
            changeSendBtn('', 'success ');
        else if(mailing.sendMailingXHR == 'FAILED')
            changeSendBtn('', 'error ');
        setPointerEventsBody('');
        setTimeout(function () {
            changeSendBtn(t('send'), 'default ');
            setRealSend(0);
        }, 3000);
    }, [mailing.sendMailingXHR]);

    return (
        <Button BtnProps={sendBtnProps} />
    );
}

export default SendBtn;
