import styled from 'styled-components/macro';
import {wrapAll} from '../../../../../component/anotherOneCircle/AnotherOneCircle.css';
import {scrollbar} from '../../../../../globalVariables/GlobalVariables';

export const guidesList = styled.div`
    height:100% ;
    width:100% ;
`;

export const text = styled.div`
    height: 100px;
    padding-inline-start: 20px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 15px;
`;

export const guides = styled.div`
    max-height:calc(100% - 100px) ;
    padding: 10px 35px;
    overflow: hidden;
    display:grid;
    grid-template-columns: repeat(2 , 1fr);
    justify-items: center;
    ${scrollbar}
    ${wrapAll}{
        margin-top: 5px;
    }
    > div:nth-child(${p=>p.membersLength+1}){
        order: -1;
    }
`;
