import styled from 'styled-components/macro';
import {image, icon, right} from '../../../../../tickets/components/ticketRow/rightPart/RightPart.css';
import {placeForDrag} from '../../../../../../component/newTable/newRow/Row.css';
import {SHADOW_COLOR, VERY_LIGHT_GRAY, WHITE, ellipsisLines, mobileMedia, scrollbar} from '../../../../../../globalVariables/GlobalVariables';
import {part} from '../../../../../tickets/components/ticketRow/part/Part.css';
import zIndex from '../../../../../../globalVariables/zIndex';

export const firstPart = styled.div`
    display:flex;
    cursor: pointer;
    height: 100%;
    border-inline-end: 0.5px solid ${VERY_LIGHT_GRAY};
`;
export const placeDrag = styled(placeForDrag)`

`;
export const ticketIcon = styled(icon)`
    background-image: none;
    width: 50px;
    border-radius: 0px;
    ${image}{
      display: none;
    }
`;
export const container = styled.div`
    position: absolute;
    top: 50px;
    background-color: ${WHITE};
    width: 100%;
    height: max-content;
    border-radius: 5px;
    box-shadow: 0px 3px 6px ${SHADOW_COLOR};
    z-index: ${zIndex.baseHigh};
    ${part}{
      height: 100%;
      border-inline-end: 0.5px solid ${VERY_LIGHT_GRAY};
      &:last-child {
        border-inline-end: none;
      }
    }
    ${mobileMedia}{
      inset-block-start: 10px;
      width: 100%;
    }
    ${ticketIcon}{
      &:last-child {
        border-radius: 5px;
      }
    }
    ${scrollbar}
`;
export const priceWrap = styled.div`
    display: flex;
    height: 60px;
`;

export const ticketRight = styled(right)`
    width: 100%;
    padding-inline-end:0px;
`;
export const header = styled.div`
`;
export const price = styled.div`

`;
export const boldText = styled.div``;
export const text = styled.div``;

export const ticketTitle = styled.div`
    ${ellipsisLines(2)}
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0;
`;
