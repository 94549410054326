import styled from 'styled-components/macro';
import * as disabledInput from '../disabledInput/DisabledInput.css';
import zIndex from '../../../globalVariables/zIndex';

export const container=styled.div`
    position:relative ;
`;
export const modeBtn=styled.div`
    position:absolute ;
    inset-inline-end:0;
    top:13px;
    z-index:${zIndex.baseHighest};
`;

export const disabled=styled.div`
        font-weight:600 ;  
        &.touched{
        ${disabledInput.container}{
            ::before{
                transform: scale(1);
            }
        }}
`;
