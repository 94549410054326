import * as css from './BlockPage.css';
import {useTranslation} from 'react-i18next';
import Btn from '../../../component/button/btn/Btn';
import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {shareLink} from '../../../functions/share';

function BlockPage({setBlockPage}) {
    const {t} = useTranslation('layout');
    const styleCancel = 'background-color: #7c7c7c; border-color: #5E5C5C; color:#FFFFFF;';
    const styleLink = 'background-color: #C5C5C5; border-color: #5E5C5C;';
    const event = useSelector((s) => s.event);
    const location = useLocation();
    const page = location.pathname.split('/').pop();
    const history = useHistory();
    const close=()=>{
        setBlockPage(false);
    };
    const closeAndSendLink=async ()=>{
        setBlockPage(false);
        await shareLink('Tickchak','',location.pathname);
        history.push(`/e/${event.eid}/realtime`);
    };

    return (
        <css.container  >
            <css.back onClick={()=>history.push(`/e/${event.eid}/realtime`)}/>
            <css.title>{t('workingOnIt')}</css.title>
            <css.text >{t('developmentDelayed')}</css.text>
            <css.actions>
                {page&&page!=='design'&&<Btn  style={styleCancel} onClick={close}>{t('continueAnyway')}</Btn>}
                <Btn  style={styleLink} onClick={closeAndSendLink}>{page&&page==='design'?t('closeSendLink'):t('sendLink')}</Btn>
            </css.actions>
        </css.container>
    );

}
export default BlockPage;
