import * as css from './MemberBigLoader.css';
import React from 'react';

function MemberBigLoader({type}) {

    return (
        <css.container className={type}>
            <css.top>
                <css.topDetails>
                    <css.optionsIcon/>
                    <css.modeBtn />
                </css.topDetails>
            </css.top>
            <css.image />
            <css.details/>
            <css.details />
            <css.whatsUp/>
            <css.footer/>
        </css.container>
    );
}

export default MemberBigLoader;
