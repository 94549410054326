const updateArrayAfterDrag = (drag, sourceArr) => {
    if(!drag.destination)
        return sourceArr;
    const source = drag.source.index;
    const destination = drag.destination.index;
    let newArr = [...sourceArr];
    newArr.splice(source, 1);
    newArr.splice(destination, 0, sourceArr[source]);
    return newArr;

};
export default  updateArrayAfterDrag;