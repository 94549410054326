import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {getAccountSettings, updateAccount, updateAccountBill} from '../../../redux/account/accountActions';

function useAccountSettings() {
    const account = useSelector(state => state.account);
    const [currentUpdatedField, setCurrentUpdatedField] = useState(null);
    const [changedFields, setChangedFields] = useState({});
    const timer = useRef(null);
    const {isMasterState} = useSelector(state => state.ui);

    const getClassName = (name) => `${currentUpdatedField===name?account.updateAccountXHR:changedFields[name]} ${isMasterState && 'master'}`;
    const getBillClassName = (name) => `${currentUpdatedField===name?account.updateAccountBillXHR:changedFields[name]} ${isMasterState && 'master'}`;

    useEffect(() => {
        if(account.pid && !account.supplier) {
            (async () => await getAccountSettings())();
        }
    }, [account.pid]);

    useEffect(() => () => clearTimeout(timer.current), []);

    const update = async (name, value,isBill=false) => {
        setCurrentUpdatedField(name);
        if(isBill){
            if(account.billPackage[name] != value)
                await updateAccountBill(name, value);}
        else if(account[name] !== value) {
            await updateAccount(name, value);
        }
        clearTimeout(timer.current);
        setChangedFields(prev=>({...prev,[name]:'SUCCESS'}));
        timer.current=setTimeout(setCurrentUpdatedField, 1000);
    };

    return {account, update, getBillClassName, getClassName, isMasterState, currentUpdatedField, changedFields};
}

export default useAccountSettings;
