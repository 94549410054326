import React from 'react';
import './TableStatus.css';
import t from '../../../TranslateHe';

const RtlCss = React.lazy(() => import('./TableStatus-rtl'));

function TableStatus(props) {

    let status = props.status;
    status = status === 'SENT' ? 1 : status;
    const text = {'PENDING': 5, 'SENT': 1, 'ERROR': 2,};
    status = text[status] ? text[status] : status;
    const statusText = {
        0: t('New'),
        1: t('Success'),
        2: t('Failure'),
        3: t('Waiting'),
        4: t('Off'),
        5: t('Pending'),
        7: t('Error'),
    };

    const tableStatusClass = 'table-status status-' + status;

    return (
        <>
            {(props.lang === 'he') && <RtlCss />}
            <div className={tableStatusClass}>
                <span>{'● ' + (props.mini ? '' : statusText[status])}</span>
            </div>
        </>
    );
}

export default TableStatus;