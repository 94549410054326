import * as css from './BtnLanguages.css';
import List from '../../list/List';
import React from 'react';
import useShow from '../../../hooks/useShow';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../redux/setGlobalState';
import * as flags from '../../../assets/symbols/symbols/languagesIcons';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {getLanguages} from '../../../redux/languages/languagesActions';

function BtnLanguages() {
    const {open,close,isOpen,showClass}=useShow();
    const history = useHistory();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const urlLang = urlParams.get('brandingLang');
    const {languages, getLanguagesXHR} = useSelector(s=>s.languages);

    const {currentLang} = useSelector(s=>s.branding);
    const currentLangName =  languages?.find(language => language.lang === currentLang)?.name;
    const list = languages?.filter(language => language.lang !== currentLang)
        .map(language => ({
            key: language.lang,
            lang: language.lang,
            text: language.nativeName,
            icon: flags[`${language.name}Flag`],
            onClick: () => changeLang(language.lang)
        }));

    const changeLang =(lang)=>{
        setGlobalState('branding.currentLang',lang);
    };

    useEffect(()=>{
        if(!getLanguagesXHR)
            getLanguages().then();
    },[]);

    useEffectOnUpdate(() => {
        urlParams.set('brandingLang', currentLang);
        history.push({search:urlParams.toString()});
    },[currentLang]);

    useEffect(()=>{
        if(urlLang && urlLang !== currentLang) {
            setGlobalState('branding.currentLang', urlLang);
        }

    },[urlLang]);
    if(languages.length<2)
        return <></>;
    return (
        <css.wrapper>
            <css.button  className={showClass} onClick={open} data-cy="BtnLanguages" >
                <css.text> {languages?.find(language => language.lang === currentLang).nativeName}</css.text>
                <css.icon icon={flags[`${currentLangName}Flag`]}/>
            </css.button>
            {!!isOpen && <List list={list} close={close}  width={190+'px'} top={60+'px'} showClass={showClass} data-cy="listLanguages"/>}
        </css.wrapper>
    );
}
export default BtnLanguages;
