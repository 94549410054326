import * as css from './UserMessageBlock.css.js';
import React,{useEffect} from 'react';
import {useSelector} from 'react-redux';
import useShow from '../../hooks/useShow';
import setGlobalState from '../../redux/setGlobalState';
import Close from '../../component/close/Close';
import store from '../../store';
import Button from '../../component/button/btn/Btn.js';

let onAccept=()=>{};
let onClose=()=>{}; 

const openMessage=(title,text,acceptButton,closeButton)=>{
    onAccept=acceptButton?acceptButton.onClick:()=>{};
    onClose=closeButton?.onClick?closeButton.onClick:()=>{};
 
    setGlobalState('ui.userMessageBlock', {...store.getState().ui.userMessageBlock, 
        title,
        status:'open',
        text,
        acceptText:acceptButton?.text,
        closeText:closeButton?(closeButton?.text||'close'):null,
    }
    );
};

export const bounceMessageBlock=(title,text,acceptButton,closeButton={text:'close'})=>{
    const status=store.getState().ui.userMessageBlock.status;
    if(!status||status!=='open'){
        openMessage(title,text,acceptButton,closeButton);
    }
};

const UserMessageBlock=React.memo(function UserMessage() {
    const userMessage=useSelector(s=>s.ui.userMessageBlock);
    let {title,text,acceptText,closeText,status}=userMessage;

    const {open,close,isOpen,showClass}=useShow();

    useEffect(()=>{
        
        if(status==='open'&&!isOpen)
            open();
        else if(status==='close'||status==='replace')
            close();
    },[status]);

    const closeAndInitial=(e)=>{
        e?.stopPropagation();
        close();
        setTimeout(() => {
            setGlobalState('ui.userMessageBlock', 'reset');
        }, 310);
    };

    const closeMessage=(e)=>{
        onClose();
        closeAndInitial(e);
    };

    if(!isOpen)
        return <></>;

    return <>
        <css.userMessagesBackground>
            <css.warpUserMessages className={`${showClass}`}  >
                <css.close type={'attention'}>
                    <Close className={'white'} onClick={closeMessage}/>
                </css.close >
                <css.Title >{title}</css.Title>
                <css.Message >{text}</css.Message>
                <css.buttons   >
                    {<Button onClick={closeMessage} >{closeText}</Button>}
                    {<Button onClick={(e)=>{onAccept();closeAndInitial(e);}}  className={' main '} >{acceptText}</Button>}
                </css.buttons>
            </css.warpUserMessages>
        </css.userMessagesBackground>
    </>;
}
);
export default UserMessageBlock;