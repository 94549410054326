import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import setGlobalState, {resetGlobalState} from '../../redux/setGlobalState';
import store from '../../store';
import Colors from './colors.js/Colors';
import * as css from './ColorsPicker.css';

export const getNextColor = () => {
    const colorsPicker = store.getState().ui.colorsPicker;
    const arrColorTemp = colorsPicker.arrColorTemp;
    let indexMenor = arrColorTemp.indexOf(Math.min(...arrColorTemp));
    let selectColor = colorsPicker.arrColor[indexMenor];
    setGlobalState(`ui.colorsPicker.arrColorTemp[${indexMenor}]`, arrColorTemp[indexMenor] + 1);
    return selectColor;
};

function ColorsPicker({onClickColor}) {
    const {t} = useTranslation('layout');

    const colorsPicker = useSelector((state) => state.ui.colorsPicker);
    const arrColorTemp = colorsPicker.arrColorTemp;
    const arrColor = colorsPicker.arrColor;

    useEffect(() => {
        return () => {
           // resetGlobalState('ui.colorsPicker');

        };
    }, []);

    useEffect(() => {
        let index = arrColorTemp.findIndex(c => { return c === 0; });
        setGlobalState('ui.colorsPicker.isAllColorsDisabled', (index === -1));
    }, []);

    return (
        <>
            <css.wrapAll>
                <css.text>{t('chooseColor')}</css.text>
                <css.wrapColors>
                    <css.colors>
                        {arrColor?.map((c, index) => {
                            if(!arrColorTemp[index] || colorsPicker.isAllColorsDisabled || c === colorsPicker.currentColor) {
                                return <Colors key={index} onClickColor={onClickColor} color={c} />;
                            }
                            return null;
                        })}
                        {arrColor?.map((c, index) => {
                            if(arrColorTemp[index] && !colorsPicker.isAllColorsDisabled && c !== colorsPicker.currentColor) {
                                return <Colors key={index} onClickColor={onClickColor} color={c} disableColor={true}/>;
                            }
                            return null;
                        })}
                    </css.colors>
                </css.wrapColors>
            </css.wrapAll>
        </>
    );
}

export default ColorsPicker;
