import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {Else, If, Then} from 'react-if';
import CircleClose from '../../../../component/circleClose/CircleClose';
import {useRequiredFields} from '../../../../hooks/useValidationSchemaContext';
import {useStepClick} from '../navigation/Navigation';
import StepContext from '../stepContext/StepContext';
import StepLeft from '../StepLeft/StepLeft';
import StepRight from '../stepRight/StepRight';
import * as css from './Step.css';
import {useEffect,useContext,useState} from 'react';
import useStep from '../StepLeft/useStep';
import {newEventContext} from '../newEventForm/NewEventForm';

export const stopEnterKeyDown=e=>{
    if(e.key==='Tab')
        e.stopPropagation();
};

function Step({children,index,forwardRef=null}) {
    
    const {steps,currentStep,setCurrentStep,isReady,failedState:[failed]}=useContext(newEventContext);
    const {name,RightComp,TopComp,getTopValue=value=>value,isShowErrorMessages,isEmptyField=value=>!value}=steps[index];
    
    const {t} = useTranslation('newEvent');
    const [{value},meta,helpers]=useField(name);
    const isRequired=useRequiredFields(name);
    const isOpen=currentStep===index;
    const isLast = steps[index].isLast;
    const className=isOpen?'open':'close';
    const onClickStep = useStepClick();
    const {onContinue} = useStep(steps[index]);
    const isEmpty=isEmptyField(value);
    const [isAnimateNow,setIsAnimateNow]=useState(false);
    
    const onClose=(e)=>{
        e.stopPropagation();
        if(!meta.touched)
            helpers.setTouched(true);   
        if(!meta.error)
            setCurrentStep(prev=> (isLast||isReady)?-1 :prev+1);  
    };
    const [realIsOpen,setRealIsOpen]=useState();

    useEffect(()=>{
        if(isOpen)
            setRealIsOpen(true);
        else 
            setTimeout(() => {
                setRealIsOpen(isOpen);
            }, 500);
    },[isOpen]);

    useEffect(()=>{
        setIsAnimateNow(true);
        setTimeout(() => {
            setIsAnimateNow(false);
        }, 500);
    },[isOpen]);

    const onKeyDown = e => {
        if(['Tab', 'Enter'].includes(e.key)&&!isEmpty) {
            onContinue();
        }
    };

    const validClassName=((meta.error||failed[name])?'error':'valid');

    const topTextBeforeFill=t(`${name}TopHeader`)===`${name}TopHeader`?(t(steps[index].texts?.header||`${name}Header`)):t(`${name}TopHeader`);
    const topTextAfterFill=t(`${name}TopAfterFill`)===`${name}TopAfterFill`?topTextBeforeFill:t(`${name}TopAfterFill`);
    const topTextNoValue = t('noValue',{name:t(name)});
    const topText=isEmpty?((!meta.touched || isOpen)? topTextBeforeFill : topTextNoValue) :topTextAfterFill;
  
    return (
        <css.wrapper className={className} index={index} ref={forwardRef} tabIndex={1} onKeyDown={onKeyDown} data-cy={`${name}Step`}>
            <css.top className={className} onClick={()=>onClickStep(index)} data-cy={`stepTop-${name}`}>
                <css.close className={isOpen||!meta.touched?'':validClassName} data-cy='stepClosedSign'>
                    {isOpen&&<CircleClose isDark styleCss='position:unset;' close={onClose} closeType = 'stepClose'/>}
                </css.close>
                <css.image className={`${className} ${name}`} name={name}/>
                <css.topTitle className={className}>
                    {!isRequired&&<css.notRequired className={className}>{t('notRequired')}</css.notRequired>}
                    {(isOpen||isEmpty)?
                        topText
                        :
                        TopComp?
                            <TopComp/>
                            :
                            <css.wrapCloseText className={isRequired&&'required'}>
                                {topText}
                                <css.valueOnClose>{getTopValue(value)?.toString()}</css.valueOnClose>
                            </css.wrapCloseText>
                    }
                </css.topTitle>
            </css.top>
            <css.warpContext 
                className={`${className} ${(isOpen&&meta.touched)?validClassName:''} ${isAnimateNow?'isAnimateNow':''}`}
                errorMessage={typeof meta.error==='object'?Object.values(meta.error)[0]:meta.error} 
                isShowErrorMessages={isShowErrorMessages}
                data-cy={`${name }StepValidation`}
                data-cy-message={typeof meta.error==='object'?Object.values(meta.error)[0]:meta.error}
        
            >
                {realIsOpen&&
                <If condition={!!RightComp}>
                    <Then>
                        <StepContext >
                            <StepRight name={name}>
                                {children}
                            </StepRight>
                            <StepLeft name={name} />
                        </StepContext>
                    </Then>
                    <Else>
                        {children}
                    </Else>
                </If>}
            </css.warpContext>
        </css.wrapper>
    );
}

export default Step;
