import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import Btn from '../../../../../component/button/btn/Btn';
import * as css from './draftPopup.css';
import PopUpBubble from '../../../../../component/popUpBubble/PopUpBubble';
import useShow from '../../../../../hooks/useShow';

function DraftPopup() {
    const {t} = useTranslation('ticket');
    const [{value}]=useField('draft');
    const [{value:title}]=useField('title');
    const {isOpen,open,close,showClass}=useShow();

    if(!value)
        return <></>;

    return (
        <>
            <css.wrapButton length={title?.length+3}>
                <Btn onClick={isOpen?close:open}>{t('draft')}</Btn>                
                {isOpen&&<PopUpBubble showClass={showClass} close={close} >
                    <css.draftPop >{t('draftExplanation')}</css.draftPop>
                </PopUpBubble>}
            </css.wrapButton>
        </>
    );
}

export default DraftPopup;
