import styled from 'styled-components/macro';
import {BGImageCenter, ellipsisLines} from '../../../globalVariables/GlobalVariables';

export const main = styled.div`
    display: flex;
    height: 80px;
    margin-bottom: 12px;
`;

export const icon = styled.div`
    ${p=>BGImageCenter(p.icon,'contain')}
    height: 80px;
    width: 105px;
    margin-inline-start: 19px;
`;

export const text = styled.div`
    width: 255px;
    margin-inline-start: 5px;
    ${ellipsisLines(4)}
`;

export const title = styled.div`
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
    &.white{
        color: #FFFFFF;
    }
`;

export const content = styled.div`
    font-size: 14px;
     white-space: pre-line;
    &.white{
        color: #FFFFFF;
    }
`;

export const buttons = styled.div`
    display: flex;
    margin-inline-end: 30px;
    justify-content: flex-end;
`;