import styled from 'styled-components/macro';

export const imageStage = styled.div`
  max-width: 100%;
`;

export const upload = styled.div`
cursor: pointer;
position: absolute;
width: 100%;
height:100%;
background-color: transparent;

  `;
