import {useTranslation} from 'react-i18next';
import InnerPop from '../../../../../component/newPopup/innerPop/InnerPop';
import * as css from './GuidesListPop.css';
import User from '../../../../../component/user/User';
import {useField} from 'formik';
import AnotherOneCircle from '../../../../../component/anotherOneCircle/AnotherOneCircle';
import useNavigationLink from '../../../../../hooks/useNavigationLink';
import {useEffect} from 'react';
import setGlobalState from '../../../../../redux/setGlobalState';
import {guideArrayHelpers} from '../guideList.js/GuideList';
import useTeamMembers from '../../../../../hooks/useTeamMembers';

export const doOnMark=(user)=>{
    guideArrayHelpers.push({...(user.status==='unauthenticated'?{email: user.email}:{nameFirst:user.nameFirst, nameLast:user.nameLast}),
        active: 1, uid: user.uid, picture: user.picture, newUser:user.newUser});
};

const doOnRemove=(user,field)=>{
    guideArrayHelpers.remove(field.value.findIndex((x)=>x.uid===user.uid));
};

function GuidesListPop({show}) {

    const {t} = useTranslation('ticket');
    const [field,,]=useField('users');
    const {members} = useTeamMembers();
    const activeMembers = members.filter(m=>m.status!=='inactive');
    const guideMembers=activeMembers.map(m=>({...m,active:field.value.some(f=>f.uid===m.uid)}));
    const teamMemberNavigation = useNavigationLink('teamMemberPop', 'teamMember');

    useEffect(()=>{
        return () => {
            setGlobalState('ui.team.isGuide',0);
        };
    },[]);

    return (
        <InnerPop dataCy='guidesList' show={show} title={t('addGuide')} buttons={{whiteBtn:{text:t('close')}}}>
            <css.guidesList>
                <css.text>{t('chooseGuide')}</css.text>
                <css.guides membersLength={guideMembers.length>9?guideMembers.length:undefined}> 
                    {  guideMembers.map((member) =>
                        <User key={member.uid}
                            user={member} 
                            doOnRemove={(user)=>doOnRemove(user,field)} 
                            doOnMark={doOnMark} 
                            isMark={member?.active} 
                            notVerified={member?.status==='unauthenticated'}/>
                    )}
                    <AnotherOneCircle title={t('addGuide')} size={80} onClick={()=>{ setGlobalState('ui.team.isGuide',1);
                        teamMemberNavigation.open();}}/>
                </css.guides>
            </css.guidesList>
        </InnerPop>
    );
}

export default GuidesListPop;
