import * as css from './InputTimeStartEnd.css';
import {useField} from 'formik';
import {useRef} from 'react';
import TimePicker from '../../FormikDatePicker/TimePicker';

function InputTimeStartEnd({className, endName, startName, descText, text}) {

    const [, metaS] = useField(startName);
    const [, metaE] = useField(endName);

    const ref = useRef(null);

    const classes = `${className}  ${!!metaS.touched && metaE.touched && ((metaE.error || metaS.error) ? ' invalid' : ' valid')}`;

    return (<>
        <css.container className={classes}>
            <css.icon className={classes} ref={ref} />
            <css.wrap>
                <css.inputs>
                    <css.wrapDate>
                        <css.dateText>{text}</css.dateText>
                        <css.wrapInput top={ref.current?.getBoundingClientRect().top}>
                            <TimePicker name='start' />
                        </css.wrapInput>
                        <css.wrapInput top={ref.current?.getBoundingClientRect().top} >
                            <TimePicker name='end' />
                        </css.wrapInput>
                    </css.wrapDate>
                </css.inputs>
                <css.descText> {descText} </css.descText>
            </css.wrap>
        </css.container>
    </>
    );
}

export default InputTimeStartEnd;
