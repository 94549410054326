import * as css from './CashQuestion.css';
import {useTranslation} from 'react-i18next';

export function CashQuestion() {
    const {t} = useTranslation('realtime');

    return (
        <css.questionContiner>
            {`${t('cashQuestionText')} `}
            {/*<span onClick={() => setReplaceComp(true)}>*/}
            {/*    {t('clickHere')}*/}
            {/*</span>*/}
        </css.questionContiner>
    );
}