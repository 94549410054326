import {Else, If, Then} from 'react-if';
import * as css from './LeadRow.css';
import {useTranslation} from 'react-i18next';

function ContactLead({lead,leadPopOpen}) {
    const {t} = useTranslation('publish');
    const {contactPhone,contactEmail,contactName}= lead;

    return (
        <css.wrapper onClick={()=>leadPopOpen(lead)} data-cy='editContact' className='contacts'>
            <css.widthMaxContent>
                <If condition={contactPhone||contactEmail||contactName}>
                    <Then>
                        <css.boldText data-cy='editContactName'>{contactName}</css.boldText>
                        <css.text data-cy='editContactPhone'>{contactPhone}</css.text>
                        <css.text data-cy='editContactEmail'>{contactEmail}</css.text>
                    </Then>
                    <Else>
                        <css.boldText data-cy='contactNameTitle'>{t('contactName')}</css.boldText>
                        <css.text>__</css.text>
                    </Else>
                </If>
            </css.widthMaxContent>
        </css.wrapper>
          
    );
}
export default ContactLead;
