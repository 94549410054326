import React from 'react';
import * as css from './PopUpFrame.css';

function PopUpFrame({children, showClass, forwardRef}) {

    return (
        <css.popUpFrame className={'popUpFrame ' + showClass} data-cy="popUpFrame" >
            <div ref={forwardRef}>
                {children}
            </div>
        </css.popUpFrame>
    );
}
export default PopUpFrame;
