import styled from 'styled-components/macro';

export const leftChildren = styled.div`
    display: flex;
    align-items: center;
    font-size:16px;
`;
export const title = styled.div`
    width: 134px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
export const price = styled.div`
    width: 65px;
    text-align: left;
    padding-inline-end: 10px;
`;
