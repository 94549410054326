import {useTranslation} from 'react-i18next';
import {defaultImage} from '../../../assets/symbols/images/defaultImage';
import SmallPopUp from '../../newPopup/smallPopUp/SmallPopUp';
import UserMessagePop from '../../userMessagePop/UserMessagePop';
import * as css from './DeleteImagePop.css';

function DeleteImagePop({newSize,image,openDeleteImage,onDeleteImage,top}) {

    const {t} = useTranslation('layout');

    return (
        <SmallPopUp {...openDeleteImage} top={top}>
            <>
                <css.wrapImage>
                    <css.shareImage width={newSize.width} height={newSize.height}>
                        <css.image img={image||defaultImage} width={newSize.width} height={newSize.height} />
                    </css.shareImage>
                </css.wrapImage>
                <UserMessagePop text={t('deleteImage')} firstButton={{text:t('cancelBtn'),onClick:()=>openDeleteImage.close()}} 
                    secondButton={{text:t('confirmation'),onClick:()=>{onDeleteImage();openDeleteImage.close();}}} />
            </>
        </SmallPopUp>
    );
}
export default DeleteImagePop;
