import Btn from '../../../../../component/button/btn/Btn';
import useShow from '../../../../../hooks/useShow';
import {TSystemMessage} from '../../../../../redux/realtime/realtime.type';
import SystemMessageDisplayPop from '../../windows/systemMessage/systemMessageDisplayPop';
import * as css from './form.css';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

function PopBottom({close}){

    const previewShow = useShow();

    const {t} = useTranslation('realtime');

    const form=useFormikContext();

    const onSave = e => {
        form.handleSubmit(e);
        
    };

    const openPreviewPop = async() => {
        const fieldsToCheck = {
            image: true,
            message: true,
            title: true
        };
        form.setTouched(fieldsToCheck, true);
        const errors = await form.validateForm() as TSystemMessage;
        if(!errors.image && !errors.message && !errors.title)
            previewShow.open();
    };
    return (
        <css.bottom >
            <css.buttonsRight>
                <Btn onClick={openPreviewPop} className={''} disabled={false} >{t('preview')}</Btn>
                {previewShow.isOpen && <SystemMessageDisplayPop messagePreview={{...form.values as any, displayInPop: true}} onClose={()=>previewShow.close()} />}
                
            </css.buttonsRight>
            <css.buttonsLeft>
                <css.nextBtn> 
                    <Btn className={'white'} onClick={close} disabled={undefined}>{t('close')}</Btn>
                </css.nextBtn>
        
                <css.saveBtn className={(!form.isValid &&' disabled ') }>
                    <Btn onClick={onSave} className={''} disabled={false} >{t('save')}</Btn>
                </css.saveBtn>
            </css.buttonsLeft>

        </css.bottom >
    );
}

export default PopBottom;