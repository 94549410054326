import store from '../../../store';
import TicketsPopupContent from '../ticketsPopUp/basicTicket/ticketsPopupContent/TicketsPopupContent';
import CoursePopupContent from '../ticketsPopUp/courseTicket/coursePopupContent/CoursePopupContent';

export default function getComponentByEventType() {
    const type = store.getState().event.type;

    switch (type) {
        case 'course':
            return CoursePopupContent;
        default :
            return TicketsPopupContent;
    }

}
