import * as css from './DataItem.css';
import React from 'react';
import useShow from '../../hooks/useShow';
import List from '../list/List';

function SumMoreItems({icon = '', className,  list = [] ,onClick}) {

    const {open, close, isOpen, showClass} = useShow();

    return (<>
        <css.wrap className={'sum ' + className} onClick={isOpen ? close : open}>
            <css.icon icon={icon} />
            <css.text className={className} >{'+' + list.length}</css.text>
        </css.wrap>
        {!!isOpen && <List close={close} onClick={onClick} width={'200px'} list={list} showClass={showClass +' inputList'} />}
    </>
    );
}
export default SumMoreItems;
