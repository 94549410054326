import {useRef} from 'react';
import useOnClickOutside from '../../../hooks/UseOnClickOutside';
import MobilePopup from './MobilePopup';

function MobilePopupModal({children, close, showClass, title, list,height='max-content',top , dataCyName, cssStyle=''}) {
    
    const ref = useRef(null);
    // temp
    useOnClickOutside(ref, close,'MobilePopupModal');

    return (  
        <MobilePopup {...{cssStyle, close,showClass, title,list, height, top,dataCyName}} forwardRef={ref} >
            {children}
        </MobilePopup>

    );
}

export default MobilePopupModal;
