import * as css from './Cash.css';
import {useSelector} from 'react-redux';
import {formatRealtimeNumber} from '../../functions';
import {useTranslation} from 'react-i18next';
import {CashGraph} from '../cashGraph/CashGraph';
import MasterPermission from '../../../components/rolePermission/MasterPermission';
import CashTemplate from './CashTemplate';
import {XHR_LOADING_STATE} from '../../../../assets/constants/constants';
import {useState} from 'react';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';

export function Cash() {
    const {t} = useTranslation('realtime');
    const event = useSelector((s: any) => s.event);
    const realtime = useSelector((s: any) => s.realtime?.event);
    const isLoading = useSelector((s: any) =>s.realtime.getRealtimeXHR === XHR_LOADING_STATE);
    const [isStats,setIsStats]=useState(false);
    const totalPrice = realtime?.eventStats?.all?.totalPrice;
    const statsData = realtime?.cash?.statsData?.balance;
    const percent = realtime?.moreInfo?.soldPercent;
    const trend = realtime?.moreInfo?.salesTrend;

    const getSubtext = (percent: number) => {
        const index = Math.min(Math.floor(percent / 20), 4); // Ensure the index does not go beyond 4
        switch (index) {
            case 0: return t('startSale');
            case 1: return t('lowSales');
            case 2: return t('goodSales');
            case 3: return t('almostFinishedSelling');
            case 4: return t('finishedSelling');
            default: return '';
        }
    };

    if(isLoading) {
        return <CashTemplate />;
    }

    return (
        <>
            <css.cashContainer>
                <MasterPermission>
                    {!!statsData && <ModeBtn status={isStats} click={(status) => {
                        setIsStats(status);
                    }} className={'isStats'} dataCyName={'isStats'} dataTitle={undefined}
                    text={t('displayStats')} requestStatus={undefined}/>}
                </MasterPermission>
                <css.cashHeader>{t('cash')}</css.cashHeader>
                <css.Section>
                    <css.cashAmount><span>{event.currencySymbol}</span>{formatRealtimeNumber((isStats?statsData:totalPrice) || 0, false)}</css.cashAmount>
                    {(!!event?.settings?.event?.active && event.timeEnd > Math.floor(Date.now() / 1000)) && trend > 0 &&
                        <css.Trend className='free-color' trend={trend}>{Math.floor(trend ? trend : 0)}%</css.Trend>}
                    <css.Subtext>{getSubtext(parseFloat(percent))}</css.Subtext>
                </css.Section>

            </css.cashContainer>
            <MasterPermission><CashGraph/></MasterPermission>
        </>
    );
}
