import * as css from './MyTickchakSystem.css';
import {useTranslation} from 'react-i18next';
import PopUpBubble from '../../../component/popUpBubble/PopUpBubble';
import {setCurrentRating} from '../../../pages/components/rating/Rating';
import {useSelector} from 'react-redux';
import {TICK_DOMAIN_MY} from '../../../config/config';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import {oldMan} from '../../../assets/symbols/images/oldMan';
import BtnAnimation from './btnAnimation/BtnAnimation';
import useShowModal from '../../../hooks/useShowModal';
import {useRef} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import TickchakLoaderXHR from '../../../component/tickchakLoader/TickchakLoaderXHR';
import Btn from '../../../component/button/btn/Btn';

function MyTickchakSystem() {
    const {t} = useTranslation('layout');
    const [XHR,setXHR]=useState(null);

    const {isShowRatings,currentRatingAction,ratings} = useSelector(s=>{
        return ({
            isShowRatings:s.ratings.currentRating?.show,
            currentRatingAction:s.ratings.currentRating?.rating.action,
            ratings:s.ratings?.ratings
        });});

    let newCurrentRating = ratings?.find((item) => item.action === 'useApp');

    useEffect(() => {
        return () => setXHR(null);
    }, []);

    const ref = useRef();
    const show = useShowModal(ref);

    useEffectOnUpdate(()=>{ 
        if(currentRatingAction==='useApp'){
            if(isShowRatings)
                return;
            else
                location.href = TICK_DOMAIN_MY+location.pathname;
            setXHR('LOADING');
        }

    }, [isShowRatings]);

    const onClickOld = ()=>{ 
        if(newCurrentRating.rated){
            location.href =TICK_DOMAIN_MY+location.pathname;
            setXHR('LOADING');
        }
        setCurrentRating('useApp');     
    };
    const isOpenEvents=useSelector(s=>s.ui.show.isOpenEvents);

    return (
        <>
            <css.warp ref={ref} className={isOpenEvents?' margin':''}>
                <BtnAnimation onClick={show.open} text={t('oldInterface')}/>
                {show.isOpen &&<PopUpBubble 
                    close={show.close}
                    showClass={show.showClass} >
                    <>
                        <css.top image={oldMan} />
                        <css.body>
                            <css.text> {t('newSystem')}</css.text>
                            <Btn className={'white'} onClick={onClickOld}>
                                {t('takeMeThere')}
                            </Btn>
                        </css.body>
                    </>          
                </PopUpBubble>} 
            </css.warp>
            <TickchakLoaderXHR xhr={XHR} cssStyle={'position:fixed ;'}/>
        </>
    );
}

export default MyTickchakSystem;

