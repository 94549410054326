import styled from 'styled-components';
import {ellipsis} from '../../../globalVariables/GlobalVariables';
import * as inputClasses from '../../classes/input';

export const container = inputClasses.container;
export const icon = inputClasses.icon;
export const wrap = inputClasses.wrap;
export const disabledInput = styled(inputClasses.input) `
  ${ellipsis}
  font-weight:${inputClasses.INPUT_WEIGHT} ;
`;
export const title = inputClasses.title;