import {STATIC_PATH} from '../config/config';

const s3Upload = async (base64Image, mimeType, url, key) => {
    const base64Data = base64Image.replace(/data:(image\/(png|jpeg|webp|svg+xml)|application\/pdf);base64,/, '');
    
    // Convert base64 to blob
    const blob = new Blob([Buffer.from(base64Data, 'base64')], {type: mimeType});
    
    // Use the pre-signed URL to upload the file
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': `${mimeType}; charset=UTF-8`, // Ensure this matches the ContentType in your Node.js server
        },
        body: blob,
    };
	
    const uploadResponse = await fetch(url, options);
    if(uploadResponse.ok) {
        return `${STATIC_PATH}${key}`;
    } else {
        throw new Error('Upload failed');
    }
};

export default s3Upload;