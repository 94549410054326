import * as css from './Artist.css';
import {artistArrayHelpers} from '../artists/Artists';
import Animation from '../../../../../component/animation/Animation';
import active from '../../../../../assets/animations/active.json';
import useShow from '../../../../../hooks/useShow';
import {useEffect} from 'react';
function Artist({artist,index,isTop=false}) {
    const removeArtist=()=>{
        // deleteArtist(artist?.artistId).then(()=>{
        artistArrayHelpers.remove(index);

        // });
    };
    const {open,isOpen,showClass}=useShow(300);
    useEffect(open,[]);

    return (
        <css.container>{
            isOpen&&<>
                <Animation animation={active} isLoop={false}/>
                <css.artist data-cy={`artist-${(!isTop||!artist?.image)&&artist?.title}`} image={artist?.image} name={(!isTop||!artist?.image)&&artist?.title} onClick={removeArtist} className={showClass}  />
            </>
        }
        </css.container>
    );
}

export default Artist;
