/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components/macro';
import {arrow} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter} from '../../../globalVariables/GlobalVariables';
import {BOTTOM_HEIGTH} from '../tabsPopup/TabsPopup.css';
import zIndex from '../../../globalVariables/zIndex';

export const container=styled.div`
    height: 100%;
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    width: 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px #00000052;
    transition: all 300ms;
    z-index: ${zIndex.basicModal};
    overflow:hidden;
    &.show{
        width:285px;
    }
`;

export const content=styled.div`
    height:calc(100% - ${BOTTOM_HEIGTH} );
    width: 100%;
`;

export const close=styled.div`
    ${BGImageCenter(arrow,'16px')}
    height: 60px;
    width: 30px;
    inset-inline-start: 20px;
    ${p=>!p.theme.rtl&&'transform: rotate(180deg);'}
    z-index: ${zIndex.baseRaised};
    cursor:pointer;
    position: absolute;
`;

export const title=styled.div`
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 16px;
    position: absolute;
    inset-inline-end:20px ;
    top:18px;
    color:white;
`;

export const bottom=styled.div`
    ${dFlexCenter};
    border-top: 1px solid #EEE;
    height: ${BOTTOM_HEIGTH};
`;