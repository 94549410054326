import {useTranslation} from 'react-i18next';
import GoogleMaps from '../../../../component/googleMaps/GoogleMaps';
import {updateEvent} from '../../../../redux/event/eventActions';
import * as css from './LocationStep.css';
import {useField} from 'formik';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';
import {stopEnterKeyDown} from '../Step/Step';
import StepLeft from '../StepLeft/StepLeft';
import StepContext from '../stepContext/StepContext';
import StepRight from '../stepRight/StepRight';
import useStep from '../StepLeft/useStep';
import {useContext} from 'react';
import {newEventContext} from '../newEventForm/NewEventForm';
import useMobile from '../../../../hooks/useMobile';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const onClickLocation=async (changedValues)=>{
    updateEvent(changedValues?.location).then();
};

export const getTopLocation=(value)=>{
    if(!value?.locationMap?.title)
        return '';
    const location=value.locationMap.title.split(',');
    return `${location[0]}${location[1]?`, ${location[1]}`:''}`;
};

const inputStyle=
   `grid-template-columns: repeat(2 , 1fr); 
    grid-gap: 5px;
    ${mobileMedia}{
        display: block;
  }
    `;

function LocationStep() {
    const {t} = useTranslation('newEvent');
    const [{value},,helper]=useField('location.locationMap');
    const isMobile = useMobile();
    useEffectOnUpdate(()=>{
        helper.setTouched(true);
    },[value]);

    const {steps,currentStep}=useContext(newEventContext);
    const {onContinue} = useStep(steps[currentStep]);

    return (
        <css.wrapAll>
            <StepContext>
                <StepRight name={'location'} >
                    <css.container onKeyDown={stopEnterKeyDown} >
                        <GoogleMaps locationMapName={'location.locationMap'} place={{placeName:'location.location',placeText:t('placeName')}} 
                            mapStyle={{width: '100%',height: isMobile?'280px':'215px',position:'absolute',right: '0'}} 
                            inputStyle={inputStyle}
                            locationInputProp={{events:{onKeyDown:(e)=>{
                                if(e.key==='Tab')
                                    onContinue();}}}}>
                            {isMobile&&<StepLeft name={'location'}/>}
                        </GoogleMaps>
                    </css.container>
                </StepRight>
                {!isMobile&& <StepLeft name={'location'}/>}
            </StepContext>     
        </css.wrapAll>
    );
}

export default LocationStep;

