import {hasAccountAccess} from '../../functions/permission';
import request from '../../library/request';
import highSecurityRequest from '../../functions/securityRequest/highSecurityRequest';
import store from '../../store';
import setGlobalState from '../setGlobalState';
import securityRequest from '../../functions/securityRequest/securityRequest';
import {getTs} from '../../library/dates';
import {REACT_APP_ENV, TICK_DOMAIN_MY} from '../../config/config';
import readCookie from '../../library/readCookie';
import {downloadURI} from '../../component/fileUpload/FileUpload';
import {uploadFile} from '../user/userActions';

export const getEid = () => store.getState().event.eid;

export const getEvent = async (eid) => {
    const res = await request('event.getEvent', 'get', `/event/${eid}`);
    setGlobalState('event', res);
    return res;
};

export const updateEvent = async (fields,eid=getEid()) => {
    if(!fields?.artists){fields.artists=store.getState().event.artists;}
    if(fields?.fbImgBase64){fields.fbImgBase64 = uploadFile(fields.fbImgBase64, '', 'hoFbImg', eid);}
    if(fields?.logo){fields.logo = await uploadFile(fields.logo, '', 'hubLogo', eid);}
    const res=await request('event.updateEventDetails', 'put', `/event/${eid}`, fields);
    if(res.event&&(fields.location||fields.locationMap)){
        fields.timeZone=res.event.timeZone;
        fields.lang=res.event.lang;
    }
    setGlobalState('event', {...store.getState().event, ...fields});
    return res.event;
};
export const createDefaultTemplate = async () => {
    const account = store.getState().account;
    const fields = {pid: account.pid, limit: 1, offset: store.getState().suggestions.events.length, type: 'allEvents'};
    const res = await request('event.createDefaultTemplate', 'get', `/event/${getEid()}/defaultTemplate`,fields);
    if( store.getState().event.type==='ticket'){
        setGlobalState('event.ready',5);
        setGlobalState('event.previewLastUpdate',getTs());
        setGlobalState('suggestions.events', [...res.events, ...store.getState().suggestions.events]);
    }
};

export const getEventSettings = async () => {
    let res = await request('event.getEventSettings', 'get', `/event/${getEid()}/settings`);
    const {settings,hasSuspendedTransaction}=res;
    for(const key in settings) {
        if(!settings[key])
            settings[key] = {};
    }
    if(!settings.report?.users && settings.report)
        settings.report.users = [];
    if(!settings.report?.transactionUsers)
        settings.report.transactionUsers = [];
    if(settings.blockOrder) {
        if(settings.blockOrder.blockOrders) {
            // const { limit, countLimit, fids, fields, crowd } = settings.blockOrder.blockOrders
            settings.blockOrder = {...settings.blockOrder, ...settings.blockOrder.blockOrders};
            delete settings.blockOrder.blockOrders;
        }
    }
    setGlobalState('event.settings', {...store.getState().event.settings, ...settings});
    setGlobalState('event.hasSuspendedTransaction',hasSuspendedTransaction);
};

const getSettingsMethod = (settingCategory) => {
    const settings=store.getState().event.settings||{};
    if(['access','blockOrder'].includes(settingCategory)){
        const keys = Object.keys({...(settings.access||{}),...(settings.blockOrder||{})});
        if(keys.length === 0)
            return 'post';
    }
    else if(['payments', 'billings', 'suspended'].includes(settingCategory)) {
        const keys = Object.keys(settings[settingCategory] || {});
        if(keys.length === 0)
            return 'post';
    }
    return 'put';
};

const hasUpdateSettingAccess = (name) => {
    let permission = (name === 'paycash' && 'event cash') || (name === 'hasBillings' && 'billing') || (name === 'hasPayments' && 'payments') || null;
    if(permission)
        return hasAccountAccess(permission);
    return true;
};

const getBlockOrderField = (name, value) => {
    if(['limit', 'countLimit', 'crowd', 'fields', 'fids'].includes(name)) {
        const {limit, countLimit, fids, fields, crowd} = store.getState().event.settings?.blockOrder||{};
        return {
            blockOrders: {
                limit, countLimit, fids, fields, crowd,
                [name]: value,
            }
        };
    }
    return {[name]: value};
};
export const updateEventSettings = async (name, value, settingCategory, permission, rolePermission) => {
    if(!hasUpdateSettingAccess(name))
        return;
    let method = getSettingsMethod(settingCategory);
    let field = {[name]: value};
    if(settingCategory === 'blockOrder')
        field = getBlockOrderField(name, value);
    const args = [`forms.eventSettings.${name}`, method, `/event/${getEid()}/${settingCategory === 'event' ? 'settings' : settingCategory}`,
        field];
    if(permission)
        await highSecurityRequest(permission, rolePermission, ...args);
    else
        await securityRequest(permission,rolePermission, ...args);
    setGlobalState(`event.settings.${settingCategory}.${name}`, value);
    return store.getState().forms.eventSettings[`${name}XHR`];
};

export const updateEventSettingsList = async (name, settingCategory, serverValue, reduxValue) => {
    let field = {[name]: serverValue};
    if(settingCategory === 'blockOrder')
        field = getBlockOrderField(name, serverValue);
    await request(`forms.eventSettings.${name}`, 'put', `/event/${getEid()}/${settingCategory}`,
        field);
    setGlobalState(`event.settings.${settingCategory}.${name}`, reduxValue);
};

export const updateEventDesign = async (name, value, lang, langDesign) => {
    const res = await request('event.updateEventSettings', 'put', `/event/${getEid()}/design`, {[name]: value, lang: lang});
    setGlobalState(`event.settings.design.${name}`, res?.[name]);
    const updateLangDesign = langDesign.map(item => {
        if(item.lang === lang) {
            return {...item, [name]: res?.[name]};
        }
        return item;
    });
    setGlobalState('event.settings.langDesign', updateLangDesign);
    if(name === 'fbImg' && langDesign[0].lang == lang)
        setGlobalState('event.fbImg', res?.fbImg);
    if(value?.credit){
        const type = name === 'fbImg' ? 'desk' : name === 'fbImgMobile' ? 'mobile' : 'post';
        setGlobalState(`event.settings.design.credit.${type}`, value.credit);
    }
}; 

export const getEventPermissions = async (eid) => {//change name?
    let per = JSON.parse(localStorage.getItem('perEvent_' + eid));
    if(!per) {
        const res = await request('event.getEventPermissions', 'get', `/event/${eid}/permission`);
        if(res.permissions.length) {//??
            per = res.permissions;
            localStorage.setItem('perEvent_' + eid, JSON.stringify(per));
        }
        setGlobalState('event.userEventRole', res.eventRole);//why only hear???
    }
    setGlobalState('event.permissions', per);

};

export const updateEventReady=async (ready=5)=>{
    const res=await request('updateEventReady', 'put', `/event/${getEid()}/ready`,{ready});
    if(res?.ready)
        setGlobalState('event.ready',res.ready);

};
export const getAccounts = async (fields) => {
    const pid = store.getState().account.pid;
    const res = await request('event.getAccounts', 'get', `/package/${pid}/getUserPackages`, {...fields});
    const accounts = store.getState().event?.settings?.multipleAccounts?.accounts || [];
    if(res?.packages)
        setGlobalState('event.settings.multipleAccounts.accounts', [...accounts, ...res.packages]);
};
export const updateMultipleAccount=async (data)=>{
    const accountPid = store.getState().account.pid;
    data.action === 'selectedAll' ? data.accountPid = accountPid : false;
    const res=await request('event.updateMultipleAccount', 'put', `/event/${getEid()}/updateMultipleAccount`,{data});
    if(res){
        setGlobalState('event.settings.multipleAccounts.assignedAccounts', res.multipleAccountsArray);
    }
};

export const updateAutomaticDataAccount=async (data)=>{
    const res=await request('event.updateAutomaticDataAccount', 'put', `/event/${getEid()}/updateAutomaticDataAccount`,{data});
    if(res){
        setGlobalState('event.settings.multipleAccounts.assignedAccounts', res.multipleAccountsArray);
    }
};
export const getAiImages = async ()=>{
    const res = await request('event.getAiImages', 'get', `/event/${getEid()}/aiImages`);
    if(res) {
        setGlobalState('event.aiImages', res.aiImages);
    }

};
export const createSalePageImage = async (details)=>{
    const res = await request(`event.createSalePageImage.${details.style}`, 'post', `/event/${getEid()}/salePageImage`, {details});
    if(res) {
        setGlobalState('event.aiImages',res.aiImages);
    }

};
export const createTopSectionImage = async (details)=>{
    try {
        const res=await request('event.createTopSectionImage', 'post', `/event/${getEid()}/topSectionImage`,{details});
        if(res?.ready)
            setGlobalState('event.ready',res.ready);
    } catch (error) {
        setGlobalState('event.ready',4);
    }
};
export const getDescriptionFromGPT = async (descriptionObject)=>{
    const res=await request('event.getDescriptionFromGPT', 'post', `/event/${getEid()}/descriptionFromGPT`,{descriptionObject});
    if(res)
        setGlobalState('event.description',res.description);
    return res.description;
};

export const inactiveEventSendWhatsapp = async ()=>{
    try {
        await request('event.inactiveEventSendWhatsapp', 'post', `/event/${getEid()}/sendWhatsapp`);
    } catch (error) {
        console.log(error);
    }
};
export const getEventArtistAutomaton = async (text)=>{
    return  await request('event.getArtist', 'get', `/event/${getEid()}/artistAutomaton`,{text});
    // setGlobalState('event.artists', {...store.getState().event.artists, ...result.artists});
};
export const getArtistsBySearch = async (data)=>{
    return await request('event.getArtistsBySearch', 'get', `/event/${getEid()}/artistSearch`,data);
};

export const addArtist = async (data)=>{
    return await request('event.addArtist', 'post', `/event/${getEid()}/newArtist`,data);
};
// export const addArtist = async (artistId)=>{
//     return await request('event.addArtist', 'post', `/event/${getEid()}/artist`,{artistId});
// };
export const addArtists = async (artists)=>{
    await request('event.addArtists', 'put', `/event/${getEid()}/artists`,{artists});
    setGlobalState('event.artists', artists);

};

export const deleteArtist = async (artistId)=>{
    await request('event.deleteArtist', 'delete', `/event/${getEid()}/artist/${artistId}`);
    // const index = store.getState().event.artists.findIndex(a => a.artistId === artistId);
    // unsetGlobalState(`event.artists[${index}]`);
};

export const createDesignImage = async (fields)=>{
    const res=await request('event.createDesignImage', 'post', `/event/${getEid()}/designImage`,fields);
    setGlobalState('event.fbImg',res.image);
};
export const updateDesignPop=async (showPop= true)=>{
    const res=await request('event.updateDesignPop', 'put', `/event/${getEid()}/designPop`,{showPop:Number(showPop)});
    setGlobalState('event.showPop', res.showPop);
};
export const publishDesign=async ()=>{
    await request('event.publishDesign', 'put', `/event/${getEid()}/publishDesign`);
};
export const eventDuplication = async (eid, fields, callBack) => {
    let res = await request('event.eventDuplication', 'post', `/event/${eid}/duplicate`, fields);
    setGlobalState('ui.event.duplicateSourceEid', eid);
    callBack(res);
};

export const eventFinishDuplication = async (eid, sourceEid, duplicateEvent) => {
    let res = await request('event.eventDuplication', 'post', `/event/${eid}/finishDuplicate`, {sourceEid ,duplicateEvent});
    setGlobalState('event', {...res.newEvent});
    setGlobalState('ui.event.duplicateSourceEid', null);
};
export const addGooglePing=async ()=>{
    await request('event.addGooglePing', 'post', `/event/${getEid()}/addGooglePing`);
};
export const deleteGooglePing=async ()=>{
    await request('event.deleteGooglePing', 'delete', `/event/${getEid()}/deleteGooglePing`);
};

export const getImageColors = async () => {
    const res = await request('event.getImageColor', 'get', `/event/${getEid()}/imageColors`);
    if(res.colors)
        setGlobalState('event.colors', res.colors);
};

export const postEventReport = async () => {
    const {pid} = store.getState().account;
    const tickchakToken = readCookie('tickchak_token');
    let queryString=`tickchakNewApp=1&eid=${getEid()}&pid=${pid}`;
    if(REACT_APP_ENV === 'local'){
        queryString += `&tickchak_token=${tickchakToken}`;
    }
    const res =await request('event.postEventReport', 'post', `${TICK_DOMAIN_MY}/ajax/crm/export_pdf_event_summary?${queryString}`);
    if(res.path)
        downloadURI(res.path,'eventReport');
};
export const getSuspendedQueue = async (eid,tsqid) => {
    return await request('event.getQueue', 'get', `/event/${eid}/suspendedQueue/${tsqid}`);
};
