import * as css from './PackageSettings.css';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import useAccountSettings from '../../hooks/useAccountSettings';
import Input from '../../../../component/input/Input';
import DaysPicker from '../../../../component/daysPicker/DaysPicker';
import DisabledInput from '../../../../component/input/disabledInput/DisabledInput';
import {useTranslation} from 'react-i18next';
import InputList from '../../../../component/input/inputList/InputList';
import NumberInput from '../../../../component/input/NumberInput';
import {yupStringMin} from '../../../../functions/validationsYup';

export const accountTypes = ['basic', 'advanced', 'custom'];

function PackageSettings() {
    const {account, update, getBillClassName, getClassName, isMasterState, currentUpdatedField, changedFields} = useAccountSettings();
    const {t} = useTranslation('package');
    const typesList = accountTypes.map((item, index) => { return {text: t(item), key: index}; });

    const validationSchema = Yup.object().shape({
        title: yupStringMin().max(30).required(),
        endDate: Yup.number().min(account.startDate),
        defaultCommission: Yup.number().required().min(0).max(100),
        monthlyPayment: Yup.number().required().min(0),
    });

    const initialValues = {
        type: account.type,
        title: account.title,
        endDate: account.endDate,
        defaultCommission: account.billPackage?.defaultCommission,
        monthlyPayment: account.billPackage?.monthlyPayment,
    };
    
    return (<Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={() => { }} >
        <Form>
            <css.top>
                <css.side>
                    {/* type */}
                    {isMasterState ? <InputList name="type" icon="box" text={t('packageType')}  className={getClassName('type')} onChange={update}  list={typesList} /> :
                        <DisabledInput value={t(typesList[account.type]?.text)} icon="box" text={t('packageType')}   />}
                    <css.sideValidity>{t('packageValidity')}</css.sideValidity>
                    {/* date */}
                    <DaysPicker className={getClassName('endDate')} start={{start: account.startDate}} end={{endName: 'endDate'}} onBlur={update} disabled={!isMasterState} />
                </css.side>
                <css.side>
                    {/* title */}
                    <Input name="title" icon="pencil" text={t('packageTitle')} className={currentUpdatedField==='title'?account.updateAccountXHR:changedFields.title} events={{onBlur:update}} />
                    {/* monthlyPayment */}
                    {(!!account.billPackage?.monthlyPayment || isMasterState ) && <NumberInput name="monthlyPayment" text={t('monthlyPayment')} icon="nisBell" className={getBillClassName('monthlyPayment')} events={{onBlur:(name,value)=>update(name,value,true)}} disabled={!isMasterState} />}
                </css.side>
            </css.top>
        </Form>
    </Formik>
    );
}
export default PackageSettings;
