import React, {useState} from 'react';
import * as css from './AccountMenu.css';
import useAccountMenu from '../../tickchakHooks/useAccontMenu';
import {useTranslation} from 'react-i18next';
import List from '../../component/list/List';
import useShow from '../../hooks/useShow';
import AccountVerificationMessage from '../topMenu/accountVerificationMessage/AccountVerificationMessage';
import {useSelector} from 'react-redux';

//for mobile only

function AccountMenu({closeMenu}) {

    const {t} = useTranslation('layout');
    const account = useSelector(state => state.account);
    const accountList = useAccountMenu();
    const [currentIndex, setCurrentIndex] = useState([]);

    const show = useShow();

    const onClick = (item, index) => {
        if(item.list) {
            setCurrentIndex(index);
            show.open();
        }
        else {
            item.onClick(item);
            closeMenu();
        }
    };

    return (
        <css.accountWrap >
            {!(account.ownerEmailVerified===1 && account.termsVerified && account.bankVerified)&&<AccountVerificationMessage/>}
            {accountList().arr.map((item, index) => (
                <css.accountItem key={index} className={item.list ? ' hasSubList' : ''} onClick={() => onClick(item, index)}>
                    {item.rightChildren?item.rightChildren:
                        <css.accountIcon icon={item.icon} />}
                    {t(item.title||item.text)}
                </css.accountItem>
            ))}
            <div>
                {show.isOpen && <List t={t} onClick={(item) => {
                    accountList().arr[currentIndex]?.list?.onClick(item);
                    closeMenu();
                }} showClass={show.showClass} close={show.close} list={accountList().arr[currentIndex].list.arr}
                title={accountList().arr[currentIndex].list.title} />}
            </div>
        </css.accountWrap>
    );
}

export default AccountMenu;
