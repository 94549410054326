import React, {useRef, useState, useEffect, useCallback} from 'react';
import * as css from './Resize.css';
import {ThemeProvider} from 'styled-components';

function Resize({Horizontal,width,widthChild,resize,isReduce=0}) {
    const isDragging = useRef(false);
    const dragHeadRef = useRef();
    const [position, setPosition] = useState(0);
    // const [previousTouch, setPreviousTouch] = useState();

    const onMouseDown = useCallback(e => {
        if(dragHeadRef.current && dragHeadRef.current.contains(e.target)) {
            isDragging.current = true;
        }
    }, []);

    const onMouseUp = useCallback(() => {
        if(isDragging.current) {
            isDragging.current = false;
        }
    }, []);
    let previousTouch=null;
    const onMouseMove = useCallback(e => {
        if(isDragging.current) {
            // setPosition(position => (position + e.movementX) <-250?-250:(position + e.movementX)>0?0: position + e.movementX);
            // updatePosition(e.movementX*100/width)
            if(!e.targetTouches){
                updatePosition((Horizontal?e.movementX:e.movementY*-1)*100/widthChild);
            }
            else {
                const touch = e.touches[0];
                if(previousTouch) {
                    // be aware that these only store the movement of the first touch in the touches array

                    e.movementY = touch.pageY - previousTouch.pageY;
                    e.movementX = touch.pageX - previousTouch.pageX;
                    updatePosition((Horizontal?e.movementX:e.movementY*-1)*100/widthChild);

                }

                previousTouch=touch;

            }

        }
    }, []);
    const updatePosition=(val)=>{
        setPosition(prevPosition => Math.min(Math.max(prevPosition + val, -100), isReduce?50:0));
    };
    const dragPosition=isReduce?position/-1.5+33.333:position*-1;
    useEffect(() => {
        resize(position*-1);
    },[position]);

    useEffect(() => {
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('mousedown', onMouseDown);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('touchend', onMouseUp);
        document.addEventListener('touchstart', onMouseDown);
        document.addEventListener('touchmove', onMouseMove);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousedown', onMouseDown);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('touchend', onMouseUp);
            document.removeEventListener('touchstart', onMouseDown);
            document.removeEventListener('touchmove', onMouseMove);

        };
    }, [onMouseMove, onMouseDown, onMouseUp]);
    return (
        <ThemeProvider theme={{Horizontal:Horizontal}}>
            <css.Resize width={width}>
                <css.ResizeBtn onClick={()=>updatePosition(10)}>-</css.ResizeBtn>
                <css.ResizeBase width={widthChild}>
                    <css.ResizeProgress position={dragPosition}/>
                    <css.ResizeDrag ref={dragHeadRef} position={dragPosition} />
                </css.ResizeBase>
                <css.ResizeBtn className={'plus'} onClick={()=>updatePosition(-10)}>+</css.ResizeBtn>
            </css.Resize>
        </ThemeProvider>
    );

}

export default Resize;
