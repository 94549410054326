import styled from 'styled-components/macro';
import {BGImageCenter, WHITE, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {wrapper as eyeWrapper} from '../../../components/eyeHideSection/EyeHideSection.css';
import {BRANDING_PAGE_WIDTH} from '../../BrandingPage.css';

export const wrapper=styled.div`
    font-size: 14px;
    width: ${BRANDING_PAGE_WIDTH};
    display: flex;
    max-width: 100%;
    margin-top:15px;
    background-color: ${WHITE};
    border-radius: 10px;
    padding-inline-start:15px;
    padding-block: 15px;
    img{
        width: 130px;
        border-radius: 10px;
        height: 130px;
        object-fit: cover;
    }
    ${mobileMedia}{
        zoom:0.7
    }
`;

export const left=styled.div`
    padding-inline-start: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 130px);
    ${eyeWrapper}{
        width: 100%;
    }
`;
export const guide=styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-inline-end: 30px;
    margin-block: 5px;
`;
export const guideImage=styled.div`
    margin-inline-end: 10px;
    width: 30px;
    height: 30px;
    ${p=>BGImageCenter(p.image,'35px')}
    border-radius: 50%;
`;
export const title=styled.div`
    font-size:18px;
    font-weight:700;
`;
export const details=styled.div`
    display: flex;
    align-items: center;

`;
export const rightText=styled.div`
    display: flex;
    width: 100px;
`;
export const icon=styled.div`
    width: 15px;    
    height: 20px;    
    margin-inline: 10px 9px;    
    &.location{
        margin-inline:0 3px ;
    }        
    ${p=>BGImageCenter(p.icon,'15px')}
`;

