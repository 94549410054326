import styled from 'styled-components/macro';
import {wrapInput,title} from '../basicInput/BasicInput.css';

export const container=styled.div`
    position:relative;
    width:100%;
    ${wrapInput}{
        height:${p=>p.height} ;
    }
    ${title}{
        &.empty{
            bottom:${p=>p.height};
        }
    }

`;

export const charNum=styled.div`
    position:absolute ;
    bottom:22px;
    inset-inline-start:-13px;
    font-size:9px ;
`;