import styled, {css} from 'styled-components/macro';
import {pen} from '../../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, ellipsis, tabletMedia} from '../../../../../globalVariables/GlobalVariables';

export const cssLeadColumns=css`
    .defaultColumn{
        width: 9%;
        min-width: 75px;
    }
    .title{
        width: calc(25% - 120px);
        min-width: 100px;
    }
    .contacts{
        width: calc(30% - 130px);
        min-width: 130px;
    }
    .status{
        min-width: 70px;     
        width: 120px;   
    }
    .lastColumn{
        width: 120px;
    }
    .firstColumn{
        position: unset;
    }
`;

export const detail = styled.div`
    text-align: center;
    ${tabletMedia}{
        flex-shrink: 0;
    }

`;
export const text = styled.div`
    ${ellipsis}
    position: relative;
    padding-inline-end: 8px;
    :hover{
        &.isEdit{
            cursor: pointer;
            ::after{
                content: "";
                position: absolute;
                width: 20px; 
                height: 20px;
                ${BGImageCenter(pen,'10px')}
                transition: all 300ms;
                inset-inline-end: 0;
            }
        }
    }
`;

export const boldText = styled(text)`
    font-weight: 600;
    padding-bottom: 2px;
`;
