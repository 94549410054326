import styled from 'styled-components/macro';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';

export const moreOperation= styled.div`
    background-image:url("${symbol['op']}");
    background-position:center center;
    background-repeat:no-repeat;
    cursor:pointer;
    position: relative;
    width: 20px;
    height: 18px;
    top: -2px;
`;