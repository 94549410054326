import {useEffect} from 'react';
import {useState} from 'react';
import {useRef} from 'react';
import {Close} from '../../close/Close.css';
import * as css from './SmallPopUp.css';

function SmallPopUp({children, close, showClass,top,size}) {
    const ref = useRef(null);
    const [left,setLeft]=useState(null);
    const [bottom,setBottom]=useState(null);

    useEffect(()=>{
        setImmediate(() => {
            if(left!==20&&ref.current?.getBoundingClientRect().x<10)
                setLeft(20);
            if(bottom!==20&&(ref.current?.getBoundingClientRect().bottom)>window.innerHeight)
                setBottom(20);
        });      
    },[]);

    return (
        <>
            <css.popUpBody ref={ref} className={showClass} top={top} bottom={bottom} size={size}>
                <css.wrapClose>
                    <Close onClick={close} />
                </css.wrapClose>
                {children[1] || children}
            </css.popUpBody>
        </>
    );
}

export default SmallPopUp;
