import styled from 'styled-components/macro';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';

export const packageSettings=styled.div`
    width:55px;
    height:50px;
    background-repeat:no-repeat;
    background-position:center center;
    cursor: pointer;
    position:relative;  
    &.noAccountRole{
        visibility: hidden;
    }  
    @media screen and (max-width: 1000px) {
         ${props => (props.theme.rtl ? 'border-right:0;' : 'border-left:0')};
    }
    .itemIcon{
        background-size:15px ;
    }
    
`;

export const packageIcon= styled.div`
    position:relative;  
    background-image:url("${symbol['home']}");
    background-size: 20px 20px;
    background-repeat:no-repeat;
    background-position:center center;
  width:55px;
  height:50px;
    // ${props => (props.theme.rtl ? 'border-right:1px solid #cccccc;' : 'border-left:1px solid #cccccc;')};
    :hover{
        ::after{
          left: 35px;
        }
    }
`;

export const unverifiedAccount= styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #DD2F21;
    ${BGImageCenter(symbol.error, '3px 8px')}
    position: absolute;
    top: 8px;
    inset-inline-start: 11px;
`;

