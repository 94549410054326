import createReducer from '../createReducer';

let user = JSON.parse(localStorage.getItem('user'));
let exp = JSON.parse(localStorage.getItem('exp'));
const initialState = {
    isLoggedIn:!!user,
    exp: exp?exp:0,
    message:'',
    token:null,
    socket: {}
};

const reducer = createReducer('auth',initialState);

export default reducer;