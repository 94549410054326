import styled from 'styled-components/macro';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {optionsIcon} from '../../memberOption/MemberOption.css';
import {role} from '../../memberRoleBtn/MemberRoleBtn.css';
import {Template} from '../../../../component/templates/Template.css';

export const container=styled.div`
  ${Template('250px')}
  display: flex;
  border-radius: 5%;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 15px;
  ${role}{
  max-width: 90%;
  background-color: #F3F3F3;
  width: 70%;
  height: 15px;
  border-radius: 25px;
  opacity: 0.5;
  }
`;
export const option=styled.div` 
  width:20px;
  height:20px;
  position: relative;
  margin-top: 5px;
  opacity: 1;
  ${optionsIcon}{
    transform: rotate(90deg);
  }
`;

export const image=styled.div`
transition: background-color 400ms;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #F3F3F3;
  opacity:0.5;
  ${mobileMedia}{
  width: 70px;
  height: 70px;
  opacity:0.5;
  }
`;
export const name=styled.div`
  margin: 5px 0;
  max-width: 80%;
  background-color: #F3F3F3;
  width: 90%;
  height: 15px;
  border-radius: 25px;
  opacity: 0.5;
`;