import * as css from './GridDrag.css';
import React, {useState} from 'react';
import {DndContext,closestCenter,MouseSensor,TouchSensor,DragOverlay,useSensor,useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, rectSortingStrategy} from '@dnd-kit/sortable';
import SortableItem from '../gridItemDrag/SortableItem.js';
import GridItemDrag from '../gridItemDrag/GridItemDrag.js';

function GridDrag  ({items, onDragEnd, Comp, getProps=()=>{}, minSize='300px', undraggableChildren, idName}) {
    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const handleDragStart = (event) => {
        setActiveId(event.active.id);
    };
    
    const handleDragEnd = (event) => {
        const {active, over} = event;
        if(active.id !== over?.id) {
            const oldIndex = items.findIndex(i=>i[idName]==active.id);
            const newIndex = items.findIndex(i=>i[idName]==over.id);    
            const newItems= arrayMove(items, oldIndex, newIndex);
            onDragEnd(newItems);
        }
        setActiveId(null);
    };

    const handleDragCancel = () => {
        setActiveId(null);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={items.map(i=>i[idName])} strategy={rectSortingStrategy}>
                <css.gridWrapper minSize={minSize}>                    
                    {items.map((item,index) =>
                        <SortableItem key={item[idName]} id={item[idName]} zIndex={(items.length-index)+1}>
                            <Comp {...getProps(item)}/>
                        </SortableItem>
                    )}
                    {undraggableChildren}
                </css.gridWrapper>
            </SortableContext>
            <DragOverlay adjustScale style={{transformOrigin: '0 0 '}}>
                {activeId ? <GridItemDrag id={activeId} isFloatingDragging >
                    <Comp {...getProps(items.find(i=>i[idName]===activeId))}/>
                </GridItemDrag> : null}
            </DragOverlay>
        </DndContext>
    );
}

export default GridDrag;
