import styled from 'styled-components/macro';
import {mobileMedia} from '../../../../../../globalVariables/GlobalVariables';
import {getColor} from '../../../../eventRealtime/EventRealtime.css';
export const header = styled.div`
    display: flex;
    width: 100%;
    ${mobileMedia}{
      display: none;
    }
`;
export const ticketName = styled.div`
    width: 30%;
    padding-inline-start: 30px;
    text-align: center;
    color: ${p=>getColor(p.theme.isDarkMode)};

`;

export const item = styled.div`
    width: 12%;
    text-align: center;
    color: ${p=>getColor(p.theme.isDarkMode)};

`;
