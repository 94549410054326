import {useTranslation} from 'react-i18next';
import * as css from './TitleStep.css';
import Input from '../../../../component/input/Input';
import {getEventArtistAutomaton, updateEvent} from '../../../../redux/event/eventActions';
import {useFormikContext} from 'formik';
import {useEffect} from 'react';
import setGlobalState from '../../../../redux/setGlobalState';
export const getArtistsByText=async(text,form)=>{
    await getEventArtistAutomaton(text).then(({artists})=>{
        if(artists?.length){
            const {setValue} = form.getFieldHelpers('artists');
            const newArtists=artists.filter(artist=>!form.values.artists.some(a=>a.artistId===artist.artistId));
            setValue([...form.values.artists,...newArtists]);
            let artistsId =  newArtists.map(artist => artist.artistId);
            setGlobalState('ui.artists', artistsId);
        }
    }).catch(()=>{});
};

export const onClickTitleStep=async (changedValues,value,form)=>{
    if(changedValues.title){
        await updateEvent(changedValues);
        getArtistsByText(value,form).then();

    }
};

function TitleStep({text,description}) {
    const {t} = useTranslation('newEvent');
    const {validateForm}=useFormikContext();
    
    useEffect(validateForm,[]);
    return (
        <css.wrapper  >
            <Input name={'title'} text={t(text||'eventTitle')} description={t(description||'eventDescription')} data-cy="enterEventName" />
        </css.wrapper>
    ); 
}

export default TitleStep;
