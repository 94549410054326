import {useEffect, useState} from 'react';

export default function useScrollDirection(refToScroll){

    const [scrollDir, setScrollDir] = useState('down');

    const updateScrollDir = () => {
        let newDir = refToScroll.current.scrollTop===0?'down':'up';
        if(newDir!==scrollDir){
            setScrollDir(newDir);
        }
    };
    useEffect(() => {
        refToScroll.current?.addEventListener('scroll', updateScrollDir);
        return () =>refToScroll.current?.removeEventListener('scroll', updateScrollDir);
    }, [scrollDir]);

    return scrollDir;
}