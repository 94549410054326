import * as css from './UpgradePopup.css';
// import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import BottomPopup from '../bottomPopup/BottomPopup';
import {upgradePackage} from '../../../../assets/symbols/images/Images.css';
import {updateAccountPermission} from '../../../../redux/account/accountActions';
export const permissionToCamelCase = (str = '') => {
    let permString = str.replace(/(?:_| |\b)(\w)/g, function (str, p1) { return p1.toUpperCase(); });
    permString = permString.charAt(0).toLowerCase() + permString.substring(1);
    permString = permString.trim();
    return permString;
};

function UpgradePopup({close, showClass, index}) {

    //Note: this file has comment that Should be used in the future
    const {t} = useTranslation('package');
    const tPer = useTranslation('permission');

    const permission = useSelector(state => state.account.permissions[index]);
    // const pid = useSelector(state => state.account.pid);
    const permString = permissionToCamelCase(permission.permission);
    // const [isChecked, setIsChecked] = useState(false);

    const acceptClick = async () => {
        // if (isChecked) {
        let status =1;
        await updateAccountPermission(permission.permission, status, index);
        close();
        // }
    };

    return (<>
        <BottomPopup icon={upgradePackage} close={close} showClass={showClass} disabledAcceptBtn={false} headerText={t('funToUpgrade')} acceptClick={acceptClick}>
            <css.body>
                <css.left>
                    <css.leftPriceWrap>
                        <css.leftPrice>{tPer.t('upgradeTitle')}</css.leftPrice>
                        <css.leftPerMonth>{tPer.t('upgradeText')}</css.leftPerMonth>
                    </css.leftPriceWrap>
                    {false && <css.leftOffice>
                        <css.commentOffice>{t('officeNotes')}</css.commentOffice>
                        <css.comment>{ }</css.comment>
                    </css.leftOffice>}
                </css.left>
                <css.right>
                    <css.rightHeader>
                        <css.rightTitle>{tPer.t(permString)}</css.rightTitle>
                        <css.rightId>{ }</css.rightId>
                    </css.rightHeader>
                    <css.rightText>{tPer.t(`${permString}Long`)}</css.rightText>
                    <css.rightCheckbox>
                        {/* <Checkbox onClick={setIsChecked}></Checkbox>
                        <css.rightCBText>{t("acceptCharge")}</css.rightCBText> */}
                    </css.rightCheckbox>
                </css.right>
            </css.body>
        </BottomPopup>
    </>
    );
}

export default UpgradePopup;

