import AvatarEditor from 'react-avatar-editor';
import * as css from './ImageEditor.css';
import Resize from '../resize/Resize';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Btn from '../button/btn/Btn';
import {greenV, redEx} from '../../assets/symbols/symbols/Symbols.css';
import SmallPopUp from '../newPopup/smallPopUp/SmallPopUp';
import {Else, If, Then} from 'react-if';
import Animation from '../animation/Animation';
import tableLoader from '../../assets/animations/tableLoader.json';
import {useSelector} from 'react-redux';
import {bounceMessage} from '../../layout/userMessages/UserMessage';

function ImageEditor(props) {
    const {t} = useTranslation('global');
    const layout = useTranslation('layout');
    const [scale, setScale] = useState(1);
    const isShowAnimate = useSelector(s=>s.ui.editImage.isShowAnimate);
    var newImage =(typeof props.image === 'string')?[props.image?.slice(0, 8), 's3.eu-central-1.amazonaws.com/', props.image?.slice(8)].join(''):props.image;

    const updateScale = (size) => {
        setScale(1 + (size / 100));
    };

    const refEditor = useRef();
    const save = async () => {
        const origCanvas = refEditor.current.getImage();
        const newCanvas = document.createElement('canvas');
        newCanvas.width = origCanvas.width;
        newCanvas.height = origCanvas.height;
        const ctx = newCanvas.getContext('2d');
        ctx.drawImage(origCanvas, 0, 0);
    
        let quality =1;
        let dataSize = 0;
        let reducedSizeImage;
    
        const limit =(props.limitMB||1)* 1024 * 1024;
        do {
            reducedSizeImage = newCanvas.toDataURL(props.type||'image/webp', quality);
            dataSize = atob(reducedSizeImage.split(',')[1]).length;
            quality -= 0.1;
        } while(dataSize > limit && quality > 0); 
        if(dataSize <= limit) {
            props.save(reducedSizeImage);
        }
        else {
            bounceMessage(layout.t('upLoudImageWrong'),'warning');
        }
    };

    const closePopup = ()=>{props.setUploadedImage('');props.openEditImage.close();};

    const getButtons = () =>
        <>
            <css.wrapBtn vertical={props.vertical}>
                <Btn className={'white'} onClick={e => { e.stopPropagation();props.setUploadedImage('');props.openEditImage.close();}}>
                    {t('delete2')}
                    <css.ImageEditorBtn icon={redEx}/>
                </Btn> 
            </css.wrapBtn>
            <Btn onClick={e => { e.stopPropagation(); save(); props.openEditImage.close();}}>
                {t('save')}
                <css.ImageEditorBtn icon={greenV}/>
            </Btn>

        </>;
    if(!props.openEditImage.isOpen)
        return (<></>);
    const isReduce=['image/png','image/webp'].includes(props.image.type) && (!props.type || ['image/png','image/webp'].includes(props.type)  );
    return (
        <>
            <SmallPopUp {...props.openEditImage} close={closePopup} top={props.top} size = {props.size}>
                <css.wrapEditor>
                    <css.ImageEditor className={'imageEditor ' + props.className} vertical={props.vertical} data-cy ="imageEditor">
                        {props.vertical && <Resize width={'calc(50% - ' + (props.width+40) / 2 + 'px)'} widthChild={props.width - 40} resize={updateScale} isReduce={isReduce} />}
                        <css.AvatarEditor width={props.width} height={props.height} radius={props.borderRadiusAfter} image={newImage}>
                            <If condition={isShowAnimate}>
                                <Then>
                                    <css.imageLoader >
                                        <Animation animation={tableLoader} />
                                    </css.imageLoader>
                                </Then>
                                <Else>
                                    <AvatarEditor
                                        ref={refEditor}
                                        className="canvas"
                                        image={newImage}
                                        width={props.width}
                                        height={props.height}
                                        border={props.border?props.border:(props.border!=null?props.border:10)}
                                        color={[255, 255, 255, 0.8]} //RGBA
                                        scale={scale}
                                        rotate={0}
                                        borderRadius={props.borderRadius}
                                        disableHiDPIScaling={true}
                                        crossOrigin={'anonymous'}
                                    />
                                </Else>
                            </If>
                        </css.AvatarEditor>
                        {props.vertical ?
                            <css.ImageEditorBtns vertical={1} width={'calc(50% - ' + (props.width+10) / 2 + 'px)'}>
                                {getButtons()}
                            </css.ImageEditorBtns>
                            : <css.ImageEditorBot>
                                <Resize Horizontal={1} width={props.width+20 + 'px'} widthChild={props.width - 60} resize={updateScale} isReduce={isReduce} />
                                <css.ImageEditorBtns >
                                    {getButtons()}
                                </css.ImageEditorBtns>
                            </css.ImageEditorBot>}                     
                    </css.ImageEditor>
                </css.wrapEditor>
            </SmallPopUp>
        </>
    );
}

export default ImageEditor;
