import {useTranslation} from 'react-i18next';
import {useField, useFormikContext,} from 'formik';
import Btn from '../../../../../component/button/btn/Btn';
import {BUTTON_HEIGHT, mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import {bounceMessage} from '../../../../../layout/userMessages/UserMessage';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import useTimeout from '../../../../../hooks/useTimeout';

function DraftButton({close}) {
    const {t} = useTranslation('ticket');
    const form=useFormikContext();
    const [,,{setValue}]=useField('draft');
    const type=useSelector(s=>s.event.type);
    const [shouldClose,setShouldClose]=useState(false);
    const tickets = useSelector(s => s.tickets);
    const timeout=useTimeout();

    useEffect(() => {
        if(shouldClose&&[tickets.addTicketXHR,tickets.updateTicketXHR].includes('SUCCESS'))
            timeout.current=setTimeout(close, 1000);
    }, [tickets.addTicketXHR,tickets.updateTicketXHR]);

    if(type!=='course')
        return <></>;

    const onSave=()=>{
        setValue(1);
        setImmediate(form.submitForm);
        setShouldClose(true);
    };
    
    const onClick=()=>{
        bounceMessage(t('draftMessage'),undefined,{onClick:onSave,text:t('saveAsDraft')},{text:t('cancel')});     
    };

    return (
        <Btn onClick={onClick} style={`position: absolute; inset-inline-end: 10px; top: calc(50% - ${BUTTON_HEIGHT/2}px);${mobileMedia}{top:75px;}`}>
            {t('saveAsDraft')}
        </Btn>
    );
}

export default DraftButton;
