import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
const useErrorMessages=()=>{
    const {t} = useTranslation('global');
    Yup.setLocale({
        mixed: {
            required: t('errorRequired'),
            notType: t('errorNumber'),

        },
        string: {
            max:  t('errorLength'),
            email:t('emailError'),
            matches:t('errorTemplate'),
            min: t('errorCharacters'),
            required: t('errorRequired')
        },
        array: {
            min:  t('errorRequired')
        },
    });
};
export default useErrorMessages;