import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SMSCode, {resetCodeInput, updateExpiredTime} from '../../../../component/SMSCode/SMSCode';
import {signUp, getTokenToSignUp} from '../../../../redux/user/userActions';
import * as css from './Code.css';
import {useField, useFormikContext} from 'formik';
import setGlobalState from '../../../../redux/setGlobalState';
import LoginByGoogle from '../../components/loginByBtn/LoginByGoogle';
import LoginByBtn from '../../components/loginByBtn/LoginByBtn';
import obscureEmail from '../../../../functions/ObscureEmail';
import {useHistory} from 'react-router-dom';
import Animation from '../../../../component/animation/Animation';
import loader from '../../../../assets/animations/loader.json';
import {useEffect, useRef} from 'react';
import {ClASS_DOMAIN, TICK_DOMAIN} from '../../../../config/config';
import {onSignUp} from '../signUpForm/SignUpForm';
import LoginButton from '../../components/loginButton/LoginButton';
import {setCurrentRating} from '../../../components/rating/Rating';
import {getRatings} from '../../../../redux/ratings/ratingsActions';

function Code({onBack}) {
    const {t} = useTranslation('login');
    const globalT = useTranslation('global').t;
    const values=useFormikContext().values;
    const errorMessage=useSelector(s=>s.auth.message);
    const [emailField]=useField('email');
    const [phoneField]=useField('phone');
    const [{value:sendTo},,sendToHelper]=useField('sendTo');
    const form=useFormikContext();
    const history=useHistory();
    const isMissingPhone = useSelector(s=>s.ui.login.isMissingPhone);  

    let params = new URLSearchParams(location.search);
    const packageType = params.get('packageType');
    const source = params.get('source');

    const {signUpXHR,getTokenToSignUpXHR}=useSelector(s=>s.user);

    const onUpdate=async(code) => {
        if(signUpXHR !== 'LOADING'){
            const res=await signUp(form.values,code,packageType);
            if(res?.user){
                if(window.parent !== window.self){
                    window.parent.postMessage({buttonClick: 'signUp'},source&&source==='classchak'? ClASS_DOMAIN: TICK_DOMAIN);
                }
                history.push(`/p/${res.pid}`);
                await getRatings().then(()=>{
                    setCurrentRating('createUser');
                });
            }
        }
    };
    
    const timer=useRef(null);

    useEffect(()=>{
        return ()=>{
            sendToHelper.setValue('phone');
            resetMessage();
            clearTimeout(timer.current);
        };},[]);

    const resetMessage=()=>{
        setGlobalState('user.signUpXHR',null);
        setGlobalState('auth.message',null);
    };
    useEffect(()=>{
        clearTimeout(timer.current);
        if(signUpXHR==='FAILED'){
            timer.current=setTimeout(resetMessage, 3500);
        }
        else resetMessage();
    },[signUpXHR==='FAILED']);
    
    const sendAgainClick=async () => {
        await getTokenToSignUp(values);
    };

    const sendToEmailClick=async ()=>{
        resetCodeInput(true);
        const value=sendTo==='email'?'phone':'email';
        sendToHelper.setValue(value);
        await getTokenToSignUp({...values,sendTo:value});
        updateExpiredTime();
    };

    const [title,type,buttonText]=sendTo==='email'?
        ['message','email',t('sendMeCode')]:
        ['sms','phone',`${t('sendEmail')} ${obscureEmail(emailField.value)}`];

    return (
        <css.container>
            <css.title>{t`sendSMS`+t(title)}
                <css.text>{t('lookPhone',{sendTo:t(type)})}</css.text>
            </css.title>                
            <SMSCode onUpdate={onUpdate} sendAgainClick={sendAgainClick} address={sendTo==='email'?obscureEmail(emailField.value):phoneField.value}/>
            {[signUpXHR,getTokenToSignUpXHR].includes('LOADING')&&
            <css.animationWrap>
                <Animation animation={loader}  />
            </css.animationWrap>}
            <css.bottom>
                <LoginButton text={t('return')} errorText={`${errorMessage}, ${globalT('sendAgain')}`} className={signUpXHR} onClick={errorMessage?sendAgainClick:onBack} buttonType="goBack"/>
                <LoginByBtn onClick={sendToEmailClick} text={buttonText}  className='password'/>
                { !isMissingPhone && <LoginByGoogle text={'signup_with'} callback={(response)=>onSignUp(response,history,form)}/>}
            </css.bottom>
        </css.container>
    );
}

export default Code;
