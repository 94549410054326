import styled from 'styled-components/macro';
import {BGImageCenter,scrollbar, dFlexCenter, tabletMedia} from '../../../globalVariables/GlobalVariables';
import {filter} from '../../../assets/symbols/symbols/Symbols.css';

const RIGHT_WIDTH = '50px';

export const wrapper = styled.div`
    ${p=>p.cssColumns}
    position: relative;
    height: 70px;
    margin-bottom: 30px;
    display: flex;
    box-shadow: 0px 1px 4px #00000029;
`;
export const right = styled.div`
   width: ${RIGHT_WIDTH};    
   min-width: ${RIGHT_WIDTH};    
   border-inline-end: 1px solid #00000029;
   ${BGImageCenter(filter,'15px')};
   height: 80%;
   top: 10%;
   position: absolute;
`;

export const left = styled.div`
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   ${scrollbar}
   ${tabletMedia}{
        margin-inline-end:40px;
        gap: 10px;
    }
`;

export const lastColumn = styled.div`
    height: 100%;
    ${dFlexCenter}
`;