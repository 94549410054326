import request from '../../library/request';
import store from '../../store';
import setGlobalState from '../setGlobalState';

export const getTextParamsDefaults = async (fields) => {
    const res = await request('mailing.getTextParamsDefaults', 'get', '/mailing/textParamsDefaults/', fields);
    setGlobalState('mailing.parameters', res);
};

export const buyMailingPackage = async (mpid) => {
    const pid = store.getState().account.pid;
    const billPackage = store.getState().account.billPackage;
    const res = await request('mailing.buyMailingPackage', 'post', `package/${pid}/buyMailing/${mpid}`);
    if(res.billPackage && res.mailingPackage){
        setGlobalState('account.billPackage', {...billPackage, ...res.billPackage});
        setGlobalState('account.mailingPackage', res.mailingPackage);
    }
};

export const sendMailing = async (fields) => {
    await request('mailing.sendMailing', 'post', '/mailing/sendMailing/', fields);
};