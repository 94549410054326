import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';

const DEFAULT_COLOR = '#76D6E8';

export const wrap = styled.div`
    position:relative ;
    width: max-content;
    height: 27px;
    border-radius: 22px;
    border: 1px solid #747474;
    padding: 3px;
    display: flex;
    align-items: center;
    background-color: #EEE;
    cursor: pointer;
    transition:all 200ms;
    margin:5px;
    padding-inline-end: 14px;
    :hover{
        border: 1px solid #76D6E8;
    }
`;
export const icon = styled.div`
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${p => p.color || DEFAULT_COLOR};
    margin-inline-end:10px;
    :after{
            content:"${props => props.number && props.number}";
            ${dFlexCenter};
            color: #000;
            font-size: 14px;
            height:100% ;
            ${p => BGImageCenter(symbol[p.icon], '12px')}
        }
`;

export const wrapIcon = styled.div`
    width: 21px;
    height: 21px;
    margin-inline-end: 10px;
   
`;