import styled from 'styled-components/macro';

export const Tabs=styled.div`
    display:flex;
    align-items:center;
    position:relative;
    .TabChild{
        border-bottom:3px solid #E6E6E6;
        margin: 0 5px;
        padding: 2px 5px;
        cursor:pointer;
        &.current{
            border-bottom:3px solid #01AAFF;
            font-weight:600;
        }
    }
`;
export const TabMore=styled.div`
    background-repeat:no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %233a4143; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cg id='Component_154_1' data-name='Component 154 – 1' transform='translate(1.406 1.406)'%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-233.982 -21)'/%3E%3C/g%3E%3C/svg%3E%0A");
    width: 25px;
    height: 25px;
    background-position: center center;
    cursor: pointer;
`;
export const tabChild=styled.div``;