export   const  shareLink = async (title, text,url)=>{
    if(navigator.share) {
        await navigator.share({
            title: title,
            text:text,
            url: url,
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error in sharing', error));
    }
};