import React from 'react';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import setGlobalState from './redux/setGlobalState';

function ProtectedRoute({component: Component, ...restOfProps}) {
    const history = useHistory();
    const isAuthenticated = useSelector(s=>s.auth.isLoggedIn);
    return (
        <Route
            {...restOfProps}
            render={(props) => {
                if(isAuthenticated)
                    return Component?<Component {...props} />: <Redirect to={restOfProps.path+restOfProps.to}/>;
                else {
                    setGlobalState('ui.login.isPrevPath',true);
                    history.push(restOfProps.path);
                    return <Redirect to={{pathname:'/Login'}}/>;
                }
            }
            }
        />
    );
}

export default ProtectedRoute;