import * as css from "./BtnAdd.css";
import BtnPlus from "../btnPlus/BtnPlus";

function BtnAdd({ onClick, text, state }) {
  return (
    <css.buttonNo onClick={onClick} data-cy="mainPageAddSalesPage">
      <BtnPlus hasText={false} state={state} onClick={onClick} />
      <css.buttonNoText>{text}</css.buttonNoText>
    </css.buttonNo>
  );
}
export default BtnAdd;
