import {useHistory} from 'react-router-dom';
import SignUpForm from './signUpForm/SignUpForm';
import {useState} from 'react';
import {Case, Switch} from 'react-if';
import Code from './code/Code';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {Form, Formik} from 'formik';
import validator from 'validator';
import Stage from '../components/stage/Stage';
import {getTokenToSignUp} from '../../../redux/user/userActions';
import useErrorMessages from '../../../hooks/useErrorMessages';
import Navigation from '../components/navigation/Navigation';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../redux/setGlobalState';
import {resetCodeInput, updateExpiredTime} from '../../../component/SMSCode/SMSCode';
import {useEffect} from 'react';
import {hotjar} from 'react-hotjar';
import {ClASS_DOMAIN, HOTJAR_ID, HOTJAR_SV, TICK_DOMAIN} from '../../../config/config';
import SupportComponent from '../../../layout/supportComponent/SupportComponent';
import PhoneScreen from './phoneScreen/PhoneScreen';
import {initPhoneNumber} from '../../../functions/intrernationalPhoneNumber';
import {yupStringMin} from '../../../functions/validationsYup';

export const isIframe=window.parent !== window.self;
let params = new URLSearchParams(location.search);
const source = params.get('source');
export const sendPostMessage=(message)=>{
    window.parent.postMessage({buttonClick: message},source&&source==='classchak'?ClASS_DOMAIN: TICK_DOMAIN);
};
function SignUp() {
    const [currentPage,setCurrentPage]=useState('form');
    const {t,i18n} = useTranslation('login');
    const history=useHistory();
    const user = useSelector(s => s.user);

    const isMissingPhone=useSelector(s=>s.ui.login.isMissingPhone);
    const {langloc} = useSelector((s) => s.user);

    useErrorMessages();

    const isAuthenticated = useSelector(s=>s.auth.isLoggedIn);

    useEffect(()=>{
        if(isMissingPhone){
            setCurrentPage('phone');
        }
    },[isMissingPhone]);

    useEffect(()=>{
        if(isAuthenticated){
            history.push('/');
            if(isIframe)
                sendPostMessage('signUp');
        }

        setGlobalState('ui.login.existingUserEmail',null);
        hotjar.initialize(parseInt(HOTJAR_ID), parseInt(HOTJAR_SV));
    },[]);

    const onSubmit =async (values) => {
        resetCodeInput();
        const res=await getTokenToSignUp(values);
        if(res?.isExistingUser){
            setGlobalState('ui.login.existingUserEmail',values.email);
            if(isIframe){
                sendPostMessage('signUp');
            }
            history.push('login');
            return;
        }
        setCurrentPage('code');
        updateExpiredTime();
    };
    const getTokenToSignUpXHR=useSelector(s=>s.user.getTokenToSignUpXHR);

    const codeInput=useSelector(s=>s.ui.codeInput);
    const onBack=()=>{
        const originalCodeInput={...codeInput};
        if(isMissingPhone)
            setCurrentPage('phone');
        else
            setCurrentPage('form');
        setImmediate(()=>setGlobalState('ui.codeInput',originalCodeInput));
    };

    const navClick=(index,setTouched)=>{
        if(index===0)
            onBack();
        if(index===1){
            if(getTokenToSignUpXHR==='UPDATED')
                setCurrentPage('code');
            else
                setTouched({nameLast:true,  nameFirst:true,email: true, phone:true,});
        }
    };
    
    useEffect(() => {
        i18n.changeLanguage(user.lang).then();
    }, [user.lang]);

    Yup.addMethod(Yup.mixed, 'phone', function(message) {
        return this.test('phone', message, function(value) {
            const country = this.resolve(Yup.ref('country'));
            const local = validator.isMobilePhoneLocales.find(local => local.includes(country));
            const phone = initPhoneNumber({nationalNumber: value, country: country});
            return validator.isMobilePhone(value || '', local) || validator.isMobilePhone('+'+phone.internationalNumber || '', local);
        });
    });

    const existingUserEmail=useSelector(s=>s.ui.login.existingUserEmail);

    return (
        <Formik
            initialValues={{
                nameLast:'',
                nameFirst:'',
                email:existingUserEmail||'',
                phone:'',
                country: langloc?.split('_')[1].toUpperCase() || 'IL',
                picture:null,
                sendTo:'phone'}}
            validationSchema={Yup.object().shape({
                nameLast:yupStringMin().max(30).required(),
                nameFirst:yupStringMin().max(30).required(),
                phone: Yup.string().phone(t('numberMessage')).required(),
                email: Yup.string().email().required(),
                password: yupStringMin(6,t('passwordMessage')),
            })}
            onSubmit={onSubmit}>
            {({setTouched,handleSubmit})=>
                <Form onKeyDown={e => {
                    if(e.key === 'Enter') 
                        handleSubmit();
                }}>
                    <Stage isHeightHeader >
                        <SupportComponent />
                        <Switch >
                            <Case condition={currentPage==='form'}>
                                <SignUpForm/>
                            </Case>
                            <Case condition={currentPage==='phone'}>
                                <PhoneScreen />
                            </Case>
                            <Case condition={currentPage==='code'}>
                                <Code onBack={onBack}/>
                            </Case>
                        </Switch>
                        <Navigation currentIndex={['form','phone'].includes(currentPage)?0:1} onClick={(index)=>navClick(index,setTouched)}/>
                    </Stage>
                </Form>                
            }
        </Formik>

    );
}

export default SignUp;
