import {initPhoneNumber} from '../../functions/intrernationalPhoneNumber';
import request from '../../library/request';
import store from '../../store';
import setGlobalState, {pushToGlobalState} from '../setGlobalState';
import {uploadUserImages} from '../user/userActions';

const getEid = () => store.getState().event.eid;
const getPid= () => store.getState().account.pid;

const getArgsByType=(type)=> type==='event'?['event',getEid(),'Event']:['package',getPid(),'Account'];

export const getEventTeam = async () => {
    const res = await request('team.getEventTeam', 'get', `/event/${getEid()}/teams`);
    let uniqueUsersMap = new Map();
    [...res.eventUsers,...res.packageUsers].forEach(user => {
        uniqueUsersMap.set(user.uid, user);
    });
    const team={...store.getState().team,
        members: Array.from(uniqueUsersMap.values()),
        eventMembers: res.eventUsers.map(m=>({uid:m.uid,role:m.role})),
        accountMembers: res.packageUsers.map(m=>({uid:m.uid,role:m.role})),
    };
    setGlobalState('team', team);
};

export const getAccountTeam = async () => {
    const res = await request('team.getAccountTeam', 'get', `/package/${getPid()}/teams`);
    if(!store.getState().team.members?.length)
        setGlobalState('team.members', res.packageUsers);
    setGlobalState('team.accountMembers', res.packageUsers.map(m=>({uid:m.uid,role:m.role})));
};

const updateTeamMember=async (type,values,uid)=>{
    if(values.country) {
        values.phone = initPhoneNumber({nationalNumber: values.phone, country: values.country}).internationalNumber;
    }
    const [path,id,capitalizeType]=getArgsByType(type);
    values = await uploadUserImages(values);
    const res = await request(`team.update${capitalizeType}TeamMember`, 'put', `/${path}/${id}/team/${uid}`,{...values});
    const team=store.getState().team;
    const index=team.members.findIndex(m=>m.uid===uid);
    const member=team.members[index];
    const info={...member.info,...(res.info||{})};
    const user=store.getState().user;
    setGlobalState(`team.members[${index}]`,{...member ,...res , info});
    const prevRoleIndex=team[`${type}Members`].findIndex(m=>m.uid===uid);
    if(team[`${type}Members`][prevRoleIndex].role!==res.role){
        setGlobalState(`team.${type}Members[${prevRoleIndex}].role`,res.role);

    }
    if(team['members'][index].picture!==values.picture){
        setGlobalState(`team.members[${index}].picture`,values.picture);
        if(user.uid===uid) {
            let updateUser = JSON.parse(localStorage.getItem('user'));
            updateUser.picture = values.picture;
            localStorage.setItem('user', JSON.stringify(updateUser));
            setGlobalState('user', updateUser);
        }
    }
};

export const updateEventTeamMember = async (values,uid) => {
    return await updateTeamMember('event',values,uid);
};

export const updateAccountTeamMember = async (values,uid) => {
    return await updateTeamMember('account',values,uid);
};
    
const createTeamMember=async (type='event',values)=>{
    const [path,id,capitalizeType]=getArgsByType(type);
    values = await uploadUserImages(values);
    const {teamMember} = await request(`team.create${capitalizeType}TeamMember`,'post',`/${path}/${id}/team`,values);
    pushToGlobalState(`team.${type}Members`,{uid:teamMember.uid,role:teamMember.role});
    if(!store.getState().team.members.some(m=>teamMember.uid===m.uid))
        pushToGlobalState('team.members',teamMember);
    return teamMember;
};

export const createEventTeamMember = async (values) => {  
    return await createTeamMember('event',values);
};

export const createAccountTeamMember = async (values) => {  
    return await createTeamMember('account',values);
};

const getTeamMemberInfo = async (type,uid=null) => {
    const [path,id,capitalizeType]=getArgsByType(type);
    const res = await request(`team.get${capitalizeType}TeamMemberInfo`, 'get', `${path}/${id}/userInfo/${uid}`);
    const index=store.getState().team.members.findIndex(m=>m.uid===uid);
    setGlobalState(`team.members[${index}].info`,res);
};

export const getEventTeamMemberInfo = async (uid) => {
    await getTeamMemberInfo('event',uid);
};

export const getAccountTeamMemberInfo = async (uid) => {
    await getTeamMemberInfo('account',uid);
};

export const removeMemberRole = async (uid,id,isAccountMember) => {
    await request('team.removeMemberRole', 'delete', `${isAccountMember?'package':'event'}/${id}/team/${uid}`);
    const key=isAccountMember?'accountMembers':'eventMembers';
    const allMembers=store.getState().team[key];
    const index = allMembers.findIndex(member => member.uid === uid);
    const members = [...allMembers];
    members.splice(index, 1);
    setGlobalState(`team.${key}`, members);

    store.getState().tickets.tickets?.forEach((ticket,i) => {
        const indexMember =  ticket.users?.findIndex(user => user.uid === uid);
        if(indexMember>-1){
            const ticketUsers  =[ ...ticket.users];
            ticketUsers.splice(indexMember,1);
            setGlobalState(`tickets.tickets[${i}].users`, ticketUsers);
        }
    });
    const report = store.getState().event.settings?.report;
    if(report){
        let updatedReport ={};    
        const removeUserFromReport=(key)=>{
            const index=report[key]?.findIndex(u=>`${u.uid}`===`${uid}`);
            if(index>-1)
                updatedReport[key]= [...report[key]?.slice(0, index), ...report[key]?.slice(index + 1)];
        };
        removeUserFromReport('users');
        removeUserFromReport('transactionUsers');
        if(Object.keys(updatedReport).length)
            setGlobalState('event.settings.report',{...report,...updatedReport});
    }
};

export const updateEventMemberStatus = async (uid,eid,status) => {
    const res = await request('team.updateEventMemberStatus', 'put', `/event/${eid}/team/${uid}/status`,{status});
    const index = store.getState().team.eventMembers.findIndex(member => member.uid === uid);
    setGlobalState(`team.eventMembers[${index}].status`, res.status?undefined:'inactive');
};

export const getUserDetails = async (email,phone) => {
    const res = await request('team.getUserDetails', 'get', `/event/${getEid()}/team`,{email,phone});
    return res.user;
};
export const reSendEmail = async (values) => {
    await request('team.reSendEmail', 'post', `/event/${getEid()}/team/${values.uid}/reSendMail`,values);
};
