import * as css from './PopOnPop.css';
import {useEffect} from 'react';
import useShow from '../../../hooks/useShow';
import WrapUseMouseUpOutside from '../../wrapUseMouseUpOutside/WrapUseMouseUpOutside';

function PopOnPop({topHeight = 110, children, title, image, showPopOnPop,dataCy=''}) {

    const openClose = useShow();
    function close() {
        showPopOnPop.setShow(0);
    }

    useEffect(() => {
        if(showPopOnPop?.show)
            setImmediate(openClose.open);
        else {
            openClose.close();
        }
    }, [showPopOnPop?.show]);

    if(!openClose.isOpen)
        return (
            <></>
        );

    return (
        <WrapUseMouseUpOutside callback={close}>{
            (ref)=>
                <  css.popOnPop className={openClose.showClass} ref={ref} data-cy = {`${dataCy}PopOnPop`}>
                    <css.popOnPopClose onClick={() => showPopOnPop.setShow(0)} />
                    <css.popOnPopContent>
                        <css.popOnPopTop height={topHeight}>
                            <css.popOnPopTitle>{title}</css.popOnPopTitle>
                            <css.popOnPopImage image={image} />
                            {children[0] && children[0]}
                        </css.popOnPopTop>
                        {children[1] || children}
                    </css.popOnPopContent>
                </css.popOnPop>
        }
        </WrapUseMouseUpOutside>

    );
}

export default PopOnPop;
