import {useSelector} from 'react-redux';
import {LIVE_DOMAIN} from '../../../config/config';
import TickchakLoaderXHR from '../../../component/tickchakLoader/TickchakLoaderXHR';
import {useEffect, useState} from 'react';
import zIndex from '../../../globalVariables/zIndex';
import setGlobalState from '../../../redux/setGlobalState';

function HubDesign() {

    const {landingPageUrl}=useSelector(s=>s.event);
    const [isLoading,setIsLoading]=useState(true);

    useEffect(() => {
        const handleReceiveMessage = (event) => {
            if(event.data) {
                setGlobalState('ui.isInlineMode', event.data.isInlineMode);
            }
        };

        window.addEventListener('message', handleReceiveMessage);
        return () => {
            window.removeEventListener('message', handleReceiveMessage);
        };
    }, []);

    return (
        <>
            <TickchakLoaderXHR 
                xhr={isLoading?'LOADING':null} 
                cssStyle={`position: absolute; padding: 40px; z-index:${zIndex.baseRaised};`}/>
            <iframe src={`${LIVE_DOMAIN}/${landingPageUrl}?inlineManagement=true`} width="100%" height="100%" onLoad={()=>setIsLoading(false)}/>
        </>
    );
}

export default HubDesign;