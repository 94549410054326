import * as css from './BankAdditionalVerification.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import MobilePopupModal from '../../../../component/newPopup/mobilePopup/MobilePopupModal';
import React from 'react';
import {useEffect} from 'react';
import Btn from '../../../../component/button/btn/Btn';
import SMSCode from '../../../../component/SMSCode/SMSCode';
import {getToken} from '../../../../redux/security/securityActions';
import {sendSmsVerificationBank, updateVerificationBank} from '../../../../redux/account/accountActions';

function BankAdditionalVerification({openPop}) {
    const {t} = useTranslation('package');
    const tSecurity = useTranslation('security');
    const security = useSelector(state => state.security);
    const bank=useSelector(s => s.account?.bank||[]);
    const [bankKey, setBankKey] = useState();
    const [step, setStep] = useState(1);
    const [confirmDetails, setConfirmDetails] = useState(false);
    const step2Close = step===2&&{close:()=>{
        setStep(1);
    }};
    useEffect(() => {
        setBankKey(bank?.bankNumber);
    }, [bank?.bankNumber]);
    const sendSms =()=>{
        sendSmsVerificationBank().then(()=>{
            setStep(2);
        });
    };
    const updateVerification =(code)=>{
        updateVerificationBank(code).then(()=>{
            setStep(3);
        });
    };
    return (
        <css.warper  step={step}>
            <MobilePopupModal {...openPop} {...step2Close} showClass={openPop.showClass} height={'650px'} >
                <css.content  step={step}>
                    <css.contentIn>
                        {step===1&&<css.stepOne>
                            <css.title>{t('preciousMoney')}</css.title>
                            <css.subTitle>{t('bankVerificationDetails')}</css.subTitle>
                            <css.details>

                                <css.item>{t('companyName')}&nbsp;<span>{bank.billingName}</span> </css.item>
                                <css.item>{t('idBn')}&nbsp;<span>{bank.billingId}</span> </css.item>
                                <css.item>{t('accountOwner')}&nbsp;<span>{bank.bankTransferOnBehalf}</span> </css.item>

                                <css.item>{t('bank')}&nbsp;<span>{bankKey}</span> </css.item>
                                <css.item>{t('branch')}&nbsp;<span>{bank.bankBranch}</span> </css.item>
                                <css.item>{t('bankAccount')}&nbsp;<span>{bank.bankAccount}</span> </css.item>
                            </css.details>
                            <css.confirm state={confirmDetails} onClick={()=>setConfirmDetails(!confirmDetails)}>{t('customerConfirmation')}</css.confirm>
                            <css.actions><Btn  className={'white'} onClick={openPop.close}>{t('changeDetails')}</Btn><Btn disabled={!confirmDetails}  onClick={sendSms} >{t('finishVerification')}</Btn></css.actions>

                        </css.stepOne>}
                        {step===2&& <css.stepTwo>
                            <css.securityTitle>{tSecurity.t('securityTitle')}</css.securityTitle>
                            <css.smsCode>
                                <SMSCode  onUpdate={updateVerification} sendAgainClick={isVoiceCode => getToken(security.permission, isVoiceCode)} text={tSecurity.t('verificationCode')} />
                            </css.smsCode>
                            <Btn   onClick={()=>setStep(1)}>{tSecurity.t('btn2')}</Btn>
                        </css.stepTwo>
                        }
                        {step===3&&<css.stepThree>
                            <css.nowTitle>{t('nowCalm')}</css.nowTitle>
                            <Btn onClick={openPop.close}>{t('continueWork')}</Btn>
                            <css.image />

                        </css.stepThree>
                        }
                    </css.contentIn>
                </css.content>
            </MobilePopupModal>
        </css.warper>
    );
}

export default BankAdditionalVerification;