import styled from 'styled-components/macro';
import {BGImageCenter, boxShadow, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {blueArrow} from '../../../assets/symbols/symbols/Symbols.css';
import {tooltip} from '../../tooltip/tooltipAbsolute/TooltipAbsolute.css';
import zIndex from '../../../globalVariables/zIndex';

export const button=styled.div`
  ${boxShadow};
  z-index: ${zIndex.baseRaised};
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  position: absolute;
  inset-inline-end: 16px;
  bottom: 75px;
   
  ${mobileMedia}{
    width: 35px;
    height: 35px;
  }
  
  ::before{
    content:'';
    width:15px;
    height: 15px;
    position: absolute;    
    ${BGImageCenter(blueArrow,'contain')};
    left:calc(50% - 7.5px);
    top:calc(50% - 7.5px);
    transition:all 200ms 150ms;
  }
  &.up{
    ::before {
      transform: rotate(180deg);
    }
  }
  ${tooltip}{
    ::before{
      bottom:40px;
    }
    ::after{
      inset-inline-end: 20px;
      bottom:37px;
    }
  }
`;

