import * as css from './Recipients.css';
import {useState} from 'react';
import Close from '../../close/Close';
import {useTranslation} from 'react-i18next';

function Recipients(props) {
    const {inputFocus, isMobile, properties}=props;
    const {t} = useTranslation('mailing');

    const [{recipientsClose, indexRecipient}, setRecipient] = useState({recipientsClose: 0, indexRecipient: -1});

    const closeRecipient = (close, index) => {
        setRecipient({recipientsClose: close, indexRecipient: index});
    };
    const removeRecipient = (e) => {
        if(properties.recipients.length == 1)
            properties.closePop();
        properties.removeRecipient(e);
    };

    return (
        <css.recipients className={'recipients ' + (inputFocus && 'focus')}>
            <css.rcpIcon className="rcpIcon" />
            <css.to className="to">{t('to')}</css.to>
            <css.list className="list">
                {properties.recipients?.map((item, index) => {
                    if((isMobile && index < 2) || (!isMobile && index < 3))
                        return (
                            <css.listItem key={item.key} className="listItem" onMouseOver={() => { closeRecipient(1, item.key); }} onMouseLeave={() => { closeRecipient(0, -1); }}>
                                {recipientsClose == 1 && indexRecipient == item.key ? <Close  onClick={() => { removeRecipient(item.key); }}/> : <css.listItemIcon className={' listItemIcon'} />}
                                <css.listItemText className="listItemText">{item.text}</css.listItemText>
                            </css.listItem>
                        );
                })}
                {properties.recipients.length > 3 &&
                    <css.listItem className="listItem plus">
                        <css.listItemIcon className={' listItemIcon'} />
                        <css.listItemText className="listItemText plus">{(isMobile ? properties.recipients.length - 2 : properties.recipients.length - 3)+'+'}</css.listItemText>
                    </css.listItem>
                }
            </css.list>
        </css.recipients>
    );
}

export default Recipients;