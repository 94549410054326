import * as css from './AccountItem.css';
import React from 'react';
import List from '../list/List';
import useShow from '../../hooks/useShow';
import {useTranslation} from 'react-i18next';
import {updateAutomaticDataAccount} from '../../redux/event/eventActions';
import Tooltip from '../tooltip/Tooltip';

function AccountItem({icon='',onClear=()=>{},item}) {
    const {t} = useTranslation('event');
    const {open, close, isOpen, showClass} = useShow();
    const onClearItem = () => {
        close();
        onClear(item, item.key);
    };
    const accountList = [{text:t('ShowEventsAutomatically'),type: 'radioBtn',automaticData:1, selected:item.automaticData},{text:t('ShowEventsManually'),type: 'radioBtn',automaticData:0, selected:!item.automaticData},{text:t('remove'),onClick:()=>{onClearItem();}}];
    const onClickItem = (selectedItem)=>{
        updateAutomaticDataAccount({pid: item.pid, automaticData: selectedItem.automaticData}).then(close);
    };    return (<>
        <Tooltip text={item.text}>
            <css.wrap 
                data-cy={item.key} onClick={isOpen ? close : open}>
                <css.icon icon={icon}/>
                <css.text >{item.text + ' | '}
                </css.text>
                <css.textAutomaticData >{item.automaticData ? accountList[0].text : accountList[1].text}</css.textAutomaticData>
            </css.wrap>
        </Tooltip> 

        <css.wrapList>
            {!!isOpen && <List onClick={onClickItem} close={close} width={'250px'} list={accountList} showClass={showClass} />}
        </css.wrapList>
    </>
    );
}
export default AccountItem;
