import styled from 'styled-components/macro';
import zIndex from '../../globalVariables/zIndex';

export const  gridWrapper=styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,${p=>p.minSize});
    grid-gap: 20px;
    z-index: ${zIndex.base};
    width: 100%;

`;