import {useSelector} from 'react-redux';
import * as css from './TicketBox.css';
import {useTranslation} from 'react-i18next';
import ticketColors from '../../../../assets/objects/TicketColors';
import TicketProgress from '../ticketRow/ticketProgress/TicketProgress';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';
import {updateTicket} from '../../../../redux/tickets/ticketsActions';
import TicketsGroups from '../ticketRow/ticketGroups/TicketsGroups';
import useMobile from '../../../../hooks/useMobile';
import {formatDecimal} from '../../../../library/numberFunction';
import React from 'react';

export const getTicketPrice = (ticket) => {
    if(!ticket.prices?.length)
        return formatDecimal(ticket.price);
    const priceMax = ticket.prices?.reduce((max, current) => Math.max(max, Number(current.price)), 0);
    const priceMin = ticket.prices?.reduce((min, current) => Math.min(min, Number(current.price)), Number(priceMax));
    if(priceMin===priceMax)
        return formatDecimal(priceMax);
    return (formatDecimal(priceMax) + '-' + formatDecimal(priceMin));
};

function TicketBox({ticket, openPopup=()=>{}, isDrag, className, children}) {
    const ticketsIcon = useSelector(state => state.tickets.ticketsIcon);
    const tickets = useSelector(state => state.tickets.tickets);
    const {currentTicket,updateTicketXHR} = useSelector(state => state.tickets);
    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const {t} = useTranslation('ticket');
    const color = `#${ticket.color}`;
    const isMobile = useMobile();

    const updateActive = (val)=>{
        const currentTicket = tickets.find(t=>t.tid===ticket.tid);
        const fields = {...currentTicket, active:val};
        updateTicket(ticket.tid, fields).then();
    };

    const onClickBox = (e)=>{   
        if(isMobile)
            e.stopPropagation();
        if(openPopup)
            openPopup();
        
    };

    const getPrice = (ticket) => {
        const price = getTicketPrice(ticket);
        const allPrices = price.split('-');
        return allPrices.map((p, index) => {
            const onePrice = p.split('.');
            return (
                <React.Fragment key={index}>
                    {index !== 0 && '-'}
                    {onePrice[0]}
                    {onePrice[1] && <span className="decimal">.{onePrice[1]}</span>}
                </React.Fragment>
            );
        });
    };
    
    //remember htmlescape

    return (
        <css.container data-cy={`ticketId=${ticket?.tid}`} className={className && className}>
            <css.placeDrag className={isDrag ? 'isDrag' : ''} />
            <css.mobileRow className={isDrag ? 'isDrag' : ''} onClick={onClickBox}>
                <css.iconWrap color={color} className={(ticket.imgData ? 'image' : '')}>
                    {!ticket.imgData ? <css.icon icon={ticketColors[`${ticket.color}`]?.[ticketsIcon]} color={color} />
                        : <css.image image={(JSON.parse(ticket.imgData).path)} />
                    }
                    <TicketProgress ticket={ticket} onClick={onClickBox}/>
                </css.iconWrap>
                <css.left>
                    <css.part>
                        <css.topRight>
                            <css.text data-cy='typeColumnValue'>{t(ticket.type)}</css.text>
                            <css.boldText data-cy='titleColumnValue'>{ticket.title}</css.boldText>
                        </css.topRight>
                        <css.topLeft>
                            <ModeBtn className=" small " click={(val, e) => { e.stopPropagation(); updateActive(val); }} status={ticket.active} requestStatus={currentTicket?.tid===ticket.tid && updateTicketXHR} disabled={ticket.draft} />
                            {children}
                        </css.topLeft>
                    </css.part>
                    <css.part>
                        <css.groups>
                            <TicketsGroups ticket={ticket} />
                        </css.groups>
                        <css.details>
                            <css.price data-cy='priceColumnValue'>{getPrice(ticket) || t('missing')}&nbsp;<span>{currencySymbol}</span></css.price>
                            <css.amount>
                                <css.text><css.boldText data-cy='extrasColumnValue'>{ticket.countExtra || 0}&nbsp;</css.boldText>{t('extras')}</css.text>
                                <css.text><css.boldText data-cy='unitsColumnValue'>&nbsp;{ticket.amount || 0}&nbsp;</css.boldText>{t('units')}</css.text>
                            </css.amount>
                        </css.details>
                    </css.part>
                </css.left>
            </css.mobileRow >
        </css.container>
    );
}
export default TicketBox;
