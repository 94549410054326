import * as css from './Meeting.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getHourMinutes, tsGetDateAndMonth, tsGetDayOfWeek} from '../../../library/dates';
import ticketColors from '../../../assets/objects/TicketColors';
import CourseGuides from '../../tickets/ticketsPopUp/courseTicket/courseGuides/CourseGuides';
import {Else, If, Then} from 'react-if';
import {getDays} from '../../tickets/ticketsPopUp/courseTicket/courseRowDetails/CourseRowDetails';
import CourseMeetingsList from '../courseMeetingsList/CourseMeetingsList';
import useShow from '../../../hooks/useShow';
import {useRef} from 'react';

function Meeting({meeting, onClick=()=>{},tid}) {
    const {t,i18n} = useTranslation('attendance');
    const globalT = useTranslation('global');
    const ticketT = useTranslation('ticket');
    const ticket=useSelector(s=>s.tickets.tickets?.find(t=>t.tid===(tid||meeting.tid))||{});
    const ticketsIcon = useSelector(state => state.tickets.ticketsIcon);

    const iconProps=ticket?.imgData?
        {image:JSON.parse(ticket?.imgData?ticket.imgData:'{}')?.path}:
        {icon:ticketColors[`${ticket.color}`]?.[ticketsIcon],
            className:'icon'};
    const ref = useRef(null);
    const {open, close, isOpen, showClass} = useShow();
    const meetOnClick=()=>{
        if(tid)
            open();
        onClick();
    };
    return (<>
        <css.container onClick={meetOnClick}>
            <css.image color={ticket?.color}  {...iconProps}/>
            <css.body>
                <css.name >
                    <css.nameTop>
                        {ticket?.title}
                        {tid&&<css.operation ref={ref} >
                            {isOpen && <CourseMeetingsList propsSide={{...{[i18n.dir() === 'rtl'?'left':'right']:ref.current.getBoundingClientRect().x +'px'},fixedTop:ref.current.getBoundingClientRect().y+'px'}} close={close} showClass={showClass} tid={ticket?.tid} />}
                        </css.operation>}
                    </css.nameTop>
                    <CourseGuides guides={ticket?.users}/>
                </css.name>
                <css.bottom>
                    <css.calendarIcon/>
                    <If condition={tid}>
                        <Then >
                            <css.days>{ticket.course?.meetings?.length<2?ticketT.t('day'):ticketT.t('days')}&nbsp;{getDays(ticket.course?.meetings,globalT)}</css.days>
                        </Then>
                        <Else>
                            <css.date>
                                {`${tsGetDateAndMonth(meeting?.date)} | ${globalT.t('day'+(tsGetDayOfWeek(meeting?.date)))} | ${getHourMinutes(meeting?.date)}`}
                            </css.date>
                        </Else>

                    </If>

                    <css.number>
                        <span> {ticket.sold || 0}/{ticket.amount || 0}</span>
                        {t('participants')}
                    </css.number>
                </css.bottom>
            </css.body>
        </css.container>
    </>
    );
}

export default Meeting;
