import {FieldArray, useField} from 'formik';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {getEventTeam} from '../../../../../redux/team/teamActions';
import * as css from './GuideList.css';
import BasicItem from '../../../../../component/basicItem/BasicItem';
import GeneralItem from '../../../../../component/generalItem/GeneralItem';
import {Else, If, Then} from 'react-if';
import Tooltip from '../../../../../component/tooltip/Tooltip';
import {useSelector} from 'react-redux';

export let guideArrayHelpers={};

function GuideList({show}) {
    const {t} = useTranslation('ticket');
    const [userField, ,] = useField('users');
    const getEventTeamXHR = useSelector(s => s.team.getEventTeamXHR);

    useEffect(() => {
        if(!getEventTeamXHR)        
            getEventTeam().then();
    }, []);

    const newTeamMember=()=>{
        setImmediate(show.open);
    };

    return (
        <FieldArray
            name="users"
            render={(arrayHelpers) =>
            {
                guideArrayHelpers=arrayHelpers;
                return <css.container data-cy="GuideList">
                    {!userField.value.length && <BasicItem onClick={newTeamMember} icon={'person'} name={'users'}>
                        <css.wrapText data-cy="addGuide">{t('addGuide')}</css.wrapText>
                    </BasicItem>}
                    {userField.value?.map((item) => (
                        <GeneralItem key={item.uid} icon={'person'} onClick={() => show.open()} dataCyName="guideList" >
                            <If condition={!item.nameFirst && !item.nameFirst}>
                                <Then>
                                    <css.values data-cy="guideListName">{item.email}</css.values>
                                </Then>
                                <Else>
                                    <css.values data-cy="guideListName">{`${item.nameFirst} ${item.nameLast}`}</css.values>
                                </Else>
                            </If>
                        
                        </GeneralItem>
                    ))}
                    {!!userField.value.length && <Tooltip text={t('addGuide')}> <css.plus onClick={newTeamMember} /></Tooltip>}
                </css.container>;
            }}
        />
    );
}

export default GuideList;