import {useHistory} from 'react-router-dom';

const useNavigationLink=(key, value)=>{
    const history = useHistory();
    const open = ()=>{
        let params = new URLSearchParams(location.search);
        params.set(key, value);
        history.push({
            search: params.toString()
        });
    };

    const close = (key)=>{
        let params = new URLSearchParams(location.search);
        params.delete(key);
        history.replace({
            search: params.toString(),
        });
    };
    return {open, close};
};
export default useNavigationLink;