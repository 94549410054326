import styled from 'styled-components/macro';

export const LayoutButtons = styled.div`      
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

