import SettingItem from '../settingItem/SettingItem';
import EditImageForTypes from '../../../component/editImageForTypes/EditImageForTypes';
import {useSelector} from 'react-redux';
import {getImageColors, updateEventDesign} from '../../../redux/event/eventActions';
import {useState} from 'react';
import {uploadFile} from '../../../redux/user/userActions';

function EditImage({langDesign}) {
    const settings = useSelector(state => state.event.settings);
    const lang = useSelector(state => state.event.lang);
    const eid = useSelector(state => state.event.eid);
    const [currentLang, setCurrentLang] = useState(lang);
    const images = {
        mobile: {image: settings?.design?.fbImgMobile, imageId: settings?.design?.fbImgMobileId, credit: settings?.design?.credit?.mobile},
        desk: {image: settings?.design?.fbImg, imageId: settings?.design?.fbImgId, credit: settings?.design?.credit?.desk},
        post: {image: settings?.design?.fbImgPost, imageId: settings?.design?.fbImgPostId, credit: settings?.design?.credit?.post},
    };

    const updateImage = async (data, screenType) => {
        let name = `fbImg${screenType === 'mobile' ? 'Mobile' : (screenType === 'post' ? 'Post' : '')}`;
        data.image = await uploadFile(data.image, 'image/jpeg', `ho${name}`, eid);
        await updateEventDesign(name, data, currentLang, langDesign);
        await getImageColors();
    };

    return (
        <SettingItem name={'fbImgFetch'} settingCategory={'design'}  >
            <EditImageForTypes update={updateImage} images={images} currentLang={currentLang} setCurrentLang={setCurrentLang} langDesign={langDesign}/>
        </SettingItem>

    );
}

export default EditImage;
