import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {updateGroupColor, updateGroupImage} from '../../../../redux/cluster/clusterActions';
import setGlobalState from '../../../../redux/setGlobalState';
import Button from '../../../../component/button/button/Button';
import ColorsPicker from '../../../../component/colorsPicker/ColorsPicker';
import FullEditImage from '../../../../component/fullEditImage/FullEditImage';
import PopUp from '../../../../component/popUp/PopUp';
import * as css from './GroupImagePopUp.css';
import store from '../../../../store';

export const changeArrColorTemp = (color, action) => {
    let i = store.getState().ui.colorsPicker?.arrColor?.findIndex(item => item === color);
    setGlobalState(`ui.colorsPicker.arrColorTemp[${i}]`, store.getState().ui.colorsPicker?.arrColorTemp[i] + (action === 'add' ? 1 : -1));
};

function GroupImagePopUp({group, popUpImage}) {
    const {t} = useTranslation('cluster');
    const groupImage = group?.clusterGroupImage;
    const groupColor = group?.clusterGroupColor;
    const [image, setImage] = useState(groupImage);
    const [openPopOnPop, setOpenPopOnPop] = useState(0);
    const updateGroupImageXHR = useSelector(state => state.cluster.updateGroupImageXHR);
    const event = useSelector(state => state.event);
    const isEditImage = useSelector(state => state.ui.editImage.isEditImage);
    const colorsPicker = useSelector((state) => state.ui.colorsPicker);

    const handleOnSave = async () => {
        await updateGroupImage(event?.eid, group.cgid, {clusterGroupImage: image});
    };

    const onClickColor = async (color) => {
        changeArrColorTemp(colorsPicker.currentColor, 'sub');
        changeArrColorTemp(color, 'add');
        setGlobalState('ui.colorsPicker.currentColor', color);
        await updateGroupColor(event?.eid, group.cgid, {clusterGroupColor: color});
    };

    const sendBtnProps = {
        text: updateGroupImageXHR === 'SUCCESS' ? t('saved') : t('saveV'),
        class: 'save ' + updateGroupImageXHR + ' ' + 'btnCss' + ' '+(isEditImage && ' disabled '),
        onClick: handleOnSave,
    };

    const closeBtn = {
        text: t('closeV'),
        onClick: () => setOpenPopOnPop(0),
        class: ' btnCss'
    };

    let popUpProps = [];
    popUpProps['closePop'] = () => popUpImage.close();
    popUpProps['title'] = t('imageCluster');
    popUpProps['popupContent'] = <css.wrapPopImage>
        <css.imageText>{t('chooseImage')}</css.imageText>
        <css.wrapImage><FullEditImage setImage={setImage} image={image}/></css.wrapImage>
    </css.wrapPopImage>;
    popUpProps['popupBottom'] = <>
        <css.colorPicker groupColor={groupColor} onClick={() => setOpenPopOnPop('show')}/>
        <css.wrapBtn><Button BtnProps={sendBtnProps}/></css.wrapBtn>
    </>;

    popUpProps['closePopOnPop'] = () => setOpenPopOnPop(0);
    popUpProps['openPopOnPop'] = openPopOnPop;
    popUpProps['popOnPopTopHeight'] = '110px';
    popUpProps['popOnPopTitle'] = t('groupColor');
    popUpProps['popOnPopContent'] = <ColorsPicker onClickColor={onClickColor}/>;
    popUpProps['popOnPopBottom'] = <css.wrapBtn><Button BtnProps={closeBtn}/></css.wrapBtn >;
    popUpProps['popOnPopImage'] = <css.popOnPopImg/>;
    return (<>
        <css.popUpImage> <PopUp popUpProps={popUpProps}/></css.popUpImage>
    </>
    );
}

export default GroupImagePopUp;
