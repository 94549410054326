import styled from 'styled-components/macro';
import {Template} from '../../../../component/templates/Template.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {op} from '../../../../assets/symbols/symbols/Symbols.css';

export const container=styled.div`
  width:100% ;
  height:100% ;
  position: relative;
  border-radius: 4px;
  ${Template('320px')}
`;

export const top=styled.div`
  width: 100%;
  border-radius: 4px 4px 0 0;
  height:120px;
  background-color: #F3F3F3;
  opacity: 0.5;
`;
export const topDetails=styled.div`
  height:35px;
  display: flex;
  align-items: center;
  direction: ltr;
`;

export const optionsIcon = styled.div`

  width: 20px;
  height: 20px;
  ${BGImageCenter(op, '10px')}

`;

export const options=styled.div`
  width:20px;
`;

export const image=styled.div`  
  top: 80px;
  inset-inline-start: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin:-40px auto 0 auto;
  background-color:#F3F3F3;
`;

export const details=styled.div`
  background-color:#F3F3F3;
  width: 50%;
  inset-inline-start: calc(50% - 20px);
  height: 20px;
  border-radius: 25px;
  margin: 10px 25%;
`;

export const whatsUp=styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50px;
  margin-inline-start: calc(100% - 35px);
  background-color: #F3F3F3;
  margin-bottom: 5px;
`;
export const footer=styled.div`
  height: 30px;
  width: 100%;
  background-color: #F3F3F3;
  opacity: 0.5;
`;

export const modeBtn=styled.div`
  width:30px;
  height: 10px;
  border-radius: 20px;
  background-color: #00000052;
`;