import WindowTemplate from '../../WindowTemplate/windowTemplate';

const grid = `"title title"
                        "amount amount"
                        "nothing nothing"
                        "statsItem1 span1"
                        "statsItem2 span2"
                        `;
const gridMoreStyle={                    
    'grid-auto-columns': '56px 120px'
};
const settings = [
    {
        gridArea: 'title',
        width: '100px',
        height: '29px',
        moreStyle:{
            'place-self': 'baseline'
        }
    },
    {
        gridArea: 'amount',
        width: '100px',
        height: '52px',
        moreStyle:{
            'place-self': 'baseline'
        }
    },

    {
        gridArea: 'nothing',
        width: '100%',
        height: '50px',
        moreStyle:{
            'background-color': 'transparent'
        }

    }, {
        gridArea: 'statsItem1',
        width: '100%',
        height: '21px'

    },
    {
        gridArea: 'span1',
        width: '100%',
        height: '21px'

    },
    {
        gridArea: 'statsItem2',
        width: '100%',
        height: '21px'
        
    },

    {
        gridArea: 'span2',
        width: '100%',
        height: '21px'

    },
];
const TransactionsTemplate = () => {
    
    return <WindowTemplate gridMoreStyle={gridMoreStyle} gridAreas={grid} settings={settings} />;

};

export default TransactionsTemplate;