import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ThemeProvider as SCThemeProvider} from 'styled-components';

function ThemeProvider({children}) {
    const {i18n} = useTranslation('layout');
    const [heightVh, setHeightVh]=useState(window.innerHeight * 0.01);
    useEffect(()=>{
        const updateWindowDimensions = () => {setHeightVh(window.innerHeight * 0.01);};
        window.addEventListener('resize', updateWindowDimensions);
        return () => window.removeEventListener('resize', updateWindowDimensions);

    },[]);
    document.body.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
    const isRtl=i18n.dir()==='rtl';
    const theme={
        rtl:isRtl,
        start:isRtl?'right':'left',
        end:isRtl?'left':'right',
        vh:heightVh
    };
    return (
        <SCThemeProvider theme={theme}>
            {children}
        </SCThemeProvider>
    );
}

export default ThemeProvider;
