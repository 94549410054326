import * as css from './LeftPageDefault.css';
import {useTranslation} from 'react-i18next';

function LeftPageDefault() {
    const {t}=useTranslation('layout');
    return (
        <css.defaultLeft>
            <css.defaultText>{t`pageWrapperDefault`}</css.defaultText>
            <css.defaultImage/>
        </css.defaultLeft>

    );
}

export default LeftPageDefault;
