import createReducer from '../createReducer';

const initialState = {
    pixel:{
        customPixels:[],
        specialPixels:[],
    },
    lead:{
        leads:[],
        automatedLeads:[],
        getLeadsXHR:null,
        getAutomatedLeadsXHR:null
    },
    getPixelsXHR:null,
    
    updateSpecialPixelXHR:null,
    addSpecialPixelXHR:null
};

const reducer = createReducer('publish',initialState);

export default reducer;