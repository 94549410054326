import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import SubMenu from '../../components/subMenu/SubMenu';
import * as css from './Cluster.css';
import Details from '../details/Details';
import List from '../../../component/list/List';
import ClusterPicker from './clusterPicker/ClusterPicker';
import EventPopUp from './eventPopUp/EventPopUp';
import Button from '../../../component/button/button/Button';
import useShow from '../../../hooks/useShow';
import ClusterPageHeader from './clusterPageHeader/ClusterPageHeader';
import ClusterEvents from './clusterEvents/ClusterEvents';
import {getClusterGroupEvents, getClusterGroups, addClusterGroup} from '../../../redux/cluster/clusterActions';
import {pushToGlobalState} from '../../../redux/setGlobalState';
import {setCurrentRating} from '../../components/rating/Rating';
import {getNextColor} from '../../../component/colorsPicker/ColorsPicker';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import store from '../../../store';
import useTablet from '../../../hooks/useTablet';
import {PageWrapperContext} from '../../components/pageWrapper/PageWrapper';

export const addEidsGroup = async (eids) => {
    let eventsG = [];
    eventsG =store.getState().cluster.groups[0].events.filter(item => eids.includes(item.eid));
    const clusterGroupColor = getNextColor();
    await addClusterGroup(store.getState().event?.eid, {eids: eids, clusterGroupColor: clusterGroupColor}, eventsG);
};

function Cluster() {
    const {t} = useTranslation('cluster');
    const tL = useTranslation('layout');
    const [showPicker, setShowPicker] = useState(0);
    const [pickerSub, setPickerSub] = useState(0);
    const operations = [{
        key: 0, text: 'addEvent', onClick: () => {
            setShowPicker(1);
            setPickerSub(1);
        }
    }, {key: 1, text: 'addSubscriber'}];
    const groupState= 0;
    const [showPopup, setShowPopup] = useState(0);
    const [search, setSearch] = useState('');
    const [searchState, setSearchState] = useState(0);
    const [isEvents, setIsEvents] = useState(0);
    const event = useSelector(state => state.event);
    const eventDup = useSelector((state) => state.preparingEvent.eventDup);
    const cluster = useSelector((state) => state.cluster);
    const [{limit, offset}, setLimitOffset] = useState({limit: 10, offset: 0});
    const isTablet = useTablet();

    const [checkedList, setCheckedList] = useState([]);
    const [isSelectable, setIsSelectable] = useState(false);

    const btn = {
        textColor: '#000000',
        backgroundColor: '#FFEB00',
        hoverBorderColor: '#000000',
        hoverTextColor: '#484848',
        letterSpacing: '1.2px',
        text: t('clusterEvents'),
        onClick: () => open()
    };

    const doOnDuplicate = (res) => {
        let groupIndex = cluster.groups.findIndex(item => item.cgid === eventDup.cgid);
        pushToGlobalState(`cluster.groups[${groupIndex}].events`, res.newEvent ? res.newEvent : eventDup);
    };

    const groupClick = () => {
        if(isSelectable && !checkedList.length) {
            bounceMessage(t('emptyGroup'), 'attention', undefined, {text: t('closeBtn')}, 5);
            return;
        }
        setIsSelectable(!isSelectable);
        if(isSelectable)
            addEidsGroup(checkedList);
        else
            setCheckedList([]);
    };
    
    const {open, close, isOpen, showClass} = useShow(500);
    const AddEventList = [{key: 1, type: 'existEvent', text: t('existEvent')}];

    const closePicker = () => {
        setShowPicker(0);
    };

    const handleAddEvent = () => {
        close();
        setShowPicker(1);
        setPickerSub(0);
    };
    const updateLimitOffset = (name, value) => {
        setLimitOffset(prevState => ({...prevState, [name]: value}));
    };

    useEffect(() => {
        setIsEvents((cluster.groups.length || cluster?.groups[0]?.events?.length) ? 1 : 0);
    }, [cluster.groups]);

    const isFirst = useRef(true);

    useEffect(() => {
        if(!cluster?.groups?.length)
            getClusterGroups(event?.eid, {limit: 50, offset: 0, search: ''})
                .then(() => {
                    if(!cluster?.groups[0]?.events?.length)
                        getClusterGroupEvents(event?.eid, 0, {limit: limit, offset: offset});
                });
    }, []);

    useEffect(() => {
        if(!isFirst.current && (offset % 10) === 0)
            getClusterGroupEvents(event?.eid, 0, {limit: limit, offset: offset});
        if(isFirst.current)
            isFirst.current = false;
    }, [offset]);

    useEffect(() => {
        if(cluster.firstEvent)
            setCurrentRating('createCluster');
    }, [cluster.firstEvent]);
    return (
        <>
            <PageWrapperContext.Provider value={{operations,title:tL.t`clusterDetails`}}>

                <SubMenu image={'eventCluster'} >
                    {Details}
                </SubMenu>
            </PageWrapperContext.Provider>
            <css.pageFrame>
                {!isEvents ?
                    <css.addFirstEToCluster>
                        <css.addEventToClusterImg />
                        <css.addFirstE>{t('addEventToCluster')}</css.addFirstE>
                        <css.EButton>
                            <Button BtnProps={btn}>
                                {!!isOpen && <List close={close} showClass={showClass} title={t('addEventTitle')} list={AddEventList} bottom={'1'} onClick={handleAddEvent} />}
                            </Button>
                            {!!showPicker &&
                                <ClusterPicker top={'30'} right={pickerSub ? '70%' : 'calc(50% - 150px)'} bottom={'100px'}
                                    closePicker={closePicker} />}
                        </css.EButton>
                    </css.addFirstEToCluster> :
                    <css.pfRight>
                        <ClusterPageHeader setIsSelectable={setIsSelectable} isSelectable={isSelectable} groupClick={groupClick} setSearchState={setSearchState} searchState={searchState} search={search} setSearch={setSearch} />
                        <ClusterEvents checked={{checkedList, setCheckedList, isSelectable, setIsSelectable}} groupState={groupState} updateLimitOffset={updateLimitOffset} setShowPopup={setShowPopup} addEidsGroup={addEidsGroup} searchState={searchState} search={search} />
                        <css.addEvent onClick={() => open()}>{t('addEventTitle')}
                            {!!isOpen &&
                                <List close={close} showClass={showClass} title={t('addEventTitle')} list={AddEventList} bottom={'5'}
                                    onClick={handleAddEvent} />}
                        </css.addEvent>
                        {!!showPicker && <ClusterPicker bottom={pickerSub ? 'calc(100% - 220px)' : '50px'}
                            right={pickerSub ? '70%' : 'calc(50% - 150px)'}
                            closePicker={closePicker} />}
                    </css.pfRight>
                }

                {!isTablet &&
                    <css.pfLeft>
                        <Details />
                    </css.pfLeft>
                }
                {!!showPopup && <EventPopUp closePopUp={() => setShowPopup(0)} eventPreparing={eventDup} type={'duplicate'} callBack={doOnDuplicate} />}
            </css.pageFrame >

        </>

    );
}

export default Cluster;
