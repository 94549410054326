import * as css from './ItemInArray.css';

function ItemInArray({icon=null, bgColor=null, title, left}) {
    return (
        <css.itemInArray>
            <css.icon 
                iconCss= {
                    `background-color: ${bgColor?'#'+bgColor:'#10C2D1'};
                    background-image: url("${icon}");
                    ` }
            />
            <css.title>{title}</css.title>
            <css.left>{left}</css.left>
        </css.itemInArray>
    );
}
export default ItemInArray;