import {useRef} from 'react';
import {useState} from 'react';
import useShow from '../../hooks/useShow';
import UserImageMenu from '../../layout/topMenu/layoutUserProfile/editUser/editUserProfile/editUserImage/UserImageMenu';
import UserImage from '../../layout/topMenu/layoutUserProfile/userImage/UserImage';
import ImageEditor from '../imageEditor/ImageEditor';
import * as css from './CircleImageEdit.css';
import setGlobalState from '../../redux/setGlobalState';
import compressAndEncodeToBase64 from '../../library/fileCompressor';
import UseMouseUpOutside from '../../hooks/UseMouseUpOutside';

function CircleImageEdit({image,setImage,saveImage=()=>{},deleteImage=()=>{},size={},isDefaultImg,unableEdit=false,isCompress=true}) {
    const [editImgMenu, setEditImgMenu] = useState(0);
    const [uploadedImage,setUploadedImage]= useState('');

    const {width,height} = size;

    const ref = useRef();
    const refList = useRef();
    const openEditImage = useShow();
    UseMouseUpOutside(refList,openEditImage.close);
    const onLoadEnd = (image) => {
        setUploadedImage(image);
        setGlobalState('ui.editImage.isShowAnimate',0);  
    };

    const uploadImage =(image)=>{
        openEditImage.open();  
        if(isCompress)
            compressAndEncodeToBase64(image,onLoadEnd);
        else 
            setUploadedImage(image);
        setImage();
    };

    return (
        <css.EditUserTop ref={refList}>     
            <ImageEditor size={{height:'190px',width:'290px'}} top={ref?.current?.getBoundingClientRect().y} openEditImage={openEditImage} width={width} 
                height={height} image={uploadedImage||image} borderRadius={60} save={saveImage} vertical={true} setUploadedImage={setUploadedImage}/>
            <UserImage size={size} class={'edit-profile-user-img'} path={image}>
                <css.EditUserImgBtn className={unableEdit&&'unableEdit'} onClick={()=> setEditImgMenu(!editImgMenu)} />
            </UserImage>
            {!!editImgMenu && 
            <UserImageMenu isDefaultImg={isDefaultImg} openEditImage={openEditImage} deleteImage={deleteImage} close={()=>setEditImgMenu(0)} 
                path={image} uploadImage={uploadImage}/>}    
        </css.EditUserTop>

    );
}

export default CircleImageEdit;
