import request from '../../library/request';
import setGlobalState, {pushToGlobalState} from '../setGlobalState';
import store from '../../store';
import {uploadFile} from '../user/userActions';

export const getClusterEvents = async (eid, fields) => {
    const res = await request('cluster.getClusterEvents', 'get', `/cluster/${eid}/events`, fields);
    setGlobalState('cluster.searchEvents', res.events);
};

export const getEventsSuggestions = async (clusterEid, fields) => {
    const res = await request('cluster.getEventsSuggestions', 'get', `/cluster/${clusterEid}/eventsSuggestions`, fields);
    setGlobalState('cluster.clusterEventsSearch', res.events);
};

export const addEventToCluster = async (clusterEid, event, fields) => {
    await request('cluster.addEventToCluster', 'post', `/cluster/${clusterEid}/event/${event.eid}`, fields);
    let eventsLocal = 0;
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === fields.cgid);
    // eventsLocal += store.getState().cluster.groups[groupIndex].events && store.getState().cluster.groups[groupIndex].events.length
    store.getState().cluster.groups.forEach((g) => {
        if(g.cgid) {
            eventsLocal = 1;
            return;
        }
        else
            eventsLocal += g.events && g.events.length;
    });
    setGlobalState('cluster.firstEvent', eventsLocal === 0);
    if(store.getState().cluster.groups[groupIndex]?.events)
        pushToGlobalState(`cluster.groups[${groupIndex}].events`, event);
    else
        setGlobalState(`cluster.groups[${groupIndex}].events`, [event]);
};

export const getClusterGroupEvents = async (clusterEid, cgid, fields) => {
    const res = await request('cluster.getClusterGroupEvents', 'get', `/cluster/${clusterEid}/group/${cgid}/events`, fields);
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid == cgid);
    if(store.getState().cluster.groups[groupIndex]?.events)
        setGlobalState(`cluster.groups[${groupIndex}].events`, [...store.getState().cluster.groups[groupIndex]?.events, ...res.clusterEvents]);
    else
        setGlobalState(`cluster.groups[${groupIndex}].events`, res.clusterEvents);
};

export const getClusterGroups = async (clusterEid, fields) => {
    const res = await request('cluster.getClusterGroups', 'get', `/cluster/${clusterEid}/groups`, fields);
    setGlobalState('cluster.groups', [{cgid: 0, events: null}, ...res.groups]);
};

export const deleteEventCluster = async (clusterEid, eid, cgid) => {
    await request('cluster.deleteEventCluster', 'delete', `/cluster/${clusterEid}/event/${eid}`);
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === cgid);
    let newClusterEvent = store.getState().cluster.groups[groupIndex]?.events.filter(item1 => item1.eid !== eid);
    setGlobalState(`cluster.groups[${groupIndex}].events`, newClusterEvent);
};

export const deleteGroup = async (clusterEid, cgid) => {
    await request('cluster.deleteGroup', 'delete', `/cluster/${clusterEid}/group/${cgid}`);
    let updateEvents = store.getState().cluster.groups.filter(item1 => item1.cgid === cgid)[0].events;
    updateEvents = updateEvents ? updateEvents : [];
    let updateGroups = [...store.getState().cluster.groups.filter(item1 => item1.cgid !== cgid)];
    setGlobalState('cluster.groups', updateGroups);
    setGlobalState('cluster.groups[0].events', [...updateGroups[0].events, ...updateEvents]);
};

export const addEventToGroup = async (clusterEid, event, fields) => {
    await request('cluster.addEventToGroup', 'put', `/cluster/${clusterEid}/event/${event.eid}`, fields);
    let events = store.getState().cluster.groups[0].events.filter(item1 => item1.eid !== event.eid);
    setGlobalState('cluster.groups[0].events', events);
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === fields.cgid);
    pushToGlobalState(`cluster.groups[${groupIndex}].events`, event);
};

export const deleteEventFromGroup = async (clusterEid, event, fields, cgid) => {
    await request('cluster.deleteEventFromGroup', 'put', `/cluster/${clusterEid}/event/${event.eid}`, fields);
    pushToGlobalState('cluster.groups[0].events', event);
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === cgid);
    let events = store.getState().cluster.groups[groupIndex].events.filter(item1 => item1.eid !== event.eid);
    setGlobalState(`cluster.groups[${groupIndex}].events`, events);
};

export const addClusterGroup = async (clusterEid, fields, eventsG) => {
    let res = await request('cluster.addClusterGroup', 'post', `/cluster/${clusterEid}/group`, fields);
    let events = store.getState().cluster.groups[0].events.filter(item => !eventsG.includes(item));
    setGlobalState('cluster.groups[0].events', events);
    let newGroup = res.group;
    newGroup.events = eventsG;
    pushToGlobalState('cluster.groups', newGroup);
};

export const getChildEventStats = async (eid) => {
    let res = await request('cluster.getChildEventStats', 'get', `/event/${eid}/stats`);
    setGlobalState('cluster.childEventStats', res);
};

export const updateEventActive = async (eid, fields, cgid) => {
    await request('cluster.updateEventActive', 'put', `/event/${eid}`, fields);
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === cgid);
    let eventIndex = store.getState().cluster.groups[groupIndex].events.findIndex(item => item.eid === eid);
    setGlobalState(`cluster.groups[${groupIndex}].events[${eventIndex}].active`, fields.active);
};

export const updateEventsWeight = async (clusterEid, fields, updateEventsWeight, cgid) => {
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === cgid);
    setGlobalState(`cluster.groups[${groupIndex}].events`, updateEventsWeight);
    await request('cluster.updateEventsWeight', 'put', `/cluster/${clusterEid}/events`, fields);

};

export const updateGroupTitle = async (clusterEid, cgid, fields) => {
    await request('cluster.updateGroupTitle', 'put', `/cluster/${clusterEid}/group/${cgid}`, fields);
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === cgid);
    setGlobalState(`cluster.groups[${groupIndex}].title`, fields.title);
};

export const updateGroupImage = async (clusterEid, cgid, fields) => {
    if(fields.clusterGroupImage)
        fields.clusterGroupImage = await uploadFile(fields.clusterGroupImage, '' , 'clusterGroupImg', cgid);
    if(!fields.hasOwnProperty('clusterGroupImage') || fields.clusterGroupImage){
        await request('cluster.updateGroupImage', 'put', `/cluster/${clusterEid}/group/${cgid}`, fields);
        let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === cgid);
        setGlobalState(`cluster.groups[${groupIndex}].clusterGroupImage`, fields.clusterGroupImage);
    }
};

export const updateGroupColor = async (clusterEid, cgid, fields) => {
    await request('cluster.updateGroupColor', 'put', `/cluster/${clusterEid}/group/${cgid}`, fields);
    let groupIndex = store.getState().cluster.groups.findIndex(item => item.cgid === cgid);
    setGlobalState(`cluster.groups[${groupIndex}].clusterGroupColor`, fields.clusterGroupColor);
};