import styled from 'styled-components/macro';
import {arrowBtn} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {ArrowWrap} from '../../../../component/list/List.css';
import {Item} from '../../../../component/item/Item.css';

export const container=styled.div` 
    cursor: pointer;
  font-weight: 600;
    display: flex;
    flex-wrap: wrap;
position: relative;
  //padding-inline-start: 10px;
  padding-bottom: 30px;
  ${ArrowWrap}{
    width: 0;
  }
  ${Item}{
    position: relative;
    ::after {
      content: "";
      height: 16px;
      width: 16px;
      ${BGImageCenter(arrowBtn,'6px')}
      position: absolute;
      ${props=>props.theme.rtl?'left':'right'}:12px;
      transform: rotate(${props=>props.theme.rtl?0:180}deg);
  }
 

  }
 
`;
export const arrow = styled.div`
    height: 16px;
    width: 16px;
    transform: rotate(-90deg);
    margin-top: 2px;
    ${BGImageCenter(arrowBtn,'6px')}
`;
export const date = styled.div`
   
`;

