import styled, {keyframes} from 'styled-components';

const loading = keyframes`
    from { transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(11px) rotate(-360deg); }
    
    from { -webkit-transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { -webkit-transform: rotate(360deg) translateX(11px) rotate(-360deg); }
    
    from { -moz-transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { -moz-transform: rotate(360deg) translateX(11px) rotate(-360deg); }
    
    from { -o-transform: rotate(0deg) translateX(11px) rotate(0deg); }
    to   { -o-transform: rotate(360deg) translateX(11px) rotate(-360deg); }
`;

export const sendTry=styled.div`
    border: 1px solid ;
    height: 34px;
    padding: 0 18px;
    border-radius: 28px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-inline-end: 10px;
    cursor:pointer;
    width: 140px;
    position: relative;
    transition: all 200ms;

    @media screen and (max-width: 750px){  
        position: absolute;
        ${props => props.theme.rtl ? 'right' : 'left'}:20px;
    } 

    &.open{
        width: 225px;
        text-align: ${props => props.theme.rtl ? 'right' : 'left'};
        :before{
            float:${props => props.theme.rtl ? 'left' : 'right'};
            width: 14px;
            height: 14px;
        }
    }

    &.process{
        :before{
            font-size: 0;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            bottom: 8px;
            ${props => props.theme.rtl ? 'left' : 'right'}: -23px;
            position: relative;
            background-color: #d3d3d340;
            background-image: unset;
        }
        ::after{
            content:'';
            width:7px;
            height:7px;
            border-radius:50%;
            background:#000;
            position:absolute; 
            top: calc(50% - 3.5px);
            ${props => props.theme.rtl ? 'left' : 'right'}: 12px;
            -webkit-animation: ${loading} 1s linear  infinite;
            animation: ${loading} 1s linear  infinite;
            -moz-animation:  ${loading}  1s linear infinite; 
            -o-animation: ${loading} 1s linear infinite; 

        };
    }
    &.incorrect, &.failed{
        width: 250px;
        background-color: red;
        color: #fff;
        :before{
            content:"!";
            margin: 0;
            font-size: 18px;
            background-image:unset;
        }
    }
    &.success{
        background-color:#a0d11c;
        :before{
            background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2048.09%2031.47%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3Anone%3Bstroke%3A%23484848%3Bstroke-miterlimit%3A10%3Bstroke-width%3A8px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Ev%3C%2Ftitle%3E%3Cline%20class%3D%22a%22%20x1%3D%221.49%22%20y1%3D%2214.68%22%20x2%3D%2215.23%22%20y2%3D%2229.95%22%2F%3E%3Cline%20class%3D%22a%22%20x1%3D%2246.8%22%20y1%3D%221.53%22%20x2%3D%2213.23%22%20y2%3D%2229.95%22%2F%3E%3C%2Fsvg%3E');
            margin: 13px -5px 7px;
        }
    }
    &.failed{
        border:1px solid red;
        :before{
            background-image:unset;
        }
    }
    :before{
        content: "";
        float: ${props => props.theme.rtl ? 'right' : 'left'};
        width: 20px;
        margin: ${props => props.theme.rtl ? '9px -11px 7px -3px' : '9px -3px 7px -11px'};
        height: 20px;
        background-repeat: no-repeat;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.286' height='15' viewBox='0 0 14.286 15'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235a5b5e;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M397.316,761.544l-12.863-6.625a.705.705,0,0,0-.764.083.693.693,0,0,0-.223.732l1.914,6.481-1.945,6.74a.7.7,0,0,0,.235.736.67.67,0,0,0,.434.151.737.737,0,0,0,.33-.08l12.895-6.986a.69.69,0,0,0,.366-.621A.7.7,0,0,0,397.316,761.544ZM385.265,756.9l9.346,4.814h-7.921Zm1.424,5.809h7.834l-9.29,5.037Z' transform='translate(397.694 769.844) rotate(180)'/%3E%3C/svg%3E");
    }
`;
export const sendTryInput=styled.input`
    position: absolute;
    top: 0px;
    ${props => props.theme.rtl ? 'right' : 'left'}: 12px;
    width: 175px;
    text-align: ${props => props.theme.rtl ? 'right' : 'left'};
    border: none;
    height: 100%;
    font-weight: 600;
`;

export const sendTryBtn=styled.div`
    float: ${props => props.theme.rtl ? 'left' : 'right'};
    width: 20px;
    margin: 9px -5px 7px;
    height: 20px;
`;