import styled from 'styled-components/macro';

export const container = styled.div`
    height: 100%;
    width: 100%;
    transition: all 500ms;
    border-radius: 0px;
    border: 2px dashed transparent;
    &.active{
        border: 2px dashed white;
        border-radius: 18px;
    }

`;

