import styled from 'styled-components/macro';
import realtimeFonts from '../../../fonts';
import {EventMenuForm} from '../../../../../layout/eventMenu/EventMenu.css';
import {row} from '../../../../../component/newTable/newRow/Row.css';
import {tableContainer,table} from '../../../../../component/newTable/Table.css';
import {part} from '../../../../tickets/components/ticketRow/part/Part.css';
import {LIGHT_BLUE2, VERY_LIGHT_GRAY, mobileMedia, tabletPlusMedia} from '../../../../../globalVariables/GlobalVariables';

const TITLE_PART_WIDTH=28;
const TITLE_PART_WIDTH_MOBILE=30;
const PART_WIDTH=(100-TITLE_PART_WIDTH)/6;
const PART_WIDTH_MOBILE=(100-TITLE_PART_WIDTH_MOBILE)/3;
export const container = styled.div`
  padding: 20px;
  height: 100%;
  ${mobileMedia}{
    padding: 0;
    padding-block: 20px;
    padding-inline: 6px;
  }
`;
export const title = styled.div`
    ${realtimeFonts.windowTitle}
    display: flex;
    justify-content: space-between;
    position: relative;
    width: calc(100% - 40px);
    ${mobileMedia}{
        padding-inline-start: 20px;
    }
`;
export const ticketsPage = styled(EventMenuForm)`
    margin: 0 0;
    margin-inline-end: 15px;
    ${mobileMedia}{
      margin-inline-end: 30px;
    }
`;
export const data = styled.div`
    height: 100%;
    ${tabletPlusMedia}{
      display: flex;
      justify-content: flex-end;
    } 
`;
export const tableTitle = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: calc(100% - 90px);
    inset-inline-start: 90px;
    ${mobileMedia}{
      display: none;
    }
`;
export const text = styled.div`

`;
export const tableTickets = styled.div`
    position: relative;
    inset-block-start: 20px;
    width: 70%;
    height: max-content;
    .defaultColumn{
        width: ${PART_WIDTH}%;
        ${mobileMedia}{
            width: ${PART_WIDTH_MOBILE}%;
        }
    }
    .titleColumn{
        width: ${TITLE_PART_WIDTH}%;
        min-width:${TITLE_PART_WIDTH}%;
        ${mobileMedia}{
            width: ${TITLE_PART_WIDTH_MOBILE}%;
            min-width:${TITLE_PART_WIDTH_MOBILE}%;      
        }        
    }
    ${table}{
        padding-inline-start: 5px;
        ${mobileMedia}{
            padding-inline-start: 0;
        }
    }
    ${row}{
      height: 60px;
      position: relative;
      ${mobileMedia}{
        height: 80px;        
        background-color: ${LIGHT_BLUE2};
        box-shadow: none;
      }
    }
    ${part}{
      height: 100%;
      border-inline-end: 0.5px solid ${VERY_LIGHT_GRAY};
      &:last-child {
        border-inline-end: none;
      }
    }
    ${mobileMedia}{
      inset-block-start: 10px;
      width: 100%;
        ${tableContainer}{
            width: 100%;
        }
    }
    
`;