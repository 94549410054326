import createReducer from '../createReducer';

const initialState = {
    eventDup: null,
    updateEventDetailsXHR: null,
    eventDuplicationXHR: null,
};

const reducer = createReducer('preparingEvent', initialState);

export default reducer;
