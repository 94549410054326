import {useTranslation} from 'react-i18next';
import ExtensionsRow from '../../courseTicket/extensionsRow/ExtensionsRow';
import * as css from './ExtensionsGlobal.css';
import * as cssPop from '../../courseTicket/coursePopupContent/CoursePopupContent.css';
import {useState} from 'react';
import {FieldArray, Form, useField} from 'formik';
import ExtensionsPop from '../../basicTicket/extensionsPop/ExtensionsPop';
import BtnPlus from '../../../../../component/button/btnPlus/BtnPlus';
import useMobile from '../../../../../hooks/useMobile';
import Tooltip from '../../../../../component/tooltip/Tooltip';
import DragTable from '../../../../../component/newTable/dragTable/DragTable';
import updateArrayAfterDrag from '../../../../../functions/updateArrAfterDrag';

function ExtensionsGlobal({image, text}) {
    const {t} = useTranslation('ticket');
    const [extras, , extrasHelpers] = useField('extras');
    const isMobile = useMobile();
    const [currentIndex, setCurrentIndex] = useState(-1);

    const onDragEnd = async (drag) => {
        const newArr = updateArrayAfterDrag(drag, extras?.value);
        extrasHelpers.setValue(newArr);
    };

    return (
        <>
            <css.wrapForm>
                <Form>
                    <FieldArray
                        name="extras"
                        render={(arrayHelpers) => (
                            <>
                                <css.container>
                                    <>
                                        {!extras?.value.length ?
                                            <css.wrapImage>
                                                <css.extensionsImage className={text} image={image} />
                                                <css.text>{t(text)}</css.text>
                                                {/*<css.extension onClick={() => setCurrentIndex('new')}>*/}
                                                {/*<css.plus />*/}
                                                {/*<css.text>{t('addExtensions')}</css.text>*/}
                                                <BtnPlus  onClick={() => setCurrentIndex('new')} text={t('addExtensions')} hasText={true}/>
                                                {/*</css.extension>*/}
                                            </css.wrapImage> :
                                            <css.content>
                                                <css.wrapTable>
                                                    <DragTable rowHeight={isMobile?115:70}
                                                        onDragEnd={onDragEnd}
                                                        list={extras?.value?.map((extra, index) => (
                                                            {
                                                                comp: ExtensionsRow,
                                                                props: {extra: extra, index: index, setShow:setCurrentIndex},
                                                                key: index
                                                            })
                                                        )}/> 
                                                </css.wrapTable>
                                                <css.wrapPlus><Tooltip text={t('addExtensions')}> <cssPop.plus data-cy='addExtensions' onClick={() => setCurrentIndex('new')} /> </Tooltip><css.textSmall>{t('addExtensions')}</css.textSmall></css.wrapPlus>
                                            </css.content>
                                        }
                                    </>
                                    {/*<BottomCourse onSubmitForm={onSubmitForm} continueBtnText={t(continueBtn)} continueClick={() => setGlobalState('ui.tabsPopup.currentStage', continueBtn)}/>*/}
                                </css.container>
                                <ExtensionsPop show={currentIndex !== -1} close={() => setCurrentIndex(-1)} item={currentIndex !== 'new' ? extras.value[currentIndex] : null}
                                    onDelete={() => {
                                        arrayHelpers.remove(currentIndex);
                                    }}
                                    onSubmit={(val) => {
                                        currentIndex === 'new' ?
                                            arrayHelpers.push(val) :
                                            arrayHelpers.replace(currentIndex, val);
                                    }
                                    } />
                            </>
                        )}
                    />
                </Form>
            </css.wrapForm>
        </>
    );
}
export default ExtensionsGlobal;
