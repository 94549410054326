import  * as css  from './TeamMemberRole.css';

function TeamMemberRole({icon,text,selected,type,onClick,isDisabled,cssStyle=''}) {
    
    return (
        <>
            <css.container onClick={onClick} className={!!isDisabled&&'ownerDisable'} cssStyle={cssStyle} data-cy={`${type}RoleSelect`}>
                <css.circle className={selected===type&&'selected'} data-cy={`${type}Validation`}>
                    <css.icon icon={icon}/>
                </css.circle>
                <css.text>{text}</css.text>
            </css.container>
        </>
    );
}

export default TeamMemberRole;