import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../../../component/input/Input';
import {Form} from 'formik';
import * as Yup from 'yup';
import {newBudget} from '../../../../assets/symbols/images/Images.css';
import BottomPopup from '../bottomPopup/BottomPopup';
import * as css from './ClearingPopup.css';
import InputList from '../../../../component/input/inputList/InputList';
import {useSelector} from 'react-redux';
import {bitBillingsValidations, checkIfBitOrBilling, defaultValidation, getAllCurrencies, getAllLanguages} from '../../functions/clearingInfo';
import {addAccountSuppliers, updateAccountSuppliers} from '../../../../redux/account/accountActions';
import FormContext from '../../../components/formContext/FormContext';
import {useEffect} from 'react';
import setGlobalState from '../../../../redux/setGlobalState';
import Supplier from './supplier/Supplier';
import {useState} from 'react';
import ClearingDefault from './clearingDefault/clearingDefault';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import {yupStringMin} from '../../../../functions/validationsYup';

function ClearingPopup({close, showClass, currentItem}) {
    const {t} = useTranslation('package');
    const account = useSelector(s=> s.account);
    const supplierInfo = useSelector(s=> s.supplier);
    const allCurrencies = supplierInfo?.allCurrencies;
    const allLanguages = supplierInfo?.allLanguages;
    const allSuppliers = supplierInfo?.allSuppliers;
    const [bitOrMonthly, setBitOrMonthly] = useState(false);
    const formRef = useRef();
    const packageSuppliers = useSelector(s=>s.account.packageSuppliers);
    const currenciesList = !bitOrMonthly?getAllCurrencies(allCurrencies):(bitOrMonthly==='bit'?[{key:'ILS', text:`ILS ${t('NIS')}`}]:[]);

    const onSubmitForm = async (_changedValues,values) => {
        const sameLangOrCur = defaultValidation(values.isDefault, bitOrMonthly, packageSuppliers, values.srid, values.currency, values.lang);
        if(sameLangOrCur){
            values.isDefault=false;
            bounceMessage(t(sameLangOrCur),'warning');
        }
        values = bitBillingsValidations(values, bitOrMonthly);
        if(currentItem)
            await updateAccountSuppliers(values);
        else
            await addAccountSuppliers(values);
    };

    const acceptClick = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const disabledCurrencyAndLang = (isDisabled) => {
        setBitOrMonthly(isDisabled);
    };

    const validationSchema = Yup.object().shape({
        srid: yupStringMin().max(30).required(),
        commission: Yup.number().required(),
        clearingCurrency: Yup.string().required(),
        currency: Yup.string().required(),
        languageAssign: Yup.string().required()
    });

    const initialValues = {
        srid: currentItem?.srid,
        clearingCurrency: currentItem?.clearingCurrency?currentItem.clearingCurrency:account.currency,
        commission: currentItem?.commission?currentItem?.commission:5,
        currency: currentItem?.currency?currentItem.currency:account.currency,
        languageAssign: currentItem?.languageAssign?currentItem.languageAssign:'all',
        isDefault: currentItem&&currentItem.isDefault? true : false,
    };

    useEffect(()=>{
        if(account.updateAccountSuppliersXHR==='SUCCESS'){
            close();
            setGlobalState('account.updateAccountSuppliersXHR', null);
        }
    },[account.updateAccountSuppliersXHR]);

    useEffect(()=>{
        if(account.addAccountSuppliersXHR==='SUCCESS'){
            close();
            setGlobalState('account.addAccountSuppliersXHR', null);
        }
    },[account.addAccountSuppliersXHR]);

    useEffect(()=>{
        if(!currentItem)
            return;
        const disabledInput = checkIfBitOrBilling(currentItem?.srid, allSuppliers);
        setBitOrMonthly(disabledInput);
    },[]);

    return (
        <css.clearingPopup>
            <FormContext 
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitForm}
            >
                <Form>
                    <BottomPopup close={close} showClass={showClass} icon={newBudget} disabledAcceptBtn={false}
                        headerText={currentItem ? t('editingSupplierInformation') : t('assignSupplierToPackage')} textCancel={t('leftBtn')} textAccept={t('finishBtn')} acceptClick={acceptClick}  XHR={account.updateAccountSuppliersXHR||account.addAccountSuppliersXHR}>
                        <css.wrapper>
                            <Supplier name='srid' currentItem={currentItem} disabledCurrencyAndLang={disabledCurrencyAndLang}/>
                            <InputList name='clearingCurrency' list={currenciesList} text={t('clearingCurrency')} description={t('theCurrencyThroughWhich')} />
                            <Input name='commission' text={t('clearingPercentages')} description={t('percentagesAccordingToClearing')}/>
                            <InputList name='currency' list={currenciesList} text={t('assignTerminalToCurrency')} description={t('currencyChosenByCustomer')} />
                            <InputList name='languageAssign' list={bitOrMonthly?[]:getAllLanguages(allLanguages, t)} text={t('assignTerminalToLanguage')} description={t('languageChosenByCustomer')}/>
                        </css.wrapper>
                        <ClearingDefault name={'isDefault'} bitOrMonthly={bitOrMonthly}/>
                    </BottomPopup>
                </Form>
            </FormContext>
        </css.clearingPopup>
    );
}
export default ClearingPopup;
