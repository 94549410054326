import request  from '../../library/request';
import {hasAccountAccess, hasRoleAccess} from '../permission';
import {updateRequestStatus} from '../../library/request';

const permissionPromise = (accountPermission, rolePermission, action, method) => {
    return new Promise((res, rej) => {
        let roleAccess = true, accountAccess = true;
        if(rolePermission)
            roleAccess = hasRoleAccess(rolePermission);
        if(accountPermission)
            accountAccess = hasAccountAccess(accountPermission);
        if(!roleAccess || !accountAccess) {
            updateRequestStatus(action, method, 'FAILED', 'notAccess');
            rej(`not ${roleAccess ? 'account' : 'role'} access`);
        }
        res();
    });
};

const securityRequest = async (accountPermission, rolePermission, action, method, ...args) => {
    return await permissionPromise(accountPermission, rolePermission, action, method).then(
        () => request(action, method, ...args)
    ).catch(error => {
        throw error;
    });
};

export default securityRequest;

