import styled from 'styled-components/macro';
import {ItemContent} from '../../../../component/item/Item.css';
import {text} from '../../../../component/modeBtn/ModeBtn.css';
import {arrowDown, success} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, DEFAULT_COLOR, ellipsis, ellipsisLines} from '../../../../globalVariables/GlobalVariables';

export const padding = styled.div`
    padding-inline-start: 20px;
`;
export const supplierName = styled.div`
    width: 140px;
    padding-inline-start: 10px;
    font-weight: 700;
    overflow: hidden;
    ${ellipsis}
`;

export const commission = styled.div`
    padding-inline-start: 10px;
    font-weight: 700;
    width: 45px;
`;

export const type = styled(padding)`
    width: 60px;
`;

export const language = styled(padding)`
    width: 43px;
`;

export const currency = styled(padding)`
    width: 80px;
`;

export const isDefault = styled.div`
    width: 17px;
    border-radius: 50px;
    height: 17px;
    background-color: ${DEFAULT_COLOR};
    cursor: pointer;
    &.yes{
        ${BGImageCenter(success, '10px')}
    }
`;

export const toggle = styled(padding)`
    width: 80px;
    ${text}.small{
        width: 19px;
    }
`;

export const assignedEvents = styled(padding)`
    cursor:pointer;
    width: 145px;
    ::before{
        content:"" ;
        ${BGImageCenter(arrowDown, '8px')};
        width: 15px;
        float:left;
        height: 8px;
        margin-top: 5px;
    }
    ${ItemContent}{
        min-width: 25px;
        width: 25px;
    }
`;

export const moreOp = styled.div`
    margin:4px;
`;

export const supplierDescription = styled.div`
    ${ellipsis}
`;

export const supplierClearer = styled.div`
    font-weight: 400;
    font-size: 12px;
`;