import * as css from './TicketRow.css';
import {POPUP_DURATION} from '../../../../globalVariables/GlobalVariables';
import MobileTicketRow from './mobileTicketRow/MobileTicketRow';
import useShow from '../../../../hooks/useShow';
import useMobile from '../../../../hooks/useMobile';

function TicketRow({ticket,children,TicketPopup}) {
    const isMobile = useMobile();
    const popupShow = useShow(POPUP_DURATION);//replace

    return (
        <>{isMobile ?
            <MobileTicketRow ticket={ticket} openPopup={popupShow.open} />
            :
            <css.row data-cy={`ticketId${ticket?.tid}`}>
                {children(popupShow)}
            </css.row>
        }
        {popupShow.isOpen && <TicketPopup tid={ticket?.tid} close={popupShow.close} showClass={popupShow.showClass}/>}
        </>
    );
}
export default TicketRow;
