import useOnClickOutside from './UseOnClickOutside';
import useShow from './useShow';

export default function useShowModal(ref,duration=300){
 
    const object=useShow(duration);

    useOnClickOutside(ref,object.close);

    return (object);
}

