import * as css from './ErrorIcon.css';
import Tooltip from '../tooltip/Tooltip';
function ErrorIcon({message = '', dataCyName}) {

    return (
        <Tooltip text={message}>
            <css.icon data-cy={dataCyName}/>
        </Tooltip>
    );
}
export default ErrorIcon;
