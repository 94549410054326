import createReducer from '../createReducer';

const initialState = {
    event:{
        //dates:[{date:'','meetings':[]}]
    },
    tickets:[
        // {
        // tid  ,
        // participants: [{//2
        //     qid,
        //     firstName:'',
        //     presentAmount:null,
        //     meetings:[
        //         {tdid, present, comment, }        
        //                 ],
        // meetings:[{tdid}]//2
    // }
    ],
    currentTid:0,
    participantReport:{},
    
    currentParticipant:{},
    isAcceptedAll:null,
    getEventAttendanceXHR:null,
    getEventAttendanceReportsOfMeetingXHR:null,
    updatePresenceXHR:null,
    getTicketAttendanceXHR:null,
    getAttendanceDatesXHR:null,
    attendanceSendSmsXHR:null,
};

const reducer = createReducer('attendance', initialState);

export default reducer;
