import {useRef} from 'react';
import {useState} from 'react';
import {Else, If, Then} from 'react-if';
import  login from '../../assets/animations/login.json';
import Animation from '../animation/Animation';
import * as css from './User.css';

function User({user,doOnMark=()=>{},doOnRemove=()=>{},isMark=false,notVerified=false}) {

    const [bigV, setBigV] = useState(false);
    const [hasAnimation, setHasAnimation] = useState(false);

    const timerRef =useRef(null);

    const onclick = ()=>{
        if(!isMark)
            onMark();
        else
            doOnRemove(user);
    };

    async function onMark (){
        setBigV('bigV');
        if(doOnMark)
            await doOnMark(user);
        timerRef.current=setTimeout(() => {
            setBigV(false);
            if(!user.active) {
                setHasAnimation(true);
                timerRef.current = setTimeout(() => {
                    setHasAnimation(false);
                }, 3000);}
        }, 300);
            
    }

    return (
        <css.container className={'show'}>
            {hasAnimation&&<Animation animation={login} isLoop={true} />}
            <css.wrapImage onClick={onclick} className={`${bigV}`}  data-cy="user"> 
                {(isMark && !bigV)&&<css.smallPresent data-cy='verifiedUser'/>}
                {(notVerified && !bigV)&&<css.unverifiedUser/>}
                <css.image face={user?.picture} className={`${bigV}`}  />
            </css.wrapImage>
            <css.body>
                <If condition={user.status==='unauthenticated'}>
                    <Then>
                        <css.name>{user?.email}</css.name>
                    </Then>
                    <Else>
                        <css.name>{user?.nameFirst}</css.name>
                        <css.name>{user?.nameLast}</css.name>
                    </Else>
                </If>
               
            </css.body>
        </css.container>
       
    );

}

export default User;
