import {useField} from 'formik';
import * as css from './LogoStep.css';
import {useTranslation} from 'react-i18next';

function LogoTopStep() {
    const [{value}]=useField('logo');
    const {t}=useTranslation('newEvent');
    return (
        <css.top>
            <css.text>
                {t('logo')}
            </css.text>
            <css.topImage image={value}/>
        </css.top>
    );
}

export default LogoTopStep;
