import * as css from './SubTopMenu.css';

function SubTopMenu({title}) {
    return (
        <>
            <css.circle/>
            <css.pathItem>
                <span>{title}</span>
            </css.pathItem>
        </>
    );
}
export default SubTopMenu;