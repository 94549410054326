import styled from 'styled-components/macro';
import {STRONG_BLUE, WHITE, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {wrap} from '../../../component/textEditor/editorWrapper/EditorWrapper.css';
import {BRANDING_PAGE_WIDTH} from '../BrandingPage.css';

export const wrapper=styled.div`
    display: flex;
    flex-direction: column;
    width: ${BRANDING_PAGE_WIDTH};
    max-width: 100%;
    margin-block:15px;
    background-color: ${WHITE};
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    padding: 10px;
    ${mobileMedia}{
        width: 100%;
    }
`;

export const subject=styled.div`
    border: 0.5px solid ${STRONG_BLUE};
    border-radius: 10px;
    width: 100%;
    min-height: 45px;
    max-height: max-content;
    margin: 5px 0;
    font-size: 13px;
    padding-inline-start: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    ${mobileMedia}{
        height: 35px;
        font-size: 12px;
    }
`;

export const emailSubject=styled.div`
    font-size: 12px;
`;

export const top=styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const wrapEditor = styled(wrap)`
    margin-top: 2px;
    width: 100%;
    min-height: 50px;
    .ql-editor{
        margin-top: 0px;
        margin-inline-end: 80px;
    }
    .ql-toolbar.ql-snow{
        display: none;
    }
`;