/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components/macro';
import {btn} from '../../component/button/btn/Btn.css';
import {heart, hearts, success} from '../../assets/symbols/symbols/Symbols.css';
import convertHexToRgbA from '../../functions/ConvertHexToRgbA';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
import zIndex from '../../globalVariables/zIndex';
export const types =
{
    warning: {
        backgroundColor: '#EF4C2E',
        circleColor: '#C9031B',
        circleBorderColor: '#C9021B7C',
    },
    success: {
        backgroundColor: '#88A316',
        circleColor: '#C6D600',
        circleBorderColor: '#C6D60057',
    },
    attention: {
        backgroundColor: '#D10362',
        circleColor: '#ED87C2',
        circleBorderColor: '#ED87D567',
    },
    choice: {
        backgroundColor: '#002843',
        circleColor: '#87D2ED',
        circleBorderColor: '#87D2ED67',
    }
};

export const UserMessages = styled.div`
    background-color: red;
    height:0px;
    width: 100%;
    background-color: ${p=>types[p.type].backgroundColor};
    bottom: 0;
    position: fixed;
    left:0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms;
    overflow:hidden;
    background-size: 65px;
    z-index:${zIndex.highestModal};
    &.show{
      overflow:unset;
      height:60px;  
      @media screen and (max-width: 1000px) {
        height: 150px;
        flex-direction: column;
        justify-content: unset;
        }  
    }
      ::before{
        content:'';
        ${p => (!p.theme.rtl ? ' transform: scaleX(-1);' : '')};
        inset-inline-start:50px;
        height:100%;
        width: 95px;
        ${BGImageCenter(hearts,'90px')}
        }

    @media screen and (max-width: 1000px) {
        content:unset
      }
  `;

export const circle = styled.div`
   ${p=>BGImageCenter(p.type==='e'?success:heart,'12px 12px')}
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: ${p=>types[p.type].circleColor};
    border: 2px solid ${p=>types[p.type].circleBorderColor};
    @media screen and (max-width: 1000px) {
      margin-top: -12.5px;
  }
`;

export const Message = styled.div`
   color:white;
   text-align:${p => p.theme.rtl ? 'right' : 'left'};
   padding: 0 15px;
   min-width: 800px;
   white-space: pre-line;
   letter-spacing: 1.4px;
   font-weight: 600;
   @media screen and (max-width: 1000px) {
    min-width: 0px;
    letter-spacing:1px;
    line-height: 18px;
    align-items: center;
        height: calc(100% - 60px);
        display: flex;
  }
  `;
export const buttons = styled.div`
  display: flex;
  ${btn}{
    color:white;
    margin:0 8px;
    background-color: ${p=>types[p.type].backgroundColor};
    border:2px solid ${p=>types[p.type].circleBorderColor};
    min-width: 90px;
    height: 30px;
    &.main{
      color: ${p=>types[p.type].backgroundColor};
      background-color:#fff;
     }
    :hover{
      color:white;
      background-color:rgba(${p=>convertHexToRgbA(types[p.type].circleColor)},0.5);
    }
    &.timer{
      :hover{
        color: ${p=>types[p.type].backgroundColor};
        background-color:#fff;
      }
    }
}
`;

export const close = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    inset-inline-start:20px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    background-size: 12px 12px;
    border-radius: 50%;
    background-color: ${p=>types[p.type].circleColor};
    border: 2px solid ${p=>types[p.type].circleBorderColor};
    @media screen and (max-width: 1000px) {
      margin-top: -12.5px;
  }
`;

