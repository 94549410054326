import * as css from './PaymentForm.css';
import {useTranslation} from 'react-i18next';
import SettingItem from '../settingItem/SettingItem';
import SubSettingItem from '../subSettingItem/SubSettingItem';
import InputList from '../../../component/input/inputList/InputList';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import {useSelector} from 'react-redux';
import {updateEventSettings} from '../../../redux/event/eventActions';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import Button from '../../../component/button/button/Button';
import {useHistory} from 'react-router-dom';
import AccountPermission from '../../components/accountPermission/AccountPermission';
import store from '../../../store';
import InputAmount from '../../../component/input/inputAmount/InputAmount';
import DateInput from '../../../component/input/dateInput/DateInput';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';
import Input from '../../../component/input/Input';
import {permissionToCamelCase} from '../../package/componnent/upgradePopup/UpgradePopup';

export const subSettingInputs = (settings, category, settingsXHR, t) => {
    const update = (name, setting) => {
        if(settings?.[name] !== setting)
            updateEventSettings(name, setting, category);
    };
    return {
        getInput: (name) =>
            <SubSettingItem text={t(`${name}`)}>
                <css.input>
                    <Input name={name} events={{onBlur:update}} className={settingsXHR?.[`${name}XHR`]}  />
                </css.input>
            </SubSettingItem>,
        getAmountInput: (name,text,amountText) =>
            <SubSettingItem text={text||t(`${name}`)}>
                <css.input>
                    <InputAmount name={name} events={{onBlur:update}} className={settingsXHR?.[`${name}XHR`]} amountText={amountText|| store.getState().event.currencySymbol} />
                </css.input>
            </SubSettingItem>,
        getToggle: (name,onClick=null) =>
            <SubSettingItem text={t(`${name}`)}>
                <ModeBtn click={onClick?()=>onClick(name,settings,!settings[name]):() => update(name, !settings?.[name])}
                    status={settings?.[name]}
                    requestStatus={settingsXHR?.[`${name}XHR`]}
                    dataCyName={name} />
            </SubSettingItem>,
        getList: (name, list ,onClick=update) =>
            <SubSettingItem text={t(`${name}`)}>
                <css.input>
                    <InputList name={name} list={list} onChange={(name,key)=>onClick(name,key,settings)} className={settingsXHR?.[`${name}XHR`]} t={t} />
                </css.input>
            </SubSettingItem>
    };
};

function PaymentForm({close}) {
    const {t} = useTranslation('event');
    const cancelPermissions = useSelector(state => state.account.permissions)?.find(p => permissionToCamelCase(p.permission) === 'userCancelTicket');
    const payments = useSelector(state => state.event.settings?.payments);
    const billings = useSelector(state => state.event.settings?.billings);
    const access = useSelector(state => state.event.settings?.access);
    const suspended = useSelector(state => state.event.settings?.suspended||{});
    const settingsXHR = useSelector(state => state.forms.eventSettings);
    const maxPaymentsList = [...Array(11).keys()].map(item => ({text: item + 2, key: item + 2}));
    const maxBillingsList = [...Array(35).keys()].map(item => ({text: item + 2, key: item + 2}));
    const cancelTicketListAfter = [...Array(17).keys()].map(item => ({text: item + 14, key: item + 14}));
    const cancelTicketListBefore = [...Array(7).keys()].map(item => ({text: item + 1, key: item + 1}));
    cancelTicketListAfter.unshift({text: t('always'), key: -1});
    cancelTicketListBefore.unshift({text: t('always'), key: -1});
    const {getAmountInput, getToggle, getList} = subSettingInputs(billings, 'billings', settingsXHR, t);
    const suspendedInputs = subSettingInputs(suspended, 'suspended', settingsXHR, t);
    const paymentsInputs = subSettingInputs(payments, 'payments', settingsXHR, t);
    const cancelOrdersInputs = subSettingInputs(access, 'access', settingsXHR, t);
    const history = useHistory();
    const ticketsClick = () => {
        history.push('tickets');
        close();
    };
    const validationSchema = Yup.object().shape(
        {
            minForPayments: Yup.number(),
            maxPayments: Yup.number().min(2).max(12),
            minSumBillings: Yup.number(),
            maxBillings: Yup.number().min(2).max(12),
        });

    const initialValues = {
        minForPayments: payments?.minForPayments,
        maxPayments: payments?.maxPayments,
        minSumBillings: billings?.minSumBillings,
        maxBillings: billings?.maxBillings,
        activateAuto: suspended.activateAuto||'manual',
        activateTime: suspended.activateTime,
        cancelDaysBeforeEvent: access?.cancelDaysBeforeEvent || 7,
        cancelDaysAfterOrder: access?.cancelDaysAfterOrder || 14,
    };
    
    const suspendedList = [{key: 'date', text: 'byDate'}, {key: 'auto', text: 'activateWhenAllSold'}, {key: 'manual', text: 'manual'}];
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            <Form>
                <EventRolePermission rolePermission={'manage event'}>
                    <AccountPermission permission="event cash">
                        <SettingItem settingCategory='event' name='paycash' permission='event cash' rolePermission='manage event' />
                    </AccountPermission>
                    <AccountPermission permission="billing">
                        <SettingItem settingCategory='billings' name='hasBillings' permission='billing' rolePermission='manage event' >
                            {getAmountInput('minSumBillings')}
                            {getList('maxBillings', maxBillingsList)}
                            {getToggle('ticketsPayments')}
                            {getToggle('ticketsPaymentsLock')}
                            <SubSettingItem text={t('ticketsPaymentsList')}>
                                <Button BtnProps={{text: t('tickets'), onClick: ticketsClick, width: 70, height: '20px', backgroundColor: '#222'}} />
                            </SubSettingItem>
                        </SettingItem>
                    </AccountPermission>
                    <AccountPermission permission="payments">
                        <SettingItem settingCategory={'payments'} name={'hasPayments'} permission="payments" rolePermission='manage event'>
                            {paymentsInputs.getAmountInput('minForPayments')}
                            {paymentsInputs.getList('maxPayments', maxPaymentsList)}
                        </SettingItem>
                    </AccountPermission>
                    <AccountPermission permission='suspended'>
                        <SettingItem name={'susStatus'} settingCategory={'suspended'} permission="suspended" >
                            {suspendedInputs.getList('activateAuto', suspendedList)}
                            {suspended.activateAuto === 'date' &&
                            <SubSettingItem text={t('activateTime')}>
                                <css.input>
                                    <DateInput
                                        name='activateTime'
                                        className={settingsXHR?.activateTimeXHR}
                                        onChange={val => updateEventSettings('activateTime', val, 'suspended')} />
                                </css.input>
                            </SubSettingItem>}
                        </SettingItem>
                    </AccountPermission>
                    {cancelPermissions.status==1&&<AccountPermission permission="user cancel ticket">
                        <SettingItem settingCategory={'access'} name={'userCancelTicket'} permission="user cancel ticket" rolePermission='manage event'>
                            {cancelOrdersInputs.getList('cancelDaysBeforeEvent',cancelTicketListBefore )}
                            {cancelOrdersInputs.getList('cancelDaysAfterOrder',cancelTicketListAfter)}
                        </SettingItem>
                    </AccountPermission>}
                </EventRolePermission>
            </Form>
        </Formik>);
}

export default PaymentForm;
