import {useTranslation} from 'react-i18next';
import * as css from './ImageHeader.css';

function ImageHeader({isSuspended}) {
    const {t}=useTranslation('branding');
    return (
        <css.recommendedSize>
            <span>{t(isSuspended?'suspendedImageText':'imageText')}</span>
            <span>{t('recommendedSize')}</span>
        </css.recommendedSize>
    );
}

export default ImageHeader;