function faces(index) {
   
    const facesList = [
        'https://static.tickchak.co.il/all/groupImage_1662115707090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_WXHYeRmWuaKJK56lAqGd8.png',
        'https://static.tickchak.co.il/all/groupImage_1662114034090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_B6sHZlfe6gzJ5ZyT47Cu7.png',
        'https://static.tickchak.co.il/all/groupImage_1662113728090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_QdFrkWEqqH1w1n4DYP5FX.png',
        'https://static.tickchak.co.il/all/groupImage_1662113790090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_xg6V4BzaoMH7vTyBx8qdX.png',
        'https://static.tickchak.co.il/all/groupImage_1662113858090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_aM-uK5Qq00Px8cR8b5s2O.png',
        'https://static.tickchak.co.il/all/groupImage_1662113905090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_JOiRqULzajEjAECvzB_hv.png',
        'https://static.tickchak.co.il/all/groupImage_1662113968090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_K_1KCnuSkhFEGTstDz8Gj.png',
        'https://static.tickchak.co.il/all/groupImage_1662113425090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_e5rWiXerX8LYT8Gm61Zxh.png',
        'https://static.tickchak.co.il/all/groupImage_1662113469090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_AkmlGOHgWsDdKwSdbCRvL.png',
        'https://static.tickchak.co.il/all/groupImage_1662113517090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_0HNHq0hSva7tJcjhzsuxn.png',
        'https://static.tickchak.co.il/all/groupImage_1662113561090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_1sjEQvlsiismXbGCriMuJ.png',
        'https://static.tickchak.co.il/all/groupImage_1662113652090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_uy9v4ylaG8VwAIjZZ-vC-.png',
        'https://static.tickchak.co.il/all/groupImage_1662113356090zZzR1SXR1SHNkMx0mckN2VzMDSwAlZ.png_97hjJ_PcN6qdeJN9F9MIq.png',
        'https://static.tickchak.co.il/all/groupImage_1662115901090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_9KGG6QSqyNZrCkPA8-nGy.png',
        'https://static.tickchak.co.il/all/groupImage_1662116246090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_Xc2QH9t2IIit-5U3WcY0X.png',
        'https://static.tickchak.co.il/all/groupImage_1662116353090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_5i55H_qK1jf-dfEh6z7fs.png',
        'https://static.tickchak.co.il/all/groupImage_1662116509090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_a7jgLkx0VfiYYjSCtV2Hq.png',
        'https://static.tickchak.co.il/all/groupImage_1662116639090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_bZlV7qVALIP5d7lU2PncZ.png',
        'https://static.tickchak.co.il/all/groupImage_1662116181090TUpZ1daJHUxBDTWVHc2Q3YpN1TPZmT.png_15HRVHlgLpV8z7TmlJAv-.png',
        
    ];
    
    return facesList[index%19];

}
export default faces;