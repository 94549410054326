import styled from 'styled-components/macro';
import {BGImageCenter, WHITE} from '../../../globalVariables/GlobalVariables';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';

export const button = styled.button`
    background-color: ${p=>p.BGColor || WHITE};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    border: none;
    min-width: 110px;
    padding-block: 5px;
    padding-inline: ${p=>p.icon?33:8}px 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    &::before {
        width: ${p=>p.icon?15:0}px;
        height: 20px;
        content: '';
        display: block;
        position: absolute;
        inset-inline-start: 10px;
        ${p=>p.icon && BGImageCenter(symbol[p.icon], '15px')}
        margin-inline: ${p=>p.icon?5:0}px;
}`;