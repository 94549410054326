import {useSelector} from 'react-redux';
import * as css from './ClusterOptions.css';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import useShow from '../../../../hooks/useShow';
import ListWrap from '../../../../component/listWrap/ListWrap';
import List from '../../../../component/list/List';
import {bag, blackDuplicate, copy, rectPlus, remove, symbolEventDark} from '../../../../assets/symbols/symbols/Symbols.css';
import Copy from '../../../../component/copy/Copy';
import {TICK_DOMAIN} from '../../../../config/config';
import store from '../../../../store';

function ClusterOptions({event, groupsList, handleOnClickItem}) {
    const {t} = useTranslation('cluster');
    const {i18n} = useTranslation('global');
    const ref = useRef(null);
    const {open, close, isOpen, showClass} = useShow();
    const cluster = useSelector((state) => state.cluster);

    const opTableList = {
        title: t('actions'),
        arr: [{key: 1, option: 'delete', text: t('deleteEvent'), type: 'icon', icon: remove},
            {key: 2, option: 'duplicate', text: t('duplicateEvent'), type: 'icon', icon: blackDuplicate},
            ...(event.cgid ? [{key: 3, option: 'deleteFromGroup', text: t('deleteFromGroup')}]:
                [{key: 3, type: 'icon', icon: rectPlus, text: t('addEventGroup'), list: cluster?.groups?.length > 1 ? {title: (t('groups')), arr: groupsList} : {title: (t('groups')), arr: [{key: 1, text: t('openGroup'), type: 'createGroup'}]}}]),
            {key: 4, option: 'transactions', text: t('transactions'), type: 'icon', icon: bag},
            {key: 5, option: 'tickets', text: t('tickets'), type: 'icon', icon: symbolEventDark.tickets},
            {key: 6, option: 'salesPage', text: t('salesPage'), type: 'icon', icon: copy, rightChildren: <Copy link={`${TICK_DOMAIN}/${store.getState().event?.name}/form/tickets?event=${event.eid}${(event.cgid ? `&group=${event.cgid}` : '')}`} />},
        ],
        onClick: handleOnClickItem
    };

    return (

        <css.options ref={ref} onClick={(e) => { e.stopPropagation(); open(); }}>
            {!!isOpen &&
                (event.cgid ? (
                    i18n.dir() === 'rtl' ?
                        <List close={close} left={ref.current.getBoundingClientRect().x +'px'} top={0} showClass={showClass} title={t('actions')} list={opTableList['arr']} onClick={handleOnClickItem} fixedTop={ref.current.getBoundingClientRect().y} />
                        :
                        <List close={close} right={ref.current.getBoundingClientRect().x  +'px'} top={0} showClass={showClass} title={t('actions')} list={opTableList['arr']} onClick={handleOnClickItem} fixedTop={ref.current.getBoundingClientRect().y} />
                ) : <ListWrap close={close} left={ ref.current.getBoundingClientRect().x  + 'px'} top={0} showClass={showClass} title={t('actions')} list={opTableList} onClick={handleOnClickItem} fixedTop={ref.current.getBoundingClientRect().y } />)}

        </css.options>
    );
}
export default ClusterOptions;
