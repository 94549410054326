import {invertedString} from './englishHebrewKeyMap';

export const localSearch = (array, value, searchColumns) => {
    const absoluteValue = invertedString(value);

    const searchAllColumns = !Array.isArray(searchColumns) || searchColumns.length === 0;

    const filteredList = array.filter((item) => {
        if(!item) return false;

        const keysToSearchIn = searchAllColumns ? Object.keys(item) : searchColumns;

        return keysToSearchIn.some((key) => {
            const columnValue = String(item[key]).toLowerCase();
            const searchTerms = value.split(' ');
            const absoluteSearchTerms = absoluteValue.split(' ');

            return (
                searchTerms.every((term) => columnValue.includes(term.toLowerCase())) ||
                absoluteSearchTerms.every((term) => columnValue.includes(term.toLowerCase()))
            );
        });
    });

    return filteredList;
};
