/* eslint-disable @typescript-eslint/no-unused-vars */
import {useSelector} from 'react-redux';
import {Formik, Form} from 'formik';
import SubSettingItem from '../subSettingItem/SubSettingItem';
import MultipleAccount from '../../../component/multipleAccount/MultipleAccount';
import SettingItem from '../settingItem/SettingItem';
import {useEffect} from 'react';
import setGlobalState from '../../../redux/setGlobalState';

function MultipleAccountForm() {
    const {assignedAccounts,allAccounts} = useSelector(s=>s.event.settings?.multipleAccounts);
    const {pid}=useSelector(s=>s.account);
   
    const initialValues={
        assignedAccounts: assignedAccounts,
        filteredAccounts:[],
        allAccounts: allAccounts
    };
    useEffect(() => {
        return ()=>{
            setGlobalState('event.settings.multipleAccounts.allAccounts', 0);
            setGlobalState('event.settings.multipleAccounts.assignedAccounts',  null);
        };
    }, []);
  
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
        >
            <Form>
                <SubSettingItem>
                    <MultipleAccount  />
                </SubSettingItem>
                {pid === 2000 &&
                    <SettingItem name={'allAccounts'} settingCategory={'multipleAccounts'}/>
                }
            </Form>
        </Formik>
    );
}

export default MultipleAccountForm;