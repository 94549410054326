import styled from 'styled-components/macro';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';

export const shareImageBase = styled.div`
    position:relative;
    width:${props => props.width}px;
    height:${props => props.height}px;
    max-width: 100%;
  @media screen and (max-width: 750px){
        width:${props => props.width ? props.width + 'px' : '42vh'};
        height: ${props => props.height ? props.height + 'px' : '30vh'};
    }   `;

export const sIBImage = styled.div`
    border-radius: 10px;
    width:${props => props.width}px;
    height:${props => props.height}px;
    border:2px solid #76d6e8;
    background-image:url("${props => props.img}");
    background-size: cover;
    background-position: center center;
    cursor: pointer;
    max-width: 100%;
  @media screen and (max-width: 750px){
        width:${props => props.width ? props.width + 'px' : '42vh'};
        height: ${props => props.height ? props.height + 'px' : '30vh'};
        margin: 0 auto;
    }
    `;
export const sIBBot = styled.div`
    padding-top: 10px;
    display: flex;
    min-width: 140px;
    margin : 0 auto;
    @media screen and (max-width: 750px){
    width: 42vh;
    }
`;

export const sIBBotChild = styled.div`
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    width:20px;
    height:20px;
    margin-${props=>props.theme.rtl?'left':'right'}:10px;
    ${p => BGImageCenter(p.icon, 'contain')}
    &.remove{
        position: absolute;
        ${props => props.theme.rtl ? 'left' : 'right'}:0px;
        width:19px;
        height:18px;
        margin-${props=>props.theme.rtl?'left':'right'}:0;
    }
    &.edit{
     pointer-events:none;
     opacity:0.5 ;
    }
`;
