import styled from 'styled-components/macro';
import * as ticket from '../TicketRow.css';

export const block = styled.div`
    border-inline-end:1.5px solid #ddd;
    min-width:100px;
    width:35% ;
    height:100% ;
    display:flex ;
    justify-content:center ;
    flex-direction:column ;
    padding:10px 7px;

`;

export const boldText =ticket.boldText;

export const text = ticket.text;

export const width45 = styled.div`
    width:55px;
`;