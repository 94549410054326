import createReducer from '../createReducer';

const initialState = {
    permission:null,
    requestArguments:null,
    token:null,
    isVerification:false,
    isActivating:false,
    level:null,
};

const reducer = createReducer('security', initialState);

export default reducer;