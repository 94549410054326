import styled from 'styled-components/macro';
import {dFlexCenter, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';

export const title=styled.div`
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-top: 25px;
    ${mobileMedia}{
        font-size: 16px;
    }
`;

export const wrapAll=styled.div`
  ${dFlexCenter}
 flex-direction:column ;
 height:calc(100% - 60px) ;
 ${scrollbar}
`;

export const wrapAttendanceItem=styled.div`
     margin:20px 0;
     width:100% ;
     ${scrollbar};
     height: calc(100% - 110px);
     display:flex ;
     align-items:center ;
     flex-direction:column ;
     
`;
