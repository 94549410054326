import styled from 'styled-components/macro';
import zIndex from '../globalVariables/zIndex';
import {tabletScreen} from '../globalVariables/GlobalVariables';
export const layout = styled.div`
    height:100%;
    width:100%;
    margin-top: 0;
    transition: all 300ms;
    &.show{
        height:calc(100% - 60px) ;
        /* pointer-events: none; */
        @media screen and (max-width: 1000px){  
            height:calc(100% - 150px) ;
     }
        .message{
            /* pointer-events: auto; */
        }
    }
      @media screen and (max-width: 1400px){
        -moz-transform: scale(0.80, 0.80);
        zoom: 0.80;
        zoom: 80%;
      }
      @media screen and (max-width: 1000px){
        -moz-transform: scale(1, 1);
          zoom: 1;
          zoom: 100%;
         margin-inline-start:${props=>(props.marginValue?props.marginValue:'0')}px;
          // ${props=>''+props.margin+':'+(props.marginValue?props.marginValue:'0')}px;
         // ${props=>'margin-'+props.margin+':'+(props.marginValue?props.marginValue:'0')}px;
         background:${props=>props.marginValue?'#0000008c':'#00000000'};
         -webkit-tap-highlight-color: transparent;
     }
    
`;

export const layoutContent = styled.div`
    height:calc(100% - 50px);
    width:100%;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    @media screen and (max-width: 1000px){  
     }
`;
export const layoutContentFrame = styled.div`
    @media screen and (min-width:${tabletScreen}px){
        ${p=>p.realtime && 'position: absolute'};
    }
    height:100%;
    width:100%;    
    &.event:not(.build){
            ${p=>!p.realtime && `width:calc(100% - ${p.mini?'65':'230'}px);`};
            @media screen and (max-width: 1000px){  
            width: 100%;
        }
    } 
    &.form{        
           z-index:${zIndex.baseHighest};   
    }
`;
export const MyComponent=styled.div`
    width:100%;  
    height:calc(100% - 50px);
    position: relative;
    &.account{
      height:100%
    }
  iframe{
        width: 100%;
        height: 100%;
        border:0;
    }
`;