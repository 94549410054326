import * as css from './NoTicketsScreen.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import BtnAdd from '../../../../component/button/btnAdd/BtnAdd';

function NoTicketsScreen({onClick}) {
    const eventType = useSelector(s => s.event.type);
    const {t} = useTranslation('ticket');
    return (
        <css.container data-cy='NoTicketsScreen'>
            <css.frame>
                <css.image type={eventType}/>
                <css.title>{t(`noScreenTitle${eventType}`)}</css.title>
                <BtnAdd text={t(`noScreenBtn${eventType}`)} onClick={onClick}/>
            </css.frame>

        </css.container>
    );
}

export default NoTicketsScreen;
