import React from 'react';
import './SettingsHover.css';

const RtlCss = React.lazy(() => import('./SettingsHover-rtl'));

export default function SettingsHover(props) {
    return (
        <>
            {(props.lang === 'he') && <RtlCss />}
            <div className="mailing-setting-hover">
                <div className="m-s-h-action" image="delete" onClick={()=>props.handle(props.id, 'sending_status', 'CANCEL')} />
            </div>
        </>
    );
}

