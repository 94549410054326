import {useTranslation} from 'react-i18next';
import * as css from './ArtistsStep.css';
import InputSearch from '../../../../component/inputSearch/InputSearch';
import {useState} from 'react';
import {addArtist, addArtists, getArtistsBySearch} from '../../../../redux/event/eventActions';
import List from '../../../../component/list/List';
import useShow from '../../../../hooks/useShow';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';
import Artists, {artistArrayHelpers} from './artists/Artists';
import AnotherOneCircle from '../../../../component/anotherOneCircle/AnotherOneCircle';
import useMobile from '../../../../hooks/useMobile';
import {useField} from 'formik';
import {add} from '../../../../assets/symbols/symbols/Symbols.css';

export const onClickArtist=async (changedValues)=>{
    await addArtists(changedValues.artists);
};
function ArtistsStep() {
    const {t} = useTranslation('newEvent');
    const [searchArtists, setSearchArtists] = useState([]);
    const [search, setSearch] = useState('');
    const isMobile=useMobile();
    const [{value}, ,helpers]=useField('artists');

    const {open, close, isOpen, showClass} = useShow();

    const getSearchArtists = (isSearchEmpty = false) => {
        const exceptionIds = value.map(obj => obj.artistId);
        if(search || isSearchEmpty){
            getArtistsBySearch({search,exceptionIds}).then((result)=>{
                if(!result.artists?.length)
                    setSearchArtists([{key: 0, text: t('addNewArtist',{name:search}),onClick:addNewArtist, icon:add}]);
                else 
                    setSearchArtists(result.artists);
            });
        }
        else
            setSearchArtists([]);
       
    };
    useEffectOnUpdate(()=>{
        if(searchArtists.length)
            open();
        else
            close();
    },[searchArtists]);

    const onAddArtist=(item)=>{
        helpers.setTouched(true);
        setSearch('');
        artistArrayHelpers.push(item);
    };

    const addNewArtist=()=>{
        addArtist({title:search}).then((artist)=>{
            onAddArtist(artist);   
        });
    };

    const listHeight=isMobile?{}:{height:130};
    return (
        <css.artistWrapper>
            <InputSearch text={t('findArtists')} search={1} getSearch={getSearchArtists} value={search} setSearch={setSearch} autoFocus={false} />
            <css.artistSearch>{isOpen &&
                <List showClass={showClass} close={close} onClick={onAddArtist} list={searchArtists} width='100%' classes={'desktopBehavior'} {...listHeight} />
            }</css.artistSearch>
            <css.artists>
                <Artists />
                <AnotherOneCircle title={t`anotherArtist`} onClick={()=>getSearchArtists(true)}/>
            </css.artists>
        </css.artistWrapper> 
    );
}

export default ArtistsStep;
