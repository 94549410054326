import {useEffect} from 'react';
import useNavigationLink from './useNavigationLink';

const useQueryStrings=(key, callback)=>{
    const params = new URLSearchParams(location.search);
    const useNavigation = useNavigationLink();
    const paramsKey = params?.get(key);

    useEffect(async () => {
        if(paramsKey) {
            callback(paramsKey);
            useNavigation.close(key);
        }
    }, [paramsKey]);
};
export default useQueryStrings;