import {useSelector} from 'react-redux';
import * as css from './LoginByBtn.css';

function LoginByBtn({text, className ,onClick}) {

    const isKeyboardOpen=useSelector(s=>s.ui.isKeyboardOpen);

    return (
        <css.button onClick={onClick}  id ="loginByPassword"  data-cy ="loginByPassword" className={isKeyboardOpen&&className==='google'&&'hidden'} >
            <css.logo className={className} />
            <css.text>{text}</css.text>
        </css.button>
    );
}

export default LoginByBtn;
