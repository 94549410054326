import {useTranslation} from 'react-i18next';
import * as css from './SmartSearchOnList.css';
import {useState} from 'react';
import {localSearch} from '../../../library/searchFunctions';
import useMobile from '../../../hooks/useMobile';
import useTimeout from '../../../hooks/useTimeout';
import {ThemeProvider} from 'styled-components';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';

function SmartSearchOnList({list, searchColumns=[], setSearchArray, notLocalSearch = null}) {
    const timer = useTimeout();
    const [value, setValue] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const {t} = useTranslation('global');
    const isMobile = useMobile();

    useEffectOnUpdate(() => {
        if(!isExpanded) {
            setValue('');
        }
    }, [isExpanded]);

    useEffectOnUpdate(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            const searchValue = value.toLowerCase();
            const filteredList = notLocalSearch
                ? notLocalSearch(list, searchValue).then((res)=>setSearchArray(res))
                : localSearch(list, searchValue, searchColumns);setSearchArray(filteredList);
      
        }, 800);
    }, [value]);
  
    return (
        <ThemeProvider theme={{isExpanded:isExpanded}}>
            <css.wrapper data-cy="SmartSearchOnList" className={isMobile && 'mobileState'}>
                <css.icon data-cy="SmartSearchClose" onClick={()=>setIsExpanded(!isExpanded)}/>
                {(!isMobile||isMobile&&isExpanded)&&<css.input
                    value={value}
                    autoComplete="off"
                    name="search"
                    placeholder={isExpanded ? t('searchPlaceHolder') : t('search')}
                    onChange={(e)=>setValue(e.target.value)}
                    onFocus={()=>setIsExpanded(true)}
                    onBlur={()=>{!value && setIsExpanded(false);}}
                />}
            </css.wrapper>
        </ThemeProvider>
    );
}

export default SmartSearchOnList;
