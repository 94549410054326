const userMessage = {
    text:null,
    type:null,
    acceptText:null,
    closeText:null,
    status:null,   
    timer:null,
    isModal:false,
};

export default userMessage;