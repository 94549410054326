import styled from 'styled-components/macro';
import {Template} from '../../../../component/templates/Template.css';

export const container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  gap: 4%;
  background-color: #EEEEEEAD;
  display: grid;
  border-radius: 15px;
  justify-items: center;
  grid-template-areas: ${p=>p.gridAreas};
  ${p=>Template(p.windowWidth||'370px')};
  ${p=>p.gridMoreStyle}
`;

export const generalElement = styled.div`
  grid-area: ${p=>p.gridArea};
  width: ${p=>p.width || '50px'};
  height: ${p=>p.height || '50px'};
  background-color: #EEEEEEAD;
  border-radius: 6px;
  ${p=>p.moreProps}
`;