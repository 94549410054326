import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getDate, getHourMinutes} from '../../library/dates';
import {TICK_DOMAIN, TICLI} from '../../config/config';
import {useEffect} from 'react';
import {getEventGroups} from '../../redux/tickets/ticketsActions';
export const variableTexts={
    givechak:{
        eventLink:'givechakLink',
        eventTitle:'givechakTitle'
    }
};
export function useVariables() {
    const event = useSelector(s => s.event);
    const user = useSelector(s => s.user);
    const tickets = useSelector(s => s.tickets);
    const producer = useSelector(s => s.account.packagesProducer);
    const {currentLang, data} = useSelector(s => s.branding ?? {});
    const ticketTitle = tickets?.tickets?.length > 0 ? tickets?.tickets[0]?.title : '';
    const account = useSelector(s => s.account);
    const susStatus = useSelector(s => s.event?.settings?.suspended?.susStatus);
    const suspendedStatus=account.permissions?.find((item) => item.permission === 'suspended')?.status;
    const {t} = useTranslation('variables');
    const timeVariables = {
        timeOpenstartDate : event?.timeStart && getDate(event?.timeStart),
        timeOpenstartDateHeb: data[currentLang]?.date?.hebrewStartDate || data[currentLang]?.date?.hebrewDate,
        timeOpenstartTime:getHourMinutes(event?.timeOpen||event?.timeStart),
        timeStart: event?.timeStart && getHourMinutes(event?.timeStart),
        timeOpenstartText:`${t('timeOpenstartText')}: `

    };
    // TODO when givchack
    // const givechakVariables = {
    //     refName: t('refName'),
    //     refTotal: t('refTotal'),
    //     refTarget:t('refTarget'),
    //     sendWhatsapp: t('sendWhatsappValue'),
    // };
    const suspendedVariables = {
        billingLineSuspended: t('billingLineSuspendedText')
    };

    const defaultVariables = {
        eventTitle: event?.title,
        nameFirst: user?.nameFirst,
        nameLast: user?.nameLast,
        ticketLink: `https://${TICLI}/ticket`,
        ticketLinkPrint: `https://${TICLI}/ticket?print`,
        ...(event.location && {location: event.location}),
        ...(event.timeStart && {...timeVariables}),
        ...(event.locationMap && {waze: event.waze}),
        invoiceLink: `${t('invoiceLinkValue')} https://${TICLI}/invoice`,
        ticketName: ticketTitle,
        ...(ticketTitle && {ticketNameAmount: `3 ${ticketTitle}`}),
        eventLink: `${TICK_DOMAIN}/${event.name}`,
        currencySymbol: event.currencySymbol,
        eventCurrency: event.currencySymbol,
        externalVoucher: `${t('externalVoucherValue')} <123456>`,
        billingLine: `${t('billingLineText')} ${event.supplierName}`,
        producerName: producer?.contactName,
        producerPhone: producer?.contactPhone,
        ticketText: tickets.tickets[0]?.text || t('ticketTextValue'),
        // TODO when area
        ...(event.type === 'area' && {seats: t('yourSeats')}),// + event?.area[0]?.title}), + t(", שורה 1, מושב 1")
        ...(tickets?.groups?.length > 0 && {groupName: tickets?.groups[0]?.title}),
        // ...(event.type === 'givechak' && {...givechakVariables}),
        ...((susStatus && suspendedStatus===1) && {...suspendedVariables}),

    };
    const disabledVariables = {
        ...(!event.timeStart && {...timeVariables}),
        ...(!event.location && {location: event.location}),
        ...(!event.locationMap && {waze: event.waze}),
        ...(!ticketTitle && {ticketNameAmount: `3 ${ticketTitle}`}),
        ...((susStatus && suspendedStatus!==1) && {...suspendedVariables}),
    };
    useEffect(async ()=>{
        if(!tickets?.getEventGroupsXHR)
            await getEventGroups();
    });
    return {defaultVariables, disabledVariables};
}
