import * as css from './RowTableBudget.css';
import {useTranslation} from 'react-i18next';
import {deleteBudgetItem, updateBudgetItem} from '../../../../../../redux/account/accountActions';
import List from '../../../../../../component/list/List';
import useShow from '../../../../../../hooks/useShow';
import ModeBtn from '../../../../../../component/modeBtn/ModeBtn';
import {bounceMessage} from '../../../../../../layout/userMessages/UserMessage';

function RowTableBudget({item, index, onClickRow}) {
    const {t} = useTranslation('package');

    const {open, close, isOpen, showClass} = useShow();

    const toggleClick = (e, val) => {
        e.stopPropagation();
        updateBudgetItem(index, {values: {budgetStatus: val}, budgetIndex: item.budgetIndex});
    };
    const listClick = (e) => {
        e.stopPropagation();
        if(isOpen)
            close();
        else
            open();
    };

    const handleOnClickList = (itemList) => {
        switch (itemList.type) {
            case 'deleteBudget':
                if(item.budgetDefault)
                    bounceMessage(t('deletesDefaultMessage'),'warning');
                else
                if(item.countEvents)
                    bounceMessage(t('deleteBudgetMessage'),'warning',
                        {text:'continueBtn', onClick:() => deleteBudgetItem(index, item.budgetIndex, item.countEvents)}
                    );
                else
                    deleteBudgetItem(index, item.budgetIndex, item.countEvents);
                break;
        }
        close();
    };

    const optionsList = [{key: 1, text: t('deleteBudget'), type: 'deleteBudget'}];

    return (<>
        <css.row onClick={() => onClickRow(item, index)} data-cy="budgetItem">
            <css.field> <css.boldText>{item.budgetTitle}</css.boldText></css.field>
            <css.field><css.boldText>{item.budgetIndex}</css.boldText></css.field>
            <css.field> <css.text>{item.countEvents ? item.countEvents : 0} {t('eventsBudget')}</css.text> </css.field>
            <css.wrapBudgetDefault>{!!item.budgetDefault && <css.budgetDefault />}</css.wrapBudgetDefault>
            <css.wrapMBtn>
                <ModeBtn click={(val, e) => toggleClick(e, val)} status={item.budgetStatus} />
            </css.wrapMBtn>
            <css.wrapList>
                <css.options data-cy="budgetItemOptions" onClick={(e) => listClick(e)} >
                    {isOpen && <List top={0} right={'-120px'} width={'200px'} showClass={showClass} close={close} list={optionsList} title={t('actions')} onClick={handleOnClickList} />}
                </css.options>
            </css.wrapList>
        </css.row>

    </>
    );
}

export default RowTableBudget;
