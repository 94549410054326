import Robot from '../robot/Robot';
import ChildrenLoader from '../ChildrenLoader';

function LoadingRobot({xhr,text,explain}) {

    return (
        <ChildrenLoader xhr={xhr} >
            <Robot explanationText={explain} text={text} />
        </ChildrenLoader>
    );
}

export default LoadingRobot;
