import styled from 'styled-components/macro';
import * as scroll  from '../../../component/scrollbar/Scrollbar.css';

export const icon=styled.div`
    background-repeat: no-repeat;
    height: 17px;
    width: 18px;
    background-size: contain;
    background-position: center;
    margin-left: 10px;
`; 
export const contentArea=styled.div`
    height: 58%;
    padding: 20px 48px 0;
    position: relative;
    cursor: text;
    :focus {
        outline: 0px solid transparent;
    }
    @media screen and (max-width: 750px){  
        height: calc(100% - 160px);
        padding: 0px 48px 10px;
    } 
    &.focus{
        @media screen and (max-width: 800px){
            height: calc(100% - 97px);
        }
    }
`;
export const contentMiddle=styled(scroll.ScrollBarArrow)`
    height: 100%;
    width: 100%;
    cursor:text;
    border: none;
    resize: none;
    padding: 1px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    .contentEditable{
        height: 100%;
        width: 100%; 
    }
    .contentEditable:focus{
        outline: 0px solid transparent;
    }
    .menuPopUp{
        right: -200px;
        top: -35px;
    }
    .menuPopUp.show{
        right: 31px;
    }
    :focus {
        outline: 0px solid transparent;
        border:none
    }
    & span{
        color:#76d6e8;
        cursor:pointer;
        border-bottom: 1px solid;
        position: relative;
        display: inline-block;
        padding: 0px 4px;
        line-height: 1.1;
        transition: all 200ms ease 0s;
        -webkit-user-select: none; /* Chrome/Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
    
        /* Not implemented yet */
        -o-user-select: none;
        user-select: none;    
    }
    & span:hover{
        border:1px solid;
        border-radius: 28px;
        padding: 0 5px 0 20px;
    }
    & span:hover:before{
        content:'';
        width:10px;
        height:10px;        
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %2376d6e8; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-232.576 -19.594)'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 6px;
        top: 4px;
    }
`;

export const writeOrTemplate=styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    @media screen and (max-width: 750px){  
        width: 100%;
        text-align: center;
        ${props => props.theme.rtl ? 'left' : 'right'}: 36%;
        margin-${props => props.theme.rtl ? 'right' : 'left'}: 10px;
    } 
`;

export const template=styled.div`
    font-weight: 700;
    cursor:pointer;
    transition: all 200ms;
    margin-${props => props.theme.rtl ? 'right' : 'left'}: 5px;
    :hover{
        border-bottom: 1px solid;
    }
    ::before{
        content:'';
        float:${props => props.theme.rtl ? 'left' : 'right'};
        width:10px;
        height:10px;        
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %23484848; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-232.576 -19.594)'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        margin: 7px;  
    }
`;

export const write=styled.span`
`;