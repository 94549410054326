import  {useRef} from 'react';
import * as css from './MoreOperations.css';
import List from '../list/List';
import ListWrap from '../listWrap/ListWrap';
import {useTranslation} from 'react-i18next';
import useShow from '../../hooks/useShow';

function MoreOperations(props) {
    const {title,list,onClick,listType='list',width,fixedTop=true}=props;
    const {i18n} = useTranslation('global');
    let ref=useRef();
    const {open, close, isOpen, showClass} = useShow(500);

    const handleOnClickItem=(item,num)=>{
        close();
        onClick(item,num);
    };

    return (
        <css.moreOperation ref={ref} onClick={(e)=>{e.stopPropagation();open();}}>
            {/* <css.moreOperation ref={ref} onClick={open}> */}
            {!!isOpen &&
                (listType === 'list' ? (
                    i18n.dir() === 'rtl' ?
                        <List close={close} width={width} left={(fixedTop?ref.current.getBoundingClientRect().x:1) + 'px'} showClass={showClass} title={title} list={list} onClick={handleOnClickItem} fixedTop={fixedTop?ref.current.getBoundingClientRect().y:false} />
                        :
                        <List close={close} width={width} right={(fixedTop?ref.current.getBoundingClientRect().x:1) + 'px'} showClass={showClass} title={title} list={list} onClick={handleOnClickItem} fixedTop={fixedTop?ref.current.getBoundingClientRect().y:false} />
                ) : <ListWrap close={close} left={fixedTop?ref.current.getBoundingClientRect().x:1 + 'px'} top={0} showClass={showClass} title={title} list={list} onClick={handleOnClickItem} fixedTop={fixedTop?ref.current.getBoundingClientRect().y:false} />)}

        </css.moreOperation>
    );
}
export default MoreOperations;

