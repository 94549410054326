import styled from 'styled-components/macro';
import {dFlexCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {Scrollbar} from '../scrollbar/Scrollbar.css';
import zIndex from '../../globalVariables/zIndex';

export const tableContainer = styled(Scrollbar)`
  height:calc(100% - 100px) ;
  position:relative ;
  padding-bottom:30px ;
  ${mobileMedia}{
    width:max-content ;
    margin:auto;  
    height:calc(100% - 70px) ;
  }
`;

export const table = styled.div`
width:100% ;
`;

export const placeHolder = styled.div`
  &.dragging{
    display:flex ;
  }
  ${dFlexCenter};
  display:none ;
  font-weight:600 ;
  font-size:16px ;
  letter-spacing:2px ;
  border: 2px dashed #ADADAD;
  border-radius: 5px;
  z-index:${zIndex.under};
  position:absolute ;
  top:${p=>p.top}px;
  width:100%;
  height:${p=>p.rowHeight}px;
`;

export const loader=styled.div`
  position:relative ;
  width:100%;
  height:100px ;
  overflow:hidden ;
  margin-top:10px ;
  ${dFlexCenter}
  .animation{
      width:400px ;
  }
  `;
