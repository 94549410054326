/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components/macro';
import {calendar} from '../../../assets/symbols/symbols/Symbols.css';
import {
    BGImageCenter,
    mobileMedia, options,
} from '../../../globalVariables/GlobalVariables';
import {images} from '../../tickets/ticketsPopUp/courseTicket/courseGuides/CourseGuides.css';

const IMAGE_WIDTH = '70px';
const DATE_HEIGHT = '25px';
const ICON_WIDTH='20px';
const NUMBER_WIDTH='90px';

export const container = styled.div`
  max-width: calc(100vw - 50px);
  height: 115px;
  min-height: 115px;
  display: flex;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;
  ${mobileMedia} {
    max-width: calc(100% - 10px);
  }
`;

export const image = styled.div`
  width: ${IMAGE_WIDTH};
  background-color: #${p=>p.color};
  ${p=>BGImageCenter(p.image,'contain')}
  background-position:bottom center;
  height: 100%;
  border-radius: 0 5px 5px 0;
  &.icon{
    ${p => BGImageCenter(p.icon, '30px')}
    background-position: bottom right;
    padding-bottom: 10px;
    padding-inline-start: 10px;
    background-origin: content-box;

  }
`;

export const body = styled.div`
  width: calc(100% - ${IMAGE_WIDTH});
  height: 100%;
  padding: 8px;
`;

export const name = styled.div`
  padding-top: 10px;
  font-weight: 700;
  font-size: 16px;
  height: calc(100% - ${DATE_HEIGHT});
  ${images}{
    justify-content: end;
    padding-inline-end: 15px
  }
`;
export const nameTop = styled.div`
    display: flex;
  justify-content: space-between;
`;
export const operation = styled(options)`
  position:relative; 
`;
export const bottom = styled.div`
  height: ${DATE_HEIGHT};
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 20px;
`;

export const calendarIcon = styled.div`
  width: ${ICON_WIDTH};
  height: 20px;
  ${BGImageCenter(calendar, '12px')}
`;
export const days = styled.div`
  width: calc(100% - 120px);
`;
export const date = styled.div`
line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.6px !important;
  width: calc(100% - ${ICON_WIDTH} - ${NUMBER_WIDTH});
  white-space: nowrap;
`;
export const number = styled.div`
width: ${NUMBER_WIDTH};
text-align: end;
>span{
  padding-inline-end: 3px;
  font-weight: 600;
}
`;
