import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import * as css from './ClusterGroupTitle.css';
import List from '../../../../../component/list/List';
import ClusterPicker from '../../clusterPicker/ClusterPicker';
import InputEdit from '../../../../../component/input/inputEdit/InputEdit';
import useShow from '../../../../../hooks/useShow';
import {deleteGroup, updateGroupTitle} from '../../../../../redux/cluster/clusterActions';
import setGlobalState from '../../../../../redux/setGlobalState';
import Tooltip from '../../../../../component/tooltip/Tooltip';
import GroupImagePopUp, {changeArrColorTemp} from '../../groupImagePopUp/GroupImagePopUp';
import Copy from '../../../../../component/copy/Copy';
import {TICK_DOMAIN} from '../../../../../config/config';
import {bounceMessage} from '../../../../../layout/userMessages/UserMessage';

function ClusterGroupTitle(props) {
    const {group, showEvent, updateShowEvents, getEvents} = props;

    const updateGroupImageXHR = useSelector(state => state.cluster.updateGroupImageXHR);
    const groupColor = group?.clusterGroupColor;
    const {t, i18n} = useTranslation('cluster');
    const tLayout = useTranslation('layout');
    const event = useSelector(state => state.event);
    const [showPicker, setShowPicker] = useState(0);
    const popUpImage = useShow(500);

    const opGroupList = [{key: 1, type: 'addEvent', text: t('addEvent')}, {
        key: 2,
        type: 'deleteGroup',
        text: t('deleteGroupTitle')
    }];

    useEffect(() => {
        if(updateGroupImageXHR == 'SUCCESS' || updateGroupImageXHR == 'FAILED') {
            setTimeout(function () {
                if(updateGroupImageXHR == 'SUCCESS')
                    popUpImage.close();
                setGlobalState('cluster.updateGroupImageXHR', null);
            }, 1500);
        }
    }, [updateGroupImageXHR]);

    const {close, open, isOpen, showClass} = useShow(500);

    const deleteG = async () => {
        changeArrColorTemp(group.clusterGroupColor, 'sub');
        if(!group.events)
            getEvents(event?.eid, group.cgid);
        await deleteGroup(event?.eid, group.cgid);
    };

    // const arrButton = [
    //     {type: {text: tLayout.t('btnMessage')}},
    //     {type: {text: tLayout.t('btnMessage1'), onClick: () => deleteG()}}
    // ];

    const handleGroupOnClick = (item) => {
        switch (item.type) {
            case 'addEvent':
                setShowPicker(1);
                updateShowEvents(group.cgid, true);
                close();
                break;
            case 'deleteGroup':
                bounceMessage(t('deleteGroup', {group: group.title}),'warning',
                    {text: tLayout.t('btnMessage1'), onClick: () => deleteG()},
                    {text:tLayout.t('btnMessage')});
                break;
        }
    };

    const closePicker = () => {
        setShowPicker(0);
    };
    const onClickStop = (e) => {
        e.stopPropagation();
    };
    const saveTitle = async (title) => {
        await updateGroupTitle(event?.eid, group.cgid, {title: title});
    };

    const addImage = () => {
        setGlobalState('ui.colorsPicker.currentColor', groupColor);
        popUpImage.open();
    };

    return (<>
        <css.groupTitle color={groupColor} className={showEvent && 'open'} onClick={() => updateShowEvents(group.cgid, !showEvent)}>
            <css.groupTitleArrow className={showEvent && 'open'} />
            <css.groupTitleTitle ><InputEdit onClick={onClickStop} value={group.title} width={'100%'} height={'100%'} icon={'group'}
                widthIcon={'50px'} saveInput={saveTitle} /></css.groupTitleTitle>
            <css.groupTitleOp onClick={onClickStop}>
                <Tooltip text={t('addEvent')}>
                    <css.gTOAddEvent onClick={() => handleGroupOnClick({type: 'addEvent'})} >
                        {!!showPicker && <ClusterPicker right={i18n.dir() === 'rtl' ? '-200px' : '100px'} bottom={'-100px'} cgid={group.cgid} getEvents={getEvents}
                            closePicker={closePicker} />}</css.gTOAddEvent>
                </Tooltip>
                <Tooltip text={t('addImage')}><css.gTOAddImage onClick={() => addImage()} /></Tooltip>
                <Tooltip text={t('linkToGroup')}><Copy link={`${TICK_DOMAIN}/${event.name}/form/tickets?group=${group.cgid}`} /></Tooltip>
                <Tooltip text={t('deleteGroupTitle')}><css.gTORemove text={t('deleteGroupTitle')} onClick={() => handleGroupOnClick({type: 'deleteGroup'})} /></Tooltip>
                <Tooltip text={t('moreOptions')}>
                    <css.gTOOp onClick={() => open()}>
                        {!!isOpen && <List close={close} left={0} showClass={showClass} title={t('group')} list={opGroupList}
                            onClick={handleGroupOnClick} />}
                    </css.gTOOp>
                </Tooltip>
            </css.groupTitleOp>
        </css.groupTitle>
        {!!popUpImage.isOpen && <GroupImagePopUp popUpImage={popUpImage} group={group} />}
    </>
    );
}

export default ClusterGroupTitle;
