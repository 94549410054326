import React, {useState} from 'react';
import './ScheduledChange.css';
import t from '../../../TranslateHe';
import Button from '../../../Shared/Button/Button';

const RtlCss = React.lazy(() => import('./ScheduledChange-rtl'));

function ScheduledChange(props) {
    const scheduled = props.scheduled;
    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });
    const scheduledDate = scheduled.toDateInputValue();
    const scheduledTime = ('0' + scheduled.getHours()).slice(-2) + ':' + scheduled.getMinutes();

    const [date, setDate] = useState(scheduledDate);
    const [time, setTime] = useState(scheduledTime);
    const [show, setShow] = useState(0);

    return (
        <>
            {(props.lang === 'he') && <RtlCss />}
            <div className="mailing-table-click">
                <div
                    className="m-t-c-icon"
                    image="list"
                    onClick={()=>{setShow(!show);}}
                />
                <div className={'m-t-c-input' + (show ? ' show' : '')}>
                    <div className="m-t-c-close" image="close" onClick={()=>{setShow(!show);}} />
                    <div className="m-t-c-title">{t('Change message timing')}</div>
                    <input type="date" className="date" value={date} onChange={(event) => {setDate(event.target.value);}} />
                    <input type="time" className="time" value={time} onChange={(event) => {setTime(event.target.value);}} />
                    <Button
                        text={t('Save')}
                        handle={scheduledChangeSend}
                    />
                </div>
            </div>
        </>
    );
    function scheduledChangeSend(event) {
        const newDate = new Date(date + ' ' + time);
        const nowDate = new Date();

        if(nowDate >= newDate){
            alert(t('Note! It is not possible to set a date that has already passed.'));
            setDate(scheduledDate);
            setTime(scheduledTime);
        } else if(newDate.getTime() == scheduled.getTime()){
            setShow(!show);
        } else {
            props.handle(props.id, 'scheduled', newDate);
            setShow(!show);
        }
    }
}

export default ScheduledChange;

