import {Else, If, Then} from 'react-if';
import BasicInput from './basicInput/BasicInput';
import DisabledInputFormik from './disabledInput/DisabledInputFormik';

function Input({name, text = '', description = '', icon , disabled = false, events, className, forwardRef, dataCy = ''}) {

    return (
        <If condition={disabled}>
            <Then>
                <DisabledInputFormik
                    events={events}
                    name={name}
                    text={text}
                    description={description}
                    icon={icon}
                />
            </Then>
            <Else>
                <BasicInput
                    dataCy={dataCy}
                    events={events}
                    forwardRef = {forwardRef}
                    name={name}
                    text={text}
                    description={description}
                    icon={icon}
                    className={className}/>
            </Else>
        </If>

    );
}

export default Input;
