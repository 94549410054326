import createReducer from '../createReducer';
import eventSettings from './eventSettings';

const initialState = {
    eventSettings:{...eventSettings}
};

const reducer = createReducer('forms',initialState);

export default reducer;

