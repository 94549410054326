import React from 'react';
import './Table.css';
import TableAction from './TableAction/TableAction';
import TableSearch from './TableSearch/TableSearch';
import TableFilter from './TableFilter/TableFilter';
import TableDetails from './TableDetails/TableDetails';
import SwitcherButton from '../SwitcherButton/SwitcherButton';
import TableRow from './TableRow/TableRow';
import TableLoading from './TableLoading/TableLoading';

const RtlCss = React.lazy(() => import('./Table-rtl'));

class Table extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tableActionActive: false,
            selectAll: false,
            searchValue: {'search':'', 'specialSearch':[]},
            mini: false,
            limit: 50,
            offset: 0,
            loadingRow: <TableLoading/>,
            isScroll: false,
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.div.addEventListener('scroll', this.handleScroll);
        this.setState({loadingRow:false});
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        const width = window.innerWidth;
        const mini = width <= 1024 ? true : this.state.mini;
        this.setState({mini: mini});
    }
    handleScroll(e){

        // this.div.removeEventListener("scroll", this.handleScroll);

        if(this.div.scrollTop + 800 > this.div.scrollHeight){
            if(this.props.getDetailsLoading){
                return;
            }
            if(this.props.handleScroll && typeof this.props.handleScroll === 'function'){
                if(this.state.offset > this.props.tableData.length){
                    this.setState({loadingRow:false});
                    return;
                }
                this.setState((prevState) => ({offset: prevState.offset + 50, loadingRow: <TableLoading/>}));
                this.props.handleScroll(this.props.tableData, this.state.limit, this.state.offset);
            }
            if(this.props.handleScrollAll || typeof this.props.handleScrollAll === 'function'){
                if(this.props.tableOffset > this.props.tableData.length){
                    this.setState({loadingRow:false});
                    return;
                }
                this.props.handleScrollAll();
            }
        }
        // this.div.addEventListener("scroll", this.handleScroll);
    }
    handleEvent(type, options){
        switch (type) {
            case 'change':
                this.props.handleChanges(options.id, options.name, options.value);
                break;
            case 'selected':
                this.handleSelect(options.id, type, options.value);
                break;
            case 'search':
                this.handleSearch(options.value, options.close, false);
                break;
            case 'specialSearch':
                this.handleSearch(options.value, 0, true);
                this.props.searchFunction(options, false);
                break;
            case 'showHideDetails':
                this.props.showHideDetails(options.id, this.state.limit, this.state.offset);
                break;
            case 'edit':
                this.props.handleChanges(options.id, options.name, options.value);
                break;
            case 'countdownStopped':
                this.props.handleChanges(options.id, 'process', 'end');
                break;
            case 'searchMessage':
                this.setState({loadingRow:false});
                this.props.searchFunction(options.id, true, options.search);
                break;
            default:
                break;
        }
    }

    handleSelect(key, name, value){
        if(key === 'selectAll'){
            this.props.tableData.forEach(function (item, i) {
                this.props.handleChanges(item.id, 'selected', value);
            }.bind(this));
            this.setState({selectAll: value});
        } else {
            this.props.handleChanges(key, 'selected', value);
            if(value === false){
                this.setState({selectAll: false});
            }
        }
    }

    handleSearch(searchValue, close, special){
        const value = {
            'search': close ? '' : (special ? this.state.searchValue.search : searchValue),
            'specialSearch': special ? searchValue : [],
        };
        this.setState({searchValue: value}, function () {
            this.props.searchFunction(value, false);
        });
    }

    render() {
        const type = this.props.data.type;
        const header = this.props.data.header;
        const mini = this.state.mini || this.props.allMessages;

        return (
            <>
                {(this.props.lang === 'he') && <RtlCss />}
                <div className={'table' + (mini ? ' mini ' : ' ') + (this.props.tableClass ? this.props.tableClass : '')}>
                    {
                        header && <div className="table-header">
                            <TableSearch handle={this.handleEvent} value={this.state.searchValue.search} search={header.search} lang={this.props.lang}/>
                            <TableFilter filter={header.filter}/>
                            <SwitcherButton data={header.switcherButton} type={type} handle={this.props.handleSwitcher} lang={this.props.lang}/>
                            <div className="table-title" />
                            <TableAction selected={this.state.selectAll} handle={this.handleEvent} active={this.state.tableActionActive}/>
                        </div>
                    }
                    <div className="table-content">
                        <div className="table-rows">
                            <div className="table-all-rows Scroll" ref={(div) => (this.div = div)}>
                                {this.props.getLoading ? <TableLoading /> :
                                    <TableRow
                                        content={this.props.data.content}
                                        data={this.props.tableData}
                                        handleEvent={this.handleEvent}
                                        mini={mini}
                                        lang={this.props.lang}
                                        isEvent={this.props.isEvent}
                                    />}
                                {this.props.tableClass && this.state.loadingRow}
                            </div>
                        </div>

                        {this.props.allMessages ? <TableDetails data={this.props.allMessages} handleEvent={this.handleEvent} lang={this.props.lang}/> : null }
                        {header &&  <div className="table-statistics" />}

                    </div>
                </div>
            </>
        );
    }
}

export default Table;