import {useRef} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';

export const useTimer = (seconds) => {
    const [timer,setTimer]=useState(seconds);
    let interval=useRef();

    const startTimer=()=>{
        interval.current=setInterval(() => {
            setTimer(timer=>timer-1);
        }, 1000);
    };
    const stopTimer=()=>{
        clearInterval(interval.current);
    };
    useEffect(()=>{
        startTimer();
        return ()=>stopTimer();
    },[]);

    useEffect(()=>{
        if(timer<=0)
            stopTimer();
    },[timer]);

    return {timer,setTimer,stopTimer,startTimer};
};