import * as css from '../../packageProducerBody/packageAccordion.css';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Input from '../../../../../component/input/Input';
import BankList from '../../bankList/BankList';
import setGlobalState from '../../../../../redux/setGlobalState';
import fileExtension from '../../../../../library/fileExtension';
import FileUpload from '../../../../../component/fileUpload/FileUpload';
import NumberInput from '../../../../../component/input/NumberInput';
import FormikFileUpload from '../../../../../component/fileUpload/FormikFileUpload';

function BankDetails({setBankKey}) {
    const {t} = useTranslation('package');

    const account = useSelector((state) => state.account);
    const bank = account?.bank;
    const packageVerified = account?.packageVerified;
    const bankVerified = account?.bankVerified;
    const file = useSelector(s => s.ui?.fileBase64);

    return (<>
        <css.bottomTitle>{t('bankTrans')}</css.bottomTitle>
        <css.title>{t('bankdesc')}</css.title>
        <css.wrapInputs>
            <Input name={'bankTransferOnBehalf'} text={t('accountOwner')} />
            <NumberInput name={'bankBranch'} text={t('branch')} className={(bankVerified && packageVerified) && (' verified')} />
            <BankList name={'bankNumber'} t={t} valid={true} bankKey={(key) => setBankKey(key)} className={(bankVerified && packageVerified) && (' verified')}> </BankList>
            <NumberInput name={'bankAccount'} text={t('accountNumber')} className={(bankVerified && packageVerified) && (' verified')} />
            <css.upload>
                <FormikFileUpload name={'bankVerificationFile'}  text={file.name && file.name !==''?file.name:t('uploadTitle')} desc={t('uploadDesc')} />
            </css.upload>
            {/*<FileUpload name={'bankVerificationFile'} title={file.title && file.title != '' ? file.title : t('uploadTitle')} desc={t('uploadDesc')} remove={deleteFile} value={file.base64} className={file.base64 ? 'valid' : ''} />*/}
        </css.wrapInputs>
    </>
    );
}

export default BankDetails;
