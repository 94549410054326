import * as css from './InputFrame.css';

function InputFrame({placeholder,onChange,error,value,dataCyName}) {
   
    return (
        <css.input className={error && 'error'} placeholder={placeholder} onChange={onChange} value={value} data-cy={`${dataCyName ? dataCyName : 'default'}Input`}/>
    );
}

export default InputFrame;
