import React from 'react';
import './TableLoading.css';

function TableLoading() {
    const rows = 8;
    let divRows = [];
    for(let i = 0; i < rows; i++){
        divRows.push(<div className="table-loading" key={i} />);
    }
    return (
        <div>
            {divRows}
        </div>
    );
}

export default TableLoading;