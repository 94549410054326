import PageWrapper from '../../components/pageWrapper/PageWrapper';
import {useEffect,useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import useShow from '../../../hooks/useShow';
import NewLeadPop from '../newLeadPop/newLeadPop/NewLeadPop';
import {getAutomatedLeads, getLeads} from '../../../redux/publish/leadActions';
import AutomatedLeadsArea from '../../../component/automatedLeadsArea/AutomatedLeadsArea';
import ExcelExportButton,{translatedData} from '../../components/exportExcel/ExcelExportButton';
import LeadsTable from '../leadsTable/LeadsTable';
import TableTitle from '../../components/tableTitle/TableTitle';
import TableContext from '../../components/tableContext/TableContext';
import {Else, If, Then} from 'react-if';
import NoLeadsScreen from '../noLeadsScreen/NoLeadsScreen';
import {hasAccountAccess, isMaster} from '../../../functions/permission';
import {statusLeadColor} from '../../../component/status/Status';
import * as css from './LeadPage.css';
import {cssLeadColumns} from '../leadsTable/leadRow/leadDetail/LeadDetail.css';

function LeadPage() {

    const {t}=useTranslation('publish'); 
    const leadPopShow = useShow();
    const {getLeadsXHR,getAutomatedLeadsXHR,leads,automatedLeads}=useSelector(s=>s.publish?.lead);
    const {lang}=useSelector(s=>s.user);
    const [currentLead,setCurrentLead] = useState();
    const contactNameSet = new Set();
    const statusSet = new Set();
    leads.forEach((item) => {
        item?.contactName !== '' && contactNameSet.add(item.contactName);
        item?.statusTitle && statusSet.add(item.statusTitle);
    });
    const uniqueContactNameArray = Array.from(contactNameSet).map((text) => ({
        text,
        type: 'radioBtn',
    }));
    const uniqueStatusArray = Array.from(statusSet).map((text) => ({
        key: text,
        leftChildren: (
            <css.item color={statusLeadColor[text]}>
                {t(text)}
            </css.item>
        ),
        type: 'radioBtn',
    }));
      
    const titleTableArray = [
        {text: t('title'), field:'title', action: 'sort'},
        {text: t('totalPrice'), field:'totalPrice', action: 'sort'},
        {text: t('transactions'), field:'transactions', action: 'sort'},
        {text: t('tickets'), field:'tickets', action: 'sort'},
        {text: t('ticliCount'), field:'ticliCount', action: 'sort'},    
        {text: t('ticliCountUnique'), field:'ticliCountUnique', action: 'sort'},
        {text: t('contacts'), field:'contacts', action: 'filter', list:{
            array:[{text: t('all'), key:'all', type:'radioBtn'}].concat([...uniqueContactNameArray]),
            title:t('listContactTitle'), filterField:'contactName'}},
        {text: t('status'), field:'status', action: 'filter', list:{
            array:[{text: t('all'), key:'all', type:'radioBtn'}].concat([...uniqueStatusArray]),
            title:t('listStatusTitle'), filterField:'statusTitle'}},
    ];
    useEffect(()=>{
        if(!getLeadsXHR)
            getLeads().then();
        if(!getAutomatedLeadsXHR)
            getAutomatedLeads().then();
    },[]);
    
    const openPop=(lead)=>{
        setCurrentLead(lead.ref?lead:null);
        leadPopShow.open();
    };

    const operations={buttons:[{key: 0, onClick:openPop, text: t('newLead')},
        {key: 1, props:{fileName:'leads-data', data: translatedData(automatedLeads.concat(leads),lang,t,['statusTitle']),lang:lang}, Comp: ExcelExportButton}]};
 
    return (
        <TableContext data={leads}>
            <>
                <PageWrapper image={'ticketsGroups'} {...((isMaster()||hasAccountAccess('publish'))?{operations}:{})}>
                    <>
                        <AutomatedLeadsArea/>
                        <If condition={leads?.length}>
                            <Then>
                                <TableTitle titleTableArray={titleTableArray} cssColumns={cssLeadColumns}/>
                                <LeadsTable leadPopOpen={openPop}/>
                            </Then>
                            <Else>
                                <NoLeadsScreen leadPopOpen={openPop}/>
                            </Else>
                        </If>                       
                    </>
                </PageWrapper>
                {leadPopShow.isOpen && <NewLeadPop show={leadPopShow} lead={currentLead}/>}
            </>
        </TableContext> 
    );
}

export default LeadPage;
