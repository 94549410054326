export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_API_URL = process.env.REACT_APP_SOCKET_URL;
export const FRONT_DOMAIN = process.env.REACT_APP_FRONT_DOMAIN;
export const TICK_DOMAIN = process.env.REACT_APP_TICK_DOMAIN;
export const ClASS_DOMAIN = process.env.REACT_APP_CLASS_DOMAIN;
export const TICK_DOMAIN_MY = process.env.REACT_APP_TICK_DOMAIN_MY;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
export const ZOHO_WIDGET_CODE = process.env.REACT_APP_ZOHO_WIDGET_CODE;
export const LIVE_DOMAIN = process.env.REACT_APP_LIVE_DOMAIN;

export const IS_LOCAL=process.env.REACT_APP_ENV==='local';
export const IS_PRODUCTION=process.env.REACT_APP_ENV==='production';

export const API_MAILING = process.env.REACT_APP_API_MAILING;
export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
export const HOTJAR_SV = process.env.REACT_APP_HOTJAR_SV;

export const GOOGLE_CLIENT_ID = '925559398483-ac1e3r1qldjmofebfv1tmo6j26sua1o9.apps.googleusercontent.com';

export const TICLI = process.env.REACT_APP_TICK_DOMAIN_TICLI;

export const GLASSIX_API_KEY = process.env.REACT_APP_GLASSIX_API_KEY;
export const GLASSIX_SNIPPET_ID = process.env.REACT_APP_GLASSIX_SNIPPET_ID;
export const GLASSIX_SNIPPET_ID_LOGIN = process.env.REACT_APP_GLASSIX_SNIPPET_ID_LOGIN;

export const EXCLUDE_PACKAGE_SUPPORT = [2638, 3201, 3275, 5110, 5296, 6518, 6678, 7950];
export const EXCLUDE_ACCOUNT_MANAGER_MANAGEMENT = [321, 3275, 4366, 5110, 5296, 6518, 6678, 7950, 8644];

export const WEATHER_ICONS = 'https://www.visualcrossing.com/img';
export const STATIC_PATH = process.env.REACT_APP_STATIC_PATH;

export const TICKCHAK_SUPPLIERS = [7, 10, 28, 66, 113, 474];