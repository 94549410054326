import {useEffect, useRef} from 'react';
import {useState} from 'react';
import MobilePopupModal from '../newPopup/mobilePopup/MobilePopupModal';
import * as css from './ProgressPopup.css';

//isComplete return only boolean value
function ProgressPopup({steps=[],close,showClass,list,title}) {
    const [currentIndex,setCurrentIndex]=useState(()=>{
        const notCompleteIndex= steps.findIndex(s=>!s.isComplete());
        return notCompleteIndex>-1?notCompleteIndex:0;
    });
    const isAllComplete= steps.every(s =>s.isComplete() );
    const isCurrentComplete=steps[currentIndex]?.isComplete();
    const timer=useRef(null);
    const prevIndex=useRef(currentIndex);
    
    useEffect(()=>{
        if(steps[prevIndex.current].isComplete()!==steps[currentIndex].isComplete())
            prevIndex.current=false;
        else
            prevIndex.current=currentIndex;
    },[currentIndex]);

    useEffect(()=>{
        clearTimeout(timer.current);
        if(prevIndex.current===false){
            prevIndex.current=currentIndex;
            return; 
        }
        if(isCurrentComplete){
            if(currentIndex<steps.length-1)
                timer.current=setTimeout(() => {
                    setCurrentIndex(prev=>prev+1);
                }, 2000);
        }
    },[isCurrentComplete]);

    useEffect(()=>{
        return () => { 
            clearTimeout(timer.current);
        };
    },[]);

    return (<>
        <MobilePopupModal title={title} close={close} showClass={showClass} list={list} height={steps[currentIndex]?.height||'550px'} cssStyle='overflow:visible;'>
            <css.wrap>
                <css.progress>
                    <css.firstImage className={isAllComplete|| 'show'}/>
                    <css.lastImage  className={isAllComplete &&'show'}/>
                    <css.circles data-cy ="circles">{
                        steps.map((s,index)=><css.circle
                            data-cy={`${s.comp.type.name}Circle`}
                            key={index} 
                            className={`${s.isComplete()&&'complete'} ${index===currentIndex&&'current'}`} 
                            index={!s.isComplete()?index+1:''}
                            onClick={()=>{
                                setCurrentIndex(index);
                                s.onClick&&s.onClick(index);}
                            }  >
                            {s.text}
                        </css.circle>)
                    }
                    </css.circles>
                </css.progress>
                <css.body>
                    {steps[currentIndex].comp}
                </css.body>
            </css.wrap> 
        </MobilePopupModal>
    </>
    );

}

export default ProgressPopup;

// const steps=[{
//     text:'one one one and some text',
//     comp:<div>one one one one one it is the first step</div>,
//     isComplete:()=>1===1?true:false//return boolean
// },{
//     text:'two',
//     comp:<><div>todey</div></>,
//     isComplete:()=>0?false:true
// },{
//     text:'three',
//     comp:<><div>the best</div></>,
//     onClick:(index)=>{alert(index);},
//     isComplete:()=>0?false:true
// },
// ];