export default function convertToCamelCase(inputString) {
    // Remove content within parentheses and parentheses from input string
    const stringWithoutParentheses = inputString.replace(/\([^)]*\)/g, '');

    const words = stringWithoutParentheses.split(' ');
  
    const camelCaseString = words
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
  
    return camelCaseString.charAt(0).toLowerCase() + camelCaseString.slice(1);
}
