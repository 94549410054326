import * as css from './InputDate.css';
import {useTranslation} from 'react-i18next';
import {ThemeProvider} from 'styled-components';

function InputDate(props) {
    const {t} = useTranslation('layout');
    const {onChange, index = null, doOnfocus = false, item, setFocus = false} = props;

    const handleOnChange = (e) => {
        onChange(item.name, e.target.value);
    };

    return (
        <ThemeProvider theme={{notValid: item.notValid, type: item.type, bgColor: item.bgColor}} >
            <css.inputDate >
                <css.diIcon icon="clock" />
                <css.isValid  />
                <css.wrapDate Idate={t('date')} Itime={t('time')} validationText={item.validation.text} className={(!item.value && 'defaultInput ') + (item.type == 'time' && ' inputTime ')}>
                    <css.lableInputDate>{item.text}</css.lableInputDate>
                    <input type={item.type} value={item.value} placeholder={item.type=='datetime-local'?'00:00 dd/mm/yyyy':''} onChange={(e) => handleOnChange(e)} onFocus={() => doOnfocus && doOnfocus(item, index)} onBlur={() => setFocus(0)} />
                </css.wrapDate>
            </css.inputDate>
        </ThemeProvider >
    );
}
export default InputDate;
