import * as css from './CodeInput.css';
import {useEffect, useRef, useState} from 'react';
import {If, Then, Else} from 'react-if';
import {useSelector} from 'react-redux';
import setGlobalState from '../../redux/setGlobalState';
const amount = 6;

function CodeInput({onUpdate=()=>{}, toResetCode}) {
    const isWrong = useSelector(state => state.ui.codeInput.isWrong);

    const [currentIndex, setCurrentIndex] = useState(0);

    const array = ['','','','','',''];
    const [values, setValues] = useState(array);

    const ref = useRef(null);
    const reset = () => {
        setCurrentIndex(0);
        setValues(array);
    };
    const getWebOtp = () =>{
        if('OTPCredential' in window) {
            const ac = new AbortController();
            navigator.credentials.get({
                otp: {transport:['sms']},
                signal: ac.signal
            }).then(otp => {
                const newValues = otp.code.split('');
                if(newValues.length ===6  && newValues.every(value=>value < 10 && value > -1)) {
                    setValues(newValues);
                    onUpdate(newValues.join(''));
                }    
            }).finally(() => {
                ac.abort();
            });
        }
    };
    useEffect(() => {
        ref.current?.focus();
    }, [currentIndex]);

    useEffect(() => {
        if(toResetCode)
            reset();
    }, [toResetCode]);

    useEffect(() => {
        if(isWrong) {
            reset();
        }
    }, [isWrong]);

    useEffect(() => {
        getWebOtp();
        return () => {
            setGlobalState('ui.codeInput', {expiredTime:null,code: '', isWrong: false});
        };
    }, []);

    const onPaste=(e) => {
        const val=e.clipboardData.getData('Text').split('');
        if(val.length!==6||!val.every(value=>value < 10 && value > -1))
            return;
        setValues(val);
        onUpdate(val.join(''));    
    };
    const onInput = (e) => {
        const value = e.target.value ;
        const inputValue = parseInt(e.key);
        const index = parseInt(e.target.name);
        let newValues = [...values];
        if(value && value.length === 1 && value >= 0 && value < 10) {
            newValues[index] = value;
            setValues(newValues);
            if(index < amount - 1)
                setCurrentIndex(index + 1);
            if(newValues.every(val => val !== '')) {
                onUpdate(newValues.join(''));
            }
            else if(inputValue >= 0 && inputValue < 10) {
                newValues[index] = inputValue;
                setValues(newValues);
                if(index < amount - 1)
                    setCurrentIndex(index + 1);
                if(newValues.every(val => val !== '')) {
                    onUpdate(newValues.join(''));
                }
            }   
        }
        else if(value.length === amount) {
            newValues = value.split('');
            setValues(newValues);
            onUpdate(newValues.join(''));
        }
        else if(index === amount - 1) {
            const hasEmpty = newValues.some((item => item === ''));
            if(onUpdate && !hasEmpty)
                onUpdate(newValues.join(''));
        }
        if(e.key === 'Backspace' || e.key === 'Delete') {
            newValues[index] = '';
            setValues(newValues);
            if(!newValues[index]){
                if(e.key === 'Delete' && index < amount - 1){
                    setCurrentIndex(index + 1);
                }
                else if(index > 0){
                    setCurrentIndex(index - 1);
                }     
            }
        }
        setGlobalState('ui.codeInput.code', newValues.join(''));
        
        if(isWrong)
            setGlobalState('ui.codeInput.isWrong', false); 
        
    };
    const getInput = (index) =>
        <input name={index} 
            type='tel'
            ref={currentIndex === index ? ref : null}
            onKeyUp={onInput} 
            value={values[index]} 
            onChange={()=>{}}
            autoComplete={'one-time-code'}
            onPaste={onPaste}
            onInput={onInput}
            className={(isWrong && !values.some(i => i)) ? ' wrong ' : (values[index] !== '' ? ' full ' : '')}
        />;

    return (<>
        <css.container >
            {array.map((_item, i) => 
                <If key={i} condition={i !== 3}>
                    <Then>
                        {getInput(i)}
                    </Then>
                    <Else>
                        <>
                            <css.colon>-</css.colon>
                            {getInput(i)}
                        </>
                    </Else>
                </If>            
            )}
        </css.container>
    </>
    );
}

export default CodeInput;
