import {useSelector} from 'react-redux';
import * as css from './PixelsScreen.css';
import {useContext} from 'react';
import {pixelContext} from '../../PixelsPage/PixelsPage';
import * as pixelIcons from '../../../../assets/symbols/symbols/pixelIcons';

function PixelSelection({pixelKey,className}) {
    const pixel=useSelector(s=>s.publish.pixel);

    const {getOpenPixel}=useContext(pixelContext);
    const onOpenPixel=getOpenPixel({key:pixelKey});

    const isSelectedSpecialPixel=(pixel.specialPixels.some(p=>p.key===pixelKey));

    return (
        <css.pixelIcon icon={pixelIcons[pixelKey]} data-cy={`${pixelKey}Pixel`}
            className={`${isSelectedSpecialPixel&&' selected'} ${className}`}
            onClick={!isSelectedSpecialPixel&&onOpenPixel}/>               
    );
}

export default PixelSelection;