import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './EditAttendance.css';
import List from '../list/List';
import useShow from '../../hooks/useShow';
import {blackSuccess, ex, line} from '../../assets/symbols/symbols/Symbols.css';
import {updatePresence} from '../../redux/attendance/attendanceActions';
import {useSelector} from 'react-redux';
import {bounceMessage} from '../../layout/userMessages/UserMessage';
import {getNextDays} from '../../library/dates';

function EditAttendance({fullDate,meeting}) {
    const refList = useRef();
    const {open,close,isOpen,showClass}=useShow();
    const {t} = useTranslation('attendance');
    const tLayout = useTranslation('layout');
    const {i18n} = useTranslation('global');
    const currentParticipant = useSelector(s => s.attendance.currentParticipant);
    const meetingDate = useSelector(s=>(s.attendance.tickets.find(t => t.tid === s.attendance.currentTid)?.meetings?.find(m => m.tdid === meeting.tdid)?.date));
    // const tid= useSelector(s=>s.attendance.currentTid);

    let attendanceArr ={
        'null':{color:'3abbd4',text:t('editAttendance')},
        '0':{color:'3abbd4',text:t('editAttendance')},
        '1':{color:'C6D600',text:t('present'),icon:blackSuccess},
        '2':{color:'FFDA28',text:t('late'),icon:line},
        '3':{color:'F26E6E',text:t('missing'),icon:ex}};

    const attendanceOption = [
        {key: '0', text: t('editAttendance'), rightChildren: <css.icon {...attendanceArr['0']}/>},
        {key: '1', text: t('present'), rightChildren: <css.icon {...attendanceArr['1']}/>},
        {key: '2', text: t('late'), rightChildren:<css.icon {...attendanceArr['2']}/>},
        {key: '3', text: t('missing'), rightChildren: <css.icon {...attendanceArr['3']}/>}
    ];

    const onClickItem=(item)=>{
        close();
        const updatePresent=()=>{ updatePresence(meeting.tdid,currentParticipant.qid?currentParticipant.qid:meeting.qid,{'present':parseInt(item.key)});};

        if(meetingDate>getNextDays(null,1)){
            bounceMessage(t('attendanceNote'),'warning',{text: tLayout.t('continueBtn'),onClick:updatePresent});
            return;
        }
        updatePresent();

    };

    return <>
        <css.wrapEdit>
            <css.editBtn onClick={open} {...attendanceArr[meeting.present]}> <css.text className={!meeting.present&&' edit '}>{attendanceArr[meeting.present]?.text}</css.text></css.editBtn>
        </css.wrapEdit>
        <css.wrapList ref={refList}>
            {!!isOpen && <List list={attendanceOption} close={close} onClick={onClickItem}
                {...{[i18n.dir() === 'rtl'?'right':'left']:refList.current.getBoundingClientRect().x +'px'}}
                fixedTop={refList.current.getBoundingClientRect().y - 40}
                showClass={showClass} title={`${t('attendance')+' '+fullDate}`}/>}
        </css.wrapList>
    </>;
}
export default EditAttendance;
