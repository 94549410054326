import useMobile from '../../../../../hooks/useMobile';
import WindowTemplate from '../../WindowTemplate/windowTemplate';

const mobileGrid = `"title . linkButton"
                    "graph graph graph"
                    "tableRow1 tableRow1 tableRow1"
                    "tableRow2 tableRow2 tableRow2"
                    "tableRow3 tableRow3 tableRow3"
                    "tableRow4 tableRow4 tableRow4"
`;
const grid = `"title . linkButton"
                "graph tableRow1 tableRow1"
                "graph tableRow2 tableRow2"
                "graph tableRow3 tableRow3"
                "graph tableRow4 tableRow4"
                        `;
const gridMoreStyle={                    
    'grid-auto-columns': '28% 40% 20%'
};
const settings = [
    {
        gridArea: 'title',
        width: '140px',
        height: '58px',
        moreStyle:{
            'place-self': 'baseline'
        }
    },
    {
        gridArea: 'linkButton',
        width: '111px',
        height: '29px'
    },

    {
        gridArea: 'graph',
        width: '166px',
        height: '166px',
        moreStyle:{
            'border-radius': '50%'
        }

    }, {
        gridArea: 'tableRow1',
        width: '100%',
        height: '60px'

    }, {
        gridArea: 'tableRow2',
        width: '100%',
        height: '60px'

    }, {
        gridArea: 'tableRow3',
        width: '100%',
        height: '60px'

    }, {
        gridArea: 'tableRow4',
        width: '100%',
        height: '60px'

    }
];
const TicketsTemplate = () => {
    const isMobile = useMobile();
    return <WindowTemplate gridMoreStyle={gridMoreStyle} gridAreas={isMobile?mobileGrid:grid} settings={settings} />;

};

export default TicketsTemplate;