import WindowTemplate from '../WindowTemplate/windowTemplate';

const gridMoreStyle={                    
    'justify-items': 'flex-start',
    'flex-direction': 'column',
    display: 'flex'
};
const settings = [
    {
        gridArea: 'title',
        width: '50px',
        height: '18px'
    },
    {
        gridArea: 'amount',
        width: '65px',
        height: '31px'
    },
    {
        gridArea: 'subtext', 
        width: '160px',
        height: '16px',
    },
];

function CashTemplate() {
   
    return <WindowTemplate gridMoreStyle={gridMoreStyle} gridAreas={''} settings={settings} />;

}
export default CashTemplate;