import BasicInput from '../basicInput/BasicInput';
import * as css from './RightChildrenInput.css';

function RightChildrenInput({name, text, description, children, events}) {

    return (
        <css.warp>
            <css.children>
                {children}
            </css.children>
            <BasicInput
                name={name}
                text={text}
                description={description}
                events={events}/>
        </css.warp>
    );
}

export default RightChildrenInput;
