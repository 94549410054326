import * as cssInput from '../../classes/input';
import * as cssDisabledInput from './DisabledInput.css';

const css= {...cssDisabledInput,...cssInput};

function DisabledInput({value, icon, text = '', className, children, description = '',events ,dataCyName}) {
    const hasIcon=icon!==false;

    return (
        <css.container {...events} data-cy={`${dataCyName ? dataCyName: 'default'}Input`}>
            {hasIcon&&<css.icon icon={icon}/>}
            <css.wrap>{children ?
                <css.disabledInput className={className + ' input '}>{children}</css.disabledInput>
                :
                <css.disabledInput className={className + ' input '} >{value}</css.disabledInput>
            }
            <css.title>{text}&nbsp;<span>{description}</span></css.title>
            </css.wrap>
        </css.container>
    );
}
export default DisabledInput;
