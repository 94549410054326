import * as css from './NoPixelsScreen.css';
import {useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {editOnPopupPixels, pixelContext} from '../../PixelsPage/PixelsPage';
import * as pixelIcons from '../../../../assets/symbols/symbols/pixelIcons';
import useTimeout from '../../../../hooks/useTimeout';
import {PIXEL_EXIT_DURATIOM} from './NoPixelsScreen';

function NoPixelSelection({pixelKey, className,setIsWait,pixelTypes}) {
    const {t}=useTranslation('publish');

    const {getOpenPixel}=useContext(pixelContext);
    const onOpenPixel=getOpenPixel({key:pixelKey});
    const timeout=useTimeout();
    
    const onOpen=()=>{
        if(!editOnPopupPixels.includes(pixelKey))
            setIsWait(true);
        timeout.current=setTimeout(onOpenPixel,PIXEL_EXIT_DURATIOM*(pixelTypes.length+1));
    };

    return (
        <css.pixel key={pixelKey} onClick={onOpen} className={className}>
            <css.pixelIcon icon={pixelIcons[pixelKey]} data-cy={`${pixelKey}Pixel`}/>
            {t(pixelKey)}
        </css.pixel>            
    );
}

export default NoPixelSelection;