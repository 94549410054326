import {useEffect} from 'react';
import * as Yup from 'yup';
import FormContext from '../../components/formContext/FormContext';
import {useSelector} from 'react-redux';
import {socialNetworks} from '../about/socialNetwork/SocialNetwork';
import React from 'react';
import {updateUserInfo} from '../../../redux/user/userActions';
import setGlobalState from '../../../redux/setGlobalState';
import {useTranslation} from 'react-i18next';

export const about=(currentUser,t)=>{
    const networkValidation={};
    const networkValue={};
    socialNetworks.forEach(n => {
        networkValidation[n]=Yup.string().url(t('urlErrorMessage'));
        networkValue[n]='';
    });
    const aboutInitialValues= {
        about:currentUser.info?.about||'',
        address:currentUser.info?.address||'',
        education:currentUser.info?.education||'',
        quote:currentUser.info?.quote||'',
        gallery:currentUser.info?.gallery||[],
        certificate:currentUser.info?.certificate||'',
        socialNetwork:currentUser.info?.socialNetwork||networkValue,
    };
    const aboutValidation={
        about:Yup.string().max(16777215),
        address:Yup.string().max(90),
        education:Yup.string().max(16777215),
        quote:Yup.string().max(16777215),
        gallery:Yup.array().max(10),
        socialNetwork:Yup.object().shape(networkValidation)
    };
    return [aboutInitialValues,aboutValidation];
};

function UserPop({closeTimeout,children}) {
    let params = new URLSearchParams(location.search);
    const isCurrentUser=params.get('teamMemberPop')==='user';
    const {t} = useTranslation('team');
    
    let {currentUser,XHR} = useSelector(s=>({XHR:s.user.updateUserInfoXHR, currentUser:s.user}));
    
    useEffect(()=>{     
        return ()=>{
            if(isCurrentUser)
                setGlobalState('user.updateUserInfoXHR',null);
        };}
    ,[]);

    const onSubmit=async(_changedValues,values)=>{
        updateUserInfo(values).then(closeTimeout);
    };
    const [initialValues,aboutValidation]=about(currentUser,t);

    const bottomProps={
        XHR,
        keys:{
            about:Object.keys(initialValues),
        }};

    return (
        <FormContext 
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ Yup.object().shape(aboutValidation)}
            onSubmit={onSubmit}
        >
            {({values})=>children(values,bottomProps,currentUser)}
        </FormContext>
    );
}

export default UserPop;