import React, {useEffect, useRef, useState} from 'react';
import * as css from './PageHeader.css';
import InputSearch from '../../../component/inputSearch/InputSearch';
import BtnIcon from '../../../component/button/btnIcon/BtnIcon';
import {useTranslation} from 'react-i18next';

function PageHeader(props) {
    const {op, title} = props;
    const [width, setWidth] = useState(0);
    const {t, i18n} = useTranslation('layout');
    useEffect(() => {
        let tempWidth = 0;
        op?.map((item, index) => {
            tempWidth += parseInt(item.width);
        });
        setWidth(tempWidth);
    }, [op]);

    const clickSearch = (item) => {
        item.setSearch('');
        () => item.onClick();
      
    };

    return (
        <css.pageHeader className={'message'}>
            <css.phTitle width={width}>{title}</css.phTitle>
            <css.phOperations width={width}>
                {op?.map((item, index) => {
                    let child;
                    switch (item.type) {
                        case 'search':
                            child = <css.search><InputSearch search={1} value={item.value} setSearch={item.setSearch} getSearch={item.getSearch} onClick={() => clickSearch(item)} onChange={item.onChange} /></css.search>;
                            break;
                        case 'save':
                            child = <>
                                <css.save className={'cancel'} onClick={(item.onClickCancel)} >{t('cancelBtn')}</css.save>
                                <css.save onClick={(item.onClick)}>{t('save')}</css.save>
                            </>;
                            break;
                    }
                    return <BtnIcon key={item.key} width={item.width} item={item} onClick={item.onClick} showChild={item.state} classes={item.showAlways}>
                        {child}
                    </BtnIcon>;

                    // return <css.opItem key={index} onClick={item.onClick}>
                    //     {/*<css.opItemIcon icon={item.icon} />*/}
                    //     {/*<css.opItemText className={'opText'}>{item.text}</css.opItemText>*/}
                    //     {child}
                    // </css.opItem>
                })}
            </css.phOperations>

        </css.pageHeader >
    );
}
export default PageHeader;
