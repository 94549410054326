import styled from 'styled-components/macro';
import {BGImageCenter, DEFAULT_COLOR, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {arrowBtn} from '../../../assets/symbols/symbols/Symbols.css';
import {course} from '../../../assets/symbols/images/Images.css';
import {popUpTop} from '../../../component/newPopup/topPopup/TopPopup.css';
import {Close} from '../../../component/close/Close.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {input} from '../../../component/input/inputFrame/InputFrame.css';
import zIndex from '../../../globalVariables/zIndex';
export const createCourse=styled.div`
  ${popUpTop} {
    background-color: ${DEFAULT_COLOR};
    border-radius: 8px 8px 0 0 ;
  }

  ${Close} {
    background-color: rgba(199, 236, 250, 0.38);
    border-radius: 50%;
    width: 34px;
    min-width: 34px;
    height: 34px;
    margin: 10px;
  }


`;
export const container=styled.div`
  height:320px;
  width: 100%;
  position: relative;
  background-color: ${DEFAULT_COLOR};
  padding-inline: 25px;
  text-align: center;
  ${input}{
    width:250px;
  }
  ::before{
    content: '';
    width: 280px;
    height: 280px;
    position: absolute;
    left: calc(50% - 140px);
    top: -175px;
    z-index: ${zIndex.basicModal};
    ${BGImageCenter(course,'contain')}
    ${mobileMedia}{
      width: 180px;
      height: 180px;
      left: calc(50% - 90px);
      top: -145px;
      //padding-inline: ${p=>p.theme.rtl?'35px':'25px'};
    }
  }
  
`;
export const seconds=styled.div`
  font-size: 36px;
  font-weight: 800;
  line-height: 1;
`;

export const secondsText=styled.div`
  font-size: 15px;
  line-height: 0.8;
  font-weight: 600;
`;

export const title=styled.div`
  padding-top: 120px;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 2px;
  ${mobileMedia}{
    padding-top: ${p=>p.theme.rtl?'60px':'35px'};
    font-size: 23px;
  }
`;
export const text=styled.div`
  line-height: 25px;
  font-size: 18px;
  font-weight: 500;
  padding-top:20px ;
  ${mobileMedia}{
  
    font-size: 16px;
  }
`;
export const note=styled.div`
  white-space: pre-line;
  font-size: 18px;
  font-weight: 400;
  padding: 20px 0 7px;
  ${mobileMedia}{
    font-size: 14px;
    padding-top: ${p=>p.theme.rtl?'30':'20px'};

  }
`;
export const actions =styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding-inline: 37px;
  ${btn}{
    padding-inline-end: 22px;
    ${mobileMedia}{
      font-size: 12px;
    }
    :not(.LOADING):not(.SUCCESS){
          ::before{
          content:'';
          ${BGImageCenter(arrowBtn,'auto')}
          background-position: ${p=>p.theme.rtl?'2px':'calc(100% - 2px)'} center;
          transform: rotate(${p=>p.theme.rtl?'0deg':'180deg'});
          height: 16px;
          width: 16px;
          position: absolute;
          inset-inline-end: 8px;
        }
      }
    }
  ${mobileMedia}{
    padding-inline: 9px;
    font-size: 12px;
  }
`;

