import * as css from './NoLeadsScreen.css';
import * as leadsIcons from '../../../assets/symbols/symbols/leadsIcons';
import {useTranslation} from 'react-i18next';
import Btn from '../../../component/button/btn/Btn';
import {copy} from '../../../assets/symbols/symbols/Symbols.css';

function NoLeadsScreen({leadPopOpen}) {

    const {t} = useTranslation('publish');

    return (
        <css.wrapper>
            <css.container data-cy='noLeadsScreen'>
                <css.icons>
                    {Object.keys(leadsIcons).map(icon=>{
                        return  <css.icon icon={leadsIcons[icon]} key={icon}/>;
                    })}
                </css.icons>
                <css.title>{t('noLeadsMessage')}</css.title>
                <css.text>{t('noLeadsMessage2')}</css.text>
                <Btn style={'background-color:#FFEB00;height:35px;border:none;margin-top:10px;'} onClick={leadPopOpen}>
                    <css.linkIcon icon={copy}/>
                    {t('addLink')}
                </Btn>
            </css.container>
        </css.wrapper>
    );
}
export default NoLeadsScreen;
