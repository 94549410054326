import ReactQuill from 'react-quill';
import {toSnakeCase} from '../../functions/camelSnakeCaseConversion';
const Quill = ReactQuill.Quill;

export const addVariables = () =>{
    let Embed = Quill.import('blots/embed');
    class Variable extends Embed {
        static create(variable) {
            let node = super.create();
            if(variable && variable[0] && variable[1]) {
                node.setAttribute('data-label', variable[0]);
                node.textContent = variable[1];
            } else {
                node.setAttribute('data-label', variable.label);
                node.textContent = variable.value;
            }
            return node;
        }
        static value(node) {
            return {label: node.getAttribute('data-label'), value: node.textContent};
        }
    }
    
    Variable.blotName = 'variable';
    Variable.tagName = 'span';
    Variable.className = 'variable';
    
    Quill.register(Variable);
};

export const addNoDelete = () =>{
    let BlockEmbed = Quill.import('blots/block/embed');
    class NoDelete extends BlockEmbed {
        static create(value) {
            let node = super.create();
            node.innerHTML = value;
            node.setAttribute('contenteditable', 'false');
            return node;
        }
    
        static value(node) {
            return node.innerHTML;
        }
        deleteAt(index, length) {
            console.log(index, length, 'can not delete');
        }
    }
    
    NoDelete.blotName = 'nodelete';
    NoDelete.tagName = 'p';
    NoDelete.className = 'no-delete';
    
    Quill.register(NoDelete);
};

export const replaceVariablesInDelta = (delta) => {
    return delta.ops.map((op) => {
        if(op.insert?.nodelete) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = op.insert.nodelete;
            let newTextContent = tempDiv.textContent || '';
            const spans = tempDiv.querySelectorAll('span[data-label]');
            spans.forEach(span => {
                const variableName = span.getAttribute('data-label');
                const snakeCaseVariableName = toSnakeCase(variableName);
                newTextContent = newTextContent.replace(span.textContent, `[${snakeCaseVariableName}]`);
            });
        
            return {insert: {nodelete: newTextContent}};
        }
        else if(op.insert?.variable) {
            const variable = toSnakeCase(op.insert.variable.label);
            return {insert: `[${variable}]`};
        }
        return op;
    });
};

export const deltaToHtml = (delta) => {
    const container = document.createElement('div');
    document.body.appendChild(container);        
    let quill = new Quill(container, {
        theme: 'snow'
    });
    quill.setContents(delta);
    const html = container.querySelector('.ql-editor').innerHTML;
    document.body.removeChild(container);
    return html;
};

export const convertVariablesToHtml = (text, variables, t) => {
    return (typeof text === 'string') ? text.replace(/\[([^\]]+)\]/g, (match, label) => {
        const variableKey = Object.keys(variables).find(key => toSnakeCase(key) === label);
        return variableKey
            ? `<span class="variable" data-label="${variableKey}">${variables[variableKey] || t(variableKey)}</span>`
            : match;
    }) : '';
};