import styled from 'styled-components/macro';
import {BRANDING_PAGE_WIDTH} from '../BrandingPage.css';

export const wrapper=styled.div`
    width: ${BRANDING_PAGE_WIDTH};
    max-width: 100%;
    .ql-editor {
        margin-top: 50px;
    }
`;

export const top=styled.div`
    display: flex;
    height: 60px;
    justify-content: flex-end;
    align-items: center;
    width: 95%;
`;
export const endLine=styled.div`
    bottom: 80px;
    padding-inline-start:  27px;
    position: relative;
`;
export const startLine=styled.div`
    top: 60px;
    padding-inline-start:  27px;
    position: relative;
`;