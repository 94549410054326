import React, { useState } from 'react';
import * as InputCss from './UserProfileInput.css';
import { CountriesPhoneList } from '../../../../../../component/input/countriesPhoneList/CountriesPhoneList';
import { initPhoneNumber } from '../../../../../../functions/intrernationalPhoneNumber';
import { Else, If, Then } from 'react-if';
import useEffectOnUpdate from '../../../../../../hooks/useEffectOnUpdate';

const UserProfileInput = (props) => {

    const isPhoneInput = props.iptProps.name === "phone";
    const [phoneNumber, setPhoneNumber] = useState(isPhoneInput ? initPhoneNumber({ internationalNumber: props.iptProps.value }) : {});

    useEffectOnUpdate(() => {
        props.inputChange({ target: { name: props.iptProps.name, value: phoneNumber.internationalNumber || '' } });
    }, [phoneNumber])

    const onPhoneInputChange = (e) => {
        setPhoneNumber(oldPhone => initPhoneNumber({ countryCallingCode: oldPhone.countryCallingCode, country: oldPhone.country, nationalNumber: e.target?.value }));
    }

    const onCountryListChange = (selected) => {
        setPhoneNumber(oldPhone => initPhoneNumber({ country: selected.code, nationalNumber: oldPhone.nationalNumber }));
    }

    return <InputCss.UserInputCtr onClick={
        (e)=> {
            e.stopPropagation();
            if(props.iptProps.enabled)
                props.clickOut();
        }} >
        <InputCss.InputSvgCtr className={props.iptProps.name}/>
        <If condition={!props.iptProps.isActive}>
            <Then>
                <InputCss.FieldTitleCtr>
                    {props.iptProps.value.length ? (phoneNumber.nationalNumber || props.iptProps.value) : props.iptProps.title}
                </InputCss.FieldTitleCtr>
                {isPhoneInput && 
                    <InputCss.countryImage>
                        <img src={`https://flagcdn.com/w20/${phoneNumber.country?.toLocaleLowerCase()}.png`} width="20" />
                    </InputCss.countryImage>}
            </Then>
            <Else>
                <If condition={isPhoneInput}>
                    <Then>
                        <InputCss.phoneWrapper>
                            <InputCss.InputFieldCtr
                                type="text"
                                className={props.iptProps.name}
                                name={props.iptProps.name}
                                value={phoneNumber.nationalNumber}
                                onChange={onPhoneInputChange}
                                ref={props.textInput} />
                            <CountriesPhoneList onChange={onCountryListChange} defaultCountry={phoneNumber.country} listWidth="240" name={props.iptProps.name} text={' '} />
                        </InputCss.phoneWrapper>
                    </Then>
                    <Else>
                        <InputCss.InputFieldCtr
                            type="text"
                            className={props.iptProps.name}
                            name={props.iptProps.name}
                            value={props.iptProps.value}
                            onChange={props.inputChange}
                            ref={props.textInput} />
                    </Else>
                </If>
            </Else>
        </If>

    </InputCss.UserInputCtr>;
};

export default UserProfileInput;