import {createContext, useRef, useState,useEffect} from 'react';

export const TableContextValue = createContext();

function TableContext({data,searchColumns = [],children}){
    const [searchArray, setSearchArray] = useState(data);

    useEffect(() => {
        setSearchArray(data);
    }, [data]);
    
    const tableRef=useRef();
    const scrollToBottom=()=>{
        const div = tableRef.current;
        div.scroll({
            top: div.scrollHeight,
            behavior: 'smooth'
        });
    };

    return (
        <TableContextValue.Provider value={{scrollToBottom,tableRef,data,searchColumns,searchArray,setSearchArray}} >
            {children}
        </TableContextValue.Provider>

    );
}
export default TableContext;