import * as css from './SuspendedScreen.css';
import {updateDesignEmail, updateEmailStatus} from '../../../redux/branding/brandingActions';
import {useEffect} from 'react';
import {useContext} from 'react';
import {BrandingContext} from '../BrandingPage';
import EmailImage from '../EmailImage';
import {useSelector} from 'react-redux';
import SuspendedDealEditor from '../suspendedDealEditor/SuspendedDealEditor';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import EmailSubjectTextEditor, {removeTagsFromText} from '../emailSubjectTextEditor/EmailSubjectTextEditor';
import ImageHeader from '../imageHeader/ImageHeader';

function SuspendedScreen() {
    const {textEditor: {setCurrentEditorName}} = useContext(BrandingContext);
    const {currentLang,data,getBrandingData} = useSelector(s => s.branding??{});
    const {emailSuspendedContent, emailSuspended} = data?.[currentLang]??{};

    const dependencies=[currentLang,getBrandingData[`${currentLang}XHR`]];

    useEffect(() => {
        setCurrentEditorName('emailSuspendedContent');
    }, []);

    const updateStatus = async () => {
        await  updateEmailStatus('emailSuspended', emailSuspended===1?0:1);
    };

    const saveText =(content)=>{
        const smsContent = removeTagsFromText(content.replace(/<\/p><p>/g, '\n'));
        updateDesignEmail({emailSuspendedContent:content, smsSuspendedContent:smsContent});
    };

    return (   
        <css.container data-cy="SuspendedScreen">
            <css.title>
                <ModeBtn className={'small'} status={emailSuspended} click={updateStatus}/>
            </css.title>
            <EmailSubjectTextEditor name={'emailSuspendedSubject'} dependencies={dependencies}/>
            <ImageHeader isSuspended={true}/>
            <EmailImage name='suspendedImage'/>
            <SuspendedDealEditor defaultText={emailSuspendedContent} name={'emailSuspendedContent'} dependencies={dependencies} onSave={(content)=>{saveText(content);}}  lang={currentLang}/>
        </css.container>
    );
}

export default SuspendedScreen;
