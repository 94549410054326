import * as css from './LeadsTable.css';
import Table from '../../../component/newTable/Table';
import LeadRow from './leadRow/LeadRow';
import {useContext} from 'react';
import {TableContextValue} from '../../components/tableContext/TableContext';

function LeadsTable({leadPopOpen}) {
    const {searchArray}= useContext(TableContextValue);

    return (
        <css.wrapper data-cy="leadsTable">
            <Table
                list={searchArray?.map(lead => ({comp: LeadRow, props: {lead,leadPopOpen}, key: lead.ref}))}  />
        </css.wrapper>
    );
}

export default LeadsTable;
