import * as css from './AttendanceFilter.css';
import FilterList from '../../../../component/filterList/FilterList';
import SmallSnail from '../../../tickets/components/smallSnail/SmallSnail';
import {getMonthDifferenceDayJs, getTs, getWeeksDifference} from '../../../../library/dates';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import CourseGuides from '../../../tickets/ticketsPopUp/courseTicket/courseGuides/CourseGuides';
export const getFilterDate=(date,filter)=>{
    switch (filter) {
        case 1:
            return Math.abs(getWeeksDifference(date,getTs()))<1;
        case 2:
            return Math.abs(getMonthDifferenceDayJs(date,getTs()))<1;

    }
};
function AttendanceFilter({courseFilter,guidesFilter}) {
    const tickets=useSelector(s=>s.tickets.tickets||[]);
    const {t,i18n} = useTranslation('attendance');

    let guides=[];
    tickets?.forEach((t) => {
        if(t.users)
            guides=[...guides ,...t.users];
    });
    guides = guides.filter((item, index) => guides.findIndex(a=>a.uid===item.uid) === index);
    return (
        <css.mainTitle >
            <css.titleContainer>
                {t('moreMeetings')}
            </css.titleContainer>
            <css.titleFilters>
                <CourseGuides guides={guides} openList={true} guidesFilter={guidesFilter}/>
                <FilterList filter={courseFilter.courseFilter} listProps={{list:[...[{text: t('filterTitle'),
                    key: 0,
                    onClick: ()=>courseFilter.setCourseFilter(0)}],...tickets?.map(ticket => ({
                    text: ticket.title,
                    key: ticket.tid,
                    onClick: ()=>{courseFilter.setCourseFilter(ticket.tid);},
                    rightChildren:<SmallSnail color={ticket.color}/>
                }))],...(i18n.dir() === 'rtl'?{left:'-50px'}:{right:'-80px'}),top:-5} }  />
            </css.titleFilters>
        </css.mainTitle>

    );
}
export default AttendanceFilter;