import {getBackground, getColor} from './eventRealtime/EventRealtime.css';

export const windowBase=p=>`
        border-radius: 15px; 
        box-shadow: 0 2px 5px #00000031;
        background-color: ${getBackground(p.theme.isDarkMode)};
        color: ${getColor(p.theme.isDarkMode)};
`;
export const getEventDetailsBackground = p=>`
    ${windowBase(p)};
    background-color: ${p.theme.isDarkMode ? '#00000062' : '#FFFFFF64'};
    transition: all 0.5s;
    padding: 30px;
`;