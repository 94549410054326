import SubSettingItem from '../subSettingItem/SubSettingItem';
import Btn from '../../../component/button/btn/Btn';
import {useTranslation} from 'react-i18next';
import {addGooglePing} from '../../../redux/event/eventActions';
import {useSelector} from 'react-redux';
import useRestartXHR from '../../../hooks/useRestartXHR';

function AddGooglePingItem() {
    const {t} = useTranslation('event');
    const addGooglePingXHR = useSelector(s=>s.event.addGooglePingXHR);
    useRestartXHR(addGooglePingXHR, 'event.addGooglePingXHR');

    return (
        <SubSettingItem text={t('resendPing')}>
            <Btn onClick={addGooglePing} className={addGooglePingXHR} style='width: 50px; height: 20px; font-size:11px;' >
                {t('sending')}
            </Btn>
        </SubSettingItem>
    );
}

export default AddGooglePingItem;
