import styled from 'styled-components/macro';
import {buttonNo} from '../../../../component/button/btnAdd/BtnAdd.css';
import {wrap} from '../../../../component/newTable/newRow/Row.css';
import {tableContainer} from '../../../../component/newTable/Table.css';
import {header as rightHeader} from '../../../../component/rightHeader/RightHeader.css';

export const ClearingSettings=styled.div`
        ${rightHeader}{
                height:30px;
        }
        ${tableContainer}{
                overflow: visible;
        }
        ${wrap}{
                font-size: 12px;
        }
`;
export const descriptionAndButton=styled.div`
        display: flex;
        justify-content: space-between;
        ${buttonNo}{
                margin: unset;
        }
`;
export const description=styled.div`

`;
export const title=styled.div`
        font-size: 13px;
        letter-spacing: 1px;

`;
export const extension=styled.div`
        font-size: 11px;
`;

export const header=styled.div`
        height: 50px;
`;