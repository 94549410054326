import * as css from './CashExplain.css';
import {useSelector} from 'react-redux';
import {formatRealtimeDecimal} from '../../functions';
import {useTranslation} from 'react-i18next';
export function CashExplain(){
    const {t} = useTranslation('realtime');

    const event = useSelector((s:any) => s.event);
    const {eventStats, cash} = useSelector((s:any) => s.realtime?.event);
    const total = eventStats?.all?.totalPrice;
    const refund = eventStats?.all?.totalRefund;
    const transferable = cash?.transferTickchak + refund;
    return (
        <css.explainContainerIn>
            <css.explainExplain>{t('explainAboutTotal')}
            </css.explainExplain>
            <css.explainLine>{t('howWeGotIt')}</css.explainLine>
            <css.explainLine>{t('totalInCash')} {event.currencySymbol + formatRealtimeDecimal(total || 0, false)}</css.explainLine>
            <css.explainList>
                <li>{t('validTransactions')} {event.currencySymbol + formatRealtimeDecimal(cash?.active || 0, false)}</li>
                <li>{t('cancelTransactions')} {event.currencySymbol + formatRealtimeDecimal(cash?.cancel || 0, false)}</li>
                <li>{t('refundedToCustomer')} {event.currencySymbol + formatRealtimeDecimal(refund || 0, false)}</li>
                <li>{t('totalRemoved')} {event.currencySymbol + formatRealtimeDecimal(transferable || 0 , false)} {t('totalForCommission')}</li>
                <li>{t('cashInEvent',{cash:event.currencySymbol + formatRealtimeDecimal(cash?.transferOther || 0 , false)})}</li>
            </css.explainList>
        </css.explainContainerIn>
    );
}
  
