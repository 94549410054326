import {useTranslation} from 'react-i18next';
import Btn from '../../../component/button/btn/Btn';
import * as css from './ExportExcel.css';
// import * as XLSX from 'sheetjs-style';
import Tooltip from '../../../component/tooltip/Tooltip';
import {updateOnClickOutsideStatus} from '../../../hooks/UseOnClickOutside';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import setGlobalState from '../../../redux/setGlobalState';

function ExportExcel({sheets=[],isJson=false,fileName='tickchak',headerLine=0,onClick=()=>{}}) {

    // const isNotLoadAll = useSelector(s=>s.ui.exportExcel.isNotLoadAll);
    // const [isLoading,setIsLoading]=useState(false);
    //
    // useEffect(() => {
    //     if(!isNotLoadAll&&isLoading){
    //         setIsLoading(false);
    //         onExport();
    //         setGlobalState('ui.exportExcel.isNotLoadAll',null);
    //     }
    //
    // }, [isNotLoadAll]);
    //
    // const {t,i18n} = useTranslation('global');
    // function setRTL(workbook) {
    //     if(!workbook.Workbook) workbook.Workbook = {};
    //     if(!workbook.Workbook.Views) workbook.Workbook.Views = [];
    //     if(!workbook.Workbook.Views[0]) workbook.Workbook.Views[0] = {};
    //     workbook.Workbook.Views[0].RTL = true;
    // }
    //
    // const _onClick=()=>{
    //     onClick();
    //     if(!isNotLoadAll)
    //         onExport();
    //     else
    //         setIsLoading(true);
    // };
    //
    // const onExport=()=>{
    //     const headerStyle={
    //         font:{
    //             bold:true,
    //         }
    //     };
    //     const workbook= XLSX.utils.book_new();
    //     const converter=isJson?XLSX.utils.json_to_sheet:XLSX.utils.aoa_to_sheet;
    //     sheets.forEach(sheet=>{
    //         const worksheet=converter(sheet.data,{raw:false});
    //         worksheet['!cols']=[];
    //         sheet.data[headerLine].forEach((_item,index) => {
    //             if(index<=25)
    //                 worksheet[`${String.fromCharCode(65+index)}${headerLine+1}`].s=headerStyle;
    //             // updateWidth(sheet,index,worksheet);
    //             worksheet['!cols'].push({wch: 15});
    //         });
    //         sheet.data.forEach((row,rowIndex)=>{
    //             row.forEach((cell,cellIndex)=>{
    //                 if(typeof(cell)==='object'){
    //                     worksheet[`${String.fromCharCode(65+cellIndex)}${rowIndex+1}`]={v:cell.value,t:'s',s:cell.style};
    //                 }
    //                 if(!worksheet[`${String.fromCharCode(65+cellIndex)}${rowIndex+1}`].s)
    //                     worksheet[`${String.fromCharCode(65+cellIndex)}${rowIndex+1}`].s={};
    //                 worksheet[`${String.fromCharCode(65+cellIndex)}${rowIndex+1}`].s.alignment= {
    //                     horizontal: 'right',
    //                 };
    //             });
    //         });
    //         XLSX.utils.book_append_sheet(workbook,worksheet,sheet.name);
    //     });
    //     updateOnClickOutsideStatus(false);
    //     if(i18n.dir() === 'rtl')
    //         setRTL(workbook);
    //     XLSX.writeFile(workbook ,`${fileName}.xlsx`);
    //     updateOnClickOutsideStatus(true);
    //
    // };
    
    return (
        <></>
        // <css.wrapExport>
        //     <Tooltip text={t('downlowdToExcel')}>
        //         <Btn onClick={_onClick} className={isLoading&&'LOADING'}>
        //             <css.icon />
        //             {t('export')}
        //         </Btn>
        //     </Tooltip>
        //
        // </css.wrapExport>
      
    );
}

export default ExportExcel;

// const updateWidth=(sheet,index,worksheet)=>{
//     const row=[];
//     sheet.data.forEach((rowItem)=>rowItem.forEach((dataItem,dataIndex)=>{
//         if(index===dataIndex ) {
//             row.push(dataItem);
//         } 
//     })
//     );
//     const maxWidth=row.reduce((max,cell)=>Math.max(max,cell.length),10);
//     worksheet['!cols'].push({wch: maxWidth+1});
// };
