import * as css from './ButtonScroll.css';
import TooltipAbsolute from '../../tooltip/tooltipAbsolute/TooltipAbsolute';
import useScrollDirection from '../../../hooks/useScrollDirection';
import {useTranslation} from 'react-i18next';

function ButtonScroll({refToScroll}) {
    const scrollDir = useScrollDirection(refToScroll);
    const {t} = useTranslation('global');

    const scrollMe=()=>{
        refToScroll.current.scroll({top: scrollDir==='up'?0:refToScroll.current.scrollHeight, left: 0, behavior: 'smooth'});
    };

    if(!(refToScroll.current && (refToScroll.current.scrollHeight > refToScroll.current.clientHeight+10)))
        return <></>;
    return (
        <css.button onClick={scrollMe} className={scrollDir}>
            <TooltipAbsolute text={t(`scroll${scrollDir}`)}/>
        </css.button>
    );
}
export default ButtonScroll;
