import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {container as ImageUploadContainer} from '../../imageUpload/ImageUpload.css';

export const description = styled.div`
    letter-spacing: 1.4px;
    color:#000000 ;
    padding-bottom: 10px;
`;

export const container = styled.div`
    width: 100%;
    ${dFlexCenter}
    flex-direction: column;
`;

export const wrapTexts = styled.div`
    width: 50%;
    text-align: center;
    ${mobileMedia}{  
        width: 70%;
   }
`;

export const title = styled.div`
    letter-spacing: 2.4px;
    font-size:24px;
    color:#000000 ;
    font-weight:700;
    padding-top: 10px;
    min-width: max-content;
`;

export const wrapper = styled.div`
    width: 300px;
    ${dFlexCenter}
    flex-direction: column;
    margin:5px 0;
    position:relative;
    ${mobileMedia}{
        margin-top:25px;
        > div:nth-child(2) {
        margin-bottom: 35px;
    }
   }
`;

export const top = styled.div`
    padding-bottom: 5px;
    ${dFlexCenter}
    flex-direction: column;
    ${ImageUploadContainer}{
        padding: 10px 10px;
       ${dFlexCenter}
       flex-direction: column;
   }
   ${mobileMedia}{
        flex-direction: row;
        padding-bottom: 20px;
   }
`;

export const image = styled.div`
    width: 113px;
    height: 138px;
    ${p => BGImageCenter(p.icon, 'contain')}
    ${mobileMedia}{
        margin-inline-end: 20px;
   }
`;

export const text = styled.div`
    text-align: center;
    font-weight:500;
    color: #000000;
    height:60px ;
    ${dFlexCenter}
    white-space: pre-line;
    ${mobileMedia}{
        margin: 15px 0;
        text-align: right;
        width: 140px
   }
`;

export const boldText = styled.div`
    font-weight:600;
    color: #000000;
    letter-spacing: 1px; 
`;

export const wrapImage = styled.div`
   display:flex ;
   > div:nth-child(${p => p.theme.rtl?'1':'2'}) {
        ::before{
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: #B1DEEF;
            left: 0;
            opacity:0.55 ;
            ${mobileMedia}{
                width: 100%;
                height: 1px;
                bottom: 0;
            }
       } 
    }
   ${mobileMedia}{
        flex-direction: column;
   }
`;
