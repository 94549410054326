import * as cssParticipant from './Participant.css';
import * as cssUser from '../../../../component/user/User.css';
import {useState} from 'react';
import {getPaymentDebt, sendWhatsAppOnUpdate, updatePresence} from '../../../../redux/attendance/attendanceActions';
import Animation from '../../../../component/animation/Animation';
import login from '../../../../assets/animations/login.json';
import {useParams} from 'react-router-dom';
import {useRef} from 'react';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {addHour, addMinute, getDateFormat, getNextDays, getNow,  subtractHour} from '../../../../library/dates';
import useShow from '../../../../hooks/useShow';
import ParticipantPop from '../participantPop/ParticipantPop';
import setGlobalState from '../../../../redux/setGlobalState';
import {Else, If, Then} from 'react-if';
import {useTranslation} from 'react-i18next';
import {POPUP_DURATION} from '../../../../globalVariables/GlobalVariables';
const delay=15;

const css = {...cssParticipant, ...cssUser};

const isCloseToTime=(date,duration)=>{
    
    const beforeHour=subtractHour(date);
    const endCourse=addMinute(date,duration);
    const afterHour=addHour(endCourse);
    const now=getNow();
    return (now>beforeHour&&now<afterHour);

};

function Participant({participant, face,showParticipant}) {
    const [timer,setTimer]=useState(0);
    const [bigV, setBigV] = useState(false);
    const [hasAnimation, setHasAnimation] = useState(false);
    const [isCurrent,setIsCurrent]=useState(false);

    const {pageId} = useParams();
    const tdid = pageId;
    const {t}=useTranslation('attendance');
    const {firstName, lastName,qid,_meetings, debt} = participant;

    const meet = _meetings.find(a=>Number(a.tdid)===Number(tdid));
    const meeting=useSelector(s=>s.attendance.tickets.find(t=>t.tid===s.attendance.currentTid)?.meetings?.find(t=>t.tdid=== Number(tdid)));
    const date=meeting?.date;
    const present=[1,2].includes(meet?.present);
    const timerRef =useRef(null);
    const interval=useRef(null);
    const whatsAppTimer=useRef(null);
    const openParticipantPop = useShow(POPUP_DURATION);
    const isCloseToCourse=isCloseToTime(date,meeting?.duration);

    const onclick = ()=>{
        if(!present)
            onMark();
        else {
            setGlobalState('attendance.currentParticipant',{qid, face});
            openParticipantPop.open();
        }
    };

    const sendWhatsApp=()=>{
        setTimer(delay);
        interval.current=setInterval(() => {
            setTimer(prev=>prev-1);
        }, 1000);
        whatsAppTimer.current=setTimeout(() => {
            let endTime=getDateFormat(addMinute(date,meeting.duration),'HH:mm');
            sendWhatsAppOnUpdate(qid,tdid,`${firstName} ${lastName}`,endTime);
            clearInterval(interval.current);
        }, delay*1000);
    };

    function onMark(){
        if(date>getNextDays(null,1))
            return;
        setBigV('bigV');
        updatePresence(Number(tdid), qid, {present: 1}).then(() => {
            timerRef.current=setTimeout(() => {
                if(isCloseToCourse)
                    sendWhatsApp();
                setBigV(false);
                if(!present) {
                    setHasAnimation(true);
                    timerRef.current = setTimeout(() => {
                        setHasAnimation(false);
                    }, 3000);}
            }, 300);
        });
    }

    useEffect(()=>()=>{
        clearTimeout(timerRef.current);
        clearInterval(interval.current);
    },[]);

    const onCancel=(e)=>{
        e.stopPropagation();
        setIsCurrent(true);//
        clearTimeout(whatsAppTimer.current);
        clearInterval(interval.current);
        setTimer(0);
        updatePresence(Number(tdid), qid, {present: 0}).then(()=>{
            setIsCurrent(false);
        });
    };

    const timerComp=<>
        <css.send>  {t('sending')} </css.send>
        {timer}
        <css.cancelTimer onClick={onCancel}/>
    </>;

    return (<>
        <css.container className={showParticipant}>
            {hasAnimation&&<Animation animation={login} isLoop={true} />}
            <css.wrapImage onClick={onclick} className={`${bigV} ${timer&&'timer'}`}>
                <If condition={timer}>
                    <Then>
                        {timerComp}
                    </Then>
                    <Else>
                        {(present && !bigV &&!isCurrent)&&<css.smallPresent/>}
                    </Else>
                </If>
                <css.image face={face} className={`${bigV} ${timer&&'timer'}`} />
            </css.wrapImage>
            <css.body>
                <css.name>{firstName}</css.name>
                <css.name>{lastName}</css.name>
                <css.options onClick={()=> {
                    (!debt && debt !== false) && getPaymentDebt(qid).then();
                    openParticipantPop.open();
                    setGlobalState('attendance.currentParticipant',{qid:qid,face:face});
                }}  />
            </css.body>
        </css.container>
        {!!openParticipantPop.isOpen && <ParticipantPop meet={meet} participant={participant} face={face}   openPop={openParticipantPop} debt={debt} qid={qid}/>}
    </>);
}

export default Participant;
