import styled from 'styled-components/macro';
import {addWhite} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, STRONG_BLUE} from '../../globalVariables/GlobalVariables';

export const plus = styled.div` 
    cursor : pointer;
    transition:all 400ms;
    height: 80px;
    background-color: ${STRONG_BLUE};
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    width: 80px; 
    box-shadow: 0px 3px 6px #00000029;
    top: -40px;
    position: absolute;
    left: calc(50% - 40px);
    ${BGImageCenter(addWhite,'35px')}
`;