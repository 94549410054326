import Close from '../close/Close';
import * as css from './SelectItem.css';

function SelectItem({
    index,
    text,
    icon,
    onSelected = () => {},
    onClose = () => {},
    selected,
}) {
    return (
        <css.wrap
            className={selected === index && 'toggle'}
            onClick={() => onSelected(index)}
            data-cy={`categoryChoose-${icon}`}
        >
            <css.icon icon={icon} />
            <css.text>{text}</css.text>
            <css.clear>
                <Close onClick={onClose} icon={icon} />
            </css.clear>
        </css.wrap>
    );
}

export default SelectItem;
