import * as css from './InfoLeadItem.css';

function InfoLeadItem({title, value}) {
    
    return (
        <css.wrapper data-cy="InfoLeadItem">
            <css.title>{title}</css.title>
            <css.value>{value}</css.value>
        </css.wrapper>
    );
}

export default InfoLeadItem;