import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {menuEvent} from '../layout/menu';
import {menuAccount} from '../layout/menuAccount';

const checkIfEquals = (item,params)=>{
    if(!item.path)
        return;
    const pathArr = item.path.split('/').filter(i=>i!=='');
    const paramsArr = Object.values(params).slice(1);
    if(pathArr.length!==paramsArr.length)
        return false;
    return pathArr.every((element, index) => {
        if(element !== paramsArr[index]) {
            if(element.includes(':')){
                if(!paramsArr[index])
                    return false;
            }
            else
                return false;
        }
        return true;
    });
};

export default function useCurrentItemUrl() {
    const event = useSelector(state => state.event);
    const menuJson = event ? menuEvent : menuAccount;
    const params = useParams();
    
    return Object.entries(menuJson).find(([, item]) => checkIfEquals(item,params)===true)?.slice(1)[0];
}
