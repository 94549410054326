import {fileTypes} from '../../../hooks/useRejectedFiles';
import DragFile from '../../dragFile/DragFile';

function DesignUploadImage({setImage, isForMobile}) {
      
    return ( 
        <DragFile onDrop={setImage} acceptedFiles={fileTypes.image} isForMobile={isForMobile}/>
    );
}

export default DesignUploadImage
;