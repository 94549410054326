import * as css from './BottomPopup.css';
import {useTranslation} from 'react-i18next';
import Button from '../../../../component/button/button/Button';
import OfficePopup from '../officePopup/OfficePopup';
import {isMaster} from '../../../../functions/permission';

function BottomPopup(props) {
    const {close, showClass, icon, headerText, acceptClick, disabledAcceptBtn, textAccept, textCancel,XHR} = props;
    const {t} = useTranslation('package');

    const acceptProps = {
        width: 115,
        text: textAccept ? textAccept : t('goForIt'),
        onClick: () => { acceptClick();},
        class: XHR,
    };

    const cancelProps = {
        width: 115,
        text: textCancel ? textCancel : t('anotherTime'),
        backgroundColor: '#FFF',
        borderColor: '#5D5D5D',
        hoverBackgroundColor: '#fff',
        hoverBorderColor: '#5D5D5D',
        onClick: close

    };

    return (<>
        <css.Popup className={showClass}  >
            <css.header className={'header'} icon={icon}>
                <css.headerArrow onClick={close} />
                <css.headerTitle>{headerText}
                </css.headerTitle>
            </css.header>
            {props.children}
            <css.bottom className={'bottom'}>
                <Button BtnProps={cancelProps} />
                <Button BtnProps={acceptProps} disabled={disabledAcceptBtn} />
                {!!isMaster() &&
                    <css.office> <OfficePopup /></css.office>
                }
            </css.bottom>

        </css.Popup>
    </>
    );
}

export default BottomPopup;
