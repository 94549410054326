import {useEffect, useState} from 'react';
import  {getPixels} from '../../../redux/publish/publishActions';
import {useSelector} from 'react-redux';
import PageWrapper from '../../components/pageWrapper/PageWrapper';
import {Else, If, Then} from 'react-if';
import NoPixelsScreen from '../components/noPixelsScreen/NoPixelsScreen';
import {useTranslation} from 'react-i18next';
import FacebookPixelPop from '../facebookPixelPop/FacebookPixelPop';
import useShow from '../../../hooks/useShow';
import PixelsScreen from '../components/PixelsScreen/PixelsScreen';
import {createContext} from 'react';
import setGlobalState from '../../../redux/setGlobalState';
import CustomPixelPop from '../components/customPixelPop/CustomPixelPop';
import Animation from '../../../component/animation/Animation';
import eventLoader from '../../../assets/animations/tableLoader.json';
import {hasAccountAccess, isMaster} from '../../../functions/permission';

export const oldPixelTypes=[
    'googleAnalytics'
];
export const editOnPopupPixels=['facebookPixel','customPixel'];

export const pixelContext = createContext();

function PixelsPage() {
    const publish=useSelector(s=>s.publish);
    const {pixel}=publish;
    const pixels=[...pixel.specialPixels,...pixel.customPixels];
    const {newPixel} = useSelector(s=>s.ui.pixel);
    const {t}=useTranslation('publish');
    const [currentPixel,setCurrentPixel] = useState();

    const facebookPopShow = useShow();
    const customPopShow = useShow();

    const pixelTypes = [
        'facebookPixel', 'googleRemarketing', 'googleAds', 'googleAnalytics4', 'googleTagManager',
        ...((isMaster() || hasAccountAccess('custom pixel')) ? ['customPixel'] : [])
    ];

    const openPop=(pixel)=>{
        const shows={
            customPixel:customPopShow,
            facebookPixel:facebookPopShow
        };
        const show=shows[pixel.key];
        setCurrentPixel(pixel.pixelId?pixel:null);
        show.open();
    };

    useEffect(()=>{
        if(!pixel.getPixelsXHR)
            getPixels().then();
    },[]);
    const operations={buttons:[{key: 0, onClick:()=>openPop({key:'customPixel'}), text: t('newPixel')}]};

    const pixelContextValue={
        getOpenPixel:(pixel)=>{    
            const onOpenPixel=editOnPopupPixels.includes(pixel.key)?()=>openPop(pixel)
                :pixel.pixelId?null:()=>setGlobalState('ui.pixel.newPixel',{key:pixel.key,title:t(pixel.key)});
            return onOpenPixel;
        }
    };

    return (
        <>
            <PageWrapper image={'ticketsGroups'} {...((isMaster()||hasAccountAccess('custom pixel'))?{operations}:{})} >
                <>
                    <If condition={publish.getPixelsXHR==='LOADING'}>
                        <Then>
                            <Animation animation={eventLoader} cssStyle='width:400px; position:unset; margin:0 auto; max-width:100%;'/>
                        </Then>
                        <Else>
                            <pixelContext.Provider value={pixelContextValue}>
                                <If condition={newPixel?.key||pixels.length}>
                                    <Then>
                                        <PixelsScreen pixelTypes={pixelTypes}/>
                                    </Then>
                                    <Else>
                                        <NoPixelsScreen pixelTypes={pixelTypes}/>
                                    </Else>
                                </If>
                            </pixelContext.Provider>
                        </Else>
                    </If>                  
                </>
            </PageWrapper>
            {customPopShow.isOpen && <CustomPixelPop show={customPopShow} pixel={currentPixel}/>}
            {facebookPopShow.isOpen&&<FacebookPixelPop close={facebookPopShow.close} showClass={facebookPopShow.showClass} pixel={currentPixel}/>} 
        </>
    
    );
}

export default PixelsPage;
