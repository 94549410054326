import TabsPopup from '../../../component/newPopup/tabsPopup/TabsPopup';
import TeamMember from './teamMember/TeamMember';
import {useTranslation} from 'react-i18next';
import About from '../about/About';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PopupBottom from '../../../component/popupBottom/PopupBottom.js';
import React from 'react';
import TeamMemberStatus from './TeamMemberStatus';
import TeamPop from './TeamPop';
import UserPop from './UserPop';
import useTimeout from '../../../hooks/useTimeout';

function TeamMemberPop({close,showClass}) {
    const {t} = useTranslation('team');
    const history = useHistory();
    let params = new URLSearchParams(location.search);
    const isCurrentUser=params.get('teamMemberPop')==='user';

    const onClickStage = (s) => {
        if(isCurrentUser)
            return;
        const params = new URLSearchParams(location.search);
        params.set('teamMemberPop', s);
        history.push({
            search: params.toString()
        });
    };

    let tabsList = [
        ...(isCurrentUser?[]:[{type: 'teamMember', stageText: t('teamMember'), continueBtnText:t('about'), Comp: TeamMember}]),
        {type: 'about',stageText: t('about'),Comp:About}
    ];

    const timer=useTimeout(null);    
    const closeTeamPop = ()=>{
        let params = new URLSearchParams(location.search);
        ['teamMemberPop','uid'].map(key=>params.delete(key));
        history.replace({search: params.toString()});
        close();
    };
    const closeTimeout=()=>{
        clearTimeout(timer.current);
        timer.current=setTimeout(closeTeamPop, 1500);
    };

    const getCurrentIndex = ()=>tabsList.findIndex(el => el.type === params.get('teamMemberPop'));

    const userName=useSelector(s=>`${s.user.nameFirst} ${s.user.nameLast}`);

    const Comp=isCurrentUser?UserPop:TeamPop;

    return (
        <Comp closeTimeout={closeTimeout}>{
            (values,bottomProps,currentUser)=>
                <TabsPopup showClass={showClass} tabsList={tabsList} onClickStage={onClickStage} 
                    currentIndex={getCurrentIndex()}
                    bottomComp={{Comp:PopupBottom,...bottomProps,}}
                    header={{title:isCurrentUser?userName :t('teamMember',{name:`${values.nameFirst} ${values.nameLast}`}), close: ()=>closeTeamPop(),type:'teamMember'}}>
                    {!isCurrentUser&&<TeamMemberStatus currentUser={currentUser}/>}
                </TabsPopup>}
        </Comp>
       
    );
}

export default TeamMemberPop;