import {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import InputSearch from '../../component/inputSearch/InputSearch';
import * as css from './Events.css';
import Close from '../../component/close/Close';
import Tabs from '../../component/tabs/Tabs';
import {getEvents} from '../../redux/suggestions/suggestionsActions';
import Event from './event/Event';
import {useTranslation} from 'react-i18next';
import useOnClickOutside from '../../hooks/UseOnClickOutside';
import setGlobalState from '../../redux/setGlobalState';
import BtnPlus from '../../component/button/btnPlus/BtnPlus';
import useShow from '../../hooks/useShow';
import EventsSnapshotPop from './eventsSnapshotPop/EventsSnapshotPop';
import {POPUP_DURATION} from '../../globalVariables/GlobalVariables';
import useTablet from '../../hooks/useTablet';
import Btn from '../../component/button/btn/Btn';
import EventTemplate from '../../component/templates/eventTemplate/EventTemplate';
import ClearingFeeMessagePop from '../topMenu/clearingFeeMessagePop/ClearingFeeMessagePop';
import AccountRolePermission from '../../pages/components/rolePermission/AccountRolePermission';
import useGlobalShow from '../../hooks/useGlobalShow';

function Events({search,updateLayout,show}) {

    const account = useSelector(state => state.account);
    const {t} = useTranslation('layout');
    const [searchState, setSearchState] = useState(0);
    const [expandedState, setExpandedState] = useState(0);
    const [{limit, offset}, setLimitOffset] = useState({limit: 10, offset: 0});
    const [searchText, setSearchText] = useState('');
    const currentTab = useSelector(state => state.ui.suggestions.currentEventsTab);
    const eventsTypes = useSelector(s => s.realtime.user.eventsTypes);
    const [showTitle, setShowTitle] = useState(0);
    const [first, setFirst] = useState(true);
    const isTablet = useTablet();
    const ref = useRef();
    const eventsTemp = searchText ? 'searchEvents' : 'events';
    const suggestions = useSelector((state) => state.suggestions);
    const firstTabs = [
        {key: 0, text: 'yourEvents', type: 'active'},
        {key: 1, text: 'archive', type: 'archive'}];
    const [tabList, setTabList] = useState(firstTabs);
    // const [expanded, setExpanded] = useState(false);

    const openSnapshotPop = useShow(POPUP_DURATION);
    const openClearingFeeMessagePop = useShow();

    const setInputState = (state) => {
        setSearchState(state);
    };
    const showManageEvent = useGlobalShow('isOpenCreateEvent');

    const closeEvent = () => {
        updateLayout(0);
        show.close();
    };
    const handleExpandedClick = () => {
        setExpandedState(!expandedState);
        setExpandedState(expandedState == 1 ? 0 : 1);

        //if: The events have not scrolled yet
        if(suggestions[eventsTemp].length === limit && suggestions.getEventsXHR !== 'LOADING'){
            updateLimitOffset('offset', suggestions[eventsTemp].length);
        }
    };
    const updateLimitOffset = (name, value) => {
        setLimitOffset(prevState => ({...prevState, [name]: value}));
    };
    const onScroll = e => {
        const scrollHeight = Math.round(e.target.scrollHeight - e.target.scrollTop);
        const clientHeight = Math.round(e.target.clientHeight);

        if((scrollHeight === clientHeight || Math.abs(scrollHeight - clientHeight) === 1) && suggestions.getEventsXHR !== 'LOADING')
            updateLimitOffset('offset', suggestions[eventsTemp].length);
    };
    const handleSearch = () => {
        updateLimitOffset('offset', 0);
    };
    const tabClick = (list, key) => {

        setGlobalState('ui.suggestions.currentEventsTab',key);
        updateLimitOffset('offset', 0);
        if(list) {
            setTabList(list);
        }
    };

    useOnClickOutside(ref, closeEvent);

    const getEventsEffect = async () => {
        let limitWhenExpended = 0;
        // if (suggestions.getEventsXHR !== 'LOADING') {
        if(offset === 0){
            setGlobalState(`suggestions.${eventsTemp}`, []);
            if(expandedState){
                limitWhenExpended = 20;
            }
        }
        let elem = tabList.filter((item) => {
            return currentTab === item.key;
        });
        await getEvents({pid: account.pid, search: searchText, limit: limitWhenExpended || limit, offset, type: searchText ? 'allEvents' : elem[0].type}, eventsTemp);
        // }
    };

    const isFirstRun = useRef(true);
    useEffect(() => {
        return ()=>{
            setGlobalState('ui.suggestions.currentEventsTab',0);
        };
    }, []);

    useEffect(() => {
        setTabList( [...firstTabs, ...(eventsTypes?.map((item, index) => 
            ({key: index + 2, text: t(item.type), type: item.type})) || [])
        ]);
    }, [eventsTypes]);
    
    useEffect(() => {
        updateLimitOffset('offset', 0);
    }, [account.updated]);

    useEffect(() => {
        if(!suggestions.events.length)
            setShowTitle(0);
        else {
            const timeout = setTimeout(function () {
                setShowTitle(1);
            }, 800);
            return () => clearTimeout(timeout);
        }
    }, [suggestions.events]);
    useEffect(() => {
        if(isFirstRun.current) {
            if(!suggestions.events.length )//&& account.updated
                getEventsEffect().then();
            return;
        }
        // if(account.updated)
        getEventsEffect();
    }, [limit, offset, currentTab, account.pid]);
    useEffect(() => {
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if(!suggestions.isEventsFound && first)
            tabClick(null, 1);
        setFirst(false);
    }, [suggestions.isEventsFound]);

    let eventTemplate = [];
    for(let i = 0; i < (isTablet ? 4 : (expandedState ? 10 : 6)); i++) {
        eventTemplate.push(<EventTemplate expand={expandedState} key={i}/>);//(flagEventTemp&&flagEventTemp<=i?58:10)
    }

    let flagEvent = false;
    return (
        <>
           
            <css.Events ref={ref} className={`events ${show.showClass} ${(expandedState && ' expanded')}`}>
                <css.EventsTop className='eventsTop'>
                    {!searchState && <Close  onClick={closeEvent} />}
                    <InputSearch search={search} getSearch={getEventsEffect} setSearch={setSearchText} value={searchText} onChange={handleSearch} inputState={setInputState} text={t('eventSearch')} />
                </css.EventsTop>
                {!searchText && 
                <css.EventTitle className="eventTitle">
                    <Tabs list={tabList} currentTab={currentTab} countTabs={expandedState && !isTablet ? 4 : 2} onClick={tabClick} listTitle={t('eventsType')} />
                    <AccountRolePermission views={[]} rolePermission={'manage package'}>
                        <>
                            <BtnPlus text={t('createEvent')} hasText={!!expandedState} btnWhite={false} state={showManageEvent.isOpen} onClick={openClearingFeeMessagePop.open}/>
                            {openClearingFeeMessagePop?.isOpen&&<ClearingFeeMessagePop show={openClearingFeeMessagePop} onClick={()=>{showManageEvent.open();openClearingFeeMessagePop.close();}}/>}
                        </>
                    </AccountRolePermission>
                </css.EventTitle>} 
                <Btn className={`btnToExpand ${expandedState?'contractBtn':'expandBtn'}`} onClick={handleExpandedClick}>
                    <css.icon />
                </Btn>
                <css.EventsEvents className="eventsEvents" onScroll={onScroll} hasAccount={account.pid} data-cy='myEvents'>
                    <css.EventsEventsFrame className={'eventsEventsFrame ' + ((!suggestions.isEventsFound && !suggestions[eventsTemp].length) && 'notFound')} id="header">
                        {suggestions.getEventsXHR === 'LOADING' && <css.EventsEventTemplateFrame className={((suggestions[eventsTemp].length) && 'has ') + ((!suggestions.isEventsFound && !suggestions[eventsTemp].length) && ' notFound ') + (!!expandedState && ' expanded')}>{eventTemplate}</css.EventsEventTemplateFrame>}
                        {suggestions.getEventsXHR !== 'LOADING' && (!suggestions.isEventsFound && !suggestions[eventsTemp].length) && <css.eventNotFound><css.eventNotFoundImg />
                            {searchText && <css.eventNotFoundTitle>{t('notFoundTitle', {word: searchText})}</css.eventNotFoundTitle>}<css.eventNotFoundText>{t('notFoundText')}</css.eventNotFoundText></css.eventNotFound>}
                        {suggestions[eventsTemp].map((event) => {
                            let titleOther = null;
                            if(!flagEvent && event.pid !== account.pid) {
                                flagEvent = true;
                                titleOther = <css.EventsEventsTitle key="otherPackageTitle" className={'EventsEventsTitle ' + (showTitle && 'show')}>{t('eventsPackages')}</css.EventsEventsTitle>;
                            }
                            return [titleOther, 
                                <Event 
                                    key={event.eid} 
                                    className={expandedState && ' expanded'} 
                                    event={event} 
                                />];
                        })}
                        {!!suggestions.events.length && suggestions.getEventsXHR === 'LOADING' && offset>0 &&<EventTemplate expand={expandedState}/>}
                    </css.EventsEventsFrame>
                </css.EventsEvents>
                {/* {!!account.pid&&<PackageManagement expanded={expandedState ? 1 : 0} onClick={handleExpandedClick}/>} */}
                <css.wrapSnapshot className={searchText&&'search'}>
                    <css.text>{t('accountName', {name: account.title})}</css.text>
                    <css.eventsSnapshot onClick={()=>openSnapshotPop.open()}>{t('snapshot')}</css.eventsSnapshot>
                </css.wrapSnapshot>
            </css.Events>
            <css.wrapSnapshotPop>{ openSnapshotPop.isOpen&&<EventsSnapshotPop openSnapshotPop={openSnapshotPop}/>}</css.wrapSnapshotPop> 
        </>
    
    );
}

export default Events;
