import axios from 'axios';
import store from '../store';
import {iframeClick} from '../layout/Layout';
import {dispatchEvent} from '../hooks/dispatchEvent';
import {authRefreshToken} from '../redux/auth/authActions';

export const refreshTokenIfNeed = () => {
    let exp = store.getState().auth.exp;
    if(!exp)
        return;
    const now = new Date();
    if(now > new Date(exp * 1000)) {
        iframeClick('logout');
        return;
    }
    if(now.getTime() / 1000 >= exp - 3600) {
        authRefreshToken().catch(console.error);
    }
};

const handleErrors = (err) => {
    if(err?.message === 'Network Error') {
        // TODO: handle
        return true;
    }
    const {response} = err;
    const isBaseURL = err.config.baseURL === axios.defaults.baseURL;
    if(!response || response.status >= 500) {
        // TODO: handle
        return true;
    }
    if(response.status === 401 && isBaseURL && !err.config.url.startsWith('/auth')) {
        dispatchEvent('401Exception');
        // TODO: redirect
        return true;
    }
    return false;
};

export const initAxios = () => {
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            error.isTreated = handleErrors(error);
            return Promise.reject(error);
        },
    );

    axios.interceptors.request.use((config) => {
        if(config.url !== '/logout/' && config.url !== '/user/refreshToken/') {
            refreshTokenIfNeed();
        }
        return config;
    });
};
