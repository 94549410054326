import {menuEvent} from '../layout/menu';
import {menuAccount} from '../layout/menuAccount';
import store from '../store';
import {hasAccountAccess, hasRoleAccess} from './permission';

export const getTitleByPageType=(type,idParams) => {
    const getAttendance = () =>store.getState().attendance;
    switch (type) {
        case 'groups':
            return store.getState().tickets?.groups?.find(g => g.gid == idParams)?.title;
        case 'attendance':
            return getAttendance()?.tickets?.find(t => t.tid == getAttendance().currentTid)?.meetings.find(m=>m.tdid==idParams)?.title;
        default:
            break;
    }
};

export const getSubMenu=(path,event) => {
    const menuJson = event ? menuEvent : menuAccount;
    let subMenu = Object.entries(menuJson).filter(([, item]) => item.subMenu == ((event && event.type == 'givechak' && path == 'publish') ? 'publishGivechak' : path));
 
    const billingsIndex=subMenu.findIndex(i=>i[0]==='crm/billings');
    if(billingsIndex===-1||event?.hasBillingsTransaction)
        return subMenu; 
        
    if(hasAccountAccess('billing') && hasRoleAccess('manage tickets') && event.settings?.billings?.hasBillings)
        return subMenu;
        
    subMenu.splice(billingsIndex, 1);
    return subMenu;
};
