import styled from 'styled-components/macro';
import * as symbol from '../../../../assets/symbols/symbols/Symbols.css';

export const popEventTop=styled.div`
    height:100px;
    direction:${props=>props.theme.rtl?'ltr':'rtl'};
    padding: 15px;
`;
export const popEventTopState=styled.div`
  height:30px
`;
export const popEventContain=styled.div`
    padding:10px 20px;
    
`;
export const popEventDetails=styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 130px;
    padding-top: 30px;
    align-items: start;
    justify-content: center;  
    >div{
        width:33%;
        max-width: 150px;        
    }
`;
export const pEDsSaleCash=styled.div`
    
`;
export const pEDsSaleTickets=styled.div`
    
`;
export const pEDsSaleCrm=styled.div`
    
`;
export const pEBGroup=styled.div`
  padding-top: 0;
`;
export const pEBGroupImage=styled.div`
  height: 50px;
  -webkit-mask-image:url("${symbol['group']}");
  background-color: #fff;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 30px 30px;
`;
export const pEBLink=styled.div`
    padding-top:0px;
    .clusterLink{
        height: 50px;
        width: 100%;
        background-position: center 12px;
        background-size: 30px 30px;
        background-image:url("${symbol['copyWhite']}");
    }
`;
export const pEBRemove=styled.div`
    padding-top:50px;
    background-image:url("${symbol['removeWhite']}");
`;
export const pEBDup=styled.div`
  padding-top:50px;
    background-image:url("${symbol['duplicate']}");
`;

export const pEDsDate=styled.div`
    color:#fff;
`;
export const pEDsSale=styled.div`
    
`;
export const pEDDetails=styled.div`

`;
export const pEDDetailsLocation=styled.div`
        color:#fff;
        background-image:url("${symbol['areaWhite']}");
        background-repeat: no-repeat;
        background-position:${props=>props.theme.rtl?'right':'left'} top;
        background-size:15px 15px;
        padding-${props=>props.theme.rtl?'right':'left'}: 20px;
        line-height: 14px;
        font-size: 15px;
        height: 30px;
        overflow: hidden;        
`;
export const popEventBtn=styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 70px;
    justify-content: center;
    font-size: 14px;
    >div{
        color:#fff;
        background-repeat: no-repeat;
        background-position:center 12px;
        background-size:30px 30px;
        height:100%;
        width: 100px;
        text-align: center;        
    }
`;

export const titleEvent=styled.div`
    width: 200px;
    font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    height: 50px;
    overflow: hidden;
    color:#fff;
`;