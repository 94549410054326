import * as css from './PageTitle.css';

function PageTitle({title,subTitle}) {

    return (
        <css.text>
            {title}
            <span>&nbsp;&nbsp;{subTitle}</span>
        </css.text>
    );
}
export default PageTitle;
