const eventSettings = {
    newsletterXHR: null,
    activeXHR: null,
    hasPaymentsXHR: null,//    
    ticketsPaymentsXHR: null,//הצמדה לכרטיס
    ticketsPaymentsLockXHR: null,//נעילת תשלומים בכרטיס
    ticketsPaymentsListXHR: null,//כרטיסים מוצמדים
    minForPaymentsXHR: null,//
    maxPaymentsXHR: null,//
    hasBillingsXHR: null,
    minSumBillingsXHR: null,//סכום מנימלי
    minBillingsXHR: null,
    maxBillingsXHR: null,//מקסימום תשלומים
    fullSendXHR: null,//משלוח דוח סיכום
    usersXHR:null,// יוזרים לדוח סיכום,
    unregisteredUsersXHR: null,//יוזרים לא רשומים לדוח סיכום,
    frequencyXHR: null,//תדירות משלוח דוח סיכום

    limitXHR: null,
    // crowdXHR:null,
    countLimitXHR: null,
    fidsXHR: null,
    fieldsXHR:null,
    blockOrdersSmsXHR: null,
    blockOrdersSmsTextXHR: null,

    susStatusXHR:null,
    activateAutoXHR:null,
    activateTimeXHR:null,
    
    //שלח דוח מידי?
    notificationXHR: null,//not in redux
    smsSendXHR: null,//הודעת רכישה במסרון
    emailSendXHR: null,//הודעת רכישה בדוא״ל
    transactionUsersXHR:null,// הודעת רכישה יוזרים רשומים,
    transactionUnregisteredUsersXHR: null, //הודכעות רכישה יוזרים לא רשומים,
    transactionDetailsXHR: null,//הודעות רכישה פרטי עסקה במייל
    transactionUsersNameXHR: null,// הודעות רכישה יוזרים רשומים - בשביל השמות שלהם,

};
export default eventSettings;