import styled from 'styled-components/macro';
import {locationImage} from '../../assets/symbols/images/locationImage';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import {top, topText} from '../centeredPopup.js/CenteredPopup.css';
import {popUpBottom} from '../newPopup/bottomPopup/BottomPopup.css';
import {popUpBody} from '../newPopup/mobilePopup/MobilePopup.css';

export const wrapper = styled.div`
  ${popUpBody}{
    width:400px;
    max-width:100%;
  }
  ${popUpBottom}{
    ${dFlexCenter}
    height:60px ;
  }
  ${top}{
    height:110px ;
    position: relative;
    ::before { 
        content: '';
        position: absolute;
        ${BGImageCenter(locationImage,'175px')}
        width: 175px;
        height: 175px;
        bottom: -29px;
     }
  }
  ${topText}{
    position: absolute;
    top: 15px;
    inset-inline-end: 20px;
    padding:0 ;
    font-size:16px ;
  }
`;

export const content = styled.div`
      height:calc(100% - 170px) ;
`;

export const inputWidth=styled.div`
    padding: 0 30px ;
`;