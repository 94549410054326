import {useEffect, useState} from 'react';
import Btn from '../../button/btn/Btn';
import * as css from './GenerateImageScreen.css';
import {useTranslation} from 'react-i18next';
import {createSalePageImage, getAiImages} from '../../../redux/event/eventActions';
import {useSelector} from 'react-redux';
import tableLoader from '../../../assets/animations/tableLoader.json';
import Animation from '../../animation/Animation';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import {If, Else, Then} from 'react-if';
import Image from './image/Image';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
function GenerateImageScreen({image, setImage}) {
    const {t} = useTranslation('newEvent');
    const styles = ['digitalArt', 'realistic', 'oilPainting', 'PicassoStyle', 'threeD', 'humor'];
    const event = useSelector((s) => s.event);
    const xhr = event.createSalePageImage;
    const imageSets = event.aiImages ? JSON.parse(event.aiImages) : {};
    const selectedStyles = new Set(Object.keys(imageSets));
    const [currentStyle, setCurrentStyle] = useState('');
    const currentImages = imageSets[currentStyle];

    const checkLoadingXHR = () => Object.values(xhr).find(value=> value === 'LOADING');

    const handleStyleClick = async (style) => {
        if(!checkLoadingXHR() || imageSets[style] || xhr[`${style}XHR`]==='LOADING'){
            setCurrentStyle(style);
            setImage(null);
        }
    };

    useEffect(() => {
        if(!event.getAiImagesXHR)
            getAiImages().then(()=>{handleStyleClick('digitalArt');});
        else
            handleStyleClick('digitalArt');
    }, []);
    useEffectOnUpdate(()=>{
        if(event.getAiImagesXHR && !checkLoadingXHR() && !imageSets[currentStyle]){
            createSalePageImage({title: event?.title,location:event?.location,type:event?.type,category:event?.category, style:currentStyle}).then(
                ()=>{
                    if(selectedStyles.size >= 2)
                        bounceMessage(t('designFullImages') ,'attention');
                }
            );
        }
    },[currentStyle]);
    return (
        <css.container data-cy='generateImage'>
            <css.title>{t('createImageTitle')}</css.title>
            <css.description>{t('createImageDescription')}</css.description>
            <css.styles>
                {styles.map((item, index) => (
                    <Btn
                        key={index}
                        onClick={() => {
                            handleStyleClick(item);
                        }}
                        disabled={(!selectedStyles.has(item) && selectedStyles.size >= 3 )|| (checkLoadingXHR() && !selectedStyles.has(item) && !(xhr[`${item}XHR`]==='LOADING'))}
                        className={`btn ${item === currentStyle ? 'active' : ''}`}
                    >
                        {t(item)}
                    </Btn>
                ))}
            </css.styles>
            <css.images>
                <If condition={xhr[`${currentStyle}XHR`]==='LOADING'}>
                    <Then>
                        {Array.from({length: 4})
                            .map((_, index)=>(
                                <Image key={index}>
                                    <Animation animation={tableLoader} />
                                </Image>
                            )) }  
                    </Then>
                    <Else>
                        {currentImages?.map((item, index) => (
                            <Image
                                key={index}
                                item={item}
                                image={image} 
                                setImage={setImage}
                                data-cy={`image-${index}`}
                            />
                        ))}
                    </Else>
                </If>
            </css.images>
            
        </css.container>
    );
}

export default GenerateImageScreen;
