import styled from 'styled-components/macro';
import {BGImageCenter, DEFAULT_COLOR} from '../../../../globalVariables/GlobalVariables';
import {op, pen} from '../../../../assets/symbols/symbols/Symbols.css';

export const row = styled.div`
    width:100% ;
    display: flex;
    height:70px ;
    transition:all 300ms;
    &.delete{
        /* margin-top:-70px ; */
        /* transform:scaleY(0) ; */
        opacity:0 ;
    }
    :hover{
        .circleWrap{
            display:block;
            transition:background-color 400ms ;
            background-color: #76D6E8;
            /* background-color: ${DEFAULT_COLOR}; */
            ${BGImageCenter(pen,'16px'  )}
            >div{
            display:none;
            }
        }

    }
    `;

export const part = styled.div`
    border-inline-end:1.5px solid #ddd;
    display:flex ;
    justify-content:space-between ;
    align-items:center ;
    padding: 0 1% ;
`;

export const block = styled.div`
    /* border-inline-end:1.5px solid #ddd; */
    min-width:100px;
    width:35% ;
    height:100% ;
    display:flex ;
    justify-content:center ;
    flex-direction:column ;
    padding:10px ;

`;

export const boldText = styled.div`
    font-weight:600 ;
    font-size:14px ;
    margin-inline-start: 7px;
`;
export const text = styled.div`
    display:flex ;
    font-size:12px ;
    line-height:15px ;
`;
export const wrapText = styled.div`
    width:45px;
`;

export const middle = styled(part)`
    width:37%;
    padding:0 ;
`;
export const left = styled(part)`
    border:none;
    width:18%;
`;

