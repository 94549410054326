import {useState, useEffect} from 'react';
import Animation from '../../../../../component/animation/Animation';
import eventLoader from '../../../../../assets/animations/eventLoader.json';
import * as css from './EventType.css';

function EventType({onClick, type, status, text}) {

    const [iconClass, setIconClass] = useState('show');
    useEffect(() => {
        if(status === 'loading')
            setIconClass('');
        else setIconClass('show');
    }, [status]);

    return (
        <css.EventType className={`${status}  ${iconClass}`} onClick={status === 'disabled' || status === 'loading' ? null : onClick}>
            <css.iconWrap className={`${status} ${iconClass === 'show' ? 'hiddenAnimate' : ''}`}>
                <css.EventTypeIcon type={type} className={`${type} ${status} ${iconClass}`} />
                <Animation animation={eventLoader} />
            </css.iconWrap>
            <css.EventTypeText>
                {text}
            </css.EventTypeText>
        </css.EventType>
    );

}

export default EventType;
