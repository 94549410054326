import createReducer from '../createReducer';

const initialState = {
    artists:[],
    location:null,
    currentLang:null,
    data:{},
    getBrandingData:{
        heXHR:null,
        enXHR:null,
        frXHR:null,
        ruXHR:null,
        deXHR:null
    },
    previewKey:1,
    updateDesignEmailXHR:null

};

const reducer = createReducer('branding', initialState);

export default reducer;