import {useSelector} from 'react-redux';
import UserImage from '../../../../layout/topMenu/layoutUserProfile/userImage/UserImage';
import * as css from './ManagerDetails.css';

function ManagerDetails(props) {
    const userState=useSelector(state=>state.user.user);

    return (<>
        <css.details>
            <UserImage class="layout-img-user" path={userState.picture}  />
            <css.text>
            נערך ביום חמישי 21.02.21 שעה 18:45 
                <br/>ע״י שיראל שלמה בן הרב משה</css.text>
        </css.details>

    </>
    );
}

export default ManagerDetails;
