import styled, {createGlobalStyle, css} from 'styled-components/macro';
import {BGImageCenter, LIGHT_GRAY, MATTE_LIGHT_BLUE, STRONG_BLUE, WHITE, dFlexCenter, ellipsis, ellipsisLines, mobileMedia, tabletMedia} from '../../../globalVariables/GlobalVariables';
import {uploadLight} from '../../../assets/symbols/symbols/brandingIcons';
import {sIBBot, sIBImage} from '../../../component/fullEditImage/FullEditImage.css';
import {imageStage, upload} from '../../../component/fullEditImage/absoluteImageEditor/AbsoluteImageEditor.css';
import {drop} from '../../../component/dropzone/Dropzone.css';
import {AvatarEditor, ImageEditor, ImageEditorBtns} from '../../../component/imageEditor/ImageEditor.css';
import {Resize} from '../../../component/resize/Resize.css';
import {pageFrame} from '../../components/pageWrapper/PageWrapper.css';
import {eyeSection} from '../../components/eyeHideSection/EyeHideSection.css';
import {givechakImage} from '../../../assets/symbols/images/givechakImage';
import {container as modeBtnContainer} from '../../../component/modeBtn/ModeBtn.css';
import {BRANDING_PAGE_WIDTH} from '../BrandingPage.css';

const ICON_BOTTOM = '7px';
const ICON_INSET_END = '35px';

export const globalStyle = createGlobalStyle`
  ${pageFrame}{  
    background-color: ${LIGHT_GRAY};
  }
  `;

export const wrapper=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    width: ${BRANDING_PAGE_WIDTH};
    max-width: 100%;
    ${mobileMedia}{
        width: 100%;
        margin: 7px;
    }
    ${tabletMedia}{
        margin-bottom: 50px;
    }
`;

export const subject=styled.div`
    border: 0.5px solid ${STRONG_BLUE};
    background-color: ${WHITE};
    border-radius: 10px;
    width: 100%;
    height: 45px;
    margin: 5px 0;
    font-size: 13px;
    padding-inline-start: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    ${mobileMedia}{
        height: 35px;
        font-size: 12px;
    }
`;

export const emailSubject=styled.div`
    font-size: 12px;
`;

export const top=styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const container=styled.div`
    width: 100%;
    height: max-content;
    background:${WHITE} 0 0 no-repeat padding-box;
    border-radius: 10px;
`;

export const emailImage=styled.div`
    background: ${p=>p.color||MATTE_LIGHT_BLUE};
    border-radius: 10px;
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    position: relative;
    ${mobileMedia}{
        height: ${p=>p.height};
        justify-content: flex-start;
    }
    ${sIBBot}{
        position: absolute;
        bottom: ${ICON_BOTTOM};
        inset-inline-end: ${ICON_INSET_END};
        min-width: 75px;
        justify-content: flex-end;
    }
    ${sIBImage}{
        border: none;
    }
    ${upload}{
      top:0;
      inset-inline-start: 0;
    }
    ${imageStage}{
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
    }
    ${drop}{
      width: 100%;
      height: 100%;
      section,div{
        width: 100%;
        height: 100%;
      }
    }
  ${mobileMedia}{
    ${AvatarEditor}{
      scale: 0.9;
      margin: 0;
    }
    ${ImageEditor}{
      padding: 0;
    }
    ${Resize}{
      scale: 0.9;
    }
    ${ImageEditorBtns}{
      padding-inline-end: 10px;
      
    }

  }
  
  

`;

export const image=styled.div`
    position: absolute;
    border-radius: 10px;
    width: 240px;
    height: 100%;
    ${p => BGImageCenter(p.image, 'cover')}
    &.givechak {
    ${p => p.image === givechakImage && css`
      width: 200px;
      height: 100%;
      background-size: auto;
      ${mobileMedia} {
        background-size: 40%;
      }
    `}
  }
`;

export const uploadIcon=styled.div`
    width: 20px;
    height: 20px;
    ${BGImageCenter(uploadLight, 'contain')}
    position: absolute;
    bottom: ${ICON_BOTTOM};
    inset-inline-end: ${ICON_INSET_END};
    pointer-events: none;
`;

export const details=styled.div`
    display: flex;
    height: 80px;
    border-top: 1px solid #d8d0d0;
    border-bottom: 1px solid #d8d0d0;
    justify-content: center;
    align-items: center;
    &.smallText{
      zoom: 0.85;
    }
    >div{
        max-width: 33%;
        width: fit-content;
        margin: 0 20px;
        height: 75px;
        margin-top: 10px;
        >${eyeSection}{
          inset-inline-start: 40px;
        }
    }
    ${mobileMedia}{
        height: 70px;
        justify-content: space-evenly;
        >div{
            height: 65px;
            margin: 0;          
        }
    }
`;

export const partDetail=styled.div`
    ${dFlexCenter}
`;

export const icon=styled.div`
    height: 40px;
    width: 40px; 
    ${p=>BGImageCenter(p.icon,'23px')}
    ${mobileMedia}{
        background-size: 17px;
        width: 30px; 
    }
`;

export const partText=styled.div`
    font-size: 13px;
    width: calc(100% - 40px);   
    ${ellipsisLines(3)}
   ${mobileMedia}{
        font-size: 11px;
        width: calc(100% - 30px); 
   }
`;

export const title=styled.div`
    font-size: 21px;
    height: 90px;
    font-weight: 600;
    padding: 0 20px;
    ${dFlexCenter}
    ${mobileMedia}{
        height: 70px;
        font-size: 16px;
    }
`;

export const editor=styled.div`
  min-height: 300px;
  height: max-content;
  padding-block: 20px;
  ${dFlexCenter};
`;

export const text=styled.div`
  ${ellipsis};
`;

export const boldText=styled(text)`
  font-weight: 600;
`;

export const wrapModeBtn = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    ${modeBtnContainer}{
      width: max-content;
      &.small{
        .modeBtnText{
          font-weight: 600;
          font-size: 14px;
          width: 100%;
          margin-inline-start: 10px;
        }
        .toggle{
          width:50px;
        }
      }
    }
    
`;

export const modeBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 100%;
`;

export const hideEye = styled.div`
    ${eyeSection}{
      display: none !important;
    }
`;
