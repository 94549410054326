import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import {btn} from '../button/btn/Btn.css';
import {loader} from '../loader/Loader.css';
import zIndex from '../../globalVariables/zIndex';

export const ImageEditor = styled.div`
    display:${props=>props.vertical?'flex':'block'};
    padding: 0 20px 20px;
    border-radius:8px ;
    &.share{
        z-index: ${zIndex.baseRaised};
        background: #fff;
    }
`;
export const wrapEditor = styled.div`
`;
export const AvatarEditor = styled.div`
   ${dFlexCenter}
    width:${props=>props.width}px;
    height:${props=>props.height}px;
    position:relative;
    margin: 0 10px 10px 10px;
    /* ::before{
        content:'';
        width:${props=>props.width-20}px;
        height:${props=>props.height-20}px;
        position: absolute;    
        background-image: url("${props => props.image}");
        ${props => BGImageCenter(props.image, `${props.width-20}px ${props.height-20}px`)};
        opacity:0.7 ;
    } */
    ::after{
        content: '';
        position: absolute;
        box-shadow: 0px 3px 6px #00000029;
        width:${props=>props.width-10}px;
        height:${props=>props.height-10}px;
        border-radius: ${props=>props.radius?props.radius:'50%'};
        border: 7px solid #90c7d673;
        /* top: 3px; */
        pointer-events: none; 
        /* ${props=>(props.theme.rtl?'right':'left')}: 3px; */
        background-image: repeating-linear-gradient(#cccccc85 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #cccccc85 0 1px, transparent 1px 100%);
        background-size: ${props=>props.width/3}px ${props=>props.height/3}px;
    }
`;
export const ImageEditorBtns = styled.div`
    display:flex;
    width:${props=>props.width};
    ${props=>props.vertical?'margin:0 auto;':' align-items: center;'}    
    justify-content:${props=>!props.vertical&&'flex-end'};
    ${props=>props.vertical&&'flex-direction:column;'}
    padding-top:7px ;
    align-items: flex-end;
    ${btn}{
        height:25px ;
        padding:0 6px ;
    }
 `;

export const ImageEditorBtn = styled.div`
    ${p=>BGImageCenter(p.icon,'8px')}
    width: 13px;
    margin-inline-start: 2px;
    height: 13px;
`;

export const ImageEditorBot = styled.div`
    height:80px;
`;

export const wrapBtn = styled.div`
 margin-${props=>props.vertical?'bottom':(props.theme.rtl?'left':'right')}:5px;   
`;
export const imageLoader = styled(loader)`
.animation{
      width:250px ;
  }
`;