import styled from 'styled-components/macro';
import {BGImageCenter, boxShadow, DEFAULT_COLOR, dFlexCenter, scrollbar} from '../../../globalVariables/GlobalVariables';
import {upgradePackage} from '../../../assets/symbols/images/Images.css';
import zIndex from '../../../globalVariables/zIndex';

export const popup = styled.div`
z-index:${zIndex.basicModal};
position:absolute ;
`;
export const container = styled.div`
left:calc(50% - 150px);
top:calc(50% - 300px);
background-color:#fff ;
position:absolute ;
 height:600px;
 max-height: 95%;
 width:300px;
 border-radius:10px ;
 ${boxShadow}
`;
export const top = styled.div`
  &.send{
      height:65% ;
  }
  background-color:${DEFAULT_COLOR};
  ${BGImageCenter(upgradePackage, '160px')}
  display:flex ;
  align-items:flex-end ;
  justify-content:center ;
  height:50%;
  border-radius:10px ;
  transition:all 300ms ;
  `;
export const topClose = styled.div`
    position:absolute ;
    top:10px;
    inset-inline-start:10px;
    height:30px ;
    width:30px ;
    border-radius: 50%;
    background-color:#ace7f2 ;
    transition:all 300ms ;
    :hover{
        background-color: #FFF ;
        & .white{
            background-color: #222;
        }
    }
    `;
export const topImage = styled.div`

`;
export const topText = styled.div`
font-size:23px ;
text-align: center;
padding:0 20px 20px 20px;
font-weight: 700;

`;
export const bottom = styled.div`
height:50% ;
&.send{
    height: 35%;
}
padding:50px 40px 0 40px;
text-align: start;
display:flex ;
flex-direction:column ;

`;

export const bottomTitle = styled.div`
    height:10% ;
    font-size: 15px;
    font-weight: 800;
    display:flex ;
`;

export const bottomDescription = styled.div`
    height:60% ;
    ${scrollbar}
    
    /* font-size: 12px; */
`;

export const bottomButtons = styled.div`
    height:20% ;
${dFlexCenter}
>div{
    white-space: nowrap;
}

`;

