import * as css from './DataItem.css';
import React from 'react';

function DataItem({value='',icon='',color,className,onClear=()=>{}, dataCyName}) {

    return (
        <css.wrap
            className={className} color={color} data-cy={`${dataCyName ? dataCyName : "default" }DataItem`}>
            <css.icon className='dataItem' icon={icon} onClick={onClear} color={color}/>
            <css.text className={className} >{value}</css.text>
        </css.wrap>
    );
}
export default DataItem;
