/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components/macro';

export const routing = styled.div`

`;
export const routeBack = styled.div`
    
`;
export const navigationRouter = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 350px);
    @media screen and (max-width: 1000px){
         width: calc(100% - 30px);
    }
`;
export const navigationFrame = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1000px){
         width: 100%;
    }
`;
export const navigationLinks = styled.div`
    display: flex;
`;

export const navigationTitle = styled.div`
    font-weight:400;
    cursor: pointer;
    width: max-content;
    &.bold{
        font-weight: bold;
    }
`;