import {useEffect, useRef} from 'react';

import * as css from './Events.css';
import {useTranslation} from 'react-i18next';
import Tooltip from '../../../component/tooltip/Tooltip';
import setGlobalState from '../../../redux/setGlobalState';
import {useHistory} from 'react-router-dom';

function Events(props) {
    const {t} = useTranslation('layout');
    const history = useHistory();

    const getUserEventsEffect = async () => {
        if(props.offset === 0)
            setGlobalState('suggestions.eventsByRole', []);
        props.getUserEvents({search: props.search, type: props.type, limit: props.limit, offset: props.offset});
    };
    useEffect(async () => {
        await getUserEventsEffect();
    }, [props.offset]);

    const count = useRef(0);
    const isFirstRun = useRef(true);
    useEffect(() => {
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        count.current = props.cnt;
        setTimeout(async function () {
            if(count.current != props.cnt)
                return;
            await getUserEventsEffect();
        }, 700);
    }, [props.search]);

    const openEvent = (event, e) => {
        e.stopPropagation();
        if(props.onClick)
            props.onClick(event);
        else
            history.push('/e/' + event.eid + '/realtime');
    };
    const arrRole = {owner: t('owner'), manager: t('manager'), conductor: t('conductor'), representative: t('representative'), designer: t('designer'), analyst: t('analyst')};
    return (
        <div>
            <css.EventFrame >
                {!!(props.eventsList.length && props.title) && <css.EventsTitle>{t('moreEvents')}</css.EventsTitle>}
                {props.eventsList?.map((item) =>
                    <css.Event key={item.eid} onClick={(e) => openEvent(item, e)}>
                        <css.EventImage className={'EventImage'} image={item.fbImg} />
                        <Tooltip text={item.title}>
                            <css.EventText className={'EventText'}>{item.title}</css.EventText>
                        </Tooltip>
                        <css.EventIcon className={item.eventRole} dataTitle={arrRole[item.eventRole]} />
                    </css.Event>
                )}
            </css.EventFrame>
        </div>

    );
}

export default Events;
