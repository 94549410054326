import {useTranslation} from 'react-i18next';
import MobilePopup from '../../../component/newPopup/mobilePopup/MobilePopup';
import * as css from './VerifyUserPop.css';
import Btn from '../../../component/button/btn/Btn';
import openEmailInBrowser from '../../../library/linkToMailInbox';
import {useHistory} from 'react-router-dom';
import setGlobalState from '../../../redux/setGlobalState';

function VerifyUserPop({show}) {

    const {t} = useTranslation('layout');
    const history = useHistory();
    
    const onClick = async ()=>{
        openEmailInBrowser('email');
    };
    const onClose = async ()=>{
        show?.close();
        setGlobalState('ui.login.isInactiveUser',false);
        history.push('/');
    };

    return (
        <MobilePopup showClass={show?.showClass} close={onClose} height='350px' dataCyName="VerifyUserPop">
            <css.globalStyle/>
            <css.container>
                <css.image/>
                {t('verifyUserMassage')}
                <Btn onClick={onClick}>
                    {t('moveToMail')}
                    <css.mailIcon/>
                </Btn>
            </css.container>
        </MobilePopup>
    );
}

export default VerifyUserPop;