import * as css from './Boxoffice.css';
import {useTranslation} from 'react-i18next';
import AccountPermission from '../../pages/components/accountPermission/AccountPermission';
import setGlobalState from '../../redux/setGlobalState';
import Tooltip from '../../component/tooltip/Tooltip';

function Boxoffice({className}) {
    const {t} = useTranslation('layout');

    return (
        <AccountPermission permission="boxoffice" >
            <Tooltip text={t('boxOffice')}>
                <css.boxOffice className={className && className} onClick={() => setGlobalState('ui.newIframe.openBoxoffice', true)} />
            </Tooltip>
        </AccountPermission>

    );
}
export default Boxoffice;