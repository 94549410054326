export const onDatePickerChange = (val, helpers, e) => {
    // if(!val)
    //     return;
    helpers.setTouched(true);
    helpers.setValue(val);
    e?.stopPropagation();
};
export const onFocus =async (ref,value,type='date',props) => {
    if(props?.onFocus)
        props.onFocus();
    const input = ref.current.querySelector('input');
    if(!value){
        await new Promise(res=>setTimeout(() => {
            if(input)
                input.value=type==='date'?'00/00/0000':'00:00';
            res();
        },300));
    }
    setTimeout(()=>{input?.setSelectionRange(0, 2);},0);
};

export const onKeyDown = (e,ref,type,props) => {
    if(props?.onKeyDown)
        props.onKeyDown(e);
    const input = ref.current.querySelector('input');
    if(!input || e.target !== input )
        return;
    if(['Backspace','Delete','Tab','v','a'].includes(e.key)){
        return;
    }
    if( !(e.key >= 0 && e.key <= 9))
        return e.preventDefault();
    if((e.target.value+e.key).length===2){
        setImmediate(() => {
            input.value=(type==='date'?`${e.target.value}/00/0000`:`${e.target.value}:00`);
        });
    }
    const {selectionStart} = input;
    if(type==='date'){
        const dayLength=2,monthLength=2,yearLength=4;
        switch (selectionStart) {
            case dayLength-1:
                setImmediate(() => {input.setSelectionRange(monthLength+1, monthLength+3);});       
                break;
            case dayLength+monthLength:
                setImmediate(() => { input.setSelectionRange(dayLength+monthLength+2, dayLength+monthLength+2+yearLength);     });       
                break;
            case dayLength+monthLength+yearLength+2:
                e.preventDefault();
                break;
            default:
                break;
        }
    }
    else {
        const HHLength=2,MMLength=2;
        switch (selectionStart) {
            case HHLength-1:
                setImmediate(() => {input.setSelectionRange(MMLength+1, MMLength+3);});       
                break;
            case HHLength+MMLength+1:
                e.preventDefault();
                break;
            default:
                break;
        }
    }
        
};