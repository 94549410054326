import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../../../component/input/Input';
import InnerPop from '../../../../component/newPopup/innerPop/InnerPop';
import useShow from '../../../../hooks/useShow';
import * as css from './SocialNetwork.css';

export const socialNetworks=['tiktok','instagram','facebook'];

function SocialNetwork() {
    const show=useShow();
    const [currentNetwork,setCurrentNetwork]=useState(null);

    const {t} = useTranslation('team');
    const onClick=(network)=>{
        if(currentNetwork===network&&show.isOpen)
            return show.close();
        setCurrentNetwork(network);
        show.open();
    };

    return (
        <>
            <css.title className='networkTitle'>{t('socialNetwork')}</css.title>
            <css.networks  className='network'>
                {socialNetworks.map(n=><css.network key={n} className={n} onClick={()=>onClick(n)} data-cy={n}/>)}
            </css.networks>
            <InnerPop dataCy='socialNetwork' show={show} title={t('socialNetwork')} buttons={{whiteBtn:{text:t('close')}}}>
                <css.socialNetwork data-cy="socialNetworkSideBar">    
                    <css.top className={currentNetwork}>
                        <css.image className={currentNetwork}/>
                        <css.followMe>FOLLOW ME <span>ON</span></css.followMe>  
                        <css.logo className={currentNetwork}/>  
                        <css.logoText className={currentNetwork}/>  
                    </css.top>
                    <css.bottom>
                        <Input name={`socialNetwork.${currentNetwork}`} icon='copy' text={t(currentNetwork)}  description={t('socialNetworkDesc')}/>
                    </css.bottom>
                </css.socialNetwork>
            </InnerPop>
        </>
       
    );

}

export default SocialNetwork;
