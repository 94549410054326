export const getFirstInvalidField=(initialValues, errors) =>{
   
    for(const key in initialValues) {
        if(key in errors) {
            if(typeof initialValues[key] === 'object' && typeof errors[key] === 'object') {
                return getFirstInvalidField(initialValues[key], errors[key]);
            }
            return {name:key,value:initialValues[key],error:errors[key]};
        }
    }
  
    return null;
};

export const getFirstErrorFieldForScroll=(initialValues, errors) =>{
    for(const key in initialValues) {
        if(key in errors) {
            return key; 
        }
    }
    return null;
};