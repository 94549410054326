import React from 'react';
import Table from '../../Shared/Table/Table';
import MailingTable from './MailingTable';
import MailingFormat from './MailingTableFormt';
import './Mailing.css';
import '../../Images.css';
import {withRouter} from 'react-router-dom';

import {API_MAILING} from '../../../../config/config';
import {connect} from 'react-redux';

const RtlCss = React.lazy(() => import('./Mailing-rtl'));

class Mailing extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            getLoading: false,
            getDetailsLoading: false,
            tableData: [],
            allMessages: '',
            limit: 20,
            offset: 0,
            mailingType: '',
        };
        this.mailingGet = this.mailingGet.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.mailingGetDetails = this.mailingGetDetails.bind(this);
        this.handleSwitcher = this.handleSwitcher.bind(this);
        this.createTable = this.createTable.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollAll = this.handleScrollAll.bind(this);
    }

    handleScrollAll(){
        if(this.state.getLoading){
            return;
        }
        this.setState(prevState =>{
            return {
                ...prevState,
                offset : prevState.offset + this.state.limit
            };
        }, () => {this.mailingGet(false, false, false, this.state.tableData);});
    }

    handleScroll(data, limit, offset){
        this.mailingGetDetails(this.state.allMessages.Id, limit, offset, data);
    }

    handleSwitcher(type){
        this.setState({mailingType: type, offset: 0,}, function () {this.mailingGet('');});
    }
    createTable(type, messages){
        return  (
            <Table
                data={MailingTable(this.state.mailingType, type)}
                tableData={messages}
                searchFunction={type === 'regular' ? this.mailingGet : false}
                handleChanges={type === 'regular' ? this.handleChanges : false}
                showHideDetails={type === 'regular' ? this.mailingGetDetails : false}
                handleScroll={type === 'small' ? this.handleScroll : false}
                handleScrollAll={type === 'small' ? false : this.handleScrollAll}
                allMessages={type === 'regular' ? this.state.allMessages : false}
                tableClass={type === 'regular' ? '' : 'not-mini'}
                handleSwitcher={type === 'regular' ? this.handleSwitcher : false}
                getDetailsLoading={this.state.getDetailsLoading}
                getLoading={this.state.getLoading}
                tableOffset={this.state.offset}
                lang={this.props.user.lang}
                isEvent={this.props.event.eid}
            />
        );
    }

    handleChanges(key, name, value){
        const index = this.state.tableData.findIndex(row => row.id === key);
        if(this.state.tableData[index]){
            let tempTableData = this.state.tableData;
            if(name === 'selected' || name === 'process'){
                const temp = MailingFormat.format([tempTableData[index]['original']], false, this.handleChanges);
                tempTableData[index] = temp[0];
                tempTableData[index][name] = value;
            } else {
                tempTableData[index].process = 'start';
            }

            this.setState({tableData: tempTableData}, function () {

                if(name !== 'selected' && name !== 'process'){
                    let dataSend = {};
                    dataSend[name] = value;
                    fetch(API_MAILING+'/'+key, {
                        method: 'PUT',
                        headers: {'Content-Type': 'application/json','Authorization': this.props.event.eid && this.props.mailingJson ? this.props.mailingJson : this.props.accountMailingJson,},
                        body: JSON.stringify(dataSend)
                    }).then(response => response.json())
                        .then(response => {
                            if(response.statusCode === 200){
                                if(name === 'sendingStatus' && value === 'CANCEL'){
                                    tempTableData.splice(index, 1);
                                } else {
                                    response.body[name] = value;
                                    let mailingResponse = MailingFormat.format([response.body], false, this.handleChanges);
                                    tempTableData[index] = mailingResponse[0];
                                    tempTableData[index].process = 'end';
                                }
                                this.setState({tableData: tempTableData});
                            }
                        });
                }
            });
        }
    }

    mailingGet(search, isSearchMessages=false, searchMessages=false, data=false){
        if(isSearchMessages){
            this.mailingGetDetails(search, 50, 0, false, searchMessages);
            return;
        }
        this.setState({getLoading: true});
        let searchParamUrl = '';
        let searchParam = '';
        let specialSearchParam = '';
        if(search){
            searchParam = search.search ? 'search='+search.search : '';
            if(search.specialSearch && search.specialSearch.length){
                search.specialSearch.forEach(function (item, i) {
                    specialSearchParam += i ? '&' : '';
                    specialSearchParam += item.type + '=' + item.value;
                });
            }
            searchParamUrl = '&' + searchParam + (searchParam && specialSearchParam ? '&'+ specialSearchParam : specialSearchParam);
        }

        const limit = this.state.limit;
        const offset = this.state.offset;
        const mailingTypeUrl = this.state.mailingType ? '/'+ this.state.mailingType : '';

        fetch(API_MAILING+'/'+ mailingTypeUrl +'?limit='+limit+'&offset='+offset+searchParamUrl, {
            headers: {'Authorization': this.props.event.eid && this.props.mailingJson ? this.props.mailingJson : this.props.accountMailingJson,},
        }).then(response => response.json())
            .then(response => {
                if(response.statusCode === 200){
                    let oldData = [];
                    if(data){
                        oldData = data.map(function (item) {
                            return item.original;
                        });
                    }
                    let mailingResponse = MailingFormat.format(oldData.concat(response.body), false, this.handleChanges);
                    this.setState(() => ({
                        getLoading: false,
                        tableData: mailingResponse,
                        // offset: prevState.offset + mailingResponse.length,
                    }));
                }
            });
    }

    mailingGetDetails(id, limit, offset, data=false, serach=false){
        if(parseInt(id) === -1) {
            this.setState({getDetailsLoading: false,getLoading: false, allMessages: '',});
            return;
        }
        if(this.state.getDetailsLoading){
            return;
        }
        this.setState({getDetailsLoading: true});
        const index = this.state.tableData.findIndex(row => row.id === id);
        let mailingResponse = this.state.tableData[index];
        if(!data){
            mailingResponse = MailingFormat.formatSmall(this.state.tableData[index], true);
            mailingResponse.mailingAll = this.createTable('small', mailingResponse.messages);
            this.setState({allMessages: mailingResponse,});
        }
        let searchText = '';
        if(serach){
            searchText = '&search='+serach;
        }

        fetch(API_MAILING+'/message/'+id+'?limit='+limit+'&offset='+offset+searchText, {
            headers: {'Authorization': this.props.event.eid && this.props.mailingJson ? this.props.mailingJson : this.props.accountMailingJson,},
        }).then(response => response.json())
            .then(response => {
                if(response.statusCode === 200){
                    let oldData = [];
                    if(data){
                        oldData = data.map(function (item) {
                            return item.original;
                        });
                    }
                    mailingResponse = MailingFormat.formatSmall(this.state.tableData[index], true);
                    mailingResponse.messages= MailingFormat.formatSmallMessages(this.state.tableData[index], oldData.concat(response.body), true);
                    this.setState({getDetailsLoading: false}, function () {
                        mailingResponse.mailingAll = this.createTable('small', mailingResponse.messages);
                    });
                    this.setState({allMessages: mailingResponse,});
                } 
            });
    }

    componentDidMount() {
        this.mailingGet();
    }

    componentDidUpdate(prevProps) {
        if(this.props.mailingJson !== prevProps.mailingJson || this.props.user.lang !== prevProps.user.lang || this.props.pid !== prevProps.pid) {
            this.mailingGet();
        }
    }

    render() {
        return (
            <>
                {(this.props.user.lang === 'he') && <RtlCss />}
                {this.createTable('regular', this.state.tableData)}
            </>

        );
    }
}

// export default Mailing

const mapStateToProps = state => ({
    event:state.event,
    pid:state.account.pid,
    mailingJson:state.event.mailingJson,
    accountMailingJson:state.account.mailingJson,
    user:state.user,
}
);
export default withRouter(connect(mapStateToProps) (Mailing));