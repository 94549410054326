import * as css from './GeneralItem.css';
import React, {useRef, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import useShow from '../../hooks/useShow';
import List from '../list/List';

function GeneralItem({children, color, number, icon, onClick = () => { }, drag={}, listOptions={} ,dataCyName}) {

    const {key, index, isDraggingOver} = drag;
    const {list = [], onClickList} = listOptions;

    const {open, close, isOpen, showClass} = useShow();

    const openList = (e) => {
        e.stopPropagation();
        open();
    };

    return (
        // < Draggable draggableId={key?.toString()} key={key} index={index} >
        //     {(provided, snapshot) => (
        // <css.wrap {...provided.draggableProps} ref={provided.innerRef} className={className + " " + snapshot.isDragging && ' drag '} color={color}
        //     isDragging={snapshot.isDragging} isDraggingOver={isDraggingOver}
        // >
        <css.wrap color={color} onClick={onClick}>
            <css.wrapIcon>
                <css.icon className='icon' icon={icon} number={number} />
                <css.drag className="drag" />
            </css.wrapIcon>
            {children}
            <css.options onClick={openList} data-cy={`${dataCyName ? dataCyName : "default" }Options` }   />
            {isOpen && <List top={27} width={'180px'} showClass={showClass} close={close} list={list} onClick={onClickList} />}

        </css.wrap >
        //     )
        //     }
        // </Draggable >
    );
}
export default GeneralItem;
