import styled from 'styled-components/macro';
import {popUpBody as mobilePopUpBody} from '../../../component/newPopup/mobilePopup/MobilePopup.css';
import {popUpBody as tabsPopUpBody} from '../../../component/newPopup/wrapPopup/WrapPopup.css';
import {MARGIN_TOP , ROBOT_HEIGHT} from '../../../component/robot/Robot.css';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

const popupHeightWhenHasRobot=` &.show{
  ${mobileMedia}{
    height:calc(100% - ${ROBOT_HEIGHT} - ${MARGIN_TOP} - ${MARGIN_TOP});
  }

}`;
export const container = styled.div`
  height: 100%;
  .pageWrapperFrame{
      display:unset ;
  }
    &.smallPopUp{
      ${tabsPopUpBody}{
       ${popupHeightWhenHasRobot}
      }
      ${mobilePopUpBody}{
       ${popupHeightWhenHasRobot}
      }
  }
`;