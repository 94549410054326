import * as css from './sendToSMS.css';
import {useTranslation} from 'react-i18next';
import {useField, useFormikContext} from 'formik';

function SendToEmail({onClick=()=>{}}) {
    const {t}=useTranslation('login');

    const {submitForm} = useFormikContext();
    const [,,helper]=useField('sendToEmail');

    const sendToSMS=()=> {
        helper.setValue(false);
        submitForm().then(onClick);
    };
    return (
        <css.toSMS onClick={sendToSMS} data-cy='smsCodeLogin'>
            <span> {t('sendMeCode')}</span>
        </css.toSMS>
        
    );
}

export default SendToEmail;