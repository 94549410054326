export const htmlEscape=(str)=>{
    
    if(typeof str !=='string')
        return '';
        
    const htmlEscapes = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;'
    };

    // Regex containing the keys listed immediately above.
    let htmlEscape = /[&<>"'\/]/g;

    // Escape a string for HTML interpolation.
    return ('' + str).replace(htmlEscape, function(match) {
        return htmlEscapes[match];
    });
};

export const cssEscape=(str)=>{

    if(typeof str !=='string')
        return '';

    const cssEscapes = {
        '&': '\\0026',
        '<': '\\003c',
        '>': '\\003e',
        '"': '\\0022',
        "'": '\\0027',
        '/': '\\002f'
    };

    // Regex containing the keys listed immediately above.
    let cssEscape = /[&<>"'\/]/g;

    // Escape a string for HTML interpolation.
    return ('' + str).replace(cssEscape, function(match) {
        return cssEscapes[match];
    });
};