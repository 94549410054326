import request from '../../library/request';
import store from '../../store';
import setGlobalState, {pushToGlobalState} from '../setGlobalState';
import {getEndOfDay, getStartOfDay} from '../../library/dates';
import {getClosetMeet} from '../../pages/attendance/courseMeetingsList/CourseMeetingsList';

const getEid = () => store.getState().event.eid;
const getTicketAttendanceIndex = (tid) => store.getState().attendance.tickets.findIndex(t=>Number(t.tid)===(Number(tid||store.getState().attendance.currentTid)));

export const getEventAttendance = async (date) => {
    const dateIndex=store.getState().attendance.event.currentDateIndex;
    const meetings=(store.getState().attendance.event.dates||[])[dateIndex]?.meetings||[];
    const limit=20;
    if(meetings.length &&( meetings.length%limit>0))
        return;
    const offset=meetings.length;
    const res = await request('attendance.getEventAttendance', 'get', `/event/${getEid()}/attendances`,{limit,offset,startDate:getStartOfDay(date),endDate:getEndOfDay(date)});
    setGlobalState(`attendance.event.dates[${dateIndex}].meetings`,[...meetings,...res.meetings]);

};

export const getMeetingAttendance = async (tdid) => {

    let participants=store.getState().attendance.tickets.find(t=>t.tid===store.getState().attendance.currentTid)?.participants||[];
    if(participants.some(p=>p._meetings?.some(a=>a.tdid===tdid)))
        return;

    let {attendance,tid} = await request('attendance.getMeetingAttendance', 'get', `/event/${getEid()}/attendance/ticketDate/${tdid}`);
    // participants=store.getState().attendance.tickets.find(t=>Number(t.tid)===Number(tid))?.participants||[];

    attendance.forEach(attendance=>{
        const participantIndex=participants.findIndex(p=>p.qid===attendance.qid);
        const participant=participants[participantIndex];
        if(!participant)
            return;
        const newAttendance= [...participant._meetings];
        newAttendance?.push({tdid,...attendance});
        setGlobalState(`attendance.tickets[${getTicketAttendanceIndex()}].participants[${participantIndex}]._meetings`,newAttendance);
    }
    );
    return tid;
};

export const getTicketAttendance = async (tdid,tid=null) => {
    let res;
    if(tdid)
        res = await request('attendance.getTicketAttendance', 'get', `/event/${getEid()}/attendance/ticketDate/${tdid}/ticket`);
    else
        res = await request('attendance.getTicketAttendance', 'get', `/event/${getEid()}/attendance/ticket/${tid}/ticket`);
    const ticketIndex=getTicketAttendanceIndex(res.tid);
    if(ticketIndex===-1){
        pushToGlobalState('attendance.tickets',{
            tid:res.tid,
            meetings:res.meetings,
            participants:res.participants.map(p=>({...p,_meetings:[]}))
        });
    }

    if(store.getState().attendance.currentTid!==res.tid)
        setGlobalState('attendance.currentTid',res.tid);
        // setGlobalState('attendance.ticket.tid',res.tid);
};

export const getAttendanceDates = async () => {
    const res = await request('attendance.getAttendanceDates', 'get', `/event/${getEid()}/attendance/dates`);
    const dates=res.map(r=>({...r,meetings:[]}));
    setGlobalState('attendance.event.dates',dates);
    const closetDate= getClosetMeet(dates);
    const index=dates.findIndex(d=>d.date===closetDate?.date);
    if(index>-1)
        setGlobalState('attendance.event.currentDateIndex',index);
};

export const updatePresence = async (tdid, qid, fields) => {
    if(store.getState().attendance.updatePresenceXHR==='LOADING')
        return;
    await request('attendance.updatePresence', 'put', `/event/${getEid()}/attendance/ticketDate/${tdid}/qr/${qid}`,fields);
    const participants=[...store.getState().attendance.tickets.find(t=>t.tid===store.getState().attendance.currentTid)?.participants];

    const index=participants.findIndex(p=>p.qid===qid);
    const meetings=participants[index]._meetings;
    let meetingIndex=meetings.findIndex(a=>Number(a.tdid)===Number(tdid));
    const meeting={...meetings[meetingIndex],...fields};
    const ticketIndex=getTicketAttendanceIndex();
    setGlobalState(`attendance.tickets[${ticketIndex}].participants[${index}]._meetings[${meetingIndex}]`,meeting);
    let prevPresent=  meetings[meetingIndex]?.present;
    let numberToAdd=0;
    if([null,0,3].includes(prevPresent)&&[1,2].includes(fields.present))
        numberToAdd=1;
    else if([1,2].includes(prevPresent)&&[0,3].includes(fields.present))
        numberToAdd=-1;
    setGlobalState(`attendance.tickets[${ticketIndex}].participants[${index}].presentAmount`,store.getState().attendance.tickets[ticketIndex].participants[index].presentAmount+numberToAdd);
};

export const getParticipantReportOfCourse = async (tid,qid) => {
    const res = await request('attendance.getParticipantReportOfCourse', 'get', `/event/${getEid()}/attendance/ticket/${tid}/qr/${qid}`);
    const ticketIndex=getTicketAttendanceIndex();
    const index=store.getState().attendance.tickets[ticketIndex].participants.findIndex(p=>p.qid===qid);
    setGlobalState(`attendance.tickets[${ticketIndex}].participants[${index}]._meetings`,res);
};
export const getPaymentDebt = async (qid) => {
    const res = await request('attendance.getPaymentDebt', 'get', `/event/${getEid()}/attendance/qr/${qid}`) ;
    const ticketIndex=getTicketAttendanceIndex();
    const index=store.getState().attendance.tickets[ticketIndex].participants.findIndex(p=>p.qid===qid);
    const debt =  Object.keys(res).length > 0 ? res : false;
    setGlobalState(`attendance.tickets[${ticketIndex}].participants[${index}].debt`,debt);
};
export const sendSmsPaymentDebt = async (qid, body) => {
    await request('attendance.attendanceSendSms', 'post', `/event/${getEid()}/attendance/qr/${qid}/sendDebtSms`, {body}) ;

};
export const sendWhatsAppOnUpdate=async(qid,tdid,name,meetingEndTime)=>{
    const tid=store.getState().attendance.currentTid;
    const ticket=store.getState().tickets.tickets?.find(t=>t.tid===tid)||{users:[]};
    const body={
        name,
        meetingEndTime,
        courseName:ticket.title,
        guideName:`${ticket.users[0]?.nameFirst} ${ticket.users[0]?.nameLast}`,
        guideUid:ticket.users[0]?.uid,
    };
    await request('attendance.sendWhatsAppOnUpdate', 'post', `/event/${getEid()}/attendance/ticketDate/${tdid}/qr/${qid}/whatsapp`,body);
    
};