import {useTranslation} from 'react-i18next';
import {popPixelImage} from '../../../../assets/symbols/images/imagepixelPop';
import CustomPixel from '../../customPixel/CustomPixel';
import PopUpWithHeader from '../../../../component/newPopup/popUpWithHeader/PopUpWithHeader';

function CustomPixelPop({show, pixel}) {

    const {t} = useTranslation('publish');

    return (
        <PopUpWithHeader show={show} title={t('customPixel')} image={popPixelImage}>
            <CustomPixel show={show} pixel={pixel}/>
        </PopUpWithHeader>
    );
}

export default CustomPixelPop;