import  {useEffect, useState} from 'react';
import * as css from './Copy.css';
import {useTranslation} from 'react-i18next';

function Copy({className,link}) {
    const [copied, setCopied] = useState(0);
    const {t} = useTranslation('layout');
    let myInput = null;

    const onCopy = (e) => {
        e.stopPropagation();
        myInput.select();
        document.execCommand('copy');
        setCopied(1);
    };

    useEffect(() => {
        if(copied)
            setTimeout(function () {
                setCopied(0);
            }, 2000);
    }, [copied]);

    return (
        <css.link>
            <css.linkIcon data-cy='copy' img={'copy'} className={(className && className) + ' ' + (copied && 'copied')}
                onClick={onCopy}>{t('copied')}</css.linkIcon>
            <input readOnly value={link} ref={(ref) => myInput = ref} />
        </css.link>
    );
}
export default Copy;
