import styled from 'styled-components/macro';

export const wrapRadioBtn=styled.div`
    display: flex;
    align-items: center;
    padding: 0 9px 0 0;
`;
export const radioBtnText=styled.div`
   font-size: 12px;
    margin: 0 4px;
    letter-spacing: 1px;
    font-weight: 500;
`;