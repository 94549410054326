export const getInitialValues = (ticket, t = val => val) => {
    const imgData = JSON.parse(ticket.imgData || '{}');
    let details = {
        active: ticket.tid ? ticket.active : 1,
        title: ticket.title ? ticket.title : '',
        color: ticket.color ? ticket.color : '76d6e8',
        description: ticket.description ? ticket.description && JSON.parse(ticket.description) : '',
        course: ticket.course? ticket.course :  {startDate: '', endDate: '',meetings: []},
        prices: ticket.prices?.length ? ticket.prices : [{price:'0'}],
        limit: ticket.limit ? ticket.limit : 10,
        // preSale: (ticket.preSale&&ticket.preSale[0]) ? ticket.preSale[0] :{tpsid:0,discount:0,discountType:'sum'},
        users: ticket.users?.length ? ticket.users : [],
        startDiscount: '',
        endDiscount: '',
        imgData: {image: imgData?.path || '', imageId: imgData?.imageId, credit: {creditName: imgData?.creditName, license: imgData?.license, alt:imgData?.alt}},
        // monthNumber:0
        location: {...ticket.location,
            skipHolidays:ticket.location?.skipHolidays? ticket.location.skipHolidays :'Jewish',
            locationMap:(typeof ticket.location?.locationMap ==='string')?JSON.parse(ticket.location.locationMap):ticket.location?.locationMap
        },
        draft:ticket?.draft || 0,
    };

    let extras = {
        extras: ticket.extras?.length ? ticket.extras : []
    };

    let payments = {
        text: ticket.text ? ticket.text : '',
        payments: ticket.payments ? ticket.payments : {},
        crowd: ticket.crowd ? ticket.crowd : {},
    };
    payments={...payments, payments:{...payments.payments, extraException:!!payments.payments.extraException}};

    const initialCourseField = {
        active: true,
        required: true,
    };

    const customFields = {
        courseFields: ticket.tid ? ticket.ticketFieldsCustom :
            [{
                title: t('firstName'),
                type: 'firstName',
                ...initialCourseField
            },
            {
                title: t('lastName'),
                type: 'lastName',
                ...initialCourseField
            }]
    };

    return {extras, payments, details, customFields};
};
