import * as css from './TransactionsQueue.css';
import QueueProcessStatus, {getTaskState} from '../queueProcessStatus/QueueProcessStatus';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import {getSuspendedQueue} from '../../redux/event/eventActions';

function TransactionsQueue() {
    const {t} = useTranslation('realtime');
    const cookieValue = Cookies.get('tickchakQueue');
    let suspended;
    if(cookieValue) {
        suspended = (JSON.parse(cookieValue)).suspended;
    }
    const [data, setData] = useState({amount:0, sold:0});
    
    const action = (async ()=>{
        const res = await getSuspendedQueue(suspended.eid, suspended.tsqid);
        if(res) {
            setData({amount: res?.allTransactions, sold: res?.executedTransactions});
        }
        return res;
    });
    useEffect(()=>{
        if(suspended?.tsqid && suspended?.eid){
            getTaskState(3000, action, 'suspended');
        }
    }, [suspended]);
    if(!suspended || !suspended.tsqid || !suspended.eid)
        return <></>;
    return <>
        <QueueProcessStatus amount={data.amount} sold={data.sold}>
            <css.title>{t('transactionProcessTitle')}</css.title>
            <css.goalText>{data.sold}/{data.amount}</css.goalText>
        </QueueProcessStatus>
    </>;
}
export default TransactionsQueue;
