import React from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './EmailButtons.css';
import Btn from '../../../component/button/btn/Btn';
import GoogleWalletBtn from '../../../component/googleWalletBtn/GoogleWalletBtn';
import EyeHideSection from '../../components/eyeHideSection/EyeHideSection';
import {YELLOW_ORANGE} from '../../../globalVariables/GlobalVariables';
import AccountPermission from '../../components/accountPermission/AccountPermission';

const style = ` height: 40px;
                line-height:40px;
                font-size: 14px;
                padding-inline-end:27px;
               `;

function EmailButtons() {
    const {t} = useTranslation('branding');

    const wrapper=(sectionName,children)=>
        <EyeHideSection sectionName={sectionName}>{children}</EyeHideSection>;

    return (   
        <css.wrapper data-cy='emailButtons'>
            {wrapper('viewTicket',<Btn style={style+` background-color:${YELLOW_ORANGE};border:none;`}>{t('showCard')}</Btn>)}
            {wrapper('calendar', <Btn style={style}>{t('addToDiary')}</Btn>)}
            <AccountPermission permission={'google wallet ticket'}>
                {wrapper('googleWallet',<GoogleWalletBtn />)}
            </AccountPermission>
        </css.wrapper>
    );
}

export default EmailButtons;
