import {useSelector} from 'react-redux';
import ticketColors from '../../../../assets/objects/TicketColors';
import * as css from './Snail.css';

function Snail({onClick, color}) {
    const symbol = useSelector(s => s.tickets.ticketsIcon);
    const icon = ticketColors[color][symbol];
    return (
        <css.snailBtn icon={icon} onClick={onClick} color={color} />
    );
}
export default Snail;