import styled from 'styled-components/macro';
import {text as editorTitle, top as topEditor} from '../smsEditor/SmsEditor.css';
import {wrap} from '../../../component/textEditor/editorWrapper/EditorWrapper.css';
import {wrapper} from '../smsScreen/SmsScreen.css';
import {modeBtn} from '../emailScreen/EmailScreen.css';

export const container=styled(wrapper)`
    ${topEditor}{
        width: 100%;
    } ${wrap}{
        width: 100%;
    }
    ${editorTitle}{
        font-weight: bold;
    }
`;

export const title=styled(modeBtn)`
`;