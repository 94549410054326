import styled from 'styled-components/macro';
import {DEFAULT_COLOR, mobileMedia} from '../../globalVariables/GlobalVariables';
import zIndex from '../../globalVariables/zIndex';

export const container=styled.div`
    width: 100%;
    position: absolute;
    top: 4px;
    right: 0;
    text-align: end;
    .noBorder{
        font-weight:600;
        border:none ;
        width:100%;
        background-color:transparent ;
    }
    .react-datepicker__month--selected ,.react-datepicker__month-text--keyboard-selected{
        background-color:${DEFAULT_COLOR} ;
    }
    .react-datepicker__time-list-item--selected{
        background-color:${DEFAULT_COLOR} !important ;
    }
    .react-datepicker-popper{
        min-width:max-content ;
        z-index:${zIndex.baseHigh};

    }
    .react-datepicker__triangle{
        left:-22px !important;
    }
    ${mobileMedia}{   
        input[type=${p => p.theme.type}]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            background: transparent;
            cursor: pointer; 
        } 
        input[type=${p => p.theme.type}]:not(.hasValue):before{
            color: #484848;
            content: attr(placeholderText);
            position: absolute;
            left: 5px;
            font-weight: 500;
            width: max-content;
        }
        input[type=${p => p.theme.type}] {
            appearance: none;
            border: none;
            background: transparent;
            font-weight: 600; 
            width: fit-content;
        } 
}

`;
