import {useTimezoneSelect} from 'react-timezone-select';
import {useTranslation} from 'react-i18next';
import InputList from '../../../../../component/input/inputList/InputList';

export default function TimeZoneList(){
    const {t} = useTranslation('newEvent');

    const {options} = useTimezoneSelect({labelStyle:'abbrev'});

    const list=options.map(option=>({key:option.value,text:`(${option.label.split('(')[1]}`}));
    
    return   <InputList 
        list={list} 
        text={t('timeZone')} 
        name="locale.timeZone"
    />;

}