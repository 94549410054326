import {useField} from 'formik';
import * as css from './InputToggle.css';
import Input from '../Input';
import ModeBtn from '../../modeBtn/ModeBtn';
import {useEffect} from 'react';
import {Else, If, Then} from 'react-if';
import DisabledInput from '../disabledInput/DisabledInput';

function InputToggle({name, toInvert = false, toggleProps = {}, inputProps = {}}) {
    const {toggleText, toggleDescription, toggleName} = toggleProps;
    const [, meta, helpers] = useField(name);
    const [toggleField, toggleMeta, toggleHelpers] = useField(toggleName);
    const status = toggleField?.value;

    useEffect(() => {
        if(!meta.touched && toggleMeta.touched)
            helpers.setTouched(true);
    }, [status]);

    const modeBtnClick = val => {
        toggleHelpers.setValue(val);
        if(!meta.touched)
            helpers.setTouched(true);
        if(val !== toInvert) {
            helpers.setValue(meta.initialValue);
        }
    };

    return (
        <css.container>
            <css.modeBtn>
                <ModeBtn click={modeBtnClick} status={status} className='small' />
            </css.modeBtn>
            <If condition={!!status === !!toInvert}>
                <Then>
                    <Input name={name}  {...inputProps} />
                </Then>
                <Else>
                    <css.disabled className={meta.touched && ' touched'}>
                        <DisabledInput value={toggleText} description={toggleDescription} />
                    </css.disabled>
                </Else>
            </If>
        </css.container>

    );
}
export default InputToggle;

