function LoadScript(url,callback){
    loadScript(url,callback);
}

function LoadScripts(url){
    url.map(item => {loadScript(item);});
}

function loadScript(url,callback){
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    if(callback)
        script.onload = () => {callback();};
    document.body.appendChild(script);
}

export default LoadScript;