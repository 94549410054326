import styled from 'styled-components/macro';
import {arrowDown, snail} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const snailBtn=styled.div`
    background-color:#C6D600;
    height: 45px;
    width: 45px;
    border-radius:30px;
    cursor: pointer;
    position: relative;
    ${BGImageCenter(snail,'26px')};
    background-image: url("${p=>p.icon}");
    background-color: #${p=>p.color};
    ::before{
        content:"";
        ${BGImageCenter(arrowDown,9)};
        height: 25px;
        width: 25px;
        float: left;
        position: absolute;
        ${props => props.theme.rtl ? 'left' :'right'}: -21px;
        top: 11px;
    }
  
`;