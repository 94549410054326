import {useTranslation} from 'react-i18next';
import {imageLeadPop} from '../../../../assets/symbols/images/imageLeadPop';
import PopUpWithHeader from '../../../../component/newPopup/popUpWithHeader/PopUpWithHeader';
import NewLead from '../../newLead/NewLead';

function NewLeadPop({show, lead = {}}) {

    const {t} = useTranslation('publish');

    return (
        <PopUpWithHeader show={show} title={t('newLead')} image={imageLeadPop} dataCyName="newLeadPop">
            <NewLead show={show} lead={lead}/>
        </PopUpWithHeader>
    );
}

export default NewLeadPop;