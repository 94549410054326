import * as css from './Image.css';

function Image({image, setImage, item, children}) {
    return (      
        <css.image
            onClick={() => {
                setImage&&setImage(item);
            }}
            image={item}
            className={item&&item === image && 'selected'}
        >
            {children}
        </css.image>
    );
}
export default Image;
