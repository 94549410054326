import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SmallSnail from '../smallSnail/SmallSnail';
import LeftChildren from './leftChildren/LeftChildren';
import {getTicketPrice} from '../ticketBox/TicketBox';
import * as css  from './TicketsList.css';
import List from '../../../../component/list/List';

function TicketsList({showClass, close, onClick, exist}) {

    const {t} = useTranslation('ticket');
    const tickets = useSelector(s => s.tickets.tickets);
    const ticketsForChoose = tickets.filter(t=> !exist.some(existTicket=>t.tid===existTicket.tid));
    const list =  ticketsForChoose?.map(ticket => ({
        ticket:ticket,
        text: `${ticket.title}`,
        key: ticket.tid,
        onClick: onClick,
        color: `#${ticket.color}`,
        children:<css.wrapItem onClick={()=>onClick({ticket})}>
            <css.itemRight><SmallSnail color={ticket.color}/></css.itemRight>
            <LeftChildren title={ticket.title} price={getTicketPrice(ticket)} />
        </css.wrapItem> ,
    }));

    return (
        <List list={list} top={40} left={'25px'} showClass={showClass} close={close} title={t('addToGroup')} />
    );
}

export default TicketsList;