import * as css from './SideMenu.css';
import {Fragment} from 'react';
import Tooltip from '../tooltip/Tooltip';
import {useTranslation} from 'react-i18next';

function SideMenuItem({item,icons,onClick,currentPage}) {

    const {t}=useTranslation('global');

    return (
        <css.wrapper data-cy='sideMenuItem'>
            <Tooltip text={t(item)}>
                <css.designEmailIcon icon={icons[item]}
                    onClick={()=>onClick(item)} className={currentPage===item&&' selected'} data-cy='sideMenuSelected'/>     
            </Tooltip>    
            <css.text>{t(item)}</css.text>
        </css.wrapper>  
    );
}

function SideMenu({icons,arrTypes,onClick,currentPage}) {

    return (
        <css.right data-cy='sideMenu'>
            {Object.keys(arrTypes).map((item) =>
                <Fragment key={item}>
                    <SideMenuItem item={item} icons={icons} onClick={onClick} currentPage={currentPage}/>
                </Fragment>
            )}
        </css.right>
    );
}
export default SideMenu;