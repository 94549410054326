import styled from 'styled-components/macro';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';

export const nav = styled.div`
${dFlexCenter}
justify-content: space-between;
width:50px;
position: absolute;
inset-inline-end: 20px;
bottom: 20px;
`;
export const circle = styled.div`
cursor: pointer;
width: 13px;
height: 13px;
border-radius: 50%;
background-color: white;
&.current{
    ${dFlexCenter}
    ::before{
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #CCD232;
    }
}
`;