import styled from 'styled-components/macro';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const top=styled.div`
    display: flex;
    height: 170px;
    ${mobileMedia}{
        padding-top:20px;
            display: block;
            height: 270px;
    }
`;

export const side=styled.div`
    padding:20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:50%;
    ${mobileMedia}{
            width: unset;
            padding: 0;
    }
`;

export const sideValidity=styled.div`
        font-size: 10px;
        letter-spacing: 2px;
`;

export const bottom=styled.div`
        display: block;
`;

export const bottomTitle=styled.div`
        font-size: 14px;
        letter-spacing: 2px;
`;

export const bottomInputs=styled.div`
        padding-top: 10px;
        height: 105px;
        display: flex;
        ${mobileMedia}{
            display: block;
            height: 150px;
        }

`;