import * as css from './SubSettingForm.css';

function SubSettingForm({children,className, name}) {
    return (
        <css.container className={className} data-cy={`${name}Expanded`}>
            {children}            
        </css.container>

    );
}

export default SubSettingForm;
