import {Else, If, Then} from 'react-if';
import Btn from '../../button/btn/Btn';
import * as css from './FirstScreenDesign.css';
import ImageUpload from '../../imageUpload/ImageUpload';
import ConditionalWrapper from '../../conditionalWrapper/ConditionalWrapper';

function ImageType({text,icon,btnText,onClick,hasButton=true,setImage}) {
    
    return (
        <>
            <css.wrapper>
                <css.top>
                    <ConditionalWrapper condition={hasButton} wrapper={(children)=><ImageUpload onDrop={setImage} >{children}</ImageUpload>}>
                        <>
                            <css.image icon={icon} />
                            <css.text>{text}</css.text>
                        </>
                    </ConditionalWrapper>
                </css.top>
                {<If condition={hasButton}>
                    <Then>
                        <Btn onClick={onClick} className={'white'}>
                            {btnText}
                        </Btn>
                    </Then>
                    <Else>
                        <css.boldText>{'בקרוב...'}</css.boldText>
                    </Else>
                </If>}
            </css.wrapper>
        </>
    );
}

export default ImageType;