import styled from 'styled-components/macro';
import zIndex from '../../../globalVariables/zIndex';

export const popOnPop=styled.div`
    height: 100%;
    position: absolute;
    ${props => props.theme.rtl ? 'left' : 'right'}: -285px;
    top: 0;
    width: 285px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 5px 15px #00000052;
    transition: all 400ms;
    z-index: ${zIndex.basicModal};
    &.show{
        ${props => props.theme.rtl ? 'left' : 'right'}: 0;
    }
`;
export const popOnPopBottom=styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 77px;
`;

export const popOnPopContent=styled.div`
    height: 100%;
    width: 100%;
`;

export const popOnPopMiddle=styled.div`
    background: #fbfbfb;
    height: ${props => (props.height+'px')};
    position: relative;
`;

export const popOnPopTitle=styled.div`
    position: absolute;
    top: 10px;
    ${props => props.theme.rtl ? 'left' : 'right'}: 19px;
    font-weight: 500;
`;

export const popOnPopTop=styled.div`
    height: ${props => (props.height)};
    background-color: #76d6e8;
    position: relative;
    border-radius: 8px;
    transition: all 600ms;
`;
export const popOnPopClose=styled.div`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.122' height='10.289' viewBox='0 0 12.122 10.289'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:none;stroke:%233a4143;stroke-linecap:round;%7D.a%7Bstroke-width:1.3px;%7D.b%7Bstroke-linejoin:round;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-14.256 -14.974)'%3E%3Cpath class='a' d='M-3395.785-132h9.819' transform='translate(-3370.056 -111.845) rotate(-180)'/%3E%3Cpath class='b' d='M-3391.8-133.857l4.084,4.084-4.084,4.084' transform='translate(-3372.711 -109.654) rotate(-180)'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    height: 10px;
    width: 30px;
    padding: 20px;
    transform: rotate(${props => props.theme.rtl ? '180deg' : '360deg'});
    z-index: ${zIndex.basicModal};
    cursor:pointer;
    position: absolute;
`;
