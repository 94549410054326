import * as css from './Event.css';
import * as Date from '../../../library/dateFunctions_old_to_be_deprecated';
import {useEffect,  useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {changeAccountMessage} from '../../userPackages/UserPackages';
import EventOption from './eventOption/EventOption';
import {tsGetYear} from '../../../library/dates';
import {defaultImage} from '../../../assets/symbols/images/defaultImage';
import StaticActiveInactive from '../../../component/staticActiveInactive/StaticActiveInactive';
import Tooltip from '../../../component/tooltip/Tooltip';
import {reloadIframe} from '../../myIframe/MyIframe';
import {useSelector} from 'react-redux';
import {eventDuplication} from '../../../redux/event/eventActions';
import TickchakLoader from '../../../component/tickchakLoader/TickchakLoader';
import getCountryByTimeZone from '../../../library/getCountryByTimeZone';
import useGlobalShow from '../../../hooks/useGlobalShow';

function Event ({event,className}) {
    const [show, setShow] = useState(0);
    const paletteClass=(event.type==='course'&&[3,4].includes(event.ready))&&'palette';
    const {t} = useTranslation('layout');
    const accountT=useTranslation('package');
    const history = useHistory();
    const currentEid=useSelector(s=>s.event.eid);
    const duplicateXHR = useSelector(s=>s.event.eventDuplicationXHR);
    const {close}=useGlobalShow('isOpenEvents');
    useEffect(() => {
        const timeout = setTimeout(function () {
            setShow(1);
        }, 0);
        return () => clearTimeout(timeout);
    }, []);
    const callBackDuplicateEvent=(newEvent)=>{
        history.push(`/e/${newEvent?.newEvent?.eid}/event`);
    };
    const duplicateEvent = () =>{
        let updatedEvent = {...event, ready: 0, title: event.title+' '+t('copy'),  timeOpen:null, timeStart:null,timeEnd:null};
        eventDuplication(event.eid,updatedEvent, callBackDuplicateEvent);
    };

    const isCurrentEvent=currentEid===event.eid;
    const linkClick=()=>{
        if(isCurrentEvent){
            reloadIframe();
            close();
        }
        else {
            changeAccountMessage(event,accountT,history);
        }
    };
    return (
        <>
            <Link  to={`/e/${event.eid}/realtime`}  onClick={linkClick}>
                <css.Event  className={'event ' + (show && 'show') + ' ' + className} status={(event.tickets.sold * 100) / event.tickets.amount} data-cy="eventItem" >
                    <css.EventContent className="eventContent" >
                        <css.EventContentMain className={'eventContentMain '+ paletteClass} >
                            <css.ECMainData>
                                <css.ECMainTitle className={'eCMainTitle ' +paletteClass }>{event.title}</css.ECMainTitle>
                                <css.ECMainDate className="eCMainDate" >
                                    {event.type==='course'?
                                        tsGetYear(event.timeStart)
                                        :(event.timeStart?
                                            `${Date.ts2date(event.timeStart)} ${Date.ts2time(event.timeStart)} ${getCountryByTimeZone(event.timeZone)}`
                                            :t('continuousEvent'))}
                                </css.ECMainDate>
                            </css.ECMainData>
                            <css.ECMainOptions>
                                <EventOption event={event} openDuplicateEvent={duplicateEvent}/>
                            </css.ECMainOptions>
                        </css.EventContentMain>
                        <css.EventContentBot>
                            <css.EventStateBtn>
                                <Tooltip text={t(event.active?'activeMode':'inActiveMode')}>
                                    <StaticActiveInactive isActive={event.active}/>
                                </Tooltip>
                            </css.EventStateBtn>
                            <css.EventContentPack className="eventContentBot">{event.pTitle}</css.EventContentPack>
                        </css.EventContentBot>
                    </css.EventContent>
                    <css.EventImg className="eventImg" image={event.fbImg||defaultImage}>
                        <css.EventImgOption className="eventImgOption"/>
                        <css.EventImgEdit className="eventImgEdit" />
                    </css.EventImg>
                </css.Event>
            </Link>
            {duplicateXHR==='LOADING' && <TickchakLoader/>}
        </>

    );
}

export default Event;