import * as css from './EventTemplate.css';
function EventTemplate({expand}) {
    return (
        <css.EventTemplate className={expand&&'expanded'}>
            <css.leftSquare />
            <css.wrap>
                <css.topSquare />
                <css.secondSquare/>
                <css.thirdSquare />
                <css.forthSquare />
            </css.wrap>
        </css.EventTemplate>
    );
}

export default EventTemplate;
