import styled from 'styled-components/macro';
import {cashRegister} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';

export const boxOffice = styled.div` 
    cursor : pointer;
    transition:all 400ms;
    height: 50px;
    background-color: #76D6E8;
    border-radius: 50%;
    width: 50px; 
    box-shadow: 0px 3px 6px #00000029;
    ${BGImageCenter(cashRegister,'25px')}
        &.react{
            top: -22px;
            position: absolute;
            left: calc(50% - 25px);
            &.show{
                font-size: 9px;
                line-height: 102px;
            }
        }
        :hover{
            &.react{
                background-color: #fff;
            }
            transform: scale(1.1);
            box-shadow: 0 0 9px 2px #9e9e9e; 
        }
    
`;