import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import * as css from './EmailVerificationForm.css';
import Btn from '../../../../component/button/btn/Btn';
import Input from '../../../../component/input/Input';
import React, {useEffect} from 'react';
import {Else, If, Then} from 'react-if';
import useNavigationLink from '../../../../hooks/useNavigationLink';
import {emailVerification} from '../../../../redux/account/accountActions';
import openEmailInBrowser from '../../../../library/linkToMailInbox';
import setGlobalState from '../../../../redux/setGlobalState';
import useTimeout from '../../../../hooks/useTimeout';

function EmailVerificationForm({name,onSubmit}) {

    const {t}=useTranslation('security');
    const tLayout = useTranslation('layout');
    const [field,] = useField(name);
    const {ownerEmailVerified, updateVerifiedEmailXHR}=useSelector(s => s.account);
    const useNavigation = useNavigationLink();

    let verificationParams = null;
    let params = new URLSearchParams(location.search);
    verificationParams = params?.get('emailVerify');

    useEffect(async () => {
        if(verificationParams) {
            await emailVerification({verification: verificationParams ,ownerEmailVerified:1});
            useNavigation.close('emailVerify');
        }
    }, [verificationParams]);
    const timer = useTimeout();

    useEffect(() => {
        if(updateVerifiedEmailXHR==='SUCCESS')
        {
            timer.current=setTimeout(() => {
                setGlobalState('account.updateVerifiedEmailXHR',null);
            }, 5000);
        }
    }, [updateVerifiedEmailXHR]);
    return (

        <css.container>
            <css.title>{tLayout.t('emailVerification')}</css.title>
            <If condition={ownerEmailVerified===2}>
                <Then>
                    <css.emailMessage>{t('emailSent',{email:field.value})}</css.emailMessage>
                   
                </Then>
                <Else>
                    <css.text>{t('verifyEmailText')}</css.text>
                    <css.wrapInput><Input text={t('email')} name='email' icon={'mailingIcon'}/></css.wrapInput> 
                    <css.text>{t('verifyEmailNote')}</css.text>
                </Else>
            </If>
            <css.wrapBtn> 
                <If condition={ownerEmailVerified===2}>
                    <Then>
                        <css.buttons >
                            <Btn className={`${updateVerifiedEmailXHR==='SUCCESS'?'send':''} resent`} disabled={updateVerifiedEmailXHR==='SUCCESS'} onClick={onSubmit}>
                                {updateVerifiedEmailXHR==='SUCCESS'?t('sentEmailBtn'):t('resentEmailBtn')}
                            </Btn>
                            <Btn onClick={() => openEmailInBrowser(field.value)}>
                                {t('linkEmailBtn')}
                            </Btn></css.buttons>
                    </Then>
                </If>
                <Btn className={ownerEmailVerified===2&&'LOADING'} onClick={onSubmit}>
                    {t('verify')}
                </Btn>
            </css.wrapBtn>
        </css.container>

    );

}

export default EmailVerificationForm;
