import * as css from './BottomPopup.css';

function BottomPopup({children}) {

    return (
        <css.popUpBottom>
            {children}
        </css.popUpBottom>

    );
}

export default BottomPopup;
