import  * as css  from './VerificationSuccess.css';

function VerificationSuccess({text,title, children}) {

    return (
        <css.container>
            <css.title >{title}</css.title>
            <css.text >{text}</css.text>
            {children}
        </css.container>
    );
}

export default VerificationSuccess;