import React from 'react';
import * as css from './Stage.css';
import StageItem from './stageItem/StageItem';

function Stage({tabsList,onClickStage,color}) {

    return (
        <>
            <css.stage className={'stage'} data-cy='stage'>
                {tabsList?.map((item, index) => {
                    return <StageItem key={item.type} index={index} item={item} onClickStage={onClickStage} color={color}/>;
                })}
            </css.stage>
        </>
    );
}
export default Stage;
