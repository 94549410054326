/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components/macro';
import {DEFAULT_COLOR, dFlexCenter} from '../../globalVariables/GlobalVariables';

export const container = styled.div`
width:100% ;
${dFlexCenter}
display: flex;
direction: ltr;
.full{
        background-color:${DEFAULT_COLOR} ;
        caret-color: transparent;
    }

input{
    margin:0 2px;
    width:30px ;
    height:40px ;
    border: 1px solid #000000;
    border-radius: 5px;
    text-align:center ;
    font-weight:800 ;
        :focus{
            background-color:#FFF ;
            color:${DEFAULT_COLOR};
            border: 1px solid ${DEFAULT_COLOR};
    }   
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
    }
input[type=number]{ 
    -moz-appearance: textfield;

}
.wrong{
    border: 1px solid #CC402F;
    :focus{
    border: 1px solid #CC402F;

    }
}

`;
export const colon = styled.div`


${dFlexCenter}
`;
