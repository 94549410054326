import {useHistory, useLocation} from 'react-router-dom';
import SubTopMenu from '../subTopMenu/SubTopMenu';
import * as css from './Routing.css';

function Routing({title, id, subTitle}) {
    const location = useLocation();
    const history = useHistory();

    const goBack = ()=>{
        const oneBackUrlPart = history.location.pathname.substring(0,history.location.pathname.lastIndexOf('/'));
        history.push(oneBackUrlPart);
    };

    return (
        <css.navigationRouter>
            <css.navigationFrame>
                <css.navigationTitle onClick={goBack} className={subTitle?'':'bold'}>
                    {title}
                </css.navigationTitle>
                <css.navigationLinks>
                    <SubTopMenu location={location} title={subTitle} id={id} />
                </css.navigationLinks>
            </css.navigationFrame>
        </css.navigationRouter>
    );
}

export default Routing;
