import * as css from './RightHeader.css';

function RightHeader({children,icons}) {
    return (<css.header className={!children && 'changeToEnd'}>
        {children}
        <css.icons>{icons}</css.icons>
    </css.header>
    );
}

export default RightHeader;
