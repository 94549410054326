import * as css from './Part.css';

function Part({children,className,padding,cssStyle, field='', onClick=null}) {

    return (
        <css.part data-cy={`${field}ColumnValue`} className={`${className} defaultColumn ${onClick ?? 'click'}`} padding={padding} cssStyle={cssStyle} onClick={onClick ? onClick: ()=>{}}>
            {children}
        </css.part>
    );
}
export default Part;
