import styled from 'styled-components/macro';
import {blackSuccess} from '../../assets/symbols/symbols/Symbols.css';
import convertHexToRgbA from '../../functions/ConvertHexToRgbA';
import {BGImageCenter, DEFAULT_COLOR} from '../../globalVariables/GlobalVariables';

export const wrapCheckbox = styled.div`
    width:30px ;
    padding-inline-start:10px;
    display:flex ;
    align-items:center ;
    input[type=checkbox] {
         position: relative;
	       cursor: pointer;
    }
    input[type=checkbox]:before {
         content: "";
         display: block;
         position: absolute;
         width: 20px;
         height: 20px;
         top:-4px;
         left: -3px;
         border-radius: 50%;
         transition: box-shadow 300ms;
         background-color:#D0D0D0;
}
    input[type=checkbox]:checked:before {
        background-color:${p => p.color ? p.color : DEFAULT_COLOR};

    }
    input[type=checkbox]:checked:after {
        ${BGImageCenter(blackSuccess, '10px')}
         content: "";
         display: block;
         width: 20px;
         height: 20px;
         top:-3px;
         left: -3px;
         position: absolute;

}
&.border{
    input[type=checkbox]:before{
        box-shadow:0px 0px 0px 5px rgba(${props => convertHexToRgbA(props.color ? props.color : '#76D6E8')}, 0.5);
    }
}
`;

export const text = styled.div`
 font-weight:500 ;
`;

