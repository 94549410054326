import  {useState} from 'react';
import * as css from './RadioBtn.css';

function RadioBtn(props) {
    const {classes, onClick, name, selected} = props;
    const [border, setBorder] = useState('');
    // const [selected, setSelected] = useState(0)

    const updateSelected = (e) => {
        setBorder(' border');
        setTimeout(() => {
            setBorder('');
        }, 300);
        e.stopPropagation();
        // setSelected(!selected)
        if(onClick)
            onClick();
        // onClick(!props.checked)
    };

    // useEffect(() => {
    //     if (props.selected)
    //         setSelected(1)
    // }, [])

    return (
        <>
            <css.container >
                {/* <css.input type={"radio"} name={name} checked={props.checked} className={(classes ? classes : "classicRadioBtn")} onClick={updateSelected} />
                <css.checkmark onClick={(e) => e.stopPropagation()} className={"checkmark" + border}></css.checkmark> */}

                <css.input type={'radio'} name={name} checked={selected} className={(classes ? classes : 'classicRadioBtn ') + (selected ? ' selected' : '')} onClick={updateSelected} />
                <css.checkmark onClick={(e) => e.stopPropagation()} className={'checkmark' + border} />
            </css.container>
        </>);
}
export default RadioBtn;

