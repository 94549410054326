import request from '../../library/request';
import store from '../../store';
import setGlobalState from '../setGlobalState';
import {uploadFile} from '../user/userActions';

const getEid = () => store.getState().event.eid;

export const getSearchTickets = async (search) => {
    const res = await request('tickets.getSearchTickets', 'get', `/event/${getEid()}/tickets`, {search});
    setGlobalState('tickets.searchTickets', res.ticketsInfo);
};

export const getEventTickets = async () => {
    const limit = 20;
    const storeTickets=store.getState().tickets.tickets;
    const res = await request('tickets.getEventTickets', 'get', `/event/${getEid()}/tickets`,
        {limit, offset:storeTickets?.length});
    if(!storeTickets.length){
        setGlobalState('tickets.ticketsIcon', res.ticketsIcon);
        if(!store.getState().event.settings?.report)
            setGlobalState('event.settings.report',{attendanceReport:res.attendanceReport});
    }
    const tickets=[...storeTickets,...res.ticketsInfo];
    setGlobalState('tickets.tickets', tickets);
    if(res.ticketsInfo.length < limit) {
        setGlobalState('tickets.isAcceptedAll', true);
    }
    else {
        getEventTickets();
    }

    return tickets;
};

export const checkEventLocation =  (locationMap,location) => {
    const event = store.getState().event;
    if(!event.location && !event.locationMap && locationMap){
        setGlobalState('event', {...event, location:location,locationMap:locationMap});
    }
};

export const updateTicket = async (tid, fields) => {
    if(fields.imgData?.image) { fields.imgData.image = await uploadFile(fields.imgData.image, '', 'ticketImage', getEid()); }
    const index = store.getState().tickets.tickets.findIndex(ticket => ticket.tid === tid);
    setGlobalState('tickets.currentTicket',store.getState().tickets.tickets[index]);
    let res = await request('tickets.updateTicket', 'put', `/event/${getEid()}/ticket/${tid}`, fields);
    checkEventLocation(fields?.location?.locationMap, fields?.location?.location);
    const value = store.getState().tickets.tickets[index];
    setGlobalState(`tickets.tickets[${index}]`, {...value, ...res.ticketUpdated});
    setGlobalState('tickets.currentTicket', res.ticketUpdated);
    return res.errors;
};

export const updateTicketWeight = async (tickets = []) => {
    setGlobalState('tickets.tickets', tickets);
    const tidWeights=tickets.map((i, index) => ({tid: i.tid, weight: index}));
    await request('tickets.updateTicketWeight', 'put', `/event/${getEid()}/tickets`,
        {tidWeights});
};

export const deleteTicket = async (tid) => {
    await request('tickets.deleteTicket', 'delete', `/event/${getEid()}/ticket/${tid}`);
    const index = store.getState().tickets.tickets.findIndex(ticket => ticket.tid === tid);
    const tickets = [...store.getState().tickets.tickets];
    tickets.splice(index, 1);
    setGlobalState('tickets.tickets', tickets);
};

export const duplicateTicket = async (tid, fields) => {
    const res = await request('tickets.duplicateTicket', 'post', `/event/${getEid()}/ticket/${tid}/duplicate`, fields);
    setGlobalState('tickets.tickets', [...store.getState().tickets.tickets, res.newTicket]);
    setGlobalState('tickets.currentTicket', res.newTicket);
    const ticketGroups = store.getState().tickets.groups?.filter(g => g.tickets?.some(t => t.tid === tid));
    ticketGroups.forEach(g => {
        const index = store.getState().tickets.groups.findIndex(group => group.gid === g.gid);
        setGlobalState(`tickets.groups[${index}].tickets`, [...store.getState().tickets.groups[index].tickets, res.newTicket]);
    });
};

export const getEventGroups = async () => {
    const res = await request('tickets.getEventGroups', 'get', `/event/${getEid()}/groups`);
    setGlobalState('tickets.groups', res.groupsInfo);
};

export const updateGroup = async (gid, fields) => {
    if(fields.groupImage) { fields.groupImage = await uploadFile(fields.groupImage, '', 'groupImage', getEid()); }
    const res = await request('tickets.updateGroup', 'put', `/event/${getEid()}/group/${gid}`, fields);
    const index = store.getState().tickets.groups.findIndex(g => g.gid === gid);
    const value = store.getState().tickets.groups[index];
    setGlobalState(`tickets.groups[${index}]`, {...value, ...res.updateFields});

};

export const addGroup = async (fields) => {
    if(fields.groupImage) { fields.groupImage = await uploadFile(fields.groupImage, '', 'groupImage', getEid()); }
    const res = await request('tickets.addGroup', 'post', `/event/${getEid()}/group`, fields);
    setGlobalState('tickets.groups', [...store.getState().tickets.groups, res.group]);
};

export const deleteGroup = async (gid) => {
    await request('tickets.deleteGroup', 'delete', `/event/${getEid()}/group/${gid}`);
    const index = store.getState().tickets.groups.findIndex(g => g.gid === gid);
    const groups = [...store.getState().tickets.groups];
    groups.splice(index, 1);
    setGlobalState('tickets.groups', groups);
};

export const getTicket = async (tid) => {
    const res = await request('tickets.getTicket', 'get', `/event/${getEid()}/ticket/${tid}`);
    setGlobalState('tickets.currentTicket', res.ticketInfo);
};

export const addTicket = async (fields) => {
    if(fields.imgData?.image) { fields.imgData.image = await uploadFile(fields.imgData.image, '', 'ticketImage', getEid()); }
    const res = await request('tickets.addTicket', 'post', `/event/${getEid()}/ticket`, fields);
    setGlobalState('tickets.tickets', [...store.getState().tickets.tickets, res.ticket]);
    setGlobalState('tickets.currentTicket', res.ticket);
    checkEventLocation(fields?.location?.locationMap, fields?.location?.location);
    if(res?.ready)
        setGlobalState('event.ready', res.ready);
};

export const updateTicketsIcon = async (icon) => {
    await request('tickets.updateTicketsIcon', 'put', `/event/${getEid()}/ticketsIcon`, {icon});
    setGlobalState('tickets.ticketsIcon',icon);
};

export const updateGuideReport = async (tid,uid,status) => {
    await request('tickets.updateGuideReport', 'put', `/event/${getEid()}/ticket/${tid}/guide/${uid}/report`, {status});
    const tickets= store.getState().tickets.tickets;
    const index=tickets.findIndex(ticket=>ticket.tid===tid);
    const userIndex=tickets[index].users.findIndex(user=>user.uid===uid);
    setGlobalState(`tickets.tickets[${index}].users[${userIndex}].attendanceReport`,status);
};
export const sendTicket = async (mailToSend, subject, emailContent, eid, lang) => {
    await request('tickets.sendTicket', 'post', `/event/${eid}/mail`,{mailToSend,subject,emailContent,lang});
};

