import * as css from './EditImageForTypes.css';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import setGlobalState from '../../redux/setGlobalState';
import {useSelector} from 'react-redux';
import ExternalImagePopup from '../imagePopup/externalImagePopup/ExternalImagePopup';

function EditImageForTypes({update = () => { }, images, currentLang, setCurrentLang, langDesign}) {
    const {t} = useTranslation('event');
    const [screenType, setTypeScreen] = useState('desk');
    const [image, setImage] = useState(images[screenType]);
    const sizes = {'mobile': {height: 215, width: 180}, 'post': {height: 250, width: 250}, 'desk': {height: 200, width: 300}};
    const recommendedSizes = {mobile: '215x180', desk: '1080x1920', post: '400x400'};
    const design = useSelector(state => state.event.settings.design);

    useEffect(() => {
        setImage(images[screenType]);
    }, [ screenType, images]);

    const handleLanguageClick = (item) => {
        setGlobalState('event.settings.design',{...design,fbImg:item.fbImg.image,fbImgMobile:item.fbImgMobile.image,fbImgPost: item.fbImgPost.image});
        setCurrentLang(item.lang);
    };
    return (
        <css.container data-cy="EditImageForTypes">
            <css.sceenTypes>
                <css.mobileIcon  onClick={() => {
                    setTypeScreen('mobile');
                }} className={screenType == 'mobile' ? ' checked' : ''} data-cy = "mobileScreenTypeBtn">{t('mobile')}</css.mobileIcon>
                <css.descIcon onClick={() => setTypeScreen('desk')} className={screenType == 'desk' ? ' checked' : ''} data-cy = "deskScreenTypeBtn" >{t('screen')}</css.descIcon>
                <css.postIcon onClick={() => setTypeScreen('post')} className={screenType == 'post' ? ' checked' : ''} data-cy = "postScreenTypeBtn">{t('post')}</css.postIcon>
            </css.sceenTypes>
            
            <css.wraper>
                <css.description data-cy="imageSizeDescription" length={langDesign.length}>
                    { langDesign?.length > 1 && <css.wrapLangsBtn>
                        {
                            langDesign && langDesign.map((item, index)=>{
                                return  <css.langbtn key={index} className={item.lang == currentLang && 'selected'} onClick={() => handleLanguageClick(item)} data-cy={`langbtn${index}`}>
                                    {item.nativeName}
                                </css.langbtn>;
                            })
                        }
                    </css.wrapLangsBtn>}
                    {t(`imageDescription_${screenType}`)}
                    <css.sizeDesciption>{t('imageSize') + ' ' + recommendedSizes[screenType]}</css.sizeDesciption>
                </css.description>
                <css.imageEditor length={langDesign.length}>
                    <ExternalImagePopup imageProps={
                        {   
                            size: sizes[screenType],
                            image: image.image,
                            imageId: image.imageId,
                            setImage: (i)=>{setImage(i);!i&&update(i,screenType);},
                            update: (image)=>update(image,screenType),
                            type: 'image/jpeg'
                        }                    
                    } creditProps={image.credit}
                    />
                </css.imageEditor>
            </css.wraper>
        </css.container >
    );
}
export default EditImageForTypes;