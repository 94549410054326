import {useTranslation} from 'react-i18next';
import {teamMemberPhoto} from '../../../../assets/symbols/images/teamMemberPhoto';
import {analyst, conductor, designer, guide, manager, marketingSeller, owner} from '../../../../assets/symbols/symbols/Symbols.css';
import CircleImageEdit from '../../../../component/circleImageEdit/CircleImageEdit';
import TeamMemberRole from '../teamMemberRole/TeamMemberRole';
import  * as css  from './TeamMember.css';
import Input from '../../../../component/input/Input';
import {useField, useFormikContext} from 'formik';
import {getUserDetails} from '../../../../redux/team/teamActions';
import {useSelector} from 'react-redux';
import EventRolePermission from '../../../components/rolePermission/EventRolePermission';
import {roles} from '../../../team/Team';
import setGlobalState from '../../../../redux/setGlobalState';
import EmailInput from '../../../../component/input/EmailInput';
import PhoneInput from '../../../../component/input/phoneInput/PhoneInput';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import useTeamMembers from '../../../../hooks/useTeamMembers';
import {initPhoneNumber} from '../../../../functions/intrernationalPhoneNumber';

export const roleIcons={
    owner,
    manager,
    conductor,
    guide,
    designer,
    analyst,
    representative:marketingSeller,
};

function TeamMember() {

    const {t} = useTranslation('team'); 
    const tLayout = useTranslation('layout'); 
    const [pictureField, ,pictureHelpers] = useField('picture');
    const [roleField, ,roleHelpers] = useField('role');
    const {isMaster,uid} = useSelector(state => state.user);
    const {accountMembers}=useTeamMembers();
    let params = new URLSearchParams(location.search);
    const isPackagePage=params.get('packagePage')==='team';
    const currentMemberUid=Number(params.get('uid'));
    const {isGuide,existsUser,isPackageRole,role} = useSelector(s=>({
        isGuide:s.ui.team.isGuide,
        existsUser:s.team.existsUser,
        isPackageRole:s.team.members.find(m=>m.uid===currentMemberUid)?.isPackageRole===true,
        role:s.team.members.find(m=>m.uid===currentMemberUid)?.role,
    }));  
    const userRole= useSelector(s=>isPackagePage?s.account.userAccountRole:s.event.userEventRole);
    const eventType  = useSelector(s=>s.event.type);

    const form = useFormikContext();
    
    const checkUserInfo= ()=>{    
        const phone = initPhoneNumber({nationalNumber: form.values.phone, country: form.values.country}).internationalNumber;
        getUserDetails(form.values.email,phone).then((res)=>{
            if(res){
                bounceMessage(t('existsUserMessage'),'warning', 
                    {text: tLayout.t('okBtn'),onClick:()=> {
                        form?.setValues({...form.values, ...res});
                        setGlobalState('team.existsUser', res);
                    }},
                    {text: tLayout.t('btnMessage')});
            }
            else if(existsUser) {
                setGlobalState('team.existsUser', res);
                form?.setValues({...form.initialValues,
                    phone:phone,
                    email:form.values.email});
            }
        });
    };
    const membersByRoles=roles.reduce((all,role)=>{
        const roleMembers=accountMembers.filter(({role:teamRole})=>teamRole===role);
        return roleMembers.length?{...all,[role]:roleMembers}:all;
    },{});
    
    const canAddOwner=()=>{
        if(isMaster&&membersByRoles?.owner?.length<2&&isPackagePage)
            return true;
        return false;
    };
    const canEdit=()=>{
        if(isMaster || uid===currentMemberUid || ((userRole==='manager' || userRole==='owner') && role==='guide'))
            return true;
        return false;
    };
    const propsTeamMember=(name)=>({
        icon:roleIcons[name],
        text:t(name),
        type:name,
        selected:roleField.value,
        isDisabled:(form.initialValues.role=='owner'&&(!isPackagePage||!isMaster||membersByRoles?.owner?.length<2))||isPackageRole||(eventType==='course' && name!=='guide'&& isGuide),
        onClick:()=>{roleHelpers.setValue(name);roleHelpers.setTouched(true);}
    });
    return (
        <>
            <EventRolePermission rolePermission={'manage event'}> 
                {/* <css.text>{t('personalDetails')}</css.text> */}
                <css.container>
                    <CircleImageEdit unableEdit={!canEdit()} isDefaultImg={teamMemberPhoto===pictureField.value} size={{height:115,width:115}} image={pictureField.value} setImage={()=>pictureHelpers.setTouched(true)}
                        saveImage={(image)=>pictureHelpers.setValue(image)} deleteImage={()=>pictureHelpers.setValue(teamMemberPhoto)}/>
                    <css.wrapper>
                        <css.wrapTeamMemberRole>
                            <TeamMemberRole {...{...propsTeamMember('owner'),...(!canAddOwner()?({isDisabled:true,...(isMaster?{onClick:()=>bounceMessage(t('addOwnerMassage'),'warning'),cssStyle:' &.ownerDisable{pointer-events: unset;}'}:{})}):{})}} icon={owner}/>
                            <TeamMemberRole {...propsTeamMember('manager')} icon={manager}/>
                            {eventType==='course'&& <TeamMemberRole {...propsTeamMember('guide')} icon={guide}/>}
                            <TeamMemberRole {...propsTeamMember('conductor')} icon={conductor}/>
                            <TeamMemberRole {...propsTeamMember('designer')} icon={designer}/>
                            <TeamMemberRole {...propsTeamMember('analyst')} icon={analyst}/>
                            <TeamMemberRole {...propsTeamMember('representative')} icon={marketingSeller}/>
                        </css.wrapTeamMemberRole>
                    </css.wrapper>
                    <css.inputs> 
                        <EmailInput text={t('email')} name='email' events={{onBlur:checkUserInfo}} disabled={currentMemberUid}/>
                        <PhoneInput countryFieldName='country' text={t('phone')} name='phone' events={{onBlur:checkUserInfo}} disabled={(currentMemberUid&&!isMaster)}/>
                        <Input text={t('firstName')} name='nameFirst' disabled={existsUser||currentMemberUid}/>
                        <Input text={t('lastName')} name='nameLast' disabled={existsUser||currentMemberUid}/>
                    </css.inputs>
                </css.container>
            </EventRolePermission>
        </>
    );
}

export default TeamMember;