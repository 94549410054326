import {isMaster} from './permission';
import store from '../store';

export default function getPathByEventRole(event){
    const perPath=`/e/${event.eid}`;
    const userAccountRole=store.getState().account.userAccountRole;
    const role=event.userEventRole||userAccountRole;
    if(event.ready<5){
        if(isMaster()|| role === 'manager' || role === 'owner')
            return `${perPath}/event`;
        return '/';
    }
    switch (role){
        case 'conductor':
        case 'representative':
            return `${perPath}/crm`;
        case 'designer':
            return `${perPath}/design`;
        case 'guide':
            return `${perPath}/attendance`;
    }

    if(window.location.pathname.includes('/realtime') && event.type==='frontpage')
        return `${perPath}/design`;

    return `${perPath}/realtime`;
}