import styled from 'styled-components/macro';
import { BGImageCenter, desktopMedia } from '../../../globalVariables/GlobalVariables';
import { arrowBtn } from '../../../assets/symbols/symbols/Symbols.css';
import { List } from '../../list/List.css';


export const container = styled.div`
    display: flex;
`

export const filter = styled.div`
    border-bottom: 1px solid;
    position: relative;
    cursor: pointer;
    direction: initial;
    height: 35px;
    width: 43px;
    transition: all 300ms;
    &.open{
        border-radius: 0;
    }
    ::before{
        content:'';
        ${BGImageCenter(arrowBtn, '6px')};
        position:absolute;
        background-size: 8px 8px;
        background-repeat: no-repeat;
        background-position: center center;
        width: 8px;
        height: 8px;
        inset-inline-end: 7px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(-90deg);
        top: calc(50% - 4px);
    } 

    ${List}  {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        box-shadow: 0px 13px 26px #00000029;
        ${desktopMedia} {
            height: 290px;
            position: relative;
        }
        div[class*='ListTitle']{
            height: 100px;
        }
    }

    .itemIcon {
        background-size: cover;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
    }
`

export const chosenItem = styled.div`
    height: 100%;
    font-weight: bold;
    display:flex;   
    align-items: center;
    justify-content: flex-start;

    img {
        background-color: lightgray;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        box-shadow: 0px 3px 6px #00000029;
        border: 0.5px solid;
    }
`
export const chosenItemTitle = styled.div`
    height: 100%;
    font-weight: bold;
    display:flex;   
    align-items: center;
    justify-content: flex-start;

    &.open {
        padding: 15px;
        height: 50px;
        gap:10px;
        border-bottom: 1px solid #EEE;
        img {
            border-radius: 0;
        }
    }
    img {
        background-color: lightgray;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        box-shadow: 0px 3px 6px #00000029;
        border: 0.5px solid;
        width: 24px;
        height: 17px;
        border: none;
    }
`
export const searchWrapper = styled.div`
    border-bottom: 1px solid #EEE;
    direction: rtl;
    width: 100%;
`;