import * as css from './PackageUpgrade.css';
import {useTranslation} from 'react-i18next';
import setGlobalState from '../../redux/setGlobalState';

function PackageUpgrade({children,className,permission}) {

    const {t} = useTranslation('layout');

    const upgrade=()=>{
        setGlobalState('ui.account.upgradePermission', permission);
    };

    return (
        <css.packageUpgrade className={className&&className} onClick={upgrade}>
            <css.packageUpgradeIcon className={' packageUpgradeIcon '+(className&&className)} dataTitle={t('packageUpgrade')} />
            <css.packageUpgradeBlock >
                {children}
            </css.packageUpgradeBlock>
        </css.packageUpgrade>
    );
}

export default PackageUpgrade;
