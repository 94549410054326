import createReducer from '../createReducer';

const initialState = {
    clusterEvents: [],
    firstEvent: 0,
    clusterEventsSearch: [],
    getClusterEventsXHR: null,
    // getEvents: 'null',/////////////
    getEventLoading: 'null',
    childEventStats: null,
    groups: [],
    searchEvents: [],
    getClusterGroupEventsXHR: null,
    getChildEventStatsXHR: null,
    updateGroupImageXHR:null,
};

const reducer = createReducer('cluster', initialState);

export default reducer;