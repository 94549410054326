import React from 'react';
import * as css from './WhatsAppPreview.css';
import {getTs,  tsToDate} from '../../../../library/dates';
import {useSelector} from 'react-redux';
import {convertVariablesToHtml} from '../../../../component/textEditor/Variables';
import {useVariables} from '../../../../assets/objects/Variables';
import {useTranslation} from 'react-i18next';
import {createMarkup} from '../../previewScreen/PreviewScreen';

function WhatsAppPreview({brandingPreview}) {

    const currentLang = useSelector(s => s.branding.currentLang);
    const value = useSelector(s => s.branding.data?.[currentLang]?.['whatsappContent']);
    const {defaultVariables} = useVariables();
    const {t} = useTranslation('variables');

    const htmlWithValues = value ? convertVariablesToHtml(value, defaultVariables,t): '';
    let text  = `<p>${t('branding:hello')}</p><p>${htmlWithValues}</p><p>${t('branding:endLine')}</p>`;
    text = text?.replace(/\\n/g, '</p><p>');
      
    return (   
        <css.wrapper data-cy={'whatsAppPreview'}>
            <css.title> 
                <css.profile />
                {brandingPreview||'Tickchak'}    
            </css.title>
            <css.text time={tsToDate(getTs(), null, null, 'HH:mm')} dangerouslySetInnerHTML={createMarkup(text)} />
        </css.wrapper>
    );
}

export default WhatsAppPreview;
