import {useEffect, useState,useRef} from 'react';
import List from '../list/List';
import * as css from './ListWrap.css';

function ListWrap({list,onClick,t,top, left ,width,showClass,fixedTop,close}) {
    const [currentIndexes, setCurrentIndexes] = useState([]);
    const [currentIndexes1, setCurrentIndexes1] = useState([]);
    const [current, setCurrent] = useState('indexB');
    const [isReturn, setIsReturn] = useState(true);
    const [{indexAShow, indexBShow}, setShow] = useState({indexAShow: false, indexBShow: true});
    const [isFirst,setIsFirst]=useState(true);

    const click=()=>{
        setShow({indexAShow:true,indexBShow:true});
        setTimeout(()=>{
            setCurrent(current=='indexA'?'indexB':'indexA');
        },0);
    };
    const isFirstRun = useRef(true);

    useEffect (() => {
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        setTimeout(()=>{
            setShow({indexAShow:current=='indexA'?true:false,indexBShow:current=='indexB'?true:false});
        },300);
    },[current]);

    function arrowReturnClick(e) {
        setIsReturn(true);
        e.stopPropagation();
        let temp = list;
        for(let i = 0; i < currentIndexes1.length - 1; i++)
            temp = temp.arr[currentIndexes1[i]].list;
        setIndexes(null,'return');
        click();
    }

    function setIndexes(val,type) {
        let setIndexesFunc= current=='indexB'?setCurrentIndexes:setCurrentIndexes1;
        let indexes= current=='indexB'?currentIndexes1:currentIndexes;
        if(type=='into')
            setIndexesFunc([...indexes, val]);
        else setIndexesFunc(indexes.slice(0, indexes.length - 1));
    }

    function arrowIntoClick(key, e) {
        if(isFirst)
            setIsFirst(false);
        setIsReturn(false);
        e.stopPropagation();
        setIndexes(key,'into');
        click();
    }

    function getCurrentList(index) {
        let temp = list, indexes = index ? currentIndexes1 : currentIndexes;
        indexes.forEach((c) => {
            temp = temp.arr[c].list;
        });
        return temp;
    }

    const comp=(index)=><List
        classes={showClass==''?'':(isFirst?'':(isReturn && ' return ') + ( (index===0?(current == 'indexA' ? ' current ' : ' notCurrent '):(current == 'indexB' ? ' current ' : ' notCurrent '))))}
        arrowReturnClick={arrowReturnClick} arrowIntoClick={arrowIntoClick}
        onClick={getCurrentList(index).onClick||onClick}
        isChild={(index ? currentIndexes1: currentIndexes).length>0}
        showClass={showClass}
        width={width}
        left={0}
        t={t}
        title={getCurrentList(index).title}
        list={getCurrentList(index).arr} top={0}
        close={close}/>;

    return (
        <css.listWrap left={left} top={fixedTop?fixedTop:top} isFixed={fixedTop?true:false} >
            {indexAShow && comp(0)}
            {indexBShow && comp(1)}
        </css.listWrap>

    );

}

export default ListWrap;

