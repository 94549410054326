import {useTranslation} from 'react-i18next';
import InputList from '../../../../component/input/inputList/InputList';
import TimeZoneList from './timeZoneList/TimeZoneList';
import {useSelector} from 'react-redux';
import {updateEvent} from '../../../../redux/event/eventActions';
import store from '../../../../store';
import * as css from './LocaleStep.css';
import {DEFAULT_LANG, DEFAULT_TIME_ZONE} from '../../../../globalVariables/locals';
import DisabledInput from '../../../../component/input/disabledInput/DisabledInput';

export const  onClickLocale= async(a)=>{
    await updateEvent(a.locale);
    
};

export const getTopLocale=(value)=>
     `${value.timeZone||DEFAULT_TIME_ZONE}, ${store.getState().languages.allLanguages?.find(l=>l.lang===value.lang)?.nativeName||DEFAULT_LANG}`;

function LocaleStep() {
    const {t} = useTranslation('newEvent');
    
    const event = useSelector(s=>s.event);
    const isReady = event.ready===5;
    const allLanguages=useSelector(s=>s.languages.allLanguages);

    const langList=allLanguages.map(lang=>({key:lang.lang,text:lang.nativeName}));

    return (
        <>
            <css.globalStyle/>
            <TimeZoneList/>
            {isReady ? <DisabledInput value={langList?.find(l=>l.key=== event.lang)?.text} text={t('lang')} /> :
                <InputList className="timeZoneList" list={langList} name="locale.lang" text={t('lang')}/>}

        </>

    );
}

export default LocaleStep;