import {scrollbar} from '../../../../globalVariables/GlobalVariables';
import {createGlobalStyle} from 'styled-components';

export const globalStyle = createGlobalStyle`
    .cm-scroller,
    .ͼ2 .cm-activeLine,
    .cm-activeLine,
    .ͼ1 .cm-gutterElement {
        background-color: #F3F3F3 ;
        ${scrollbar};
    }
`;