import React, {useState, useEffect} from 'react';
import './TableSearch.css';
import Button from '../../Button/Button';
import t from '../../../TranslateHe';

const RtlCss = React.lazy(() => import('./TableSearch-rtl'));

function TableSearch(props){
    const [active, setActivity] = useState(0);
    const [specialSearch, setSpecialSearch] = useState([]);
    useEffect(() => {
        if(props.search && props.search.active && props.search.specialSearch.option){
            props.search.specialSearch.option.forEach(item => setSpecialSearch(prevState => [...prevState, {type:item.type,text:item.text,value:''}]));
        }
    }, [props.search.specialSearch.option]);

    if(!props.search || !props.search.active){
        return null;
    }

    const imgSearchIcon = props.value ? 'close' : 'search';

    let specialSort = '';
    if(props.search.specialSearch && props.search.specialSearch.active){
        let specialOptions = specialSearch.map(function (item, i) {
            return (
                <div className="special-search-option" key={item.type} type={item.type}>
                    <div className="name">{item.text}</div>
                    <div className="input">
                        <input type="text"
                            name={item.type}
                            value={item.value}
                            onChange={(event) => {
                                let newSpecialSearch = [...specialSearch];
                                newSpecialSearch[i] = {type:item.type,text:item.text,value:event.target.value};
                                setSpecialSearch(newSpecialSearch);
                            }
                            }
                        />
                    </div>
                </div>
            );
        });
        specialSort = <div className="special-search-list"><form>{specialOptions}
            <Button text={t('search')} additionalClass="special-search-btn" handle={()=>{
                props.handle('specialSearch', {'value':specialSearch});
            }}/>
        </form>
        </div>;
    }

    const tableSearchClass = 'table-search' + (active ? ' show-special' : '');
    return (
        <>
            {(props.lang === 'he') && <RtlCss />}
            <div className={tableSearchClass}>
                <div className="table-search-items">
                    <div className="table-search-item icon" image={imgSearchIcon} imageColor="a7a6a6" onClick={(event)=>{props.handle('search', {value:event.target.value, close:1});}} />
                    <input className="table-search-item search" type="text" value={props.value} onChange={(event)=>{props.handle('search', {value:event.target.value, close:0});}} placeholder={t('Search')}/>
                    <div className="table-search-item special-search" image="list" imageColor="a7a6a6" onClick={() => {setActivity(!active);}} />
                </div>
                {specialSort}
            </div>
        </>
    );
}

export default TableSearch;