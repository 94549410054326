import RightPart from './rightPart/RightPart';
import LeftPart from './leftPart/LeftPart';
import ParticipantsPart from './participantsPart/ParticipantsPart';
import TicketRow from './TicketRow';
import CourseRowDetails from '../../ticketsPopUp/courseTicket/courseRowDetails/CourseRowDetails';
import CoursePopupContent from '../../ticketsPopUp/courseTicket/coursePopupContent/CoursePopupContent';

function CourseRow({ticket}) {

    return (
        <TicketRow TicketPopup={CoursePopupContent} ticket={ticket}>
            {(popupShow)=>(
                <>
                    <RightPart ticket={ticket} openPopup={popupShow.open} />
                    <ParticipantsPart eventType={'course'} ticket={ticket}/>
                    <CourseRowDetails ticket={ticket} />
                    <LeftPart ticket={ticket} courseShow={popupShow}/>
                </>
            )}
        </TicketRow>
    );
}
export default CourseRow;
