import * as css from './RightPart.css';
import {useTranslation} from 'react-i18next';
import ticketColors from '../../../../../assets/objects/TicketColors';
import TicketProgress from '../ticketProgress/TicketProgress';
import TicketsGroups from '../ticketGroups/TicketsGroups';
import {useSelector} from 'react-redux';

function RightPart({ticket,openPopup}) {
    const {t} = useTranslation('ticket');
    const color = `#${ticket.color}`;
    const ticketsIcon = useSelector(state => state.tickets.ticketsIcon);

    //remember html escape
    return (
        <>
            <css.firstPart onDoubleClick={openPopup} className='titleColumn' >
                <css.placeDrag className=' place ' />
                <css.icon icon={ticketColors[`${ticket.color}`]?.[ticketsIcon]} color={color} className='row_icon'>
                    {ticket.imgData && <css.image image={(JSON.parse(ticket.imgData).path)} />}
                    <TicketProgress onClick={(e)=>{e.stopPropagation(); openPopup();}} ticket={ticket}/>
                </css.icon>
                <css.right>
                    <css.header>
                        <css.text data-cy='typeColumnValue'>{t(ticket.type)}</css.text>
                        <css.title data-cy='titleColumnValue'>{ticket.title}</css.title>
                    </css.header>
                    <TicketsGroups ticket={ticket}/>
                </css.right>
            </css.firstPart>
        </>

    );
}
export default RightPart;
