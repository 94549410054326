import styled from 'styled-components/macro';
import {mobileScreen} from '../../globalVariables/GlobalVariables';

export const oCircle = styled.div`
    transition: all 100ms;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => props.circleColor?props.circleColor:'#fff'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-top: -6px;
    &.currentStage{
        transform: scale(1.7);
        border: 1px solid ${props => props.borderColor?props.borderColor:'#43BAE7'};
    }
`;

export const iCircle = styled.div`
    transition: all 100ms;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${props => props.circleColor?props.circleColor:'#43BAE7'};
`;

export const stageText = styled.div`
  color: ${props => props.stageTextColor?props.stageTextColor:'#5D5D5D'};
    font-size: 14px;
    letter-spacing: 1.2px;
    &.currentStage{
        font-weight: bold;
    }
`;

export const stage = styled.div`
    display: flex;
    justify-content: flex-${props => props.theme.rtl ? 'end' : 'start'};
    margin-left:${props => props.theme.rtl ? 100 : 440}px;
    @media screen and (max-width:${mobileScreen}px){  
        justify-content: center;
        margin-left: 0px;
    
    } 
`;

export const wrapStae = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 15px;
    cursor: pointer;
    :hover{
        .oCircle{
        :not(.currentStage){ 
            transform: scale(1.7);
            border: 1px solid ${props => props.borderColor?props.borderColor:'#43BAE7'};
         .iCircle{
            width: 6px;
            height: 6px;
        }
      }}
    }
`;