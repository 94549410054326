import React, {useState} from 'react';
import * as css from './Checkbox.css';

function Checkbox({classes, onClick, color, selected} ) {
    const [border, setBorder] = useState('');

    const updateSelected = (e) => {
        e.stopPropagation();
        setBorder(' border');
        setTimeout(() => {
            setBorder('');
        }, 300);
        if(onClick)
            onClick(!selected);
    };

    return (
        <css.checkbox data-cy ={(classes ? classes : 'classicCheckbox')} className={(classes ? classes : 'classicCheckbox') + (selected ? ' selected' : '') + border} onClick={updateSelected} color={color}/>
    );
}
export default Checkbox;
