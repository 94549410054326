import styled from 'styled-components/macro';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';
import {cssEscape} from '../../library/charEscape';
import zIndex from '../../globalVariables/zIndex';
export const title =styled.div`
    position:relative;

    ::before {
        content:"${props=>(cssEscape(props.dataTitle))}";
        background: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 3px;
        padding: 2px 5px;
        top: -15px;
        width: max-content;
        left:50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        text-align: center;
        position: absolute;
        opacity:0;
        transition:all 400ms;
        pointer-events:none;
    }
    ::after {
        opacity:0;
        content: '';
        background: #fff;
        width: 10px;
        height: 10px;
        position: absolute;
        -webkit-transform: rotate( 45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 5px;
        right: calc(50% - 5px);
        transition:all 300ms;
    }
    :hover::before,:hover::after{
        opacity:1;
    }
`;
export const titleBlack =styled(title)`
    ::after {   
        top:unset;   
        bottom: 4px;
        background: #0B0B0B;
    }
    ::before {
        background: #0B0B0B;
        top:unset;
        border: 1px solid #fff;
        border-top: 0;
        color: #fff;
        bottom: -15px;
        z-index: ${zIndex.baseHighest};
        font-size: 12px;
        padding: 4px 20px;
        ${props=>(props.dataTitleIcon?`
        background-image:url("`+symbol[props.dataTitleIcon]+`");
        background-size:10px 10px;
        background-repeat:no-repeat;
        padding-`+(props.theme.rtl?'left':'right')+`:32px;
        background-position:`+(props.theme.rtl? '12px':'calc(100% - 12px)')+` center;
        `:'')};
    }
 
`;
export const titleBlue =styled(title)`
    ::before {
        background: #01acf1;
        font-weight: 600;
        top:unset;
           bottom: 20px;
        line-height: 24px;
        z-index: ${zIndex.baseRaised};
        padding: 2px 10px;     
    }
    ::after {      
        background: #01acf1;
        top:unset;
        bottom: 14px;
        z-index: ${zIndex.baseRaised};
    }
`;
