import styled from 'styled-components/macro';

export const chekBoxText = styled.div`
    font-size: 14px;
    margin: 0 4px;
    letter-spacing: 1px;
    cursor: pointer;
`;

export const WrapChekBox = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;

`;