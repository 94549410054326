import * as css from './State.css';
import Animation from '../animation/Animation';
import activeJson from '../../assets/animations/active.json';
import useEffectOnUpdate from '../../hooks/useEffectOnUpdate';
import {useState} from 'react';

function State(props) {
    const {active,activeText,inactiveText,className,onClick,activeXHR}=props;
    const [successAndActive,setSuccessAndActive]=useState();
    const updateState=(e)=>{
        if(onClick)
            onClick(!active,e);
    };
    useEffectOnUpdate(()=>{
        setTimeout(()=> {
            setSuccessAndActive(!!active);
        },700);
    },[active]);
    return (
        <>
            {successAndActive  &&<css.stateAnimation ><Animation animation={activeJson} isLoop={false}/></css.stateAnimation>}
            <css.state active={active} successAndActive={successAndActive} className={`${className||''} ${activeXHR}`}  onClick={updateState} data-cy='statusMode'>
                {active?activeText:inactiveText}
            </css.state>
        </>
    );
}
export default State;