import React, {useRef, useState} from 'react';
import * as css from './UserImageMenu.css';
import FileUploader from '../../../../../../component/dropzone/Dropzone';
import {updateUserDetails} from '../../../../../../redux/user/userActions';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import UseMouseUpOutside from '../../../../../../hooks/UseMouseUpOutside';
const UserImageMenu = (props) => {
    const {t} = useTranslation('layout');
    let [isPreview, setIsPreview] = useState(false);
    const user=useSelector(s=>s.user);
    let [files, setFiles] = useState([]);

    const uploadImageHandler = (filesU) => {
        props.uploadImage(filesU[0]);
        props.close();
    };

    const uploadSelectHandler = (upload) =>{
        setIsPreview(false);
        if(upload){
            props.isOpen();
            updateUserDetails({picture:files[0].base64});
        } else {

            setFiles([]);
        }
    };

    const refMenu=useRef();
    UseMouseUpOutside(refMenu,()=>{props.close();});
    // const thumbs = files.map(file => {
    //     return(<css.UserPreviewImgPreview key={file.name}>
    //         <css.UserPreviewImgImg src={file.preview}/>
    //         {/*<ImageCrop path={file.preview}/>*/}
    //     </css.UserPreviewImgPreview>)
    // });
    return (
        !isPreview &&
        <css.UserImgMenuDivStyled ref={refMenu}>
            <css.UserImgMenuLine className="title">{t('profilePicture')}</css.UserImgMenuLine>
            <css.UserImgMenuLine><FileUploader onUpload={uploadImageHandler} maxFiles={1} btnTitle={t('newImage')} type="image/*"/></css.UserImgMenuLine>
            {!props.isDefaultImg && <css.UserImgMenuLine onClick={(e)=>{props.deleteImage();e.stopPropagation();props.close();}}>{t('removeImage')}</css.UserImgMenuLine>}
        </css.UserImgMenuDivStyled>
    // : <css.UserPreviewImgCtr>
    //     <css.UserPreviewImgPreviewCtr>
    //         { thumbs}
    //     </css.UserPreviewImgPreviewCtr>
    //     <css.UserPreviewImgSelect>
    //         <css.UserPreviewImgSelectYes onClick={() => uploadSelectHandler(true)}/>
    //         <css.UserPreviewImgSelectNo onClick={() => uploadSelectHandler(false)}/>
    //     </css.UserPreviewImgSelect>
    // </css.UserPreviewImgCtr>
    );
};

export default UserImageMenu;
