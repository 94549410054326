import Btn from '../../../../component/button/btn/Btn';
import * as css from './MailingPackage.css';
import {useTranslation} from 'react-i18next';
import {formatDecimal, formatNumber} from '../../../../library/numberFunction';
import {buyMailingPackage} from '../../../../redux/mailing/mailingActions';
import {useSelector} from 'react-redux';
import useShow from '../../../../hooks/useShow';
import BottomPopup from '../bottomPopup/BottomPopup';
import {upgradePackage} from '../../../../assets/symbols/images/Images.css';

function MailingPackage({mailingPackage}) {
    const {t} = useTranslation('package');
    const {open, isOpen, close, showClass} = useShow();
    const account = useSelector((s:any) => s.account);
    const selected = mailingPackage.mpid === parseInt(account?.mailingPackage?.mpid);
    const buyPackage = () => {
        buyMailingPackage(mailingPackage.mpid);
        close();
    };
    return (<>
        <css.container className={selected && 'selected'}>
            <css.checkbox className={selected && 'selected'}/>
            <css.title>{mailingPackage.title}</css.title>
            <css.packageDetails>{t('SMS')} {formatNumber(mailingPackage.amountSms)}</css.packageDetails>
            <css.packageDetails>{t('E-mail')} {formatNumber(mailingPackage.amountEmail)}</css.packageDetails>
            <css.price>{formatNumber(mailingPackage.price)}₪</css.price>
            <css.detailsTitle>{t('additionalPrices')}</css.detailsTitle>
            <css.detail>{formatDecimal(account.billPackage?.chargeSms) * 100} {t('smsPrice')} <span>({t('perUnit')})</span></css.detail>
            <css.detail>{formatDecimal(account.billPackage?.chargeEmail) * 100} {t('emailPrice')} <span>({t('perUnit')})</span></css.detail>
            <css.detail>{formatDecimal(account.billPackage?.chargeWhatsapp) * 100} {t('whatsappPrice')} <span>({t('perUnit')})</span></css.detail>
            <css.detail>{t('mailingPurchase')} <span>({t('free')})</span></css.detail>
            <Btn className={''} onClick={open} disabled={false}>
                {t('choosePackage')}
            </Btn>
        </css.container>        
        {isOpen && <BottomPopup icon={upgradePackage} close={close} showClass={showClass} disabledAcceptBtn={false} headerText={t('funToUpgrade')} acceptClick={buyPackage}>
            <css.popupContent>
                {t('buyPackage', {packageName: mailingPackage.title, price: formatNumber(mailingPackage.price)})}
            </css.popupContent>
        </BottomPopup> }
    </>
    );
}

export default MailingPackage;
