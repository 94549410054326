import styled from 'styled-components/macro';

export const block = styled.div`
width:100% ;
padding-right:10px ;
`;

export const boldText = styled.div`
    font-weight:600 ;
    font-size:14px;
    &.noWarp{
        white-space:nowrap ;
    }
    
`;
export const text = styled.div`
    display:flex ;
    flex-direction: ${p => (p.reverse ? 'row-reverse' : 'row')};
    justify-content: ${p => (p.reverse && 'flex-end')};
    font-size:12px ;
    line-height:15px ;
`;

export const image = styled.div`
  margin-inline-end: -15px;
  width: 30px;
  position: relative;
  border-color: #000000;
`;
