import styled from 'styled-components';

export const icon=styled.div`
    background-repeat: no-repeat;
    height: 17px;
    width: 18px;
    background-size: contain;
    background-position: center;
    margin-${props => props.theme.rtl ? 'left' : 'right'}: 10px;
`;
export const bottomItem = styled.div`
    display: flex;
    .text{
        height: 22px;
        line-height: 22px;
        border-${props => props.theme.rtl ? 'right' : 'left'}: 1px solid;
        padding-${props => props.theme.rtl ? 'right' : 'left'}: 15px;
        @media screen and (max-width: 750px){  
            padding-${props => props.theme.rtl ? 'right' : 'left'}: 0px;
        } 
    }
`; 

export const bottomScheduled=styled(bottomItem)`
    padding: 5px 20px 20px;
    @media screen and (max-width: 750px){  
        position: absolute;
        ${props => props.theme.rtl ? 'left' : 'right'}: 19px;
        padding:0;
    }
`;

export const scheduledIcon=styled(icon)`
    @media screen and (max-width: 750px){  
        margin-${props => props.theme.rtl ? 'left' : 'right'}: 5px;
    }
`;

export const outerSquare=styled.div`
    border:1px solid;
    width:15px;
    height:15px;
    position: relative;
    margin-top: 3px;
    cursor: pointer;
    float: ${props => props.theme.rtl ? 'right' : 'left'};
`; 

export const innerSquare=styled.div`
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #76d6e8;
    width: 8px;
    height: 9px;
`; 

export const scheduledText=styled.div`
&.text {
    @media screen and (max-width: 750px){  
        padding-${props => props.theme.rtl ? 'right' : 'left'}: 7px;
    }
}
`;