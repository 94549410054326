import Animation from '../animation/Animation';
import * as css from './TickchakLoader.css';
import tickchakLoader from '../../assets/animations/tickchakLoader.json';
import progress from '../../assets/animations/progress.json';

function TickchakLoader({showClass,cssStyle}) {
    return (
        <css.wrapper className={showClass} cssStyle={cssStyle} data-cy='tickchakLoader'> 
            <Animation animation={tickchakLoader} />
            <css.progressWarp>
                <Animation animation={progress} />
            </css.progressWarp>
        </css.wrapper>
    );
}

export default TickchakLoader;
