import styled from 'styled-components/macro';
import {artist} from '../artist/Artist.css';
import {mobileMedia} from '../../../../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
    display: flex;
    column-gap: 20px;
    ${artist}{
      border:2px solid #76D6E8;
      ::before{
        bottom: 20px;
        color: #474747;
        font-size: 13px;
      }
      ${mobileMedia}{
        width: 45px;
        height: 45px;
      }
    } 
  ${mobileMedia}{
    column-gap: 5px;
  }
`;

