import styled from 'styled-components';
import {warpButtons} from '../../../../component/designLandingPopup/DesignLandingPopup.css';
import {popUpTop} from '../../../../component/newPopup/topPopup/TopPopup.css';
import {popUpBody} from '../../../../component/newPopup/mobilePopup/MobilePopup.css';
import {DARK_GRAY, LIGHT_BLUE, LIGHT_GRAY2} from '../../../../globalVariables/GlobalVariables';

export const wrapPopup = styled.div`
    ${popUpTop}{
        background-color: ${LIGHT_BLUE};
        border-radius: 5px 5px 0 0;
    }
    ${popUpBody}{
        max-width: 520px;
    }
`;

export const wrapAll = styled.div`
    textarea {
        width: 90%;
        height: auto;
        max-height:120px;
        position: relative;
        inset-block-start: 50px;
        inset-inline-start: 20px;
        border: 1px solid ${DARK_GRAY};
        border-radius: 5px;
        padding: 10px;
        resize: none;
    }
`;

export const buttons = styled(warpButtons)`
    inset-inline-end: unset;
    width: 100%;
    padding-top: 10px;
    border-top: 1px solid ${LIGHT_GRAY2};
`;