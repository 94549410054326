import  {useState} from 'react';
import * as css from './WrapChekBox.css';
import Checkbox from '../checkbox/Checkbox';

function WrapChekBox(props) {
    const [clicked, Clicked] = useState(0);
    const {item, handleSelected} = props;

    const CBClicked = () => {
        Clicked(!item.selected);
        handleSelected(!item.selected);
    };

    return (
        <>
            <css.WrapChekBox>
                <Checkbox onClick={handleSelected} selected={item?.selected} clicked={clicked} />
                <css.chekBoxText onClick={CBClicked}>{item?.text}</css.chekBoxText>
            </css.WrapChekBox>
        </>
    );
}
export default WrapChekBox;
