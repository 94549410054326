import * as css from './DescriptionStep.css';
import TextareaInput from '../../../../component/input/textareaInput/TextareaInput';
import Btn from '../../../../component/button/btn/Btn';
import {useTranslation} from 'react-i18next';
import {getDescriptionFromGPT} from '../../../../redux/event/eventActions';
import {useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import {getArtistsByText} from '../titleStep/TitleStep';
import useTimeout from '../../../../hooks/useTimeout';
import StepRight from '../stepRight/StepRight';
import StepContext from '../stepContext/StepContext';
import StepLeft from '../StepLeft/StepLeft';

function DescriptionStep() {

    const form = useFormikContext();
    const xhr=useSelector(s=>s.event.getDescriptionFromGPTXHR);
    const {t} = useTranslation('newEvent');
    const event = useSelector(s=>s.event);
    const description=form.values.description;
    const timeout=useTimeout();

    const onKeyDown=e=>{
        if(e.key==='Enter')
            e.stopPropagation();
    };
    const TextMe=(()=>{
        !form.values.description && getDescriptionFromGPT({eventName:event.title, eventType:event.type, eventLocation:event?.location,category:event?.category, artists:event?.artists})
            .then(res=>{
                const {setValue} = form.getFieldHelpers('description');
                setValue(res);})
            .catch(()=>{});
    });
      
    const onKeyUp=()=>{
        clearTimeout(timeout.current);
        timeout.current=setTimeout(() => {
            getArtistsByText(description,form);    
        }, 1000);
    };
    return (
        <css.wrapAll>
            <StepContext>
                <StepRight name={'description'} >
                    <css.wrapper>
                        { (xhr==='LOADING'  || !description)&& 
                    <Btn
                        onClick={TextMe}
                        disabled={xhr==='LOADING'}
                    >
                        <css.icon />
                        {xhr==='LOADING'?t('xhrLoading'): t('makeMeText')}
                    </Btn>  
                        }
                        <css.Textarea onKeyDown={onKeyDown}>
                            <TextareaInput name={'description'} text={description?'':t('textDescription')} events={{onKeyUp}}/>
                        </css.Textarea>
                    </css.wrapper>
                </StepRight>
                <StepLeft name={'description'}/>
            </StepContext>     
        </css.wrapAll>
    );
}

export default DescriptionStep;
