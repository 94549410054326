import BtnPlus from '../../../../component/button/btnPlus/BtnPlus';
import * as css from './AnotherOneLoader.css';

function AnotherOneLoader({title, dataCyName}) {

    return (
        <css.addGroup>
            <BtnPlus bsize={30} dataCyName={dataCyName}/>
            <css.title>{title}</css.title>
        </css.addGroup>
    );
}
export default AnotherOneLoader;