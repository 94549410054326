import {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../../../../component/input/Input';
import InputStartEndDate from '../inputStartEndDate/InputStartEndDate';
import * as css from './CourseDetails.css';
import {Form, useField, useFormikContext} from 'formik';
import NewStaffPop from '../newStaffPop/NewStaffPop';
import CourseDatePop from '../courseDatePop/CourseDatePop';
import PricesPop from '../pricesPop/PricesPop';
import GuideList from '../guideList.js/GuideList';
import MonthlyPrice from '../monthlyPrice/MonthlyPrice';
import DayAndTime from '../dayAndTime/DayAndTime';
import TextareaInput from '../../../../../component/input/textareaInput/TextareaInput';
import CourseImage from './CourseImage';
import {getFollowingMonth, getMonthDifference} from '../../../../../library/dates';
import {bounceMessageModal} from '../../../../../layout/userMessages/UserMessage';
// import InputPreOrder from '../inputPreOrder/InputPreOrder';
import NumberInput from '../../../../../component/input/NumberInput';
import LocationCourse from '../locationCourse/LocationCourse';
import GuidesListPop from '../guidesList/GuidesListPop';
import useShow from '../../../../../hooks/useShow';
import {useSelector} from 'react-redux';
import {useContext} from 'react';
import {CourseDetailsContext} from '../coursePopupContent/CoursePopupContent';
import {getFirstErrorFieldForScroll} from '../../../../../functions/getFirstErrorFieldName';
import setGlobalState from '../../../../../redux/setGlobalState';
import ModeBtn from '../../../../../component/modeBtn/ModeBtn';
// import store from '../../../../../store';

function CourseDetails() {

    const {t} = useTranslation('ticket');
    const [itemDate, setItemDate] = useState(null);
    const [itemPrice, setItemPrice] = useState(null);
    const [showNewStaff, setShowNewStaff] = useState(0);
    const [showCourseDate, setShowCourseDate] = useState(0);
    const [pricePop, setPricePop] = useState(0);
    const [course, metaCourse] = useField('course');
    const [payments, , helpers] = useField('payments');
    const [{value:hideGuide}, , hideGuideHelpers] = useField('hideGuide');
    const ticket = useSelector(s => s.tickets?.currentTicket);
    const show=useShow();
    const form = useFormikContext();

    const {errorFieldRef} = useContext(CourseDetailsContext);

    const firstTime = useRef(true);

    useEffect(() => {
        if(firstTime.current){
            firstTime.current=false;
            return;
        }
        if(metaCourse.touched && !firstTime.current && course.value.endDate && course.value.startDate){
            const paymentsNumber = getMonthDifference(course.value.startDate,course.value.endDate);
            const secondPayment = getFollowingMonth(course.value.startDate);
            helpers.setValue({...payments.value, numberPayments:paymentsNumber, secondPayment:secondPayment});
            if(ticket?.tid)
                bounceMessageModal(t('changingThePeriod'),'attention');
        }
    }, [course.value.startDate, course.value.endDate]);

    const skipHolidayOptions = [
        // { value: 0, label: t("withoutSkipping") },
        {key: 'Jewish', text: t('skip')},
    ];

    const getErrorFieldRef=(name,refName)=>{
        if(getFirstErrorFieldForScroll(form.initialValues,form.errors)===name)
            return {[refName]:errorFieldRef};
        else 
            return {};
    };

    useEffect(()=>{
        const {value}=course;
        if(value.startDate||value.endDate||value.meetings?.length)
            setGlobalState('ui.courses.hasDates',true);
        else setGlobalState('ui.courses.hasDates',false);
        setImmediate(form.validateForm);
    },[course.value]);

    return (
        <>
         
            <css.wrapForm>
                <Form>
                    <css.container data-cy="CourseDetails">
                        <css.wrapAll>
                            <css.firstPart>
                                <css.wrapInputs>
                                    <Input dataCy={'courseName'} name={'title'} text={t('titleField')} description={t('courseNameDesc')} icon={'event'} {...getErrorFieldRef('title','forwardRef')}/>
                                    <Input name={'description.summary'} text={t('moreInfo')} description={t('summaryDesc')} icon={'pen'}/>
                                    <TextareaInput name={'description.description'} text={t('extendedInformation')} description={t('descriptionDesc')} icon={'clusterDetails'} limit={700}/>
                                </css.wrapInputs>
                                <CourseImage/>
                            </css.firstPart>
                            <css.wrapDate>
                                <css.wrapCourse {...getErrorFieldRef('course', 'ref')}>
                                    <InputStartEndDate type='month' startName={'course.startDate'}
                                        endName={'course.endDate'} text={t('meetingsDates')}
                                        description={t('titleDesc')}
                                        selectedProps={{
                                            selectText: t('holidays'),
                                            listSelect: skipHolidayOptions,
                                            selectName: 'location.skipHolidays'
                                        }} descText={t('meetingsTime')}/>
                                    <DayAndTime setShowCourseDate={setShowCourseDate} setItemDate={setItemDate}/>
                                </css.wrapCourse>
                                <LocationCourse/>
                                <css.price {...getErrorFieldRef('prices', 'ref')}>
                                    <MonthlyPrice setItemPrice={setItemPrice} setPricePop={setPricePop}/>
                                    <css.payments>
                                        <NumberInput name={'amount'} text={t('amountField')}
                                            description={t('numberParticipantsDesc')} {...getErrorFieldRef('amount', 'forwardRef')}/>
                                        <NumberInput name={'limit'} text={t('limitField')} description={t('limitDesc')}
                                            icon="pen" {...getErrorFieldRef('limit', 'forwardRef')}/>
                                    </css.payments>
                                </css.price>
                                <css.guideTop>
                                    <css.text>{t('usersField')}</css.text>
                                    <ModeBtn text={t('hideDataText')} status={!!hideGuide} click={(status) => {
                                        hideGuideHelpers.setValue(status ? 1 : 0);
                                    }}/></css.guideTop>
                                <css.wrapPrice {...getErrorFieldRef('users', 'ref')}>
                                    <GuideList show={show}/>
                                </css.wrapPrice>

                                {/*<InputPreOrder/>*/}
                            </css.wrapDate>
                        </css.wrapAll>
                        <GuidesListPop show={show}/>
                        <NewStaffPop showPopOnPop={{show: showNewStaff, setShow: setShowNewStaff}}/>
                        <CourseDatePop showPopOnPop={{show: showCourseDate, setShow: setShowCourseDate}} item={itemDate}/>
                        <PricesPop showPopOnPop={{show: pricePop, setShow: setPricePop}} index={itemPrice} />
                    </css.container>
                </Form>
            </css.wrapForm>
         
        </>
    );
}

export default CourseDetails;