import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';

const DEFAULT_COLOR = '#76D6E8';

export const wrap = styled.div`
    position:relative ;
    width: max-content;
    height: 27px;
    border-radius: 22px;
    border: 1px solid #747474;
    background-color: transparent;
    padding: 3px;
    display: flex;
    align-items: center;
    background-color: #EEE;
    cursor: pointer;
    transition:all 200ms;
    margin:5px;
    :hover{
        border: 1px solid #76D6E8;
        .icon{
            display:none ;
            
        }
        .drag{
            display: block;
        }
    }
`;
export const icon = styled.div`
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${p => p.color || DEFAULT_COLOR};
    margin-inline-end:10px;
    :after{
            content:"${props => props.number && props.number}";
            ${dFlexCenter}
            color: #000;
            font-size: 14px;
            height:100% ;
            ${p => BGImageCenter(symbol[p.icon], '12px')}
        }
`;

export const drag = styled.div`
    margin-inline-start: 6px;
    background-image:url("${symbol['drag']}");
    width: 10px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: grab;
    display:none;
   

`;
export const options = styled.div`
    cursor: pointer;
    width: 20px;
    height: 10px;
    ${BGImageCenter(symbol['op'], '8px')}
    :hover{
        transform: scaleX(3.5);
    }
`;
export const wrapIcon = styled.div`
    width: 21px;
    height: 21px;
    margin-inline-end: 10px;
   
   
`;
export const list = styled.div`
    width: 180px;
`;