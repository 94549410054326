import * as css from './BankVerificationForm.css';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {Form, useFormikContext} from 'formik';
import FileUpload from '../../../../component/fileUpload/FileUpload';
import Btn from '../../../../component/button/btn/Btn';
import BankList from '../../../package/componnent/bankList/BankList';
import Input from '../../../../component/input/Input';
import setGlobalState from '../../../../redux/setGlobalState';
import {useSelector} from 'react-redux';
import fileExtension from '../../../../library/fileExtension';
import FormikDropzon from '../../../../component/dropzone/FormikDropzone';
import FormikFileUpload from '../../../../component/fileUpload/FormikFileUpload';

const deleteFile = () => {
    setGlobalState('ui.fileBase64.base64', '');
    setGlobalState('ui.fileBase64.title', '');
};

function BankVerificationFormDetails({setBankKey, onSubmitForm}) {
    const {t} = useTranslation('package');
    const file = useSelector(s => s.ui.fileBase64);
    const {bank,updatePackageVerificationXHR}=useSelector(s => s.account);
    const form=useFormikContext();

    return (
        <>
            <css.title >{t('bankVerification')}</css.title>
            <Form>
                <css.inForm>

                    <Input name={'billingName'} text={t('companyNameDesc')} />
                    <Input name={'billingId'} text={t('idBn')} />
                    <Input name={'bankTransferOnBehalf'} text={t('accountOwner')} />

                    <BankList name={'bankNumber'} t={t} valid={true} bankKey={(key)=>setBankKey(key)}/>

                    <Input name={'bankBranch'} text={t('branch')}  />
                    <Input name={'bankAccount'} text={t('bankAccount')}  />
                    <Btn disabled={!form.isValid} onClick={onSubmitForm} className={updatePackageVerificationXHR}>{t('finishVerification')}</Btn>

                    <css.upload>
                        <FormikFileUpload name={'bankVerificationFile'}   text={file.name && file.name !==''?file.name:t('uploadTitle')} desc={t('uploadDesc')} />
                    </css.upload>

                </css.inForm>

            </Form>
        </>
    );
}

export default BankVerificationFormDetails;