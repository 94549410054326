import styled from 'styled-components/macro';

export const wrapper = styled.div`
width: 16px;
height: 14px;
border-radius: 3px;
gap: 3px;
overflow: hidden;
    border-radius: 3px;
    display: flex;
`;

export const piece = styled.div`
background-color: #76D6E8;
width: ${p=>p.percent}%;
height: 100%;

`;
export const others = styled.div`
background-color: black;
width: ${p=>(100-p.percent)}%;
height: 100%;

`;