import React, {Component} from 'react';
import './EditableDiv.css';

const RtlCss = React.lazy(() => import('./EditableDiv-rtl'));

class EditableDiv extends Component{
    constructor(props){
        super(props);

        this.state = {
            editable:false,
            text:''
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentDidMount() {
        this.setState({text:this.props.text});
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event, save) {
        if(save || (this.wrapperRef && !this.wrapperRef.contains(event.target))) {
            const value = save ? event.target.value : this.wrapperRef.value;
            this.setState({editable:false});
            this.props.handle('edit', {id:this.props.id, name:this.props.name, value:value});
        }
    }

    handleClick(){
        this.setState({
            editable:true,
            text: this.props.text
        });
    }
    handleKeyUp(event){
        if(event.keyCode === 13){
            event.preventDefault();
            this.handleClickOutside(event, true);
        }
    }

    handleEdit(event){
        const target = event.target;
        this.setState({text:target.value});
    }

    render() {
        if(this.state.editable === false){
            return (
                <>
                    {(this.props.lang === 'he') && <RtlCss />}
                    <div className="editable-div hover-edit" onClick={this.handleClick}>{this.props.text}</div>
                </>
            );
        } else {
            return (
                <>
                    {(this.props.lang === 'he') && <RtlCss />}
                    <div className="editable-div-edit">
                        <textarea
                            className="editable-textarea"
                            value={this.state.text}
                            onKeyDown={this.handleKeyUp}
                            onChange={this.handleEdit}
                            ref={this.setWrapperRef}
                        />
                    </div>
                </>

            );
        }
    }
}

export default EditableDiv;