import {useTranslation} from 'react-i18next';
import Btn from '../../button/btn/Btn';
import * as css from './InnerPop.css';
import WrapUseMouseUpOutside from '../../wrapUseMouseUpOutside/WrapUseMouseUpOutside';

function InnerPop({children,show,title,buttons={},dataCy}) {

    const {t} = useTranslation('global');
    const {blueBtn,whiteBtn}= buttons;

    if(!show.isOpen)
        return <></>;

    return (
        <WrapUseMouseUpOutside  callback={show.close}>
            {(ref)=>
                <css.container data-cy={`${dataCy}PopOnPop`} className={show.showClass} ref={ref}>
                    <css.close onClick={show.close} />
                    <css.title >{title}</css.title>
                    <css.content>
                        {children}
                    </css.content>
                    <css.bottom>
                        {whiteBtn && <Btn onClick={whiteBtn.onClick||show.close} className='white' disabled={whiteBtn.disabled}>{t(whiteBtn.text)}</Btn> }
                        {blueBtn && <Btn onClick={blueBtn.onClick||show.close} disabled={blueBtn.disabled}>{t(blueBtn.text)}</Btn> }
                    </css.bottom>
                </css.container>
            }</WrapUseMouseUpOutside>
    );
}

export default InnerPop;
