import createReducer from '../createReducer';

const initialState = {
    tickets: [],
    searchTickets: [],
    isSearchState:false,
    currentTicket: null,
    groups: [],
    ticketsIcon:null,
    isAcceptedAll:false,
    
    addTicketXHR:null,
    updateTicketXHR:null,
    getEventTicketsXHR:null,
    getEventGroupsXHR:null,
    updateGroupXHR:null,
    addGroupXHR:null,
    deleteGroupXHR:null,
    duplicateTicketXHR:null,
    sendTicketXHR:null,
};

const reducer = createReducer('tickets', initialState);

export default reducer;
