import {useTranslation} from 'react-i18next';
import * as css from './LocationPop.css';
import InnerPop from '../../../../../component/newPopup/innerPop/InnerPop';
import GoogleMaps from '../../../../../component/googleMaps/GoogleMaps';

function LocationPop({show,onSubmitForm}) {
    const {t} = useTranslation('ticket');

    return (
        <InnerPop dataCy='location' show={show} title={t('location')} buttons={{whiteBtn:{text:t('save'),onClick:onSubmitForm}}}>
            <css.top />
            <css.content>
                <css.wrapMap><GoogleMaps locationMapName={'locationMapTemp'}  place={{placeName:'locationTemp',placeText:t('placeName'),description:t('placeNameDesc')}} 
                    inputStyle={'padding: 10px 10px 30px;'}/></css.wrapMap>          
            </css.content>
        </InnerPop>
    );
}

export default LocationPop;
