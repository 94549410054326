import * as css from './Table.css';
import Animation from '../animation/Animation';
import tableLoader from '../../assets/animations/tableLoader.json';
import Search from '../search/Search';
import Header from './header/Header';
import RightHeader from '../rightHeader/RightHeader';
import {useContext} from 'react';
import {TableContextValue} from '../../pages/components/tableContext/TableContext';

function WarpTable({
    list, 
    checked, 
    children, 
    title = '', 
    scrollProps = {}, 
    searchProps ,
    header=false,
    buttonsChildren
}) {
    const {checkedList, setCheckedList,isSelectable, setIsSelectable} = checked||{};    
    const {toShowLoader = false, XHR, onScrollEnd = () => { }} = scrollProps;

    let tableRef;
    if(TableContextValue) {
        tableRef = useContext(TableContextValue)?.tableRef;
    }
    
    const onScroll = (e) => {
        if(Math.round(e.target.scrollHeight - e.target.scrollTop) === Math.round(e.target.clientHeight) &&
            XHR !== 'LOADING') {
            onScrollEnd();
        }
    };
    const getCheckedProps = (key) => ({
        isSelectable,
        isChecked: checkedList?.some(i => i == key),
        onChecked: (val) => val ? setCheckedList(list => [...list, key]) :
            setCheckedList(list => list.filter(i => i !== key))
    });

    const clearChecked = () => {
        setCheckedList([]);
    };

    const onCheckedAll = () => {
        if(!isSelectable)
            return;
        if(checkedList.length === list.length)
            clearChecked();
        else 
            setCheckedList(()=>list.map(i=>i.key));
    };

    return (
        <>        
            {title&&<RightHeader icons={<>
                {buttonsChildren}
                {checked&&
                    <><div onClick={() => { setIsSelectable(!isSelectable); clearChecked(); }}>checked</div>
                        <div onClick={onCheckedAll}>checkedAll</div>
                    </>}
                {searchProps && <Search {...searchProps} />}
            </>}>
                {title}
            </RightHeader>}
            <css.tableContainer onScroll={onScroll} className="tableContainer" {...(tableRef?{ref:tableRef}:{})}>
                {header&&<Header Comp={header}/>}
                {children(getCheckedProps)}
                {toShowLoader && <css.loader className={toShowLoader && ' show '}>
                    <Animation animation={tableLoader} />
                </css.loader>}
            </css.tableContainer>
        </>

    );

}
export default WarpTable;

