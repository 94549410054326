import createReducer from '../createReducer';

const initialState = {
    languages:[],
    allLanguages:[],
    
    getLanguagesXHR:null,
    getAllLanguagesXHR:null,

};

const reducer = createReducer('languages',initialState);

export default reducer;