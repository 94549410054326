import {useEffect} from 'react';
import setGlobalState from '../redux/setGlobalState';

function useWindowWidth() {

    useEffect(() => {
        function handleResize() {
            setGlobalState('ui.windowWidth', Number(window.innerWidth));
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

}

export default useWindowWidth;
