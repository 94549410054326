import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ts2date, ts2time} from '../../../../library/dateFunctions_old_to_be_deprecated';
import ExplainText from '../../../../component/explainText/ExplainText';
import PopUpMobile from '../../../../component/popUpMobile/PopUpMobile';
import {TICK_DOMAIN} from '../../../../config/config';
import Copy from '../../../../component/copy/Copy';
import {formatDecimal} from '../../../../library/numberFunction';
import * as css from './ClusterPopupMobile.css';
import State from '../../../../component/state/State';
import useShow from '../../../../hooks/useShow';
import List from '../../../../component/list/List';
import {handleState} from '../clusterEventRow/ClusterEventRow';

function ClusterPopupMobile(props) {
    const {t} = useTranslation('cluster');
    const tLayout = useTranslation('layout');
    const {eid, cgid, handleClickOpTable, groupList} = props;////////////cluster?????
    const {open, isOpen, showClass,close} = useShow(500);
    const cluster=useSelector((state) => state.cluster);
    const groups=cluster.groups;
    const eventCurrent=groups.filter(item=>item.cgid===cgid)[0].events.filter(item=>item.eid===eid)[0];

    const onClickOperation = (item) => {
        handleClickOpTable(item, eventCurrent, cgid);
        props.close();
    };
    return (

        <PopUpMobile gradient={1} background={'url(' + eventCurrent?.fbImg + ')'} close={props.close}>
            <css.popEventTop>
                <css.popEventTopState>
                    <State activeText={t('active')} inactiveText={t('inactive')} active={eventCurrent.active} onClick={(active)=>handleState(active,eventCurrent,tLayout)} />
                </css.popEventTopState>
            </css.popEventTop>
            <css.popEventContain>
                <css.titleEvent>{eventCurrent?.title}</css.titleEvent>
                <css.popEventDetails>
                    <css.pEDsDate>
                        <ExplainText className={'white'} text={ts2date(eventCurrent?.timeStart)}
                            explain={t('date')} />
                        <ExplainText className={'white'} text={ts2time(eventCurrent?.timeStart)}
                            explain={t('time')} />
                    </css.pEDsDate>
                    {cluster.childEventStats ? <>
                        <css.pEDsSale>
                            <css.pEDsSaleCash><ExplainText className={'white'} text={formatDecimal(cluster.childEventStats?.other.totalPrice + cluster.childEventStats?.today.totalPrice) + eventCurrent.currencySymbol} explain={t('sale')} /></css.pEDsSaleCash>
                            <css.pEDsSaleTickets><ExplainText className={'white'} text={cluster.childEventStats.other.ticketsSold + cluster.childEventStats.today.ticketsSold} explain={t('tickets')} /></css.pEDsSaleTickets>
                            <css.pEDsSaleCrm><ExplainText className={'white'} text={cluster.childEventStats.other.amountOrders + cluster.childEventStats.today.amountOrders} explain={t('crm')} /></css.pEDsSaleCrm>
                        </css.pEDsSale>
                        <css.pEDDetails>
                            <css.pEDDetailsLocation>{eventCurrent.location}</css.pEDDetailsLocation>
                        </css.pEDDetails>
                    </> :
                        <div>{t('loading')}</div>}
                </css.popEventDetails>
                <css.popEventBtn>
                    <css.pEBLink><Copy link={TICK_DOMAIN + '/' + eventCurrent.name}
                        className={'clusterLink'} />{t('link')}</css.pEBLink>
                    <css.pEBGroup onClick={cgid ? ()=>(onClickOperation({option: 'deleteFromGroup'})) : open}>
                        <css.pEBGroupImage />
                        {cgid ? t('groupDelete') : t('groupAdd')}
                        {!!isOpen && <List showClass={showClass} close={close} list={groupList} bottom={'0'} left={0} onClick={(item) => onClickOperation({option: 'addEventGroup', cgid: item.cgid})} />}
                    </css.pEBGroup>
                    <css.pEBRemove onClick={() => onClickOperation({option: 'delete'})}>{t('remove')}</css.pEBRemove>
                    <css.pEBDup onClick={() => onClickOperation({option: 'duplicate'})}>{t('duplicateEvent')}</css.pEBDup>
                </css.popEventBtn>

            </css.popEventContain>
        </PopUpMobile>
    );
}

export default ClusterPopupMobile;
