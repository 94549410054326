import * as css from '../leadsTable/leadRow/LeadRow.css';
import {useTranslation} from 'react-i18next';
import {blackDuplicate, pen, remove, crm} from '../../../assets/symbols/symbols/Symbols.css';
import useShow from '../../../hooks/useShow';
import {addLead, deleteLead, updateLead} from '../../../redux/publish/leadActions';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import {useHistory} from 'react-router-dom';
import {useContext} from 'react';
import {TableContextValue} from '../../components/tableContext/TableContext';
import List from '../../../component/list/List';
import {useRef} from 'react';

function LeadOptions({lead,openPopup}) {
    const {t} = useTranslation('publish');
    const {open, close, isOpen, showClass} = useShow();
    const {ref,title,contactPhone,contactEmail,contactName} = lead;
    const history = useHistory();
    const refList = useRef(null);

    const {scrollToBottom}= useContext(TableContextValue);

    const onDeleteLead= async() =>{
        if(lead.transactions)
            bounceMessage(t('deleteLeadWarning'),'warning', 
                {text: t('thanks'), onClick:()=>{}},lead.active&&{text: t('delay'), onClick:()=> updateLead(ref, {active:0})});
        else 
            await deleteLead(ref); 
    };

    const onDuplicate=()=>{
        addLead({title,contactPhone,contactEmail,contactName}).then(()=>{
            scrollToBottom();
        });
    };
    
    const leadTransactions = async() =>{
        history.push(`crm?lead=${lead.ref}`);
    };

    const optionsList = [
        {text: t('duplication'), onClick: onDuplicate ,icon: blackDuplicate, dataCyName:'duplicate'},
        {text: t('delete'), onClick: onDeleteLead, icon: remove ,dataCyName:'remove'},
        {text: t('edit'), onClick: ()=>openPopup(lead), icon: pen ,dataCyName:'edit'},   
        {text: t('leadTransactions'), onClick: ()=>leadTransactions(), icon: crm ,dataCyName:'leadTransactions'}     
    ]
    ;

    return (

        <css.options onClick={e=>{e.stopPropagation();isOpen ? close() : open();}} data-cy="leadOptions" ref={refList}>
            {isOpen && <List left={refList.current.getBoundingClientRect().x +'px'} 
                list={optionsList} top={40} 
                showClass={showClass} close={close}  
                title={t('additionalActions')} 
                fixedTop={refList.current.getBoundingClientRect().y}
            /> }
        </css.options>
    );
}
export default LeadOptions;
