import useEvent from './useEvent';
import setGlobalState, {resetGlobalState} from '../redux/setGlobalState';
import store from '../store';
import {initialUser,initialUserXHR} from '../redux/user/userReducer';

export default function useEvent401Exception() {
    useEvent('401Exception', () => {
        setGlobalState('user', {
            ...initialUser,
            ...initialUserXHR
        },);
        setGlobalState('auth', {
            isLoggedIn: false,
            exp: 0,
            message: '',
            token: null
        },);
        setGlobalState('user.updated', false);
        setGlobalState('account.updated', false);
        Object.entries(store.getState()).forEach((item) => {
            if(!['auth', 'user'].includes(item[0]))
                resetGlobalState(item[0]);
        });
        localStorage.clear();

        // TODO: alert(); logout;
    });
}