function validation(type, value) {
    switch (type) {
        case 'email':
            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
                return true;
            break;
        case 'password':
            if(value.length > 5)
                return true;
            break;
        case 'phone':
            let patt = /^05\d([-]{0,1})\d{7}$/;
            if(value.match(patt))
                return true;
            break;
        case 'text':
            if((/<|>/.test(value)))
                return true;
            break;
        case 'bankBranch':
            if(isNaN(value) || value < 1 || value > 999)
                return true;
            break;
        case 'bankAccountNumber':
            if(isNaN(value) || value < 1 || value.length > 9)
                return true;
            break;
        case 'bankNum':
            if(isNaN(value))
                return true;
            break;
        default:
            break;
    }
    return false;

}
export default validation;