import React, {useEffect, useState} from 'react';
import * as css from './PopUpMobile.css';

function PopUpMobile(props) {
    const [show, setShow] = useState(0);
    const [showPop, setShowPop] = useState(0);
    const close = () => {
        setShowPop(0);
        setTimeout(function () {
            setShow(0);
            setTimeout(function () {
                if(props.close)
                    props.close();
            }, 200);
        }, 300);
    };
    useEffect(() => {
        setTimeout(function () {
            setShow(1);
            setShowPop(1);
        }, 0);
    }, []);
    const popupContent = <> <css.popUpTop />
        <css.popUpClose onClick={close} color={props.color} />
        {props.children}

    </>;
    return (
        <css.popUpScreen className={show && 'show'} onClick={close}>
            <css.popUp className={(showPop ? 'show' : 'hide') + (props.isKeyboard?' keyboard':'')} background={props.background && props.background} color={props.color} onClick={(e) => e.stopPropagation()}>
                {props.gradient ?
                    <css.popUpBack>
                        {popupContent}
                    </css.popUpBack>
                    :
                    popupContent
                }
            </css.popUp>
        </css.popUpScreen>
    );
}
export default PopUpMobile;