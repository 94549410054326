import {useRef} from 'react';
import * as css from './EventDetails.css';
import {useTranslation} from 'react-i18next';
import DetailInput from '../../../../../component/detailInput/DetailInput';
import InputDate from '../../../../../component/input/inputDate/InputDate';
import Textarea from '../../../../../component/textArea/Textarea';
import {ts2time} from '../../../../../library/dateFunctions_old_to_be_deprecated';
import CategoryListNotFormik from '../../../../components/categoryList/CategoryListNotFormik';
import useMobile from '../../../../../hooks/useMobile';

function EventDetails(props) {
    const {inputs,eid, setInputsVal, setTextareaInput, inputsDate, setInputsDate, textaeraInput, valCategory, clickList, setFocus, handleInputValid, handleInputDateValid, closeCategory} = props;
    const {t} = useTranslation('layout');
    const refSroll = useRef();
    const isMobile = useMobile();

    const handleOnChangeInput = (e) => {
        const {name, value} = e.target;
        setInputsVal(inputs.map((item) => {
            if(item.name == name)
                return {...item, value: value};
            return item;
        }));
    };

    const handleOnChangeInputDate = (name, value) => {
        let newValue = new Date(value);
        let start = name == 'timeStart' ? newValue : (inputsDate[0].value ? new Date(inputsDate[0].value) : null);
        let open = name == 'timeOpen' ? value : (inputsDate[1].value ? inputsDate[1].value : null);
        let end = name == 'timeEnd' ? newValue : (inputsDate[2].value ? new Date(inputsDate[2].value) : null);
        let now = new Date();
        setInputsDate(inputsDate.map((item) => {
            let valid = 0;
            let validation;
            switch (item.name) {
                case 'timeStart':
                    valid = (start && start < now) ? 1 : 0;
                    break;
                case 'timeOpen':
                    valid = (open && open > ts2time(start?.getTime() / 1000)) ? 1 : 0;
                    break;
                case 'timeEnd':
                    if(end && end < now) {
                        validation = {type: 'startDate', text: t('errorEventStart')};
                        valid = 1;
                    }
                    else {
                        valid = (start && end && end <= start) ? 1 : 0;
                        validation = {type: 'endDate', text: t('errorEventEnd')};
                    }
                    break;
            }
            return {...item, notValid: valid, value: item.name == name ? value : item.value, validation: validation ? validation : item.validation};
        }));
    };

    const doOnfocus = (item, index) => {
        if(isMobile) {
            refSroll.current.scrollTo({top: 75 * index, left: 0, behavior: 'smooth'});
            setFocus(1);
        }
        if(item.validation) {
            handleInputValid(item, 0, (item.name == 'title') && {type: 'text', text: t('syntaxError')});
            if(item.name == 'description')
                setTextareaInput(prevState => ({...prevState, notValid: 0, validation: {type: 'text', text: t('syntaxError')}}));
        }
    };

    const handleOnChangeTextarea = (e) => {
        setTextareaInput((prev) => ({...prev, value: e.target.value}));
    };

    return (
        <>
            <css.wrapAll ref={refSroll}>
                <css.wrapInput>
                    {inputs?.map((item, index) => {
                        return <DetailInput index={index} key={index} item={item} onChange={handleOnChangeInput} setFocus={setFocus} doOnfocus={doOnfocus} handleInputValid={handleInputValid} />;
                    })}
                    {inputsDate?.map((item, index) => {
                        return <InputDate index={(inputs?.length) + index} key={index} item={item} setFocus={setFocus} onChange={handleOnChangeInputDate} doOnfocus={doOnfocus} handleInputDateValid={handleInputDateValid} />;
                    })}
                </css.wrapInput>
                <css.wrapInputTwo>
                    <CategoryListNotFormik setFocus={setFocus} onChange={clickList}  value={valCategory} eid={eid}/>
                    <Textarea index={(inputs?.length) + (inputsDate?.length) + 1} setFocus={setFocus} doOnfocus={doOnfocus} onChange={handleOnChangeTextarea} setTextareaInput={setTextareaInput} textaeraInput={textaeraInput} />
                </css.wrapInputTwo>
            </css.wrapAll>
        </>
    );
}

export default EventDetails;
