import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import GrayWrapper from '../../../component/grayWrapper/GrayWrapper';
import CopyLink from '../../../component/copyLink/CopyLink';
import Input from '../../../component/input/Input';
import * as css from './FacebookPixelPopContent.css';

function DomainVerificationSection() {
    const {t} = useTranslation('publish');
    const form = useFormikContext();

    const metaText = '<meta name="facebook-domain-verification" content="';

    return (
        <>
            <GrayWrapper name={'domainVerification'} title={t('implementationDomain',{domain:form.values.domain})} dataCyName={'facebookPixelDomain'}>
                <css.children>
                    {metaText}<Input name='domainVerification' />{'"/>'}
                </css.children>   
            </GrayWrapper>
            <css.wrapAll>
                <a href="https://www.facebook.com/business/help/286768115176155?id=199156230960298" target="_blank" rel="noreferrer">
                    {t('domainInformation')}
                </a>
                <CopyLink title={t('copy')} text={`${metaText}${form.values.domainVerification}"/>`}/>
            </css.wrapAll> 
        </>
    );
}

export default DomainVerificationSection;
