import styled from 'styled-components/macro';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../globalVariables/zIndex';

export const link = styled.div`
    input{
        width: 5px;
        height: 0;
        border: 0;
        position: absolute;
    }
        z-index:${zIndex.baseRaised};
`;
export const linkIcon = styled.div`
    background-image:url("${symbol['copy']}");
    background-repeat: no-repeat;
    background-position: center center;
    height:30px;
    width:20px;
    cursor:pointer;    
    font-size:0;
    transition:all 200ms;
    

    &.copied{
        font-size: 12px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        padding: 0 20px;
        border-radius: 30px;
        line-height: 30px;
        background: #eaeaea;
        font-weight: 600;
        z-index: ${zIndex.baseRaised};
        color: #474747;
    }

    &.groups{
        line-height: 20px;
        height: 20px;
        margin: 5px;
    }
`;