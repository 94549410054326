import React from 'react';
import * as css from './Stages.css';

function Stages(props) {

    const {stages} = props;

    return (
        <>
            <css.stage className={'stage'}>
                {stages?.map((item) => {
                    return <>
                        <css.wrapStae borderColor={item.borderColor} onClick={() => item.onClickStage(item.type)}>
                            <css.oCircle circleColor={item.oCircleColor} borderColor={item.borderColor} className={('oCircle ')+(!!item.currentStage && 'currentStage')} >
                                <css.iCircle circleColor={item.iCircleColor} className={'iCircle'} />
                            </css.oCircle>
                            <css.stageText stageTextColor={item.stageTextColor} className={(!!item.currentStage && 'currentStage')}>{item.stageText}</css.stageText>
                        </css.wrapStae>
                    </>;
                })}
            </css.stage>
        </>
    );
}
export default Stages;
