import {useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import LoginButton from '../loginButton/LoginButton';

function EnterButton({text, actionName,buttonType}) {
    const {submitForm} = useFormikContext();
    const XHR = useSelector((state) => state.user?.[`${actionName}XHR`]);

    const onClick = () => {
        submitForm();
    };
    return (
        <LoginButton onClick={onClick} className={XHR} text={text}  buttonType ={buttonType}/>
    );
}

export default EnterButton;
