import styled from 'styled-components/macro';
import {BGImageCenter, DEFAULT_COLOR, ellipsisLines, mobileScreen ,mobileMedia} from '../../../../../../globalVariables/GlobalVariables';
import * as symbols from '../../../../../../assets/symbols/symbols/Symbols.css';
import {pen,op} from '../../../../../../assets/symbols/symbols/Symbols.css';

export const row = styled.div`
    border-bottom:1px solid #bebaba;
    background-color: #d7d7d763;
    width:100% ;
    display: flex;
    height:60px ;
    cursor: pointer;
    :hover{
        .circleWrap{
            transition:background-color 400ms ;
            background-color: #aaa;
            /* background-color: ${DEFAULT_COLOR}; */
            ${BGImageCenter(pen,'16px'  )}
            >div{
            display:none;
            }
        }
    }
    `;
export const part = styled.div`
    display:flex ;
    justify-content:space-between ;
    align-items:center ;
    padding:2% ;
    ${mobileMedia} {
      padding: 0;
    }
`;
export const boldText = styled.div`
    font-weight:600 ;
    font-size:14px ;
    ${mobileMedia} {
      ${ellipsisLines(3)};
      font-size:12px ;
    }
`;
export const text = styled.div`
    display:flex ;
    font-size:14px ;
    line-height:15px ;
    ${ellipsisLines(2)}
    ${mobileMedia} {
      font-size: 12px;
      ${ellipsisLines(3)}
    }
`;

export const wrapMBtn = styled(part)`
    width:15%;
    @media screen and (max-width:${mobileScreen}px) {
        width:65px;
    }
    .modeBtnText{
        margin-inline-end:2px;
        font-size:9px ;
        width:15px;
        color:black;
    }
`;

export const wrapList = styled(part)`
    position:relative;
    width:6%;
    ${mobileMedia} {
      width:20px;
    }
    .toggle{
        width:35px ;
        height:16px;
        border:none; 
        :after{
            inset-inline-start:4px;
        }  
        &.active{
            background-color: ${DEFAULT_COLOR};
            :after{
                background-color:#fff ;
                inset-inline-start:calc(100% - 13px);
                }  
        }
    }
`;
export const options = styled.div`
    inset-inline-end:3%;
    width:20px;
    height:20px;
        ${BGImageCenter(op, '10px')}
`;

export const field =  styled(part)`
    width: 23%;
    @media screen and (max-width:${mobileScreen}px) {
        width: calc(33% - 36px);
        padding-inline-start: 5px;
        font-size: 12px;
    }
`;

export const wrapBudgetDefault =  styled(part)`
  width: 10%;
  ${mobileMedia} {
    display: flex;
    justify-content: center;
    width:25px;
  }
`;

export const budgetDefault = styled.div`
     width: 18px;
        height:18px;
        border-radius: 50%;
        bottom: 25px;
        inset-inline-end: 0;
        ${BGImageCenter(symbols.blackSuccess, '10px')}
        background-color:#76D6E8 ;
        transition:all 200ms cubic-bezier(0.42, 0, 0.95, 1.52);
`;