import * as css from './Scheduled.css';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

function Scheduled(props) {
    const {children}=props;
    const {t} = useTranslation('mailing');
    const [scheduled, setScheduled] = useState(0);

    const handleScheduled = () => {
        setScheduled(scheduled == 0 ? 1 : 0);
    }; 

    return (
        <css.bottomScheduled className="bottomScheduled">
            <css.scheduledIcon className="scheduledIcon" onClick={() => { handleScheduled(); }}>
                <css.outerSquare className="outerSquare">
                    {scheduled == 1 && <css.innerSquare className="innerSquare" />}
                </css.outerSquare>
            </css.scheduledIcon>
            <css.scheduledText className="scheduledText text">{t('scheduledMessage')}</css.scheduledText>
        </css.bottomScheduled>
    );
}

export default Scheduled;