import * as css from './transaction.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import {formatRealtimeNumber, formatRealtimeDecimal} from '../../../functions';
import TransactionsTemplate from './transactionsTemplate';

const Transactions = () => {
    const {t} = useTranslation('realtime');
    const {all: stats, mostFrequentTicketsAmount} = useSelector((state: any)=>state.realtime.event.eventStats)||{};
    const currencySymbol = useSelector((state: any)=>state.event.currencySymbol);
    const isLoading = useSelector((s:any)=>s.realtime?.event?.getEventStatsXHR === XHR_LOADING_STATE);
    
    if(isLoading) {
        return <TransactionsTemplate />;
    }
    if(!stats)
        return <></>;
    return (
        <css.container>
            <div>
                <css.title>{t('transactionsTitle')}</css.title>
                <css.amount>{formatRealtimeNumber(stats.amountOrders, true)}</css.amount>
            </div>
            <css.stats>
                {mostFrequentTicketsAmount && <css.statsItem>
                    <css.averageNumber className='free-color'>{t('know')}</css.averageNumber>
                    <span>{t('mostFrequentTicketsAmount', {amount: mostFrequentTicketsAmount})}</span>
                </css.statsItem>}
                <css.statsItem>
                    <css.averageNumber className='free-color'>{formatRealtimeDecimal(((stats.ticketsSold || 0) / (stats.amountOrders || 1)).toFixed(1)||0, true)}</css.averageNumber>
                    <span>{t('averageTickets')}</span>
                </css.statsItem>
                <css.statsItem>
                    <css.averageNumber className='free-color'>{formatRealtimeNumber((stats.totalPrice || 0) / (stats.amountOrders || 1)||0, true)} {currencySymbol} </css.averageNumber>
                    <span>{t('averagePrice')}</span>
                </css.statsItem>
            </css.stats>
        </css.container>
    );
};

export default Transactions;