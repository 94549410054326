import styled from 'styled-components/macro';
import {dFlexCenter} from '../../../../../globalVariables/GlobalVariables';
import * as ticket from '../TicketRow.css';

export const wrap = styled.div`
    ${dFlexCenter};
    width: 100%;
    justify-content: right;
    padding-inline:10px;
`;

export const boldText = ticket.boldText;
export const text = ticket.text;
