import React from 'react';
import {useContext} from 'react';
import {TabsPopupContext} from '../../newPopup/tabsPopup/TabsPopup';
import * as css from './StageItem.css';

function StageItem({item, onClickStage, color = '',index}) {

    const {currentStageIndex,setCurrentStageIndex,tabsList}= useContext(TabsPopupContext);

    const onClick=()=>{
        if(item.disabled){
            item.disabledClick(item);
        }
        else {
            setCurrentStageIndex(index);
            if(onClickStage)
                onClickStage(tabsList[index]?.type);
        }
    };

    return (
        <>
            <css.wrapStae borderColor={color || item.borderColor} onClick={onClick} className={item.disabled?'disabled':''} data-cy={`${item.type}Stage`}>
                <css.oCircle circleColor={item.oCircleColor} borderColor={color || item.borderColor} className={('oCircle ') + (!!(index === currentStageIndex) && 'currentStage')} >
                    <css.iCircle circleColor={color || item.iCircleColor} className={'iCircle'} />
                </css.oCircle>
                <css.stageText data-cy={item.type} stageTextColor={item.stageTextColor} className={`${index === currentStageIndex?'currentStage':''} ${item.disabled?'disabled':''}`}>
                    {item.stageText}
                </css.stageText>
            </css.wrapStae>
        </>
    );
}
export default StageItem;
