import {useRef, useEffect} from 'react';

export default function useTimeout() {

    const timer = useRef();
  
    useEffect(() => () => clearTimeout(timer.current),[]);

    return timer;
}
