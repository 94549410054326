import {useTranslation} from 'react-i18next';
import List from '../../../../../component/list/List';
import useShow from '../../../../../hooks/useShow';
import SystemMessagePop from '../systemMessagePop/systemMessagePop';
import * as css from './systemMessageMenu.css';
import {useEffect, useState} from 'react';
import {getLastsRealtimeMessages, getRealtimeMessageById} from '../../../../../redux/realtime/realtimeActions';
import {useSelector} from 'react-redux';
import {pen} from '../../../../../assets/symbols/symbols/Symbols.css';
import {IS_PRODUCTION} from '../../../../../config/config';

function SystemMessageMenu() {
    const realtimeMessageShow = useShow();
    const {t} = useTranslation('realtime');
    const menuShow = useShow();
    const [showList, setShowList] = useState(false);
    const uid = useSelector((s:any) => s.user?.uid);
    const isSystemMessageMasterUser=()=>{
        return !IS_PRODUCTION || [7, 1555,8176,2604,15579,324].includes(uid);
    };

    const messagesList = useSelector((s: any)=>s.realtime.lastsRealtimeMessages)?.map(m=>({
        text: m.smid,
        title: m.officeTitle,
        icon: pen,
        onClick: ()=>{realtimeMessageShow.open(); getRealtimeMessageById(m.smid);}
    }));
    const menuList = [
        {
            title: t('createNewMessage'),
            text: 'create',
            onClick: realtimeMessageShow.open
        },
        {
            title: t('history'),
            text: 'history',
            list:[],

        }
    ].concat(showList?messagesList ||[]:[]);

    useEffect(()=>{
        if(isSystemMessageMasterUser()){
            getLastsRealtimeMessages();
        }

    },[]);

    const onClose = ()=>{
        setShowList(false);
        menuShow.close();
    };
    if(!isSystemMessageMasterUser())
        return <></>;
    return (
        <>
            {realtimeMessageShow.isOpen && <SystemMessagePop {...realtimeMessageShow} />}
            <css.openMessagePopup onClick={()=>menuShow.open()} data-cy="changedDisplay" />
            {menuShow.isOpen && <List arrowIntoClick={()=>setShowList(oldShow=>!oldShow)} title={t('systemMessage')} {...menuShow} close={onClose} list={menuList} />}
        </>
    );
}

export default SystemMessageMenu;