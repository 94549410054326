import {useState} from 'react';

export default function useClose(setToFalse,duration=300,callback){

    const [isClose,setIsClose]=useState(false);

    function close() {
        setIsClose(true);
        setTimeout(() => {
            setIsClose(false);
            setToFalse(false);
            if(callback)
                callback();
        }, duration);    
    }

    return ({isClose:isClose,setClose:close});
}
// const close = () => {
//     setShowPop(0)
//     setTimeout(function () {
//         setShow(0)
//         setTimeout(function () {
//             if(props.close)
//                 props.close()
//         }, 200)
//     }, 300)
// }