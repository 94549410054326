import React, {useEffect, useRef, useState} from 'react';
import * as css from './FormButtton.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {REACT_APP_ENV} from '../../../config/config';
import SharePopUp from './sharePopUp/SharePopUp';
import Tooltip from '../../../component/tooltip/Tooltip'; 
import ConditionalWrapper from '../../../component/conditionalWrapper/ConditionalWrapper';
import Link from '../../../component/link/Link';
import hasSpecificTranslate from '../../../library/hasSpecificTranslate';

function FormButton(props) {
    const {name,type,domain,landingPageUrl}=useSelector(s=>s.event);
    const {t} = useTranslation('layout');
    const [copied, setCopied] = useState(0);
    const [showPopup, setShowPopup] = useState(0);
    const opRef = useRef();

    let myInput = null;
    const clickMoreOpCopy = (e) => {
        e.stopPropagation();
        myInput.select();
        document.execCommand('copy');
        setCopied(1);
    };
    useEffect(() => {
        if(copied)
            setTimeout(function () {
                setCopied(0);
            }, 2000);
    }, [copied]);

    const getDomain =()=>{
        if(REACT_APP_ENV === 'production' && domain && type!=='hub')
            return `https://${domain}/${landingPageUrl||name}`;
        else return `${props.domain}/${landingPageUrl||name}`;
    };

    return (
        <> <css.EventMenuFormMain>
            <css.EventMenuForm>
                <Link href={getDomain()}>
                    <css.EventMenuFormText>{hasSpecificTranslate(`${type}SalesPage`,t)||t('salesPage')}</css.EventMenuFormText>
                    <css.EventMenuFormMore />
                </Link>
            </css.EventMenuForm>
            {<css.EventMenuOP ref={opRef} className={props.isMiniDisplay && 'miniDisplay'}>
                <ConditionalWrapper
                    condition={!copied}
                    wrapper={children =>  <Tooltip text={t('copyLink')}> {children} </Tooltip>}>
                    <css.EventMenuOPChild img={'copy'} className={' ' + (copied && 'copied')}
                        onClick={clickMoreOpCopy}>{t('copied')}</css.EventMenuOPChild>  
                </ConditionalWrapper>
                <input readOnly value={getDomain()} ref={(ref) => myInput = ref}/>
                <Tooltip text={t('shared')}>
                    <css.EventMenuShareOPChild img={'share'} onClick={() => setShowPopup(1)}/>
                </Tooltip>
            </css.EventMenuOP>
            }
            {!!showPopup &&
            <SharePopUp closePopUp={()=>setShowPopup(0)} getDomain={getDomain}/>}
        </css.EventMenuFormMain>
        </>
    );
}

export default FormButton;