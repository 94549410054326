import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {useField, useFormikContext} from 'formik';
import setGlobalState from '../../../../../redux/setGlobalState';
import {getTicket} from '../../../../../redux/tickets/ticketsActions';
import TabsPopup from '../../../../../component/newPopup/tabsPopup/TabsPopup';
import FormikModeBtn from '../../../../../component/formikModeBtn/FormikModeBtn';
import * as css from './GlobalPopupContent.css';
import TicketOptions from '../../../components/ticketOptions/TicketOptions';
import {bounceMessage, bounceMessageModal} from '../../../../../layout/userMessages/UserMessage';
import {deleteCourse} from '../../../../../redux/suggestions/suggestionsActions';
import {useHistory} from 'react-router-dom';
import BottomCourse from '../../courseTicket/bottomCourse/BottomCourse';
import {CourseDetailsContext} from '../../courseTicket/coursePopupContent/CoursePopupContent';
import {useRef} from 'react';
import {openDesignPop} from '../../../../../layout/Layout';
import DraftButton from '../draftButton/DraftButton';
import DraftPopup from '../draftButton/DraftPopup';
// import {setCurrentRating} from '../../../../components/rating/Rating';

function GlobalPopupContent({tid, close=()=>{}, onSubmitForm, header,top, tabsList,showClass}) {
    const {t} = useTranslation('ticket');
    const tLayout = useTranslation('layout');
    const {ticket,addTicketXHR,tickets} = useSelector(s => ({ticket:s.tickets?.currentTicket,
        addTicketXHR:s.tickets.addTicketXHR,
        tickets:s.tickets?.tickets,}));
        
    const event = useSelector(s => s.event);
    const [titleField] = useField('title');
    const cutTitle = titleField.value.replace(header.title,'');
    const history = useHistory();
    const isTicket=event.type==='ticket';
    
    const errorFieldRef = useRef();

    const closureFunctionality = () => {
        close();
        courseFeedback();
        if(isTicket && tickets.length === 1)
            openDesignPop();
    };

    const saveAndClose = () => {
        onSubmitForm();
        setTimeout(function(){
            closureFunctionality();
            if(!tickets.length && isTicket)
                openDesignPop();
        },2000);
    };

    const courseFeedback = () => {
        // if(ui.courses.feedback){
        //     setCurrentRating('createCourse');
        //     setGlobalState('ui.courses.feedback', null);
        // }
    };

    useEffect(() => {
        if(tid)
            getTicket(tid).then();
    }, []);

    useEffect(() => {
        return () => {
            setGlobalState('tickets.currentTicket', null);
            setGlobalState('ui.colorsPicker.currentColor', '');
        };
    }, []);

    const form = useFormikContext();

    const errorCaseBtn = {
        text: t('close'),
        onClick: () => {closureFunctionality();},
        isImplementFromButtonOnly:true
    };
  
    const onClose = () => {
        if(event.ready<3 && event.type==='course'&&!addTicketXHR){
            bounceMessage(tLayout.t('deleteCourseWarning'),'warning', 
                {text: tLayout.t('btnMessage1'), onClick:()=> {event.eid && deleteCourse(event.eid);history.go(-2);}},
                {text: tLayout.t('btnMessage')});
        }
        else { 
            if(Object.keys(form.touched).length) {
                if(Object.keys(form.errors).length)
                    bounceMessageModal(t('closeFormMessage'),'warning',
                        {text:t('continueEditing'),onClick:()=>{}},
                        errorCaseBtn);
                else {
                    bounceMessageModal(t('closeFormMessage'),'warning',
                        {
                            text: t('saveAndClose'),
                            onClick: () => saveAndClose(),
                            isImplementFromButtonOnly:true
                        },errorCaseBtn);
                }
            }
            else 
                closureFunctionality();
        }
          
    };
    const bottomComp= {Comp: BottomCourse, onSubmitForm,close:onClose};
    
    const onDuplicate = () => {
        bounceMessage(t('duplicateMsg'),'warning');
    };

    return (
        <CourseDetailsContext.Provider value={{errorFieldRef}}>
            <TabsPopup showClass={showClass} tabsList={tabsList} image={form.values.imgData.path}
                header={{title: `${header.title} ${cutTitle}`,type:header.type, close: onClose,list:{comp:TicketOptions,ticket:ticket,onDeleteCallback:close,...(Object.keys(form.touched).length ? {onDuplicateCallback: onDuplicate} : {})}}}
                color={form.values?.color}
                top={top}
                bottomComp={bottomComp}
                stageChildren={<DraftButton close={closureFunctionality}/>}>
                <css.wrapMode>
                    <DraftPopup/>
                    <FormikModeBtn name='active' className="small" /></css.wrapMode>
            </TabsPopup>
        </CourseDetailsContext.Provider>

    );
}

export default GlobalPopupContent;
