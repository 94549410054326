import styled from 'styled-components/macro';
import {closedEye,openEye} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const passwordInput=styled.div`
  position: relative;
`;
export const eye=styled.div`
  position: absolute;
  width: 20px;
  height: 35px;
  z-index: ${zIndex.baseHighest};
  ${p=>BGImageCenter(p.isPasswordSecure?openEye:closedEye, 'contain')};
  inset-inline-end: 10px;
  cursor:pointer;
  transition: all 200ms;
  
`;

