import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Accordion from '../../package/componnent/accordion/Accordion';
import GeneralForm from '../generalForm/GeneralForm';
import PaymentForm from '../paymentForm/PaymentForm';
import MailingForm from '../mailingForm/MailingForm';
import ReportForm from '../reportForm/ReportForm';
import {getEventSettings} from '../../../redux/event/eventActions';
import {getCrowds} from '../../../redux/crowds/crowdsActions';
import {getEventTeam} from '../../../redux/team/teamActions';
import SaleForm from '../saleForm/SaleForm';
import {useSelector} from 'react-redux';
import SettingsPopupWrapper from '../settingsPopupWrapper/SettingsPopupWrapper';

function SettingsPopup({showClass, close}) {
    const {t} = useTranslation('event');
    const getEventSettingsXHR=useSelector(state=>state.event.getEventSettingsXHR);
    const getEventTeamXHR = useSelector(s => s.team.getEventTeamXHR);
    const getCrowdsXHR=useSelector(state=>state.crowds.getCrowdsXHR);
    const langDesign = useSelector(state => state.event.settings?.langDesign);

    useEffect(() => {
        if(!getEventSettingsXHR)
            getEventSettings().then();
        if(!getEventTeamXHR)
            getEventTeam().then();
        if(!getCrowdsXHR)
            getCrowds().then();
    }, []);
   
    return (
        <SettingsPopupWrapper showClass={showClass} close={close}>
            <>
                <Accordion title={t('generalSettings')} dataCyTitle='generalSettings'>
                    <GeneralForm langDesign={langDesign}/>
                </Accordion>
                <Accordion title={t('PaymentsAndStanding')}  isClose dataCyTitle='PaymentsAndStanding'>
                    <PaymentForm close={close} />
                </Accordion>
                <Accordion title={t('advertisingMarketing')} isClose dataCyTitle='advertisingMarketing'>
                    <MailingForm />
                </Accordion>
                <Accordion title={t('reportsAndUpdates')} isClose dataCyTitle='reportsAndUpdates'>
                    <ReportForm />
                </Accordion>
                <Accordion title={t('sale')} isClose dataCyTitle='sale'>
                    <SaleForm />
                </Accordion>
            </>
        </SettingsPopupWrapper>
    );
}

export default SettingsPopup;
