import styled from 'styled-components/macro';
import {dFlexCenter, scrollbar} from '../../../../../globalVariables/GlobalVariables';

// const DEFAULT_COLOR = '#76D6E8';

export const wrapAll=styled.div`
    height:200px ;
    display: flex;
    flex-direction: column;
`;

export const wrapContentPop=styled.div`
    background: #fbfbfb;
    padding: 30px 15px 15px 15px;
    height: calc(100% - 259px);
    border-bottom: 1px solid #EEE;
    ${scrollbar} 
`;
export const bottomPop=styled.div`
   height: 70px;
   ${dFlexCenter}
`;
export const earlySale = styled.div`
    width: 90%;
    height: 100px;
    border: 1px solid #707070;
    border-radius: 8px;
    ${dFlexCenter}
    flex-direction:column ;
    padding: 7px;
    margin: 17px;
`;
export const text = styled.div`
    font-size:14px;
    font-weight:600 ;
`;

export const wrapText = styled.div`
   display:grid;
   grid-template-columns: repeat(2 , 1fr);
   align-items:center ;
`;

export const wrapPrice = styled.div`
     margin: -20px;
`;

export const wrapMonths = styled.div`
   display:grid;
   grid-template-columns: repeat(2 , 1fr);
   justify-items: center;
   padding: 15px 25px 0 0;
`;