import {useDropzone} from 'react-dropzone';
import * as css from './ImageUpload.css';
import {fileTypes,validator} from '../../hooks/useRejectedFiles';
import compressAndEncodeToBase64 from '../../library/fileCompressor';

function ImageUpload({children=()=><></>,acceptedFiles=fileTypes.image,onDrop}) {

    const convertToBase64=(acceptedFiles,rejectedFiles)=>{
        if(rejectedFiles.length){
            return onDrop(null,acceptedFiles,rejectedFiles);
        }
        const file = acceptedFiles[0];
        if(!file)
            return;
        compressAndEncodeToBase64(file,(base64)=>onDrop(base64,acceptedFiles,rejectedFiles));
    };

    const {getRootProps, getInputProps, isDragActive,open} = useDropzone({
        onDrop:convertToBase64,
        noClick: true,
        noKeyboard: true,
        accept: acceptedFiles,
        validator
    });
    
    return (
        <css.container {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}  >
            <input {...getInputProps()} />
            {typeof children==='function'?children(open, isDragActive):children}
        </css.container>
    );
}

export default ImageUpload;