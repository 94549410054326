import {useTranslation} from 'react-i18next';
import * as css from './BudgetItemStep.css';
import InputList from '../../../../component/input/inputList/InputList';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import store from '../../../../store';
import {useField} from 'formik';
import {updateEvent} from '../../../../redux/event/eventActions';

export const onClickBudgetIndex=async (changedValues)=>{
    if(changedValues?.budgetIndex === 0) {
        changedValues.budgetIndex = null;
    }
    updateEvent(changedValues);
};

export const getTopBudgetItem=(value)=>{
    const budgetItems=store.getState().account.budgetItems;
    return budgetItems.find(b=>b.budgetIndex===value)?.budgetTitle;
};

function BudgetItemStep() {
    const {t} = useTranslation('newEvent');
    const budgetItems = useSelector(s=>s.account.budgetItems);
    const [,,budgetIndexHelpers]=useField('budgetIndex');
    const history = useHistory();

    const budgetItemsList = [
        {key: 0 ,text: t('withoutBudgetItem'), onClick:()=>budgetIndexHelpers.setValue(0)},
        ...budgetItems.map(item => ({key: item.budgetIndex, text: `${item.budgetTitle} (${item.budgetIndex})`})),
        {key: 'newKey', text: t('new'), onClick: () => history.push('?packagePage=bank')}
    ];

    return (
        <css.wrapper>
            <InputList name={'budgetIndex'} text={t('budgetIndex')} list={budgetItemsList} t={t} />
        </css.wrapper>
    );
}

export default BudgetItemStep;
