import BottomPopup from '../bottomPopup/BottomPopup';
import WrapPopup from '../wrapPopup/WrapPopup';
import * as css from '../tabsPopup/TabsPopup.css';
import ButtonScroll from '../../button/buttonScroll/ButtonScroll';
import {useEffect, useRef, useState} from 'react';

function RegularPopup({top, image, color, children}) {
    const refContent=useRef();
    const [showButtonScroll,setShowButtonScroll]=useState(false);
    const timer=useRef(null);
    useEffect(()=>{
        timer.current=setTimeout(()=> {
            setShowButtonScroll(refContent.current?.firstElementChild.clientHeight > refContent.current?.clientHeight);
        },1000);
        return ()=> clearTimeout(timer.current);
    },[]);
    return (
        <WrapPopup {...top} height={(refContent.current?.scrollHeight||0)+200}>
            <>{children && children[0]}</> 
            <>
                <css.popUpImage color={color}>
                    <css.image image={image} />
                    {children && children[1]}
                </css.popUpImage>
                <css.popUpContentRegular ref={refContent}>
                    {children && children[2]}
                    {showButtonScroll&&<ButtonScroll refToScroll={refContent}/>}
                </css.popUpContentRegular>
                <BottomPopup>{children && children[3]}</BottomPopup>
            </>
        </WrapPopup>
    );
}

export default RegularPopup;
