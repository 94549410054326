import {Field, useField} from 'formik';
import * as css from './SelectFormik.css';

function SelectFormik({name, listField, icon, className,text}) {

    const [, meta, ] = useField(name);

    return (
        <>
            <css.container className={`${className}  ${(!!meta.touched && !!meta.value) &&' valid'}`}>
                <css.icon icon={icon} className={className}/>
                <css.wrap>
                    <css.wrapSelect>
                        {/* <Select
                            name={name}
                            value={helpers.value}
                            onChange={(value) => {
                                helpers.setValue(value.value)}}
                            options={listField}
                            onBlur={() => helpers.setTouched(true)}
                        /> */}
                        <Field as="select" name={name}>
                            {listField?.map((item, index) => {
                                return <option value={item.value}>{item.label}</option>;
                            })}
                        </Field>
                        <css.title>{text}</css.title>
                    </css.wrapSelect>
                </css.wrap>
            </css.container>

        </>
    );
}

export default SelectFormik;

