import styled from 'styled-components/macro';

export const supplierName = styled.div`
    width: 100px;
    padding-inline-start: 30px;
`;

export const commission = styled.div`
    width: 110px;
    text-align: center;
`;

export const type = styled.div`
    width: 50px;
`;

export const language = styled.div`
    width: 43px;
`;

export const currency = styled.div`
    width: 50px;
    text-align: center;
`;

export const isDefault = styled.div`
    width: 79px;
    text-align: center;
`;

export const assignedEvents = styled.div`
`;
