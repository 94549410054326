import {ts2time} from './dateFunctions_old_to_be_deprecated';
import * as css from '../component/mailing/Mailing.css';
function mailingTemplates(area, event, user, textParameters) {
    const parameters = textParameters;

    let templates = [];
    switch (area) {
        case 'crm':
            templates.push(
                {
                    title: 'כרטיס דיגיטלי',
                    content: 'שלום <span data-param="nameFirst" data-key="nameFirst" contenteditable="' + false + '">' + user.nameFirst + '</span> <span data-param="nameLast" data-key="nameLast" contenteditable="' + false + '">' + user.nameLast + '</span>, <br><span data-param="eventTitle" data-key="eventTitle" contenteditable="' + false + '">' + (event ? event.title : 'שם האירוע') + '</span>, <span data-param="location" data-key="location" contenteditable="' + false + '">' + (parameters['event.location']) + '</span>.<br> פתיחת שערים <span data-param="timeOpenstartTime" data-key="timeOpenstartTime" contenteditable="' + false + '">' + (event ? ts2time(event.timeStart) : 'שעת התחלה') + '</span>, נא לדייק.<br>הכרטיסים הינם דיגיטליים, ניתן להדפיס את הכרטיס מכאן <span data-param="ticketLinkPrint" data-key="ticketLinkPrint" contenteditable="' + false + '">tic.li/ticket?print</span><br> או להציגם על גבי צג הסמארטפון ביום האירוע <span data-param="ticketLink" data-key="ticketLink" contenteditable="' + false + '">tic.li/ticket</span><br>בכדי למנוע עיכוב בשערי הכניסה ודאו שיש לכל אחד מהנכנסים כרטיס.<br> שימו לב - הודעה זו אינה משמשת ככרטיס כניסה לאירוע.<br> מחכים לראותכם.<br><br> נשלח באמצעות טיקצ\'אק.',
                    line: <>שלום <css.tmplPar>{user.nameFirst}</css.tmplPar> <css.tmplPar>{user.nameLast}</css.tmplPar>, <css.tmplPar>{event ? event.title : 'שם האירוע'}</css.tmplPar>, <css.tmplPar>{parameters['event.location']}</css.tmplPar> .פתיחת שערים <css.tmplPar>{event ? ts2time(event.timeStart) : 'שעת התחלה'}</css.tmplPar>, נא לדייק.הכרטיסים הינם דיגיטליים, ניתן להדפיס את הכרטיס מכאן <css.tmplPar>{'tic.li/ticket?print'}</css.tmplPar> או להציגם על גבי צג הסמארטפון ביום האירוע <css.tmplPar>{'tic.li/ticket'}</css.tmplPar>.בכדי למנוע עיכוב בשערי הכניסה ודאו שיש לכל אחד מהנכנסים כרטיס.<br /> שימו לב - הודעה זו אינה משמשת ככרטיס כניסה לאירוע.<br /> מחכים לראותכם. נשלח באמצעות טיקצ'אק.</>,
                    opened: <>שלום <css.tmplPar>{user.nameFirst}</css.tmplPar> <css.tmplPar>{user.nameLast}</css.tmplPar>, <br /><css.tmplPar>{event ? event.title : 'שם האירוע'}</css.tmplPar>, <css.tmplPar>{parameters['event.location']}</css.tmplPar>.<br /> פתיחת שערים <css.tmplPar>{event ? ts2time(event.timeStart) : 'שעת התחלה'}</css.tmplPar>, נא לדייק.<br />הכרטיסים הינם דיגיטליים, ניתן להדפיס את הכרטיס מכאן <css.tmplPar>{'tic.li/ticket?print'}</css.tmplPar><br /> או להציגם על גבי צג הסמארטפון ביום האירוע <css.tmplPar>{'tic.li/ticket'}</css.tmplPar><br />.בכדי למנוע עיכוב בשערי הכניסה ודאו שיש לכל אחד מהנכנסים כרטיס. שימו לב - הודעה זו אינה משמשת ככרטיס כניסה לאירוע. מחכים לראותכם. נשלח באמצעות טיקצ'אק.</>,
                    ticket: true
                },
                {
                    title: 'כרטיס באיסוף עצמי',
                    content: 'שלום <span data-param="nameFirst" data-key="nameFirst" contenteditable="' + false + '">' + user.nameFirst + '</span> <span data-param="nameLast" data-key="nameLast" contenteditable="' + false + '">' + user.nameLast + '</span>, <br><span data-param="eventTitle" data-key="eventTitle" contenteditable="' + false + '">' + (event ? event.title : 'שם האירוע') + '</span>, <span data-param="location" data-key="location" contenteditable="' + false + '">' + (parameters['event.location']) + '</span> היום.<br> פתיחת שערים <span data-param="timeOpenstartTime" data-key="timeOpenstartTime" contenteditable="' + false + '">' + (event ? ts2time(event.timeStart) : 'שעת התחלה') + '</span>, נא לדייק.<br>הכרטיסים יחכו לכם בכניסה לאירוע.<br>במידה והזמנתם יותר מכרטיס אחד, נא הגיעו ביחד, או וודאו שכולם מקבלים העתק מהכרטיס.<br>שימו לב - הודעה זו אינה משמשת ככרטיס כניסה לאירוע.<br>מחכים לראותכם.<br>נשלח באמצעות טיקצ\'אק.',
                    line: <>שלום <css.tmplPar>{user.nameFirst}</css.tmplPar> <css.tmplPar>{user.nameLast}</css.tmplPar>, <css.tmplPar>{event ? event.title : 'שם האירוע'}</css.tmplPar>, ב<css.tmplPar>{parameters['event.location']}</css.tmplPar> היום. תחילת האירוע בשעה <css.tmplPar>{event ? ts2time(event.timeStart) : 'שעת התחלה'}</css.tmplPar>, נא לדייק. כולם מקבלים העתק מהכרטיס. שימו לב - הודעה זו אינה משמשת ככרטיס כניסה לאירוע. מחכים לראותכם. נשלח באמצעות טיקצ'אק.</>,
                    opened: <>שלום <css.tmplPar>{user.nameFirst}</css.tmplPar> <css.tmplPar>{user.nameLast}</css.tmplPar>,<br /><css.tmplPar>{event ? event.title : 'שם האירוע'}</css.tmplPar>, ב<css.tmplPar>{parameters['event.location']}</css.tmplPar> היום.<br /> תחילת האירוע בשעה <css.tmplPar>{event ? ts2time(event.timeStart) : 'שעת התחלה'}</css.tmplPar>, נא לדייק.<br />הכרטיסים יחכו לכם בכניסה לאירוע.<br />במידה והזמנתם יותר מכרטיס אחד, נא הגיעו ביחד, או וודאו שכולם מקבלים העתק מהכרטיס.<br />שימו לב - הודעה זו אינה משמשת ככרטיס כניסה לאירוע.<br />מחכים לראותכם.<br />נשלח באמצעות טיקצ\'אק.</>,
                    ticket: true
                }
            );

            return templates;
        case 'crowd':
            templates.push(
                {
                    title: 'מי ידאג לנו לתבנית לקהלים?',
                    content: 'שלום <span data-param="nameFirst" data-key="nameFirst" contenteditable="' + false + '">' + user.nameFirst + '</span> <span data-param="nameLast" data-key="nameLast" contenteditable="' + false + '">' + user.nameLast + '</span>, <br>לצערנו מוטי מתעצל להכין לנו תבניות לקהלים.<br> נקווה שבקרוב הוא יתעשת וינסח משהו.<br> עד אז מומלץ לפתוח את הדיוור מתוך אירוע.<br>(כשנמצאים באירוע הדיוור מתנהל עם תבניות ומשתנים כמו של עסקאות,<br> מחוץ לאירוע מתנהל כמו קהלים)',
                    line: <>שלום <css.tmplPar>{user.nameFirst}</css.tmplPar> <css.tmplPar>{user.nameLast}</css.tmplPar>, לצערנו מוטי מתעצל להכין לנו תבניות לקהלים. נקווה שבקרוב הוא יתעשת וינסח משהו. עד אז מומלץ לפתוח את הדיוור מתוך אירוע. (כשנמצאים באירוע הדיוור מתנהל עם תבניות ומשתנים כמו של עסקאות, מחוץ לאירוע מתנהל כמו קהלים).</>,
                    opened: <>שלום <css.tmplPar>{user.nameFirst}</css.tmplPar> <css.tmplPar>{user.nameLast}</css.tmplPar>, <br />לצערנו מוטי מתעצל להכין לנו תבניות לקהלים. נקווה שבקרוב הוא יתעשת וינסח משהו. עד אז מומלץ לפתוח את הדיוור מתוך אירוע. (כשנמצאים באירוע הדיוור מתנהל עם תבניות ומשתנים כמו של עסקאות, מחוץ לאירוע מתנהל כמו קהלים).</>,
                    ticket: true
                }
            );
            return templates;
        default:
    }
}
export default mailingTemplates;