import {useTranslation} from 'react-i18next';
import RadioBtn from '../radioBtn/RadioBtn';
import * as css from './WrapRadioBtn.css';

function WrapRadioBtn({text, onClick, selected, name}) {

    const {t} = useTranslation('global');

    return (
        <css.wrapRadioBtn>
            <RadioBtn onClick={onClick} selected={selected} name={name} />
            <css.radioBtnText >{t(`${text}`)}</css.radioBtnText>
        </css.wrapRadioBtn>
    );
}

export default WrapRadioBtn;