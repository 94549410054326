import {useTranslation} from 'react-i18next';
import CourseDetails from '../courseDetails/CourseDetails';
import CourseFields from '../courseFields/CourseFields';
import PaymentSettings from '../paymentSettings/PaymentSettings';
import GlobalPopupContent from '../../components/globalPopupContent/GlobalPopupContent';
import useTicket from '../../../../../hooks/useTicket';
import {useRef} from 'react';
import Extensions from '../../courseTicket/extensions/Extensions';
import FormContext from '../../../../components/formContext/FormContext';
import {getFollowingMonth} from '../../../../../library/dates';
import {useSelector} from 'react-redux';
import {bounceMessageModal} from '../../../../../layout/userMessages/UserMessage';
import {createContext} from 'react';
import {validationCourse} from '../../functions/validationSchema';

export const CourseDetailsContext = createContext();

function CoursePopupContent({tid, close,showClass}) {

    const {t} = useTranslation('ticket');
    const ticket = useSelector(s => s.tickets?.currentTicket);
    const {save,validationSchema,generalInitialValues,mergedInitialValues}=useTicket();
    let  {details, extras, payments,  customFields}=generalInitialValues;
    details = {...details, amount: ticket?.amount ? ticket?.amount : 30,hideGuide:ticket?.hideGuide||0,hideScheduled:ticket?.hideScheduled||0};
    if(!details.prices[0]?.hasOwnProperty('title'))
        details.prices=[];

    const followingMonth = getFollowingMonth(details?.course?.startDate);
    if(!payments.payments.secondPayment)
        payments = {
            ...payments,
            payments: {...payments.payments, secondPayment: followingMonth},
        };
    if(!payments.payments.extraException)
        payments = {
            ...payments,
            payments: {...payments.payments, extraException: 1},
        };
    if(!ticket?.tid) {
        payments.payments.hasBillings = true;
        payments.payments.numberPayments = 2;
    }

    const courseInitialValues = mergedInitialValues({details,extras,payments,customFields});
    // courseInitialValues.draft=false;

    const formRef = useRef();

    const onSubmitForm = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const disabled={
        disabled:!ticket?.tid,
        disabledClick:()=>{
            bounceMessageModal(t('tabMessage'),'warning');
        }
    };

    let tabsList = [
        {type: 'courseDetails', stageText: t('courseDetails'),textContinue:t('courseExtension'), Comp: CourseDetails, onSubmitForm},
        {type: 'participantDetails',...disabled, stageText: t('participantDetails'),textContinue:t('paymentSettings'), Comp: CourseFields, onSubmitForm},
        {type: 'extensions',...disabled, stageText: t('extensions'),textContinue:t('participantDetails'), Comp: Extensions, onSubmitForm},
        {type: 'paymentSettings',...disabled, stageText: t('advancedSettings'), Comp: PaymentSettings, onSubmitForm}
    ];
    const hasDates=useSelector(s=>s.ui.courses.hasDates);

    return (

        <FormContext
            innerRef={formRef}
            initialValues={courseInitialValues}
            validationSchema={validationSchema.concat(validationCourse(hasDates))}
            enableReinitialize
            onSubmit={save}
        >       
            <GlobalPopupContent
                header={{image:'course', title: t('course'),type:'course'}}
                top={'50'}
                onSubmitForm={onSubmitForm}
                tabsList={tabsList} 
                tid={tid}
                showClass={showClass}
                close={close}
            />      
        </FormContext>

    );
}

export default CoursePopupContent;
