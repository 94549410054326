import styled from 'styled-components/macro';
import {BGImageCenter, dFlexCenter} from '../../../globalVariables/GlobalVariables';

export const item=styled.div`
    height:45px ;
    width:85% ;
    box-shadow:0px 1px 2px #00000070;
    background-color: white;
    border-radius: 22px;
    margin:7px 0;
    ${dFlexCenter}
`;

export const rightPart=styled.div`
    width: calc(100% - 80px);
    display:flex ;
`;

export const leftPart=styled.div`
   ${dFlexCenter}
    width: 80px;
    border-${props => (props.theme.rtl ?'right':'left')}: 1px solid #d1cece;
    height:75% ;
`;

export const image=styled.div`
    height:40px;
    width: 40px;
    background-color: #fff;
    ${BGImageCenter(props => props.symbol,'33px')};
    background-image:url("${p=>p.face}");
    cursor: pointer;
    border-radius:50%;
    box-shadow: 0px 3px 6px #00000029;
    margin:2px ;
  
`;

export const text=styled.div`
    font-size: 12px;
`;

export const wrapText=styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding:0 5px;

`;

export const boldText=styled.div`
    font-weight: 700;
    font-size: 12px;
    padding:0 8px;
`;

export const left=styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 44px);
  justify-content: space-between;
`;

export const icon=styled.div`
    ${props=>BGImageCenter(props.icon,'17px 17px')}
    position:relative ;
    height:27px;
    width:27px ;
    cursor: pointer;
    transition: all 300ms;
    border-radius:50% ;
    :hover{
        box-shadow:2px 2px 3px #00000070;
        background-color: white;
    }
`;
