import * as css from './MemberOption.css';
import useShow from '../../../hooks/useShow';
import {useTranslation} from 'react-i18next';
import {pencil,  remove} from '../../../assets/symbols/symbols/Symbols.css';
import {isMaster} from '../../../functions/permission';
import List from '../../../component/list/List';
import {removeMemberRole} from '../../../redux/team/teamActions';
import {useSelector} from 'react-redux';
import {useOpenMemberPop} from '../memberBig/MemberBig';
import {useHistory} from 'react-router-dom';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
export const useEditAccountMember=()=>{
    const {t} = useTranslation('team');

    const history=useHistory();
    const ticketT = useTranslation('ticket').t;

    const editAccountMember=(member)=>{
        bounceMessage(t('editAccountMessage'),'choice',
            {
                text:ticketT('continueEditing'),
                onClick:()=>history.push({search:`?packagePage=team&teamMemberPop=teamMember&uid=${member.uid}`})
            },{
                text:ticketT('cancel')
            });
    };
    return editAccountMember;
};

function MemberOptions({deletePermission,right=25,member,isAccountPage}) {
    const {uid}=member;
    const {t} = useTranslation('team');
    const {open, close, isOpen, showClass} = useShow();

    const editAccountMember=useEditAccountMember();
    const id = useSelector(s=>isAccountPage?s.account.pid:s.event.eid);
    const openMemberPop=useOpenMemberPop();
    const isUnauthenticated=member.status==='unauthenticated';

    const onDelete = async () => {
        await removeMemberRole(uid,id,isAccountPage);
    };
    let optionsList = [];
    if(uid){
        optionsList = [
            ...(isUnauthenticated?[]:[
                {text: t('editMember'),  icon: pencil, onClick:member.isPackageRole?()=>editAccountMember(member):()=>openMemberPop(member),dataCyName:"editMember"}
            ]),
            ...(member.isPackageRole&&!isAccountPage?
                []
                :[{type: 'icon', text: t('delete'), onClick: onDelete, icon: remove, disabled: deletePermission?false:true ,dataCyName:"delete"}]),
            ...(isMaster?
                [{text: `${t('number')+': '+uid}`,dataCyName:"number"}]
                :[]),
        ];
    }

    return (

        <css.options onClick={e=>{e.stopPropagation();isOpen ? close() : open();} }  >
            <css.optionsIcon data-cy="memberOptions" />
            {isOpen && <List
                list={optionsList}
                top={0}
                right={right+'px'}
                showClass={showClass} 
                close={close}
                title={isUnauthenticated?t('crewMember'):`${member.nameFirst} ${member.nameLast}`}
            />}
        </css.options>
    );
}
export default MemberOptions;
