import {useEffect} from 'react';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import FormContext from '../../components/formContext/FormContext';
import * as actions from '../../../redux/team/teamActions';
import {useSelector} from 'react-redux';
import {teamMemberPhoto} from '../../../assets/symbols/images/teamMemberPhoto';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import React from 'react';
import {doOnMark} from '../../tickets/ticketsPopUp/courseTicket/guidesList/GuidesListPop';
import setGlobalState from '../../../redux/setGlobalState';
import {about} from './UserPop';
import useTeamMembers, {getAccountOnlyMembers} from '../../../hooks/useTeamMembers';
import validator from 'validator';
import {initPhoneNumber} from '../../../functions/intrernationalPhoneNumber';
import {yupStringMin} from '../../../functions/validationsYup';

function TeamPop({closeTimeout,children}) {
    const {t} = useTranslation('team');
    let params = new URLSearchParams(location.search);
    const isPackagePage=params.get('packagePage')==='team';
    let team = useSelector(s=>s.team);
    const uid=Number(params.get('uid')||team.currentMemberUid);
    const type=isPackagePage?'Account':'Event';
    
    let isGuide = useSelector(s=>(s.ui.team.isGuide));

    const XHR=team[`update${type}TeamMemberXHR`]||team[`create${type}TeamMemberXHR`];
    const existsUser=team.existsUser;
    const allMembers=useTeamMembers();
    let  members=[];
    if(isPackagePage)
        members=allMembers.accountMembers;
    else 
        members= [...getAccountOnlyMembers(allMembers.accountMembers,allMembers.eventMembers),...allMembers.eventMembers];
    const currentUser=members.find(m=>m.uid===uid)||{};
    const ownerMember=members.find(m=>m.role==='owner');
    const [aboutInitialValues,aboutValidation]=about(currentUser,t);
    
    Yup.addMethod(Yup.mixed, 'phone', function(message) {
        return this.test('phone', message, function (value) {
            const country = this.resolve(Yup.ref('country'));
            const local = validator.isMobilePhoneLocales.find(local => local.includes(country));
            const phone = initPhoneNumber({nationalNumber: value, country: country});
            return validator.isMobilePhone(value || '', local) || validator.isMobilePhone('+'+phone.internationalNumber || '', local);
        });
    });
    const validationSchema = Yup.object().shape({
        nameFirst: yupStringMin().max(30).required(),
        nameLast: yupStringMin().max(30).required(),
        phone: Yup.string().phone().required(),
        email:Yup.string().email().required(),
        ...aboutValidation
    });

    const {country, nationalNumber} = initPhoneNumber({internationalNumber: currentUser.phone});
    const teamInitialValues={nameFirst:currentUser.nameFirst||'',
        nameLast:currentUser.nameLast||'',
        email:currentUser.email||'',
        phone:nationalNumber|| currentUser.phone||'' ,
        country: country,
        picture:currentUser.picture||teamMemberPhoto,
        role:currentUser.role||(isGuide?'guide':(ownerMember?'manager':'owner'))};

    const initialValues = {
        ...teamInitialValues,
        ...aboutInitialValues
    };
    
    useEffect(()=>{
        const getXHR=isPackagePage?(team.getAccountTeamXHR||team.getEventTeamXHR):team.getEventTeamXHR;
        if(getXHR!=='SUCCESS')
            return;
        if(uid&&!currentUser.info){
            setGlobalState('team.currentMemberUid',uid);
            actions[`get${type}TeamMemberInfo`](uid);
        }
    },[team.getEventTeamXHR,team.getAccountTeamXHR]);

    useEffect(()=>{
        if(XHR) {
            if(uid) 
                setGlobalState(`team.update${type}TeamMemberXHR`, null);
            else setGlobalState(`team.create${type}TeamMemberXHR`, null);
        }
    },[]);

    const teamMemberSubmit=async(_changedValues,values)=>{
        values.phone = initPhoneNumber({nationalNumber: values.phone, country: values.country}).internationalNumber || values.phone;
        if(currentUser.uid)
            actions[`update${type}TeamMember`](values,currentUser.uid).then(closeTimeout);
        else
            actions[`create${type}TeamMember`](values).then((res)=>{
                closeTimeout();
                if(isGuide){
                    doOnMark({...res,newUser:1});
                }
                if(existsUser)
                    bounceMessage(t('addTeamMemberSuccess',{name:res.nameFirst+' '+res.nameLast}),'success'); 
                
                else
                    bounceMessage(t('addExistsTeamMemberSuccess',{name:res.nameFirst+' '+res.nameLast}),'success');
            });
    };
    const bottomProps={
        XHR,
        keys:{
            about:Object.keys(aboutInitialValues),
            teamMember:Object.keys(teamInitialValues)
        }};

    return (
        <FormContext 
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={teamMemberSubmit}
        >{({values})=>children(values,bottomProps,currentUser)}
        </FormContext>
       
    );
}

export default TeamPop;