import {Else, If, Then} from 'react-if';
import BasicInput from './basicInput/BasicInput';
import DisabledInputFormik from './disabledInput/DisabledInputFormik';

function NumberInput({name, text = '', description = '', icon, disabled = false, events={}, className, forwardRef}) {

    return (
        <If condition={disabled}>
            <Then>
                <DisabledInputFormik
                    name={name}
                    text={text}
                    description={description}
                    icon={icon}
                    className={className}
                />
            </Then>
            <Else>
                <BasicInput
                    events={{onFocus:e=>e.target.select(),
                        onKeyDown:e=>['E','e','-','+'].includes(e.key)&&e.preventDefault(),
                        ...events}}
                    forwardRef = {forwardRef}
                    name={name}
                    text={text}
                    description={description}
                    icon={icon}
                    className={className} 
                    types={{fieldType:'input',inputType:'number'}}/>
            </Else>
        </If>
    );
}

export default NumberInput;
