import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import * as css from './Packages.css';
import {getAccounts} from '../../../redux/suggestions/suggestionsActions';
import setGlobalState from '../../../redux/setGlobalState';
import Tooltip from '../../../component/tooltip/Tooltip';
import {dispatchCustomEvent} from '../../../hooks/dispatchEvent';
import ListWrap from '../../../component/listWrap/ListWrap';
import useAccountMenu from '../../../tickchakHooks/useAccontMenu';
import useShow from '../../../hooks/useShow';
import {useTranslation} from 'react-i18next';

function Packages({offset,search,limit,cnt,onClick,openEvents}) {

    const accounts = useSelector(state => state.suggestions.accounts);
    const pid = useSelector(state => state.account.pid);
    const getPackagesEffect = () => {
        if(offset == 0)
            setGlobalState('suggestions.accounts', []);
        getAccounts({search: search, limit: limit, offset: offset});
    };
    useEffect(() => {
        getPackagesEffect();
    }, [offset, pid]);
    const count = useRef(0);
    const isFirstRun = useRef(true);
    useEffect(() => {
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        count.current = cnt;
        setTimeout(function () {
            if(count.current != cnt)
                return;
            getPackagesEffect();
        }, 700);

    }, [search]);
    const history = useHistory();

    const handleOnClickPackage = (e, item) => {
        e.stopPropagation();
        dispatchCustomEvent('changePackage', {detail: item.pid});
        dispatchCustomEvent('changeEvent', {detail: null});
        
        history.push({
            pathname: '/p/' + item.pid + '/home',
        });
        onClick();
        openEvents();
    };
    const {open, close, isOpen, showClass} = useShow();
    const {t} = useTranslation('layout');
    const getList=useAccountMenu(close);
    const [currentAccount,setCurrentAccount]=useState({pid:null,title:null});
    const openDefinition=(e,pid,title)=>{
        e.stopPropagation();
        setCurrentAccount({pid,title});
        open();
    };
    return (
        <css.PackageFrame>
            {accounts?.map((item) => <css.Package key={item.pid} index={item.pid} onClick={(e) => handleOnClickPackage(e, item)}>
                <css.PackageIcon />
                <Tooltip text={item.title}><css.PackageText>{item.title}</css.PackageText></Tooltip>
                <Tooltip text={t('numberOfEvents')}> <css.PackageEvents>{item.countEvents}</css.PackageEvents></Tooltip>
                {/*<css.PackageDefinition  onClick={(e)=> openDefinition(e,item.pid,item.title)}  />*/}
            </css.Package>)}
            {isOpen && <ListWrap close={close} showClass={showClass} list={getList(currentAccount.pid,currentAccount.title)}  top="36" t={t} />}
        </css.PackageFrame>
    );
}

export default Packages;
