import React, {useState} from 'react';
import * as css from './Navigation.css';
import {menuEvent} from '../menu';
import {menuAccount} from '../menuAccount';
import {useParams, withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Boxoffice from '../boxoffice/Boxoffice';
import SubList from '../subList/SubList';
import {useSelector} from 'react-redux';
import EventActive from '../../pages/components/eventActive/EventActive';
import useTablet from '../../hooks/useTablet';
import useShow from '../../hooks/useShow';
import useNavigationList from './useNavigationList';
import useCurrentItemUrl from '../../tickchakHooks/useCurrentItemUrl';
import {getPageHeader} from '../../pages/components/subMenu/SubMenu';
import hasSpecificTranslate from '../../library/hasSpecificTranslate';

export const getPageTitle = (eventType,t,title='') => {
    if(title.indexOf('of') !== 0)
        return t(title);
    let perfectTitle = title.substring(2);
    
    return hasSpecificTranslate(`${eventType}${perfectTitle}`,t)||t(`event${perfectTitle}`);
};

function Navigation(props) {
    const event = useSelector(state => state.event);
    const {t} = useTranslation('layout');
    const [maxDisplay, ] = useState(0);
    const isTablet = useTablet();
    const params = useParams();

    const show = useShow();

    // useEffect(() => {
    //     setTimeout(function(){
    //         setMaxDisplay(0);
    //     },2000)
    // }, [])
    // const updateMaxDisplay = (max) => {
    //     setMaxDisplay(max);
    // }
    //
    
    let [,,,currentPage,subPage] = props.location.pathname.split('/');
    const menuJson = event ? menuEvent : menuAccount;
    let page = Object.entries(menuJson).filter(([key]) => key == currentPage)[0];
    page = page && page[1];
    const currentItemUrl = useCurrentItemUrl();
    let idParams=params[currentItemUrl?.idParams];
    const navigationList = useNavigationList(idParams);

    return (
        <>
            <css.Navigation className={maxDisplay && 'max'}>
                <css.NavigationRouter>
                    <css.NavigationFrame className={maxDisplay && 'max'}>
                        <css.NavigationTitle className={(navigationList?.length&& 'arrow ') + (maxDisplay && ' max')} onClick={isTablet ? show.open : undefined}
                            symbol={page?.path.replace('/', '')} data-cy={page?.path.replace('/', '')}>{isTablet?getPageHeader(currentPage,subPage,t):getPageTitle(event.type,t,page?.title)}</css.NavigationTitle>
                        <css.NavigationLinks>
                            <SubList show={show}  />
                        </css.NavigationLinks>
                    </css.NavigationFrame>
                </css.NavigationRouter>
                <css.NavigationBtn className={maxDisplay && 'max'}>
                    {(event.eid && event.ready===5 && event.type!=='hub')&& <>
                        <css.boxoffice className={currentItemUrl?.component==='MyIframe'&&'iframe'} data-cy="boxOffice"><Boxoffice /></css.boxoffice> 
                        <css.activeEvent><EventActive /></css.activeEvent>
                    </>}
                </css.NavigationBtn>
            </css.Navigation>

        </>
    );
}

export default withRouter((Navigation));
