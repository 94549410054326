import * as css from './AccountPermission.css';
import {Case, Switch} from 'react-if';
import PackageUpgrade from '../../../layout/packageUpgrade/PackageUpgrade';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';

function AccountPermission({permission,children,toShowUpgrade=false,toShowMessage=false,statusArr=[0],message}) {
    const {t} = useTranslation('global');
    const permissions=useSelector(s=>s.account.permissions);

    let {status,hide}=permissions?.find((item) => item.permission === permission)||{};
    return (
        <>
            <Switch>
                <Case condition={status===1 || permission === true}>{children}</Case>
                <Case condition={toShowMessage&&statusArr.includes(status)}>
                    <css.inactive onClick={() => bounceMessage(message||t('inactivePermission'),'warning')}>
                        {children}
                    </css.inactive>
                </Case>
                <Case condition={toShowUpgrade&&status === -1 && !hide}>
                    <PackageUpgrade permission={permission}>{children}</PackageUpgrade>
                </Case>
            </Switch>
        </>
    );
}

export default AccountPermission;
