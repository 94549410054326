import * as css from './MenuPopUp.css';
import {useState, useRef} from 'react';
import useOnClickOutside from '../../../hooks/UseOnClickOutside';

function MenuPopUp(props) {
    const {menuPopUpProps}=props;
    const maxHeight = menuPopUpProps.maxHeight ? Number((menuPopUpProps.maxHeight).replace('px','')) : 240;
    const ref = useRef(); 
    const scrollRef = useRef('');

    const [offset, setOffset] = useState(0);
    const [{selected, selectedIndex}, setSelected] = useState({selected:'',selectedIndex:-1});

    const handleSelect = (index, key)=>{
        const operation = menuPopUpProps.current && key == menuPopUpProps.current ? 'delete' : 'switch';
        menuPopUpProps.onClick(key,operation);
        setSelected({selected:'selected',selectedIndex:index});
        setTimeout(function(){
            setSelected({selected:'',selectedIndex:-1});
        },1000);
    };

    const openMore = (e)=>{
        const newOffset = offset + maxHeight;
        scrollRef.current.scrollTo({top: newOffset, left: 0, behavior: 'smooth'});
        setOffset(newOffset);
    };

    useOnClickOutside(ref, ()=>{
        setOffset(0);
        scrollRef.current.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    });

    return (
        <css.menuPopUp contentEditable={false} className={'menuPopUp '+menuPopUpProps.class+' '+(!!menuPopUpProps.show==1&&'show ')} cssProps={menuPopUpProps.popUpCss} ref={ref}>
            <css.menuTitle className="menuTitle">{menuPopUpProps.title}</css.menuTitle>
            <css.menuItems className="menuItems" ref={scrollRef}>
                {Object.entries(menuPopUpProps.popUpItem)?.map(([key, value], index) => (
                    <css.menuItem key={key} index={index} className={'menuItem '+menuPopUpProps.class} onClick={() => {handleSelect(index, key); }}>
                        <css.menuItemIcon className={'menuItemIcon '+menuPopUpProps.class} />
                        <css.menuItemText title={value} className={'menuItemText '}>{value}</css.menuItemText>
                        <css.menuItemBtn className={'menuItemBtn '+(selectedIndex==index?selected:(menuPopUpProps.current && key == menuPopUpProps.current && selected!='selected'? 'garbage' : menuPopUpProps.class))} />
                    </css.menuItem>
                ))}
            </css.menuItems>
            <css.menuMore className="menuMore" onClick={openMore} />
        </css.menuPopUp>
    );
}

export default MenuPopUp;