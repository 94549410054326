import styled, {keyframes} from 'styled-components';
import {arrowBtn} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';

export const pathItem = styled.div`
    text-align:right;
    font-size: 16px;
    font-weight: 800;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
`;

export const circle = styled.div`
    height: 16px;
    width: 16px;
    background-color: #76d6e8;
    border-radius: 50%;
    margin: 3px 10px 0;
    ${BGImageCenter(arrowBtn,'6px')}
    ${p=>!p.theme.rtl&&'transform: rotate(180deg);'}
`;