import ticketColors from '../../../assets/objects/TicketColors';
import {updateTicketsIcon} from '../../../redux/tickets/ticketsActions';
import styled from 'styled-components/macro';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';
import {vi} from '../../../assets/symbols/symbols/Symbols.css';
import {ItemText} from '../../../component/item/Item.css';

const css={
    checkbox:styled.div`
  width: 100%;
  height: 100%;
  ${p=>BGImageCenter(p.image,'20px')};
  background-color:${p=>p.color};
  border: 1px solid transparent;
  transition: all 400ms;
  &.selected{
    ${BGImageCenter(vi,'12px')}
  }
`};

export const ticketOptionsCssStyle=`.ticketsOptionsList{${ItemText}{padding-inline-start: 10px}}`;  

function getTicketsIconList(ticketsIcon,t) {
    const {flower,animal}=ticketColors.C61010;
    const options={
        empty:{color:'#AAA',image:null},
        animal:{color:'#8700AF',image:animal},
        flower:{color:'#ed2df0',image:flower}
    };
        
    const list= Object.entries(options).map(([option,value])=>({
        text:t(option),
        key:option,
        rightChildren:<css.checkbox className={ticketsIcon===option?'selected':''} {...value} />
    }));

    const optionsList={list,title:t('tickets'), onClick:({key})=>updateTicketsIcon(key), classes:'ticketsOptionsList'};
    return optionsList;
}

export default getTicketsIconList;
