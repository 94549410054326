import * as css from './SmsEditor.css';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import {useContext} from 'react';
import {BrandingContext} from '../BrandingPage';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import EditorWrapper from '../../../component/textEditor/editorWrapper/EditorWrapper';
import {updateEmailStatus} from '../../../redux/branding/brandingActions';
import {removeTagsFromText} from '../emailSubjectTextEditor/EmailSubjectTextEditor';

const SMS_MAX_CHAR=1800;
export const SMS_CHARS_ON_MESSAGE=70;
export const SMS_FREE_CHARS=200;

function SmsEditor({name,onSave}) {
    const statusFields ={
        smsContent:'smsTicket',
        smsEnterContent:'smsEnter'
    };
    const {t} = useTranslation('branding');
    const {textEditor}= useContext(BrandingContext);
    const branding = useSelector(s => s.branding);
    const currentLang = branding.currentLang;
    const smsValue = branding.data[currentLang]?.[name];
    const smsStatus = branding.data[currentLang]?.[statusFields[name]];
    const dependencies=[currentLang,branding.getBrandingData[`${currentLang}XHR`]];

    const updateStatus = async () => {
        await updateEmailStatus(statusFields[name], smsStatus===1?0:1);
    };
    
    const saveText = (text) =>{
        const textToSave = text.replace(/<\/p><p>/g, '\n');
        onSave(removeTagsFromText(textToSave));
    };

    const smsText = smsValue?.replace(/\n/g, '</p><p>');

    return (
        <>
            <css.top data-cy='smsEditor'>
                <css.text>{t(name)}</css.text>
                <ModeBtn className={'small'} status={smsStatus} click={updateStatus} dataCyName={'smsEditor'}/>
            </css.top>
            <EditorWrapper limit={{maxChar:SMS_MAX_CHAR,  showCharCounter:true,showMessagesAmount:true}} defaultText={!!smsText && `<p>${smsText}</p>`} showToolbar={false} dependencies={dependencies} events={{onSave: saveText,onFocus:()=>textEditor.setCurrentEditorName(name)}}   lang={currentLang}/>
        </>
    );
}

export default SmsEditor;