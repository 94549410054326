import styled from 'styled-components/macro';
import {animate} from '../../../../component/animation/Animation.css';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const wrap=styled.div`  
    top: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.upperModal};
    transition: all 500ms;
    opacity: 0;
    &.show{
        opacity: 1;
    }
`;

export const container = styled.div`
height: 100%;
div,span{
    color:#111;
}
${animate}{
    width: 120%;
    bottom: 20px;
    inset-inline-start: 10px;
}
padding: 50px;
position: relative;

`;
export const top=styled.div`
    height:70%;
    min-height: 250px;
`;
export const text=styled.div`  
    font-weight: 800;
    font-size: 30px;
    text-align: center;
`;

export const header=styled.div`  
    font-weight: 900;
    padding-top: 20px;
    font-size: 55px;
    line-height: 45px;
    text-align: center;
    position: relative;
    padding-bottom: 40px;

    `;

export const rectangle=styled.span` 
    position: relative;
    padding-inline-end: 4px;
          ::before{
            content: '';
            position: absolute;
            height: 10px;
            width: 10px;
            background-color: #3f8fb4;
            transform: rotate(45deg);
            inset-inline-end:-4px;
            top:14px;
    }
    `;

export const bottom=styled.div`  
    ${dFlexCenter}
    padding-top:60px;
`;

