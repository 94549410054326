import React, {useEffect, useRef, useState} from 'react';
import * as css from './InputList.css';
import List from '../../list/List';
import {useField} from 'formik';
import useShow from '../../../hooks/useShow';
import {useTranslation} from 'react-i18next';
import useMobile from '../../../hooks/useMobile';

function InputList({list=[], name, icon, text, onChange , isSelectable = false, className, t = v => v, children, valid, description = '', onFocus, multiSelect = false, width='100%'}) {
    const {open, close, isOpen, showClass} = useShow();
    const [field, meta, helpers] = useField(name);
    const {t:globalT}=useTranslation(['layout','global']);
    const click = (item) => {
        helpers.setTouched(true);
        if(multiSelect) {
            helpers.setValue([...new Set([...field.value, item.key])]);
        } else {
            helpers.setValue(item.key);
        }
        onChange && onChange(name, item.key);
        if(!isSelectable)
            close();
    };

    const openClose = () => {
        if(list?.length)
            if(isOpen)
                close();
            else
                open();
    };
    useEffect(() => {
        if(!list.length)
            close();
    }, [list.length]);

    const [search,setSearch]=useState('');
    const filteredList=list?.filter(item=>item.text.toString().toLowerCase().includes(search.toLowerCase()));
    if(!filteredList.length)
        filteredList.push({text:globalT('layout:notFoundText'),onClick:()=>setSearch('')});

    const onSearchChange=e=>{
        open();
        e.stopPropagation();
        setSearch(e.target.value);
    };
    const isLongList=list.length>7;
    const ref=useRef();
    useEffect(()=>{
        if(ref.current && isOpen)
            ref.current.focus();
    },[ref.current,isOpen]);

    useEffect(()=>{
        setSearch('');
    },[field.value]);

    const isMobile=useMobile();
    if(isLongList && isMobile && filteredList[0].key!=='inputListSearch')
        filteredList.unshift({text:'',key:'inputListSearch',
            leftChildren:<css.wrapMobileInput>{search||`${globalT('global:search')}...`}
                <input type='text' ref={ref} onChange={onSearchChange} onClick={e=>e.stopPropagation()}/>
            </css.wrapMobileInput>});

    return (
        <css.container data-cy= {`${name}Validation`} className={`${className}  ${(valid || !!meta.touched) && !isSelectable &&(meta.error ? 'invalid' : 'valid')} ${!list.length && ' disabled'}`}>
            <css.icon icon={icon} className={className}>
                {children}
            </css.icon>
            <css.wrap>
                <css.input
                    data-cy ={`${name}Input`}
                    onFocus = {onFocus}
                    onClick={openClose}
                    className={`inputList ${className}`}> 
                    {isLongList&&!isMobile&&<input type='text' ref={ref} onChange={onSearchChange}/>}
                    {search?search:(isSelectable ? text :t(list.find(i=>i.key===field.value)?.text)||'')}
                    {isOpen && <List close={close} onClick={click} list={filteredList} top={25} left={'1px'} showClass={showClass} width={width} t={t}/>}
                </css.input>
                <css.title>{!isSelectable && text}&nbsp;<span>{description}</span></css.title>
            </css.wrap>
        </css.container>
    );
}
export default InputList;