import * as css from './SendToEmail.css';
import {useTranslation} from 'react-i18next';
import {useField, useFormikContext} from 'formik';

function SendToEmail({onClick=()=>{}}) {
    const {t}=useTranslation('login');

    const {submitForm} = useFormikContext();
    const [,,helper]=useField('sendToEmail');

    const sendToEmail=()=> {
        helper.setValue(true);
        submitForm().then(onClick);
    };
    return (
        <css.toEmail id="emailIcon" onClick={sendToEmail} data-cy='emailCodeLogin'>
            <css.icon/>
            <span> {t('sendMeEmail')}</span>
        </css.toEmail>
        
    );
}

export default SendToEmail;