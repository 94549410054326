import styled from 'styled-components/macro';
import {mobileMedia, SHADOW_COLOR, WHITE} from '../../globalVariables/GlobalVariables';
import {circle, circleWrap, percent} from '../../pages/tickets/components/ticketRow/ticketProgress/TicketProgress.css';

export const progressWrapper = styled.div`
  height: 100px;
  position: absolute;
  bottom: 100px;
  left: 15px;
  ${mobileMedia}{
    bottom: 80px;
    zoom: 0.8;
  }
  ${circleWrap}{
    width: 120px ;
    height: 120px;
    box-shadow: 0 3px 6px ${SHADOW_COLOR};
    position: unset;
  }
  ${circle} {
    width: 75%;
    height: 75%;
    font-size: 26px;
    background-color: ${WHITE};
  }
  ${percent} {
    display: contents;
    font-size: 13px;
    height: 26px;
  }
`;
