import {useEffect, useState} from 'react';
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';

export const percentage = (totalValue,partialValue) =>( Math.round(((100 * partialValue) / (totalValue?totalValue:partialValue))||0));

function CircleProgress({partialValue=0, totalValue=0, styleProps,children}) {
    
    const [isShow, setIsShow] = useState(false);
    const styles = {
        ...buildStyles(styleProps?styleProps:{rotation: 0.45, pathColor: '#76D6E8', trailColor: '#DBDBDB',pathTransitionDuration:0.7}),
        trail: {strokeWidth: styleProps?.strokeTrailWidth|| 2}
    };

    useEffect(() => {
        setIsShow(true);
    }, []);

    return (
        <CircularProgressbarWithChildren
            value={isShow?percentage(totalValue,partialValue):0}
            strokeWidth={styleProps?.strokeWidth||5}
            background={false}
            counterClockwise
            styles={styles}>
            <>
                {children}
            </>
        </CircularProgressbarWithChildren>
    );
}

export default CircleProgress;
