import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Btn from '../../../component/button/btn/Btn';
import useNavigationLink from '../../../hooks/useNavigationLink';
import EventRolePermission from '../../../pages/components/rolePermission/EventRolePermission';
import * as css from './AccountVerificationMessage.css';
import VerificationItem from './verificationItem/VerificationItem';

function AccountVerificationMessage() {
    const account = useSelector(s=>s.account);
    const {t} = useTranslation('layout');
    const verificationPop = useNavigationLink('verificationPop', 'open');
    const openPop = ()=>{
        verificationPop.open();
    };

    return (
        <EventRolePermission rolePermission={'owner'} views={['manager']}>
            <css.verificationMessage onClick={(e)=>{e.stopPropagation();}} data-cy = "verificationMessage">
                <css.verificationMessageIn>
                    <css.verificationMessageBox>
                        <css.title>{t('verifyYourAccount')}</css.title>
                        <css.bigIcon />
                        <VerificationItem className={account.ownerEmailVerified===1?'verified':'unVerified'} text={t('emailVerification')} data-cy="emailVerification"/>
                        <VerificationItem className={account.termsVerified?'verified':'unVerified'} text={t('termsVerification')} data-cy="termsVerification"/>
                        <VerificationItem className={account.bankVerified?'verified':'unVerified'} text={t('bankAccountVerification')} data-cy="bankAccountVerification"/>
                        <Btn onClick={openPop} className={'white'}>{t('verify')}</Btn>
                    </css.verificationMessageBox>
                </css.verificationMessageIn>
            </css.verificationMessage>
        </EventRolePermission>
    );
}

export default AccountVerificationMessage;