import React, {useEffect, useRef, useState} from 'react';
import {DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';
import Btn from '../btn/Btn';
import * as css from './CountButton.css';

function CountButton( {className,children, count=16, callBack=()=>{}, style={circleColor:'#5652CC',backgroundColor:DEFAULT_COLOR}, onClick}) {
    const [seconds, setSeconds] = useState(count);
    const [progressBarDeg, setProgressBarDeg] = useState(90);
    const [progressBarColor, setProgressBarColor] = useState(style.backgroundColor);
    let secondsPercentage = 0;
    const timer = useRef(null);

    useEffect(() => {
        if(seconds > 0) {
            timer.current = setTimeout(function () {
                setSeconds(seconds - 1);
                secondsPercentage = (((count - seconds + 1) / count) * 100);
                if(secondsPercentage > 50) {
                    setProgressBarDeg((secondsPercentage - 50) * 180 / 50 + 90);
                    setProgressBarColor(style.circleColor);
                }
                else {
                    setProgressBarDeg(secondsPercentage * 180 / 50 + 90);
                    setProgressBarColor(style.backgroundColor);
                }
            }, 1000);
        }
        else {
            if(seconds === 0)
                callBack();
        }
    }, [seconds]);

    useEffect(() => () => clearTimeout(timer.current), []);

    return (
        <css.btnProgressBar progressBarDeg={progressBarDeg} progressBarColor={progressBarColor} typeCss={style}>
            <Btn  style={style} onClick={onClick} className={className}>
                {children}
                <css.space/>
                <css.Counter typeCss={style}>
                    {seconds}
                </css.Counter>
            </Btn>
        </css.btnProgressBar>
    );
}
export default CountButton;
