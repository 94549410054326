import styled from 'styled-components/macro';
import {mobileScreen, scrollbar, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';
export const BACKGROUND_COLOR='#FAFAFA';

export const Popup = styled.div`
  position: fixed;
  max-width: 95%;
  height: 100%;
  top: 0;
  ${props => (props.theme.rtl ? 'left' : 'right')}: -790px;
  width: 790px;
  background:${BACKGROUND_COLOR} ;
  transition: all 500ms;
  z-index: ${zIndex.basicModal};
  border-radius: ${props => (props.theme.rtl ? '0px 10px 10px 0px' : '10px 0px 0px 10px')};

  &.show {
    ${props => (props.theme.rtl ? 'left' : 'right')}: 0px;
  }

  @media screen and (max-width: ${mobileScreen}) {
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

export const Management = styled.div`
  float: ${props => (props.theme.rtl ? 'left' : 'right')};
  margin-top: 15px;
  ${props => (props.theme.rtl ? 'left' : 'right')}: 10px;
  position: absolute;
`;

export const accordions = styled.div`
    padding: 0 25px;
    height: calc(100% - 130px);
        ${scrollbar}   
    margin-top:20px;
    margin-bottom: 20px;
    &.showBanner{
        height: calc(100% - 250px);
    }
 	 ${mobileMedia} {
	    padding: 0 15px;
	
  	}
`;

