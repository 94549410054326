import * as css from './StepContext.css';

function StepContext({children}) {
    
    return (
        <css.context>
            {children}
        </css.context>
    );
}

export default StepContext;
