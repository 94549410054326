import React from 'react';
import './SwitcherButton.css';

const RtlCss = React.lazy(() => import('./SwitcherButton-rtl'));

export default function SwitcherButton(props) {
    if(!props.data || !props.data.active){
        return null;
    }
    let options = [{image: 'email', selected:true, type: ''},];
    let optionIndex = 0;
    options = options.concat(props.data.options);
    options.forEach(function (item, i) {
        if(item.type == props.type){
            options[i].selected = true;
            optionIndex = i;
        } else {
            options[i].selected = false;
        }
    });

    const image = options[2].selected ? options[2].image : options[1].image;
    return (
        <>
            {(props.lang === 'he') && <RtlCss />}
            <div className="switcher-button">
                <div className="switcher-button-option" option={optionIndex}>
                    <div className="option-1" onClick={()=> props.handle(options[1].type)}>{options[1].text}</div>
                    <div className="option-0" onClick={()=> props.handle(options[0].type)}><div className="option-image" image={image} imageColor="ffffff" />{!options[0].selected && <span>●</span>}</div>
                    <div className="option-2" onClick={()=> props.handle(options[2].type)}>{options[2].text}</div>
                </div>
            </div>
        </>
    );

}
