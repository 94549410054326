import * as css from './LoginPasswordInput.css';
import LoginInput from '../loginInput/LoginInput';
import {useState} from 'react';

function LoginPasswordInput(inputProps) {
    const [isPasswordSecure, setIsPasswordSecure] = useState(true);

    return (
        <css.passwordInput>
            <css.eye data-cy = 'eyeLogin' isPasswordSecure={isPasswordSecure} onClick={(e)=> {
                e.stopPropagation();
                setIsPasswordSecure(!isPasswordSecure);
            }}/>
            <LoginInput {...inputProps} type={isPasswordSecure?'password':'text'} />
        </css.passwordInput>
    );
}

export default LoginPasswordInput;
