import * as css from './LoginPhoneInput.css';
import {CountriesPhoneList} from '../../../../component/input/countriesPhoneList/CountriesPhoneList';
import LoginInput from '../loginInput/LoginInput';
import {useTranslation} from 'react-i18next';

function LoginPhoneInput() {

    const {t} = useTranslation('login');

    const onCountryChange = (selectedCountry) => {
        localStorage.setItem('user.dialCode', JSON.stringify(selectedCountry.code));
    };

    return (<>
        <css.mainContainer>
            <css.loginWrapper className='loginWrapper'>
                <LoginInput name="phone" text={t('phone')} description={t('phoneDesc')} type='tel' data-cy="phone" />
            </css.loginWrapper>
            <CountriesPhoneList t={t} fieldName={'country'} styleObj={css} onChange={onCountryChange} defaultCountry={JSON.parse(localStorage.getItem('user.dialCode'))} />
        </css.mainContainer>
    </>);
}

export default LoginPhoneInput;

