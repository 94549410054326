import styled from 'styled-components/macro';
import {Scrollbar} from '../../component/scrollbar/Scrollbar.css';
import zIndex from '../../globalVariables/zIndex';

export const packages=styled.div`
    position: absolute;
    background: #fff;
    width: 300px;
    top: 50px;
    z-index: ${zIndex.dropDown};
    right: calc(50% - 150px);
    box-shadow: 0px 13px 24px #00000029;
`;
export const PackagesEvents=styled(Scrollbar)`
    max-height:calc(${props=>props.theme.vh*100}px - 85px)
`;
export const MoreItems=styled.div`
    width: 100%;
    height: 30px;
    border-top: 1px solid #ececec;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %233a4143; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cg id='Component_154_1' data-name='Component 154 – 1' transform='translate(1.406 1.406)'%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-233.982 -21)'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center center;
`;