import * as css from './AccountVerification.css';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import Btn from '../../../../component/button/btn/Btn';
import Close from '../../../../component/close/Close';
import setGlobalState from '../../../../redux/setGlobalState';
import {getPackageBank} from '../../../../redux/account/accountActions';
import EventRolePermission from '../../../components/rolePermission/EventRolePermission';
import useNavigationLink from '../../../../hooks/useNavigationLink';

function AccountVerification({showBanner, setShowBanner}) {
    const account = useSelector(s=>s.account);
    const verified = account.bankVerified;
    const isManager = account.userAccountRole=='owner' || account.userAccountRole=='manager'? true : false;
    const {t} = useTranslation('package');
    const verificationPop = useNavigationLink('verificationPop', 'open');

    const closeBanner = () => {
        setShowBanner(false);
    };

    const handleMessage = () => {
        verificationPop.open();
    };

    useEffect(async () => { 
        await getPackageBank();  
    }, []);

    useEffect(async () => {
        if(account.sendPackageVerificationXHR=='SUCCESS'){
            if(account.bank)
                setGlobalState('account.bank.bankVerifiedSent',1);
            else {
                await getPackageBank();
                setGlobalState('account.bank.bankVerifiedSent',1);
            }
        }
    }, [account.sendPackageVerificationXHR]);

    return (
        <>
            {!verified?
                showBanner&&
                <EventRolePermission rolePermission={'owner'} views={['manager']}>
                    <css.verification>
                        <css.close><Close onClick={closeBanner} className='white'/></css.close>
                        <css.symbol />
                        <css.text>
                            <css.title>{t('verificationTitle')}</css.title>
                            <css.content>
                                {t('verificationText')}
                            </css.content>
                        </css.text>
                        <css.btn onClick={handleMessage}>
                            <Btn style= {`color: ${isManager?'#484848':'#48484830'};
                            background-color: ${isManager?'#fff':'#FFFFFF61'};
                            font-weight: 800;
                            border:none;
                            font-size: 12px;
                            :hover{
                                color:#76d6e8;
                            } 
                        `}
                            >
                                {t('verifyAccount')}
                            </Btn>
                        </css.btn>
                    </css.verification>
                </EventRolePermission>
                :
                <css.verifySymbol>
                    <css.verifiedTitle>{t('verifiedAccount')}</css.verifiedTitle>
                </css.verifySymbol>
            }
        </>
    );
}

export default AccountVerification;
