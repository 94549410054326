import styled from 'styled-components';
import {mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import {amount} from '../../../../../component/input/inputAmount/InputAmount.css';

export const boldText=styled.div`
    font-weight:700 ;
`;

export const link=styled.div`
    font-weight:700 ;
    color:#76D6E8 ;
    width:max-content ;
    border-bottom:2px solid #76D6E8;
`;

export const wrapAmount=styled.div`
    display:flex ;
    flex-direction:column ;
    padding-inline-start:25px;
    justify-content:center ;
    padding-top:8px ;
`;
export const wrapInput = styled.div`
   width:60% ;
   ${mobileMedia}{
     width:100% ;
     padding:0 ;
    }
    &.empty{
        ${amount}{
            display:none;
        }
    }
    ${amount}{
        inset-inline-end:calc(100% - 42px - ${p=>p.length*9}px);
    }
`;