import createReducer from '../createReducer';

const initialState = {
    ratings:[],
    currentRating:{
        rating:{},
        show:false
    },
    getRatingsXHR:null,
};

const reducer = createReducer('ratings',initialState);

export default reducer;