import styled from 'styled-components/macro';
import realtimeFonts from '../../../fonts';
import {BGImageCenter, dFlexCenter, ellipsisLines} from '../../../../../globalVariables/GlobalVariables';
import {grayClock, location, locationWhite, sunset, whiteClock, wind} from '../../../../../assets/symbols/symbols/Symbols.css';

export const wrapper = styled.div`
  padding: 20px;
  text-align: center;
  display: grid;
  height: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "title link"
                            "weather weather"
                            "weatherDetails temperature";
`;

export const title = styled.div`
  grid-area: title;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${realtimeFonts.windowTitle};
  width: 175%;
`;
export const link = styled.div`
  grid-area: link;
`;
export const weather = styled.div`
  grid-area: weather;
  ${realtimeFonts.text};
  ${dFlexCenter};
  flex-direction: column;
`;
export const temperature = styled.div`
  grid-area: temperature;
  ${realtimeFonts.mainDataBold};
  font-weight: bold;
  justify-self: self-end;
  align-self: self-end;
  margin-inline-end: 25px;
  margin-bottom: 9px;
`;
export const weatherDetails = styled.div`
  grid-area: weatherDetails;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
`;
export const locationText = styled.div`
  ${realtimeFonts.text};
  display: inline-flex;
  p{
    ${ellipsisLines(2)}
  }
`;
export const locationIcon = styled.div`
    ${p=>BGImageCenter(p.theme.isDarkMode?locationWhite: location, '13px 20px')};
    width: 42px;
    height: 20px;
`;
export const weatherIcon = styled.img`
  width: 100px;
  height: 100px;
`;
export const weatherDetail = styled.div`
    display: inline-flex;
    column-gap: 5px;
`;
export const clockIcon = styled.div`
    ${p=>BGImageCenter(p.theme.isDarkMode?whiteClock:grayClock, '13px 20px')};
    width: 17px;
    height: 20px;
`;
export const windIcon = styled.div`
    ${BGImageCenter(wind, '13px 20px')};
    width: 17px;
    height: 20px;
`;
export const sunsetIcon = styled.div`
    ${BGImageCenter(sunset, '13px 20px')};
    width: 17px;
    height: 20px;
`;
// export const cloudIcon = styled.div`
//     ${BGImageCenter(clock, '13px 20px')};
//     width: 17px;
//     height: 20px;
// `;