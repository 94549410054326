import thunk from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import rootReducer from './redux/reducers';
import {composeWithDevTools} from 'redux-devtools-extension';
import {NODE_ENV} from './config/config';

const middleware = [thunk];

let store;

if(NODE_ENV==='local'){
    const composeEnhancers = composeWithDevTools({trace: true,traceLimit:25});
    store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middleware))
    );
}
else
    store = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(...middleware))
    );

export default store;