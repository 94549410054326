import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './Details.css';
import CategoryList from '../../components/categoryList/CategoryList';
import setGlobalState from '../../../redux/setGlobalState';
import {updateEvent} from '../../../redux/event/eventActions';
import {Form} from 'formik';
import Input from '../../../component/input/Input.js';
import Btn from '../../../component/button/btn/Btn';
import TextareaInput from '../../../component/input/textareaInput/TextareaInput';
import * as Yup from 'yup';
import {getBudgetItems} from '../../../redux/account/accountActions';
import InputList from '../../../component/input/inputList/InputList';
import {getTs, tsGetYear} from '../../../library/dates';
import FormContext from '../../components/formContext/FormContext';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';
import GoogleMapsPop from '../../../component/googleMapsPop/GoogleMapsPop';
import useShow from '../../../hooks/useShow';
import DisabledInput from '../../../component/input/disabledInput/DisabledInput';
import {useSelector} from 'react-redux';

function Details() {
   
    const event = useSelector(state => state.event);
    const updateEventDetailsXHR = event.updateEventDetailsXHR;
    const ref = useRef(null);
    const {t} = useTranslation('layout');
    const openGoogleMaps=useShow();

    const getValue = (values)=>{
        if(!values.locationMap?.title)
            return values.location; 
        else
            return `${values.location}, ${values.locationMap.title}`;
    };

    useEffect(() => {
        if(updateEventDetailsXHR !== 'LOADING')
            setTimeout(() => {
                setGlobalState('event.updateEventDetailsXHR', null);
            }, 2000);
    }, [updateEventDetailsXHR]);

    const initialValues = {
        title: event.title,
        category: event.category||0,
        location: event.location,
        locationMap :event?.locationMap && {
            ...event?.locationMap,
            lat: Number(event?.locationMap?.lat),
            lng: Number(event?.locationMap?.lng)

        },
        timeStart: tsGetYear(event.timeStart),
        description: event.description,
        budgetIndex: event.budgetIndex
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().max(90),
        timeStart: Yup.number().min(2000).max(2037)
    });

    const onSubmit = async (_changedValues,values) => {
        let timeStart = new Date(values.timeStart, 0, 1);
        timeStart = getTs(timeStart);
        const fields = {...values, timeStart};
        if(fields.category===0)
            fields['category']=null;
        if(event.type === 'course'){
            fields.timeEnd = getTs(new Date(values.timeStart, 11, 31));
        }
        updateEvent(fields);
    };
    const budgetItems = useSelector(state => state.account.budgetItems);

    let budgetList = budgetItems?.map(i => ({text: i.budgetTitle, key: i.budgetIndex}));
    budgetList?.push({key: null, text: t`withoutBudget`});
    useEffect(() => {
        if(!budgetItems?.length)
            getBudgetItems();
    }, []);
 
    return (
        
        <EventRolePermission rolePermission={'manage event'}>
            <FormContext
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={onSubmit}
            >{({values})=>
                    <Form>          
                        {/* <css.detailTitle>{t(`${event.type}Details`)}</css.detailsTitle> */}
                        <css.detailsFrame >
                            <Input name='title' text={t(`${event.type}Name`)} icon="event" />
                            <CategoryList />
                            <Input name='timeStart' text={t`workYear`} icon='calendar' />
                            <DisabledInput value={getValue(values)} text={t('location')} icon='location' events={{onClick:()=>openGoogleMaps.open()}}/>
                            <TextareaInput name='description' icon='feather' limit={90} height="40px" text={t`description`} />
                            <InputList name='budgetIndex' list={budgetList} icon='nisBell' text={t('budgetAssignment')} />
                        </css.detailsFrame>
                        <css.saveBtn>
                            <Btn className={updateEventDetailsXHR} onClick={() => { if(ref.current) ref.current.handleSubmit(); }} >{t('save')}</Btn>
                        </css.saveBtn>  
                        {openGoogleMaps.isOpen && <GoogleMapsPop openGoogleMaps={openGoogleMaps} locationName={'location'} locationMapName={'locationMap'}/>}               
                    </Form>}
            </FormContext>
        </EventRolePermission>
    );
}

export default Details;
