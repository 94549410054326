import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as css from './Timer.css';
import {getTs} from '../../../../library/dates';
import {useTranslation} from 'react-i18next';

function Timer() {
    const {timeStart , timeEnd} = useSelector((state: any) => state.event);
    const [timeLeft, setTimeLeft] = useState('');
    const [text, setText]= useState('toStartEvent');
    const {t} = useTranslation('realtime');
    const getTime = (time) =>{
        return time < 10 ? `0${time}` : time;
    };
    useEffect(() => {
        const timer = setInterval(() => {
            const now = getTs() * 1000;
            let countdownTime = 0;

            const eventStartMs = timeStart * 1000;
            const eventEndMs = timeEnd * 1000;

            if(now < eventStartMs) {
                countdownTime = eventStartMs;
            } else if(now >= eventStartMs && now < eventEndMs) {
                setText('toEndEvent');
                countdownTime = eventEndMs;
            } else {
                clearInterval(timer);
                setTimeLeft('00 : 00 : 00 : 00');
                return;
            }

            const distance = countdownTime - now;
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimeLeft(`${getTime(days)} : ${getTime(hours)} : ${getTime(minutes)} : ${getTime(seconds)}`);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeStart, timeEnd]);

    return (
        <css.wrapTimer>
            <css.wrap>
                {/* <css.title>{t(text)}</css.title> */}
                <css.timer>
                    {timeLeft}
                </css.timer>
            </css.wrap>
            {/*<css.formats>*/}
            {/*    {t('timerFormat')}  */}
            {/*</css.formats>*/}
        </css.wrapTimer>
    );
}

export default Timer;
