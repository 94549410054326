import React from 'react';
import * as css from './GogleWalletBtn.css';

function GoogleWalletBtn() {
    return (   
        <css.btn>     
            <css.text>{'Google Wallet'}</css.text>       
            <css.icon />
        </css.btn>
    );
}

export default GoogleWalletBtn;
