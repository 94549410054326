import styled from 'styled-components/macro';

export const topClose = styled.div`
    position:absolute ;
    top:10px;
    inset-inline-start:10px;
    height:30px ;
    width:30px ;
    border-radius: 50%;
    background-color:rgba(255,255,255,0.4) ;
     transition:all 300ms ;
    :hover{
        background-color: #FFF ;
        & .white{
            background-color: #222;
        }
    }
    ${p=>p.styleCss||''}
`;