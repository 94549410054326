import React from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './CourseSection.css';
import EyeHideSection from '../../../components/eyeHideSection/EyeHideSection';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getEventTickets} from '../../../../redux/tickets/ticketsActions';
import {clock, location, totalFutureIcon} from '../../../../assets/symbols/symbols/Symbols.css';
import formatPhone from '../../../../library/formatPhone';
import {defaultImage} from '../../../../assets/symbols/images/defaultImage';

function CourseSection() {
    const {t:globalT} = useTranslation('branding');
    const t = key => globalT(`branding:${key}`);
    const tickets=useSelector(s=>s.tickets);
    const user = useSelector(s => s.user);
    const {currencySymbol,location:eventLocation} = useSelector(s => s.event);

    const course=tickets.tickets[0]||{};

    useEffect(()=>{
        if(!tickets.getEventTicketsXHR)
            getEventTickets().then();
    },[]);
    
    const guides=course.users;
    const guide = course?.users?.length === 1 ? course?.users[0] || user : false;
    const {start='10:20',duration=45,day=3} =(course.course?.meetings?.[0]||{});
    const date=`${globalT(`global:day${day||''}`)} ${start||''}`;
    
    return (  
        <css.wrapper>
            <img src={course.imgData?(JSON.parse(course.imgData).path):defaultImage}/>
            <css.left>
                <css.title>{course.title||globalT('ticket:courseNameExample')}</css.title>
                {!course?.hideScheduled && <css.details>
                    <css.rightText>
                        <b>{t('meetings')}&nbsp;</b>
                        {date}
                    </css.rightText>
                    <css.icon icon={clock}/>
                    {`${t('meet')}: ${duration || ''} ${t('minutes')}`}
                </css.details>}
                <EyeHideSection sectionName='location'>
                    <css.details>
                        <css.rightText>
                            <css.icon className='location' icon={location}/>
                            {eventLocation||'<location>'}
                        </css.rightText>
                        <css.icon icon={totalFutureIcon}/>{currencySymbol}1200/12 | {currencySymbol}0
                    </css.details>
                </EyeHideSection>
                {!course?.hideGuide&&<EyeHideSection sectionName={'guide'}>
                    {(Array.isArray(guides) && guides.length > 1) ? guides?.slice(0, 3)?.map((guide) =>
                        <css.guide key={guide.uid}>
                            <css.guideImage image={guide.picture}/>
                            <div>
                                <b>{`${t('guided')} ${guide.nameFirst} ${guide.nameLast}`}</b>
                                <div>{formatPhone(user.phone)}</div>
                            </div>
                        </css.guide>
                    ) : !!guide && <css.guide key={guide.uid}>
                        <css.guideImage image={guide.picture}/>
                        <b>{`${t('guided')} ${guide.nameFirst} ${guide.nameLast}`}</b>&nbsp;{formatPhone(user.phone)}
                    </css.guide>}
                </EyeHideSection>}
            </css.left>
        </css.wrapper>
    );
}

export default CourseSection;
