function ExtractContent(string, tag) {
    const regex = new RegExp(`<${tag}>((.|\n)*?)<\/${tag}>`);
    const match = string?.match(regex);
    return match ? match[1] : '';
}

function RemoveTagContent(string, tags = ['script', 'style']) {
    if(!string)
        return string;
    let result = string;
    tags.forEach(tag => {
        const regex = new RegExp(`<${tag}>[\\s\\S]*?<\\/${tag}>`, 'gi');
        result = result.replace(regex, '');
    });
    return result;
}

export {ExtractContent, RemoveTagContent};
