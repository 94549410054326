import {useField} from 'formik';
import BasicInput from '../basicInput/BasicInput';
import * as css from './TextareaInput.css';

function TextareaInput({name, text , description , icon,limit=70, events, height = '60px'}) {
    const [field]=useField(name);

    return (
        <css.container className={name} height={height}>
            <BasicInput name={name}
                text={text}
                description={description}
                icon={icon}
                events={events}
                types={{fieldType:'textarea',inputType:'text'}} />
            <css.charNum>{field.value?.length}/{limit}</css.charNum>
        </css.container>
    );
}

export default TextareaInput;
