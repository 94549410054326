import * as css from './FirstScreenDesign.css';
import {useTranslation} from 'react-i18next';
import ImageType from './ImageType';
import {uploadImage} from '../../../assets/symbols/images/uploadImage';
import {createImage} from '../../../assets/symbols/images/createImage';

function FirstScreenDesign({setCurrentScreen,setImage}) {

    const {t} = useTranslation('event');
    
    return (
        <>
            <css.container>
                <css.wrapTexts>
                    <css.title>{t('imagePopTitle')}</css.title>
                    <css.description>{t('imagePopDesc')}</css.description>
                </css.wrapTexts>
                <css.wrapImage>
                    <ImageType setImage={setImage} icon={uploadImage} text={t('uploadImageText')} btnText={t('uploadImageBtn')} onClick={()=>setCurrentScreen(1)}/>
                    <ImageType icon={createImage} text={t('createImageText')} btnText={t('createImageBtn')} onClick={()=>setCurrentScreen(2)} hasButton={true}/>
                </css.wrapImage>
            </css.container>
        </>
    );
}

export default FirstScreenDesign;
