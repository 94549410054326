import * as css from './googleMapsPop.css';
import {useTranslation} from 'react-i18next';
import React from 'react';
import GoogleMaps from '../googleMaps/GoogleMaps';
import BottomPopup from '../newPopup/bottomPopup/BottomPopup';
import Btn from '../button/btn/Btn';
import CenteredPopup from '../centeredPopup.js/CenteredPopup';
import {Form, Formik, useField} from 'formik';
import {useRef} from 'react';

function GoogleMapsPop({openGoogleMaps,locationName,locationMapName}) {
    const {t} = useTranslation('global');

    const [fieldLocation,,helpersLocation]=useField(locationName);
    const [fieldLocationMap,,helpersLocationMap]=useField(locationMapName);

    const formRef = useRef();

    const onSubmitForm = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const save =(values)=>{
        helpersLocation.setValue(values.locationTemp);
        helpersLocationMap.setValue(values.locationMapTemp);
        openGoogleMaps.close();
    };

    return (
        <Formik 
            innerRef={formRef}
            initialValues={{locationTemp:fieldLocation.value||'',locationMapTemp:fieldLocationMap?.value||''}}
            onSubmit={save}
        >
            <Form>
                <css.wrapper>
                    <CenteredPopup {...openGoogleMaps} title={t('location')}>
                        <>
                            <css.content> 
                                <GoogleMaps locationMapName={'locationMapTemp'} place={{placeName:'locationTemp',placeText:t('location')}} 
                                    mapStyle={{width:'100%' ,height:'calc(100% - 157px)'}} inputStyle='padding: 25px 15px;'/>
                            </css.content> 
                            <BottomPopup>
                                <Btn onClick={onSubmitForm}>
                                    {t('edit')}
                                </Btn>
                            </BottomPopup>
                        </>
                    </CenteredPopup>
                </css.wrapper>
            </Form>                
        </Formik>
    );
}

export default GoogleMapsPop;