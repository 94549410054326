import {AsYouType} from 'libphonenumber-js';
const LOCAL_COUNTRY = 'IL';

export function initPhoneNumber({countryCallingCode, nationalNumber, internationalNumber, country}){
    try {
        if(internationalNumber && !internationalNumber.toString().startsWith('+')){
            internationalNumber='+'+internationalNumber;
        }
        if(!(nationalNumber || internationalNumber)) {
            
            return {country: country || LOCAL_COUNTRY};
        }
        let newNumber = new AsYouType(country || countryCallingCode || LOCAL_COUNTRY);
        newNumber.input(String(nationalNumber || internationalNumber || ''));
        let newFullNumber = newNumber?.getNumber();
        if(newFullNumber?.country === LOCAL_COUNTRY && newFullNumber[0] !== '0') {
            newFullNumber.nationalNumber = 0 + newFullNumber.nationalNumber;
        }
        if(newFullNumber.number[0] === '+') {
            newFullNumber.number = newFullNumber.number.substring(1);
        }
        return {...newFullNumber, internationalNumber: newFullNumber.number} || {country: country || LOCAL_COUNTRY};
    } catch (err) {
        return {country: country || LOCAL_COUNTRY};
    }
}