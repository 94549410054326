import * as css from './QueueProcessStatus.css';
import TicketProgress from '../../pages/tickets/components/ticketRow/ticketProgress/TicketProgress';
import Cookies from 'js-cookie';
import {ReactNode} from 'react';

export const getTaskState = (time, action, id) => {
    const cookieValue = Cookies.get('tickchakQueue');
    if(cookieValue) {
        const queueId = (JSON.parse(cookieValue))[id];
        if(queueId){
            action().then(res=>{
                if(res?.success){
                    const dataObject = JSON.parse(cookieValue);
                    delete dataObject[id];
                    if(Object.keys(dataObject).length === 0) {
                        Cookies.remove('tickchakQueue');
                    } else {
                        Cookies.set('tickchakQueue', JSON.stringify(dataObject));
                    }
                }
                else {
                    setInterval(() => {
                        getTaskState(time, action, id);
                    }, time);
                }
            }
            );
        }
    }
    
};

function QueueProcessStatus ({children, amount, sold}:{children: ReactNode, amount: number, sold: number}) {

    return <>
        <css.progressWrapper>
            <TicketProgress ticket={{color: '76D6E8', amount, sold}} onClick={()=>{}} styleProps={{strokeTrailWidth: '0'}} >
                {children}
            </TicketProgress>
        </css.progressWrapper>
    </>;
}
export default QueueProcessStatus;