import styled from 'styled-components/macro';
import {YELLOW} from '../../../globalVariables/GlobalVariables';

export const buttonNo = styled.div`
 background-color: ${YELLOW};
 padding-block: 7px;
 padding-inline-end: 15px;
 padding-inline-start: 6px;
 border-radius: 30px;
 display: flex;
 cursor: pointer;
 align-items: center;
 margin: 0 auto;
 width: max-content;
`;
export const buttonNoText = styled.div`
 padding-inline-start: 6px;
 font-weight: 600;
`;

