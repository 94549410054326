import {ReactNode, useEffect, useState} from 'react';
import * as css from './window.css';
import Link from '../../../../component/link/Link';
import MobilePopup from '../../../../component/newPopup/mobilePopup/MobilePopup';
import useShow from '../../../../hooks/useShow';
import {Else, If, Then} from 'react-if';
import useMobile from '../../../../hooks/useMobile';
import EventRolePermission from '../../../components/rolePermission/EventRolePermission';
import List from '../../../../component/list/List';
import {useTranslation} from 'react-i18next';
import {screen, hideEye} from '../../../../assets/symbols/symbols/Symbols.css';
import useRealtimeGrid, {windowsSettings} from '../../useRealtimeGrid';
import {LayoutIcon} from '../LayoutIcon/LayoutIcon';

export type WindowPropsType = {
    gridArea: string;
    link?: {onClick?: () => void, text: string, href?: string};
    explainComp?: ReactNode;
    howArrivedComp?: ReactNode;
    howArrivedTitle?: string;
    children?: ReactNode;
    role: any;
    height?: string;
    draggable?: boolean;
    deployable?: boolean;
}
export default function Window({deployable = true, draggable = true, gridArea, link = null, explainComp = null, howArrivedComp = null, howArrivedTitle, children = null, role, height = null}: WindowPropsType) {
    const [openComponent, setOpenComponent] = useState('');
    const EXPLAIN_COMPONENT ='explainComponent';
    const HOW_ARRIVED_COMPONENT='howArrivedComponent';
    const show = useShow();
    const isMobile = useMobile();
    const isOnArrived = openComponent === HOW_ARRIVED_COMPONENT;
    const isOnExplain = openComponent === EXPLAIN_COMPONENT;
    const {open, close, isOpen, showClass} = useShow(500);
    const {t} = useTranslation('realtime');
    const {updateWindowSize, getShowWindow, showHideWin} = useRealtimeGrid();
    const showWindow = getShowWindow(gridArea);
    
    const [openLayout, setOpenLayout] = useState(false);
    useEffect(()=>{
        if(isMobile){
            if(openComponent) { show.open(); }
            else {show.close();}
        }
    },[openComponent]);

    const hideWindow = () => {
        showHideWin(gridArea, false);
    };
    const arrowIntoClick = () => {
        setOpenLayout(!openLayout);
    };
    const onLayoutClick = ({key}) => {
        updateWindowSize(gridArea, key);
    };
    const getOption = (size) => ({
        key: size,
        title: (size/12*100).toFixed()+ '%', 
        rightChildren: <LayoutIcon percent={size/12*100} />,
        classes:'layoutItem', onClick:onLayoutClick, disabled: (windowsSettings[gridArea].minSize > size)

    });
    const moreWindowActions = [
        {key: 'hideWindow', text: t('hideWindow'), icon: hideEye, onClick:hideWindow},
        !isMobile && {key: 'changeDeployment', title: t('changeLayout'), icon: screen, list: [], classes: 'layoutParent'+(openLayout?' open':'')},
        ...(openLayout?[
            getOption(4),
            getOption(6),
            getOption(8),
            getOption(12)
        ]:[])
    ];
    
    return (
        showWindow && children ?  <EventRolePermission { ...role} views={[]}>
            <css.windowWrapper className={(draggable?'draggable ':' ')+gridArea} isOpen={openComponent} gridArea={gridArea} height={height}>
                <css.windowHeader openLayout={openLayout}>
                    {link && <Link href={link.href} isBlank={false}>
                        <css.linkButton onClick={link.onClick} className= 'free-color' >
                            {link.text}
                        </css.linkButton>
                    </Link>
                    }
                    {howArrivedComp && <css.howArrivedText onClick={()=>setOpenComponent(HOW_ARRIVED_COMPONENT)}>{howArrivedTitle}</css.howArrivedText>}
                    {explainComp &&  <css.questionIcon className='free-color' onClick={()=>setOpenComponent(EXPLAIN_COMPONENT)}>?</css.questionIcon>}
                    {deployable && <css.moreActionsIcon className="disabled" onClick={open} />}
                    {isOpen && <List arrowIntoClick={arrowIntoClick} classes="free-color" close={()=>{close(); setOpenLayout(false);}} t={t} showClass={showClass} title={t('additionalActions')} list={moreWindowActions} top={5} left={'0'}/>}
                </css.windowHeader>
                {children}
                <If condition={isMobile}>
                    <Then>
                        {show?.isOpen && <MobilePopup title={isOnArrived? howArrivedTitle:''} showClass={show?.showClass} close={() => setOpenComponent('')} dataCyName="realtimeWindowExplanation" list={undefined} forwardRef={undefined}>
                            <css.explainContainer isOpen={openComponent}>
                                {openComponent && <>
                                    <css.explainWindowContent>
                                        {isOnExplain ? <>
                                            <css.questionIconMobile className="free-color">?</css.questionIconMobile>
                                            {explainComp}
                                        </>
                                            :isOnArrived ? howArrivedComp
                                                :''}
                                    </css.explainWindowContent>
                                </>}
                            </css.explainContainer>
                        </MobilePopup>}
                    </Then>
                    <Else>
                        <css.explainContainer isOpen={openComponent}>
                            <css.explainWindowTitle>
                                <css.backArrow onClick={()=>setOpenComponent('')}>→</css.backArrow>
                                {isOnArrived && howArrivedTitle}
                            </css.explainWindowTitle>
                            <css.explainWindowContent>
                                {isOnExplain?explainComp:''}
                                {isOnArrived?howArrivedComp:''}
                            </css.explainWindowContent>
                        </css.explainContainer>
                    </Else>
                </If>
            </css.windowWrapper>
        </EventRolePermission>
            :<></>
    );
}

