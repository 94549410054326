import {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import isScrollEnd from '../../../../functions/isScrollEnd';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import {updateAccount} from '../../../../redux/account/accountActions';
import setGlobalState from '../../../../redux/setGlobalState';
import Btn from '../../../../component/button/btn/Btn';
import * as css from './TermsApproval.css';
import {heTermsOfUse, enTermsOfUse} from './termsOfUseText';

function TermsApproval() {

    const securityT=useTranslation('security');
    const {t}=useTranslation('login');
    const {updateAccountXHR,termsVerified}=useSelector(s=>s.account);

    const [_isScrollEnd,setIsScrollEnd]=useState(false);
    const onScroll=e=>{
        if(isScrollEnd(e,25))
            setIsScrollEnd(true);
    };

    const onAccept=()=>{
        if(_isScrollEnd)
            updateAccount('termsVerified',true);
        else
            bounceMessage(securityT.t('termMessage'));
    };
    useEffect(()=>{
        if(updateAccountXHR)
            setGlobalState('account.updateAccountXHR',null);
    },[]);
    return (
        <css.wrap>
            <css.regulation onScroll={_isScrollEnd?null:onScroll}>
                <css.regulationTitle>{t('termsOfUse')}</css.regulationTitle>
                {securityT.i18n.language==='he'?heTermsOfUse:enTermsOfUse}
            </css.regulation>
            <css.acceptBtn>
                <Btn onClick={onAccept} className={termsVerified?'SUCCESS':updateAccountXHR} style={`width:100px;${_isScrollEnd?'':'opacity:0.5;'}`}>
                    {securityT.t('accept')}
                </Btn>
            </css.acceptBtn>
        </css.wrap>

    );

}

export default TermsApproval;
