import {useTranslation} from 'react-i18next';
import Tooltip from '../../../../component/tooltip/Tooltip';
import * as css from './ParticipantOptions.css';

function ParticipantOptions({openAttendance}) {
 
    const {t} = useTranslation('attendance');

    return (
        <css.wrapOptions>
            <Tooltip text={t('report')}> <css.iconParticipant onClick={openAttendance.open}  /></Tooltip>
        </css.wrapOptions>
    );
}

export default ParticipantOptions;
