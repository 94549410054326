import {useTranslation} from 'react-i18next';
import * as css from './MonthlyPrice.css';
import * as cssPlus from '../coursePopupContent/CoursePopupContent.css';
import GeneralItem from '../../../../../component/generalItem/GeneralItem';
import {FieldArray, useField} from 'formik';
import {useSelector} from 'react-redux';
import {remove} from '../../../../../assets/symbols/symbols/Symbols.css';
import BasicItem from '../../../../../component/basicItem/BasicItem';
import Tooltip from '../../../../../component/tooltip/Tooltip';
export let priceArrayHelpers={};

function MonthlyPrice({setItemPrice, setPricePop}) {

    const {t} = useTranslation('ticket');
    const currencySymbol = useSelector(state => state.event.currencySymbol);
    const event = useSelector(s => s.event);
    const [pricesField,] = useField('prices');
    let prices = pricesField.value;

    const listOptions = [{key: 'delete', text: t('delete'), type: 'icon', icon: remove , dataCyName:'delete'}];

    const onClickOptionsList = (item, index) => {
        switch (item.key) {
            case 'delete':
                priceArrayHelpers.remove(index);

                break;
            default:
                break;
        }
    };

    const onClickPrice = (index) => {
        setItemPrice(index);
        setPricePop(1);
    };

    return (
        <>
            <css.text>{t(event.type + 'PriceTitle')}</css.text>
            <css.wrapPrice>
                <FieldArray
                    name="prices"
                    render={(arrayHelpers) =>
                    {//{ insert, remove, push }
                        priceArrayHelpers=arrayHelpers;
                        return <>
                            {!prices?.length && <BasicItem onClick={() => onClickPrice(null)} icon={'person'} name={'prices'}>
                                <css.wrapText data-cy="addPrice">{t('addPrice')}</css.wrapText>
                            </BasicItem>}
                            {prices?.map((item, index) => {
                                return <GeneralItem key={item.tpid} icon={'person'} onClick={() => onClickPrice(index)}  listOptions={{list: listOptions, onClickList: (item) => onClickOptionsList(item, index)}} dataCyName="monthlyPrice">
                                    <css.values data-cy="priceTitle">{item.title}</css.values>
                                    <css.values data-cy="monthlyPriceAmount">{item.price + ' ' + currencySymbol}</css.values>
                                </GeneralItem>;
                            })}
                            {!!(prices?.length && event.type === 'course') && <Tooltip text={t('addPrice')}><css.wrapPlus><cssPlus.plus onClick={() => onClickPrice(null)} /></css.wrapPlus></Tooltip>}
                        </>;
                    }
                    }
                />
            </css.wrapPrice></>
    );
}

export default MonthlyPrice;