import styled from 'styled-components/macro';
import {excel} from '../../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';

export const icon=styled.div`
    ${BGImageCenter(excel, '13px')}
    position:relative ;
    width:17px;
    height:17px;
    margin-inline-end: 5px;
`;
export const backGroundBtn=styled.div`
    ${btn}{
        background-color:white;
        border-color: black;
    }
`;
