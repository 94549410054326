import createReducer from '../createReducer';

const initialState = {
    allCurrencies:[],
    allLanguages:[],
    allSuppliers:[],
};

const reducer = createReducer('supplier',initialState);

export default reducer;