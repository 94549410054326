import {useEffect} from 'react';
import useShow from '../hooks/useShow';

function ChildrenLoader({xhr ,children}) {

    const show=useShow();
    useEffect(()=>{
        if(xhr==='LOADING')
            show.open();
        else if(show.isOpen)
            show.close();
    },[xhr]);
    
    if(!show.isOpen)
        return <></>;

    return (
        <>
            {typeof children==='function'?
                children(show):
                children}
        </>
    );
}

export default ChildrenLoader;
