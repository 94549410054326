import {useTranslation} from 'react-i18next';
import * as css from './NewLead.css';
import * as Yup from 'yup';
import {useContext, useRef} from 'react';
import FormContext from '../../components/formContext/FormContext';
import {Form} from 'formik';
import Btn from '../../../component/button/btn/Btn';
import Input from '../../../component/input/Input';
import LeadInformation from '../leadInformation/LeadInformation';
import {addLead, updateLead} from '../../../redux/publish/leadActions';
import PhoneInput from '../../../component/input/phoneInput/PhoneInput';
import {initPhoneNumber} from '../../../functions/intrernationalPhoneNumber';
import validator from 'validator';
import {TableContextValue} from '../../components/tableContext/TableContext';
import {useSelector} from 'react-redux';
import useRestartXHR from '../../../hooks/useRestartXHR';
import useTimeout from '../../../hooks/useTimeout';
import {yupStringMin} from '../../../functions/validationsYup';

function NewLead({show, lead = {}}) {

    const {t} = useTranslation('publish');
    const formRef = useRef();
    const {langloc} = useSelector((s) => s.user);
    const language = langloc?.split('_')[1].toUpperCase() || 'IL';
    const {scrollToBottom}= useContext(TableContextValue);
    const {addLeadXHR} = useSelector(s=>s.publish);

    const initialValues = {
        ref: lead?.ref || '',
        title: lead?.title || '',
        countryCode: language,
        countryCodeSecond: language,
        contactName: lead?.contactName || '',
        contactEmail: lead?.contactEmail || '',
        contactPhone: lead?.contactPhone || '',
        contactPhoneSecond: lead?.contactPhoneSecond || '',
    };

    Yup.addMethod(Yup.mixed, 'phone', function(message, countryField) {
        return this.test('phone', message, function(value) {
            if(!value) {
                return true;
            }
            const country = this.resolve(Yup.ref(countryField));
            const local = validator.isMobilePhoneLocales.find(local => local.includes(country));
            const phone = initPhoneNumber({nationalNumber: value, country: country});
            return validator.isMobilePhone(value || '', local) || validator.isMobilePhone('+'+phone.internationalNumber || '', local);
        });
    });

    const validationSchema = Yup.object().shape({
        title: yupStringMin().required().matches(/(\S.*\S)/),
        contactName: yupStringMin().matches(/(\S.*\S)/),
        contactEmail: Yup.string().email(),
        contactPhone: Yup.string().phone(t('numberMessage'), 'countryCode'),
        contactPhoneSecond: Yup.string().phone(t('numberMessage'), 'countryCodeSecond')
    });
    useRestartXHR(addLeadXHR,'publish.addLeadXHR');
    const timer = useTimeout();

    const onSubmit = async (_changedValues,values) => {
        if(lead?.ref){
            await updateLead(lead?.ref,values).then(show.close);
        }
        else {
            await addLead(values).then(()=>{
                timer.current=setTimeout(() => {
                    show.close();
                },1000);   
                scrollToBottom();
            });
        }
    };

    const handleSubmit = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <>
            <css.wrapper data-cy="NewLead">
                <FormContext
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                >
                    <Form>
                        <css.wrapInput>
                            <Input name={'title'} text={t('theLeadTitle')} description={t('popupDesc')} icon={false}/>
                            <Input name={'contactName'} text={t('contactName')} description={t('popupDesc')} icon={false}/>
                            <Input name={'contactEmail'} text={t('contactEmail')} description={t('companyEmail')} icon={false}/>
                            <PhoneInput countryFieldName='countryCode' text={t('contactPhone')} name='contactPhone' description={t('companyPhone')} icon={false}/>
                            <PhoneInput countryFieldName='countryCodeSecond' text={t('contactPhoneSecond')} name='contactPhoneSecond' description={t('companyPhone')} icon={false}/>
                        </css.wrapInput>
                    </Form>
                </FormContext>
                {lead?.ref &&<css.wrapInfo>
                    <css.informationTitle>{t('leadInformation')}</css.informationTitle> 
                    <LeadInformation lead={lead}/>
                </css.wrapInfo>}
            </css.wrapper>
            <css.saveButton>
                <Btn onClick={handleSubmit} disabled={addLeadXHR === 'LOADING'} className={addLeadXHR}>{t('save')}</Btn>
            </css.saveButton >     
        </>
    );
}

export default NewLead;