import * as css from './AnotherOneCircle.css';
import BtnPlus from '../button/btnPlus/BtnPlus';

function AnotherOneCircle({title, onClick ,size}) {

    return (
        <>
            <css.wrapAll>
                <css.wrapper onClick={onClick} size={size}>
                    <BtnPlus dataCyName={'AnotherOne'} white={true} onClick={onClick} bsize={30}/>
                </css.wrapper>
                <css.title>{title}</css.title>
            </css.wrapAll>
        </>
    );
}
export default AnotherOneCircle;