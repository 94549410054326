import {useEffect} from 'react';

const UseMouseUpOutside=(ref, handler)=>{
    useEffect(
        () => {
            const listener = (event) => {
                if(!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mouseup', listener);
            return () => {
                document.removeEventListener('mouseup', listener);
            };
        },
        [ref, handler]
    );

};
export default UseMouseUpOutside;