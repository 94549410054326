import styled from 'styled-components/macro';
import {dFlexCenter} from '../../../globalVariables/GlobalVariables';
import {addEventToCluster} from '../../../assets/symbols/images/Images.css';

export const pageFrame = styled.div`
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    `;
export const pfRight = styled.div`
    width:70%;
    border-inline-end:1px solid #d7d7d7;
    position:relative;
    @media screen and (max-width:1000px){
        width:100%;
    }
`;
export const EButton = styled.div`
    .Button{
        padding: 17px 24px;
    }
`;
export const addFirstEToCluster = styled.div`
    width:70%;
    border-inline-end:1px solid #d7d7d7;
    position:relative;
    ${dFlexCenter};
    flex-direction: column;
    @media screen and (max-width:1000px){
        width:100%;
    }
`;
export const addEventToClusterImg = styled.div`
    background-size:contain;
    background-repeat: no-repeat;
    width: 248px;
    height: 285px;      
    background-image:url("${addEventToCluster}");  
`;
export const addFirstE = styled.div`
    margin: 40px 0;
    color:#000;
    @media screen and (max-width:1000px){
        margin: 25px 0;
    }
`;
// export const group=styled.div`
// `

export const pfLeft = styled.div`
    width:30%;
`;
export const addEvent = styled.div`
    margin: 0 auto;
    width: max-content;
    cursor:pointer;
    position: relative;
    @media screen and (max-width: 1000px){
        height: 50px;
        line-height: 50px;
        width: calc(100% - 20px);
        margin: 10px;
        text-align: center;
        border: 1px dashed #dadada;
        border-radius: 5px;
    }
`;
export const groupsList=styled.div`
    display :flex;
    width:100%;
    height:100%;
    align-items:center;
    position:absolute;
    left :0;
    top:0;
    justify-content: center;
    .list{
        position:relative;
    }
        
`;