import createReducer from '../createReducer';

const initialState = {
    crowds:null,

    getCrowdsXHR:null,
};

const reducer = createReducer('crowds',initialState);

export default reducer;