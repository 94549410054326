import * as css from './ImagePopup.css';
import MobilePopup from '../newPopup/mobilePopup/MobilePopup';
import Btn from '../button/btn/Btn';
import FullEditImage from '../fullEditImage/FullEditImage';
import {useTranslation} from 'react-i18next';
import {Form, useField, useFormikContext} from 'formik';
import Input from '../input/Input';
import {defaultImage} from '../../assets/symbols/images/defaultImage';
import useShow from '../../hooks/useShow';
import {Else, If, Then} from 'react-if';
import WarningPopup from './warningPopup/WarningPopup';
import InnerPop from '../newPopup/innerPop/InnerPop';

export type TImageProps = {
    size: { width: number, height: number },
    light?: string,
    image: string,
    imageId: string
    type?: string
    update?: (newImage: object) => void,
    setImage?: (newImage: object) => void,
    deleteImage?: () => void,
    isCompress?: boolean,
    limitMB?: number
};
export const creditForm = (fieldName, imageProps, t) => <>
    <FullEditImage {...imageProps}/>
    <Form >
        <css.details>
            <Input text={t('photographerName')} name={`${fieldName}.creditName`} events={undefined} className={undefined} forwardRef={undefined} icon={undefined} />
            <Input text={t('licenseLink')} name={`${fieldName}.license`} events={undefined} className={undefined} forwardRef={undefined} icon={undefined} />
            <Input text={t('alt')} name={`${fieldName}.alt`} events={undefined} className={undefined} forwardRef={undefined} icon={undefined}  />
        </css.details>
    </Form>
</>;
    
function ImagePopup({imageProps, fieldName, externalPopup, absoluteImage}:{imageProps: TImageProps, fieldName: string, externalPopup?: boolean, absoluteImage?: boolean}) {
    const {t} = useTranslation('layout');
    const {open, close, showClass, isOpen} = useShow();
    const warningShow =  useShow();
    const [{value:image},, helpers] = useField(fieldName);
    const {dirty} = useFormikContext();

    const onSubmit = async () => {
        if(!image?.image){
            helpers.setValue({...image, image: null, credit:{creditName: null, license: null, alt: null, userApproveWithoutCredit:0}});
            close();
        }
        else if(!image?.credit?.creditName){
            warningShow.open();
        }
        else {close();}
    };

    const saveImageWithoutCredit = () => {
        helpers.setValue({...image, credit:{...image.credit, userApproveWithoutCredit:1}});
        close();
    };
    
    return (<>
        <css.wrapPopup>
            <css.image data-cy={'imagePopup'} absoluteImage={absoluteImage} img={imageProps.image||defaultImage} width={imageProps.size.width} height={imageProps.size.height} onClick={open}/>
            <If condition={externalPopup}>
                <Then>
                    {!!isOpen && 
                    <MobilePopup showClass={showClass} close={close} title={t('imageEditTitle')} list={undefined} forwardRef={undefined} dataCyName={undefined}>
                        <css.wrapAll size={imageProps?.size?.width}>
                            {creditForm(`${fieldName}.credit`, imageProps, t)}
                            <css.buttons>
                                <Btn className={'cancel'} onClick={close} disabled={false}>{t('close')}</Btn>
                                <Btn onClick={onSubmit} className={''} disabled={!dirty}>{t('add')}</Btn>
                            </css.buttons>
                            {!!warningShow.isOpen && <WarningPopup setApprove={saveImageWithoutCredit} show={warningShow}/>}
                        </css.wrapAll>
                    </MobilePopup>
                    }
                </Then>
                <Else>
                    <InnerPop dataCy='imagePopup' title={t('imageEditTitle')} show={{open, close, showClass, isOpen}} buttons={{whiteBtn:{text:t('close')}, blueBtn:{text:t('add'), onClick: onSubmit, disabled: !dirty}}}>
                        <css.top /><css.wrapAll size={imageProps?.size?.width}>
                            {creditForm(`${fieldName}.credit`, imageProps, t)}
                        </css.wrapAll>
                        {!!warningShow.isOpen && <WarningPopup setApprove={saveImageWithoutCredit} show={warningShow}/>}
                    </InnerPop>
                </Else>
            </If>
        </css.wrapPopup>
    </>
    );
}

export default ImagePopup;
