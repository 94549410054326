import React, {useState, useEffect} from 'react';
import * as InputCss from './userProfileInput/UserProfileInput.css';
import {useTranslation} from 'react-i18next';
import List from '../../../../../component/list/List';
import useShow from '../../../../../hooks/useShow';

const UserProfileSelect = (props) => {
    const {t} = useTranslation('layout');
    const [selectedLangText, setSelectedLangText] = useState('');
    const selectLangOptions = [{key: 'en_us', text: t('englishUs')}, {key: 'he_il', text: t('hebrew')},];
    
    const {open, close, isOpen, showClass} = useShow( 300);

    useEffect(()=>{
        if(selectLangOptions){
            for(let i = 0; i < selectLangOptions.length; i++){
                if(selectLangOptions[i].key == props.iptProps.value){
                    setSelectedLangText(selectLangOptions[i].text);
                }
            }
        }
    },[props.iptProps.value, t]);

    const selectClick = (name, value) => {
        props.inputChange({target: {name: name, value: value}});
        close();
    };

    const openClose=()=>{
        if(isOpen)
            close();
        else open();
    };

    return <InputCss.UserInputCtr onClick={props.iptProps.enabled ? props.clickOut : null} >
        <InputCss.InputSvgCtr className={props.iptProps.name} onClick={openClose}/>
        <InputCss.FieldTitleCtr onClick={openClose}>{selectedLangText}
            {!!isOpen&&<List
                showClass={showClass} 
                top={'0'}
                title={props.iptProps.title}
                list={selectLangOptions}
                onClick={(e)=>{selectClick(props.iptProps.name, e.key);}}
                close={close}
            />}
        </InputCss.FieldTitleCtr>

    </InputCss.UserInputCtr>;
};

export default UserProfileSelect;