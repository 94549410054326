import * as css from './WrapModeBtn.css';
import {useTranslation} from 'react-i18next';
import ModeBtn from '../modeBtn/ModeBtn';
// import {statuses} from '../..'

function WrapModeBtn({text, dataTitle, click, status, requestStatus}) {

    const {t} = useTranslation('global');

    return (<>
        <css.item>
            <css.right>
                <css.name>{t(text)}</css.name>
                {t(`${text}Desc`)}
            </css.right>
            <ModeBtn dataTitle={dataTitle} click={click} status={status} requestStatus={requestStatus} />
        </css.item>
    </>
    );
}

export default WrapModeBtn;