import * as css from './TicketOptions.css';
import {updateGuideReport} from '../../../../redux/tickets/ticketsActions';
import {supportWhatsApp} from '../../../../assets/symbols/symbols/Symbols.css';
import UserImage from '../../../../layout/topMenu/layoutUserProfile/userImage/UserImage';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';

function getGuideReportOption(ticket ,event,t) {
    
    return {
        type: 'icon', icon: supportWhatsApp, text: t('sendReportToGuide'),
        disabled:!ticket.users?.length||!event.settings?.report?.attendanceReport,
        list: {
            title: t('sendReportToGuide'),
            arr: ticket.users?.map(user => ({
                key: user.uid,
                rightChildren:<UserImage path={user.picture} class='layout-img-user' />,
                leftChildren:<css.guideItemLeft>
                    {`${user.nameFirst} ${user.nameLast}`}
                    <ModeBtn className={'small'} status={user.attendanceReport} click={(selected,e)=>{
                        e.stopPropagation();
                        updateGuideReport(ticket.tid,user.uid,selected);
                    }}/>
                </css.guideItemLeft>
            }))}};

}
export default getGuideReportOption;
