import styled from 'styled-components/macro';
import {header} from '../../../component/rightHeader/RightHeader.css';
import {tableContainer} from '../../../component/newTable/Table.css';
import {tabletMedia} from '../../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
     height: calc(100% - 280px);
     ${header}{
          display:none;
     }
     ${tableContainer}{
          height: calc(100% - 5px);
          margin-top: 5px;
     }
     ${tabletMedia}{
          height: calc(100% - 156px);
     }
`;

