import * as css from './Sender.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

function Sender(props) {
    const {mailOrSms, isMobile}=props;
    const textParameters=useSelector(s=>s.mailing.parameters);

    const {t} = useTranslation('mailing');

    return (
        <css.bottomSender className="bottomSender">
            <css.senderIcon className="senderIcon" />
            <css.senderText className="senderText text">
                <css.senderTitle className="senderTitle">{isMobile ? '' : t('theSender')}</css.senderTitle>
                <css.senderValue className="senderValue">{mailOrSms == 'email' ? (textParameters['package.brandingEmail'] ? textParameters['package.brandingEmail'] : 'info@tickchak.co.il') : (textParameters['package.brandingSms'] ? textParameters['package.brandingSms'] : 'Tickchak')}</css.senderValue>
            </css.senderText>
        </css.bottomSender>
    );
}

export default Sender;