import {useSelector} from 'react-redux';
import NewTicketsEvent from './NewTicketsEvent';
import NewHubEvent from './NewHubEvent';

function NewEvent() {
    const type=useSelector(s=>s.event.type);
    
    const generators={
        hub:NewHubEvent
    };

    const Comp=generators[type]||NewTicketsEvent;

    return (
        <Comp/>
    );
}

export default NewEvent;
