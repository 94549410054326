import Close from '../../close/Close';
import * as css from './TopPopup.css';
import {Else, If, Then} from 'react-if';

function TopPopup({children, closePop, title,list=false, dataCyName, closeProps={}}) {
    return (
        <css.popUpTop data-cy={`${dataCyName ? dataCyName : 'default'}PopUpTop`}>
            <Close onClick={closePop} className={'pop'} {...closeProps}/>
            <css.popTitle>{title}</css.popTitle>
            <css.children>{children}</css.children>
            <If condition={!list}>
                <Then>
                    <css.options />
                </Then>
            </If>
            <Else>
                <css.listCon>
                    {list&&<list.comp{...list}/>}
                </css.listCon>
            </Else>

        </css.popUpTop>
    );
}

export default TopPopup;
