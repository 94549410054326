import styled from 'styled-components/macro';

import {BGImageCenter, BUTTON_HEIGHT, dFlexCenter} from '../../../globalVariables/GlobalVariables';
import {whatsApp} from '../../../assets/symbols/symbols/Symbols.css';
export const btnHelp=styled.div`
  height: ${BUTTON_HEIGHT}px;
  border: #57dc09 2px solid;
  border-radius: 20px;
  width: fit-content;
  padding: 6px;
  font-weight: 600;
  padding-inline-start: 35px;
  padding-inline-end: 10px;
  ${BGImageCenter(whatsApp,'auto')}
  background-position: ${props => props.theme.rtl ? 'calc(100% - 10px)' : '10px'} center;
  cursor: pointer;
  ${dFlexCenter}
  transition: background-color 300ms;
  :hover{
    background-color: #CCC;
  }
`;