import * as css from './OfficeOptions.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import setGlobalState from '../../redux/setGlobalState';

function OfficeOptions() {
    const {isMasterState} = useSelector(state => state.ui);
    const {isMaster} = useSelector(state => state.user);
    const {t} = useTranslation('package');

    const masterClick = () => {
        setGlobalState('ui.isMasterState', !isMasterState);
    };

    return (
        <>
            {isMaster&& 
            <css.circle dataTitle={t('tickchakOffice')} onClick={masterClick}>
                <css.activeCircle className={isMasterState ? 'show' : ''} />
                <css.officeIcon />
            </css.circle>}
        </>
    );
}
export default OfficeOptions;
