import * as css from './FullEditImage.css';
import FileUploader from '../dropzone/Dropzone';
import setGlobalState from '../../redux/setGlobalState';
import {defaultImage} from '../../assets/symbols/images/defaultImage';
import useShow from '../../hooks/useShow';
import DeleteImagePop from './deleteImagePop/DeleteImagePop';
import AbsoluteImageEditor from './absoluteImageEditor/AbsoluteImageEditor';
import {edit, editLight, remove, removeLight, upload, uploadLight} from '../../assets/symbols/symbols/Symbols.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FullEditImage({setImage = (_value, _shouldValidate) => {}, image, size, update, deleteImage =()=>{}, light='',isCompress=true, limitMB=1,type}) {
    
    const isEditImageTrue=()=>{setGlobalState('ui.editImage.isEditImage', true);};

    const openDeleteImage = useShow();

    const onDeleteImage = () => {
        setImage('');
        deleteImage();
    };

    const onEditImage = (openEditImage) => {
        if(image){
            isEditImageTrue();
            openEditImage.open();     
        }
    };
    return (<>
        <AbsoluteImageEditor limitMB={limitMB} setImage={setImage} image={image} size={size} update={update} isCompress={isCompress} type={type}>
            {(image,newSize,forwardRef,uploadImage,openEditImage)=>
                <>
                    <css.shareImageBase width={newSize.width} height={newSize.height} >
                        <css.sIBImage data-cy={'Dropzone'} img={image||defaultImage} width={newSize.width} height={newSize.height}>
                            <FileUploader width={`${newSize.width}px`} height={`${newSize.height}px`} onUpload={uploadImage} maxFiles={1} type="image/*"  />
                        </css.sIBImage>
                        <css.sIBBot>
                            <css.sIBBotChild data-cy="upload" className={light} icon={light?uploadLight:upload} >
                                <FileUploader width={'20px'} height={'20px'} onUpload={uploadImage} maxFiles={1} type="image/*" />
                            </css.sIBBotChild>
                            <css.sIBBotChild data-cy="edit" className={(!image&&'edit')+light} icon={light?editLight:edit} onClick={()=>onEditImage(openEditImage)} />
                            {deleteImage && <css.sIBBotChild data-cy="remove" className={(!image&&'edit ')+' remove'+light} icon={light?removeLight:remove} onClick={()=>openDeleteImage.open()} />}
                        </css.sIBBot>
                        <DeleteImagePop newSize={newSize} image={image} openDeleteImage={openDeleteImage} onDeleteImage={onDeleteImage} top={forwardRef?.current?.getBoundingClientRect().y-25}/>
                    </css.shareImageBase>
            
                </>}
        </AbsoluteImageEditor>
    </>
    );
}
export default FullEditImage;
