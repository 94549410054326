import store from '../store';

export const hasEventRoleAccess = (perm) => {
    if(!perm)
        return false;
    const eid = store.getState().event.eid;
    let user = getUser();
    if(!user) {
        return false;
    }
    if(hasAccountRoleAccess(perm))
        return true;
    // if (!$eid){
    //     if (!inArray($perm, $user->permissions))
    //     {
    //         watchdog(__METHOD__." no event id, needed permissions '" .$perm."' user has ".watchjson($user->permissions).watchjson(debugBacktrace()). debugBacktrace()[0]['file'].' '.debugBacktrace()[0]['line'] );
    //         return false;
    //     }
    //     return true;
    // }
    let permissions = (localStorage.getItem('perEvent_' + eid));
    if(permissions && permissions !== 'undefined') {
        permissions = JSON.parse(permissions);
        if(permissions.indexOf(perm) !== -1)
            return true;
    }
    return false;

};

export const hasAccountRoleAccess = (perm) => {
    if(!perm)
        return false;
    const account = store.getState().account;
    const pid = account.pid;
    const accountRole = account.userAccountRole;
    let user = getUser();
    if(!user) {
        return false;
    }
    if(isMaster())
        return true;
    // if(!hasAccountAccess(perm,pid,user.isMaster))
    //     return false;
    if(!accountRole) {
        if(!pid && perm === 'access')
            return true;
        return false;
    }
    let permissions =store.getState().account.userPermissions;
    if(permissions.indexOf(perm) !== -1)
        return true;
    return false;
};

export const getUser = () => store.getState().user;

export const isMaster = () => store.getState().user.isMaster;

export const hasRoleAccess = rolePermission => store.getState().event.eid ? hasEventRoleAccess(rolePermission)
    : hasAccountRoleAccess(rolePermission);

export const hasAccountAccess = permission =>
    store.getState().account.permissions?.find((item) => item.permission === permission)?.status===1;