import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import MobilePopupModal from '../../../component/newPopup/mobilePopup/MobilePopupModal';
import EventSnapshotItem from './eventSnapshotItem/EventSnapshotItem';
import * as css from './EventsSnapshotPop.css';
import {getEventsReport} from '../../../redux/suggestions/suggestionsActions';
import Loader from '../../../component/loader/Loader';
import ExportExcel from '../../../pages/attendance/exportExcel/ExportExcel';
import {useState} from 'react';
import setGlobalState from '../../../redux/setGlobalState';
import Tooltip from '../../../component/tooltip/Tooltip';
import {Fragment} from 'react';
import FilterList from '../../../component/filterList/FilterList';
import {tsGetYear} from '../../../library/dates';
import {ts2date, ts2time} from '../../../library/dateFunctions_old_to_be_deprecated';

function EventsSnapshotPop({openSnapshotPop}) {

    const {t} = useTranslation('layout');
    const {eventsReport,getEventsReportXHR} = useSelector((s) => s.suggestions);
    const account = useSelector(s => s.account);
    const [{limit, offset}, setLimitOffset] = useState({limit: 10, offset: 0});

    const listOptions = [
        {key: 0, text: t('allEvents'),type:'allEvents'},
        {key: 1, text: t('yourEvents'),type:'active'},
        {key: 2, text: t('archive'),type:'archive'},
        {key: 3, text: t('cluster'),type:'cluster'},
        {key: 4, text: t('frontpage'),type:'frontpage'}];

    const [currentType,setCurrentType]=useState(listOptions[0]);

    const onClickList=(item)=>{
        if(item.type!==currentType.type) { 
            setGlobalState('ui.exportExcel.isNotLoadAll',true);
            setLimitOffset({offset:0});
            setGlobalState('suggestions.eventsReport',[]);
            setCurrentType(item);
        }
    };  
        
    const updateLimitOffset = (name, value) => {
        setLimitOffset(prevState => ({...prevState, [name]: value}));
    };
        
    const getDate = (type,timeStart) => {
        return type==='course'?tsGetYear(timeStart) :
            (timeStart?ts2date(timeStart) + ' - ' + ts2time(timeStart):t('continuousEvent'));
    };

    const getAllEvents = () => {
        getEventsReport(true,{pid: account.pid, search:'', limit:(offset<20)?20:10, offset, type: currentType.type,isSnapshot:1});
    };

    useEffect(() => {
        setGlobalState('ui.exportExcel.isNotLoadAll',true);
    }, []);

    useEffect(() => {
        getEventsReport(false,{pid: account.pid, search:'', limit:(offset<20)?20:10, offset, type: currentType.type,isSnapshot:1});
    }, [limit, offset, account.pid,currentType]);

    useEffect(() => {
        return setGlobalState('suggestions.eventsReport',[]);
    }, []);

    const sheets=[
        {
            name:t('snapshotPop'),
            data:[[t('eventNumber'),t('eventName'),t('date'),t('ticketsSale'),t('crm'),t('totalSale')],
                ...eventsReport.map(e=>[
                    e.eid,
                    e.title,
                    getDate(e.type,e.timeStart),
                    `${e.tickets.sold?e.tickets.sold:0}/${e.tickets.amount?e.tickets.amount:0}`,
                    e.transactions,e.totalPrice])],
        }
    ];

    let flagEvent = false;
    return (
        <>
            <MobilePopupModal {...openSnapshotPop} title={t('snapshotPop')} >
                <css.wrapTopChildren >
                    <Tooltip text={t('eventFiltering')}>
                        <FilterList filter={currentType.key} onClick={onClickList} listProps={{list:listOptions,'width':'190px'}}/>
                    </Tooltip>
                    <ExportExcel sheets={sheets} fileName={t('snapshotPop')} onClick={getAllEvents}/>
                </css.wrapTopChildren><>
                    <css.boldTitle>{currentType.text}</css.boldTitle>
                    <Loader height={'calc(100% - 104px)'} XHR ={getEventsReportXHR} onScrollEnd={ ()=>updateLimitOffset('offset',eventsReport.length)}>
                        <css.wrapAllItems >
                            {eventsReport?.map((event,key) => {
                                let titleOther = null;
                                if(!flagEvent && event.pid !== account.pid) {
                                    flagEvent = true;
                                    titleOther = <css.title>{t('eventsPackages')}</css.title>;
                                }
                                return [titleOther, <Fragment key={key}><EventSnapshotItem event={event} getDate={getDate} openSnapshotPop={openSnapshotPop}/></Fragment> ];})
                            }
                        </css.wrapAllItems>
                    </Loader>     </>
            </MobilePopupModal>
          
        </>
    );
}
export default EventsSnapshotPop;