import {useTranslation} from 'react-i18next';
import * as css from './DateTopStep.css';
import {useField} from 'formik';
import {getHourMinutes, tsToDate} from '../../../../../library/dates';

function DateTopStep() {
    const {t} = useTranslation('newEvent');

    const [dateField]=useField('date');
    const {startDate,startTime,endDate,endTime,duration}=dateField.value;
  
    return (
        <css.wrapper>
            <css.startText>{t('start')}</css.startText>
            <css.startDate>
                <span>{duration?getHourMinutes(startTime):''}</span>
                {tsToDate(startDate)}
            </css.startDate>
            <css.endText>{t('end')}</css.endText>
            <css.endDate>
                <span>{duration?getHourMinutes(endTime):''}</span>
                {tsToDate(endDate)}
            </css.endDate>
        </css.wrapper>

    );
}

export default DateTopStep;