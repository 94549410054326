import * as css from './UserMessage.css.js';
import React,{useEffect} from 'react';
import {useSelector} from 'react-redux';
import useShow from '../../hooks/useShow';
import setGlobalState from '../../redux/setGlobalState';
import Close from '../../component/close/Close';
import store from '../../store';
import initialUserMessage from '../../redux/ui/uiUserMessage';
import Btn from '../../component/button/btn/Btn.js';
import CountButton from '../../component/button/countButton/CountButton.js';
import {useTranslation} from 'react-i18next';
import {useRef} from 'react';
import UseMouseUpOutside from '../../hooks/UseMouseUpOutside.js';

let onAccept=()=>{};
let onClose=()=>{}; 

const openMessage=(text,type='',acceptButton,closeButton,timer=8)=>{
    onAccept=acceptButton?acceptButton.onClick:()=>{};
    onClose=closeButton?.onClick?closeButton.onClick:()=>{};
 
    setGlobalState('ui.userMessage', {...store.getState().ui.userMessage, 
        status:'open',
        text,
        type,
        acceptText:acceptButton?.text,
        closeText:closeButton?(closeButton?.text||'close'):null,
        isImplementFromButtonOnly:(closeButton&&closeButton.isImplementFromButtonOnly),
        timer:acceptButton&&closeButton?null:timer
    }
    );
};

export const bounceMessage=(text,type='attention',acceptButton,closeButton={text:'close'},timer)=>{
    const status=store.getState().ui.userMessage.status;
    if(!status||status!=='open'){
        openMessage(text,type,acceptButton,closeButton,timer);
        return;
    }
    setGlobalState('ui.userMessage.status','replace');
    setTimeout(() => {
        openMessage(text,type,acceptButton,closeButton,timer);
    }, 500);
};

export const bounceMessageModal=(text,type='attention',acceptButton,closeButton={text:'close'},timer)=>{
    setGlobalState('ui.userMessage.isModal',true);
    bounceMessage(text,type,acceptButton,closeButton,timer);
    
};

const UserMessage=React.memo(function UserMessage() {
    const userMessage=useSelector(s=>s.ui.userMessage);
    let {text,type,acceptText,closeText,status,timer}=userMessage;
    const {t}=useTranslation('layout');

    const {open,close,isOpen,showClass}=useShow();
    const ref=useRef();  
 
    UseMouseUpOutside(ref, ()=>setGlobalState('ui.userMessage.status','close'));

    useEffect(()=>()=>setGlobalState('ui.userMessage.isModal',false),[]);
    
    useEffect(()=>{
        if(status==='open'&&!isOpen)
            open();
        else if(status==='replace')
            close();
        else if(status==='close')
            close();
    },[status]);

    const closeAndInitial=(e)=>{
        e?.stopPropagation();
        close();
        setTimeout(() => {
            setGlobalState('ui.userMessage',initialUserMessage);
        }, 310);
    };

    const closeMessage=(e)=>{
        onClose();
        closeAndInitial(e);
    };

    if(!isOpen)
        return <></>;

    const Button=timer?CountButton:Btn;
    const buttonProps=timer?{style:{backgroundColor:css.types[type].backgroundColor,circleColor:css.types[type].circleColor},count:timer,callBack:closeMessage,className:'timer'}:{};
    type=type?type:'attention';

    return <>
        <css.UserMessages className={`${showClass} ${type}`} type={type} { ...(userMessage?.isModal?{ref:ref}:{})} data-cy = {`userMessage-${type}`}>
            <css.close type={type}>
                <Close className={'white'} onClick={userMessage.isImplementFromButtonOnly?closeAndInitial:closeMessage}/>
            </css.close >
            <css.circle type={type}/>
            <css.Message>{text}</css.Message>
            <css.buttons type={type}  >
                {closeText&&<Button onClick={closeMessage} {...buttonProps}>{t(closeText)}</Button>}
                {acceptText&&<Button onClick={(e)=>{onAccept();closeAndInitial(e);}}  className={' main '} {...buttonProps}>{acceptText}</Button>}
            </css.buttons>
        </css.UserMessages>
    </>;
}
);
export default UserMessage;