import styled from 'styled-components/macro';
import {BGImageCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';
import * as input from '../../classes/input';
import {copy} from '../../copyLink/CopyLink.css';

export const container = styled(input.container)`
  ${copy}{
    position: absolute;
    bottom: 0px;
    inset-inline-end: 20px; 
  }
`;

export const icon = styled(input.icon)`
    ${BGImageCenter(p=>p.icon,16)}`;
export const wrap = input.wrap;

export const inputs =  styled(input.input)`
    display: flex;
    justify-content: space-between;
    font-weight:400 ;
`;
export const wrapInput = styled.div`
    display: flex;
    direction: ltr;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
      -webkit-transition-delay: 9999s;
    }
    transition: all 300ms;
    input{
      width: ${p=>p.isSubDomain?'90':'150'}px;
      border: 0;
      outline: 0;
      direction: ltr;
      font-weight:${input.INPUT_WEIGHT} ;
      border-bottom: 1px solid;
      font-size: 14px;
      border-right: 0;
      transition: all 100ms;
      resize: none;
      background: transparent;
      &.notValid {
        color: #F00020;
      }
      :hover {
        color: #65B8E2;
        font-weight: 600;
        &.notValid {
          border-bottom: 1px solid #F00020;
        }
        ::placeholder {
          color: #65B8E2;
        }
        ::-webkit-input-placeholder { 
          letter-spacing: 1px !important;
        }
      }
      /* :hover {
        font-weight: 600;
        &.notValid {
          border-bottom: 1px solid #F00020;
        }
      } */
    }
    &.inValid{
        ${'input'} {
            color: #DD2F21;
            font-weight: 600;
            :focus {
                color: #DD2F21;
            }
            ::placeholder {
                color: #DD2F21;
            }
        }
    }
    ${mobileMedia}{
      input{
          width: ${p=>p.isSubDomain?'70':'130'}px;
      }
    }
`;

export const description = styled.div`
    position:absolute ;
    display: flex;
    font-size: 10px;
`;

export const text = styled.div`
    font-weight:500;    
`;
