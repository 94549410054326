import styled from 'styled-components/macro';
import {blackSuccess} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, GREEN, dFlexCenter} from '../../../../globalVariables/GlobalVariables';

export const text = styled.div`
    height: 30px;
    line-height: 30px;
    font-weight: bold;
 
`;

export const icon = styled.div`
    width: 20px ;
    height: 20px;
    ${p=>BGImageCenter(p.icon,'contain')}
`;

export const circle = styled.div`
    position:relative ;
    width: 40px;
    height: 40px;
    border: 1px solid #5D5D5D;
    border-radius:50% ;
    ${dFlexCenter};
    &.selected{
        background-color: #76D6E8;
       ::before{
        transform:scale(1);
       }
    }
    :hover{
        border: 1px solid #76D6E8;
        transition:all 300ms;
    }
    ::before {
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 50%;
        top: 5px;
        right: -7px;
        inset-inline-end: 0;
        transform:scale(0);
        transition:all 200ms cubic-bezier(0.42, 0, 0.95, 1.52);
        ${BGImageCenter(blackSuccess, '8px')}
        background-color:${GREEN};
  }
`;
export const container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding-inline-end:9px;
    &.ownerDisable{
        pointer-events:none;
        opacity:0.6;
        cursor: default;
        ${circle}:hover{
            border: 1px solid #5D5D5D;
        }
    }

    ${p=>p.cssStyle}
`;