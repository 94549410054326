import {image} from '../../../../component/circleImageText/CircleImageText.css';
import styled from 'styled-components/macro';
import {DEFAULT_COLOR} from '../../../../globalVariables/GlobalVariables';

export const container=styled.div`
  ${image}{
    background-size: 40px;
    background-color: ${DEFAULT_COLOR};
    border: #FFFFFF 5px solid;
  }
`;
