import {useTranslation} from 'react-i18next';
import {bank} from '../../../../library/bank';
import InputList from '../../../../component/input/inputList/InputList';

function BankList({name, children, bankKey, valid, className}) {
    const {t} = useTranslation('global');
    const p = useTranslation('package');

    const change = (name, key) => {
        bankKey(key);
    };

    return (
        <>
            <InputList name={name} className={(!valid ? 'problem' : '' )+ ' ' + className} text={p.t('bank')} list={bank} icon={''} onChange={(n, k) => change(n, k)} t={t} valid={valid}>
                {children}
            </InputList>
        </>
    );
}

export default BankList;