import * as css from './Header.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Close from '../../../component/close/Close';
import {ts2time, ts2date} from '../../../library/dateFunctions_old_to_be_deprecated';
import EventActive from '../../components/eventActive/EventActive';
import {defaultImage} from '../../../assets/symbols/images/defaultImage';
import hasSpecificTranslate from '../../../library/hasSpecificTranslate';
import getCountryByTimeZone from '../../../library/getCountryByTimeZone';

function EventSettingsHeader({close}) {

    const {t}=useTranslation('event');
    const {timeEnd,timeStart,title,fbImg,timeZone,type}= useSelector(state=>state.event);
    
    return (
        <css.container data-cy='EventSettingsHeader'  >
            <css.close>   <Close  onClick={close} className=" white"/> </css.close>
            <css.status>
                {type!=='hub'&& <EventActive classes=' yellow '/>}
            </css.status>
            <css.eventWarp>
                <css.eventPicture image={fbImg||defaultImage}/>
                <css.eventSettingWarp>
                    <css.eventSetting>{hasSpecificTranslate(`${type}Settings`,t)||t('eventSettings')}</css.eventSetting>
                    <css.eventDetailsWrap>
                        <css.eventName>{title}</css.eventName>
                        <css.dateWarp>
                            {timeStart && <>
                                <css.eventDate>{ts2date(timeStart)}</css.eventDate>
                                <css.eventDate>{ts2time(timeStart)}</css.eventDate>
                                <css.eventDate className='end'>{`${ts2date(timeEnd)} ${getCountryByTimeZone(timeZone)}`}</css.eventDate>
                            </>}
                        </css.dateWarp>
                    </css.eventDetailsWrap>
                </css.eventSettingWarp>
            </css.eventWarp>
        </css.container>

    );
}

export default EventSettingsHeader;
